import { InputNumber as AntInputNumber } from 'antd'
import { InputNumberProps as AntInputNumberProps } from 'antd/lib/input-number/index'
import React, { ComponentRef } from 'react'
import styled from 'styled-components'

type ValueType = string | number

export type InputNumberRef = ComponentRef<typeof AntInputNumber>

export interface InputNumberProps<T extends ValueType = ValueType> extends AntInputNumberProps<T> {
  editing?: boolean
  hideStep?: boolean
}

const StyledAntInputNumber: typeof AntInputNumber = styled(AntInputNumber)`
  &.read-only,
  &.read-only input
  {
    border-color: transparent;
    background-color: transparent;
    outline-color: transparent;
    box-shadow: none;
  }

  &.hideStep .ant-input-number-handler-wrap {
    display: none;
  }
`

export const InputNumber = React.forwardRef<InputNumberRef, InputNumberProps>((props, ref) => {
  const { editing, hideStep, ...args } = props
  if (editing === false && args.readOnly === undefined) {
    args.className = `${args.className} read-only`
    args.readOnly = true
  }
  if (hideStep) {
    args.className = `${args.className} hideStep`
  }
  if (args.autoComplete === undefined) {
    args.autoComplete = 'off'
  }
  return <StyledAntInputNumber data-lpignore="true" ref={ref} {...args} />
}) as (<T extends ValueType = ValueType>(
  props: InputNumberProps<T> & {
    ref?: React.Ref<InputNumberRef> | undefined
  }
) => ReturnType<typeof StyledAntInputNumber>)
// Type assertion necessary for generics with forwardRef
// rc-util supportRef requires component function to have ref explicilty as param

export default InputNumber
