import { Upload as AntUpload } from 'antd'
import { UploadProps as AntUploadProps } from 'antd/lib/upload/index'
import React from 'react'
import styled from 'styled-components'

export interface UploadProps extends React.PropsWithChildren<AntUploadProps> {
  editing?: boolean
}

const StyledAntUpload = styled(AntUpload)`
  &.read-only,
  &.read-only:hover,
  &.read-only .ant-upload,
  &.read-only .ant-upload:hover
  {
    cursor: default;
  }
  &.read-only .ant-upload-select,
  &.read-only .ant-upload-select:hover
  {
    border: 1px dashed transparent;
    background: inherit;
  }
`

export const Upload = React.forwardRef<any, UploadProps>((props, ref) => {
  const { editing, ...args } = props
  if (editing === false) {
    args.className = `${args.className} read-only`
    args.openFileDialogOnClick = false
  }
  return <StyledAntUpload {...args} ref={ref} />
})

export default Upload
