import { gql } from '@apollo/client'
import { CustomerDrawer } from 'components/drawers/CustomerDrawer'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { ViewMoreDrawer } from 'components/layout/ViewMoreDrawer'
import { customerName } from 'helpers/customerName'
import { byFieldAsc } from 'helpers/sortting'
import { compact, isNumber, isString } from 'lodash'
import React from 'react'
import { Customer, Tag } from 'schema'
import { RecursivePartial } from 'types'

import { TAG_SLIM_FRAGMENT } from 'gql/tags'
import { LabelWithTags } from './components/LabelWithTags'

export type CustomerSelectProps = ReferenceSelectProps<Customer>

const GET_CUSTOMERS = gql`
  query GetCustomers($where: QueryCustomersWhereInput) {
    customers(where: $where) {
      id
      abbreviation
      name

      tags {
        ...TagFields
      }
    }
  }

  ${TAG_SLIM_FRAGMENT}
`

const customerOptionGenerator = (customer?: RecursivePartial<Customer> | null) => {
  if (!customer?.id) return

  const name = customerName(customer)
  if (!name) return

  const tags = (customer.tags?.slice() || []) as Tag[]

  if (customer.id === 8029) {
    tags.push({
      id: -1,
      path: 'system',
      slug: 'overdue',
      color: '#eb5a46',
      name: 'Overdue: $3105.15',
      active: true,
    } as any)
  }

  return {
    name,
    label: <LabelWithTags
      tooltip={customer.name || customer.abbreviation}
      tags={tags}
    >
      {name}
    </LabelWithTags>,
    value: customer.id,
  }
}

export const defaults: Partial<CustomerSelectProps> = {
  showSearch: true,
  optionsGenerator: (customers) => (
    compact(customers.map(customerOptionGenerator)).sort(byFieldAsc('name'))
  ),
  generateExternalLink: (customerId) => {
    const id = isNumber(customerId) ? customerId : (isString(customerId) ? parseInt(customerId) : undefined)

    return (
      <ViewMoreDrawer
        closeIcon={false}
        buttonStyle={{ opacity: id ? 1 : 0, visibility: id ? 'visible' : 'hidden' }}
      >
        <CustomerDrawer key={id} id={id} />
      </ViewMoreDrawer>
    )
  },
}

export const CustomerSelect: React.FC<CustomerSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Customer',
  queryField: 'customers',
  gql: GET_CUSTOMERS,
  defaults: {
    ...defaults,
    showExternalLink: !props.mode,
  },
}, props))

export default CustomerSelect
