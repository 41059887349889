import { gql } from '@apollo/client'
import { ADDRESS_FRAGMENT } from './addresses'
import { SITE_FULL_FRAGMENT } from './sites'
import { TAG_SLIM_FRAGMENT } from './tags'

export const ORDER_DETAILS_FULL_FRAGMENT = gql`
  fragment OrderDetailsFragment on OrderDetails {
    id
    type
    subType
    equipmentId
    equipment {
      id
      name
      displayName
    }
    inventory {
      id
      quantity
      itemId
      item {
        id
        name
        displayMultiplier
        displayUnit
      }
    }
    metrics {
      key
      value
    }
    schedule {
      id
      scheduleIndex
      startTime
      endTime
      duration
      address {
        ...AddressFields
      }
      pour {
        id
        name
        typeId
        type {
          id
          name
        }
      }
      step
      stepDetails {
        slug
        name
      }
      metrics {
        key
        value
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`

export const ORDER_FULL_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    revision
    name
    branchId
    purchaseOrder
    instructions
    bulletinToCustomer
    cashOnDelivery
    dateOfService
    dateOfServiceLocal
    flatRate
    linesOnSite
    commentsCount
    additionalIds {
      key
      value
    }
    integrations {
      quickbooks {
        invoice {
          id
        }
      }
    }
    planned {
      ...OrderDetailsFragment
    }
    actuals {
      ...OrderDetailsFragment
    }
    billable {
      ...OrderDetailsFragment
    }

    assignments {
      id
      acknowledgedAt
      role
      roleDetails {
        slug
        name
        sort
      }
      operatorId
      operator {
        id
        firstName
        lastName
      }
    }

    supplierId
    supplier {
      id
      name
      name2
    }
    status
    statusDetails {
      slug
      name
      color
    }
    cancellationReason
    cancellationReasonDetails {
      slug
      name
    }
    customerId
    customer {
      id
      name
      abbreviation
    }
    siteId
    site {
      ...SiteFields
    }
    contacts {
      default
      contactId
      contact {
        id
        firstName
        lastName
      }
      roleId
      role {
        id
        name
        slug
        sort
      }
      createdAt
    }
    tags {
      ...TagFields
    }
  }
  ${ORDER_DETAILS_FULL_FRAGMENT}
  ${SITE_FULL_FRAGMENT}
  ${TAG_SLIM_FRAGMENT}
`

export const ORDER_SLIM_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    revision
    name
    dateOfService
    statusDetails {
      slug
      name
      color
    }
    customer {
      id
      name
      abbreviation
    }
    billable {
      equipment {
        id
        name
        displayName
      }
    }
    planned {
      schedule {
        id
        step
        startTime
      }
    }
    contacts {
      id
      default
      contact {
        id
        firstName
        lastName
      }
    }
    site {
      id
      name
      name2
      address {
        id
        lat
        lng
        timezone
      }
    }
    tags {
      ...TagFields
    }
    assignments {
      id
      acknowledgedAt
      roleDetails {
        slug
        sort
      }
      operator {
        id
        firstName
        lastName
      }
    }
  }
  ${TAG_SLIM_FRAGMENT}
`

export const GET_ORDERS_QUERY = gql`
  query GetOrders(
    $orderBy: [QueryOrdersOrderByInput!],
    $where: QueryOrdersWhereInput
    $skip: Int
    $take: Int
  ) {
    orders(
      orderBy: $orderBy
      where: $where
      skip: $skip
      take: $take
    ) {
      ...OrderFragment
    }
  }
`

export const GET_ORDERS_SLIM = gql`
  ${GET_ORDERS_QUERY}
  ${ORDER_SLIM_FRAGMENT}
`

export const GET_ORDERS_FULL = gql`
  ${GET_ORDERS_QUERY}
  ${ORDER_FULL_FRAGMENT}
`

export const GET_ORDER_FULL = gql`
  query GetOrder($id: Int!) {
    order(id: $id) {
      ...OrderFragment
    }
  }

  ${ORDER_FULL_FRAGMENT}
`

export const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $data: OrderUpdateInput!
    $where: OrderWhereUniqueInput!
  ) {
    updateOrder(
      data: $data
      where: $where
    ) {
      ...OrderFragment
    }
  }
  ${ORDER_FULL_FRAGMENT}
`

export const CREATE_ORDER = gql`
  mutation CreateOrder(
    $data: OrderCreateInput!
  ) {
    createOrder(
      data: $data
    ) {
      ...OrderFragment
    }
  }
  ${ORDER_FULL_FRAGMENT}
`
