import { DownOutlined } from '@ant-design/icons'
import { DatePicker } from 'antd'
import { compact } from 'lodash'
import React, { ComponentProps, useRef, useState } from 'react'
import styled from 'styled-components'

export type DatePickerButtonProps = React.PropsWithChildren<ComponentProps<typeof DatePicker> & {
  centered?: boolean
}>

export const DatePickerButton = styled((props: DatePickerButtonProps) => {
  const {
    className, style, children, centered, ...datePickerProps
  } = props

  const [open, setOpen] = useState<boolean>(false)
  const clickGuard = useRef<boolean>(false)

  return (
    <div className={compact([className, centered && 'centered']).join(' ')} style={style}>
      <div
        className="date-picker-text"
        onClick={() => {
          if (clickGuard.current) return
          setOpen(!open)
        }}
      >
        {children} <DownOutlined />
      </div>
      <DatePicker
        {...datePickerProps}
        onChange={(...args) => {
          setOpen(false)

          if (props.onChange) {
            props.onChange(...args)
          }
        }}
        onOpenChange={(newOpen) => {
          clickGuard.current = true
          setOpen(newOpen)
          if (props.onOpenChange) {
            props.onOpenChange(newOpen)
          }
          setTimeout(() => {
            clickGuard.current = false
          }, 100)
        }}
        open={open}
      />
    </div>
  )
})`
  position: relative;

  .date-picker-text {
    text-align: center;

    > .anticon {
      margin-left: 5px;
      font-size: 0.9em;
    }
  }

  .ant-picker {
    position: absolute;
    top: 0;
    margin-left: -140px;
    left: 50%;
    height: 100%;
    width: 0;
    visibility: hidden;
    overflow: hidden;
  }
`
