import { compact, first, last } from 'lodash'

import { useGeotabUrl } from 'hooks/useGeotabUrl'
import { useSamsaraUrl } from 'hooks/useSamsaraUrl'
import { useMemo } from 'react'

import { useFeatures } from 'hooks/useFeature'
import { useGeotabEquipmentId } from 'hooks/useGeotabEquipmentId'
import { DateTime } from 'luxon'
import { Route, RouteWaypointStatus } from 'schema'
import { DropdownMenu, DropdownMenuProps } from './DropdownMenu'
import { Menu, MenuDivider } from './Menu'

export interface RouteActionsMenuProps extends Omit<DropdownMenuProps, 'dropdownProps' | 'menuProps'> {
  route: Route | null
}

export const RouteActionsMenu = ({ route, ...dropdownProps }: RouteActionsMenuProps): JSX.Element | null => {
  const [features] = useFeatures(...['integrations.samsara.enabled', 'integrations.geotab.enabled'])
  const samsaraEnabled = features['integrations.samsara.enabled']
  const geotabEnabled = features['integrations.geotab.enabled']

  const generateSamsaraUrl = useSamsaraUrl()
  const generateGeotabUrl = useGeotabUrl()
  const geotabEquipmentId = useGeotabEquipmentId(route?.equipmentId || route?.equipment?.id)

  const samsaraLink = useMemo(() => {
    if (!samsaraEnabled) return

    const samsaraRouteId = route?.integrations?.samsara?.route?.id
    const samsaraRouteUrl = generateSamsaraUrl(`/fleet/dispatch/v2/route/${samsaraRouteId}`)
    const link = (samsaraRouteId && samsaraRouteUrl) ? samsaraRouteUrl : undefined

    return (
      <Menu.Item key="links.viewInSamsara" disabled={!samsaraRouteId}>
        <a href={link} target="blank">View in Samsara</a>
      </Menu.Item>
    )
  }, [route, samsaraEnabled, generateSamsaraUrl])

  const geotabLink = useMemo(() => {
    if (!geotabEnabled) return

    const geotabTripUrl = (() => {
      if (!route) return
      if (!geotabEquipmentId) return

      const isTracked = route.waypoints.some((wp) => wp.status !== RouteWaypointStatus.Scheduled)
      if (!isTracked) return

      const waypointTimes = compact(route.waypoints.flatMap((wp) => [wp.actualArrivalTime || wp.scheduledArrivalTime, wp.actualDepartureTime || wp.scheduledDepartureTime])).sort()
      const startDate = DateTime.fromISO(first(waypointTimes) || route.startTime).startOf('hour').minus({ hours: 2 }).toUTC().toISO()
      const endDate = DateTime.fromISO(last(waypointTimes) || route.startTime).endOf('hour').plus({ hours: 2 }).toUTC().toISO()

      return generateGeotabUrl([
        '#tripsHistory',
        `dateRange:(endDate:'${endDate}',startDate:'${startDate}')`,
        `devices:!(${geotabEquipmentId})`,
      ].join(',')) || undefined
    })()

    return (
      <Menu.Item key="links.viewInGeotab">
        <a href={geotabTripUrl} target="blank">View in Geotab</a>
      </Menu.Item>
    )
  }, [route, geotabEnabled, geotabEquipmentId, generateGeotabUrl])

  const links = compact([
    geotabLink,
    samsaraLink,
  ])

  return (
    <DropdownMenu {...dropdownProps}>
      {links.length > 0 && (
        <>
          <MenuDivider>Links</MenuDivider>
          {links}
        </>
      )}
    </DropdownMenu>
  )
}
