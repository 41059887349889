import { Button, Popover, Space } from 'antd'
import LatitudeInput from 'components/form/inline-edit/LatitudeInput'
import LongitudeInput from 'components/form/inline-edit/LongitudeInput'
import { parseCoordinates } from 'helpers/parseCoordinates'

import { useEffect, useMemo, useState } from 'react'
import useMapControl from 'react-map-gl/dist/esm/components/use-map-control'

interface Coordinates {
  lat?: number
  lng?: number
}

interface CoordinatesInputButtonProps {
  visible?: boolean
  className?: string
  style?: React.CSSProperties
  onChange?: (coordinates: Coordinates) => void
}

export const CoordinatesInputButton = (props: CoordinatesInputButtonProps) => {
  const { containerRef } = useMapControl({})
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false)
  const [coords, setCoords] = useState<Coordinates>({})

  const setLat = (lat: any) => {
    setCoords((prev) => ({
      ...prev,
      lat,
    }))
  }

  const setLng = (lng: any) => {
    setCoords((prev) => ({
      ...prev,
      lng,
    }))
  }

  useEffect(() => {
    if (popoverVisible) {
      setCoords({})
    }
  }, [popoverVisible])

  const hidePopover = () => {
    setPopoverVisible(false)
  }

  const onUpdate = () => {
    if (props.onChange) {
      props.onChange(coords)
    }
    hidePopover()
  }

  const onPaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = evt?.clipboardData?.getData('text')
    if (!pastedValue) return

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const coords = parseCoordinates(pastedValue)
    if (coords) {
      evt.preventDefault()
      return setCoords({ lat: coords[0], lng: coords[1] })
    }
  }

  const {
    className,
  } = props

  const style: React.CSSProperties = useMemo(() => ({ ...props.style, position: 'absolute' }), [props.style])

  if (props.visible === false) return null

  return (
    <Popover
      title="Custom Latitude and Longitude"
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={(visible) => setPopoverVisible(visible)}
      content={(
        <Space direction="vertical" style={{ width: '100%' }}>
          <LatitudeInput
            style={{ width: '100%' }}
            value={coords.lat || ''}
            onChange={setLat}
            onPaste={onPaste}
          />

          <LongitudeInput
            style={{ width: '100%' }}
            value={coords.lng || ''}
            onChange={setLng}
            onPaste={onPaste}
          />

          <Space style={{ float: 'right' }}>
            <Button size="small" type="default" onClick={hidePopover} tabIndex={-1}>Cancel</Button>
            <Button size="small" type="primary" onClick={onUpdate}>Update</Button>
          </Space>
        </Space>
      )}
    >
      <div style={style} className={className}>
        <div className="mapboxgl-ctrl mapboxgl-ctrl-group" ref={containerRef}>
          <button
            className="mapboxgl-ctrl-icon mapboxgl-ctrl-geolocate"
            type="button"
            title="Enter Latitude and Longitude"
          >
            <span className="mapboxgl-ctrl-icon" aria-hidden="true" />
          </button>
        </div>
      </div>
    </Popover>
  )
}
