import { assign, compact } from 'lodash'
import React, { useMemo } from 'react'

import { useLocation } from 'react-router'

import { MenuProps } from 'antd'
import { Menu } from './Menu'

export interface LocationAwareMenuProps extends MenuProps {
  onlyOne?: boolean
  menu?: any
}

const childrenLinksToKeys = (children: React.ReactNode): Record<string, string[]> => {
  if (!children || children === true) return {}

  const collect: Record<string, string[]> = {}

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && 'props' in child) {
      const isGroup = (child.type as any)?.name === 'MenuItemGroup'
      const isSubMenu = (child.type as any)?.name === 'SubMenu'

      if (isGroup || isSubMenu) {
        assign(collect, childrenLinksToKeys(child.props.children))
      }

      const link = child.props?.link
      if (link && child.key) {
        if (!collect[link]) {
          collect[link] = []
        }
        collect[link].push(child.key.toString())
      }
    }
  })

  return collect
}

export const LocationAwareMenu: React.FC<LocationAwareMenuProps> = ({
  children, menu, onlyOne, ...rest
}) => {
  const MenuComponent = menu || Menu
  const location = useLocation()

  const linksToKeys = useMemo(() => childrenLinksToKeys(children), [children])

  const activeKey = useMemo(() => (
    compact(
      Object.entries(linksToKeys)
        .sort(([linkA], [linkB]) => (
          linkB.length - linkA.length
        )).flatMap(([link, keys]) => {
          if (location.pathname.startsWith(link)) {
            return keys
          }
          return null
        })
    )
  ), [linksToKeys, location.pathname])

  if (rest.mode === 'inline') {
    rest.getPopupContainer = () => document.createElement('div')
  }

  return (
    <MenuComponent
      selectedKeys={onlyOne ? activeKey.slice(0, 1) : activeKey}
      defaultOpenKeys={activeKey}
      {...rest}
    >
      {children}
    </MenuComponent>
  )
}
