import { Button, Collapse } from 'antd'
import { Panel } from 'components/common/Collapse/Panel'
import { WithIconTooltip } from 'components/common/WithIconTooltip'
import { AlignWithInputs } from 'components/form/common/Layout'
import { FormItem } from 'components/form/FormItem'
import { DurationPicker } from 'components/form/inline-edit/DurationPicker'
import { Input } from 'components/form/inline-edit/Input'
import { InputNumber } from 'components/form/inline-edit/InputNumber'
import { Switch } from 'components/form/inline-edit/Switch'
import TextArea from 'components/form/inline-edit/TextArea'
import { StartTimeInput } from 'components/form/OrderForm/common/StartTimeInput'
import { useFormState } from 'components/form/OrderForm/OrderFormState'
import { PourTypeSelect } from 'components/form/reference-selects/PourTypeSelect'
import { SupplierSelect } from 'components/form/reference-selects/SupplierSelect'
import { colors } from 'constants/colors'
import { estimatePourDuration } from 'helpers/estimatePourDuration'
import { useOrganization } from 'hooks/useOrganization'
import { compact, findLastIndex } from 'lodash'
import styled from 'styled-components'
import { FormValues } from '../FormValues'
import { OrderFormPanelProps } from './PanelProps'

type Schedule = NonNullable<FormValues['details']['schedule']>

const SCHEDULE_KEY = ['details', 'schedule']

const REPOSITION_DURATION_MINUTES = 30

const ENABLE_MULTIPOUR = false

const LinesOnSiteSwitch = styled(Switch).attrs({
  checkedChildren: 'Lines Present',
  unCheckedChildren: 'No Lines on Site',
})`
  min-width: 135px;
  margin-left: 10px;

  &.ant-switch-checked {
    font-weight: 600;
    background-color: ${colors.error};

    &.ant-switch-disabled {
      opacity: 1;
    }
  }

  .ant-switch-inner {
    padding: 0 3px;
  }
`

export const PoursPanel = (props: OrderFormPanelProps): JSX.Element => {
  const organization = useOrganization()
  const {
    form, branch, order, selectedSite, selectedEquipment, editing,
  } = useFormState()

  const addPour = () => {
    const currentValues: NonNullable<Schedule> = form.getFieldValue(SCHEDULE_KEY) || []
    const lastPourIndex = findLastIndex(currentValues as any, (entry: any) => entry?.step === 'pour')
    const lastPour = currentValues?.[lastPourIndex]

    if (!lastPour) return

    const newItems = [
      {
        step: 'reposition',
        duration: REPOSITION_DURATION_MINUTES * 60,
      },
      {
        step: 'pour',
        startTime: lastPour.startTime ? lastPour.startTime.clone().add(lastPour.duration || 0, 'seconds').add(REPOSITION_DURATION_MINUTES, 'minutes') : null,
        pour: {
          typeId: lastPour?.pour?.typeId,
        },
      },
    ]

    form.setFields([{
      name: SCHEDULE_KEY,
      value: [
        ...currentValues.slice(0, lastPourIndex + 1),
        ...newItems,
        ...currentValues.slice(lastPourIndex + 1),
      ],
    }])
  }

  const removePour = (scheduleEntryIndex: number) => {
    const currentValues: Schedule = form.getFieldValue(SCHEDULE_KEY) || []

    const isLastPour = currentValues.slice(scheduleEntryIndex + 1).findIndex(({ step }) => step === 'pour') === -1

    if (isLastPour) {
      // remove the last reposition + target removal pour
      form.setFields([{
        name: SCHEDULE_KEY,
        value: [
          ...currentValues.slice(0, scheduleEntryIndex - 1),
          ...currentValues.slice(scheduleEntryIndex + 1),
        ],
      }])
    } else {
      // remove the target removal pour + next reposition
      form.setFields([{
        name: SCHEDULE_KEY,
        value: [
          ...currentValues.slice(0, scheduleEntryIndex),
          ...currentValues.slice(scheduleEntryIndex + 2),
        ],
      }])
    }
  }

  const { key, ...propsWithoutKey } = props

  return (
    <>
      <FormItem
        noStyle
        shouldUpdate={(prevValues, curValues) => (
          prevValues?.details?.schedule?.length !== curValues?.details?.schedule?.length
        )}
      >
        {() => {
          const schedule: Schedule = form.getFieldValue(['details', 'schedule']) || []
          const pours = schedule.filter((scheduleEntry) => scheduleEntry?.step === 'pour')

          let pourNumber = 0

          return (
            <Collapse defaultActiveKey={(new Array(20).fill(true)).map((_, index) => `${key}-${index}`)} ghost>
              {compact(schedule.map((scheduleEntry, index) => {
                if (scheduleEntry.step !== 'pour') return
                // eslint-disable-next-line no-plusplus
                pourNumber++

                const namePrefix = ['details', 'schedule', index]

                return (
                  <Panel {...propsWithoutKey} header="Pour" key={`${key}-${index}`}>
                    <FormItem
                      label="Name / Lot"
                      name={[...namePrefix, 'pour', 'name']}
                    >
                      <Input
                        editing={editing}
                        placeholder="Lot or descriptive name"
                      />
                    </FormItem>
                    <FormItem
                      label="Pour Start Time"
                      name={[...namePrefix, 'startTime']}
                      rules={[
                        {
                          required: true,
                          message: 'Pour Start Time is required',
                        },
                      ]}
                    >
                      <StartTimeInput
                        editing={editing}
                        placeholder="Select Pour Start Time"
                        branchTimezone={branch?.timezone}
                        siteTimezone={selectedSite?.address?.timezone}
                      />
                    </FormItem>
                    <FormItem
                      label="Cubic Yards"
                      name={[...namePrefix, 'metrics', 'cubicYards']}
                    >
                      <InputNumber
                        editing={editing}
                        placeholder="Estimated Cubic Yards"
                        step={5}
                        onBlur={() => {
                          const thisKey = [...namePrefix, 'metrics', 'cubicYards']
                          const durationKey = [...namePrefix, 'duration']
                          if (!form.getFieldValue(durationKey)) {
                            form.setFields([{
                              name: durationKey,
                              value: estimatePourDuration({
                                yards: form.getFieldValue(thisKey),
                                equipment: selectedEquipment,
                                branch,
                                organizationId: organization?.id,
                              }),
                            }])
                          }
                        }}
                      />
                    </FormItem>
                    <FormItem
                      label="Pour Type"
                      name={[...namePrefix, 'pour', 'typeId']}
                    >
                      <PourTypeSelect
                        editing={editing}
                        placeholder="Select type of Pour"
                      />
                    </FormItem>
                    <FormItem
                      label="Duration"
                      name={[...namePrefix, 'duration']}
                    >
                      <DurationPicker
                        id={`order-form_details_schedule_pour_${index}_duration`}
                        editing={editing}
                        placeholder="Enter Pour Duration"
                        minuteStep={15}
                        unit="seconds"
                      />
                    </FormItem>

                    {pourNumber === 1 && (
                      <>
                        <FormItem
                          label="Supplier"
                          name={['order', 'supplierId']}
                        >
                          <SupplierSelect
                            allowClear
                            editing={editing}
                            placeholder="Select Supplier of Concrete"
                            resources={compact([order?.supplier])}
                          />
                        </FormItem>

                        <FormItem
                          label={(
                            <WithIconTooltip title="This indicates lines are present on site to the Operator.">
                              Lines on Site
                            </WithIconTooltip>
                          )}
                          name={['order', 'linesOnSite']}
                          valuePropName="checked"
                        >
                          <LinesOnSiteSwitch
                            editing={editing}
                          />
                        </FormItem>

                        <FormItem
                          label={(
                            <WithIconTooltip
                              title="Details entered here will be visible to the Customer on their Order Confirmation."
                              tooltipProps={{ overlayStyle: { minWidth: '280px' } }}
                            >
                              Custom Details
                            </WithIconTooltip>
                          )}
                          name={['order', 'bulletinToCustomer']}
                        >
                          <TextArea
                            editing={editing}
                            placeholder="Details visible to Customer"
                            autoSize={{ minRows: 1 }}
                          />
                        </FormItem>

                      </>
                    )}
                    {ENABLE_MULTIPOUR && editing && pours.length > 1 && (
                      <AlignWithInputs>
                        <Button onClick={() => { removePour(index) }}> Remove Pour #{pourNumber} </Button>
                      </AlignWithInputs>
                    )}
                  </Panel>
                )
              }))}
            </Collapse>
          )
        }}
      </FormItem>
      {
        ENABLE_MULTIPOUR && editing && (
          <div style={{ padding: '16px' }}>
            <Button style={{ width: '100%' }} onClick={addPour}>Add Another Pour</Button>
          </div>
        )
      }
    </>
  )
}
