import { ENV_IS_PRODUCTION } from 'constants/environment'
import { trim } from 'lodash'

import { PropsWithChildren, useEffect, useRef } from 'react'

import { useLocation } from 'react-router-dom'

const getCurrentVersion = async () => {
  const request = await fetch('/__version')
  if (!request.ok) {
    return undefined
  }
  return trim(await request.text())
}

const getLoadedVersion = () => (
  (document.head.querySelector('meta[name="version"]') as HTMLMetaElement)?.content
)

export const AutoUpdater = (props: PropsWithChildren<unknown>) => {
  if (!ENV_IS_PRODUCTION) {
    return <>{props.children}</>
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const loadedVersion = useRef(getLoadedVersion())
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const needsRefresh = useRef<boolean>(false)

  const checkVersion = async () => {
    const currentVersion = await getCurrentVersion()
    if (
      currentVersion &&
      currentVersion !== loadedVersion.current
    ) {
      needsRefresh.current = true
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const interval = setInterval(checkVersion, 10 * 60 * 1000)
    return () => clearInterval(interval)
  }, [])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // Prevents reloading during stateful route changes
    // ex: Duplicate Order / Convert Note to Order
    if (location.state) return

    if (needsRefresh.current) {
      window.location.reload()
      needsRefresh.current = false
    }
  }, [location.pathname])

  return <>{props.children}</>
}
