import { Col, Row, Tooltip } from 'antd'
import { AddItemButton, RemoveItemButton } from 'components/buttons'
import { Form, FormListProps } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'
import { AssignmentRoleSelect } from 'components/form/reference-selects/AssignmentRoleSelect'
import { AssignmentConfirmedIcon } from 'components/icons'
import { timeWithZone } from 'helpers/datetime'
import { useBranch } from 'hooks/useBranch'
import { compact } from 'lodash'
import { useEffect } from 'react'
import { Assignment } from 'schema'
import { RecursivePartial } from 'types'
import { OperatorSelect, OperatorSelectProps } from '../reference-selects/OperatorSelect'

interface OperatorAssignmentsFormListOptions {
  placeholder?: OperatorSelectProps['placeholder']
  editing?: boolean
  singleMode?: boolean
  hideRole?: boolean
  bordered?: boolean
  resources?: RecursivePartial<Assignment>[]
}

interface OperatorAssignmentsFormListProps extends Omit<FormListProps, 'name' | 'children'>, OperatorAssignmentsFormListOptions {
  name?: FormListProps['name']
}

interface FormListFieldsProps extends OperatorAssignmentsFormListOptions {
  listProps: Parameters<FormListProps['children']>
}

const AcknowledgedPsuedoField = (props: { value?: string, onChange?: any }) => {
  const branch = useBranch()

  if (!props.value) return null

  const ackTime = timeWithZone(props.value, branch?.timezone)

  return (
    <Tooltip title={`Acknowledged at ${ackTime.toFormat('M/d h:mma')}`}>
      <AssignmentConfirmedIcon />
    </Tooltip>
  )
}

const FormListFields = (props: FormListFieldsProps) => {
  const {
    editing,
    placeholder,
    singleMode,
    hideRole,
    bordered,
    resources,
    listProps,
  } = props

  const [fields, { add, remove }, { errors }] = listProps

  const showAdd = !singleMode
  const showRemove = !singleMode
  const showRole = !hideRole

  useEffect(() => {
    if (
      fields.length === 0
      && (editing || singleMode)
    ) {
      add()
    }
  }, [fields, editing, singleMode])

  return (
    <>
      <Form.ErrorList errors={errors} />
      {fields.map((field, index) => (
        <FormItem
          key={field.key}
          style={{ marginBottom: 0 }}
        >
          <Row gutter={5} wrap={false}>
            <Col style={{ flex: '1 1 auto', minWidth: '145px' }}>
              <FormItem
                listPrefix={field}
                name="operatorId"
              >
                <OperatorSelect
                  bordered={bordered}
                  editing={editing}
                  placeholder={placeholder}
                  resources={compact((resources || []).map((r) => r.operator))}
                />
              </FormItem>
            </Col>
            <Col style={{ flex: '0 0 auto' }}>
              <FormItem
                listPrefix={field}
                name="acknowledgedAt"
              >
                <AcknowledgedPsuedoField />
              </FormItem>
            </Col>
            {showRole && (
              <Col style={{ flex: '0 0 140px' }}>
                <FormItem
                  listPrefix={field}
                  name="role"
                >
                  <AssignmentRoleSelect
                    bordered={bordered}
                    editing={editing}
                    defaultActiveFirstOption={editing}
                    placeholder={false}
                    resources={compact((resources || []).map((r) => r.roleDetails))}
                  />
                </FormItem>
              </Col>
            )}
            {showRemove && (
              <Col style={{ flex: '0 0 auto', visibility: editing ? 'visible' : 'hidden' }}>
                <RemoveItemButton onClick={() => {
                  remove(index)

                  if (fields.length === 1) {
                    add()
                  }
                }}
                />
              </Col>
            )}
          </Row>
        </FormItem>
      ))}
      {editing && showAdd && (
        <div style={{ width: '100%', margin: `0 ${bordered ? 0 : 10}px` }}>
          <AddItemButton
            style={{ width: '100%' }}
            type="default"
            onClick={() => {
              if (fields.length >= 1) {
                add({ role: 'support' })
              }
            }}
          >
            Add Additional Operator
          </AddItemButton>
        </div>
      )}
    </>
  )
}

export const OperatorAssignmentsFormList = (props: OperatorAssignmentsFormListProps) => {
  const {
    name,
    prefixCls,
    rules,
    initialValue,
    ...options
  } = props

  return (
    <Form.List
      name={name || 'assignments'}
      prefixCls={prefixCls}
      rules={rules}
      initialValue={initialValue}
    >
      {(...listProps) => <FormListFields {...options} listProps={listProps} />}
    </Form.List>
  )
}
