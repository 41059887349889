import { compact } from 'lodash'

import { useSelectedBranch } from 'hooks/useBranch'
import { useFeatures } from 'hooks/useFeature'
import { useSamsaraUrl } from 'hooks/useSamsaraUrl'
import { useIsMobile, useViewportMode } from 'hooks/useViewportMode'
import { useSettingsMenuAndRoutes } from 'pages/Settings/useSettingsMenuAndRoutes'
import { useMemo } from 'react'

import {
  AreaChartOutlined, CalendarOutlined,
  ContainerOutlined, DesktopOutlined, DollarCircleOutlined, LogoutOutlined, MobileOutlined, PhoneOutlined, SettingOutlined, UserSwitchOutlined,
} from '@ant-design/icons'
import {
  AircallIcon, CompanyOutlineIcon, GeotabIcon, SamsaraIcon, SupportIcon,
} from 'components/icons'

import SubMenu from 'antd/lib/menu/SubMenu'
import { LocationAwareMenu } from 'components/menus/LocationAwareMenu'
import { MenuLink, MenuLinkProps } from 'components/menus/Menu'
import { SiderMenu } from 'components/menus/SiderMenu'
import { useGeotabUrl } from 'hooks/useGeotabUrl'
import { useSession } from 'hooks/useSession'
import { useSupportWidget } from 'hooks/useSupportWidget'

interface MenuConfigProps extends MenuLinkProps {
  feature?: string
  key?: React.Key
}

interface MenuGroupProps extends MenuConfigProps {
  group: string
}

type MenuConfig = MenuConfigProps | MenuGroupProps

export const PrimaryMenu = () => {
  const menu = usePrimaryMenu()
  const isMobile = useIsMobile()

  return isMobile ?
    <MobileMenu menu={menu} />
    :
    <Menu menu={menu} />
}

const Menu = ({ menu }: { menu: MenuConfig[] }) => (
  <LocationAwareMenu
    menu={SiderMenu}
    theme="light"
    mode="inline"
    onlyOne
  >
    {menu.map((config) => (
      'group' in config ?
        <SubMenu {...config} />
        :
        <MenuLink {...config} />
    ))}
  </LocationAwareMenu>
)

const MobileMenu: typeof Menu = (props) => {
  const { menu, ...rest } = props
  const { menu: settingsMenu } = useSettingsMenuAndRoutes()

  const mobileMenu = useMemo(() => {
    const cloned = menu.slice()

    const settingsIndex = cloned.findIndex((item) => item.key === '/settings')
    if (settingsIndex !== -1) {
      cloned[settingsIndex] = {
        group: 'Settings',
        title: 'Settings',
        ...cloned[settingsIndex],
        children: settingsMenu,
      }
    }

    return cloned
  }, [menu])

  return <Menu menu={mobileMenu} {...rest} />
}

const usePrimaryMenu = (): MenuConfig[] => {
  const [features] = useFeatures(...['integrations.aircall.enabled'])
  const { branchId } = useSelectedBranch()
  const { logout } = useSession()

  const { isMobile, isTabletBreakpoint, setViewportMode } = useViewportMode()

  const samsaraDashboardUrl = useSamsaraUrl()()
  const geotabDashboardUrl = useGeotabUrl()('#map')
  const supportWidget = useSupportWidget()

  const menu = useMemo(() => {
    const config: MenuConfig[] = compact([
      {
        link: `/branches/${branchId}/schedule`,
        icon: <CalendarOutlined />,
        children: <>
          Schedule
        </>,
      },
      {
        link: '/orders',
        icon: <ContainerOutlined />,
        children: <>
          Orders
        </>,
      },
      {
        link: '/invoices',
        icon: <DollarCircleOutlined />,
        children: <>
          Invoices
        </>,
      },
      {
        link: '/insights',
        icon: <AreaChartOutlined />,
        children: <>
          Insights
        </>,
      },
      {
        link: '/customers',
        icon: <CompanyOutlineIcon />,
        children: <>
          Customers
        </>,
      },
      {
        link: '/contacts',
        icon: <PhoneOutlined />,
        children: <>
          Contacts
        </>,
      },
      {
        link: '/team',
        icon: <UserSwitchOutlined />,
        children: <>
          Team
        </>,
      },
      {
        link: 'https://phone.aircall.io/',
        target: '_blank',
        preventNavigation: true,
        icon: <AircallIcon />,
        feature: 'integrations.aircall.enabled',
        children: <>
          Aircall
        </>,
        onClick: () => {
          window.open('https://phone.aircall.io/', 'Aircall Phone', 'width=376,height=659')
        },
      },
      samsaraDashboardUrl && {
        link: samsaraDashboardUrl,
        target: '_blank',
        icon: <SamsaraIcon />,
        children: <>
          Samsara
        </>,
      },
      geotabDashboardUrl && {
        link: geotabDashboardUrl,
        target: '_blank',
        icon: <GeotabIcon />,
        children: <>
          Geotab
        </>,
      },
      {
        link: '/settings',
        icon: <SettingOutlined />,
        children: <>
          Settings
        </>,
      },
      isMobile ? {
        icon: <DesktopOutlined />,
        onClick: () => {
          setViewportMode('desktop')
        },
        children: <>
          Desktop App
        </>,
      } : (isTabletBreakpoint && {
        icon: <MobileOutlined />,
        onClick: () => {
          setViewportMode('mobile')
        },
        children: <>
          Mobile App
        </>,
      }),
      {
        icon: <SupportIcon />,
        onClick: () => {
          supportWidget.toggle()
        },
        children: <>
          Support
        </>,
      },
      {
        link: '/auth/logout',
        onClick: () => {
          logout()
        },
        icon: <LogoutOutlined rotate={180} />,
        children: <>
          Logout
        </>,
      },
    ])

    return compact(
      config.map((props, index) => {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { feature, ...rest } = props

        if (feature && features[feature] !== true) {
          return null
        }

        if (!props.key) {
          props.key = props.link || `index-${index}`
        }

        return props
      })
    )
  }, [branchId, features, samsaraDashboardUrl])

  return menu
}
