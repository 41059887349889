import { compact } from 'lodash'
import { useOrganization } from './useOrganization'

const URL = 'https://api.cloudinary.com/v1_1/vulcan/image/upload'

type Preset = 'logo' | 'credential' | 'document' | 'other'

const PRESETS: Record<Preset, string> = {
  logo: 'etgvd8lnbJykpr2Q4qrKyzRM',
  credential: 'vxSc0yddb2Kbqid9bnqTOpal',
  document: 'uv4obdiB1PpsT568bnWf33mA',
  other: 'uAmc2gor3xQjA0iY6ZwyJqAs',
}

export const objectToCloudinaryContext = (obj?: Record<any, any>): string | undefined => {
  if (!obj) return undefined

  const kvJoined = compact(
    Object.entries(obj).map(([k, v]) => {
      if (!v) return
      return [k, v].join('=')
    })
  )

  if (kvJoined.length === 0) {
    return undefined
  }

  return kvJoined.join('|')
}

export const useCloudinaryUploadParams = (name?: keyof typeof PRESETS) => {
  const uploadPreset = PRESETS[name || 'other'] || PRESETS.other
  const organization = useOrganization()

  const data = (args: Record<any, any> = {}) => {
    const { context, ...rest } = args

    return {
      ...rest,
      upload_preset: uploadPreset,
      context: objectToCloudinaryContext({
        ...context,
        tid: organization?.id,
      }),
    }
  }

  return { url: URL, data }
}
