import React, { useMemo } from 'react'

import { formatPhone } from 'helpers/formatPhone'
import styled from 'styled-components'

import {
  Operator,
  Query,
  QueryOperatorsArgs,
} from 'schema'

import { useQuery } from '@apollo/client'
import { GET_OPERATORS } from 'gql/operators'

import {
  generatePath, Link, Route, useHistory, useParams,
  useRouteMatch,
} from 'react-router-dom'

import {
  Button, Col, Row, Typography,
} from 'antd'
import AntTable, { ColumnsType, TableProps } from 'antd/lib/table/Table'

import { OperatorDrawer } from 'components/drawers/OperatorDrawer'
import { ErrorMessage } from 'components/ErrorMessage'
import { OperatorFormProps } from 'components/form/OperatorForm'
import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'
import { byFieldAsc, byFieldDesc, byPersonNameAsc } from 'helpers/sortting'
import { activeColumn } from 'helpers/table/activeColumn'
import { Actions, Body, Header } from 'hooks/useContentLayout'
import { PageContent } from 'layouts/Content/PageContent'

const { Paragraph, Title } = Typography

const Table: React.FC<TableProps<any>> = styled(AntTable)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

const PhoneCol = styled.div`
  white-space: nowrap;
`

const columns: ColumnsType<Operator> = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    sorter: {
      compare: byFieldAsc('firstName'),
    },
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    sorter: {
      compare: byFieldAsc('lastName'),
    },
  },
  {
    title: 'Phone',
    dataIndex: 'phones',
    render: (_, { phones }) => phones.map((phone, i) => (
      <PhoneCol key={i}>
        {formatPhone(phone.number)}
      </PhoneCol>
    )),
  },
  activeColumn(),
]

const Index = () => {
  const history = useHistory()
  const match = useRouteMatch()

  const { loading, data, error } = useQuery<Query, QueryOperatorsArgs>(GET_OPERATORS)

  const operators = useMemo(() => (
    (data?.operators || []).slice().sort((a, b) => (
      byFieldDesc('active')(a, b) || byPersonNameAsc(a, b)
    ))
  ), [data?.operators])

  const onRow: TableProps<Operator>['onRow'] = (record: Operator, _rowIndex: number | undefined) => {
    const rowLink = `${match.url}/${record.id}`
    return {
      onClick: () => {
        history.push(rowLink)
      },
    }
  }

  return (
    <PageContent>
      <Header>
        <Title level={2}>
          Operators
        </Title>
      </Header>

      <Actions>
        <Link to={`${match.url}/new`}>
          <Button type="primary">
            Add New Operator
          </Button>
        </Link>
      </Actions>

      <Body>
        {error && (
          <ErrorMessage>
            <Paragraph>{error.message}</Paragraph>
          </ErrorMessage>
        )}
        <Table
          columns={columns}
          rowKey="id"
          dataSource={operators}
          pagination={false}
          onRow={onRow}
          locale={{
            emptyText: loading ? 'Loading' : undefined,
          }}
          bordered
        />
      </Body>
    </PageContent>
  )
}

const Show = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id: urlId, edit: urlEdit } = useParams<{ id: string, edit?: string }>()

  const isNew = urlId === 'new'
  const id = isNew ? undefined : parseInt(urlId)
  const basePath = history.location.pathname.slice(0, match.path.indexOf('/:id'))

  const props: OperatorFormProps = {
    id,
    editing: isNew || urlEdit === 'edit',
    onSubmission: (operator, action) => {
      if (action === 'create' && operator?.id) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: `${basePath}/${operator?.id}`,
        })
      }
    },
    onCancel: () => {
      if (isNew) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: basePath,
        })
      }
    },
  }

  return <OperatorDrawer key={`operator-${id}`} {...props} />
}

const OperatorPage = (_args: any) => {
  const history = useHistory()
  const match = useRouteMatch()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  return (
    <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
      <Col flex="auto" style={{ minWidth: 0 }}>
        <Route path={match.path} component={Index} />
      </Col>
      <RoutableDrawer showClose={false} onClose={() => { history.replace(basePath) }} width={475}>
        <Route path={`${match.path}/:id/:edit?`} component={Show} />
        <Route path={match.path} hideOnMobile>
          <div className="padded">
            Select an Operator to view and edit.
          </div>
        </Route>
      </RoutableDrawer>
    </Row>
  )
}

export default OperatorPage
