import colors from 'constants/colors'
import styled from 'styled-components'

import { Layout as AntLayout, Typography } from 'antd'
import { LocationAwareMenu as LocationAwareMenuUnstyled } from 'components/menus/LocationAwareMenu'
import { SiderMenu } from 'components/menus/SiderMenu'
import { Redirect, Switch, useRouteMatch } from 'react-router-dom'

import { useIsMobile } from 'hooks/useViewportMode'
import { useSettingsMenuAndRoutes } from './useSettingsMenuAndRoutes'

const Content = styled(AntLayout.Content)`
  background-color: ${colors.backgroundPrimary};
  max-height: 100vh;
  overflow: auto;
`

const Sider = styled(AntLayout.Sider).attrs({
  theme: 'light',
  width: '140px',
})`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  border-right: 1px solid ${colors.borderPrimary};
`

const LocationAwareMenu = styled(LocationAwareMenuUnstyled)`
  .ant-menu-item-group-title {
    text-transform: uppercase;
    font-size: 0.85em;
    font-weight: 600;
    margin-top: 10px;
  }
`

const { Title } = Typography

const VerticalFlexSider = styled(Sider)`
  > .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    > * {
      flex: 0 0 auto;
    }
  }
`

const TermsAndPrivacy = styled.div.attrs({
  children: <>
    <a href="https://cretesuite.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>
    <a href="https://cretesuite.com/privacy-policy" target="_blank" rel="noreferrer">Privacy</a>
  </>,
})`
  margin-top: auto;
  margin-bottom: 10px;
  justify-self: flex-end;
  text-align: center;

  a {
    font-size: 0.7em;
    color: ${colors.fontPrimary};
  }

  a:first-child:after {
    content: "\\25CF";
    font-size: 0.4em;
    padding: 0 5px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
`

export const SettingsPage = () => {
  const match = useRouteMatch()
  const isMobile = useIsMobile()
  const { menu, routes } = useSettingsMenuAndRoutes(match.path)
  const defaultPath = routes[0]?.props?.path

  return (
    <>
      {!isMobile && (
        <VerticalFlexSider>
          <Title level={5} style={{ margin: '10px 0 5px 15px' }}>Settings</Title>
          <LocationAwareMenu menu={SiderMenu} mode="inline" onlyOne>
            {menu}
          </LocationAwareMenu>

          <TermsAndPrivacy />
        </VerticalFlexSider>
      )}
      <Content>
        <Switch>
          {routes}
          {defaultPath && <Redirect to={defaultPath} />}
        </Switch>
      </Content>
    </>
  )
}

export default SettingsPage
