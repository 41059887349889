import { ContactDrawer } from 'components/drawers/ContactDrawer'
import { ContactSelect, ContactSelectProps } from './ContactSelect'
import { SelectWithAddForm } from './SelectWithAddForm'

export const ContactSelectWithAdd = (props: Omit<ContactSelectProps, 'dropdownRender'>) => (
  SelectWithAddForm({
    name: 'Contact',
    select: ContactSelect,
    selectProps: props,
    form: ContactDrawer,
    formProps: {
      customerId: props.customerId,
    },
  })
)
