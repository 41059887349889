import { CustomerSitesArgs, Query, Site } from 'schema'

import { useApolloClient } from '@apollo/client'
import { GET_SITES_FOR_CUSTOMER, SITE_FULL_FRAGMENT } from 'gql/sites'

import { useEffect, useState } from 'react'

export const useSite = (customerId: number | undefined | null, siteId: number | undefined | null): Site | null => {
  const apollo = useApolloClient()
  const [site, setSite] = useState<Site | null>(null)

  useEffect(() => {
    if (!customerId || !siteId) {
      setSite(null)
      return
    }

    const cached: Site | null = apollo.readFragment({
      id: `Site:${siteId.toString(10)}`,
      fragment: SITE_FULL_FRAGMENT,
      fragmentName: 'SiteFields',
    })

    if (cached && cached.customerId === customerId) {
      setSite(cached)
      return
    }

    apollo.query<Query, CustomerSitesArgs & { customerId: number }>({
      query: GET_SITES_FOR_CUSTOMER,
      fetchPolicy: 'cache-first',
      variables: {
        customerId,
        where: {
          id: {
            equals: siteId,
          },
        },
      },
    }).then(({ data }) => {
      setSite(data?.customers?.[0]?.sites?.[0] || null)
    })
  }, [customerId, siteId])

  return site
}
