import React, { useMemo } from 'react'
import { Order, RouteWaypointStatus } from 'schema'

import colors from 'constants/colors'
import { formatRelativeDateTime, formatRelativeTime, timeWithZone } from 'helpers/datetime'
import { byFieldPathAsc } from 'helpers/sortting'
import { compact } from 'lodash'
import { DateTime } from 'luxon'
import styled from 'styled-components'

import { Col } from 'antd'
import { FullName } from 'components/common/FullName'
import { OperatorList } from 'components/common/OperatorList'
import { Tag } from 'components/common/Tag'
import { TagsList } from 'components/common/TagsList'
import { ResourceTag } from 'components/tags/ResourceTag'
import { dateToSlug } from 'helpers/url'
import { useHistory } from 'react-router-dom'

import { ClockCircleOutlined } from '@ant-design/icons'
import {
  AssignmentConfirmedIcon, ExitOutlineIcon, MapMarkerIcon, NavigateArrowIcon,
} from 'components/icons'
import { withSystemTags } from 'helpers/order/withSystemTags'
import { customerContactTitle } from '../events/OrderEvent'
import { useSchedulerState } from '../SchedulerState'
import { Item, ItemProps, ItemTitle } from './Item'

export interface OrderItemProps extends ItemProps {
  order: Order
}

const StatusTag = styled(Tag)`
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  border-radius: 6px;
  width: 85px;
  padding: 3px 6px;
  overflow-wrap: normal;
  height: auto;
  hyphens: auto;
  white-space: normal;
  margin-right: 0;
`

const useOrderHumanTime = (order: Order) => {
  const { selectedDate } = useSchedulerState()

  if (!order.dateOfService) return null

  const timezone = (
    order?.site?.address?.timezone ||
    order?.branch?.timezone ||
    DateTime.local().zoneName
  )
  const dateOfService = DateTime.fromISO(order.dateOfService).setZone(timezone)

  return formatRelativeDateTime(dateOfService, selectedDate, { trimTopOfHour: true })
}

const TimeBadge = ({ order }: Pick<OrderItemProps, 'order'>) => {
  const time = useOrderHumanTime(order)
  if (!time) return null

  const statusColor = order.statusDetails?.color || colors.emptyStatus

  return (
    <StatusTag color={statusColor}>
      {time}
    </StatusTag>
  )
}

const OrderTitle = styled(ItemTitle)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ItemContentCol = styled(Col)`
  width: 100%;
  overflow: hidden;

  ${TagsList} {
    margin-top: 5px;
  }
`

const ItemTimeCol = styled(({ children, order, className }: React.PropsWithChildren<{ order: Order, className?: string }>) => {
  const { selectedDate, branch } = useSchedulerState()
  const history = useHistory()

  const wrappedChildren = React.Children.map(children, (child) => <div>{child}</div>)

  return (
    <Col
      className={className}
      onClick={(evt) => {
        const routeId = order?.route?.id
        if (!branch) return
        if (!routeId) return

        history.push(`/branches/${branch.id}/schedule/${dateToSlug(selectedDate)}/route/${routeId}`)

        evt.preventDefault()
        evt.stopPropagation()
      }}
    >
      {wrappedChildren}
    </Col>
  )
})`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  align-self: stretch;
  justify-content: space-around;
  margin: 3px 5px 3px 0;
`

const DepartureStatus = styled(({ order, className }: { order: Order, className?: string }) => {
  const { selectedDate, timezone } = useSchedulerState()

  const route = order?.route
  if (!route) return null

  const waypoints = route.waypoints || []
  const orderWpIndex = waypoints.findIndex((wp) => wp.order?.id === order.id)
  const wp = waypoints[orderWpIndex]
  const lastWp = waypoints[orderWpIndex - 1]

  if (!wp) return null

  let color: string | null | undefined
  let icon: JSX.Element | undefined
  let time: DateTime | undefined

  if ([RouteWaypointStatus.InProgress, RouteWaypointStatus.Completed].includes(wp.status) && wp.actualArrivalTime) {
    time = timeWithZone(wp.actualArrivalTime, wp.address.timezone || timezone)
    color = wp.arrivalPerformance && colors.performance[wp.arrivalPerformance]
    icon = <MapMarkerIcon />
  } else if (wp.status === RouteWaypointStatus.EnRoute && wp.estimatedArrivalTime) {
    time = timeWithZone(wp.estimatedArrivalTime, wp.address.timezone || timezone)
    color = wp.arrivalPerformance && colors.performance[wp.arrivalPerformance]
    icon = <NavigateArrowIcon style={{ fontSize: '0.9em' }} />
  } else if (lastWp && lastWp.status === RouteWaypointStatus.Completed && lastWp.actualDepartureTime) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    time = timeWithZone(lastWp.scheduledDepartureTime!, lastWp.address.timezone || timezone)
    icon = <ExitOutlineIcon style={{ fontSize: '1em', verticalAlign: 'text-top', marginTop: '1px' }} />
    color = lastWp.departurePerformance && colors.performance[lastWp.departurePerformance]
  } else if (lastWp) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    time = timeWithZone(lastWp.scheduledDepartureTime!, lastWp.address.timezone || timezone)
    icon = <ClockCircleOutlined />
    color = colors.greyscale65
  }

  if (!time) return null

  return (
    <div
      className={className}
      style={{ color: color || colors.greyscale65 }}
    >
      {icon} {formatRelativeTime(time, selectedDate, { trimTopOfHour: true })}
    </div>
  )
})`
  font-size: 0.9em;
  text-align: center;
  font-weight: bold;
  padding: 3px 0px;

  .anticon {
    font-size: 0.8em;
    vertical-align: baseline;
    margin-left: -5px;
    margin-right: 2px;
  }
`

export const OrderItem = (props: OrderItemProps) => {
  const { order, ...rest } = props

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const contact = (order.contacts.find((contact) => contact.default) || order.contacts[0])?.contact

  const equipmentName = compact([order?.billable?.equipment?.displayName, order?.planned?.equipment?.displayName])[0]

  const title = compact([customerContactTitle(order?.customer, contact), equipmentName]).join(' | ')

  const operatorsLine = () => {
    const operatorsSorted = [...(order?.assignments || [])].sort(byFieldPathAsc(['roleDetails', 'sort']))

    return (
      <OperatorList>
        {operatorsSorted?.map((assignment, i) => (
          <span key={i}>
            <FullName {...assignment.operator} />
            {assignment.acknowledgedAt && <AssignmentConfirmedIcon />}
          </span>
        ))}
      </OperatorList>
    )
  }

  const tags = useMemo(() => withSystemTags(order), [order])

  return (
    <Item {...rest}>
      <ItemTimeCol order={order}>
        <TimeBadge order={order} />
        <DepartureStatus order={order} />
      </ItemTimeCol>
      <ItemContentCol>
        <OrderTitle>{title}</OrderTitle>
        <div>{order.site?.name}</div>
        <div>{operatorsLine()}</div>
        <TagsList minified>
          {tags.map((tag) => (
            <ResourceTag key={tag.id} tag={tag} />
          ))}
        </TagsList>
      </ItemContentCol>
    </Item>
  )
}
