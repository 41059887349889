import React from 'react'
import styled from 'styled-components'

import { MoreOutlined } from '@ant-design/icons'
import { Dropdown, DropDownProps, MenuProps } from 'antd'
import { Menu } from './Menu'

const StyledMenu = styled(Menu)`
  margin-right: 10px;

  .ant-dropdown-menu-item-group-title {
    font-size: 0.8rem;
  }
`

const ButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 10;
  font-size: 16px;
`

export type DropdownMenuProps = React.PropsWithChildren<Omit<DropDownProps, 'overlay'> & {
  style?: React.CSSProperties
  menuProps?: MenuProps
}>

export const DropdownMenu = (props: DropdownMenuProps) => {
  const {
    menuProps, style, children, ...args
  } = props

  if (!children) return null

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomLeft"
      {...args}
      overlay={(
        <StyledMenu {...menuProps}>
          {children}
        </StyledMenu>
      )}
    >
      <ButtonWrapper style={style}>
        <MoreOutlined />
      </ButtonWrapper>
    </Dropdown>
  )
}
