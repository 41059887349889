import { Typography } from 'antd'
import { Tag as AntTag, TagProps } from 'components/common/Tag'
import { Tag as TagModel } from 'schema'
import styled from 'styled-components'
import { SetRequired } from 'types'

const Tag = styled(AntTag)`
  &:hover {
    opacity: 1;
  }

  &.block {
    display: block;
    margin-right: 0px;
  }

  &.checkable {
    cursor: pointer;
    border-width: 0;
    border-left-width: 4px;
    padding-right: 5px;
  }

  &.checkable.notchecked {
    border-radius: 0px;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.85);
  }
`

export interface ResourceTagProps extends TagProps {
  tag: SetRequired<Partial<TagModel>, 'name'>
  /**
  * use `display: block` for tag
  */
  block?: boolean
  /**
  * is this tag checkable/uncheckable
  */
  checkable?: boolean
  /**
  * when `chekable=true`, is this tag checked
  */
  checked?: boolean
}

export const ResourceTag = (args: ResourceTagProps) => {
  const {
    tag, checked, block, checkable, ...rest
  } = args
  const props: TagProps = rest

  const classes: string[] = args.className ? [args.className] : []

  if (checkable) {
    props.closable = false
    classes.push(
      'checkable',
      checked ? 'checked' : 'notchecked'
    )
  }

  if (block) {
    classes.push('block')
  }

  if (classes.length > 0) {
    props.className = classes.join(' ')
  }

  return (
    <Tag color={tag.color || undefined} {...props}>
      <Typography.Text ellipsis={{ tooltip: tag.name }} style={{ color: 'inherit' }}>
        {tag.name}
      </Typography.Text>
    </Tag>
  )
}
