import { Popover, PopoverProps } from 'antd'
import { isNil } from 'lodash'
import { useState } from 'react'
import { RequestLocationViaSmsForm, RequestLocationViaSmsFormProps } from './RequestLocationViaSmsForm'

type RequestLocationViaSmsPopoverProps = RequestLocationViaSmsFormProps & Omit<PopoverProps, 'content'>

export const RequestLocationViaSmsPopover = (props: RequestLocationViaSmsPopoverProps) => {
  const {
    customerId, onSubmission, submitContent, onCancel, ...rest
  } = props
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false)
  const visible = isNil(rest.visible) ? popoverVisible : rest.visible

  const popoverProps: PopoverProps = {
    title: 'Request Site Location via SMS',
    placement: 'topLeft',
    ...rest,
    visible,
    content: <div style={{ maxWidth: '240px' }}>
      <RequestLocationViaSmsForm
        customerId={customerId}
        submitContent={submitContent}
        onCancel={(...args) => {
          setPopoverVisible(false)
          if (onCancel) onCancel(...args)
        }}
        onSubmission={(...args) => {
          setPopoverVisible(false)
          if (onSubmission) onSubmission(...args)
        }}
      />
    </div>,
    children: (
      <div onClick={() => setPopoverVisible(!visible)}>
        {props.children}
      </div>
    ),
  }

  return <Popover {...popoverProps} />
}
