import { gql } from '@apollo/client'
import {
  Col, Row, Space, Typography,
} from 'antd'
import { Button } from 'components/buttons'
import { Skeleton } from 'components/common/Skeleton'
import { OrderScheduleLink } from 'components/links/OrderScheduleLink'
import { OrderStatusUpdate } from 'components/order/OrderStatusUpdate'
import { ManageQuickbooksInvoiceButton } from 'components/quickbooks/ManageQuickbooksInvoiceButton'
import { ResourceTag } from 'components/tags/ResourceTag'
import { wrapIn } from 'helpers/formatting'
import { compact, isEmpty } from 'lodash'
import React, { Suspense } from 'react'
import styled from 'styled-components'
import { useOrderBillingState } from './OrderBillingState'
import { OrderShowHeaderFragmentFragment } from './__generated__/Header'

const DownloadWorkTicketPDFButton = React.lazy(
  () => import('pdfs/WorkTicket').then((module) => ({ default: module.DownloadWorkTicketPDFButton }))
)

gql`
  fragment OrderShowHeaderFragment on Order {
    id
    name
    status
    dateOfServiceLocal
    branch {
      id
    }
    additionalIds {
      key
      value
    }
    cancellationReasonDetails {
      slug
      name
    }
    integrations {
      quickbooks {
        invoice {
          id
        }
      }
    }
    statusDetails {
      slug
      name
      color
    }
    tags {
      id
      name
      color
    }
  }
`

export const Header = () => (
  <>
    <Row gutter={[16, 16]}>
      <Col flex="auto" style={{ margin: 0, minHeight: '36px' }}>
        <TitleSection />
      </Col>
      <Col>
        <ActionsSection />
      </Col>
    </Row>
    <TagsSection />
  </>
)

const TitleSection = () => {
  const { loading, order } = useOrderBillingState<OrderShowHeaderFragmentFragment>()

  return (
    <>
      <Typography.Title level={3} style={{ margin: '0 10px 2px 0' }}>
        {
          loading ?
            <Skeleton active loading title={{ width: 200 }} paragraph={false} />
            :
            order?.name || 'Order not found'
        }
        {order && (
          <OrderStatusUpdate
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id={order.id!}
            orderStatus={order.status}
            resources={compact([order.statusDetails])}
            showSearch={false}
            style={{
              marginLeft: 10,
              marginTop: 2,
              verticalAlign: 'top',
            }}
            placeholder=""
            iconOnly
          />
        )}
      </Typography.Title>

      {order?.status === 'cancelled' && (
        <div style={{ fontSize: '1.1em', marginTop: 8 }}>
          <strong>Cancelled</strong> {wrapIn(': ', '', order.cancellationReasonDetails?.name)}
        </div>
      )}
    </>
  )
}

const ButtonsLoadingWrapper = styled.div<{ loading?: boolean }>`
  ${(props) => props.loading && `
    pointer-events: none !important;

    .ant-btn {
      color: rgba(0,0,0,.25) !important;
      border-color: #d9d9d9 !important;
      background: #f5f5f5 !important;
      text-shadow: none !important;
      box-shadow: none !important;

      .anticon {
        color: rgba(0,0,0,.25) !important;
      }
    }
  `}
`

const ActionsSection = () => {
  const {
    loading, order, form, isEditing, updateOrder, autofillValues,
  } = useOrderBillingState<OrderShowHeaderFragmentFragment>()

  const orderId = order?.id || 0

  if (!loading && !orderId) {
    return null
  }

  const hasInvoice = !isEmpty(order?.additionalIds?.find(({ key }) => key === 'invoice')?.value)

  return (
    <ButtonsLoadingWrapper>
      <Space wrap>
        <div style={{ display: 'none' }}>
          <ManageQuickbooksInvoiceButton
            orderId={orderId}
            beforeSubmit={async () => {
              const didAutoFill = autofillValues('billable')
              const shouldSave = didAutoFill || isEditing('billable')
              if (shouldSave) {
                await form.submit()
              }
            }}
            onSubmission={async () => {
              if (['reviewed', 'cancelled'].includes(order?.status || '')) return
              await updateOrder({
                status: 'reviewed',
              })
            }}
          />
        </div>

        {hasInvoice ?
          <Button href={`/invoices/${orderId}`}>View Invoice</Button>
          :
          <Button href={`/invoices/new?orderId=${orderId}`}>Create Invoice</Button>}

        <Suspense fallback={(
          <Button>
            Download Work Ticket
          </Button>
        )}
        >
          <DownloadWorkTicketPDFButton orderId={orderId} />
        </Suspense>

        <OrderScheduleLink order={order || {}}>
          <Button>View On Schedule</Button>
        </OrderScheduleLink>
      </Space>
    </ButtonsLoadingWrapper>
  )
}

const TagsSection = () => {
  const { order } = useOrderBillingState<OrderShowHeaderFragmentFragment>()

  return (
    <Space style={{ marginTop: 10 }}>
      {order?.tags?.map((tag) => (
        <ResourceTag block tag={tag as any} />
      ))}
    </Space>
  )
}
