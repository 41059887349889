import { DatePicker, DatePickerProps, DatePickerRef } from 'components/form/inline-edit/DatePicker'
import React from 'react'

export type DateTimePickerProps = Omit<DatePickerProps, 'showTime'> & {
  showTime?: Exclude<DatePickerProps['showTime'], boolean>
}

export const DateTimePicker = React.forwardRef<DatePickerRef, DateTimePickerProps>((props, ref) => {
  const args: DatePickerProps = {
    showNow: false,
    ...props,
  }

  args.showTime = {
    format: 'hh:mm a',
    minuteStep: 5,
    ...props.showTime,
  }

  if (args.format === undefined) {
    args.format = `MM/DD ${args.showTime.format}`
  }

  return <DatePicker data-lpignore="true" {...args} ref={ref} />
})

export default DatePicker
