import { Button as AntButton } from 'antd'
import { ButtonProps as AntButtonProps } from 'antd/lib/button'
import {
  AddItemIcon, CloseIcon,
  LinkExternalIcon, NextIcon, PreviousIcon, RemoveItemIcon,
} from 'components/icons'
import { compact } from 'lodash'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components'

export type ButtonProps = Omit<AntButtonProps, 'type'> & {
  type?: AntButtonProps['type'] | 'plain'
  noStyle?: boolean
}

const CustomButton = styled(AntButton)`
  &.plain {
    background: transparent;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
  &.noStyle {
    background: unset;
    padding: unset;
    margin: unset;
    border: unset;
    height: unset;
  }
`

export const Button: React.FC<ButtonProps> = ({ href, ...args }) => {
  const { type, noStyle, ...rest } = args
  const props: AntButtonProps = rest
  if (type === 'plain') {
    props.type = 'text'
    props.className = compact([props.className, 'plain']).join(' ')
  } else {
    props.type = type
  }
  if (noStyle) {
    props.className = compact([props.className, 'noStyle']).join(' ')
  }

  const btn = <CustomButton {...props} />

  if (href) {
    return <Link to={href}> {btn} </Link>
  }

  return btn
}

export const RemoveItemButton: React.FC<Omit<ButtonProps, 'icon'>> = (props) => (
  <Button type="link" icon={<RemoveItemIcon />} {...props} />
)

export const AddItemButton: React.FC<Omit<ButtonProps, 'icon'>> = (props) => (
  <Button type="link" icon={<AddItemIcon />} {...props} />
)

export const PreviousButton: React.FC<Omit<ButtonProps, 'icon'>> = (props) => (
  <Button icon={<PreviousIcon />} {...props} />
)

export const NextButton: React.FC<Omit<ButtonProps, 'icon'>> = (props) => (
  <Button icon={<NextIcon />} {...props} />
)

export const LinkExternalButton: React.FC<Omit<ButtonProps, 'icon'>> = (props) => (
  <Button type="text" icon={<LinkExternalIcon />} {...props} />
)

export const CloseButton: React.FC<Omit<ButtonProps, 'icon'>> = (props) => (
  <Button type="plain" icon={<CloseIcon />} {...props} />
)

export const AdditionalDataButton: React.FC<LinkProps> = ({ children, ...props }) => (
  <Link {...props}>
    <Button style={{ width: '100%', marginBottom: '15px' }}>
      {children}
    </Button>
  </Link>
)
