import { gql } from '@apollo/client'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import React from 'react'
import { AssignmentRole } from 'schema'

export type AssignmentRoleSelectProps = ReferenceSelectProps<AssignmentRole>

const GET_ASSIGNMENT_ROLES = gql`
  query GetAssignmentRoles($where: QueryAssignmentRolesWhereInput) {
    assignmentRoles(where: $where) {
      slug
      name
      sort
    }
  }
`

const defaults: Partial<AssignmentRoleSelectProps> = {
  defaultActiveFirstOption: true,
}

export const AssignmentRoleSelect: React.FC<AssignmentRoleSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Assignment Role',
  queryField: 'assignmentRoles',
  gql: GET_ASSIGNMENT_ROLES,
  defaults,
  fetchPolicy: 'cache-first',
}, props))

export default AssignmentRoleSelect
