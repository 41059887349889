import { useState } from 'react'
import { useHistory } from 'react-router'

import { Customer } from 'schema'

import { MergeCustomerModal } from 'components/modals/MergeCustomersModal'
import { DropdownMenu, DropdownMenuProps } from './DropdownMenu'
import { Menu, MenuDivider } from './Menu'

export interface CustomerActionsMenuProps extends Omit<DropdownMenuProps, 'dropdownProps' | 'menuProps'> {
  customerId: Customer['id'] | undefined
}

export const CustomerActionsMenu = ({ customerId, ...dropdownProps }: CustomerActionsMenuProps): JSX.Element | null => {
  const history = useHistory()
  if (!customerId) return null

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showMergeCustomerModal, setShowMergeCustomerModal] = useState<boolean>(false)
  return (
    <>
      <DropdownMenu {...dropdownProps}>
        <MenuDivider>Actions</MenuDivider>
        <Menu.Item key="actions.mergeCustomer" onClick={() => setShowMergeCustomerModal(true)}>Merge Customer</Menu.Item>
      </DropdownMenu>

      <MergeCustomerModal
        visible={showMergeCustomerModal}
        customerId={customerId}
        onCancel={() => setShowMergeCustomerModal(false)}
        onOk={() => {
          history.go(0)
        }}
      />
    </>
  )
}
