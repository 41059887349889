import {
  ChangeEvent, useEffect, useMemo, useRef, useState,
} from 'react'

import { formatPhone } from 'helpers/formatPhone'
import { byFieldAsc, byFieldDesc, byPersonNameAsc } from 'helpers/sortting'
import {
  compact, isArray, isEmpty, isObject, omitBy, uniqBy,
} from 'lodash'
import styled from 'styled-components'
import qs from 'utils/qs'

import { useIsMobile } from 'hooks/useViewportMode'

import {
  Button, Col,
  Input as AntInput, Row, Typography,
} from 'antd'
import AntTable, { TableProps as AntTableProps } from 'antd/lib/table/Table'

import { gql } from '@apollo/client'

import {
  generatePath, Link, Route, useHistory, useLocation, useParams,
  useRouteMatch,
} from 'react-router-dom'

import { ContactDrawer } from 'components/drawers/ContactDrawer'
import { ErrorMessage } from 'components/ErrorMessage'
import { ContactFormProps } from 'components/form/ContactForm'
import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'
import { Actions, Body, Header } from 'hooks/useContentLayout'
import { PageContent } from 'layouts/Content/PageContent'
import { GetContactsIndexQuery, useGetContactsIndexLazyQuery, useGetContactsIndexQuery } from './__generated__/Contacts'

const { Paragraph, Title } = Typography
const SearchInput = AntInput.Search

type TableProps = AntTableProps<Contact>

const Table = styled(AntTable)`
  .ant-table-row:hover {
    cursor: pointer;
  }
` as typeof AntTable

const PhoneCol = styled.div`
  white-space: nowrap;
`

gql`
  query GetContactsIndex {
    contacts {
      id
      firstName
      lastName
      active
      emails
      phones {
        id
        number
      }
      customers {
        id
        name
        active
      }
    }
  }
`

type Contact = NonNullable<GetContactsIndexQuery>['contacts'][0]

const Index = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const isMobile = useIsMobile()

  const { loading, data, error } = useGetContactsIndexQuery()
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([])
  const [searchValue, setSearchSearchValue] = useState<string>('')

  const customerFilters = useMemo(() => (
    uniqBy(
      filteredContacts.flatMap((contact) => (
        contact.customers.map((customer) => ({
          text: customer.name,
          value: customer.id,
        }))
      )),
      (customer) => customer.value
    ).sort(byFieldAsc('text'))
  ), [filteredContacts])

  useEffect(() => {
    const searchStrings = searchValue.toLowerCase().split(' ').map((val) => val.replace(/\W+/g, ''))

    setFilteredContacts(
      (data?.contacts || []).filter((contact) => (
        searchStrings.every((search) => (
          contact.firstName?.toLowerCase().includes(search) ||
          contact.lastName?.toLowerCase().includes(search) ||
          contact.emails.some((email) => email.toLowerCase().includes(search)) ||
          contact.phones.findIndex((phone) => phone.number.includes(search)) >= 0
        ))
      )).sort((a, b) => (
        byFieldDesc('active')(a, b) || byPersonNameAsc(a, b)
      ))
    )
  }, [searchValue, data?.contacts])

  const initalQueryStringValues = useRef((() => {
    const search = qs.parse(location.search)

    return {
      filters: {
        customers: compact([(isObject(search.filters) && !isArray(search.filters)) ? search.filters?.customers : undefined].flat(1).map((s) => s && parseInt(s.toString()))),
      },
    }
  })()).current

  let columns: TableProps['columns'] = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: {
        compare: byFieldAsc('firstName'),
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: {
        compare: byFieldAsc('lastName'),
      },
    },
    {
      title: 'Customers',
      dataIndex: 'customers',
      defaultFilteredValue: initalQueryStringValues.filters.customers,
      filters: customerFilters,
      filterSearch: true,
      render: (customers: Contact['customers'], _contact) => customers.map((customer, i) => (
        <div key={i}>
          {customer.name}
        </div>
      )),
      onFilter: (value, contact) => (
        contact.customers.some((customer) => customer.id === value)
      ),
    },
    {
      title: 'Phones',
      dataIndex: 'phones',
      render: (phones: Contact['phones'], _contact) => phones.map((phone, i) => (
        <PhoneCol key={i}>
          {formatPhone(phone.number)}
        </PhoneCol>
      )),
    },
    {
      key: 'active',
      title: 'Active',
      dataIndex: 'active',
      render: (value: Contact['active'], row: Contact) => (value ? 'active' : 'inactive'),
      sorter: {
        compare: (a: Contact, b: Contact) => (a.active ? -1 : 1),
      },
    },
  ]

  if (isMobile) {
    columns = columns.slice(0, 3)
  }

  const onRow: TableProps['onRow'] = (record: Contact, _rowIndex: number | undefined) => {
    const rowLink = `${match.url}/${record.id}`
    return {
      onClick: () => {
        history.push(rowLink)
      },
    }
  }

  const onChange: TableProps['onChange'] = (_pagination, filters, _sorter, _extra) => {
    const search = qs.parse(location.search)
    const historyValues: any = {
      ...search,
      filters: omitBy({
        ...(isObject(search.filters) && !isArray(search.filters)) ? search.filters : undefined,
        ...filters,
      }, isEmpty),
    }
    history.push({
      search: qs.stringify(historyValues),
    })
  }

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => setSearchSearchValue(event.target.value)

  return (
    <PageContent>
      <Header>
        <Title level={2}>
          Contacts
        </Title>
      </Header>

      <Actions>
        <Link to={`${match.url}/new`}>
          <Button type="primary">
            Add New Contact
          </Button>
        </Link>
      </Actions>

      <Body>
        <SearchInput
          placeholder="Search by name, email, or phone"
          onChange={onSearch}
          allowClear
          style={{
            width: 400,
            maxWidth: '100%',
            marginBottom: 20,
          }}
        />

        {error && (
          <ErrorMessage>
            <Paragraph>{error.message}</Paragraph>
          </ErrorMessage>
        )}
        <Table
          columns={columns}
          rowKey="id"
          dataSource={filteredContacts}
          pagination={false}
          onChange={onChange}
          onRow={onRow}
          bordered
          locale={{
            emptyText: loading ? 'Loading' : undefined,
          }}
        />
      </Body>
    </PageContent>
  )
}

const Show = () => {
  const [refetchContacts] = useGetContactsIndexLazyQuery()

  const history = useHistory()
  const match = useRouteMatch()
  const { id: urlId, edit: urlEdit } = useParams<{ id: string, edit?: string }>()

  const isNew = urlId === 'new'
  const id = isNew ? undefined : parseInt(urlId)
  const basePath = history.location.pathname.slice(0, match.path.indexOf('/:id'))

  const props: ContactFormProps = {
    id,
    editing: isNew || urlEdit === 'edit',
    onSubmission: (contact, action, opts) => {
      const shouldClose = opts !== undefined && typeof opts.shouldClose === 'boolean' ? opts.shouldClose : true
      if (action === 'create' && contact.id && shouldClose) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: `${basePath}/${contact.id}`,
        })
      }
      refetchContacts()
    },
    onCancel: () => {
      if (isNew) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: basePath,
        })
      }
    },
  }

  return <ContactDrawer {...props} />
}

export const ContactsPage = (_args: any) => {
  const history = useHistory()
  const match = useRouteMatch()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  return (
    <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
      <Col flex="auto" style={{ minWidth: 0 }}>
        <Index />
      </Col>

      <RoutableDrawer showClose={false} onClose={() => { history.replace(basePath) }} width={475}>
        <Route path={`${match.path}/:id/:edit?`} component={Show} />
        <Route path={match.path} hideOnMobile>
          <div className="padded">
            Select a Contact to view and edit.
          </div>
        </Route>
      </RoutableDrawer>
    </Row>
  )
}

export default ContactsPage
