import React, { useRef } from 'react'
import styled from 'styled-components'

import {
  Divider, DividerProps, Menu as AntMenu, MenuItemProps,
} from 'antd'
import AntMenuItem from 'antd/lib/menu/MenuItem'
import { isExternalUrl } from 'helpers/isExternalUrl'
import { omit } from 'lodash'
import {
  Link as ReactRouterLink, LinkProps, useHistory, useLocation,
} from 'react-router-dom'

const MenuStyled = styled(AntMenu)`
  &.ant-menu-vertical .ant-menu-item {
    height: 40px;
    line-height: 40px;
    margin: 8px 0;

    &:first-child {
      margin-top: 0px;
    }
  }
`

export const DividerStyled = styled(Divider)`
  &.ant-divider {
    margin: 8px 0;

    .ant-divider-inner-text {
      font-size: 0.8rem;
      font-weight: 400;
    }
  }

  &.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 10%;
  }
  &.ant-divider-horizontal.ant-divider-with-text-left::after {
    width: 90%;
  }
`

export type MenuLinkProps = React.PropsWithChildren<MenuItemProps & {
  link?: string
  preventNavigation?: boolean
  target?: LinkProps['target']
}>

const Link: typeof ReactRouterLink = (props) => {
  const { to } = props
  if (isExternalUrl(to)) {
    const aProps = omit(props, ['component', 'to', 'replace', 'innerRef'])
    return <a {...aProps} href={to} ref={props.innerRef} />
  }
  return <ReactRouterLink {...props} />
}

export const MenuLink: React.FC<MenuLinkProps> = (props) => {
  const {
    link, preventNavigation, target, onClick, ...rest
  } = props

  const ref = useRef<AntMenuItem | null>(null)

  return (
    <AntMenu.Item
      key={link}
      {...rest}
      onClick={(evt) => {
        if (onClick) {
          onClick(evt)
        }
      }}
      ref={ref}
    >
      {
        link ? (
          <Link
            to={link}
            target={target}
            onClick={(evt) => {
              if (props.preventNavigation) {
                evt.preventDefault()
              }
            }}
          >
            {props.children}
          </Link>
        )
          :
          props.children
      }
    </AntMenu.Item>
  )
}

export interface MenuLinkToggleableProps extends MenuLinkProps {
  basePath: string
}

export const MenuLinkToggleable: React.FC<MenuLinkToggleableProps> = (props) => {
  const { basePath, ...rest } = props
  const location = useLocation()
  const history = useHistory()

  return (
    <MenuLink
      {...rest}
      onClick={(info) => {
        if (props.onClick) {
          props.onClick(info)
        }

        if (props.link && location.pathname.startsWith(props.link)) {
          history.push({ pathname: basePath })
        }
      }}
    />
  )
}

export const MenuDivider = ({ children, ...props }: DividerProps): JSX.Element => (
  <DividerStyled {...props} orientation={props.orientation || 'left'}>{children}</DividerStyled>
)

const Menu = MenuStyled
Menu.Divider = MenuDivider

export { Menu }
