import { timeWithZone } from 'helpers/datetime'
import { PartialOrder } from 'types/PartialOrder'
import { dateToSlug } from './dateSlug'

export const orderScheduleUrl = (order: PartialOrder) => {
  if (!order?.id) return

  const branchId = order?.branch?.id || order?.branchId
  if (!branchId) return

  const dateOfService = order?.dateOfServiceLocal ?
    timeWithZone(order.dateOfServiceLocal, null)
    :
    timeWithZone(order.dateOfService, order?.site?.address?.timezone || order?.branch?.timezone)

  if (!dateOfService) return

  return `/branches/${branchId}/schedule/${dateToSlug(dateOfService)}/order/${order.id}`
}
