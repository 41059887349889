import {
  Button, Input, Popconfirm, Select, Space, Table,
} from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { compact, isEmpty, round } from 'lodash'
import { GetComponentProps } from 'rc-table/lib/interface'
import {
  useContext, useEffect, useMemo, useState,
} from 'react'
import { computeSubtotal, getValueFromPercentage } from '../../helpers/computeBalance'
import { InvoiceBalanceContext } from '../../hooks/useBalance'
import { InvoiceSettingsContext } from '../../hooks/useInvoiceSettings'
import { PartialInvoice } from '../../hooks/useInvoices'
import { InvoiceItemsContext } from '../../hooks/useItems'
import {
  ItemRow, ROW_TYPE, SubtotalRow, TableItemRow, TableProductRow,
} from '../../types/ItemTypes'
import { formatToCurrency } from '../../utils/format'
import { EquipmentList, ProductServiceItem, getItemFromId } from './data/productServiceList'
import { usePriceSheets } from './data/usePriceSheets'

type CallbackRender = (id: number, propName: keyof TableProductRow, value: string | number | boolean) => void

function createNewEmptyProductRow(id: number): ItemRow {
  return {
    type: ROW_TYPE.PRODUCT,
    id,
    item: '',
    description: '',
    qty: 0,
    rate: 0,
    baseRate: 0,
    amount: 0,
    tax: false,
  }
}

function getSubtotal(arr: ItemRow[], id: number) {
  let boo = true
  let i = id - 1
  let subtotal = 0
  while (boo) {
    if (i > 0) {
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      const item = arr.find((it) => (it.id === i))
      if (typeof item !== 'undefined' && item.type === ROW_TYPE.PRODUCT) {
        subtotal += round(item.amount, 2)
        i -= 1
      } else {
        boo = false
      }
    } else {
      boo = false
    }
  }
  return subtotal
}

function buildOptions(items: ProductServiceItem[]): { value: number, label: string }[] {
  return items.map((i) => ({ value: i.id, label: i.itemName }))
}

const renderCell = (prop: keyof TableProductRow, cb: CallbackRender) => function render(v: any, r: TableItemRow, i: number) {
  if (r.type === ROW_TYPE.SUBTOTAL) return v
  if (r.editable) return <Input value={v} onChange={(e) => { cb(i + 1, prop, e.currentTarget.value) }} />
  return v
}

type ProductsTableProps = {
  invoice: PartialInvoice
}

export const ProductsTable = ({ invoice }: ProductsTableProps) => {
  const priceSheets = usePriceSheets()
  const { settings } = useContext(InvoiceSettingsContext)
  const priceSheet = useMemo(() => priceSheets.find(({ id }) => id === settings.priceRateID), [settings.priceRateID])

  const { updateBalance, balance } = useContext(InvoiceBalanceContext)
  const { updateItems } = useContext(InvoiceItemsContext)

  const [subtotal, setSubtotal] = useState<number>(0)
  const [products, setProducts] = useState<TableItemRow[]>([createNewEmptyProductRow(1)])

  useEffect(() => {
    if (!priceSheet) return
    const newProducts = priceSheet.itemsAdjuster(products)
    updateItems(newProducts)
    setProducts(newProducts)
  }, [priceSheet])

  useEffect(() => {
    const lineItems = compact(invoice.lineItems || [])
    if (isEmpty(lineItems)) return
    updateItems(lineItems as any)
    setProducts(lineItems as any)
  }, [invoice.lineItems])

  const [product2Update, setProduct2Update] = useState<{ action: 'update', item: TableItemRow } | { action: 'delete', id: number } | undefined>()
  const updateProp = (id: number, propName: keyof TableProductRow, value: string | number | boolean) => {
    const productsAdjusted = priceSheet ? priceSheet.itemsAdjuster(products) : products

    const newRows = productsAdjusted.map((item) => {
      if (item.id === id && item.type === ROW_TYPE.PRODUCT) {
        let { amount } = item
        if (propName === 'qty') {
          // update amount
          if (value === '') value = 0
          amount = item.rate * (value as number)
        }
        if (propName === 'rate') {
          if (value === '') value = 0
          amount = item.qty * (value as number)
        }
        return { ...item, [propName]: value, amount: round(amount, 2) }
      }
      return item
    })

    updateItems(newRows)
    setProducts(newRows)
  }
  const setItemProps = (idEquipment: number, idRow: number) => {
    const item = getItemFromId(EquipmentList, idEquipment)
    if (item === undefined) return
    const o: TableItemRow = {
      id: idRow, type: ROW_TYPE.PRODUCT, item: item.itemName, description: item.description || '', qty: 1, rate: item.price, baseRate: item.price, tax: item.taxable, amount: item.price, editable: true,
    }
    setProduct2Update({ action: 'update', item: o })
  }
  // effect used to calculate subtotal when products change
  useEffect(() => {
    const newSubtotal = computeSubtotal(products)
    if (subtotal !== newSubtotal) {
      setSubtotal(newSubtotal)
      updateBalance({ subtotal: newSubtotal, surcharge: getValueFromPercentage(newSubtotal, 7) })
    }
  }, [products])
  /*
    effect used to update product/service according to what the user selected
    it is a separate effect because using setProducts directly when the user interacts with the table causes some components to unmount
    updating a state associated to the same component that is unmounting raises an error
  */
  useEffect(() => {
    if (product2Update) {
      if (product2Update.action === 'update') {
        let newRows = products.map((p) => {
          if (p.id === product2Update.item.id && p.type === ROW_TYPE.PRODUCT) {
            return product2Update.item
          }
          return p
        })

        if (priceSheet) {
          newRows = priceSheet.itemsAdjuster(newRows)
        }

        updateItems(newRows)
        setProducts(newRows)
      } else {
        handleRemoveItem(product2Update.id)
      }
      setProduct2Update(undefined)
    }
  }, [product2Update])
  const renderSearchSelect = () => function render(v: any, r: TableItemRow, i: number) {
    if (r.type === ROW_TYPE.SUBTOTAL) return v
    if (r.editable) {
      return (
        <Select
          showSearch
          placeholder="Search Equipment"
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
          options={buildOptions(EquipmentList)}
          onChange={(vi) => { setItemProps(vi, i + 1) }}
          value={v !== '' ? v : undefined}
        />
      )
    }
    return v
  }
  const defaultColumns: ColumnsType<TableItemRow> = [
    { title: '#', key: 'i', render: (v, r) => r.id },
    {
      title: 'Product/Service',
      dataIndex: 'item',
      key: 'item',
      render: renderSearchSelect(),
    },
    {
      title: 'Description', dataIndex: 'description', key: 'description', render: renderCell('description', updateProp),
    },
    {
      title: 'QTY', dataIndex: 'qty', key: 'qty', render: renderCell('qty', updateProp),
    },
    {
      title: 'Rate', dataIndex: 'rate', key: 'rate', render: renderCell('rate', updateProp),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (value, record, index) => {
        if (record.type === ROW_TYPE.SUBTOTAL) {
          return `Subtotal: ${formatToCurrency(record.total)}`
        }
        return formatToCurrency(record.amount)
      },
    },
    {
      title: '',
      dataIndex: '',
      render(value, record) {
        return products.length > 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => { setProduct2Update({ action: 'delete', id: record.id }) }}>
            <a>Delete</a>
          </Popconfirm>
        ) : null
      },
    },
  ]
  const handleRemoveAllItems = () => {
    const newRows = [createNewEmptyProductRow(1)]
    updateItems(newRows)
    setProducts(newRows)
  }
  const handleCreateNewEmptyItem = () => {
    const row = createNewEmptyProductRow(products.length + 1)
    const newRows = [...products, row]
    updateItems(newRows)
    setProducts(newRows)
  }
  const handleCreateSubtotal = () => {
    const newID = products.length + 1
    const newItem: SubtotalRow = { type: ROW_TYPE.SUBTOTAL, id: newID, total: getSubtotal(products, newID) }
    const newRows = [...products, newItem]
    updateItems(newRows)
    setProducts(newRows)
  }
  const handleRemoveItem = (id: number) => {
    const newRows = products
      .filter((row) => row.id !== id)
      .map((row, i) => ({ ...row, id: i + 1 }))
      .map((row, i, slf) => {
        if (row.type === ROW_TYPE.SUBTOTAL) {
          // calculate total between products
          row.total = getSubtotal(slf, row.id)
        }
        return row
      })

    updateItems(newRows)
    setProducts(newRows)
  }
  const enableRowEdition = (id: number) => {
    const newRows = products.map((item) => {
      if (item.type === ROW_TYPE.SUBTOTAL) return item
      if (item.id !== id) {
        return { ...item, editable: false }
      }
      return { ...item, editable: true }
    })
    updateItems(newRows)
    setProducts(newRows)
  }
  const rowOnClickEdit: GetComponentProps<TableItemRow> = (d, i) => ({
    onClick: () => {
      enableRowEdition((i || 0) + 1)
    }, // click row
  })
  return (
    <>
      <div style={{ display: 'flex', paddingBottom: '2rem', justifyContent: 'flex-end' }}>
        <div>
          <Space>
            <Button onClick={handleCreateNewEmptyItem}>Add item</Button>
            <Button onClick={handleCreateSubtotal}>Add subtotal</Button>
            <Button type='text' onClick={handleRemoveAllItems}>Clear all</Button>
          </Space>
        </div>
      </div>
      <Table bordered columns={defaultColumns} dataSource={products} onRow={rowOnClickEdit} pagination={false} />
    </>
  )
}
