import { formatPhone } from 'helpers/formatPhone'
import { byFieldAsc } from 'helpers/sortting'
import styled from 'styled-components'

import { useNotification } from 'hooks/useNotification'
import { useMemo } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'
import { ADDRESS_FRAGMENT } from 'gql/addresses'
import {
  Mutation, Query, QueryCustomersArgs, QuickbooksMutationNamespaceCreateCustomerArgs, QuickbooksMutationNamespaceLinkCustomerArgs,
} from 'schema'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Skeleton } from 'antd'
import { Address } from 'components/common/Address'
import { LinkableLists, LinkableListsProps } from 'components/common/LinkableLists'
import ErrorMessage from 'components/ErrorMessage'

const CustomerSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    .ant-skeleton-title {
      height: 12px;
      margin-top: 0;
    }

    .ant-skeleton-paragraph {
      margin-top: 0;

      > li {
        height: 12px;
        margin-top: 8px;
      }
    }
  }
`

const nameForCustomer = (customer: { name: string, active?: boolean | null }) => (
  customer.active !== false ?
    customer.name
    : customer.name.includes('deleted') ?
      customer.name
      :
      `${customer.name} (deleted)`
)

export const QuickbooksCustomersMatch = () => {
  const notification = useNotification()

  const customersQuery = useQuery<Query, QueryCustomersArgs>(GET_CUSTOMERS)
  const quickbooksQuery = useQuery<Query>(GET_QUICKBOOKS_CUSTOMERS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  })

  const [
    linkCustomer,
    linkCustomerResponse,
  ] = useMutation<Mutation, QuickbooksMutationNamespaceLinkCustomerArgs>(LINK_QUICKBOOKS_CUSTOMER)

  const [
    createCustomer,
    createCustomerResponse,
  ] = useMutation<Mutation, QuickbooksMutationNamespaceCreateCustomerArgs>(CREATE_QUICKBOOKS_CUSTOMER)

  const loading = customersQuery.loading || quickbooksQuery.loading || linkCustomerResponse.loading || createCustomerResponse.loading
  const error = customersQuery.error || quickbooksQuery.error

  const quickbooksCustomers = useMemo(() => (quickbooksQuery?.data?.integrations?.quickbooks?.customers || []).slice().sort(byFieldAsc('name')), [quickbooksQuery?.data])

  const customers = useMemo(() => (customersQuery?.data?.customers || []).map((customer) => ({ ...customer, quickbooksId: customer.integrations?.quickbooks?.customer?.id })).sort(byFieldAsc('name')), [customersQuery?.data])

  const customerIdToQuickbooksId = useMemo(() => Object.fromEntries(
    customers.map((customer) => [customer.id, customer.quickbooksId])
  ), [customers])

  const updateMatch = async (customerId: number, quickbooksId: string | null) => {
    const action = quickbooksId ? 'update' : 'delete'

    const resp = await linkCustomer({
      variables: {
        quickbooksId,
        customer: {
          id: customerId,
        },
      },
    })

    if (resp.errors) {
      notification.error({
        message: 'Error Saving Match',
        description: resp.errors.map((err) => err.message).join('\n'),
        duration: 5,
      })
      return false
    }
    const customer = resp?.data?.integrations?.quickbooks?.linkCustomer.name

    notification.success({
      message: action === 'update' ? 'Saved Match' : 'Deleted Match',
      description: `${action === 'update' ? 'Saved match' : 'Deleted match'} for ${customer}`,
      duration: 3,
    })
    return true
  }

  const createMatch = async (customer: typeof customers[0], force?: boolean) => {
    const resp = await createCustomer({
      variables: {
        force,
        where: {
          id: customer.id,
        },
      },
    })

    if (resp.errors) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const requiresForce = resp.errors.every((error) => error.extensions?.code === 'REQUIRES_FORCE')
      if (requiresForce) {
        Modal.confirm({
          title: 'Customer Already Exists',
          icon: <ExclamationCircleOutlined />,
          content: <>
            It appears <strong>{customer.name}</strong> already exists in QuickBooks.<br /><br />
            <strong>If these Customers are the same, click cancel below and link these Customers. </strong>
          </>,
          cancelText: 'Cancel',
          okText: 'Create Duplicate Customer',
          onOk: async () => {
            await createMatch(customer, true)
          },
        })
        return false
      }

      notification.error({
        message: 'Error Saving Match',
        description: resp.errors.map((err) => err.message).join('\n'),
        duration: 5,
      })
      return false
    }
    // TODO: only fetch the one we just created
    await quickbooksQuery.refetch()

    notification.success({
      message: 'Created New Customer',
      description: `Created ${customer.name} in Quickbooks`,
      duration: 3,
    })

    return true
  }

  const props: LinkableListsProps<typeof customers[0], typeof quickbooksCustomers[0]> = {
    mode: 'one-to-one',
    loading,
    canCreate: 'Customer',
    linked: (customer, quickbooksCustomer) => customerIdToQuickbooksId[customer.id] === quickbooksCustomer.id,
    onCreate: async (customer) => createMatch(customer),
    onLink: async (customer, quickbooksCustomer) => updateMatch(customer.id, quickbooksCustomer.id),
    onDelete: async (customer, _quickbooksCustomer) => updateMatch(customer.id, null),
    list1: {
      title: 'CreteSuite Customer',
      resources: customers,
      generateOption: (customer) => nameForCustomer(customer),
      renderCard: (customer) => (
        <div style={{ minHeight: '110px' }}>
          <div>{customer.name}</div>
          <span style={{ fontWeight: 'normal' }}>
            {customer.billingAddress &&
              <Address address={customer.billingAddress} />}

            {(customer.phones || []).map((phone, i) => (
              <div key={i}> {formatPhone(phone.number)}</div>
            ))}
            {(customer.emails || []).map((email, i) => (
              <div key={i}> {email} </div>
            ))}
          </span>
        </div>
      ),
      skeleton: <CustomerSkeleton />,
    },
    list2: {
      title: 'Quickbooks Customer',
      resources: quickbooksCustomers,
      generateOption: (customer) => nameForCustomer(customer),
      renderCard: (customer) => (
        <div style={{ minHeight: '110px' }}>
          <div>{customer.name}</div>
          <span style={{ fontWeight: 'normal' }}>
            {customer.address &&
              <Address address={customer.address} />}
            {customer.phone &&
              <div> {customer.phone}</div>}
            {customer.email &&
              <div> {customer.email}</div>}
          </span>
        </div>
      ),
      skeleton: <CustomerSkeleton />,
    },
  }

  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <LinkableLists {...props} />
    </>
  )
}

export default QuickbooksCustomersMatch

const GET_QUICKBOOKS_CUSTOMERS = gql`
  query GetQuickbooksCustomers {
    integrations {
      quickbooks {
        customers {
          id
          revision
          active
          name
          phone
          email
          address {
            street
            city
            state
            zip
          }
        }
      }
    }
  }
`

const CUSTOMER_FRAGMENT = gql`
  fragment CustomerFields on Customer {
    id
    name
    abbreviation
    emails
    active
    integrations {
      quickbooks {
        customer {
          id
        }
      }
    }
    phones {
      number
    }
    billingAddress {
      ...AddressFields
    }
  }
  ${ADDRESS_FRAGMENT}
`

const GET_CUSTOMERS = gql`
  query GetCustomersMatchList {
    customers {
      ...CustomerFields
    }
  }
  ${CUSTOMER_FRAGMENT}
`

const LINK_QUICKBOOKS_CUSTOMER = gql`
  mutation LinkQuickbooksCustomer(
    $customer: CustomerWhereUniqueInput!,
    $quickbooksId: String
  ) {
    integrations {
      quickbooks {
        linkCustomer(customer: $customer, quickbooksId: $quickbooksId) {
          ...CustomerFields
        }
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`

const CREATE_QUICKBOOKS_CUSTOMER = gql`
  mutation CreateQuickbooksCustomer(
    $force: Boolean,
    $where: CustomerWhereUniqueInput!,
  ) {
    integrations {
      quickbooks {
        createCustomer(where: $where, force: $force) {
          ...CustomerFields
        }
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`
