import { Form as AntdForm } from 'antd'
import AntInternalForm, { FormInstance as AntFormInstance } from 'antd/lib/form/Form'
import { formLayout } from 'components/form/common/Layout'
import { FormItem } from 'components/form/FormItem'
import React, { useState } from 'react'

type AntInternalFormType = typeof AntInternalForm

export type FormInstance<Values> = AntFormInstance<Values> & {
  submit: (values: Values) => Promise<void> | void
}

const CurrentFormContext = React.createContext<AntFormInstance<any>>(null as unknown as AntFormInstance)

export const InternalForm: AntInternalFormType = (props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
  const args = {
    ...formLayout,
    ...props,
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const form = args.form || AntdForm.useForm()[0]

  args.onFinish = async (values) => {
    if (isSubmitting) return

    setIsSubmitting(true)

    try {
      if (props.onFinish) {
        await props.onFinish(values)
      }
      setIsSubmitting(false)
    } catch (err) {
      setIsSubmitting(false)
      throw err
    }
  }

  if (isSubmitting) {
    args.className = `${args.className || ''} processing`
  }

  if (args.autoComplete === undefined) {
    args.autoComplete = 'new-off'
  }

  form.submit = async () => {
    try {
      const values = await form.validateFields()
      if (!args.onFinish) return
      try {
        await args.onFinish(values)
      } catch (err: any) {
        console.error(err)
      }
    } catch (err: any) {
      if (args.onFinishFailed) {
        args.onFinishFailed(err)
      }
    }
  }

  args.form = form

  return (
    <CurrentFormContext.Provider value={args.form}>
      <AntdForm {...args} />
    </CurrentFormContext.Provider>
  )
}

export const useCurrentForm = () => React.useContext(CurrentFormContext)

type FormType = typeof InternalForm

interface FormInterface extends Omit<typeof AntdForm, keyof AntInternalFormType | 'Item'>, FormType {
  Item: typeof FormItem
}

const Form = InternalForm as FormInterface
Form.Item = FormItem
Form.List = AntdForm.List
Form.ErrorList = AntdForm.ErrorList
Form.useForm = AntdForm.useForm
Form.Provider = AntdForm.Provider

export * from 'antd/lib/form'
export { Form }
export default Form
