import { WarningFilled } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { EXPIRATION_CSS, getCredentialExpirationWarning } from 'components/common/CredentialExpirationDate'
import { Tooltip } from 'components/common/Tooltip'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { LinkExternalIcon } from 'components/icons'
import colors from 'constants/colors'
import { byPersonNameAsc } from 'helpers/sortting'
import { compact, countBy, uniq } from 'lodash'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Operator } from 'schema'
import styled from 'styled-components'
import { RecursivePartial } from 'types'

export type OperatorSelectProps = ReferenceSelectProps<Operator>

const GET_OPERATORS = gql`
  query GetOperators($where: QueryOperatorsWhereInput) {
    operators(where: $where) {
      id
      firstName
      lastName

      credentials {
        id
        expirationDate
      }
    }
  }
`

const CredentialWarningIcon = styled(WarningFilled)`
  margin-right: 10px;
  ${EXPIRATION_CSS}
`

const OperatorOptionLabel = ({ operator }: { operator: RecursivePartial<Operator> }) => {
  const expirationWarnings = useMemo(() => (
    compact(
      (operator.credentials || []).map((credenital) => (
        credenital.expirationDate && getCredentialExpirationWarning(DateTime.fromISO(credenital.expirationDate))
      ))
    )
  ), [operator?.credentials])

  const tooltipTitle = useMemo(() => {
    if (expirationWarnings.length === 0) return null
    const counted = countBy(expirationWarnings) as Record<typeof expirationWarnings[0], number>
    const lines: string[] = []
    const s = (num: number) => (num === 1 ? '' : 's')

    if (counted.expired) {
      lines.push(`${operator.firstName} has ${counted.expired} expired credential${s(counted.expired)}.`)
    }
    if (counted.imminent) {
      lines.push(`${operator.firstName} has ${counted.imminent} imminently expiring credential${s(counted.imminent)}.`)
    }
    if (counted.upcoming) {
      lines.push(`${operator.firstName} has ${counted.upcoming} credential${s(counted.upcoming)} expiring soon.`)
    }

    return (
      <div style={{ lineHeight: '1.6em', padding: '3px 5px' }}>
        {lines.map((line) => <>{line}<br /></>)}
        <Link
          to={`/team/${operator.id}`}
          style={{ display: 'block', textAlign: 'right' }}
          target="_blank"
        > View Operator <LinkExternalIcon />
        </Link>
      </div>
    )
  }, [expirationWarnings])

  return (
    <>
      {expirationWarnings.length > 0 && (
        <Tooltip title={tooltipTitle} color={colors.greyscale80} noWrap>
          <CredentialWarningIcon className={uniq(expirationWarnings).join(' ')} />
        </Tooltip>
      )}
      {[operator?.firstName, operator?.lastName].join(' ')}
    </>
  )
}

const operatorOptionGenerator = (operator?: RecursivePartial<Operator> | null) => {
  if (!operator?.id) return

  return {
    title: [operator?.firstName, operator?.lastName].join(' '),
    label: <OperatorOptionLabel operator={operator} />,
    value: operator.id,
  }
}

const defaults: Partial<OperatorSelectProps> = {
  showSearch: true,
  filterOption: (val: string, opt) => opt?.title?.toLowerCase().includes(val.toLowerCase()),
  optionsGenerator: (operators) => (
    compact(operators.slice().sort(byPersonNameAsc).map(operatorOptionGenerator))
  ),
}

export const OperatorSelect: React.FC<OperatorSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Operator',
  queryField: 'operators',
  gql: GET_OPERATORS,
  withCurrentBranchFilter: true,
  defaults,
}, props))

export default OperatorSelect
