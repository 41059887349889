import {
  ApolloClient, ApolloClientOptions,
  ApolloLink, ApolloProvider as ApolloProviderOrig, concat,
  FieldReadFunction, HttpLink, InMemoryCache, InMemoryCacheConfig,
} from '@apollo/client'
import { ApolloProviderProps as ApolloProviderPropsOrig } from '@apollo/client/react/context'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import { API_URL } from 'constants/environment'
import { disableFragmentWarnings } from 'graphql-tag'
import { Token } from 'hooks/useSession'
import { isNumber, merge } from 'lodash'
import React, { useEffect, useState } from 'react'

import generatedConfig from './generated-config'

disableFragmentWarnings()

const fieldReadFunctionById = (typeName: string, idField = 'id'): FieldReadFunction => (_, { args, toReference }) => toReference({
  __typename: typeName,
  [idField]: args?.[idField],
})

const customConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        user: fieldReadFunctionById('User'),
        order: fieldReadFunctionById('Order'),
        route: fieldReadFunctionById('Route'),

        equipment: (value, { args, toReference }) => {
          if (value) return value

          const { where } = args || {}
          const byId = where?.id?.equals
          if (
            Object.keys(where || {}).length === 1 &&
            isNumber(byId)
          ) {
            return [
              toReference({
                __typename: 'Equipment',
                id: byId,
              }),
            ]
          }

          return undefined
        },
        events: {
          keyArgs: ['where'],
        },
      },
    },
  },
}

const cacheConfig: InMemoryCacheConfig = merge(
  generatedConfig,
  customConfig
)

const cache = new InMemoryCache(cacheConfig)

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = Token
  if (token) {
    operation.setContext({
      headers: {
        authorization: token,
      },
    })
  }
  return forward(operation)
})

const clientOptions: Omit<ApolloClientOptions<any>, 'cache'> = {
  uri: API_URL,
  link: concat(
    authMiddleware,
    new HttpLink({
      uri: API_URL,
    })
  ),
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
}

export type ApolloProviderProps = Omit<ApolloProviderPropsOrig<any>, 'client'>

// export const ApolloProvider: React.FC<ApolloProviderProps> = (props) => {
//   const client = new ApolloClient({ cache: cache, ...clientOptions})
//   return <ApolloProviderOrig {...props} client={ client } />
// }

const SCHEMA_VERSION = '2' // Must be a string.
const SCHEMA_VERSION_KEY = 'com.cretesuite.api.schemaversion'

export const ApolloProvider: React.FC<ApolloProviderProps> = (props) => {
  const [client, setClient] = useState<ApolloClient<any> | null>(null)

  useEffect(() => {
    const init = async () => {
      const localStore = new LocalStorageWrapper(window.localStorage)
      const persistor = new CachePersistor({
        cache,
        storage: localStore,
      })

      const currentVersion = await localStore.getItem(SCHEMA_VERSION_KEY)
      if (currentVersion === SCHEMA_VERSION) {
        await persistor.restore()
      } else {
        await persistor.purge()
        await localStore.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
      }

      const newClient = new ApolloClient({
        cache,
        ...clientOptions,
      });

      (newClient as any).customClearStore = async () => {
        try {
          await newClient.clearStore()
          await persistor.purge()
        } catch { }
      }

      setClient(newClient)
    }
    init().catch(console.error)
  }, [])

  if (!client) {
    return null
  }

  return <ApolloProviderOrig {...props} client={client} />
}
