import { isNil } from 'lodash'

import {
  Actions, Body, ContentContext, Footer, Header, useContentLayoutState,
} from 'hooks/useContentLayout'

import { AdditionalDataButton } from 'components/buttons'
import { CloseButton } from 'hooks/useClosable'

import { CustomerForm, CustomerFormProps } from 'components/form/CustomerForm'

import { TagsEditor } from 'components/tags/TagsEditor'

import { gql, useQuery } from '@apollo/client'
import { CustomerActionsMenu } from 'components/menus/CustomerActionsMenu'
import { TAG_SLIM_FRAGMENT } from 'gql/tags'
import { Query } from 'schema'
import { DrawerContent } from '../../layouts/Content/DrawerContent'
import { ResourceTag } from '../tags/ResourceTag'
import { FooterSection } from './FooterSection'

export type CustomerDrawerProps = CustomerFormProps

export const CustomerDrawer = (props: CustomerDrawerProps) => {
  const { id } = props
  const isNew = isNil(id)

  const { data } = useQuery<Query, any>(GET_CUSTOMER_WITH_TAGS, {
    skip: isNew,
    variables: { id },
    nextFetchPolicy: 'cache-only',
  })

  const FormContentLayout = useContentLayoutState()

  return (
    <DrawerContent>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <TagsEditor scope="customers" id={id!} selected={data?.customers?.[0]?.tags || []} enabled={!isNew}>
        <Header>
          {id ? <FormContentLayout.Header.Target /> : 'New Customer'}
          <TagsEditor.Tags style={{ marginTop: '5px' }} />
          {id === 8029 && (
            <>
              <ResourceTag tag={{
                color: '#eb5a46',
                name: 'Overdue: $3105.15',
              }}
              />
            </>
          )}
        </Header>

        <Actions>
          <TagsEditor.Selector />
          <CustomerActionsMenu customerId={id} />
          <CloseButton />
        </Actions>
      </TagsEditor>

      <Body>
        <ContentContext.Provider value={FormContentLayout}>
          <CustomerForm key={`customer-${id}`} {...props} />
        </ContentContext.Provider>
      </Body>

      <Footer>
        <FooterSection>
          <FormContentLayout.Footer.Target />
        </FooterSection>

        {id && (
          <>
            <AdditionalDataButton to={`/contacts?filters[customers]=${id}`}>
              View Contacts
            </AdditionalDataButton>

            <AdditionalDataButton to={`/orders?customerId=${id}`}>
              View Orders
            </AdditionalDataButton>

            <AdditionalDataButton to={`/customers/${id}/sites`}>
              View Sites
            </AdditionalDataButton>
          </>
        )}
      </Footer>
    </DrawerContent>
  )
}

const GET_CUSTOMER_WITH_TAGS = gql`
 query GetCustomerWithTags($id: Int!) {
    customers(
      where: {
        id: {
          equals: $id
        }
      }
    ) {
      id
      tags {
        ...TagFields
      }
    }
  }

  ${TAG_SLIM_FRAGMENT}
`
