import { pourTypesToTree } from 'helpers/pourTypes'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import {
  generatePath, Link, Route, useHistory,
  useParams, useRouteMatch,
} from 'react-router-dom'

import { PushpinOutlined } from '@ant-design/icons'
import {
  Button, Col, Row, Typography,
} from 'antd'
import AntTable, { TableProps as AntTableProps } from 'antd/lib/table/Table'
import { ErrorMessage } from 'components/ErrorMessage'
import { PourTypeFormProps } from 'components/form/PourTypeForm'

import { QueryLazyOptions, useQuery } from '@apollo/client'
import { PourTypeDrawer } from 'components/drawers/PourTypeDrawer'
import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'
import { GET_POUR_TYPES } from 'gql/pour-types'
import { DocumentNode } from 'graphql'
import { Actions, Body, Header } from 'hooks/useContentLayout'
import { PageContent } from 'layouts/Content/PageContent'
import {
  PourType,
  Query,
  QueryPourTypesArgs,
} from '../schema'

const { Paragraph, Title } = Typography

const Table: React.FC<AntTableProps<any>> = styled(AntTable)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

const CategoryLabel = styled.div.attrs((props) => ({
  children: <><span>Category</span>{props.children}</>,
} as React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>))`
  display: inline-block;

  > span {
    font-weight: bold;
    font-size: 10px;
    display: block;
  }
`

const PinnedIcon = styled(PushpinOutlined)`
  margin-left: 10px;
`

const useIndexQuery = (): [DocumentNode, QueryLazyOptions<QueryPourTypesArgs>] => [GET_POUR_TYPES, { variables: {} }]

const Index = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const indexQuery = useIndexQuery()

  const { loading, data, error } = useQuery<Query, QueryPourTypesArgs>(...indexQuery)

  const pourTypes = data?.pourTypes || []

  const pourTypesTree = useMemo(() => pourTypesToTree(pourTypes), [pourTypes])

  const columns: NonNullable<React.ComponentProps<typeof Table>['columns']> = [

    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, record: PourType) => (
        <>
          {record.isCategory ?
            <CategoryLabel>{record.name}</CategoryLabel>
            :
            record.name}
          {record.pinned && <PinnedIcon />}
        </>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (value: PourType['active'], _row: PourType) => (value ? 'active' : 'inactive'),
    },
  ]

  const onRow = (record: PourType, _rowIndex: number | undefined) => {
    const rowLink = `${match.url}/${record.id}`
    return {
      onClick: () => {
        history.push(rowLink)
      },
    }
  }

  return (
    <PageContent>
      <Header>
        <Title level={2}>
          Pour Types
        </Title>
      </Header>

      <Actions>
        <Link to={`${match.url}/new`}>
          <Button type="primary">
            Add New Pour Type
          </Button>
        </Link>
      </Actions>

      <Body>
        {error && (
          <ErrorMessage>
            <Paragraph>{error.message}</Paragraph>
          </ErrorMessage>
        )}
        <Table
          bordered
          rowKey="id"
          columns={columns}
          dataSource={pourTypesTree}
          defaultExpandAllRows
          pagination={false}
          onRow={onRow}
          locale={{
            emptyText: loading ? 'Loading' : undefined,
          }}
        />
      </Body>
    </PageContent>
  )
}

const Show = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id: urlId, edit: urlEdit } = useParams<{ id: string, edit?: string }>()

  const isNew = urlId === 'new'
  const id = isNew ? undefined : parseInt(urlId)
  const basePath = history.location.pathname.slice(0, match.path.indexOf('/:id'))

  const props: PourTypeFormProps = {
    id,
    editing: isNew || urlEdit === 'edit',
    onSubmission: (pourType, action) => {
      if (action === 'create' && pourType?.id) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: `${basePath}/${pourType?.id}`,
        })
      }
    },
    onCancel: () => {
      if (isNew) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: basePath,
        })
      }
    },
  }

  return <PourTypeDrawer key={urlId} {...props} />
}

export const PourTypesPage = (_args: any) => {
  const history = useHistory()
  const match = useRouteMatch()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  return (
    <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
      <Col flex="auto" style={{ minWidth: 0 }}>
        <Route path={match.path} component={Index} />
      </Col>
      <RoutableDrawer showClose={false} onClose={() => { history.replace(basePath) }} width={475}>
        <Route path={`${match.path}/:id/:edit?`} component={Show} />
        <Route path={match.path} hideOnMobile>
          <div className="padded">
            Select a Pour Type to view and edit.
          </div>
        </Route>
      </RoutableDrawer>
    </Row>
  )
}

export default PourTypesPage
