import { Checkbox as AntCheckbox } from 'antd'
import { CheckboxProps as AntCheckboxProps } from 'antd/lib/checkbox'
import React, { ComponentRef } from 'react'
import styled from 'styled-components'

export type CheckboxRef = ComponentRef<typeof AntCheckbox>

export interface CheckboxProps extends AntCheckboxProps {
  editing?: boolean
}

const StyledAntCheckbox = styled(AntCheckbox)`
  &.ant-checkbox.read-only,
  &.ant-checkbox.read-only .ant-switch-handle
  {
    cursor: inherit;
  }
`

export const Checkbox = React.forwardRef<CheckboxRef, CheckboxProps>((props, ref) => {
  const { editing, ...args } = props
  if (editing === false && args.disabled === undefined) {
    args.className = `${args.className || ''} read-only`
    args.disabled = true
  }
  return <StyledAntCheckbox {...args} ref={ref} />
})

export default Checkbox
