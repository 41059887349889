import { compact } from 'lodash'

import { useFormState } from 'components/form/OrderForm/OrderFormState'
import { useFeature } from 'hooks/useFeature'

import { Panel } from 'components/common/Collapse/Panel'
import { FormItem } from 'components/form/FormItem'

import { OperatorAssignmentsFormList } from 'components/form/common/OperatorAssignmentsFormList'
import { TextArea } from 'components/form/inline-edit/TextArea'
import { BillableEquipmentSelect } from 'components/form/reference-selects/BillableEquipmentSelect'
import { EquipmentSelect } from 'components/form/reference-selects/EquipmentSelect'
import { SystemFormList } from '../common/SystemFormList'
import { OrderFormPanelProps } from './PanelProps'

export const DispatchPanel = (props: OrderFormPanelProps): JSX.Element => {
  const {
    editing, order, details, selectedEquipment,
  } = useFormState()

  const [inventoryEnabled] = useFeature('inventory.enabled')
  const systemInputEnabled = !inventoryEnabled

  return (
    <Panel {...props} header="Dispatch">
      <FormItem
        label="Equipment"
        name={['details', 'equipmentId']}
      >
        <EquipmentSelect
          allowClear
          editing={editing}
          resources={compact([details?.equipment])}
        />
      </FormItem>
      <FormItem
        label="Billable Equipment"
        name={['order', 'billable', 'equipmentId']}
      >
        <BillableEquipmentSelect
          editing={editing}
          resources={compact([order?.billable?.equipment])}
        />
      </FormItem>

      {systemInputEnabled && (
        <SystemFormList
          label="System"
          name={['details', 'inventory']}
          editing={editing}
          defaultToOne={selectedEquipment?.type === 'trailer_pump'}
        />
      )}

      <FormItem
        label="Operators"
      >
        <OperatorAssignmentsFormList
          name={['order', 'assignments']}
          editing={false}
          hideRole
          singleMode
          placeholder="Edit Route to Select Operators"
          resources={compact(order?.assignments || [])}
        />
      </FormItem>

      <FormItem
        label="Instructions"
        name={['order', 'instructions']}
      >
        <TextArea
          editing={editing}
          placeholder="Instructions for operator"
          autoSize={{ minRows: 1 }}
        />
      </FormItem>
    </Panel>
  )
}
