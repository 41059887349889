import { gql } from '@apollo/client'
import { PHONE_FRAGMENT } from './phones'

export const USERS_FRAGMENT = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    username
    isOperator
    isAdmin
    active
    createdAt
    updatedAt
    phones {
      ...PhoneFields
    }
  }
  ${PHONE_FRAGMENT}
`

export const GET_USERS = gql`
  query GetUser(
    $orderBy: [QueryUsersOrderByInput!],
    $where: QueryUsersWhereInput
  ) {
    users(
      orderBy: $orderBy,
      where: $where
    ) {
      ...UserFields
    }
  }
  ${USERS_FRAGMENT}
`

export const CREATE_USER = gql`
  mutation CreateUser(
    $data: UserInput!
  ) {
    createUser(
      data: $data
    ) {
      ...UserFields
    }
  }
  ${USERS_FRAGMENT}
`

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateUser(
      data: $data
      where: $where
    ) {
      ...UserFields
    }
  }
  ${USERS_FRAGMENT}
`
