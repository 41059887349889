import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserForFormQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetUserForFormQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: number, firstName?: string | null, lastName?: string | null, username: string, isOperator?: boolean | null, isAdmin: boolean, active: boolean, createdAt: Types.Scalars['DateTime'], updatedAt: Types.Scalars['DateTime'], operator?: { __typename?: 'Operator', id: number, credentials: Array<{ __typename?: 'Credential', id: number, number?: string | null, images: Array<string>, expirationDate?: any | null, type: string, active: boolean, typeDetails: { __typename?: 'CredentialType', slug: string, name: string } }> } | null, phones: Array<{ __typename?: 'Phone', id: number, number: string, type: string, sms: boolean, active: boolean }> } | null };


export const GetUserForFormDocument = gql`
    query GetUserForForm($id: Int!) {
  user(id: $id) {
    id
    firstName
    lastName
    username
    isOperator
    isAdmin
    active
    createdAt
    updatedAt
    operator {
      id
      credentials {
        id
        number
        images
        expirationDate
        type
        typeDetails {
          slug
          name
        }
        active
      }
    }
    phones {
      id
      number
      type
      sms
      active
    }
  }
}
    `;

/**
 * __useGetUserForFormQuery__
 *
 * To run a query within a React component, call `useGetUserForFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserForFormQuery(baseOptions: Apollo.QueryHookOptions<GetUserForFormQuery, GetUserForFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserForFormQuery, GetUserForFormQueryVariables>(GetUserForFormDocument, options);
      }
export function useGetUserForFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserForFormQuery, GetUserForFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserForFormQuery, GetUserForFormQueryVariables>(GetUserForFormDocument, options);
        }
export type GetUserForFormQueryHookResult = ReturnType<typeof useGetUserForFormQuery>;
export type GetUserForFormLazyQueryHookResult = ReturnType<typeof useGetUserForFormLazyQuery>;
export type GetUserForFormQueryResult = Apollo.QueryResult<GetUserForFormQuery, GetUserForFormQueryVariables>;
export function refetchGetUserForFormQuery(variables: GetUserForFormQueryVariables) {
      return { query: GetUserForFormDocument, variables: variables }
    }