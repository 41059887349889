import colors from 'constants/colors'
import styled from 'styled-components'

const Wrapper = styled.div<{ block?: boolean, bordered?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => props.block && 'width: 100%; height: 100%;'}
  ${(props) => props.bordered && `border: 1px solid ${colors.borderPrimary};`}
`

const Header = styled.div<{ borderless?: boolean }>`
  flex: 0 0 auto;
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid ${colors.borderPrimary};

  ${(props) => props.borderless && 'border-bottom-color: transparent;'}
`

const Body = styled.div`
  flex: 1 1 auto;
  width: 100%;
  min-height: 1px;
  padding: 20px;
  overflow: auto;
`

const Footer = styled.div`
  flex: 0 0 auto;
  width: 100%;
  padding: 10px 20px;
  border-top: 1px solid ${colors.borderPrimary};
`

const Card: typeof Wrapper & {
  Wrapper: typeof Wrapper,
  Header: typeof Header,
  Body: typeof Body,
  Footer: typeof Footer,
} = Wrapper as any
Card.Wrapper = Wrapper
Card.Header = Header
Card.Body = Body
Card.Footer = Footer

export { Card }
