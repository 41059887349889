import { Tag as AntTag } from 'antd'
import { TagProps as AntdTagProps } from 'antd/lib/tag'
import Color from 'color'
import styled from 'styled-components'

const DEFAULT_COLOR = '#dddddd'

const ColorWhite = Color('#fff')

const LightTag = styled(AntTag)`
  font-weight: 500;

  &, .ant-tag-close-icon, .ant-tag-close-icon:hover {
    color: rgba(0, 0, 0, 0.85)
  }
`

const DarkTag = styled(AntTag)`
  font-weight: 500;

  &, .ant-tag-close-icon {
    color: #fff;
  }
`

export type TagProps = AntdTagProps & {
  fontColor?: string
}

export const Tag: React.FC<TagProps> = (props) => {
  const { ...args } = props
  if (!args.color) {
    args.color = DEFAULT_COLOR
  }

  if (!args.style) {
    args.style = {}
  }
  args.style.borderColor = args.style.borderColor || args.color

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const colorObj = Color(args.color!)
  let TagComponent: React.ComponentType

  if (colorObj.contrast(ColorWhite) > 1.8) {
    TagComponent = DarkTag
  } else {
    TagComponent = LightTag
  }

  return <TagComponent {...args} />
}
