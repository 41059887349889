import {
  Document, Image, Page, pdf, StyleSheet, Text, View,
} from '@react-pdf/renderer'
// import CantarellBoldFont from '../assets/fonts/Cantarell/Cantarell-Bold.ttf'
// import CantarellRegularFont from '../assets/fonts/Cantarell/Cantarell-Regular.ttf'
import colors from '../constants/colors'
import { PDFInvoiceSchema, ROW_TYPE } from '../types/ItemTypes'
import { formatToCurrency } from '../utils/format'

// Font.register({
//   family: 'Cantarell', fonts: [{ src: CantarellRegularFont }, { src: CantarellBoldFont, fontWeight: 'bold' }],
// })

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
    fontFamily: 'Helvetica',
    fontSize: 11,
    lineHeight: '1.4',
    textAlign: 'left',
  },
  section: {
    flexDirection: 'row',
    padding: '20em',
  },
  companyText: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
    fontSize: 11,
  },
  invoiceHeaderProp: {
    fontFamily: 'Helvetica-Bold',
    // color: '#6A329F',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  invoiceHeaderValue: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    fontFamily: 'Helvetica',
    textTransform: 'uppercase',
    color: colors.greyscale85,
    fontSize: 20,
    paddingRight: '50px',
    marginTop: '-15px',
  },
  tableHeaderSection: {
    backgroundColor: '#e4e5e7',
    padding: '4px 10px 3px',
    marginBottom: '3px',
  },
  tableHeader: {
    // alignSelf: 'center',
    fontFamily: 'Helvetica-Bold',
    color: '#262626',
    // color: '#6A329F',
    // textTransform: 'uppercase',
  },
  tableItem: {
    alignSelf: 'flex-start',
    padding: '3px 0 3px 10px',
  },
  amountHeader: {
    alignSelf: 'flex-end',
  },
  amountItem: {
    alignSelf: 'flex-end',
    paddingRight: '10px',
  },
  balanceSection: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  balanceItem: {
    fontFamily: 'Helvetica-Bold',
    alignSelf: 'flex-end',
    padding: '3px 0',
  },
  grandTotal: {
    fontSize: '15px',
  },
  tyItem: {
    alignSelf: 'flex-start',
  },
})

// export function GeneratePDFDocument() {
export function generatePDFDocument(schema: PDFInvoiceSchema) {
  const {
    balance, items, settings, jobDetails,
  } = schema
  // Create Document Component
  const buildTableItems = () => {
    const buildItems = () => {
      const obj: { item: any[], qty: any[], rate: any[], amount: any[] } = {
        item: [],
        qty: [],
        rate: [],
        amount: [],
      }
      for (const i of items) {
        if (i.type === ROW_TYPE.SUBTOTAL) {
          obj.item.push(<Text style={styles.tableItem}>{' '}</Text>)
          obj.qty.push(<Text style={styles.tableItem}>{' '}</Text>)
          obj.rate.push(<Text style={styles.tableItem}>{' '}</Text>)
          obj.amount.push(<Text style={[styles.tableItem, styles.amountItem]}>Subtotal: {formatToCurrency(i.total)}</Text>)
        } else {
          obj.item.push(<Text style={styles.tableItem}>{i.item}</Text>)
          obj.qty.push(<Text style={styles.tableItem}>{i.qty}</Text>)
          obj.rate.push(<Text style={styles.tableItem}>{formatToCurrency(i.rate)}</Text>)
          obj.amount.push(<Text style={[styles.tableItem, styles.amountItem]}>{formatToCurrency(i.amount)}</Text>)
        }
      }
      return obj
    }
    const obj = buildItems()
    return (
      <View style={styles.section}>
        <View style={styles.tableColumn}>
          <View style={styles.tableHeaderSection}>
            <Text style={styles.tableHeader}>Description</Text>
          </View>
          {obj.item}
        </View>
        <View style={styles.tableColumn}>
          <View style={styles.tableHeaderSection}>
            <Text style={styles.tableHeader}>Quantity</Text>
          </View>
          {obj.qty}
        </View>
        <View style={styles.tableColumn}>
          <View style={styles.tableHeaderSection}>
            <Text style={styles.tableHeader}>Rate</Text>
          </View>
          {obj.rate}
        </View>
        <View style={styles.tableColumn}>
          <View style={[styles.tableHeaderSection]}>
            <Text style={[styles.tableHeader, styles.amountHeader]}>Amount</Text>
          </View>
          {obj.amount}
        </View>
      </View>
    )
  }
  const buildCompanyTitle = () => (
    <View style={{ ...styles.section, justifyContent: 'space-between', alignContent: 'flex-start' }}>
      {/* <Text style={styles.companyText}>{settings.companySection}</Text> */}
      <View style={{ display: 'flex', flexDirection: 'column' }}>
        <Text style={styles.companyText}>BIG BUCK PUMPING, LLC</Text>
        <Text>{'6526 Old Brick Rd 120-306\nWindermere, FL  34786 US\nadmin@bigbuck.com\nbigbuck.com'}</Text>
      </View>

      <Text style={styles.title}>INVOICE</Text>

      <Image style={{ width: 100, height: 75, paddingRight: '20em' }} source="https://res.cloudinary.com/vulcan/image/upload/v1663183936/uploads/logos/niynosek2veroi9lzpcy.png" />
    </View>
  )
  const buildCompanyInfo = () => (
    <View style={styles.section}>
      <View style={{ display: 'flex', flexGrow: 2 }}>
        <View style={styles.tableColumn}>
          <Text style={styles.tableHeader}>Bill To</Text>
          <Text>{settings.customerName}</Text>
          <Text>{settings.billAddress}</Text>
        </View>
      </View>
      <View style={{
        display: 'flex', flexDirection: 'column', flexGrow: 1,
      }}
      >
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={styles.invoiceHeaderProp}>
            <Text>Invoice</Text>
          </View>
          <View style={styles.invoiceHeaderValue}>
            <Text>{settings.invoiceNumber || 0}</Text>
          </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={styles.invoiceHeaderProp}>
            <Text>Invoice Date</Text>
          </View>
          <View style={styles.invoiceHeaderValue}>
            <Text>{settings.invoiceDate.toLocaleDateString()}</Text>
          </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={styles.invoiceHeaderProp}>
            <Text>Due Date</Text>
          </View>
          <View style={styles.invoiceHeaderValue}>
            <Text>{settings.dueDate.toLocaleDateString()}</Text>
          </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={styles.invoiceHeaderProp}>
            <Text>Terms</Text>
          </View>
          <View style={styles.invoiceHeaderValue}>
            <Text>{settings.terms}</Text>
          </View>
        </View>
      </View>
    </View>
  )
  const buildSiteAddress = () => (
    <View style={styles.section}>
      <View style={styles.tableColumn}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={styles.tableHeader}>Jobsite: </Text>
          <Text>{settings.siteAddress.replace('\n', ' ')}</Text>
        </View>
        {settings.lot && (
          <View style={{ display: 'flex' }}>
            <Text>{settings.lot}</Text>
          </View>
        )}
      </View>
    </View>
  )
  const buildJobDetails = () => (
    <View style={styles.section}>
      <View style={styles.tableColumn}>
        <Text style={styles.tableHeader}>Job Date</Text>
        <Text>{jobDetails.jobDate.toLocaleDateString()}</Text>
      </View>
      <View style={styles.tableColumn}>
        <Text style={styles.tableHeader}>Pour Type</Text>
        <Text>{jobDetails.pourType}</Text>
      </View>
      <View style={styles.tableColumn}>
        <Text style={styles.tableHeader}>Job Duration</Text>
        <Text>{jobDetails.onSiteDurationHours} hours</Text>
      </View>
      <View style={styles.tableColumn}>
        <Text style={styles.tableHeader}>Travel Duration</Text>
        <Text>{jobDetails.travelDurationHours} hours</Text>
      </View>
      <View style={styles.tableColumn}>
        <Text style={styles.tableHeader}>Cubic Yards</Text>
        <Text>{jobDetails.cubicYards}</Text>
      </View>
    </View>
  )
  const buildBalance = () => (
    <View style={[styles.section]}>
      <View style={{ display: 'flex', flexGrow: 1 }}>
        <Text style={styles.tyItem}>{settings.thankYouMessage}</Text>
      </View>
      <View style={styles.balanceSection}>
        <Text style={styles.balanceItem}>Subtotal: {formatToCurrency(balance.subtotal)}</Text>
        {balance.discount > 0 && <Text style={styles.balanceItem}>Discount: {formatToCurrency(balance.discount)}</Text>}
        <Text style={styles.balanceItem}>Fuel Surcharge 7%: {formatToCurrency(balance.surcharge)}</Text>
        <Text style={[styles.balanceItem, styles.grandTotal]}>Balance Due: {formatToCurrency(balance.total)}</Text>
      </View>
    </View>
  )
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {buildCompanyTitle()}
        {buildCompanyInfo()}
        {buildSiteAddress()}
        {buildJobDetails()}
        {buildTableItems()}
        {buildBalance()}
      </Page>
    </Document>
  )
}

export async function openOrSavePDF(doc: JSX.Element, pdfName: string, open = false) {
  const blob = await pdf(doc).toBlob()
  saveAs(blob, pdfName, open)
}

export function getBlob(doc: JSX.Element) {
  return pdf(doc).toBlob()
}

export async function printPDF(b: Blob | null) {
  if (!b) return
  const fileURL = URL.createObjectURL(b)
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.src = fileURL
  iframe.addEventListener('load', () => {
    URL.revokeObjectURL(iframe.src)
    iframe.contentWindow?.focus()
    iframe.contentWindow?.print()
  })
  document.body.appendChild(iframe)
}

function saveAs(b: Blob, filename: string, openInWindow = false) {
  const pdfURL = URL.createObjectURL(b)
  if (!openInWindow) {
    const a = document.createElement('a')
    a.href = pdfURL
    a.download = filename
    a.click()
    URL.revokeObjectURL(pdfURL)
  } else {
    window.open(pdfURL, '_blank')
  }
}
