import { Typography } from 'antd'
import { useContext } from 'react'
import { InvoiceBalanceContext } from '../../hooks/useBalance'
import { formatToCurrency } from '../../utils/format'

export const SubtotalPreview = () => {
  const { balance } = useContext(InvoiceBalanceContext)
  const {
    discount, subtotal, surcharge, total,
  } = balance
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Title level={5} style={{ margin: 0, padding: '0.5rem' }}>Subtotal: {formatToCurrency(subtotal)}</Typography.Title>
        {discount > 0 && <Typography.Title level={5} style={{ margin: 0, padding: '0.5rem' }}>Discount: {formatToCurrency(discount)}</Typography.Title>}
        {surcharge > 0 && <Typography.Title level={5} style={{ margin: 0, padding: '0.5rem' }}>Fuel Surcharge 7%: {formatToCurrency(surcharge)}</Typography.Title>}
        <Typography.Title level={4} style={{ margin: 0, padding: '0.5rem' }}>Balance Due: {formatToCurrency(total)}</Typography.Title>
      </div>
    </>
  )
}
