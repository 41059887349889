import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  gql, MutationHookOptions, MutationTuple, useMutation,
} from '@apollo/client'
import { Modal, notification } from 'antd'
import { useEffect, useRef } from 'react'
import { MessageStatus, Mutation, MutationRequestSiteLocationArgs } from 'schema'

const REQUEST_SITE_LOCATION = gql`
  mutation RequestSiteLocation($data: RequestSiteLocationInput! $where: SiteWhereUniqueInput!) {
    requestSiteLocation(data: $data, where: $where) {
      id
      status
    }
  }
`

export const useRequestSiteLocation = (
  options?: MutationHookOptions<Mutation, MutationRequestSiteLocationArgs> & {
    showConfirmation?: boolean
  }
): MutationTuple<Mutation, MutationRequestSiteLocationArgs> => {
  const { showConfirmation, ...mutationOptions } = options || {}
  const lastVariables = useRef<MutationRequestSiteLocationArgs>()

  const [
    requestSiteLocation,
    requestSiteLocationResponse,
    ...rest
  ] = useMutation<Mutation, MutationRequestSiteLocationArgs>(REQUEST_SITE_LOCATION, mutationOptions)

  const requestSiteLocationProxy: typeof requestSiteLocation = (args) => {
    lastVariables.current = args?.variables
    return requestSiteLocation(args)
  }

  useEffect(() => {
    const errors = requestSiteLocationResponse?.error?.graphQLErrors
    const message = requestSiteLocationResponse?.data?.requestSiteLocation

    if ((errors || []).length > 0 && lastVariables.current) {
      const isDuplicate = (errors || []).every((error) => error.extensions?.code === 'DUPLICATE_ERROR')

      if (isDuplicate) {
        Modal.confirm({
          title: 'Message Already Sent',
          icon: <ExclamationCircleOutlined />,
          content: <>
            A location request for this site has already been sent to this number. <strong>Do you want to Resend?</strong>
          </>,
          cancelText: 'Cancel',
          okText: 'Resend',
          onOk() {
            if (!lastVariables.current) return

            requestSiteLocation({
              variables: {
                ...lastVariables.current,
                data: {
                  ...lastVariables.current.data,
                  forceResend: true,
                },
              },
            })
          },
        })
      } else {
        notification.error({
          top: 60,
          message: 'Error Sending Message',
          description: errors?.map((error) => error.message).join('\n'),
          duration: 5,
        })
      }
    } else if (showConfirmation && message) {
      if (message?.status === MessageStatus.Skipped) {
        notification.error({
          top: 60,
          message: 'Message NOT Sent',
          description: 'Person has opted out of these notifications',
          duration: 5,
        })
      } else {
        notification.success({
          top: 60,
          message: 'Message Sent',
          description: 'Site location request has been sent.',
          duration: 3,
        })
      }
    }
  }, [requestSiteLocationResponse])

  return [
    requestSiteLocationProxy,
    requestSiteLocationResponse,
    ...rest,
  ]
}
