import styled from 'styled-components'

export interface FixedWrapperProps {
  header?: React.ReactNode
  body?: React.ReactNode
  footer?: React.ReactNode
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  oveflow: hidden;
  position: relative;
`

const Header = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
`

const Body = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`

const FixedWrapper: typeof Wrapper & {
  Header: typeof Header,
  Body: typeof Body,
  Footer: typeof Footer,
} = Wrapper as any
FixedWrapper.Header = Header
FixedWrapper.Body = Body
FixedWrapper.Footer = Footer

export { FixedWrapper }
