import React, { useContext, useState } from 'react'
import { Order } from 'schema'

interface OrderPagesState {
  orders: Order[]
  setOrders: React.Dispatch<React.SetStateAction<OrderPagesState['orders']>>
  path: string | undefined
  setPath: React.Dispatch<React.SetStateAction<OrderPagesState['path']>>
}

const OrderPagesContext = React.createContext<OrderPagesState>({} as OrderPagesState)

type ContextProviderProps = React.ComponentProps<typeof OrderPagesContext.Provider>

type OrderPageStateProviderProps = Omit<ContextProviderProps, 'value'>

export const OrderPagesStateProvider = (props: OrderPageStateProviderProps) => {
  const [orders, setOrders] = useState<OrderPagesState['orders']>([])
  const [path, setPath] = useState<OrderPagesState['path']>()

  // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
  const state: OrderPagesState = {
    orders,
    setOrders,
    path,
    setPath,
  }

  return <OrderPagesContext.Provider {...props} value={state} />
}

export const useOrderPagesState = () => useContext(OrderPagesContext)
