import { gql } from '@apollo/client'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import React from 'react'
import { OrderCancellationReason } from 'schema'

export type OrderCancellationReasonSelectProps = ReferenceSelectProps<OrderCancellationReason>

const GET_CANCELLATION_REASONS = gql`
  query GetOrderCancellationReasons($where: QueryOrderCancellationReasonsWhereInput) {
    orderCancellationReasons(where: $where) {
      slug
      name
      sort
    }
  }
`

export const OrderCancellationReasonSelect: React.FC<OrderCancellationReasonSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Cancellation Reason',
  queryField: 'orderCancellationReasons',
  gql: GET_CANCELLATION_REASONS,
  fetchPolicy: 'cache-first',
  defaults: {
    defaultActiveFirstOption: false,
    showSearch: true,
    allowClear: true,
  },
}, props))

export default OrderCancellationReasonSelect
