import { orange } from '@ant-design/colors'
import { useState } from 'react'
import styled from 'styled-components'

import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_ORDER } from 'gql/orders'
import {
  Equipment, Mutation, MutationUpdateOrderArgs, Order, Query, QueryEquipmentArgs,
} from 'schema'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Modal, ModalProps } from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loading } from 'components/Loading'
import { GET_EQUIPMENT } from 'gql/equipment'

const Emphasize = styled.span`
  font-weight: bolder;
`

export interface MoveOrderToNewRouteModalProps extends Omit<ModalProps, 'visible'> {
  orderIds: Order['id'][]
  equipmentId: Equipment['id'] | null
}

export const MoveOrderToNewRouteModal = (props: MoveOrderToNewRouteModalProps) => {
  const { orderIds, equipmentId, ...modalInputProps } = props

  const [visible, setVisible] = useState<boolean>(true)

  const { data, loading: loadingRaw } = useQuery<Query, QueryEquipmentArgs>(
    GET_EQUIPMENT,
    {
      skip: !equipmentId,
      variables: {
        where: {
          id: {
            equals: equipmentId,
          },
        },
      },
    }
  )

  const equipment = data?.equipment?.[0]?.name

  const [moveOrderToNewRoute, { loading: mutationLoading, error: mutationError }] = useMutation<Mutation, MutationUpdateOrderArgs>(UPDATE_ORDER, {
    refetchQueries: ['GetEvents'],
  })

  const loading = loadingRaw && !data

  const modalProps: ModalProps = {
    title: <><QuestionCircleOutlined style={{ color: orange[4], marginRight: 5 }} /> Confirm New Route</ >,
    cancelText: 'Cancel',
    okText: 'Create New Route',
    width: '85%',
    ...modalInputProps,
    confirmLoading: mutationLoading,
    style: {
      ...modalInputProps?.style,
      minWidth: '300px',
      maxWidth: '700px',
    },
    visible,
    destroyOnClose: true,
    onCancel: async (evt) => {
      if (props.onCancel) {
        await props.onCancel(evt)
      }
      setVisible(false)
    },
    onOk: async (evt) => {
      // can't do this in parallel via Order mutation
      // would need to use Route mutation, which doesn't
      // support connecting Orders atm
      for (const orderId of orderIds) {
        // eslint-disable-next-line no-await-in-loop
        const resp = await moveOrderToNewRoute({
          variables: {
            data: {
              revision: -42,
              planned: {
                equipmentId,
              },
              route: {
                create: true,
              },
            },
            where: {
              id: orderId,
            },
          },
        })

        if (resp.errors) {
          return
        }
      }

      if (props.onOk) {
        await props.onOk(evt)
      }

      setVisible(false)
    },
  }

  return (
    <Modal {...modalProps}>
      {loading ?
        <Loading style={{ fontSize: '1.2rem' }} />
        : (
          <>
            Are you sure you want to move this order to a new Route {equipment ? <>for <Emphasize>{equipment}</Emphasize></> : 'on Notes'}?
          </>
        )}
      {mutationError && (
        <ErrorMessage
          title="Error Moving Order"
          size="small"
          style={{ marginTop: 20 }}
        >
          {mutationError.message}
        </ErrorMessage>
      )}
    </Modal>
  )
}
