import { gql, useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import {
  Mutation,
  MutationUpdateOrderArgs,
} from 'schema'

import { OrderStatusSelect, OrderStatusSelectProps } from 'components/form/reference-selects/OrderStatusSelect'
import { ErrorIcon } from 'components/icons'

interface OrderStatusUpdateProps extends Omit<OrderStatusSelectProps, 'id' | 'defaultValue' | 'value'> {
  id: number
  orderStatus: string | undefined
}

export const OrderStatusUpdate: React.FC<OrderStatusUpdateProps> = (props) => {
  const {
    id, onChange, orderStatus, ...rest
  } = props
  const selectProps: OrderStatusSelectProps = rest
  const [value, setValue] = useState<typeof orderStatus>(orderStatus)

  const [
    updateOrder,
    { loading, error },
  ] = useMutation<Mutation, MutationUpdateOrderArgs>(UPDATE_ORDER_STATUS)

  useEffect(() => {
    if (!orderStatus) return
    setValue(orderStatus)
  }, [orderStatus])

  selectProps.onChange = async (changedValue, option) => {
    const lastValue = value
    if (lastValue === undefined || lastValue === changedValue) return

    let newValue = changedValue?.toString()
    setValue(newValue)

    const response = await updateOrder({
      variables: {
        data: {
          revision: -42,
          status: newValue,
        },
        where: {
          id,
        },
      },
    })

    const updated = response?.data?.updateOrder?.status
    if (updated && updated !== newValue) {
      newValue = updated
      setValue(updated)
    }

    if (response.errors) {
      setValue(lastValue)
      return
    }

    if (onChange && newValue) {
      onChange(newValue, option)
    }
  }

  return (
    <>
      <OrderStatusSelect
        dropdownMatchSelectWidth={false}
        {...selectProps}
        value={value}
        loading={loading}
        bordered={selectProps.bordered === true}
      />

      {error && <div style={{ fontSize: '12px' }}> <ErrorIcon /> Error: {error.message} </div>}
    </>
  )
}

export const UPDATE_ORDER_STATUS = gql`
  mutation UpdateOrderStatus(
    $data: OrderUpdateInput!
    $where: OrderWhereUniqueInput!
  ) {
    updateOrder(
      data: $data
      where: $where
    ) {
      id
      revision
      status
      statusDetails {
        slug
        name
        color
      }
    }
  }
`
