import { DataGridCalculateArgs, DataGridMiddleware } from './DataGrid'

export const fieldMiddleware = (
  field: string,
  fn: (
    args: DataGridCalculateArgs<any>,
    next: (args: DataGridCalculateArgs<any>) => any
  ) => any
): DataGridMiddleware => (middlewareField, args, next) => {
  if (field !== middlewareField) return next(args)
  return fn(args, next)
}
