import { DatePicker as AntDatePicker } from 'antd'
import { DatePickerProps as AntDatePickerProps } from 'antd/lib/date-picker'
import { Moment } from 'moment'
import { SharedTimeProps } from 'rc-picker/lib/panels/TimePanel'
import React, { ComponentRef } from 'react'
import styled from 'styled-components'

export type DatePickerRef = ComponentRef<typeof AntDatePicker>

export type DatePickerCustomProps = {
  editing?: boolean
  showTime?: boolean | SharedTimeProps<Moment>
  hideInput?: boolean
}

export type DatePickerProps = DatePickerCustomProps & AntDatePickerProps

const StyledAntDatePicker = styled(AntDatePicker)`
  &.read-only,
  &.read-only input
  {
    border-color: transparent;
    background-color: transparent;
    outline-color: transparent;
    box-shadow: none;
  }

  &.hide-input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
  }
`

export const DatePicker = React.forwardRef<DatePickerRef, DatePickerProps>((props, ref) => {
  const { editing, hideInput, ...args } = props
  if (editing === false) {
    args.className = `${args.className || ''} read-only`
    args.inputReadOnly = true
    args.open = false
    args.suffixIcon = null
    args.allowClear = false
  }
  if (args.format === undefined) {
    args.format = 'MM/DD/YYYY'
  }
  if (args.autoComplete === undefined) {
    args.autoComplete = 'off'
  }
  if (hideInput) {
    args.className = `${args.className || ''} hide-input`
  }
  return <StyledAntDatePicker datas-lpignore="true" {...args} ref={ref} />
})

export default DatePicker
