import FormValues from '../FormValues'

const durationUnit = 'seconds'

export const fillScheduleTimes = (schedule: FormValues['details']['schedule']) => {
  if (!schedule) return schedule

  let oneHasTime = false

  // TODO: make step calculator smarter and don't just 0 out non pour times here
  schedule.forEach((entry) => {
    if (entry.step === 'pour') return
    entry.startTime = undefined
    entry.endTime = undefined
  })
  // END TODO

  schedule.forEach((entry) => {
    oneHasTime = oneHasTime || entry.startTime !== undefined || entry.startTime !== undefined

    if ((entry.duration || 0) > 0 && entry.startTime && !entry.endTime) {
      entry.endTime = entry.startTime.clone().add(entry.duration, durationUnit)
    }

    if ((entry.duration || 0) > 0 && entry.endTime && !entry.startTime) {
      entry.startTime = entry.endTime.clone().subtract(entry.duration, durationUnit)
    }

    if (entry.startTime && entry.endTime) {
      const calculatedDuration = entry.endTime.diff(entry.startTime, durationUnit)
      if (entry.duration === undefined) {
        entry.duration = calculatedDuration
      }

      if (entry.duration !== calculatedDuration) {
        entry.endTime = undefined
      }
    }
  })

  if (!oneHasTime) {
    return schedule
  }

  // const durationByIndex = schedule.map((item) => item.duration)
  // const durationsByIndexReversed = [...durationByIndex].reverse()
  // const timesByIndex = schedule.map((item) => [ item.startTime, item.endTime ])

  for (let i = (schedule.length - 1); i >= 0; i--) {
    const entry = schedule[i]

    if (!entry.startTime) {
      let searchIndex = i - 1
      let durationPad = 0
      let baseTime: moment.Moment | undefined

      for (searchIndex; searchIndex >= 0; searchIndex--) {
        if (schedule[searchIndex].endTime) {
          baseTime = schedule[searchIndex].endTime
          break
        }

        durationPad += schedule[searchIndex].duration || 0

        if (schedule[searchIndex].startTime) {
          baseTime = schedule[searchIndex].startTime
          break
        }
      }

      if (baseTime) {
        entry.startTime = baseTime.clone().add(durationPad, durationUnit)
      }
    }

    if (!entry.endTime && entry.startTime) {
      entry.endTime = entry.startTime.clone().add(entry.duration || 0, durationUnit)
    }
  }

  for (let i = 0; i < schedule.length; i++) {
    const entry = schedule[i]

    if (!entry.endTime) {
      let searchIndex = i + 1
      let durationPad = 0
      let baseTime: moment.Moment | undefined

      for (searchIndex; searchIndex < schedule.length; searchIndex++) {
        if (schedule[searchIndex].startTime) {
          baseTime = schedule[searchIndex].startTime
          break
        }

        durationPad += schedule[searchIndex].duration || 0

        if (schedule[searchIndex].endTime) {
          baseTime = schedule[searchIndex].endTime
          break
        }
      }

      if (baseTime) {
        entry.endTime = baseTime.clone().subtract(durationPad, durationUnit)
      }
    }

    if (!entry.startTime && entry.endTime) {
      entry.startTime = entry.endTime.clone().subtract(entry.duration || 0, durationUnit)
    }
  }

  return schedule
}
