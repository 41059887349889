import { Collapse } from 'components/common/Collapse'
import { byFieldPathAsc, mulitSort } from 'helpers/sortting'
import { Document as DocumentComponent } from './Document'
import { GetEquipmentDocumentsQuery } from './__generated__/ GetEquipmentDocuments'

type Documents = NonNullable<GetEquipmentDocumentsQuery['equipment'][0]>['documents']
type Document = Documents[0]

export type DocumentListSort = NonNullable<Parameters<Array<Document>['sort']>[0]>

export const DocumentListSorters: Record<string, DocumentListSort> = {
  name: byFieldPathAsc(['typeDetails', 'name']),
  pinned: byFieldPathAsc(['pinned']),
  default: (a, b) => (
    mulitSort(DocumentListSorters.pinned, DocumentListSorters.name)(a, b)
  ),
}

interface DocumentListProps {
  documents: Documents
  sort?: DocumentListSort
}

export const DocumentList = (props: DocumentListProps) => {
  const sort = props.sort || DocumentListSorters.default
  const documents = props.documents.slice().sort(sort)

  const defaultActiveKey = documents.length <= 2 ? documents.slice(0, 2).map(({ id }) => id) : undefined

  return (
    <Collapse mode="list" defaultActiveKey={defaultActiveKey}>
      {documents.map((doc) => (
        <DocumentComponent key={doc.id} document={doc} />
      ))}
    </Collapse>
  )
}
