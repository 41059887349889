import { InfoCircleOutlined } from '@ant-design/icons'
import { useCreation } from 'ahooks'
import { Tooltip } from 'components/common/Tooltip'
import { FormItem } from 'components/form/FormItem'
import { ViewRoutePopover } from 'components/route/ViewRotuePopover'
import { timeWithZoneToMoment } from 'helpers/datetime'
import { pushAt } from 'helpers/pushAt'
import { roundTo } from 'helpers/rounding'
import { useOutgoingTravelEstimates } from 'hooks/useTravelEstimates'
import {
  compact, flow, groupBy, isNil, isNumber, max, pick, toNumber,
} from 'lodash'
import fpMap from 'lodash/fp/map'
import { Duration } from 'luxon'
import { Moment } from 'moment'
import { useMemo } from 'react'
import { OrderMetric } from 'schema'
import { useOrderBillingState } from '../../OrderBillingState'
import { TableCol } from '../TableCol'
import { TableRow } from '../TableRow'
import { OrderCompareTableFragmentFragment } from '../__generated__'
import { HoursInput } from './helpers/HoursInput'
import { TimeRangeFields } from './helpers/TimeRangeFields'

const mapToNumbers = fpMap(toNumber)

const lastAndCurrentWaypoint = (order: OrderCompareTableFragmentFragment | undefined) => {
  if (!order) return []

  const { route } = order
  const waypointId = order.routeWaypointId

  if (!route) return []
  if (!waypointId) return []

  const waypointIndex = route.waypoints.findIndex((wp) => wp.id === waypointId)
  const waypoint = route.waypoints[waypointIndex]
  const lastWaypoint = route.waypoints[waypointIndex - 1]

  return [lastWaypoint, waypoint]
}

const key = 'travelHours'

export const TravelDuration = () => {
  const {
    details,
    order,
    placeholders,
    isEditing,
    register: registerRaw,
  } = useOrderBillingState<OrderCompareTableFragmentFragment>()

  const fields = useCreation(() => {
    const allFields: string[] = []

    const register: typeof registerRaw = (...args) => {
      const { name } = args[0]
      allFields.push(name)
      registerRaw(...args)
    }

    const [lastWaypoint, waypoint] = lastAndCurrentWaypoint(order)
    if (!lastWaypoint || !waypoint) return allFields

    const durationFields: string[] = []
    const plannedStartTime = lastWaypoint.scheduledDepartureTime
    if (plannedStartTime) {
      const name = `planned|${key}|startTime`
      const value = timeWithZoneToMoment(plannedStartTime, lastWaypoint.address.timezone)
      register({ name, value })
    }

    const plannedEndTime = waypoint.scheduledArrivalTime
    if (plannedEndTime) {
      const name = `planned|${key}|endTime`
      const value = timeWithZoneToMoment(plannedEndTime, waypoint.address.timezone)
      register({ name, value })
    }

    register({
      name: `planned|${key}|duration`,
      dependsOn: [`planned|${key}|startTime`, `planned|${key}|endTime`],
      calculate: ({ current, dependentData }) => {
        if (!isNil(current)) return current

        const start: Moment = dependentData[`planned|${key}|startTime`]
        const end: Moment = dependentData[`planned|${key}|endTime`]
        if (start && end) {
          return end.diff(start)
        }
        return undefined
      },
    })
    durationFields.push(`planned|${key}|duration`)

    const actualWithReportedTime = Object.keys(details || {}).find((detailName) => {
      if (!details) return
      if (!detailName.startsWith('actual.')) return
      if (detailName === 'actual.operator') return
      const schedule = details[detailName].schedule || []
      const hasTime = schedule.some((entry) => entry.startTime || entry.endTime)
      return hasTime
    }) || 'actual.operator'

    if (actualWithReportedTime) {
      const actualStartTime = lastWaypoint.actualDepartureTime
      const actualEndTime = waypoint.actualArrivalTime

      if (actualStartTime && actualEndTime) {
        const startTimeKey = `${actualWithReportedTime}|${key}|startTime`
        register({
          name: startTimeKey,
          value: timeWithZoneToMoment(actualStartTime, lastWaypoint.address.timezone),
        })

        const endTimeKey = `${actualWithReportedTime}|${key}|endTime`
        register({
          name: endTimeKey,
          value: timeWithZoneToMoment(actualEndTime, lastWaypoint.address.timezone),
        })

        const durationKey = `${actualWithReportedTime}|${key}|duration`
        register({
          name: durationKey,
          dependsOn: [startTimeKey, endTimeKey],
          calculate: ({ current, dependentData }) => {
            if (!isNil(current)) return current

            const start: Moment = dependentData[`${actualWithReportedTime}|${key}|startTime`]
            const end: Moment = dependentData[`${actualWithReportedTime}|${key}|endTime`]
            if (start && end) {
              return end.diff(start)
            }
            return undefined
          },
        })
        durationFields.push(durationKey)
      }
    }

    const billableValue = hoursToMillis(details?.billable?.metrics.find((item) => item.key === key)?.value)

    register({
      name: `billable|${key}|duration`,
      dependsOn: durationFields,
      value: billableValue,
      autofill: true,
      suggest: ({ dependentData }) => {
        const actualFields = durationFields.filter((field) => field.startsWith('actual.'))
        const actualMax = flow(Object.values, mapToNumbers, compact, max)(pick(dependentData, actualFields))
        if (!isNil(actualMax)) {
          return actualMax
        }

        return dependentData[`planned|${key}|duration`]
      },
      onSave: (update, value) => {
        if (isNil(value)) return update
        const asHours = Duration.fromMillis(value).as('hours')
        return pushAt(update, ['billable', 'metrics', 'upsert'], {
          key: 'travelHours',
          value: asHours,
        })
      },
    })

    return allFields
  }, [details])

  const fieldsBySection = useMemo(() => (
    groupBy(fields, (field) => field.split('|')[0])
  ), [fields])

  return (
    <TableRow>
      <TableCol section="title">
        Travel
        {order?.route?.id && (
          <div>
            <ViewRoutePopover routeId={order?.route?.id} />
          </div>
        )}
      </TableCol>
      {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        Object.entries(fieldsBySection).map(([section, fields]) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const durationField = fields.find((f) => f === `${section}|${key}|duration`)!
          const startField = fields.find((f) => f === `${section}|${key}|startTime`)
          const endField = fields.find((f) => f === `${section}|${key}|endTime`)

          return (
            <TableCol section={section} key={section}>
              <FormItem name={durationField}>
                <HoursInput
                  editing={isEditing(section as any)}
                  placeholder={placeholders[durationField]}
                />
              </FormItem>

              {startField && endField && (
                <TimeRangeFields
                  startField={startField}
                  endField={endField}
                  relativeTimezone={order?.branch?.timezone}
                />
              )}

              {section === 'planned' &&
                <EstimatedDriveTimeCallout />}
            </TableCol>
          )
        })
      }
    </TableRow>
  )
}

const EstimatedDriveTimeCallout = () => {
  const { order, details, register } = useOrderBillingState<OrderCompareTableFragmentFragment>()
  const [lastWaypoint, waypoint] = lastAndCurrentWaypoint(order)

  const didTravelFromYard = order?.branch?.yards?.some((yard) => yard.id === lastWaypoint?.address?.id)
  const defaultYard = order?.branch?.yards?.find((yard) => yard.id && yard.id === order?.route?.waypoints?.[0]?.address?.id) || order?.branch?.yards?.[0]

  const { data: travelEstimates } = useOutgoingTravelEstimates(defaultYard?.id, didTravelFromYard ? [] : compact([waypoint?.address?.id]))

  const showCallout = useCreation(() => {
    const duration = travelEstimates?.[0]?.duration
    if (!duration) return false
    if (!waypoint) return false

    const plannedEndTime = waypoint.scheduledArrivalTime
    if (!plannedEndTime) return false

    const durationRounded = roundTo(duration.as('minutes'), 5)

    register({
      name: `planned|${key}|endTime`,
      value: timeWithZoneToMoment(plannedEndTime, waypoint.address.timezone),
    })

    register({
      name: `planned|${key}|startTime`,
      value: timeWithZoneToMoment(plannedEndTime, waypoint.address.timezone).subtract({
        minutes: durationRounded,
      }),
    })

    const billableValue = hoursToMillis(details?.billable?.metrics.find((item) => item.key === key)?.value)

    register({
      name: `billable|${key}|duration`,
      value: billableValue,
      autofill: true,
      suggest: () => duration,
      onSave: (update, value) => {
        if (isNil(value)) return update
        const asHours = Duration.fromMillis(value).as('hours')
        return pushAt(update, ['billable', 'metrics', 'upsert'], {
          key: 'travelHours',
          value: asHours,
        })
      },
    })

    return true
  }, [travelEstimates, waypoint])

  if (!showCallout) return null

  return (
    <div>
      <Tooltip
        placement="right"
        trigger={['click']}
        title={(
          <>
            Order was serviced from a previous stop or job site in a Route.
            An estimated travel time from {defaultYard?.name || 'the yard'} to {order?.site?.name || 'this site'} is provided here.
          </>
        )}
      >
        <span style={{ cursor: 'pointer', fontSize: '0.8em' }}>Estimated Travel Time <InfoCircleOutlined style={{ marginLeft: '4px', verticalAlign: 'middle' }} /></span>
      </Tooltip>
    </div>
  )
}

const hoursToMillis = (value: OrderMetric['value']) => {
  if (isNil(value)) return undefined
  const hours = isNumber(value) ? value : parseFloat(value)
  return Duration.fromObject({ hours }).as('milliseconds')
}
