import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip, TooltipProps } from 'components/common/Tooltip'
import React from 'react'
import styled from 'styled-components'

type WithIconTooltipProps = React.PropsWithChildren<{
  icon?: React.Component
  position?: 'start' | 'end'
  title?: TooltipProps['title']
  tooltipProps?: Partial<TooltipProps>
}>

const IconWrap = styled.span<{ position: WithIconTooltipProps['position'] }>`
  > .anticon {
    font-size: 0.9em;
    cursor: pointer;

    ${(props) => props.position === 'start' && `
      margin-right: 0.5em;
    `}
    ${(props) => props.position === 'end' && `
      margin-left: 0.5em;
    `}
  }
`

export const WithIconTooltip = (props: WithIconTooltipProps) => {
  const {
    children, icon, title, tooltipProps,
  } = props

  const position = props.position || 'end'

  const iconWithDefault = icon || <InfoCircleOutlined />

  const tooltip = title && (
    <Tooltip mouseEnterDelay={0.4} {...tooltipProps} title={title}>
      <IconWrap position={position}>
        {iconWithDefault}
      </IconWrap>
    </Tooltip>
  )

  return (
    <>
      {position === 'start' && tooltip}
      {children}
      {position === 'end' && tooltip}
    </>
  )
}
