export interface ProductServiceItem {
  id: number,
  slug: string,
  name: string,
  itemName: string,
  description?: string,
  price: number,
  taxable: boolean,
}

export type ProductServiceList = ProductServiceItem[]

export const EquipmentList: ProductServiceList = [
  {
    id: 7,
    name: '4" Trailer Pump Hourly',
    description: '4" Concrete Pump Hourly',
    price: 143.93,
    taxable: false,
    slug: '4" Trailer.onSiteHours',
  },
  {
    id: 8,
    name: '4" Trailer Pump Travel',
    description: '4" Concrete Pump Travel',
    price: 143.93,
    taxable: false,
    slug: '4" Trailer.travelHours',
  },
  {
    id: 9,
    name: '4" Trailer Pump Yardage',
    description: '4" Concrete Pump Yardage',
    price: 3,
    taxable: false,
    slug: '4" Trailer.cubicYards',
  },
  {
    id: 10,
    name: '28m Hourly',
    description: '28m Boom Pump Hourly',
    price: 135.93,
    taxable: false,
    slug: '28m.onSiteHours',
  },
  {
    id: 11,
    name: '28m Travel',
    description: '28m Boom Pump Travel',
    price: 135.93,
    taxable: false,
    slug: '28m.travelHours',
  },
  {
    id: 12,
    name: '28m Yardage',
    description: '28m Boom Pump Yardage',
    price: 2.5,
    taxable: false,
    slug: '28m.cubicYards',
  },
  {
    id: 13,
    name: '32m Hourly',
    description: '32m Boom Pump Hourly',
    price: 143.93,
    taxable: false,
    slug: '32m.onSiteHours',
  },
  {
    id: 14,
    name: '32m Travel',
    description: '32m Boom Pump Travel',
    price: 143.93,
    taxable: false,
    slug: '32m.travelHours',
  },
  {
    id: 15,
    name: '32m Yardage',
    description: '32m Boom Pump Yardage',
    price: 2.7,
    taxable: false,
    slug: '32m.cubicYards',
  },
  {
    id: 16,
    name: '36m Hourly',
    description: '36m Boom Pump Hourly',
    price: 149.93,
    taxable: false,
    slug: '36m.onSiteHours',
  },
  {
    id: 17,
    name: '36m Travel',
    description: '36m Boom Pump Travel',
    price: 149.93,
    taxable: false,
    slug: '36m.travelHours',
  },
  {
    id: 18,
    name: '36m Yardage',
    description: '36m Boom Pump Yardage',
    price: 3,
    taxable: false,
    slug: '36m.cubicYards',
  },
  {
    id: 19,
    name: '38m Hourly',
    description: '38m Boom Pump Hourly',
    price: 155.92,
    taxable: false,
    slug: '38m.onSiteHours',
  },
  {
    id: 20,
    name: '38m Travel',
    description: '38m Boom Pump Travel',
    price: 155.92,
    taxable: false,
    slug: '38m.travelHours',
  },
  {
    id: 21,
    name: '38m Yardage',
    description: '38m Boom Pump Yardage',
    price: 3.3,
    taxable: false,
    slug: '38m.cubicYards',
  },
  {
    id: 22,
    name: '42m Hourly',
    description: '42m Boom Pump Hourly',
    price: 167.92,
    taxable: false,
    slug: '42m.onSiteHours',
  },
  {
    id: 23,
    name: '42m Travel',
    description: '42m Boom Pump Travel',
    price: 167.92,
    taxable: false,
    slug: '42m.travelHours',
  },
  {
    id: 24,
    name: '42m Yardage',
    description: '42m Boom Pump Yardage',
    price: 3.59,
    taxable: false,
    slug: '42m.cubicYards',
  },
  {
    id: 25,
    name: '47m Hourly',
    description: '47m Boom Pump Hourly',
    price: 203.9,
    taxable: false,
    slug: '47m.onSiteHours',
  },
  {
    id: 26,
    name: '47m Travel',
    description: '47m Boom Pump Travel',
    price: 203.9,
    taxable: false,
    slug: '47m.travelHours',
  },
  {
    id: 27,
    name: '47m Yardage',
    description: '47m Boom Pump Yardage',
    price: 4.19,
    taxable: false,
    slug: '47m.cubicYards',
  },
  {
    id: 28,
    name: '50m Hourly',
    description: '50m Boom Pump Hourly',
    price: 214.7,
    taxable: false,
    slug: '50m.onSiteHours',
  },
  {
    id: 29,
    name: '50m Travel',
    description: '50m Boom Pump Travel',
    price: 214.7,
    taxable: false,
    slug: '50m.travelHours',
  },
  {
    id: 30,
    name: '50m Yardage',
    description: '50m Boom Pump Yardage',
    price: 4.29,
    taxable: false,
    slug: '50m.cubicYards',
  },
  {
    id: 31,
    name: '52m Hourly',
    description: '52m Boom Pump Hourly',
    price: 220.05,
    taxable: false,
    slug: '52m.onSiteHours',
  },
  {
    id: 32,
    name: '52m Travel',
    description: '52m Boom Pump Travel',
    price: 220.05,
    taxable: false,
    slug: '52m.travelHours',
  },
  {
    id: 33,
    name: '52m Yardage',
    description: '52m Boom Pump Yardage',
    price: 4.35,
    taxable: false,
    slug: '52m.cubicYards',
  },
  {
    id: 34,
    name: '56m Hourly',
    description: '56m Boom Pump Hourly',
    price: 239.89,
    taxable: false,
    slug: '56m.onSiteHours',
  },
  {
    id: 35,
    name: '56m Travel',
    description: '56m Boom Pump Travel',
    price: 239.89,
    taxable: false,
    slug: '56m.travelHours',
  },
  {
    id: 36,
    name: '56m Yardage',
    description: '56m Boom Pump Yardage',
    price: 4.49,
    taxable: false,
    slug: '56m.cubicYards',
  },
  {
    id: 37,
    name: '58m Hourly',
    description: '58m Boom Pump Hourly',
    price: 239.89,
    taxable: false,
    slug: '58m.onSiteHours',
  },
  {
    id: 38,
    name: '58m Travel',
    description: '58m Boom Pump Travel',
    price: 239.89,
    taxable: false,
    slug: '58m.travelHours',
  },
  {
    id: 39,
    name: '58m Yardage',
    description: '58m Boom Pump Yardage',
    price: 4.49,
    taxable: false,
    slug: '58m.cubicYards',
  },
  {
    id: 40,
    name: 'Cancellation',
    description: undefined,
    price: 550,
    taxable: false,
    slug: 'cancellation',
  },
  {
    id: 41,
    name: 'Convenience Fee',
    description: 'CC Processing Fee',
    price: 0,
    taxable: false,
    slug: 'convenience_fee',
  },
  {
    id: 42,
    name: 'Delivery/Pickup',
    description: undefined,
    price: 57.5,
    taxable: false,
    slug: 'delivery_pickup',
  },
  {
    id: 43,
    name: 'Extra Hose',
    description: 'Extra Hose',
    price: 1.15,
    taxable: false,
    slug: 'extra_hose',
  },
  {
    id: 44,
    name: 'Extra Man',
    description: 'Extra Man',
    price: 40.25,
    taxable: false,
    slug: 'extra_man',
  },
  {
    id: 45,
    name: 'Flat Rate',
    description: 'Flat Rate',
    price: 950,
    taxable: false,
    slug: 'flat_rate',
  },
  {
    id: 46,
    name: 'Fuel Surcharge',
    description: 'Fuel Surcharge',
    price: 0.1,
    taxable: false,
    slug: 'fuel_surcharge',
  },
  {
    id: 47,
    name: 'Late Fee',
    description: undefined,
    price: 0,
    taxable: false,
    slug: 'late_fee',
  },
  {
    id: 48,
    name: 'Maintenance Service',
    description: undefined,
    price: 0,
    taxable: false,
    slug: 'maintenance_service',
  },
  {
    id: 49,
    name: 'Mechanic Labor',
    description: undefined,
    price: 0,
    taxable: false,
    slug: 'mechanic_labor',
  },
  {
    id: 50,
    name: 'Primer',
    description: 'Primer',
    price: 28.75,
    taxable: false,
    slug: 'primer',
  },
  {
    id: 51,
    name: 'Wash Out Boxes',
    description: 'Wash Out Boxes',
    price: 150,
    taxable: true,
    slug: 'wash_out_boxes',
  },
].map((item) => ({ ...item, itemName: item.name }))

export function getItemFromId(items: ProductServiceList, id: number): ProductServiceItem | undefined {
  return items.find((v) => v.id === id)
}
