import { parseLatitude } from 'helpers/parseCoordinates'
import React from 'react'
import { CoordinateInput, CoordinateInputProps, CoordinateInputRef } from './CoordinateInput'

export type LatitudeInputProps = Omit<CoordinateInputProps, 'min' | 'max' | 'stringMode' | 'coordinateParser'>

export const LatitudeInput = React.forwardRef<CoordinateInputRef, LatitudeInputProps>((props, ref) => {
  const args: CoordinateInputProps = {
    placeholder: 'Latitude',
    ...props,
    coordinateParser: parseLatitude,
    min: -90,
    max: 90,
  }

  return <CoordinateInput {...args} />
})

export default LatitudeInput
