import { gql } from '@apollo/client'

export const POUR_TYPE_FRAGMENT = gql`
  fragment PourTypeFragment on PourType {
    id
    name
    pinned
    isCategory
    categoryId
    active
  }
`

export const GET_POUR_TYPES_QUERY = gql`
  query GetPourTypes($where: QueryPourTypesWhereInput) {
    pourTypes(where: $where) {
      ...PourTypeFragment
    }
  }
`

export const GET_POUR_TYPES = gql`
  ${GET_POUR_TYPES_QUERY}
  ${POUR_TYPE_FRAGMENT}
`

export const CREATE_POUR_TYPE = gql`
  mutation CreatePourType(
    $data: PourTypeInput!
  ) {
    createPourType(
      data: $data
    ) {
      ...PourTypeFragment
    }
  }
  ${POUR_TYPE_FRAGMENT}
`

export const UPDATE_POUR_TYPE = gql`
  mutation UpdatePourType(
    $data: PourTypeUpdateInput!
    $where: PourTypeWhereUniqueInput!
  ) {
    updatePourType(
      data: $data
      where: $where
    ) {
      ...PourTypeFragment
    }
  }
  ${POUR_TYPE_FRAGMENT}
`
