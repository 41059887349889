import { WatchQueryFetchPolicy } from '@apollo/client'
import { DateTime, Duration, DurationObject } from 'luxon'

declare global {
  interface Window { _csqc: Record<string, DateTime> }
}

export const useCachedQueryFetchPolicy = (
  key: string,
  { ttl }: { ttl?: Duration | DurationObject } = {}
): WatchQueryFetchPolicy => {
  window._csqc ||= {}

  if (
    !window._csqc[key]
  ) {
    // this is gross, but apollo cancels the
    // request if on rerender the fetch-policy
    // changes -- which on a lot of our components
    // happens multiple times first render
    setTimeout(() => {
      window._csqc[key] = DateTime.utc()
    }, 500)
    return 'cache-and-network'
  }

  if (
    ttl &&
    window._csqc[key] &&
    window._csqc[key].plus(ttl) < DateTime.utc()
  ) {
    // this is gross, but apollo cancels the
    // request if on rerender the fetch-policy
    // changes -- which on a lot of our components
    // happens multiple times first render
    setTimeout(() => {
      window._csqc[key] = DateTime.utc()
    }, 500)
    return 'cache-and-network'
  }

  return 'cache-first'
}
