import { useRef, useState } from 'react'
import styled from 'styled-components'

import { SettingOutlined } from '@ant-design/icons'
import { OrderFieldsSelector, OrderFieldsSelectorProps } from './OrderFieldsSelector'

const OrderFieldsSelectorHiddenInput = styled(OrderFieldsSelector)`
  visibility: hidden;
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
  margin-left: -13px;
`

export const OrderFieldsSelectorIcon = (props: Omit<OrderFieldsSelectorProps, 'open'>) => {
  const [open, setOpen] = useState<boolean>(false)
  const clickGuard = useRef<boolean>(false)

  const { style, className, ...rest } = props

  return (
    <>
      <SettingOutlined
        onClick={(evt) => {
          if (clickGuard.current) return
          setOpen(!open)
        }}
        style={style}
        className={className}
      />

      <OrderFieldsSelectorHiddenInput
        {...rest}
        open={open}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onDropdownVisibleChange={(open) => {
          clickGuard.current = true
          setOpen(open)
          if (props.onDropdownVisibleChange) {
            props.onDropdownVisibleChange(open)
          }
          setTimeout(() => {
            clickGuard.current = false
          }, 100)
        }}
      />
    </>
  )
}
