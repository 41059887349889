import { compact, isEmpty } from 'lodash'
import React, { useState } from 'react'

import {
  Mutation,
  MutationCreateUserArgs,
  MutationUpdateUserArgs, User, UserInput,
} from 'schema'

import { useMutation } from '@apollo/client'
import { CREATE_USER, GET_USERS, UPDATE_USER } from 'gql/users'

import {
  Button, Col, Row, Typography,
} from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'

import { FullName } from 'components/common/FullName'
import { PhonesFormList } from 'components/form//common/PhonesFormList'
import { Form } from 'components/form/Form'
import { Input } from 'components/form/inline-edit/Input'
import SwitchInput from 'components/form/inline-edit/Switch'
import { Loading } from 'components/Loading'
import { Footer, Header } from 'hooks/useContentLayout'
import { GetUserForFormQuery, useGetUserForFormQuery } from './__generated__/GetUserForForm'

const { Paragraph } = Typography

export interface UserFormProps {
  id?: number
  editing?: boolean
  onSubmission?: (user: User, action: 'update' | 'create') => void
  onCancel?: () => void
  customerId?: number
}

type UserFormValues = NonNullable<NonNullable<GetUserForFormQuery>['user']>

export const UserForm: React.FC<UserFormProps> = (props) => {
  const { id, onSubmission } = props
  const isNew = id === undefined
  const [editing, setEditing] = useState<boolean>(props.editing || isNew)

  const { data, error: queryError, loading: queryLoading } = useGetUserForFormQuery({
    skip: isNew,
    variables: !id ? undefined : { id },
  })

  const user = data?.user

  const [
    createUser,
    createUserResponse,
  ] = useMutation<Mutation, MutationCreateUserArgs>(CREATE_USER, {
    refetchQueries: [{
      query: GET_USERS,
    }],
  })

  const [
    updateUser,
    updateUserResponse,
  ] = useMutation<Mutation, MutationUpdateUserArgs>(UPDATE_USER)

  // eslint-disable-next-line unused-imports/no-unused-vars
  const { loading: mutationLoading, error: mutationError } = isNew ? createUserResponse : updateUserResponse

  const [form] = Form.useForm<UserFormValues>()

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.resetFields()
  }

  const onFinish = async (values: UserFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data: UserInput = values as any

    data.phones = compact(
      compact<any>(values.phones || []).map(({ number, sms, type }) => {
        if (!number) return
        return { number, sms, type }
      })
    )

    if (id) {
      const resp = await updateUser({
        variables: { data, where: { id } },
      })

      if (resp.errors) return

      setEditing(false)

      const updatedUser = resp?.data?.updateUser

      if (updatedUser && onSubmission) {
        onSubmission(updatedUser, 'update')
      }
    } else {
      const resp = await createUser({
        variables: { data },
      })

      if (resp.errors) return

      setEditing(false)

      const newUser = resp?.data?.createUser

      if (newUser && onSubmission) {
        onSubmission(newUser, 'create')
      }
    }
  }

  const getValues = () => {
    const formVals = form.getFieldsValue()
    if (!isEmpty(formVals)) return formVals
    return user || {}
  }

  if (queryError) {
    return (
      <ErrorMessage to="/users">
        <Paragraph>{queryError.message}</Paragraph>
      </ErrorMessage>
    )
  }

  if (queryLoading) return <Loading />

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="user-form"
        onFinish={onFinish}
        requiredMark={editing}
        scrollToFirstError
        initialValues={{
          active: true,
          ...user,
        }}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) => (
            prev.firstName !== current.firstName ||
            prev.lastName !== current.lastName
          )}
        >
          {() => (
            <Header hideIfOrphan>
              <FullName {...getValues()} />
            </Header>
          )}
        </Form.Item>

        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'First name is required',
            },
          ]}
        >
          <Input placeholder="First name" editing={editing} />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Last name is required',
            },
          ]}
        >
          <Input placeholder="Last name" editing={editing} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="username"
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
          ]}
        >
          <Input placeholder="Email Address" editing={editing} />
        </Form.Item>
        <PhonesFormList
          editing={editing}
          singleMode
          hideType
        />
        <Form.Item
          label="Active"
          name="active"
          valuePropName="checked"
        >
          <SwitchInput
            editing={editing}
          />
        </Form.Item>
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}
