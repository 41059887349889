import { gql } from '@apollo/client'
import { Order, OrderUpdateInput } from 'schema'
import { SetOptional } from 'type-fest'
import { UpdateOrderForBillingMutationOptions, useUpdateOrderForBillingMutation } from './__generated__/update'

gql`
  mutation UpdateOrderForBilling(
    $data: OrderUpdateInput!
    $where: OrderWhereUniqueInput!
  ) {
    updateOrder(
      data: $data
      where: $where
    ) {
      ...OrderForBillingFull
    }
  }
`

type MutationOptionsWithoutVariables = Omit<UpdateOrderForBillingMutationOptions, 'variables'>

export const useOrderBillingUpdate = (orderId: Order['id'], revision?: Order['revision']) => {
  const [mutation, mutationResponse] = useUpdateOrderForBillingMutation()

  const update = (
    data: SetOptional<OrderUpdateInput, 'revision'>,
    options?: MutationOptionsWithoutVariables
  ) => (
    mutation({
      ...options,
      variables: {
        data: {
          revision: revision || -42,
          ...data,
        },
        where: {
          id: orderId,
        },
      },
    })
  )

  return [update, mutationResponse] as [typeof update, typeof mutationResponse]
}

export type OrderBillingUpdateOrderFunction = ReturnType<typeof useOrderBillingUpdate>[0]
