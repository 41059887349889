import { compact } from 'lodash'
import styled from 'styled-components'

import { Drawer as AntDrawer } from 'antd'
import { DrawerProps as AntDrawerProps } from 'antd/lib/drawer'
import { useIsMobile } from 'hooks/useViewportMode'

export interface DrawerProps extends AntDrawerProps {
  noStyle?: boolean
}

const StyledAntDrawer = styled(AntDrawer)`
  &.noStyle {
    .ant-drawer-header, .ant-drawer-body {
      padding: 0;
      margin: 0;
    }
  }
`

export const Drawer: React.FC<DrawerProps> = (props: DrawerProps) => {
  const { noStyle, ...args } = props
  const isMobile = useIsMobile()

  args.className = compact([
    args.className,
    noStyle && 'noStyle',
  ]).join(' ')

  const width = isMobile ? '100%' : args.width || '475px'

  return <StyledAntDrawer mask={false} {...args} width={width} />
}

export default Drawer
