import { QueryLazyOptions, useLazyQuery, useQuery } from '@apollo/client'
import {
  Button, Col, Row, Typography,
} from 'antd'
import AntTable, { TableProps as AntTableProps } from 'antd/lib/table/Table'
import { CurrentBranchSelectorInline } from 'components/common/CurrentBranchSelector'
import { EquipmentDrawer } from 'components/drawers/EquipmentDrawer'
import { ErrorMessage } from 'components/ErrorMessage'
import { EquipmentFormProps } from 'components/form/EquipmentForm'
import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'
import { GET_EQUIPMENT } from 'gql/equipment'
import { DocumentNode } from 'graphql'
import { unitSize } from 'helpers/equipment'
import { byFieldAsc, byFieldDesc } from 'helpers/sortting'
import { activeColumn } from 'helpers/table/activeColumn'
import { Actions, Body, Header } from 'hooks/useContentLayout'
import { useEquipmentSort } from 'hooks/useEquipmentSort'
import { PageContent } from 'layouts/Content/PageContent'
import { uniqBy } from 'lodash'
import React, { useMemo, useRef } from 'react'
import {
  generatePath, Link, Route, useHistory, useParams,
  useRouteMatch,
} from 'react-router-dom'
import styled from 'styled-components'
import {
  Equipment,
  Query,
  QueryEquipmentArgs,
} from '../schema'

const { Paragraph, Title } = Typography

const Table: React.FC<AntTableProps<any>> = styled(AntTable)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

const useIndexQuery = (): [DocumentNode, QueryLazyOptions<QueryEquipmentArgs>] => {
  const { branchId } = useParams<{ branchId?: string }>()

  let where: any | null = null

  if (branchId) {
    where = {
      branchId: {
        equals: parseInt(branchId),
      },
    }
  }

  return [GET_EQUIPMENT, { variables: { where } }]
}

const Index = () => {
  const { branchId } = useParams<{ branchId?: string }>()
  const history = useHistory()
  const match = useRouteMatch()
  const indexQuery = useIndexQuery()

  let where: any | null = null

  if (branchId) {
    // eslint-disable-next-line unused-imports/no-unused-vars
    where = {
      branchId: {
        equals: parseInt(branchId),
      },
    }
  }

  const { loading, data, error } = useQuery<Query, QueryEquipmentArgs>(...indexQuery)

  const { equipmentSortter } = useEquipmentSort()
  const equipment = useMemo(() => (
    (data?.equipment || []).slice().sort((a, b) => (
      byFieldDesc('active')(a, b) || equipmentSortter(a, b)
    ))
  ), [data?.equipment, equipmentSortter])

  const typeFilters = useMemo(() => (
    uniqBy(
      equipment.map(({ typeDetails }) => ({
        text: typeDetails.name,
        value: typeDetails.slug,
      })),
      (typeDetails) => typeDetails.value
    ).sort(byFieldAsc('name'))
  ), [equipment])

  const columns = useRef([
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: {
        compare: byFieldAsc('name'),
      },
    },
    {
      title: 'Type',
      dataIndex: 'typeDetails',
      render: (typeDetails: Equipment['typeDetails']) => typeDetails.name,
      filters: typeFilters,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onFilter: (value: any, equipment: Equipment) => (
        equipment.type === value
      ),
      sorter: {
        compare: byFieldAsc('type'),
      },
    },
    {
      title: 'Size',
      dataIndex: 'size',
      sorter: {
        compare: byFieldAsc('size'),
      },
      render: (text: Equipment['type'], row: Equipment) => text && `${text}${unitSize(row.type) || ''}`,
    },
    activeColumn(),
  ]).current

  const onRow = (record: Equipment) => ({
    onClick: () => {
      history.push(`${match.url}/${record.id}`)
    },
  })

  return (
    <PageContent>
      <Header>
        <Title level={2}>
          Equipment at <CurrentBranchSelectorInline />
        </Title>
      </Header>

      <Actions>
        <Link to={`${match.url}/new`}>
          <Button type="primary">
            Add New Equipment
          </Button>
        </Link>
      </Actions>

      <Body>
        {error
          && (
            <ErrorMessage>
              <Paragraph>{error.message}</Paragraph>
            </ErrorMessage>
          )}
        <Table
          columns={columns}
          rowKey="id"
          dataSource={equipment}
          pagination={false}
          onRow={onRow}
          locale={{
            emptyText: loading ? 'Loading' : undefined,
          }}
          bordered
        />
      </Body>
    </PageContent>
  )
}

const Show = () => {
  const [refetchEquipment] = useLazyQuery(...useIndexQuery())
  const history = useHistory()
  const match = useRouteMatch()

  const { id: urlId, branchId: urlBranchId, edit: urlEdit } = useParams<{ id: string, branchId: string, edit?: string }>()

  const isNew = urlId === 'new'
  const id = isNew ? undefined : parseInt(urlId)
  const branchId = parseInt(urlBranchId)
  const currentPath = history.location.pathname
  const basePath = currentPath.slice(0, match.path.replace(':branchId?', branchId.toString()).indexOf('/:id'))

  const props: EquipmentFormProps = {
    id,
    branchId,
    editing: isNew || urlEdit === 'edit',
    onSubmission: (equipment, action) => {
      if (action === 'create' && equipment?.id) {
        refetchEquipment()

        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: `${basePath}/${equipment?.id}`,
        })
      }
    },
    onCancel: () => {
      if (isNew) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: basePath,
        })
      }
    },
  }

  return <EquipmentDrawer {...props} />
}

export const EquipmentPage = (_args: any) => {
  const history = useHistory()
  const match = useRouteMatch()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  return (
    <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
      <Col flex="auto" style={{ minWidth: 0 }}>
        <Route path={match.path} component={Index} />
      </Col>
      <RoutableDrawer showClose={false} onClose={() => { history.replace(basePath) }} width={450}>
        <Route path={`${match.path}/:id/:edit?`} component={Show} />
        <Route path={match.path} hideOnMobile>
          <div className="padded">
            Select a piece of Equipment to view and edit.
          </div>
        </Route>
      </RoutableDrawer>
    </Row>
  )
}

export default EquipmentPage
