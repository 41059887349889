import { Input, InputProps } from 'components/form/inline-edit/Input'
import { formatPhone } from 'helpers/formatPhone'
import React from 'react'

interface PhoneNumberInputProps extends Omit<InputProps, 'value'> {
  clickToCall?: boolean
  value?: Exclude<InputProps['value'], readonly string[]>
  defaultValue?: PhoneNumberInputProps['value']
}

const cleanNumber = (value: string) => value.replace(/\D/g, '').slice(0, 10)

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {
  const {
    value,
    defaultValue,
    clickToCall,
    onChange,
    onPressEnter,
    ...rest
  } = props

  const args: InputProps = rest

  args.value = formatPhone(value) || undefined
  args.defaultValue = formatPhone(defaultValue) || undefined

  args.onChange = (event) => {
    if (!onChange) return

    onChange({
      ...event,
      target: {
        ...event.target,
        value: cleanNumber(event.target.value),
      },
      currentTarget: {
        ...event.currentTarget,
        value: cleanNumber(event.currentTarget.value),
      },
    })
  }

  args.onPressEnter = (event) => {
    if (!onPressEnter) return

    onPressEnter({
      ...event,
      currentTarget: {
        ...event.currentTarget,
        value: cleanNumber(event.currentTarget.value),
      },
    })
  }

  if (clickToCall) {
    args.onMouseUp = (evt) => {
      if (props.onMouseUp) {
        props.onMouseUp(evt)
      }

      if (args.editing) return

      const hasSelection = Boolean(window.getSelection()?.toString())
      if (hasSelection) return

      const asString = value?.toString() || ''
      if (asString.length >= 10) {
        const phoneFull = asString.length > 10 ? asString : `+1${asString}`
        window.location.href = `tel:${phoneFull}`
      }
    }
  }

  return <Input {...args} />
}
