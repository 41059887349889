import { DateTime } from 'luxon'
import { Invoice } from '../../hooks/useInvoices'
import { useToday } from '../../hooks/useToday'
import { StatusBadge, StatusBadgeProps } from '../common/StatusBadge'

export type InvoiceStatusBadgeProps = Omit<StatusBadgeProps, 'status'> & {
  invoice?: Pick<Invoice, 'balance' | 'dueDate' | 'statusDetails'>
}

export const InvoiceStatusBadge = (props: InvoiceStatusBadgeProps) => {
  const { invoice, ...rest } = props
  const statusDetails = invoice?.statusDetails
  const today = useToday()

  if (!statusDetails) {
    return null
  }

  if (statusDetails.slug === 'overdue' && invoice.dueDate) {
    const dueDate = DateTime.fromISO(invoice.dueDate, { setZone: false })
    const days = Math.floor(today.diff(dueDate).as('days'))
    const name = `${statusDetails.name} (${days} days)`

    return (
      <StatusBadge
        {...rest}
        status={{
          ...statusDetails,
          name,
        }}
      />
    )
  }

  return <StatusBadge {...rest} status={invoice?.statusDetails} />
}
