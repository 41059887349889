import { gql } from '@apollo/client'
import {
  Button, Col, Row, Typography,
} from 'antd'
import { Divider } from 'components/common/Divider'
import { ErrorMessage } from 'components/ErrorMessage'
import { Form, FormProps } from 'components/form/Form'
import { Input } from 'components/form/inline-edit/Input'
import { Switch } from 'components/form/inline-edit/Switch'
import Loading from 'components/Loading'
import { unitSize } from 'helpers/equipment'
import { wrapInParens } from 'helpers/formatting'
import { narrowArray } from 'helpers/narrowArray'
import { Footer, Header } from 'hooks/useContentLayout'
import {
  compact, difference, isEmpty, isNil, pick,
} from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import {
  DocumentTypeScope,
  DocumentUpsertInput,
  EquipmentInput,
  EquipmentUpdateInput,
} from 'schema'
import { DocumentsFormList } from '../common/DocumentFormList'
import { DurationPicker } from '../inline-edit/DurationPicker'
import { EquipmentGroupSelect, equipmentGroupSelectOptionsGenerator } from '../reference-selects/EquipmentGroupSelect'
import { EquipmentTypeSelect } from '../reference-selects/EquipmentTypeSelect'
import {
  GetEquipmentForFormQuery, useCreateEquipmentMutation, useGetEquipmentForFormQuery, useUpdateEquipmentMutation,
} from './__generated__/EquipmentForm'

const { Paragraph } = Typography

type Equipment = NonNullable<NonNullable<GetEquipmentForFormQuery>['equipment'][0]>
type Document = NonNullable<Equipment['documents'][0]>

export interface EquipmentFormProps {
  id?: number
  branchId: number
  editing?: boolean
  onSubmission?: (equipment: Equipment, action: 'update' | 'create') => void
  onCancel?: () => void
}

gql`
  fragment EquipmentForForm on Equipment {
    id
    name
    displayName
    size
    description
    prepTime
    cleanTime
    type
    active
    typeDetails {
      name
      slug
      sort
    }
    branch {
      id
      name
    }
    group {
      id
      name
    }
    documents {
      id
      active
      attachments
      expirationDate
      fields {
        key
        value
      }
      pinned
      typeDetails {
        name
        slug
      }
    }
  }
`

export const EquipmentForm: React.FC<EquipmentFormProps> = (props) => {
  const { id, branchId, onSubmission } = props
  const isNew = id === undefined
  const [editing, setEditing] = useState<boolean>(props.editing || isNew)
  const [selectedType, setSelectedType] = useState<null | string>(null)
  const [showGroupSelect, setShowGroupSelect] = useState<boolean>(false)

  const { data, error: queryError, loading: queryLoading } = useGetEquipmentForFormQuery({
    variables: { id: id || -1 },
    skip: isNew,
  })

  const equipment = data?.equipment?.[0] || null

  useEffect(() => {
    if (equipment?.group?.id) {
      setShowGroupSelect(true)
    }
  }, [equipment?.group?.id])

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const generateValuesFromEquipment = (equipment: Equipment | null) => ({
    ...equipment,
    prepTime: secondsToMinutes(equipment?.prepTime),
    cleanTime: secondsToMinutes(equipment?.cleanTime),
    active: !equipment ? true : equipment?.active,
  })

  const initialValues = useMemo(() => (
    generateValuesFromEquipment(equipment)
  ), [equipment])

  const [createEquipment, createEquipmentResponse] = useCreateEquipmentMutation({
    refetchQueries: ['GetAvailableEquipment'],
  })
  const [updateEquipment, updateEquipmentResponse] = useUpdateEquipmentMutation({
    refetchQueries: ['GetAvailableEquipment'],
  })

  const { loading: mutationLoading, error: mutationError } = isNew ? createEquipmentResponse : updateEquipmentResponse

  const [form] = Form.useForm()

  const onCancel = () => {
    props.onCancel?.()
    setEditing(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    const stringVars = narrowArray('name', 'displayName')
    const durationVars = narrowArray('prepTime', 'cleanTime')
    const floatVars = narrowArray('size')
    const boolVars = narrowArray('active')

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data: EquipmentInput | EquipmentUpdateInput = {}

    for (const key of stringVars) {
      data[key] = values[key] !== '' ? values[key] : null
    }
    for (const key of durationVars) {
      data[key] = isNil(values[key]) ? null : minutesToSeconds(parseInt(values[key]))
    }
    for (const key of floatVars) {
      data[key] = values[key] && parseFloat(values[key])
    }
    for (const key of boolVars) {
      data[key] = values[key]
    }

    data.branchId = branchId
    data.type = values.type
    data.groupId = values.group?.id

    const documentsToDelete = difference(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      equipment?.documents?.map(({ id }) => id),
      // eslint-disable-next-line @typescript-eslint/no-shadow
      compact(values?.documents?.map(({ id }: Document) => id))
    )

    data.documents = {
      upsert: values?.documents?.map((document: Document) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const data: DocumentUpsertInput['data'] = {
          ...pick(document, [
            'attachments', 'expirationDate', 'pinned',
          ]),
          fields: document.fields?.map((field: any) => pick(field, ['key', 'value'])),
          type: document.typeDetails?.slug,
          typeName: document.typeDetails?.name,
        }

        const where = pick(document, ['id'])

        return {
          data,
          where,
        }
      }),
      // eslint-disable-next-line @typescript-eslint/no-shadow
      delete: documentsToDelete.length === 0 ? undefined : documentsToDelete.map((id) => ({ id })),
    }

    if (id) {
      const resp = await updateEquipment({
        variables: { data, where: { id } },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        update(cache, { data }) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const equipment = data?.updateOneEquipment
          if (equipment) {
            cache.modify({
              id: cache.identify(equipment),
              fields: (fieldValue, details) => {
                if (details.storeFieldName?.startsWith('documents(')) {
                  return details.DELETE
                }
                return fieldValue
              },
            })
          }
        },
      })

      if (resp.errors) return

      setEditing(false)

      const updatedEquipment = resp?.data?.updateOneEquipment

      if (updatedEquipment) {
        form.setFieldsValue(generateValuesFromEquipment(updatedEquipment))
        onSubmission?.(updatedEquipment, 'update')
      }
    } else {
      const resp = await createEquipment({
        variables: { data: data as EquipmentInput },
      })

      if (resp.errors) return

      setEditing(false)

      const newEquipment = resp?.data?.createOneEquipment

      if (newEquipment && onSubmission) {
        onSubmission(newEquipment, 'create')
      }
    }
  }

  const onValuesChange: FormProps['onValuesChange'] = (changedValues, all) => {
    if (changedValues.type) {
      setSelectedType(changedValues.type)
    }
  }

  if (queryError) {
    return (
      <ErrorMessage to={`/settings/branches/${branchId}/equipment`}>
        <Paragraph>{queryError.message}</Paragraph>
      </ErrorMessage>
    )
  }

  if (queryLoading) return <Loading />

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="equipment-form"
        initialValues={initialValues}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        requiredMark={editing}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) => (
            prev.name !== current.name
          )}
        >
          {() => (
            <Header hideIfOrphan>
              {form.getFieldValue('name') || equipment?.name}
            </Header>
          )}
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Equipment name is required',
            },
          ]}
        >
          <Input placeholder="Internal name for this piece of equipment" editing={editing} />
        </Form.Item>
        <Form.Item
          label="Billable Name"
          name="displayName"
        >
          <Input placeholder="Billable name/size shown to customers" editing={editing} />
        </Form.Item>
        <Form.Item
          label="Equipment Type"
          name="type"
        >
          <EquipmentTypeSelect
            editing={editing}
            resources={compact([
              equipment?.typeDetails,
            ])}
          />
        </Form.Item>
        <Form.Item
          label={`Size ${wrapInParens(unitSize(selectedType || equipment?.type)) || ''}`}
          name="size"
        >
          <Input
            type="number"
            placeholder="Standard equipment size"
            editing={editing}
          />
        </Form.Item>
        <Form.Item
          label="Display Group"
          name={['group', 'id']}
          style={{ display: showGroupSelect ? undefined : 'none' }}
        >
          <EquipmentGroupSelect
            editing={editing}
            optionsGenerator={(items) => {
              const options = equipmentGroupSelectOptionsGenerator(items)
              const hasOptions = !isEmpty(options)
              setShowGroupSelect(hasOptions)
              return options
            }}
            resources={compact([
              equipment?.group,
            ])}
          />
        </Form.Item>
        <Form.Item
          label="Prep Time"
          name="prepTime"
        >
          <DurationPicker editing={editing} maxDuration={180} minuteStep={5} />
        </Form.Item>
        <Form.Item
          label="Clean Time"
          name="cleanTime"
        >
          <DurationPicker editing={editing} maxDuration={180} minuteStep={5} />
        </Form.Item>
        <Form.Item
          label="Active"
          name="active"
          valuePropName="checked"
        >
          <Switch
            editing={editing}
          />
        </Form.Item>

        <Divider>Documents</Divider>
        <DocumentsFormList name="documents" scope={DocumentTypeScope.Equipment} editing={editing} />
      </Form>
      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button loading={mutationLoading} block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}

const minutesToSeconds = (minutes: number | null | undefined) => {
  if (isNil(minutes)) return undefined
  return minutes * 60
}

const secondsToMinutes = (seconds: number | null | undefined) => {
  if (isNil(seconds)) return undefined
  return seconds / 60.0
}
