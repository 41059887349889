import { isEmpty } from 'lodash'

import { useSchedulerState } from 'components/scheduler/SchedulerState'

import { Col, Row } from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loading } from 'components/Loading'

import { Scheduler } from 'components/scheduler/Scheduler'
import { SchedulerSidebar } from 'components/scheduler/SchedulerSidebar'
import { SchedulerDrawer } from './SchedulerDrawer'

export const ScheduleDesktopLayout = () => {
  const state = useSchedulerState()

  const loading = state.loading && <Loading />
  const error = !isEmpty(state.errors) && <ErrorMessage>{state.errors?.map((e) => e.message)}</ErrorMessage>
  const showContent = !loading && !error

  return (
    <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
      <Col flex="auto">
        {error}
        {loading}
        {showContent &&
          <Scheduler />}
      </Col>
      <Col flex="0 0 auto" style={{ height: '100%' }}>
        <SchedulerSidebar />
      </Col>

      <SchedulerDrawer />
    </Row>
  )
}
