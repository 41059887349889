import { Col, Row, RowProps } from 'antd'
import styled from 'styled-components'

export const VerticallyCentered = styled(Row).attrs((props) => ({
  ...props,
  justify: 'center',
  align: 'middle',
  children: <Col flex="auto" style={{ maxHeight: '100%', minHeight: '0px' }}>
    {props?.children}
  </Col>,
} as RowProps))`
  height: 100%;

  > .ant-col {
    flex: 1 1 auto;
    max-height: 100%;
  }
`
