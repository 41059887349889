import { ContactDrawer } from 'components/drawers/ContactDrawer'
import { byFieldDesc, byFieldPathAsc, mulitSort } from 'helpers/sortting'
import { Order } from 'schema'
import styled from 'styled-components'
import { RecursivePartial } from 'types'
import { FullName } from './FullName'
import { WithViewMore } from './WithViewMore'
import { Wrapped } from './Wrapped'

export interface OrderContactListProps {
  viewMore?: boolean
  showRole?: boolean
  contacts?: RecursivePartial<Order['contacts']>
}

const defaultProps: Partial<OrderContactListProps> = {
  viewMore: true,
  showRole: true,
}

const BasicUL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const OrderContactList = (props: OrderContactListProps) => {
  const {
    contacts, viewMore, showRole, ...rest
  } = props

  const sorter = mulitSort(byFieldDesc('default'), byFieldPathAsc(['role', 'name']), byFieldPathAsc(['role', 'name']))

  const sorted = contacts?.slice().sort(sorter)

  if (!sorted) return null

  return (
    <BasicUL {...rest}>
      {sorted?.map((orderContact, index) => (
        <WithViewMore
          key={index}
          // eslint-disable-next-line react/no-unstable-nested-components
          drawer={() => {
            if (!viewMore) return
            const contactId = orderContact?.contact?.id
            if (!contactId) return
            return <ContactDrawer key={contactId} id={contactId} />
          }}
        >
          <FullName {...orderContact?.contact} />
          {
            showRole && (
              <Wrapped in="parens">
                {orderContact.default && (sorted?.length || 0) > 1 ? 'Default' : orderContact.role?.name}
              </Wrapped>
            )
          }
        </WithViewMore>
      ))}
    </BasicUL>
  )
}

OrderContactList.defaultProps = defaultProps

export { OrderContactList }
