import { gql } from '@apollo/client'

export const INVENTORY_ITEM_SLIM_FRAGMENT = gql`
  fragment InventoryItemFields on InventoryItem {
    id
    name
    quantity
    category
    displayMultiplier
    displayUnit
    active
    visibility {
      orderForm
      orderBilling
    }
  }
`

export const INVENTORY_ITEM_FULL_FRAGMENT = gql`
  fragment InventoryItemFields on InventoryItem {
    id
    name
    quantity
    category
    description
    displayMultiplier
    displayUnit
    active
    visibility {
      orderForm
      orderBilling
    }
  }
`

export const GET_INVENTORY = gql`
  query GetInventory($where: QueryInventoryItemsWhereInput) {
    inventory(where: $where) {
      ...InventoryItemFields
    }
  }
  ${INVENTORY_ITEM_SLIM_FRAGMENT}
`

export const GET_INVENTORY_ITEM = gql`
  query GetInventory($id: Int) {
    inventory(where: { id: { equals: $id } }) {
      ...InventoryItemFields
    }
  }
  ${INVENTORY_ITEM_FULL_FRAGMENT}
`

export const CREATE_INVENTORY_ITEM = gql`
  mutation CreateInventoryItem(
    $data: InventoryItemInput!
  ) {
    createInventoryItem(
      data: $data
    ) {
      ...InventoryItemFields
    }
  }
  ${INVENTORY_ITEM_FULL_FRAGMENT}
`

export const UPDATE_INVENTORY_ITEM = gql`
  mutation UpdateInventoryItem(
    $data: InventoryItemUpdateInput!
    $where: InventoryItemWhereUniqueInput!
  ) {
    updateInventoryItem(
      data: $data
      where: $where
    ) {
      ...InventoryItemFields
    }
  }
  ${INVENTORY_ITEM_FULL_FRAGMENT}
`
