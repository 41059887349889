import { SiteDrawer } from 'components/drawers/SiteDrawer'
import { ResourceForm, SelectWithAddForm } from './SelectWithAddForm'
import { SiteSelect, SiteSelectProps } from './SiteSelect'

export const SiteSelectWithAdd = (props: Omit<SiteSelectProps, 'dropdownRender'>) => (
  SelectWithAddForm({
    name: 'Site',
    select: SiteSelect,
    selectProps: props,
    form: SiteDrawer as ResourceForm,
    formProps: {
      customerId: props.customerId,
    },
  })
)
