import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useSearchParams = () => {
  const location = useLocation()

  // Array here is to match react-router v6 useSearchParams interface
  return useMemo(() => (
    [new URLSearchParams(location.search)]
  ), [location.search])
}
