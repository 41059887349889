import { gql } from '@apollo/client'
import { UninitializedAccessHandler } from 'errors/UninitializedAccessHandler'
import React, { useContext } from 'react'
import { GetBranchesQuery, GetBranchesQueryHookResult, useGetBranchesQuery } from './__generated__/useBranches'

gql`
  query GetBranches {
    branches {
      id
      active
      name
      timezone
      yards {
        id
        name
        address {
          ...AddressFields
        }
      }
      address {
        ...AddressFields
      }
    }
  }

  fragment AddressFields on Address {
    id
    name
    street
    street2
    city
    state
    zip
    lat
    lng
    timezone
  }
`

export type BranchesContextValueType = Omit<GetBranchesQueryHookResult, 'data' | 'previousData'> & {
  branches: NonNullable<GetBranchesQuery['branches']>
  previousBranches?: GetBranchesQuery['branches']
}

const BranchesContext = React.createContext<BranchesContextValueType>(
  UninitializedAccessHandler('BranchesProvider not yet initialized')
)

export const BranchesProvider: React.FC = ({ children }) => {
  const { data, previousData, ...rest } = useGetBranchesQuery()
  const branches = data?.branches || []
  const previousBranches = previousData?.branches
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
    <BranchesContext.Provider value={{ branches, previousBranches, ...rest }}>
      {children}
    </BranchesContext.Provider>
  )
}

export const useBranches = () => useContext(BranchesContext)
