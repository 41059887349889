import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'

const MOBILE_WIDTH = 450
const MOBILE_WIDTH_PORTRAIT = 950

const checkIsMobile = () => (
  window.innerWidth <= MOBILE_WIDTH ||
  (
    window.innerWidth <= MOBILE_WIDTH_PORTRAIT &&
    window.innerHeight <= MOBILE_WIDTH
  )
)

const TABLET_WIDTH = 850
const TABLET_WIDTH_PORTRAIT = 1200

const checkIsTablet = () => (
  window.innerWidth <= TABLET_WIDTH ||
  (
    window.innerWidth <= TABLET_WIDTH_PORTRAIT &&
    window.innerHeight <= TABLET_WIDTH
  )
)

type ViewportModeNames = 'desktop' | 'mobile'

interface ViewportMode {
  isMobileBreakpoint: boolean
  isTabletBreakpoint: boolean
  isMobile: boolean
  setViewportMode: React.Dispatch<React.SetStateAction<ViewportModeNames | null>>
}

const ViewportModeContext = React.createContext<ViewportMode>({
  isMobileBreakpoint: checkIsMobile(),
  isTabletBreakpoint: checkIsTablet(),
  isMobile: checkIsMobile(),
  setViewportMode: () => { },
})

const VIEWPORT_MOBILE = 'minimum-scale=1, initial-scale=1, width=device-width, viewport-fit=cover, maximum-scale=1.0, user-scalable=no'
const VIEWPORT_DESKTOP = 'minimum-scale=1, initial-scale=1, width=device-width'

export const ViewportProvider: React.FC = ({ children }) => {
  const [breakpoints, setBreakpoint] = useState<Pick<ViewportMode, 'isMobileBreakpoint' | 'isTabletBreakpoint'>>({
    isMobileBreakpoint: checkIsMobile(),
    isTabletBreakpoint: checkIsTablet(),
  })
  const [forceMode, setForceMode] = useState<ViewportModeNames | null>(null)

  // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
  const context: ViewportMode = {
    ...breakpoints,
    setViewportMode: setForceMode,
    isMobile: forceMode === 'mobile' || (
      forceMode === 'desktop' ? false : breakpoints.isMobileBreakpoint
    ),
  }

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint((prev) => {
        const current: typeof prev = {
          isMobileBreakpoint: checkIsMobile(),
          isTabletBreakpoint: checkIsTablet(),
        }

        if (isEqual(prev, current)) {
          return prev
        }

        return current
      })
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    document.body.classList.toggle('is-mobile', context.isMobile)

    let viewportMeta = (document.head.querySelector('meta[name="viewport"]') as HTMLMetaElement)
    if (!viewportMeta) {
      viewportMeta = document.createElement('meta')
      viewportMeta.setAttribute('name', 'viewport')
      document.head.appendChild(viewportMeta)
    }

    if (context.isMobile) {
      viewportMeta.content = VIEWPORT_MOBILE
    } else {
      viewportMeta.content = VIEWPORT_DESKTOP
    }
  }, [context.isMobile])

  return (
    <ViewportModeContext.Provider value={context}>
      {children}
    </ViewportModeContext.Provider>
  )
}

export const useIsMobile = () => React.useContext(ViewportModeContext).isMobile

export const useViewportMode = () => React.useContext(ViewportModeContext)
