import { gql } from '@apollo/client'

export const ORGANIZATION_FULL_FRAGMENT = gql`
  fragment OrganizationFragment on Organization {
    id
    name
    logo
    primaryColor
    isDemo
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $data: OrganizationUpdateInput!
    $where: OrganizationWhereUniqueInput!
  ) {
    updateOrganization(
      data: $data
      where: $where
    ) {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FULL_FRAGMENT}
`
