import { PaperClipOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { Panel } from 'components/common/Collapse'
import { DocumentExpirationDate } from 'components/common/DocumentExpirationDate'
import { Attachment } from 'components/form/common/DocumentFormList/DocumentUpload'
import colors from 'constants/colors'
import { last } from 'lodash'
import styled from 'styled-components'
import { GetEquipmentDocumentsQuery } from './__generated__/ GetEquipmentDocuments'

type DocumentType = NonNullable<GetEquipmentDocumentsQuery['equipment'][0]>['documents'][0]

interface DocumentProps {
  document: DocumentType
  className?: string
}

const Tiled = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  gap: 5px 10px;
  color: ${colors.greyscale70};
  font-size: 0.95em;

  > * {
    flex: 1 1 0;
    min-width: 120px;
  }
`

const FieldName = styled.div`
  font-weight: 600;
`

const NameCol = styled(Col)`
  font-weight: 600;
`

const ExpirationCol = styled(Col)`
  font-size: 0.9em;
`

const AttachmentLine = styled(({
  attachment,
  ...rest
}: {
  attachment: Attachment,
} & React.HTMLProps<HTMLAnchorElement>) => {
  const { url } = attachment

  if (!url) return null

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const name = attachment.name || last(url.split('/'))!.replace(/\.[^.$]+$/, '')

  return (
    <a target="_blank" rel="noreferrer" {...rest} href={url}>
      <PaperClipOutlined /> <span>{name}</span>
    </a>
  )
})`
  display: block;
  margin: 10px 0 0 0;

  > .anticon  {
    color: ${colors.fontPrimary};
    margin-right: 4px;
    opacity: 0.8;
  }
`

const SimpleDocument = styled((props: DocumentProps) => {
  const { document: doc, className } = props
  const attachment = doc.attachments?.[0]

  return (
    <Row style={{ width: '100%' }} align="middle" className={className}>
      <NameCol flex="auto">
        {attachment?.url ? (
          <AttachmentLine attachment={{
            ...attachment,
            name: doc.typeDetails?.name || attachment.name,
          }}
          />
        )
          :
          <span className="name-only">{doc.typeDetails?.name}</span>}
      </NameCol>
      {doc.expirationDate && (
        <ExpirationCol>
          <DocumentExpirationDate expirationDate={doc.expirationDate} />
        </ExpirationCol>
      )}
    </Row>
  )
})`
  padding-top: 7px;
  padding-bottom: 7px;

  .name-only {
    &:before {
      content: "▪";
      font-size: 1.3em;
      line-height: 1em;
      margin-left: 2px;
      margin-right: 13px;
    }
  }

  ${AttachmentLine} {
    color: inherit;
    margin: 0;

    > .anticon {
      font-size: 0.8em;
      margin-right: 7px;
    }
  }
`

const ComplexDocument = styled((props: DocumentProps) => {
  const { document: doc, ...rest } = props

  return (
    <Panel
      {...rest}
      key={doc.id}
      header={(
        <Row style={{ width: '100%' }} align="middle">
          <NameCol flex="auto">
            {doc.typeDetails?.name}
          </NameCol>
          {doc.expirationDate && (
            <ExpirationCol>
              <DocumentExpirationDate expirationDate={doc.expirationDate} />
            </ExpirationCol>
          )}
        </Row>
      )}
    >
      <Tiled>
        {doc.fields?.map(({ key, value }, index) => (
          <div key={index}>
            <FieldName>{key}</FieldName>
            {value}
          </div>
        ))}
      </Tiled>

      {doc.attachments?.map((attachment: any, index: number) => (
        <AttachmentLine key={index} attachment={attachment} />
      ))}
    </Panel>
  )
})`
  .ant-collapse-content-box {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  &.ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    margin-right: 9px;
  }
`

export const Document = (props: DocumentProps) => {
  const Component = (
    (props.document.fields || []).length === 0 &&
    (props.document.attachments || []).length <= 1
  ) ? SimpleDocument : ComplexDocument

  return <Component {...props} />
}
