import {
  createContext,
  useMemo, useState,
} from 'react'

import {
  DISCOUNT_TYPE, IInvoiceJobDetails, IInvoiceSettings, TERMS_ENUM,
} from '../types/ItemTypes'

interface IInvoiceSettingsContext {
  settings: IInvoiceSettings,
  jobDetails: IInvoiceJobDetails,
  updateInvoiceSettings: React.Dispatch<React.SetStateAction<IInvoiceSettings>>
  updateInvoiceJobDetails: React.Dispatch<React.SetStateAction<IInvoiceJobDetails>>
}

const defaultInvoiceSettings: IInvoiceSettings = {
  customerName: '',
  invoiceNumber: '',
  companySection: '',
  billAddress: '',
  siteAddress: '',
  lot: '',
  invoiceDate: new Date(),
  dueDate: new Date(),
  terms: TERMS_ENUM.DUE,
  priceRateID: 'default',
  discountType: DISCOUNT_TYPE.NONE,
  discountValue: 0,
  thankYouMessage: '',
}

const defaultJobDetails: IInvoiceJobDetails = {
  cubicYards: 0,
  jobDate: new Date(),
  onSiteDurationHours: 0,
  travelDurationHours: 0,
}

export const InvoiceSettingsContext = createContext<IInvoiceSettingsContext>({
  settings: defaultInvoiceSettings,
  jobDetails: defaultJobDetails,
  updateInvoiceSettings: () => null,
  updateInvoiceJobDetails: () => null,
})

export const InvoiceSettingsProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [settings, updateInvoiceSettings] = useState<IInvoiceSettings>(defaultInvoiceSettings)
  const [jobDetails, updateInvoiceJobDetails] = useState<IInvoiceJobDetails>(defaultJobDetails)
  const memoizedValue = useMemo(() => ({
    settings, updateInvoiceSettings, jobDetails, updateInvoiceJobDetails,
  }), [settings])
  return <InvoiceSettingsContext.Provider value={memoizedValue}>{children}</InvoiceSettingsContext.Provider>
}
