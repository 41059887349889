import { useCreation, useLongPress } from 'ahooks'
import { Dropdown, Menu } from 'antd'
import { formatAddress } from 'helpers/formatAddress'
import { byFieldAsc } from 'helpers/sortting'
import { useBranch } from 'hooks/useBranch'
import { useSite } from 'hooks/useSite'
import { Dictionary, isNil, omitBy } from 'lodash'
import { useRef, useState } from 'react'
import { Address } from 'schema'
import styled from 'styled-components'

interface ExternalNavigateProps {
  customerId?: number
  siteId?: number
  origin?: string | null
  children?: React.ReactNode
  className?: string
  providerSelectEnabled?: boolean
}

const coordsOrAddress = (address: Partial<Address>) => (
  !isNil(address.lat) && !isNil(address.lng) ?
    [address.lat, address.lng].join(',')
    :
    formatAddress(address, { multiline: false })
)

type NavigationProvider = 'apple' | 'google'

const defaultNavigationProvider: NavigationProvider = 'google'

const NavigateLink = styled.a`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

`

export const ExternalNavigate = (props: ExternalNavigateProps): JSX.Element | null => {
  const branch = useBranch()
  const site = useSite(props.customerId, props.siteId)
  const startAddress = (branch?.yards || []).slice().sort(byFieldAsc('id'))?.[0]?.address

  const generateLinkFor = (provider: NavigationProvider) => {
    if (!site?.address) return undefined

    const origin = props.origin === undefined ? coordsOrAddress(startAddress) : props.origin
    const destination = coordsOrAddress(site.address)

    if (provider === 'google') {
      return `https://www.google.com/maps/dir/?${new URLSearchParams(
        omitBy({
          api: '1',
          origin,
          destination,
        }, isNil) as Dictionary<string>
      ).toString()}`
    }

    if (provider === 'apple') {
      return `http://maps.apple.com/?${new URLSearchParams(
        omitBy({
          dirflg: 'd',
          saddr: origin,
          daddr: destination,
        }, isNil) as Dictionary<string>
      ).toString()}`
    }
  }

  const defaultLink = useCreation(() => (
    generateLinkFor(defaultNavigationProvider)
  ), [site, defaultNavigationProvider])

  const ref = useRef<HTMLAnchorElement>(null)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const navigateOn = (provider: NavigationProvider) => {
    setDropdownOpen(false)
    const link = generateLinkFor(provider)
    if (link) {
      window.open(link)
    }
  }

  const onClick = () => {
    navigateOn(defaultNavigationProvider)
  }
  const onLongPress = () => {
    if (props.providerSelectEnabled) {
      setDropdownOpen(true)
    }
  }

  useLongPress(onLongPress, ref, { onClick })

  return (
    <NavigateLink ref={ref} className={props.className} href={defaultLink} onClick={(evt) => { evt.preventDefault() }}>
      <Dropdown
        visible={dropdownOpen}
        trigger={[]}
        overlay={(
          <Menu onClick={({ key }) => navigateOn(key as NavigationProvider)}>
            <Menu.Item key="apple">Apple Maps</Menu.Item>
            <Menu.Item key="google">Google Maps</Menu.Item>
          </Menu>
        )}
      >
        {props.children || <>Navigate on Google</>}
      </Dropdown>
    </NavigateLink>
  )
}
