import colors from 'constants/colors'
import styled from 'styled-components'

export const FooterSection = styled.div`
  &:not(:empty):not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.borderPrimary};
    margin-bottom: 10px;
  }
`
