import React, { useState } from 'react'

import {
  InventoryItem, InventoryItemInput,
  InventoryItemWhereUniqueInput, Mutation,
  MutationCreateInventoryItemArgs,
  MutationUpdateInventoryItemArgs, Query,
} from 'schema'

import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_INVENTORY_ITEM, GET_INVENTORY, GET_INVENTORY_ITEM, UPDATE_INVENTORY_ITEM,
} from 'gql/inventory'

import {
  Button, Col, Row, Typography,
} from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'

import { Form } from 'components/form/Form'
import { Input } from 'components/form/inline-edit/Input'
import { Select } from 'components/form/inline-edit/Select'
import SwitchInput from 'components/form/inline-edit/Switch'
import { TextArea } from 'components/form/inline-edit/TextArea'
import { Loading } from 'components/Loading'
import { useBranch } from 'hooks/useBranch'
import { Footer, Header } from 'hooks/useContentLayout'
import InputNumber from '../inline-edit/InputNumber'

const { Paragraph } = Typography

export interface InventoryItemFormProps {
  id?: number
  editing?: boolean
  onSubmission?: (user: InventoryItem, action: 'update' | 'create') => void
  onCancel?: () => void
  customerId?: number
}

export const InventoryItemForm: React.FC<InventoryItemFormProps> = (props) => {
  const { id, onSubmission } = props
  const isNew = id === undefined

  const branch = useBranch()
  const [editing, setEditing] = useState<boolean>(props.editing || isNew)

  const { data, error: queryError, loading: queryLoading } = useQuery<Query, { id: InventoryItemWhereUniqueInput['id'] }>(
    GET_INVENTORY_ITEM,
    {
      variables: { id },
      skip: isNew,
    }
  )

  const item = (data?.inventory || [])[0] as InventoryItem | undefined

  const [
    createInventoryItem,
    createInventoryItemResponse,
  ] = useMutation<Mutation, MutationCreateInventoryItemArgs>(CREATE_INVENTORY_ITEM, {
    refetchQueries: [{
      query: GET_INVENTORY,
    }],
  })

  const [
    updateInventoryItem,
    updateInventoryItemResponse,
  ] = useMutation<Mutation, MutationUpdateInventoryItemArgs>(UPDATE_INVENTORY_ITEM)

  const { error: mutationError } = isNew ? createInventoryItemResponse : updateInventoryItemResponse

  const [form] = Form.useForm<InventoryItem>()

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data: InventoryItemInput = values
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    data.branchId = branch!.id

    if (id) {
      const resp = await updateInventoryItem({
        variables: { data, where: { id } },
      })

      if (resp.errors) return

      setEditing(false)

      const updatedInventoryItem = resp?.data?.updateInventoryItem

      if (updatedInventoryItem && onSubmission) {
        onSubmission(updatedInventoryItem, 'update')
      }
    } else {
      const resp = await createInventoryItem({
        variables: { data },
      })

      if (resp.errors) return

      setEditing(false)

      const newInventoryItem = resp?.data?.createInventoryItem

      if (newInventoryItem && onSubmission) {
        onSubmission(newInventoryItem, 'create')
      }
    }
  }

  if (queryError) {
    return (
      <ErrorMessage to="/inventory">
        <Paragraph>{queryError.message}</Paragraph>
      </ErrorMessage>
    )
  }

  if (!item && queryLoading) return <Loading />

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="inventory-item-form"
        onFinish={onFinish}
        requiredMark={editing}
        labelCol={{
          style: {
            width: '90px',
          },
        }}
        initialValues={{
          ...item,
          active: isNew ? true : item?.active,
        }}
      >
        {id && (
          <Form.Item
            noStyle
            shouldUpdate={(prev, current) => (
              prev.name !== current.name
            )}
          >
            {() => (
              <Header hideIfOrphan>
                {form.getFieldValue('name')}
              </Header>
            )}
          </Form.Item>
        )}

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
          ]}
        >
          <Input placeholder="Item name" editing={editing} />
        </Form.Item>
        <Form.Item
          label="Category"
          name="category"
        >
          <Select
            placeholder="Select type of item"
            editing={editing}
            options={[
              {
                label: 'Accessory',
                value: 'accessory',
              },
              {
                label: 'Reducer',
                value: 'reducer',
              },
              {
                label: 'System',
                value: 'system',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Quantity"
          name="quantity"
        >
          <InputNumber placeholder="Total quantity avaiable" editing={editing} />
        </Form.Item>
        <Form.Item
          label="Unit"
          name="displayUnit"
        >
          <Input placeholder="Unit displayed with quantity, ex 'ft'" editing={editing} />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
        >
          <TextArea placeholder="Description for this item" editing={editing} />
        </Form.Item>
        <Form.Item
          label="Active"
          name="active"
          valuePropName="checked"
        >
          <SwitchInput
            editing={editing}
          />
        </Form.Item>
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}
