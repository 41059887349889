import colors from 'constants/colors'
import React from 'react'
import styled from 'styled-components'

import { timeWithZone } from 'helpers/datetime'
import { waypointName } from 'helpers/waypoint'

import { useBranch } from 'hooks/useBranch'

import { RouteWaypoint, RouteWaypointStatus } from 'schema'

import { Tooltip, TooltipProps, TooltipTitle } from 'components/common/Tooltip'
import { RoutePerformanceLabel } from 'components/route/RoutePerformanceLabel'

const WAYPOINT_SIZE = 10

const WaypointMarkerIcon = styled.div`
  background-color: #fff;
  border: 2px solid ${colors.fontPrimary};
  border-radius: 50%;
  height: ${WAYPOINT_SIZE}px;
  width: ${WAYPOINT_SIZE}px;
`

const DispatchedWaypointMarkerIcon = styled.div`
  border: ${WAYPOINT_SIZE / 2}px solid ${colors.fontPrimary};
  border-radius: 100%;
  height: 0px;
  width: 0px;
`

const WaypointMarkerWrap = styled.div`
  padding: 6px 0;
  width: ${WAYPOINT_SIZE}px;
`

const BookendWaypointMarkerWrap = styled(WaypointMarkerWrap)`
  margin-left: -${WAYPOINT_SIZE / 2}px;

  > div {
    margin-left: 1px;
  }
`

const WaypointLine = styled.div`
  margin-bottom: 5px;
  line-height: 1.2rem;
`

const RoutePerformanceLabelStyled = styled(RoutePerformanceLabel)`
  display: block;
`

export interface WaypointTooltipContentProps {
  waypoint: RouteWaypoint & {
    eta?: string
  }
  hideArrival?: boolean
  hideDeparture?: boolean
}

export type WaypointTooltipProps = React.PropsWithChildren<Omit<TooltipProps, 'title'> & WaypointTooltipContentProps>

export const WaypointTooltipContent = ({ waypoint, hideArrival, hideDeparture }: WaypointTooltipContentProps) => {
  const branch = useBranch()
  const branchTimezone = branch?.timezone
  const timezone = waypoint?.address?.timezone || branchTimezone
  const name = waypointName(waypoint, { includeCustomer: false })
  const showTimezone = timezone !== branchTimezone

  const formatTime = (time?: string) => {
    const datetime = timeWithZone(time, timezone)
    if (!datetime) return
    const showMinute = datetime.minute !== 0
    return datetime.toFormat(`h${showMinute ? ':mm' : ''}a${showTimezone ? ' ZZZZ' : ''}`)
  }

  const lines: JSX.Element[] = []

  lines.push(<TooltipTitle>{name}</TooltipTitle>)

  if (hideArrival !== true) {
    if (waypoint.actualArrivalTime) {
      const time = formatTime(waypoint.actualArrivalTime)

      lines.push(<>
        Arrived at {time} <RoutePerformanceLabelStyled
          scheduledTime={waypoint.scheduledArrivalTime}
          actualTime={waypoint.actualArrivalTime}
          performance={waypoint.arrivalPerformance}
        />
      </>)
    } else if (waypoint.estimatedArrivalTime && waypoint?.status === RouteWaypointStatus.EnRoute) {
      const eta = formatTime(waypoint.estimatedArrivalTime)

      const performance = waypoint?.scheduledArrivalTime ? (
        <RoutePerformanceLabelStyled
          scheduledTime={waypoint.scheduledArrivalTime}
          actualTime={waypoint.estimatedArrivalTime}
          performance={waypoint.arrivalPerformance}
        />
      ) : null

      lines.push(<>ETA at {eta} {performance} </>)
    } else if (waypoint.scheduledArrivalTime) {
      const time = formatTime(waypoint.scheduledArrivalTime)
      lines.push(<>Arrive at {time}</>)
    }
  }

  if (hideDeparture !== true) {
    if (waypoint.actualDepartureTime) {
      const time = formatTime(waypoint.actualDepartureTime)
      lines.push(<>
        Departed at {time} <RoutePerformanceLabelStyled
          scheduledTime={waypoint.scheduledDepartureTime}
          actualTime={waypoint.actualDepartureTime}
          performance={waypoint.departurePerformance}
        />
      </>)
    } else if (waypoint.scheduledDepartureTime) {
      const time = formatTime(waypoint.scheduledDepartureTime)
      lines.push(<>Depart at {time}</>)
    }
  }

  return <>{lines.map((line, index) => <WaypointLine key={index}>{line}</WaypointLine>)}</>
}

export const WaypointTooltip = ({ waypoint, ...props }: WaypointTooltipProps) => (
  <Tooltip
    color={colors.greyscale80}
    {...props}
    noWrap
    title={<WaypointTooltipContent waypoint={waypoint} />}
  />
)

export interface WaypointMarkerProps extends React.HTMLAttributes<HTMLDivElement> {
  waypoint: RouteWaypoint & {
    eta?: string
  }
  routeStarted?: boolean
  dispatched?: boolean
}

export const WaypointMarker = ({
  waypoint, dispatched, routeStarted, ...props
}: WaypointMarkerProps) => {
  const isLastStop = waypoint.scheduledDepartureTime === null
  const isFirstStop = waypoint.scheduledArrivalTime === null

  const WrappingElement = isLastStop || isFirstStop ? BookendWaypointMarkerWrap : WaypointMarkerWrap

  const performance = waypoint.departurePerformance || waypoint.arrivalPerformance
  const color = (routeStarted && performance && colors.performance[performance]) || colors.greyscale65

  const Marker = dispatched ? DispatchedWaypointMarkerIcon : WaypointMarkerIcon

  return (
    <WrappingElement>
      <WaypointTooltip waypoint={waypoint}>
        <Marker {...props} style={{ ...props.style, borderColor: color }} />
      </WaypointTooltip>
    </WrappingElement>
  )
}
