import { dateToSlug } from 'helpers/url'
import { DateTime } from 'utils/luxon'

import { useNotification } from 'hooks/useNotification'

import { Redirect, useParams } from 'react-router-dom'

import { gql, useQuery } from '@apollo/client'
import { Query, QueryRoutesArgs } from 'schema'

import Loading from 'components/Loading'
import { ROUTE_FRAGMENT } from 'gql/routes'

const GET_ROUTES_WITH_BRANCH = gql`
  query GetRoute(
    $where: QueryRoutesWhereInput
  ) {
    routes(
      where: $where
    ) {
      ...RouteFields
      branch {
        id
      }
    }
  }
  ${ROUTE_FRAGMENT}
`

export const RouteShowRedirect = (_args: any) => {
  const notification = useNotification()
  const { id: urlId } = useParams<{ id: string }>()
  const id = parseInt(urlId)

  const { loading, data } = useQuery<Query, QueryRoutesArgs>(GET_ROUTES_WITH_BRANCH, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: {
          equals: id,
        },
      },
    },
  })

  if (loading) {
    return <Loading />
  }

  const route = data?.routes?.[0]
  if (!route) {
    notification.error({
      message: 'Route not found',
      duration: 5,
    })
    return <Redirect to="/" />
  }

  const nowISO = DateTime.utc().toISO()
  const startTime = DateTime.fromISO(route.startTimeLocal || route.startTime || nowISO)
  const endTime = DateTime.fromISO(route.endTimeLocal || route.endTime || nowISO)

  let date = startTime.plus(endTime.diff(startTime))
  if (route.startTimezone) {
    date = date.setZone(route.startTimezone)
  }

  return <Redirect to={`/branches/${route.branch.id}/schedule/${dateToSlug(date)}/route/${route.id}`} />
}

export default RouteShowRedirect
