import { useCreation, useMutationObserver } from 'ahooks'
import { InputNumber, InputNumberProps } from 'components/form/inline-edit/InputNumber'
import { roundTo } from 'helpers/rounding'
import {
  isEmpty, isNil, isNumber, round,
} from 'lodash'
import { Duration } from 'luxon'
import React, { useEffect, useMemo } from 'react'

const DEFAULTS: Partial<InputNumberProps<number>> = {
  step: 0.25,
  precision: 2,
  min: 0,
}

// eslint-disable-next-line unused-imports/no-unused-vars
const usePlaceholderAdjuster = (
  ref: React.MutableRefObject<HTMLInputElement | undefined>,
  adjuster: (placeholder: string | undefined) => string | undefined
) => {
  const processPotentialPlaceholderChange = () => {
    if (!ref.current) return
    const currentValue = ref.current.placeholder
    const newValue = adjuster(currentValue) || ''
    if (newValue !== (currentValue || '')) {
      ref.current.placeholder = newValue
    }
  }

  useMutationObserver(
    processPotentialPlaceholderChange,
    ref.current,
    { attributeFilter: ['placeholder'] }
  )

  useEffect(processPotentialPlaceholderChange, [ref.current])
}

// eslint-disable-next-line prefer-regex-literals
const isNumberRegex = new RegExp(/^\d+\.?\d?$/)

export const HoursInput = React.forwardRef<any, InputNumberProps<number>>((props, ref) => {
  const args = { ...DEFAULTS, ...props }

  const providedValue = useCreation(() => args.value, [args.value])
  const valueAsHours = !isNil(providedValue) ? round(Duration.fromMillis(providedValue).as('hours'), args.precision) : undefined
  args.value = valueAsHours

  args.placeholder = useMemo(() => {
    if (props.placeholder && isNumberRegex.test(props.placeholder)) {
      const placeholderMillis = parseFloat(props.placeholder)
      const placeholderAsHours = round(Duration.fromMillis(placeholderMillis).as('hours'), args.precision)
      return `${placeholderAsHours} hours`
    }
    return props.placeholder
  }, [props.placeholder])

  const hoursToMillis = (hours: number) => {
    const hoursDiff = hours - (valueAsHours || 0)
    const asMillis = (providedValue || 0) + Duration.fromObject({ hours: hoursDiff }).as('milliseconds')
    return asMillis
  }

  args.onChange = (hours) => {
    if (props.onChange) {
      props.onChange(isNil(hours) ? hours : hoursToMillis(hours))
    }
  }

  args.onStep = (hours, ...rest) => {
    if (props.onStep) {
      props.onStep(hoursToMillis(hours), ...rest)
    } else if (props.onChange) {
      const { offset, type } = rest[0]

      if (isNumber(offset)) {
        const hoursRounded = roundTo(hours, offset)
        if (hoursRounded === hours) return
        const nextValue = type === 'up' ? roundTo(hours - offset, offset) : roundTo(hours, offset)
        props.onChange(hoursToMillis(nextValue))
      }
    }
  }

  args.formatter = (value) => {
    const stringVal = (value || '').toString().replace(' hours', '')
    if (args.editing) {
      return stringVal
    }

    return isEmpty(stringVal) ? '' : `${stringVal} hours`
  }

  args.parser = (value) => {
    const stringVal = (value || '').replace(' hours', '')
    if (!stringVal) return 0
    return parseFloat(stringVal)
  }

  return <InputNumber key={args.editing ? 'editing' : 'default'} ref={ref} {...args} />
})
