import { Order } from 'schema'

import { useHistory, useRouteMatch } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import {
  ButtonProps, Col, Row, Space,
} from 'antd'
import { NextButton, PreviousButton } from 'components/buttons'
import { useOrderPagesState } from '../OrderPagesState'

interface OrdersNavigationProps {
  id: Order['id']
  size?: ButtonProps['size']
}

export const OrdersNavigation = ({ id, size }: OrdersNavigationProps) => {
  const history = useHistory()
  const match = useRouteMatch()
  const lastLocation = useLastLocation()
  const { orders, path: indexPagePath } = useOrderPagesState()

  const basePath = history.location.pathname.slice(0, match.path.indexOf('/:id'))

  const currentOrderIndex = orders.findIndex((order) => order.id === id)
  const prevOrderId = orders[currentOrderIndex - 1]?.id
  const nextOrderId = orders[currentOrderIndex + 1]?.id

  return (
    <Row gutter={16}>
      <Col flex="auto">
        <PreviousButton
          size={size}
          onClick={() => {
            history.push(indexPagePath || lastLocation || basePath)
          }}
        > Back
        </PreviousButton>
      </Col>
      <Col>
        {orders && (
          <Space>
            {prevOrderId && (
              <PreviousButton
                size={size}
                onClick={() => {
                  history.push(`${basePath}/${prevOrderId}`)
                }}
              />
            )}
            {nextOrderId && (
              <NextButton
                size={size}
                onClick={() => {
                  history.push(`${basePath}/${nextOrderId}`)
                }}
              />
            )}
          </Space>
        )}
      </Col>
    </Row>
  )
}
