import { EventProps } from './events/Card'
import { NoteEvent } from './events/NoteEvent'
import { OrderEvent } from './events/OrderEvent'
import { PendingEvent } from './events/PendingEvent'
import { RouteEvent } from './events/RouteEvent'

export const EventCard = (props: EventProps): JSX.Element | null => {
  const extendProps = props?.event?.extendedProps

  if (extendProps?.route) {
    return RouteEvent({ ...props, route: extendProps?.route })
  }

  if (extendProps?.order) {
    return OrderEvent({ ...props, order: extendProps?.order })
  }

  if (extendProps?.note) {
    return NoteEvent({ ...props, note: extendProps?.note })
  }

  return PendingEvent(props)
}
