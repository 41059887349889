import { ENV_IS_PRODUCTION } from 'constants/environment'

const intercept = (message: string) => (
  () => {
    if (ENV_IS_PRODUCTION) {
      console.error(`UninitializedAccessError: ${message}`)
      return false
    }
    throw new Error(`UninitializedAccessError: ${message}`)
  }
)

export const UninitializedAccessHandler = (message: string): any => (
  new Proxy({}, {
    get: intercept(message),
    set: intercept(message),
  })
)
