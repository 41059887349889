import { useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { GET_ORDER_FULL } from 'gql/orders'
import { Query, QueryOrderArgs } from 'schema'

import { ErrorMessage } from 'components/ErrorMessage'
import { Loading } from 'components/Loading'

import { OrderForm } from 'components/form/OrderForm/OrderForm'
import { OrderActionsMenu } from 'components/menus/OrderActionsMenu'
import { ShowOrderCommentsButton } from 'components/order/ShowOrderCommentsButton'
import { TagsEditor } from 'components/tags/TagsEditor'
import { withSystemTags } from 'helpers/order/withSystemTags'
import { CloseButton } from 'hooks/useClosable'
import { Actions, Body, Header } from 'hooks/useContentLayout'
import { DrawerContent } from 'layouts/Content/DrawerContent'

export const ScheduleOrderShow = (props: any) => {
  const { orderId } = useParams<{ orderId?: string }>()
  const id = orderId ? parseInt(orderId) : undefined

  const response = useQuery<Query, QueryOrderArgs>(GET_ORDER_FULL, {
    fetchPolicy: 'network-only',
    skip: !id,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: id!,
    },
  })

  if (!id) {
    return <ErrorMessage>Must pass order id</ErrorMessage>
  }

  if (response.loading) {
    return <Loading />
  }

  const order = response.data?.order

  if (!order) {
    return <ErrorMessage>Order #{id} not found</ErrorMessage>
  }

  const selectedTags = withSystemTags(order)

  return (
    <DrawerContent>
      <TagsEditor scope="orders" id={order.id} selected={selectedTags as any}>
        <Header>
          {order?.customer?.abbreviation ? `${order.customer.abbreviation.toUpperCase()} ${order.id}` : order.name}
          <TagsEditor.Tags style={{ marginTop: '5px' }} />
        </Header>

        <Actions>
          <TagsEditor.Selector />
          <ShowOrderCommentsButton id={order.id} />
          <OrderActionsMenu order={order} />
          <CloseButton />
        </Actions>
      </TagsEditor>

      <Body>
        <OrderForm key={order.id} detailsScope={{ type: 'planned' }} order={order} />
      </Body>
    </DrawerContent>
  )
}
