import React from 'react'

import styled from 'styled-components'

import { Col, Row } from 'antd'
import { Card } from 'components/card'
import { ContentContext, useContentLayoutState } from 'hooks/useContentLayout'

const BlockCard = styled(Card.Wrapper)`
  width: 100%;
  height: 100%;
`

const CardHeader = styled(Card.Header)`
  padding: 0;
  margin-top: -2px;
  overflow-x: hidden;

  > .ant-row > .ant-col:not(:empty) {
    margin-top: 2px;

    &:first-child {
      margin-left: 20px;
    }
    &:last-child {
      margin-right: 20px;
    }
  }

  > .ant-row > .ant-col:empty {
    display: none;
  }
`

const CardFooter = styled(Card.Footer)`
  &:empty {
    border-top: none;
    padding: 0;
  }
`

const ActionsCol = styled(Col)`
  margin-left: auto;
  display: flex;
  align-items: center;

  > * {
    padding: 10px 3px;
    margin-left: 4px;
    margin-right: 4px;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
`

const HeaderCol = styled(Col).attrs({
  flex: 'auto',
})`
  padding: 10px 0;
  font-size: 1.2rem;
  font-weight: 600;

  &:not(:empty) + ${ActionsCol} {
    min-height: 48px;
  }
`

export const DrawerContentSections = {
  Header: CardHeader,
  HeaderCol,
  ActionsCol,
  Body: Card.Body,
  Footer: CardFooter,
}

export type DrawerContentProps = React.ComponentProps<typeof BlockCard> & {
  components?: Partial<Record<keyof typeof DrawerContentSections, React.FC>>
}

export const DrawerContent = ({ children, components, ...props }: DrawerContentProps) => {
  const layoutContext = useContentLayoutState()

  const {
    Header, Body, Footer, Actions,
  } = layoutContext

  const Components = { ...DrawerContentSections, ...(components || {}) }

  return (
    <ContentContext.Provider value={layoutContext}>
      <BlockCard {...props}>
        <Components.Header>
          <Row gutter={12} wrap={false} align="top">
            <Header.Target as={Components.HeaderCol} />
            <Actions.Target as={Components.ActionsCol} />
          </Row>
        </Components.Header>
        <Body.Target as={Components.Body} />
        <Footer.Target as={Components.Footer} />
      </BlockCard>
      {children}
    </ContentContext.Provider>
  )
}
