import { ErrorMessage } from 'components/ErrorMessage'
import { Card } from 'components/card'
import { Order } from 'schema'
import { Header } from './Header'
import { useOrderBillingState } from './OrderBillingState'
import { OrderCompareTable } from './OrderCompareTable'
import { InventoryTable } from './OrderCompareTable/InventoryTable'
import { ScheduleTable } from './OrderCompareTable/ScheduleTable'
import { Overview } from './Overview'

interface OrderBillingProps {
  orderId: Order['id'],
}

export const OrderBilling = (props: OrderBillingProps) => {
  const { error } = useOrderBillingState()

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  return (
    <Card block style={{ overflow: 'hidden' }}>
      <Card.Header>
        <Header />
      </Card.Header>
      <Card.Body>
        <Overview />
        <OrderCompareTable />
        <ScheduleTable />
        <InventoryTable />
      </Card.Body>
    </Card>
  )
}
