import { gql, useQuery } from '@apollo/client'
import {
  errorDropdownRender, OptionsType, ReferenceSelect, ReferenceSelectProps,
} from 'components/form/reference-selects/ReferenceSelect'
import {
  compact, groupBy, omit, uniq, uniqBy,
} from 'lodash'
import React, { useMemo } from 'react'
import { Person, Query } from 'schema'
import { RecursivePartial } from 'types'

type PersonLike = {
  __typename?: string
  id: Person['id'] | string | number
  name?: Person['firstName']
  firstName?: Person['firstName']
  lastName?: Person['lastName']
}

export type OrganizationMembersSelectProps = ReferenceSelectProps<PersonLike> & {
  categorySortOrder?: string[]
}

const GET_ORGANIZATION_MEMBERS = gql`
  query GetOrganizationMembersForSelect {
    operators( where: { active: { equals: true } } ) {
      id
      firstName
      lastName
    }
    users( where: { active: { equals: true } } ) {
      id
      firstName
      lastName
    }
  }
`

const personOptionGenerator = (person?: RecursivePartial<PersonLike> | null) => {
  if (!person?.id) return

  const name = person.name || [person?.firstName, person?.lastName].join(' ')

  return {
    title: name,
    label: name,
    value: person.id,
  }
}

const defaults: Partial<OrganizationMembersSelectProps> = {
  placeholder: 'Select a Team Member',
  showSearch: true,
  filterOption: (val: string, opt) => opt?.title?.toLowerCase().includes(val.toLowerCase()),
}

export const OrganizationMembersSelect: React.FC<OrganizationMembersSelectProps> = (propsRaw) => {
  const { categorySortOrder, resources, ...rest } = propsRaw

  const props: OrganizationMembersSelectProps = {
    ...defaults,
    ...rest,
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  const { data: queryData, loading, error } = useQuery<Query, {}>(GET_ORGANIZATION_MEMBERS)

  if (error) {
    props.dropdownRender = errorDropdownRender('Team Members')
    console.error(`ReferenceSelect load error for Team Members: ${error}`)
  }

  props.loading = props.loading || loading

  props.remoteResources = useMemo(() => ([
    ...(resources || []),
    ...(queryData?.users || []),
    ...(queryData?.operators || []),
  ]), [queryData, resources])

  props.optionsGenerator = (items) => {
    const byType = groupBy(items, (item) => {
      if (item.__typename === 'User') return 'Admin'
      return item.__typename || 'Other'
    })

    const sortOrder = uniq([
      ...(categorySortOrder || []),
      ...Object.keys(omit(byType, 'Other')).sort(),
      'Other',
    ])

    const sortedKeys = Object.keys(byType).sort((a, b) => {
      const aPosition = sortOrder.indexOf(a)
      const bPosition = sortOrder.indexOf(b)
      if (aPosition === -1 && bPosition === -1) {
        return a < b ? -1 : 1
      }
      if (aPosition === -1) return 1
      if (bPosition === -1) return -1
      return aPosition < bPosition ? -1 : 1
    })

    const options: OptionsType = []

    for (const key of sortedKeys) {
      const subItems = byType[key]

      options.push({
        key,
        label: `${key}s`,
        options: compact(subItems.map(personOptionGenerator)),
      })
    }

    return uniqBy(options, 'key')
  }

  return <ReferenceSelect {...props} />
}

export default OrganizationMembersSelect
