import { CloseButton as CloseButtonRaw } from 'components/buttons'
import React, { useContext } from 'react'
import styled from 'styled-components'

interface ClosableContextValueType {
  close: (evt?: any) => void
  exists: boolean
}

const ClosableContext = React.createContext<ClosableContextValueType>({
  close: () => { },
  exists: false,
})

export const ClosableProvider = ClosableContext.Provider

export const ClosableElement: React.FC<Partial<ClosableContextValueType>> = (props) => (
  <ClosableProvider value={{
    close: props.close || (() => { }),
    exists: true,
  }}
  >
    {props.children}
  </ClosableProvider>
)

export const useClosable = () => useContext(ClosableContext)

const CloseButtonStyled = styled(CloseButtonRaw)`
  width: auto;
  height: auto;

  &.ant-btn-icon-only, &.ant-btn-icon-only > * {
    font-size: inherit;
  }
`

export const CloseButton: typeof CloseButtonRaw = (props) => {
  const { close, exists } = useClosable()
  if (!exists) return null

  // eslint-disable-next-line unused-imports/no-unused-vars
  const onClick = () => {
    if (props.onClick) {
      onClick()
    }
    close()
  }

  return <CloseButtonStyled {...props} onClick={close} />
}
