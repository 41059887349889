import {
  createContext, useCallback, useMemo, useState,
} from 'react'

export interface IInvoiceBalance {
  subtotal: number,
  discount: number,
  surcharge: number,
}

export interface IInvoiceBalanceTotal extends IInvoiceBalance {
  total: number
}

interface IInvoiceBalanceContext {
  balance: IInvoiceBalanceTotal,
  updateBalance: (b: Partial<IInvoiceBalance>) => void
}

const defaultBalance: IInvoiceBalanceTotal = {
  subtotal: 0, discount: 0, surcharge: 0, total: 0,
}

export const InvoiceBalanceContext = createContext<IInvoiceBalanceContext>({
  balance: defaultBalance,
  updateBalance: () => null,
})

export const InvoiceBalanceProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [balance, setBalance] = useState<IInvoiceBalanceTotal>(defaultBalance)
  const updateBalance = useCallback((obj: Partial<IInvoiceBalance>) => {
    setBalance((prev) => {
      const newBalance = { ...prev, ...obj }
      newBalance.total = newBalance.subtotal - newBalance.discount + newBalance.surcharge
      return newBalance
    })
  }, [balance])
  const memoizedValue = useMemo<IInvoiceBalanceContext>(() => ({ balance, updateBalance }), [balance])
  return <InvoiceBalanceContext.Provider value={memoizedValue}>{children}</InvoiceBalanceContext.Provider>
}
