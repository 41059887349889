import { get, PropertyPath, set } from 'lodash'

export const pushAt = <T extends object>(object: T, path: PropertyPath, value: any): T => {
  const current = get(object, path) || []
  if (!('push' in current)) {
    return current
  }
  current.push(value)
  set(object, path, current)
  return object
}
