import { useEffect, useRef, useState } from 'react'

import { useSchedulerState } from 'components/scheduler/SchedulerState'
import styled from 'styled-components'
import { DateTime, toMoment } from 'utils/luxon'

import { Calendar as AntCalendar, Col, Row } from 'antd'
import { NextButton, PreviousButton } from 'components/buttons'
import { DrawerBody } from 'components/layout/DrawerBody'
import { OrderStatusLegend } from 'components/scheduler/OrderStatusLegend'

// optionally overwrite dateCellRender
const CalendarStyled = styled(AntCalendar)`
  &.not-on-current-month {
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      color: rgba(0, 0, 0, 0.85);
      background-color: transparent;
    }
  }
`

export const ScheduleCalendarShow = () => (
  <DrawerBody style={{ paddingBottom: 15, paddingTop: 15 }}>
    <Row style={{ flexDirection: 'column', height: '100%', flexWrap: 'nowrap' }}>
      <Col flex="0 0 auto">
        <Calendar />
      </Col>
      <Col flex="1 1 auto" />
      <Col flex="0 0 auto">
        <OrderStatusLegend />
      </Col>
    </Row>
  </DrawerBody>
)

export const Calendar = () => {
  const {
    timezone,
    selectedDate,
    setSelectedDate,
  } = useSchedulerState()

  // This keeps the localized version responsive
  const [calendarDate, setCalendarDate] = useState(selectedDate)
  const showingCurrentMonth = useRef(true)

  const changeDate = (newDate: DateTime) => {
    showingCurrentMonth.current = true
    if (newDate.toMillis() !== calendarDate.toMillis()) {
      setCalendarDate(newDate)
    }
    if (newDate.toMillis() !== selectedDate.toMillis()) {
      setSelectedDate(newDate)
    }
  }

  useEffect(() => changeDate(selectedDate), [selectedDate])

  const changeCalendarMonth = (datetime: DateTime) => {
    showingCurrentMonth.current = datetime.toFormat('LLyyyy') === selectedDate.toFormat('LLyyyy')
    if (showingCurrentMonth.current) {
      changeDate(selectedDate)
    } else {
      setCalendarDate(datetime)
    }
  }

  return (
    <CalendarStyled
      className={showingCurrentMonth.current ? '' : 'not-on-current-month'}
      fullscreen={false}
      value={toMoment(calendarDate)}
      onSelect={(val) => {
        const dateISO = val.toISOString().slice(0, 10)
        changeDate(DateTime.fromISO(dateISO, { zone: timezone }))
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRender={() => (
        <Row gutter={16} style={{ margin: '0 8px' }}>
          <Col>
            <PreviousButton
              size="small"
              type="text"
              onClick={() => {
                changeCalendarMonth(calendarDate.minus({ month: 1 }))
              }}
            />
          </Col>
          <Col flex="auto" style={{ textAlign: 'center', fontWeight: 500, paddingBottom: '8px' }}>
            {calendarDate.toFormat('LLL yyyy')}
          </Col>
          <Col>
            <NextButton
              size="small"
              type="text"
              onClick={() => {
                changeCalendarMonth(calendarDate.plus({ month: 1 }))
              }}
            />
          </Col>
        </Row>
      )}
    />
  )
}
