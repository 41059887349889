import colors from 'constants/colors'
import { get as getPropertyAtPath } from 'lodash'
import { useState } from 'react'
import styled from 'styled-components'

import Form, { FormListProps, useCurrentForm } from 'components/form/Form'
import { FormItem, FormItemProps } from 'components/form/FormItem'
import { getNamePath } from 'rc-field-form/es/utils/valueUtil'

import { Input } from 'components/form/inline-edit/Input'
import Switch from 'components/form/inline-edit/Switch'

import { Collapse, Panel } from 'components/common/Collapse'
import { AddressInputWithMap } from '../AdressInputWithMap'

const PanelStyled = styled(Panel)`
  &.inactive {
    .ant-collapse-header {
      opacity: 0.7;
      color: ${colors.fontPrimary};
    }
  }
`

const Label = styled.span`
  font-weight: 600;
  color: ${colors.greyscale60};
`

interface YardFormItemProps {
  name: FormListProps['name']
  editing?: boolean
  listPrefix?: FormItemProps['listPrefix']
  onRemove: () => void
}

export const YardFormItem = ({
  editing,
  name,
  onRemove,
  listPrefix,
}: YardFormItemProps) => {
  const form = useCurrentForm()

  const path = getNamePath(name)
  const namePath = [...path, 'name']
  const activePath = [...path, 'active']

  const [defaultShow] = useState(() => {
    const value = form.getFieldValue(path)
    const isNew = value === undefined || value._isNew
    return isNew
  })

  const [active] = useState(() => form.getFieldValue(activePath))

  return (
    <Collapse mode="list" defaultActiveKey={defaultShow ? 'panel-0' : undefined}>
      <PanelStyled
        key="panel-0"
        forceRender
        className={!active ? 'inactive' : undefined}
        header={(
          <FormItem
            noStyle
            shouldUpdate={(prevValues, curValues) => (
              getPropertyAtPath(prevValues, namePath) !== getPropertyAtPath(curValues, namePath)
            )}
          >
            {() => (
              form.getFieldValue(namePath) || 'New Yard'
            )}
          </FormItem>
        )}
      >
        <Form.Item
          label={<Label>Yard Name</Label>}
          name="name"
          position="vertical"
          listPrefix={listPrefix}
          rules={[
            {
              required: true,
              message: 'Yard must have a name',
            },
          ]}
        >
          <Input placeholder="The name of this yard, ex. Orlando NW or Orlando HQ" editing={editing} />
        </Form.Item>

        <Form.Item
          label={<Label>Yard Address</Label>}
          required
          position="vertical"
          style={{ marginBottom: '20px' }}
        >
          <AddressInputWithMap
            name={listPrefix ? [listPrefix.name, 'address'] : 'address'}
            path={[...path, 'address']}
            editing={editing}
            rules={{
              street: [{ required: true }],
              city: [{ required: true }],
              state: [{ required: true }],
              zip: [{ required: true }],
              lat: [{ required: true }],
              lng: [{ required: true }],
            }}
          />
        </Form.Item>

        <FormItem
          label={<Label>Active</Label>}
          labelAlign="left"
          labelCol={{
            style: {
              flex: '0 0 60px',
            },
          }}
          name="active"
          listPrefix={listPrefix}
          valuePropName="checked"
        >
          <Switch editing={editing} />
        </FormItem>
      </PanelStyled>
    </Collapse>
  )
}
