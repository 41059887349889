import { Panel } from 'components/common/Collapse/Panel'
import { FormItem } from 'components/form/FormItem'

import { Checkbox } from 'components/form/inline-edit/Checkbox'
import { Input } from 'components/form/inline-edit/Input'

import { useFormState } from 'components/form/OrderForm/OrderFormState'
import { OrderFormPanelProps } from './PanelProps'

export const BillingPanel = (props: OrderFormPanelProps): JSX.Element => {
  const { editing } = useFormState()

  return (
    <Panel {...props} header="Billing Info">
      <FormItem
        label="Purchase Order"
        name={['order', 'purchaseOrder']}
      >
        <Input
          editing={editing}
          placeholder="Purchase Order"
        />
      </FormItem>
      <FormItem
        label="Collect on Delivery"
        name={['order', 'cashOnDelivery']}
        valuePropName="checked"
      >
        <Checkbox
          editing={editing}
        />
      </FormItem>
      <FormItem
        label="Flat Rate"
        name={['order', 'flatRate']}
        valuePropName="checked"
      >
        <Checkbox
          editing={editing}
        />
      </FormItem>
    </Panel>
  )
}
