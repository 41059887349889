import { PlusCircleOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { isNumber } from 'lodash'
import { Event } from 'schema'
import styled from 'styled-components'
import { RecursivePartial } from 'types'
import colors from '../../../constants/colors'
import { ResourceEditableStatus, useEditMode } from '../EditModeState'
import { SchedulerResource } from '../SchedulerResourcesState'
import { HeaderItem } from './HeaderItem'

export interface EquipmentHeaderItemProps {
  resource: SchedulerResource
  events?: RecursivePartial<Event>[]
}

export const EquipmentHeaderItem = (props: EquipmentHeaderItemProps) => {
  const { resource } = props
  const editMode = useEditMode()

  const editModeStatus = editMode.enabled && isNumber(resource.id) && editMode.equipment.status(resource.id)
  const showEditModeActions = (editModeStatus === ResourceEditableStatus.Selectable || editModeStatus === ResourceEditableStatus.Selected)

  const onClick = () => {
    if (!showEditModeActions) return
    if (isNumber(resource.id)) {
      editMode.equipment.toggleSelected(resource.id)
    }
  }

  return (
    <div onClick={onClick}>
      <HeaderItem>
        <Row align="middle" wrap={false} style={{ width: '100%' }}>
          <Col>
            {resource.name}
          </Col>
          {
            showEditModeActions && <EditModeCol />
          }
        </Row>
      </HeaderItem>
    </div>
  )
}

const EditModeCol = styled(Col).attrs({
  children: <PlusCircleOutlined />,
})`
  padding-left: 10px;

  .anticon {
    color: ${colors.editing.backgroundPrimary};
  }
`
