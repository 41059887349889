import { gql } from '@apollo/client'

export const COMMENT_FRAGMENT = gql`
  fragment CommentFields on Comment {
    id
    text
    personId
    createdAt
    person {
      id
      firstName
      lastName
    }
    metadata {
      key
      value
    }
    additionalIds {
      key
      value
    }
  }
`

export const GET_ORDER_COMMENTS = gql`
  query GetOrderComments(
    $where: QueryOrdersWhereInput
  ) {
    orders(
      where: $where
    ) {
      id
      revision
      comments {
        ...CommentFields
      }
    }
  }
  ${COMMENT_FRAGMENT}
`

export const UPDATE_ORDER_COMMENTS = gql`
  mutation UpdateOrder(
    $data: OrderUpdateInput!
    $where: OrderWhereUniqueInput!
  ) {
    updateOrder(
      data: $data
      where: $where
    ) {
      id
      comments {
        ...CommentFields
      }
    }
  }
  ${COMMENT_FRAGMENT}
`
