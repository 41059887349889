import {
  Customer, Query, QueryBranchesArgs, RequestSiteLocationInput,
} from 'schema'

import {
  Button, Col, Row, Tabs,
} from 'antd'

import { useQuery } from '@apollo/client'
import { GET_BRANCHES_FULL } from 'gql/branches'
import { compact, isString } from 'lodash'
import { useState } from 'react'
import { PhoneNumberInput } from '../common/PhoneNumberInput'
import { ContactSelect } from '../reference-selects/ContactSelect'
import { OrganizationMembersSelect } from '../reference-selects/OrganizationMembersSelect'

export interface RequestLocationViaSmsFormProps {
  customerId?: Customer['id']
  submitContent?: React.ReactNode
  onSubmission?: (data: RequestSiteLocationInput, action: 'update' | 'create') => void
  onCancel?: () => void
}

export const RequestLocationViaSmsForm: React.FC<RequestLocationViaSmsFormProps> = (props) => {
  const { data } = useQuery<Query, QueryBranchesArgs>(GET_BRANCHES_FULL)
  const branches = (data?.branches || []).filter((branch) => branch.active)
  const [value, setValue] = useState<any>()

  return (
    <div>
      Select an option below to send a SMS requesting a Google Maps or Apple Maps pin. When they reply, the address here will be automatically updated.

      <Tabs style={{ marginBottom: '20px' }} onChange={() => setValue(undefined)}>
        {props.customerId && (
          <Tabs.TabPane tab="Contacts" key="contacts">
            <ContactSelect
              block
              customerId={props.customerId}
              showExternalLink={false}
              value={value}
              // eslint-disable-next-line @typescript-eslint/no-shadow
              onChange={(value) => setValue(value)}
            />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab="Team" key="team">
          <OrganizationMembersSelect
            block
            value={value}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onChange={(value) => setValue(value)}
            resources={compact(branches.map((branch) => (
              !branch.mainlinePhone ? undefined : {
                id: branch.mainlinePhone,
                name: `${branch.name} Mainline`,
              }
            )))}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Phone" key="phone">
          <PhoneNumberInput
            placeholder="Enter Phone Number"
            value={value}
            onChange={(evt) => setValue(evt.currentTarget.value)}
          />
        </Tabs.TabPane>
      </Tabs>

      <Row gutter={10}>
        <Col flex="auto">
          <Button
            block
            size="small"
            onClick={() => props.onCancel?.()}
          >
            Cancel
          </Button>
        </Col>
        <Col flex="auto">
          <Button
            block
            size="small"
            type="primary"
            onClick={() => {
              if (!props.onSubmission) return

              props.onSubmission(
                isString(value) ? { phone: value } : { personId: value },
                'create'
              )
            }}
          >
            {props.submitContent || 'Continue'}
          </Button>
        </Col>
      </Row>
    </div>
  )
}
