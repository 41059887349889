import { gql, useQuery } from '@apollo/client'
import { ORGANIZATION_FULL_FRAGMENT } from 'gql/organization'
import React, { useContext } from 'react'
import {
  Person, Query, QueryUsersArgs, User,
} from 'schema'

const CurrentUserContext = React.createContext<{
  user: User | Person | null
}>({
  user: null,
})

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext)
  return context.user
}

const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    me {
      id
      firstName
      lastName

      organization {
        ...OrganizationFragment
      }

      phones {
        id
        number
      }

      ... on User {
        username
      }
    }
  }

  ${ORGANIZATION_FULL_FRAGMENT}
`

export const CurrentUserProvider: React.FC = ({ children }) => {
  const { data } = useQuery<Query, QueryUsersArgs>(CURRENT_USER_QUERY, {
    fetchPolicy: 'cache-first',
  })

  const user = data?.me || null

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
    <CurrentUserContext.Provider value={{ user }}>
      {children}
    </CurrentUserContext.Provider>
  )
}
