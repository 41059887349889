import { OrderContact } from 'schema'
import { RecursivePartial } from 'types'

export const sortOrderContacts = <T extends RecursivePartial<OrderContact>>(orderContacts: T[]): T[] => (
  orderContacts.sort((a, b) => {
    if (!a) return 1
    if (!b) return -1
    if (a.default) return -1
    if (b.default) return 1

    if (a.role?.sort && b.role?.sort) {
      return a.role?.sort > b.role?.sort ? 1 : -1
    }
    if (a.createdAt && b.createdAt) {
      return a.createdAt < b.createdAt ? -1 : 1
    }
    return 0
  })
)
