import { gql, useMutation } from '@apollo/client'
import { Mutation, Order, QuickbooksMutationNamespaceGenerateInvoicePdfLinkArgs } from 'schema'

import { Button, ButtonProps } from 'components/buttons/index'
import { QuickbooksIcon } from 'components/icons'

import { useNotification } from 'hooks/useNotification'

interface DownloadQuickbooksInvoiceButtonProps {
  orderId: Order['id']
  icon?: ButtonProps['icon'],
  onClick?: React.MouseEventHandler<HTMLElement> | undefined
  children?: React.ReactNode | undefined
}

export const DownloadQuickbooksInvoiceButton = (props: DownloadQuickbooksInvoiceButtonProps) => {
  const notification = useNotification()

  const [generatePdfLink] = useMutation<
    Mutation,
    QuickbooksMutationNamespaceGenerateInvoicePdfLinkArgs
  >(GENERATE_PDF_LINK_MUTATION, {
    variables: {
      where: {
        id: props.orderId,
      },
    },
  })

  if (!props.orderId) return null

  const { orderId, ...rest } = props

  const onClick: ButtonProps['onClick'] = async (evt) => {
    const { data, errors } = await generatePdfLink()

    let errorMsg: string | undefined

    if (errors?.length) {
      errorMsg = errors.map((err) => err.message).join('\n\n')
    }

    const url = data?.integrations?.quickbooks?.generateInvoicePdfLink
    if (!url) {
      errorMsg = 'Could not generate'
    }

    if (errorMsg) {
      notification.error({
        message: 'Error Downloading PDF',
        description: errorMsg,
        duration: 5,
      })
      return
    }

    window.open(url)

    if (props.onClick) {
      props.onClick(evt)
    }
  }

  return (
    <Button
      noStyle
      type="text"
      {...rest}
      onClick={onClick}
      icon={props.icon !== undefined ? props.icon : <QuickbooksIcon />}
    >
      {props.children || 'Download PDF'}
    </Button>
  )
}

const GENERATE_PDF_LINK_MUTATION = gql`
  mutation generateInvoicePdfLink($where: OrderWhereUniqueInput!) {
    integrations {
      quickbooks {
        generateInvoicePdfLink(where: $where)
      }
    }
  }
`
