import { pick } from 'lodash'
import React, { useState } from 'react'

import {
  Branch, BranchInput, Mutation,
  MutationCreateBranchArgs,
  MutationUpdateBranchArgs, Query,
  QueryBranchesArgs,
} from 'schema'

import { useMutation, useQuery } from '@apollo/client'
import { CREATE_BRANCH, GET_BRANCHES_FULL, UPDATE_BRANCH } from 'gql/branches'

import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
} from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loading } from 'components/Loading'

import { Form } from 'components/form/Form'
import { Input } from 'components/form/inline-edit/Input'
import SwitchInput from 'components/form/inline-edit/Switch'
import { Footer, Header } from 'hooks/useContentLayout'
import { WithIconTooltip } from '../../common/WithIconTooltip'
import { PhoneNumberInput } from '../common/PhoneNumberInput'
import { YardsFormList } from '../common/YardFormList'

const { Paragraph } = Typography

export interface BranchFormProps {
  id?: number
  editing?: boolean
  onSubmission?: (user: Branch, action: 'update' | 'create') => void
  onCancel?: () => void
}

export const BranchForm: React.FC<BranchFormProps> = (props) => {
  const { id, onSubmission } = props
  const isNew = id === undefined

  const [editing, setEditing] = useState<boolean>(props.editing || isNew)

  const { data, error: queryError, loading: queryLoading } = useQuery<Query, QueryBranchesArgs>(
    GET_BRANCHES_FULL,
    {
      variables: {
        where: {
          id: {
            equals: id,
          },
        },
      },
      skip: isNew,
    }
  )

  const [
    createBranch,
    createBranchResponse,
  ] = useMutation<Mutation, MutationCreateBranchArgs>(CREATE_BRANCH, {
    refetchQueries: [
      { query: GET_BRANCHES_FULL },
      'GetBranches',
    ],
  })

  const [
    updateBranch,
    updateBranchResponse,
  ] = useMutation<Mutation, MutationUpdateBranchArgs>(UPDATE_BRANCH)

  const { error: mutationError } = isNew ? createBranchResponse : updateBranchResponse

  const branch = (data?.branches || [])[0] as Branch | undefined

  const [form] = Form.useForm<Branch>()

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.resetFields()
  }

  const onFinish = async (values: Branch) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data: BranchInput = pick(values, [
      'active',
      'address',
      'alertPhone',
      'mainlinePhone',
      'name',
    ])

    data.yards = values.yards?.reduce((upsert, yard) => {
      const attrs: any = pick(yard, [
        'name',
        'active',
        'address.street',
        'address.street2',
        'address.city',
        'address.state',
        'address.zip',
        'address.lat',
        'address.lng',
        'address.timezone',
      ])

      if (yard.id) {
        upsert.update ||= []
        upsert.update.push({
          data: attrs,
          where: {
            id: yard.id,
          },
        })
      } else {
        upsert.create ||= []
        upsert.create.push(attrs)
      }

      return upsert
    }, {} as NonNullable<BranchInput['yards']>)

    if (id) {
      const resp = await updateBranch({
        variables: { data, where: { id } },
      })

      if (resp.errors) return

      setEditing(false)

      const updatedBranch = resp?.data?.updateBranch

      if (updatedBranch && onSubmission) {
        onSubmission(updatedBranch, 'update')
      }
    } else {
      const resp = await createBranch({
        variables: { data },
      })

      if (resp.errors) return

      setEditing(false)

      const newBranch = resp?.data?.createBranch

      if (newBranch && onSubmission) {
        onSubmission(newBranch, 'create')
      }
    }
  }

  if (queryError) {
    return (
      <ErrorMessage>
        <Paragraph>{queryError.message}</Paragraph>
      </ErrorMessage>
    )
  }

  if (!branch && queryLoading) return <Loading />

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="branch-form"
        onFinish={onFinish}
        requiredMark={editing}
        initialValues={{
          ...branch,
          active: isNew ? true : branch?.active,
        }}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) => (
            prev.name !== current.name
          )}
        >
          {() => (
            <Header hideIfOrphan>
              {form.getFieldValue('name')}
            </Header>
          )}
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
          ]}
        >
          <Input placeholder="Branch name" editing={editing} />
        </Form.Item>

        <Form.Item
          name="mainlinePhone"
          label={(
            <WithIconTooltip title="This is the phone number customers will contact you on.">
              Mainline Phone
            </WithIconTooltip>
          )}
          rules={[
            {
              len: 10,
              message: 'Phone number must be exactly 10 digits',
            },
          ]}
          validateTrigger={['onBlur']}
        >
          <PhoneNumberInput placeholder="Mainline Phone Number" editing={editing} />
        </Form.Item>

        <Form.Item
          name="alertPhone"
          label={(
            <WithIconTooltip title="This phone number will receive late departure notifications and calls.">
              Alert Phone
            </WithIconTooltip>
          )}
          rules={[
            {
              len: 10,
              message: 'Phone number must be exactly 10 digits',
            },
          ]}
          validateTrigger={['onBlur']}
        >
          <PhoneNumberInput placeholder="Alert Phone Number" editing={editing} />
        </Form.Item>

        <Form.Item
          label="Active"
          name="active"
          valuePropName="checked"
        >
          <SwitchInput
            editing={editing}
          />
        </Form.Item>

        <Divider>Yards</Divider>
        <YardsFormList name="yards" editing={editing} />
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}
