import React, { Suspense } from 'react'
import { AuthRouterProps } from './AuthRouter'

const AuthRouterLazy = React.lazy(() => import('./AuthRouter'))

export const AuthPage = (props: AuthRouterProps) => (
  <Suspense fallback={null}>
    <AuthRouterLazy {...props} />
  </Suspense>
)

export default AuthPage
