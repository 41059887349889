import { gql } from '@apollo/client'
import { Divider } from 'antd'
import { AddItemButton } from 'components/buttons'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { useSupportWidget } from 'hooks/useSupportWidget'
import { compact } from 'lodash'
import React from 'react'
import { EquipmentGroup } from 'schema'

export type EquipmentGroupSelectProps = ReferenceSelectProps<EquipmentGroup>

const GET_EQUIPMENT_GROUPS = gql`
  query GetEquipmentGroups {
    equipmentGroups {
      id
      name
      locationAware
    }
  }
`

export const equipmentGroupSelectOptionsGenerator: NonNullable<EquipmentGroupSelectProps['optionsGenerator']> = (items) => (
  compact(items.map((item) => {
    if (!item?.id) return

    return {
      label: (item.locationAware ?
        compact([item.name, 'by Yard']).join(' ')
        :
        item.name || ''
      ),
      value: item.id,
    }
  }))
)

const EquipmentGroupSelectDefaults: Partial<EquipmentGroupSelectProps> = {
  allowClear: true,
  placeholder: 'Group equipment on the schedule',
  optionsGenerator: equipmentGroupSelectOptionsGenerator,
  dropdownRender: (menu) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { show: showSupportWidget } = useSupportWidget()
    return (
      <div>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
          <AddItemButton
            onClick={() => {
              showSupportWidget('Hi, I would like to add a new Equipment group.')
            }}
          >
            Add a Group
          </AddItemButton>
        </div>
      </div>
    )
  },
}

export const EquipmentGroupSelect: React.FC<EquipmentGroupSelectProps> = (props) => (
  ReferenceSelect(buildPropsFromGql({
    name: 'Equipment Group',
    queryField: 'equipmentGroups',
    fetchPolicy: 'cache-first',
    gql: GET_EQUIPMENT_GROUPS,
    defaults: EquipmentGroupSelectDefaults,
  }, props))
)
