import { useBranch } from 'hooks/useBranch'

import bbox from '@turf/bbox'
import centroid from '@turf/centroid'
import { multiPoint } from '@turf/helpers'
import { compact } from 'lodash'
import { useMemo } from 'react'

export const useBranchGeoFeatures = () => {
  const branch = useBranch()

  const features = useMemo(() => {
    if (!branch) return

    const lngLatPairs = compact(branch.yards.map((yard) => {
      if (yard.address.lng && yard.address.lat) {
        return [yard.address.lng, yard.address.lat]
      }
      // eslint-disable-next-line no-useless-return
      return
    }))

    if (lngLatPairs.length === 0) {
      return {
        bbox: [-125.0011, 24.9493, -66.9326, 49.5904],
        center: [-96.5795, 39.8283],
        zoom: 1.7,
      }
    }

    const pointsFeature = multiPoint(lngLatPairs)

    return {
      bbox: bbox(pointsFeature),
      center: centroid(pointsFeature).geometry.coordinates,
      zoom: 6,
    }
  }, [branch?.yards])

  return features
}
