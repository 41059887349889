import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

import { useFeatures } from 'hooks/useFeature'
import { useHistory, useLocation } from 'react-router-dom'

import { FieldData } from 'rc-field-form/lib/interface'
import { RecursivePartial } from 'types/RecursivePartial'

import {
  Button, Col, Collapse, Input, Row,
} from 'antd'

import { ErrorMessage } from 'components/ErrorMessage'

import { Form, FormProps as FormPropsUntyped } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'

import { MessageOutlined } from '@ant-design/icons'
import { ViewMoreDrawer } from 'components/layout/ViewMoreDrawer'
import { Actions, Footer } from 'hooks/useContentLayout'
import { isEmpty, isEqual } from 'lodash'
import { FormValues } from './FormValues'
import { useUpsertOrder } from './logic/upsertOrder'
import { OrderFormProps } from './OrderFormProps'
import { OrderFormStateProvider, useNewOrderFormState } from './OrderFormState'

import { CommentsFormList } from '../common/CommentsFormList'
import { DEFAULT_CLEAN_TIME, DEFAULT_PREP_TIME } from './logic/constants'
import { BillingPanel } from './panels/BillingPanel'
import { CustomerPanel } from './panels/CustomerPanel'
import { DispatchPanel } from './panels/DispatchPanel'
import { GeneralPanel } from './panels/GeneralPanel'
import { InventoryPanel } from './panels/InventoryPanel'
import { PoursPanel } from './panels/PoursPanel'
import { SchedulePanel } from './panels/SchedulePanel'

type FormProps = FormPropsUntyped<FormValues>

export const OrderForm: React.FC<OrderFormProps> = (props) => {
  const route = useRef(props.route)

  const { detailsScope } = props
  const state = useNewOrderFormState(props)
  const {
    isNew,
    form,
    initialValues,
    order,
    editing,
    setEditing,
    setSelectedCustomer,
    setSelectedSite,
    selectedEquipment,
    lastSelectedEquipment,
    setSelectedEquipmentId,
  } = state

  const history = useHistory()
  const location = useLocation()

  const [features] = useFeatures('inventory.enabled')

  const errorsContainerRef = useRef<HTMLDivElement | null>(null)
  const [errors, setErrors] = useState<Error[]>([])
  const [upsertOrder] = useUpsertOrder(order?.id, {
    rescheduleFrom: props.rescheduleFrom,
    refetchQueries: ['GetEvents'],
  })

  useEffect(() => {
    setSelectedCustomer(order?.customer || (order?.customerId ? { id: order.customerId } : null))
    setSelectedSite(order?.site || (order?.siteId ? { id: order.siteId } : null))
    setSelectedEquipmentId(order?.planned?.equipmentId || order?.billable?.equipmentId || undefined)
  }, [order])

  useEffect(() => {
    if (!editing) return
    if (!isNew && !form.isFieldTouched(['details', 'equipmentId'])) return

    const scheduleLength = form.getFieldValue(['details', 'schedule'])?.length || 0
    const prepDurationPath = ['details', 'schedule', 0, 'duration']
    const cleanDurationPath = ['details', 'schedule', scheduleLength - 1, 'duration']

    const currentPrep = form.getFieldValue(prepDurationPath)
    const currentPrepWasDefault = (
      currentPrep === DEFAULT_PREP_TIME ||
      currentPrep === lastSelectedEquipment?.prepTime
    )

    if (currentPrepWasDefault) {
      form.setFields([
        {
          name: prepDurationPath,
          touched: false,
          value: selectedEquipment?.prepTime || DEFAULT_PREP_TIME,
        },
      ])
    }

    const currentClean = form.getFieldValue(cleanDurationPath)
    const currentCleanWasDefault = (
      currentClean === DEFAULT_CLEAN_TIME ||
      currentClean === lastSelectedEquipment?.cleanTime
    )

    if (currentCleanWasDefault) {
      form.setFields([
        {
          name: cleanDurationPath,
          touched: false,
          value: selectedEquipment?.cleanTime || DEFAULT_CLEAN_TIME,
        },
      ])
    }
  }, [selectedEquipment])

  const onCancel = () => {
    if (errors.length > 0) {
      setErrors([])
    }

    if (isNew) {
      history.push({
        // eslint-disable-next-line prefer-regex-literals
        pathname: location.pathname.replace(new RegExp('/new$'), ''),
      })
    } else {
      setEditing(false)
      setSelectedCustomer(order?.customer)
      setSelectedSite(order?.site)
      form.resetFields()
    }
  }

  let timesCache: (moment.Moment | undefined)[] = []

  const updateTimesCache = (values: FormValues) => {
    let last = timesCache.slice()
    if (last.length === 0) {
      last = (initialValues.details.schedule || []).map((entry) => entry.startTime || undefined)
    }
    timesCache = (values.details.schedule || []).map((entry) => entry.startTime || undefined)
    return [last, timesCache]
  }

  const updateSchedule = (changedValues: RecursivePartial<FormValues>, allValues: FormValues) => {
    if (!changedValues?.details?.schedule) return

    const [oldTimes, _newTimes] = updateTimesCache(allValues)

    const hasOtherPours = (allValues.details.schedule || []).filter((entry) => entry.step === 'pour').length > 1
    if (!hasOtherPours) return

    const updatedIndex = changedValues.details.schedule.findIndex((item) => item !== undefined)
    const updatedEntry = (changedValues.details.schedule || [])[updatedIndex]

    if (updatedEntry?.startTime === undefined) return

    const fullEntry = (allValues.details.schedule || [])[updatedIndex]
    if (fullEntry?.step !== 'pour') return

    if (!oldTimes[updatedIndex]) return
    const timeDiff = updatedEntry.startTime.diff(oldTimes[updatedIndex])

    if (timeDiff === 0) return

    const toSet: FieldData[] = [];

    (allValues.details.schedule || []).forEach((entry, index) => {
      if (index === updatedIndex) return
      if (!entry.startTime) return

      toSet.push({
        name: ['details', 'schedule', index, 'startTime'],
        value: entry.startTime.clone().add(timeDiff),
      })
    })

    form.setFields(toSet)
  }

  const onValuesChange: FormProps['onValuesChange'] = (changedValues: RecursivePartial<FormValues>, allValues) => {
    if (errors.length > 0) {
      setErrors([])
    }

    if (changedValues?.order && 'customerId' in changedValues.order) {
      setSelectedCustomer({ id: changedValues.order?.customerId || undefined })
      // Customer id changed should empty out contacts / customer
      form.setFieldsValue({
        order: {
          siteId: null,
          contacts: [{}],
        },
      })
    }

    if (changedValues?.order && 'siteId' in changedValues.order) {
      setSelectedSite({ id: changedValues.order?.siteId || undefined })
    }

    if (
      changedValues?.details &&
      'equipmentId' in changedValues.details
    ) {
      setSelectedEquipmentId(changedValues.details?.equipmentId || undefined)

      if (props.order?.planned?.equipmentId) {
        route.current = changedValues.details.equipmentId === props.order.planned.equipmentId ? props.route : undefined
      }
    }

    updateSchedule(changedValues, allValues)
  }

  const onFinish: NonNullable<FormProps['onFinish']> = async (values) => {
    setEditing('submitting')

    const update = values.order

    if (detailsScope.type === 'actuals') {
      let didInsertActuals = false

      if (update.actuals && update.actuals.length > 0) {
        for (let i = 0; i < update.actuals?.length; i++) {
          if (update.actuals[i].subType === detailsScope.subType) {
            update.actuals[i] = {
              ...values.details,
              ...detailsScope,
            }
            didInsertActuals = true
            break
          }
        }
      }
      if (!didInsertActuals) {
        update.actuals = [
          {
            ...values.details,
            ...detailsScope,
          },
        ]
      }
    } else {
      update[detailsScope.type] = values.details
    }

    if (route.current) {
      update.route = route.current
    }

    const upserted = await upsertOrder(update)
    if (upserted.errors) {
      setErrors((prev) => [...prev, ...(upserted.errors as unknown as Error[])])
      errorsContainerRef.current?.scrollIntoView()
      setEditing(true)
      return
    }
    setErrors([])

    const savedOrder = upserted.data?.createOrder || upserted.data?.updateOrder

    if (props.onSubmission && savedOrder) {
      await props.onSubmission(savedOrder, isNew ? 'create' : 'update')
    }

    setEditing(false)

    if (isNew && savedOrder) {
      history.push({
        // eslint-disable-next-line prefer-regex-literals
        pathname: location.pathname.replace(new RegExp('/new$'), `/order/${savedOrder.id}`),
      })
    }
  }

  const panels = {
    general: GeneralPanel,
    customer: CustomerPanel,
    pours: PoursPanel,
    schedule: SchedulePanel,
    dispatch: DispatchPanel,
    inventory: features['inventory.enabled'] ? InventoryPanel : null,
    billing: BillingPanel,
  }

  return (
    <OrderFormStateProvider value={state}>
      <Form
        initialValues={initialValues}
        form={form}
        name="order-form"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        scrollToFirstError
        requiredMark={editing}
      >
        <div ref={errorsContainerRef}>
          {errors.map((error) => (
            <ErrorMessage>Error on save: {error.message}</ErrorMessage>
          ))}
        </div>

        <Collapse defaultActiveKey={Object.keys(panels)} ghost>
          {Object.entries(panels).map(([key, Panel]) => (
            Panel && <Panel key={key} />
          ))}
        </Collapse>
        <FormItem
          hidden
          name={['order', 'revision']}
        >
          <Input />
        </FormItem>
        <FormItem
          hidden
          name={['order', 'branchId']}
        >
          <Input />
        </FormItem>

        <FormItem
          noStyle
          shouldUpdate={(prev, current) => (
            !isEqual(prev?.order?.comments, current?.order?.comments)
          )}
        >
          {() => {
            const commentsPath = ['order', 'comments']
            const noComments = isEmpty(form.getFieldValue(commentsPath))
            if (noComments) return

            return (
              <Actions>
                <span>
                  <ViewMoreDrawer
                    forceRender
                    button={<MessageOutlined />}
                    drawerStyle={{ padding: 20 }}
                    width="350px"
                  >
                    <div style={{ marginTop: '-20px' }}>
                      <CommentsFormList name={commentsPath} editing={false} />
                    </div>
                  </ViewMoreDrawer>
                </span>
              </Actions>
            )
          }}
        </FormItem>
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </OrderFormStateProvider>
  )
}
