import { useCreation } from 'ahooks'
import { FormItem } from 'components/form/FormItem'
import InputNumber from 'components/form/inline-edit/InputNumber'
import { pushAt } from 'helpers/pushAt'
import { byFieldAsc } from 'helpers/sortting'
import {
  compact, flow, isNil, max, omit, pick, toNumber,
} from 'lodash'
import fpMap from 'lodash/fp/map'
import { InventoryItemVisibility, OrderInventoryItemInput } from 'schema'
import { useOrderBillingState } from '../OrderBillingState'
import { Table } from './Table'
import { TableCol } from './TableCol'
import { TableRow } from './TableRow'
import { OrderCompareTableDetailsFragmentFragment, OrderCompareTableFragmentFragment } from './__generated__'

interface Row {
  title: string
  itemId: number
  cells: Cell[]
}

interface Cell {
  section: string
  field: string
}

const mapToNumbers = fpMap(toNumber)

const fieldFor = (section: string, id: number) => `${section}|inventory|${id}`

const isVisible = (item: OrderCompareTableDetailsFragmentFragment['inventory'][0]) => (
  item.item.visibility.orderBilling === InventoryItemVisibility.Always || (
    item.item.visibility.orderBilling === InventoryItemVisibility.Auto &&
    item.quantity
  )
)

export const InventoryTable = () => {
  const {
    details,
    placeholders,
    isEditing,
    register,
  } = useOrderBillingState<OrderCompareTableFragmentFragment>()

  // query for all inventory items here

  const rows = useCreation(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const rows: Row[] = []

    const inventoryItemBySortKey: Record<string, OrderCompareTableDetailsFragmentFragment['inventory'][0]['item']> = {}
    const orderInventoryItemsByField: Record<string, OrderCompareTableDetailsFragmentFragment['inventory'][0]> = {}

    Object.entries(details || {}).forEach(([section, detail]) => {
      if (!detail) return
      detail.inventory.forEach((orderInventoryItem) => {
        if (!isVisible(orderInventoryItem)) return
        const { item } = orderInventoryItem
        const sortableKey = `${item.category}|${item.name}|${item.id}`

        inventoryItemBySortKey[sortableKey] = item

        const field = fieldFor(section, item.id)
        orderInventoryItemsByField[field] = orderInventoryItem
      })
    })

    const sortedInventoryItems = Object.entries(inventoryItemBySortKey).sort(byFieldAsc('0')).map((entry) => entry[1])

    const sectionsWithoutBillable = Object.keys(omit(details, 'billable'))

    sortedInventoryItems.forEach((inventoryItem) => {
      const row: Row = {
        title: inventoryItem.name,
        itemId: inventoryItem.id,
        cells: [],
      }

      sectionsWithoutBillable.forEach((section) => {
        const field = fieldFor(section, inventoryItem.id)
        const item = orderInventoryItemsByField[field]

        if (item) {
          register({
            name: field,
            value: item.quantity,
          })

          row.cells.push({
            section,
            field,
          })
        }
      })

      const billableField = fieldFor('billable', inventoryItem.id)
      const dependsOn = row.cells.map((cell) => cell.field)

      const item = orderInventoryItemsByField[billableField]

      register({
        name: billableField,
        value: item?.quantity || null,
        dependsOn,
        autofill: true,
        suggest: ({ dependentData }) => {
          const actualFields = dependsOn.filter((field) => field.startsWith('actual.'))
          const actualMax = flow(Object.values, mapToNumbers, compact, max)(pick(dependentData, actualFields))
          if (!isNil(actualMax)) {
            return actualMax
          }

          return dependentData[fieldFor('planned', inventoryItem.id)]
        },
        onSave: (update, value) => {
          if (value === undefined) return update
          const input: OrderInventoryItemInput = {
            itemId: inventoryItem.id,
            quantity: value,
          }

          return pushAt(update, ['billable', 'inventory', 'set'], input)
        },
      })

      row.cells.push({
        section: 'billable',
        field: billableField,
      })

      rows.push(row)
    })

    return rows
  }, [details])

  if (rows.length === 0) {
    return null
  }

  return (
    <Table title="Additional Items">
      {rows.map((row) => (
        <TableRow key={row.itemId}>
          <TableCol section="title">
            {row.title}
          </TableCol>

          {row.cells.map((cell, cellIndex) => (
            <TableCol section={cell.section} key={cellIndex}>
              <FormItem name={cell.field}>
                <InputNumber
                  editing={isEditing(cell.section as any)}
                  placeholder={placeholders[cell.field]}
                />
              </FormItem>
            </TableCol>
          ))}
        </TableRow>
      ))}
    </Table>
  )
}
