import { omit } from 'lodash'
import { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export function useLocationStateDestructive<T>(key: string): T | null {
  const location = useLocation<any>()
  const history = useHistory()
  const target = location.state?.[key]
  const value = useRef<T | null>(null)

  if (target !== undefined) {
    value.current = target
  }

  useEffect(() => {
    if (target === undefined) return

    const { state, ...rest } = location

    history.replace({
      ...rest,
      state: omit(location, key),
    })
  }, [target])

  return value.current
}
