import { useRef } from 'react'
import styled from 'styled-components'

import { Button, Col, Row } from 'antd'

import { RemoveItemButton } from 'components/buttons'
import { Panel } from 'components/common/Collapse/Panel'
import { Form } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'

import { InputNumber } from 'components/form/inline-edit/InputNumber'
import { InventoryItemSelect, inventoryOptionsGenerator } from 'components/form/reference-selects/InventoryItemSelect'

import { useFormState } from 'components/form/OrderForm/OrderFormState'
import { InventoryItemVisibilitySimple } from 'schema'
import { OrderFormPanelProps } from './PanelProps'

const InventoryTableBody = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;

  .ant-form-item {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  // &.notediting > *:nth-child(even) {
  //   background-color: #f3f8fe;
  // }
`

export const InventoryPanel = (props: OrderFormPanelProps): JSX.Element => {
  const { form, editing } = useFormState()
  const inventory = useRef(form.getFieldValue(['details', 'inventory']))

  return (
    <FormItem
      noStyle
      shouldUpdate={(prevValues, curValues) => {
        const changed = prevValues?.details?.inventory !== curValues?.details?.inventory
        if (!changed) return false
        inventory.current = form.getFieldValue(['details', 'inventory'])
        return true
      }}
    >
      {() => (
        <Panel {...props} header="Inventory">
          <Form.List name={['details', 'inventory']}>
            {(fields, { add, remove }, { errors }) => (
              <>
                <Row gutter={16} style={{ fontWeight: 'bold' }}>
                  <Col flex="auto">
                    Inventory Item
                  </Col>
                  <Col flex="90px">
                    Quantity
                  </Col>
                  <Col flex="50px" />
                </Row>
                <InventoryTableBody className={editing ? 'editing' : 'notediting'}>
                  {fields.map((field, index) => (
                    <Row gutter={16} key={field.key} wrap={false}>
                      <Col flex="auto">
                        <FormItem
                          name={[field.name, 'itemId']}
                        >
                          <InventoryItemSelect
                            editing={editing}
                            dropdownClassName="ant-select-allow-options-wrap"
                            optionsGenerator={(items) => {
                              const orderFormOnly = items.filter((item) => (
                                item?.visibility?.orderForm !== InventoryItemVisibilitySimple.Hidden
                              ))
                              return inventoryOptionsGenerator(orderFormOnly)
                            }}
                          />
                        </FormItem>
                      </Col>
                      <Col flex="90px">
                        <FormItem
                          name={[field.name, 'quantity']}
                        >
                          <InputNumber editing={editing} />
                        </FormItem>
                      </Col>
                      <Col flex="50px">
                        {editing && <RemoveItemButton onClick={() => remove(index)} />}
                      </Col>
                    </Row>
                  ))}
                </InventoryTableBody>
                {editing && <Button onClick={() => { add() }}>Add Line Item</Button>}
              </>
            )}
          </Form.List>
        </Panel>
      )}
    </FormItem>
  )
}

  // type InventoryItemValue = Pick<OrderInventoryItem, 'itemId' | 'quantity'>

  // export interface OrderInventoryItemInputProps {
  //   field: FormListFieldData,
  //   editing?: boolean
  // }

  // export const OrderInventoryItemInput: React.FC<OrderInventoryItemInputProps> = ({ editing, field }) => {
  //   return <Row gutter={ 16 }>
  //     <Col flex="auto">
  //       <FormItem
  //         name={[field.name, "itemId"]}
  //       >
  //         <InventoryItemSelect editing={editing} />
  //       </FormItem>
  //     </Col>
  //     <Col flex="100px">
  //       <FormItem
  //         name={[field.name, "quantity"]}
  //       >
  //         <InputNumber editing={editing} />
  //       </FormItem>
  //     </Col>
  //   </Row>
  // }
