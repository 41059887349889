import {
  createContext, useCallback, useMemo, useState,
} from 'react'

import { TableItemRow } from '../types/ItemTypes'

type ItemRows = TableItemRow[]

interface IInvoiceItems {
  items: ItemRows,
  updateItems: (items: ItemRows) => void
}

const defaultItems: ItemRows = []

export const InvoiceItemsContext = createContext<IInvoiceItems>({ items: defaultItems, updateItems: () => null })

export const InvoiceItemsProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [items, setItems] = useState<ItemRows>(defaultItems)
  const updateItems = useCallback((it: ItemRows) => { setItems(it) }, [items])
  const memoizedValue = useMemo<IInvoiceItems>(() => ({ items, updateItems }), [items])
  return <InvoiceItemsContext.Provider value={memoizedValue}>{children}</InvoiceItemsContext.Provider>
}
