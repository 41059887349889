import { gql } from '@apollo/client'
import { ADDRESS_FRAGMENT } from './addresses'

export const BRANCH_FULL_FRAGMENT = gql`
  fragment BranchFragment on Branch {
    id
    name
    alertPhone
    mainlinePhone
    timezone
    yards {
      id
      name
      active
      address {
        ...AddressFields
      }
    }
    active
  }
  ${ADDRESS_FRAGMENT}
`

export const GET_BRANCHES_QUERY = gql`
  query GetBranches($where: QueryBranchesWhereInput) {
    branches(where: $where) {
      ...BranchFragment
    }
  }
`

export const GET_BRANCHES_FULL = gql`
  ${GET_BRANCHES_QUERY}
  ${BRANCH_FULL_FRAGMENT}
`

export const CREATE_BRANCH = gql`
  mutation CreateBranch(
    $data: BranchInput!
  ) {
    createBranch(
      data: $data
    ) {
      ...BranchFragment
    }
  }
  ${BRANCH_FULL_FRAGMENT}
`

export const UPDATE_BRANCH = gql`
  mutation UpdateBranch(
    $data: BranchUpdateInput!
    $where: BranchWhereUniqueInput!
  ) {
    updateBranch(
      data: $data
      where: $where
    ) {
      ...BranchFragment
    }
  }
  ${BRANCH_FULL_FRAGMENT}
`
