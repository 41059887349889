import colors from 'constants/colors'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import { fromMoment } from 'utils/luxon'

import { useSchedulerState } from 'components/scheduler/SchedulerState'
import { useEffect, useState } from 'react'

import { Actions, Body, Header } from 'hooks/useContentLayout'
import { DrawerContent } from 'layouts/Content/DrawerContent'

import { Card } from 'components/card'
import { DatePickerButton } from 'components/common/DatePickerButton'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loading } from 'components/Loading'
import { EquipmentScheduleList } from 'components/scheduler/EquipmentScheduleList'

import { SettingOutlined } from '@ant-design/icons'
import { NextIcon, PreviousIcon } from 'components/icons'
import { EditMode, useEditMode } from 'components/scheduler/EditModeState'
import { useViewportMode } from 'hooks/useViewportMode'
import { SchedulerToolbar } from '../../components/scheduler/SchedulerToolbar'
import { useFeature } from '../../hooks/useFeature'
import { ScheduleMobileSider } from './ScheduleMobileSider'
import { SchedulerDrawer } from './SchedulerDrawer'

const ScheduleDateWrapper = styled.div`
  display: flex;
  max-width: 350px;
  margin: 0 auto;
  padding: 0 35px;
  flex-wrap: nowrap;
  align-items: center;

  > * {
    flex: 0 0 auto;
  }

  ${DatePickerButton} {
    flex-grow: 1;
    margin: 0 15px;
  }

  > .anticon {
    font-size: 0.8em;
    padding: 4px;
  }
`

const ScheduleDatePicker = () => {
  const { selectedDate, setSelectedDate } = useSchedulerState()

  return (
    <ScheduleDateWrapper>
      <PreviousIcon
        onClick={() => {
          setSelectedDate(
            selectedDate.minus({ day: 1 })
          )
        }}
      />

      <DatePickerButton
        centered
        value={selectedDate.toMoment()}
        onChange={(date) => {
          if (!date) return
          setSelectedDate(fromMoment(date))
        }}
      >
        {selectedDate.toFormat('EEE, MMM d')}
      </DatePickerButton>

      <NextIcon
        onClick={() => {
          setSelectedDate(
            selectedDate.plus({ day: 1 })
          )
        }}
      />
    </ScheduleDateWrapper>
  )
}

const NoPadBody = styled(Card.Body)`
  padding: 0;
`

const SiderTrigger = styled(SettingOutlined)`
  background: #fff;
  position: fixed;
  top: 8px;
  right: 0px;
  padding-right: 10px;
  box-shadow: -3px 0 3px 3px ${colors.backgroundPrimary};
`

const BodyWrap = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;


  > * {
    flex: 1 0 auto;

    &:first-child {
      flex: 1 0 auto;
    }
  }
`

export const ScheduleMobileLayout = () => (
  <>
    <Schedule />
    <SchedulerDrawer />
  </>
)

const SUPPORTED_EDIT_MODES: Array<EditMode['mode']> = [
  'move-order-to-route',
]

const Schedule = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(true)
  const editMode = useEditMode()
  const [mobileEditModeBeta] = useFeature('mobile.moveRoute.enabled')
  const { setViewportMode } = useViewportMode()

  useEffect(() => {
    if (!editMode.enabled) return
    if (
      mobileEditModeBeta &&
      SUPPORTED_EDIT_MODES.includes(editMode.mode)
    ) return
    setViewportMode('desktop')
  }, [editMode.enabled, mobileEditModeBeta])

  const {
    errors,
    loading,
  } = useSchedulerState()

  return (
    <>
      <DrawerContent components={{ Body: NoPadBody }}>
        <Header>
          <ScheduleDatePicker />
        </Header>
        <Actions>
          <SiderTrigger onClick={() => setSiderCollapsed(!siderCollapsed)} />
        </Actions>
        <Body>
          <BodyWrap>
            <SchedulerToolbar />

            {!isEmpty(errors) ?
              <ErrorMessage>{errors?.map((e) => e.message)}</ErrorMessage>
              : loading ?
                <Loading />
                :
                <EquipmentScheduleList />}
          </BodyWrap>
        </Body>
      </DrawerContent>

      <ScheduleMobileSider
        collapsed={siderCollapsed}
        setCollapsed={setSiderCollapsed}
      />
    </>
  )
}
