import colors from 'constants/colors'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DateTime, Duration } from 'utils/luxon'

import { RouteWaypoint } from 'schema'

import { RightOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { Tooltip } from 'components/common/Tooltip'
import { WaypointTooltipContent } from './WaypointMarker'

const TravelSegmentWrap = styled.div`
  flex: 1 1 auto;
  padding: 10px 0;
`

const TravelSegmentLines = styled.div`
  position: relative;
  top: -2px;

  > div {
    position: absolute;
    top: 0;
    left 0;
  }
`

const TravelDuration = ({ children }: React.PropsWithChildren<unknown>) => (
  <Col style={{ alignSelf: 'center', fontSize: '0.75rem' }}>
    <Row gutter={10}>
      <Col><RightOutlined style={{ fontSize: '0.9em' }} /></Col>
      <Col>{children}</Col>
      <Col><RightOutlined style={{ fontSize: '0.9em' }} /></Col>
    </Row>
  </Col>
)

export interface TravelSegmentProps {
  startWaypoint?: RouteWaypoint & {
    eta?: string
  }
  endWaypoint?: RouteWaypoint & {
    eta?: string
  }
  routeStarted?: boolean
  onClick: React.MouseEventHandler<HTMLElement>
}

export const TravelSegment = (props: TravelSegmentProps) => {
  const {
    startWaypoint,
    endWaypoint,
    routeStarted,
    onClick,
  } = props

  const startTime = startWaypoint?.scheduledDepartureTime ? DateTime.fromISO(startWaypoint.scheduledDepartureTime) : null
  const endTime = endWaypoint?.scheduledArrivalTime ? DateTime.fromISO(endWaypoint.scheduledArrivalTime) : null
  const travelDuration = (endTime && startTime && endTime.diff(startTime)) || Duration.fromMillis(0)
  const offsetDuration = (startTime && startWaypoint?.actualDepartureTime) ? startTime.diff(DateTime.fromISO(startWaypoint.actualDepartureTime)) : null

  const calculateTravelProgress = () => {
    if (!startTime) {
      return travelDuration
    }
    if (endWaypoint?.actualArrivalTime) {
      return travelDuration
    }
    if (!startWaypoint?.actualDepartureTime) {
      return Duration.fromMillis(0)
    }

    return DateTime.utc().diff(startTime).plus(offsetDuration || Duration.fromMillis(0))
  }

  const [travelProgress, setTravelProgress] = useState(calculateTravelProgress())

  useEffect(() => {
    if (!startWaypoint?.actualDepartureTime) return
    if (endWaypoint?.actualArrivalTime) return

    let interval: number | null
    const firstDelay = (60 - (new Date()).getSeconds()) * 1000

    const timeout = setTimeout(() => {
      setTravelProgress(calculateTravelProgress())
      interval = setInterval(() => {
        setTravelProgress(calculateTravelProgress())
      }, 60 * 1000)
    }, firstDelay)

    return () => {
      if (timeout) clearTimeout(timeout)
      if (interval) clearInterval(interval)
    }
  }, [startWaypoint?.actualDepartureTime, endWaypoint?.actualArrivalTime])

  if (!travelDuration || !startWaypoint || !endWaypoint) return null

  let travelFillColor: string | undefined

  if (endWaypoint?.arrivalPerformance) {
    travelFillColor = colors.performance[endWaypoint.arrivalPerformance]
  } else if (startWaypoint?.actualDepartureTime) {
    travelFillColor = colors.performance[startWaypoint?.departurePerformance || 'onTime']
  } else if (routeStarted) {
    // eslint-disable-next-line unused-imports/no-unused-vars
    travelFillColor = colors.performance[startWaypoint?.departurePerformance || 'onTime']
  }

  const fillPercent = Math.min((travelProgress.as('minutes') / travelDuration.as('minutes')) * 100, 100)

  const color = routeStarted ? colors.performance[endWaypoint?.arrivalPerformance || startWaypoint?.departurePerformance || 'onTime'] : colors.greyscale60

  const title = (
    <Row gutter={10} align="top" wrap={false}>
      <Col flex="auto">
        <WaypointTooltipContent waypoint={startWaypoint} hideArrival />
      </Col>
      <TravelDuration>
        {travelDuration.toFormat('h:mm')}
      </TravelDuration>
      <Col flex="auto">
        <WaypointTooltipContent waypoint={endWaypoint} hideDeparture />
      </Col>
    </Row>
  )

  return (
    <Tooltip title={title} color={colors.greyscale80} noWrap>
      <TravelSegmentWrap onClick={onClick}>
        <TravelSegmentLines>
          <div style={{
            backgroundColor: color, height: '2px', marginTop: '1px', width: '100%',
          }}
          />
          <div style={{ width: `${fillPercent}%`, display: 'flex' }}>
            <div style={{ flex: '1 1 auto', backgroundColor: color, height: '4px' }} />
            {fillPercent > 0 && fillPercent < 100 && (
              <div style={{
                backgroundColor: color,
                marginTop: '-4px',
                width: '3px',
                height: '12px',
              }}
              />
            )}
          </div>
        </TravelSegmentLines>
      </TravelSegmentWrap>
    </Tooltip>
  )
}
