import { compact } from 'lodash'
import { useGeotabSettings } from './useGeotabSettings'

export const useGeotabUrl = (): (path?: string) => string | null => {
  const { data: settings } = useGeotabSettings()
  const database = settings?.database
  const server = settings?.server

  if (!settings?.enabled || !database || !server) {
    return () => null
  }

  const url = `https://${server}/${database}`

  return (path?: string) => {
    const pathCleaned = path?.replace(/^\/+/, '')
    return compact([url, pathCleaned]).join('/')
  }
}
