import { gql } from '@apollo/client'
import { Table } from './Table'
import { CubicYards } from './fields/CubicYards'
import { TravelDuration } from './fields/TravelDuration'

gql`
  fragment OrderCompareTableFragment on Order {
    id

    actuals {
      ...OrderCompareTableDetailsFragment
    }
    billable {
      ...OrderCompareTableDetailsFragment
    }
    planned {
      ...OrderCompareTableDetailsFragment
    }

    site {
      id
      name
      address {
        id
        timezone
      }
    }

    branch {
      id
      timezone
      yards {
        id
        name
      }
    }

    routeWaypointId
    route {
      id
      integrations {
        samsara {
          route {
            id
          }
        }
      }
      waypoints {
        id
        address {
          id
          timezone
        }
        actualDepartureTime
        scheduledDepartureTime
        actualArrivalTime
        scheduledArrivalTime
      }
    }
  }

  fragment OrderCompareTableDetailsFragment on OrderDetails {
    id
    type
    subType
    metrics {
      key
      value
    }
    schedule {
      id
      scheduleIndex
      startTime
      endTime
      step
      pour {
        id
        name
        type {
          id
          name
        }
      }
      stepDetails {
        slug
        name
      }
      metrics {
        key
        value
      }
    }
    inventory {
      id
      quantity
      item {
        id
        name
        category
        displayUnit
        visibility {
          orderBilling
        }
      }
    }
  }
`

export const OrderCompareTable = () => (
  <div style={{ minWidth: '650px' }}>
    <Table title="Stats">
      <CubicYards />
      <TravelDuration />
    </Table>
  </div>
)
