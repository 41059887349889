import moment from 'moment'

// eslint-disable-next-line @typescript-eslint/no-shadow
export const cloneMoment = (moment: moment.Moment) => {
  const zone = moment.zoneName()
  const value = moment.clone()

  value.zoneName = () => zone
  const origClone = value.clone.bind(value)

  value.clone = () => {
    const newMoment = origClone()
    return cloneMoment(newMoment)
  }

  return value
}
