import { buildPropsFromGql, defaultOptionsGenerator } from 'components/form/reference-selects/ReferenceSelect'
import { compact, get, isEmpty } from 'lodash'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { Col, FormItemProps, Row } from 'antd'
import { AddItemButton, RemoveItemButton } from 'components/buttons'
import { Form, FormListProps, useCurrentForm } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'
import { InputNumber } from 'components/form/inline-edit/InputNumber'
import { InventoryItemSelect } from 'components/form/reference-selects/InventoryItemSelect'
import { GET_INVENTORY } from 'gql/inventory'
import { byFieldAsc } from 'helpers/sortting'
import { InventoryItem, InventoryItemVisibilitySimple } from 'schema'

interface SystemFormListOptions {
  editing?: boolean
  defaultToOne?: boolean
}

interface SystemFormListProps extends Omit<FormListProps, 'name' | 'children'>, SystemFormListOptions {
  name?: FormListProps['name']
  label?: FormItemProps['label']
}

interface FormListFieldsProps extends SystemFormListOptions {
  name: FormListProps['name']
  listProps: Parameters<FormListProps['children']>
  items: InventoryItem[]
}

const SystemTableBody = styled.div`
  &:not(:empty) {
    margin-bottom: 10px;
  }

  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-form-item .ant-form-item-control-input-content {
    overflow-x: hidden;
  }
`

const FormListFields = (props: FormListFieldsProps) => {
  const {
    editing, defaultToOne, listProps, name,
  } = props
  const [fields, { add, remove }, { errors }] = listProps

  const form = useCurrentForm()

  const selectableItemIds = compact(props.items.map((item) => item.id))

  const systemFieldIndices = useRef<number[]>()
  const firstLoad = systemFieldIndices.current === undefined

  const calculateSystemFieldIndices = () => {
    const inventory = form.getFieldValue(name) || []
    const fieldIndicies = inventory.map((item: any, index: any) => {
      if (selectableItemIds.includes(item?.itemId)) {
        return index
      }
      if (item.category === 'system') {
        return index
      }
      // eslint-disable-next-line no-useless-return
      return
    }).filter((index: any) => index !== undefined)

    systemFieldIndices.current = fieldIndicies
  }

  useEffect(() => {
    if (selectableItemIds.length > 0) {
      calculateSystemFieldIndices()
    }
  }, [selectableItemIds])

  useEffect(calculateSystemFieldIndices, [])

  useEffect(() => {
    if (defaultToOne && fields.length === 0) {
      add({
        category: 'system',
      })
    }
  }, [defaultToOne])

  const displayForItem = (index: number) => (
    systemFieldIndices.current?.includes(index) ? undefined : 'none'
  )

  return (
    <>
      <Form.ErrorList errors={errors} />
      <SystemTableBody hidden={fields.length === 0}>
        <FormItem
          shouldUpdate={(prev, current) => {
            const didChange = get(prev, name) !== get(current, name)
            if (didChange || firstLoad) {
              calculateSystemFieldIndices()
              return true
            }
            return false
          }}
        >
          {() => (
            fields.map((field, index) => (
              <Row
                gutter={6}
                key={field.key}
                wrap={false}
                style={{ display: displayForItem(index) }}
              >
                <Col flex="auto">
                  <FormItem
                    name={[field.name, 'itemId']}
                  >
                    <InventoryItemSelect
                      placeholder="Select size"
                      editing={editing}
                      dropdownClassName="ant-select-allow-options-wrap"
                      optionsGenerator={(items) => {
                        const visibleItems = items.filter((item) => item.id && selectableItemIds.includes(item.id))
                        return defaultOptionsGenerator(visibleItems.sort(byFieldAsc('name')))
                      }}
                    />
                  </FormItem>
                </Col>
                <Col flex="75px">
                  <FormItem
                    name={[field.name, 'quantity']}
                  >
                    <InputNumber editing={editing} step={10} placeholder="ft" />
                  </FormItem>
                </Col>
                <Col flex="30px">
                  {editing && <RemoveItemButton onClick={() => remove(index)} />}
                </Col>
              </Row>
            )))}
        </FormItem>
      </SystemTableBody>
      {editing && (
        <AddItemButton
          type="default"
          onClick={() => {
            add({ category: 'system' })
          }}
        >
          Add System
        </AddItemButton>
      )}
    </>
  )
}

const useSystemInventoryItems = () => {
  const selectProps = buildPropsFromGql({
    name: 'Inventory Item',
    queryField: 'inventory',
    gql: GET_INVENTORY,
    withCurrentBranchFilter: true,
  }, {})

  const visibleItems = (selectProps.remoteResources || []).filter((item) => (
    item?.visibility?.orderForm !== InventoryItemVisibilitySimple.Hidden && item.category?.toLowerCase() === 'system'
  ))

  return visibleItems
}

export const SystemFormList = (props: SystemFormListProps) => {
  const {
    name: nameProp,
    editing,
    defaultToOne,
    label,
    ...args
  } = props

  const name = nameProp || 'inventory'

  const options = { editing, defaultToOne }

  const items = useSystemInventoryItems()

  if (isEmpty(items)) {
    return null
  }

  return (
    <FormItem label={label}>
      <Form.List
        name={name}
        {...args}
      >
        {(...listProps) => <FormListFields name={name} {...options} listProps={listProps} items={items} />}
      </Form.List>
    </FormItem>
  )
}
