import { compact } from 'lodash'

import { Panel } from 'components/common/Collapse/Panel'
import { FormItem } from 'components/form/FormItem'

import { OrderCancellationReasonSelect } from 'components/form/reference-selects/OrderCancellationReasonSelect'
import { OrderStatusSelect } from 'components/form/reference-selects/OrderStatusSelect'

import { useFormState } from 'components/form/OrderForm/OrderFormState'
import { OrderScheduleEntry } from 'schema'
import { StartTimeInput } from '../common/StartTimeInput'
import { OrderFormPanelProps } from './PanelProps'

export const GeneralPanel = (props: OrderFormPanelProps): JSX.Element => {
  const {
    editing, order, branch, selectedSite, form, isReschedule,
  } = useFormState()

  const scheduleItems: OrderScheduleEntry[] = form.getFieldValue(['details', 'schedule']) || []
  const firstPourIndex = scheduleItems.findIndex((entry) => entry.step === 'pour')

  return (
    <Panel {...props} hideHeader style={{ marginTop: 0 }}>
      <FormItem
        label="Pour Time"
        name={['details', 'schedule', firstPourIndex, 'startTime']}
        rules={[
          {
            required: true,
            message: 'Pour Time is required',
          },
        ]}
      >
        <StartTimeInput
          editing={editing}
          placeholder=""
          branchTimezone={branch?.timezone}
          siteTimezone={selectedSite?.address?.timezone}
          id="order-form_details_general_startTime"
        />
      </FormItem>
      <FormItem
        label="Order Status"
        name={['order', 'status']}
      >
        <OrderStatusSelect
          editing={editing}
          resources={compact([order?.statusDetails])}
        />
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={(prev, next) => prev?.order?.status !== next?.order?.status}
      >
        {() => {
          const notCancelled = form.getFieldValue(['order', 'status']) !== 'cancelled'

          if (isReschedule && notCancelled) {
            return (
              <FormItem
                label="Reschedule Reason"
                name={['order', 'cancellationReason']}
              >
                <OrderCancellationReasonSelect
                  placeholder="Select a Reschedule Reason"
                  editing={editing}
                />
              </FormItem>
            )
          }

          return (
            <FormItem
              label="Cancel Reason"
              name={['order', 'cancellationReason']}
              hidden={notCancelled}
            >
              <OrderCancellationReasonSelect
                editing={editing}
                resources={compact([order?.cancellationReasonDetails])}
              />
            </FormItem>
          )
        }}
      </FormItem>
    </Panel>
  )
}
