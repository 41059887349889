import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import { useSelectedBranch } from 'hooks/useBranch'
import { Redirect } from 'react-router-dom'

export const HomePage = () => {
  const { branch, loading, error } = useSelectedBranch()

  if (error && !loading) {
    return <ErrorMessage>Error loading branch {error}</ErrorMessage>
  }

  if (loading || !branch) {
    return <Loading />
  }

  return <Redirect to={`/branches/${branch.id}/schedule`} />
}

export default HomePage
