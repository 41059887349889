import { compact, difference } from 'lodash'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useCloudinaryUploadParams } from 'hooks/useCloudinaryUploadParams'

import { IdcardOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { Upload } from 'components/form/inline-edit/Upload'

const UploadStyled = styled(Upload)`
  .ant-upload-select {
    opacity: 0.8;

    .anticon {
      font-size: 1.2em;
    }

    .anticon + div {
      margin-top: 4px;
    }
  }
`

const PreviewModal = styled(Modal)`
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-close-x {
    font-size: 14px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
`

interface CredentialImageUploadProps {
  value?: string[]
  onChange?: (value: string[]) => void
  editing?: boolean
}

const fileUrl = (file: UploadFile) => file.url || file.response?.secure_url || file.response?.url

export const CredentialImageUpload = ({ editing, value, ...args }: CredentialImageUploadProps) => {
  const onChange = args.onChange || (() => { })

  const cloudinary = useCloudinaryUploadParams('credential')

  const [previewed, setPreviewed] = useState<false | string>(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    if (value === undefined) return

    setFileList((current) => {
      const existingUrls = compact(current.map((item) => item.url))
      const newUrls = difference(value || [], existingUrls)
      const newItems: UploadFile[] = newUrls.map((url, index) => ({
        uid: (index + existingUrls.length).toString(),
        name: '',
        url,
        status: 'done',
      }))
      return [...current, ...newItems]
    })
  }, [value])

  return (
    <>
      <UploadStyled
        name="file"
        listType="picture-card"
        editing={editing}
        showUploadList={{
          showPreviewIcon: true,
          showRemoveIcon: editing,
          // showDownloadIcon: true,
        }}
        action={cloudinary.url}
        data={cloudinary.data()}
        fileList={fileList}
        onPreview={((img) => {
          if (img.url) {
            setPreviewed(img.url)
          }
        })}
        onRemove={(toDelete) => {
          if (!toDelete.url) return true

          const newUrls = compact(fileList.map((file) => {
            if (!file.url) return
            if (toDelete.url === file.url) return
            return file.url
          }))

          onChange(newUrls)
        }}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onChange={({ file, fileList }) => {
          const withDefaultUrls = fileList.map((item) => ({
            ...item,
            thumbUrl: item.url,
            url: fileUrl(item),
          }))

          setFileList(withDefaultUrls)

          if (file.status === 'done') {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const urls = compact(withDefaultUrls.map((file) => fileUrl(file)))
            onChange(urls)
          }
        }}
      >
        <div>
          <IdcardOutlined />
          <div style={{ opacity: editing ? 1 : 0 }}>Upload</div>
        </div>
      </UploadStyled>
      <PreviewModal
        visible={Boolean(previewed)}
        title={null}
        footer={null}
        onCancel={() => setPreviewed(false)}
      >
        <img style={{ width: '100%' }} src={previewed || ''} />
      </PreviewModal>
    </>
  )
}
