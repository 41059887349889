import React, { useMemo } from 'react'

import { formatPhone } from 'helpers/formatPhone'
import { byFieldAsc } from 'helpers/sortting'
import styled from 'styled-components'

import {
  Button, Col, Row, Typography,
} from 'antd'
import AntTable, { TableProps as AntTableProps } from 'antd/lib/table/Table'

import { QueryLazyOptions, useQuery } from '@apollo/client'
import { GET_SUPPLIERS_FULL } from 'gql/suppliers'
import { DocumentNode } from 'graphql'

import {
  generatePath, Link, Route, useHistory, useParams,
  useRouteMatch,
} from 'react-router-dom'

import { SupplierDrawer } from 'components/drawers/SupplierDrawer'
import { ErrorMessage } from 'components/ErrorMessage'
import { SupplierFormProps } from 'components/form/SupplierForm'
import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'
import { Actions, Body, Header } from 'hooks/useContentLayout'
import { PageContent } from 'layouts/Content/PageContent'
import { compact, uniq } from 'lodash'
import {
  Query,
  QuerySuppliersArgs, Supplier,
} from '../schema'

const { Paragraph, Title } = Typography

const Table: React.FC<AntTableProps<any>> = styled(AntTable)`
  .ant-table-row:hover {
    cursor: pointer;
  }

  .ant-table-column-sort {
    background: inherit;
  }
`

const useIndexQuery = (): [DocumentNode, QueryLazyOptions<QuerySuppliersArgs>] => [GET_SUPPLIERS_FULL, { variables: {} }]

const Index = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const indexQuery = useIndexQuery()

  const { loading, data, error } = useQuery<Query, QuerySuppliersArgs>(...indexQuery)

  const suppliers = data?.suppliers || []

  const columns: NonNullable<React.ComponentProps<typeof Table>['columns']> = [
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: (a, b) => byFieldAsc('name')(a, b) || byFieldAsc('name2')(a, b),
      },
      render: (_, row: Supplier) => uniq(compact([row.name, row.name2])).join('  |  '),
    },
    {
      title: 'Phones',
      dataIndex: 'phones',
      render: (phones: Supplier['phones'], _row: Supplier) => phones.map((phone, i) => (
        <div key={i}>
          {formatPhone(phone.number)}
        </div>
      )),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (value: Supplier['active'], row: Supplier) => (value ? 'active' : 'inactive'),
      sorter: {
        compare: (a: Supplier, b: Supplier) => (a.active ? -1 : 1),
      },
    },
  ]

  const onRow = (record: Supplier, _rowIndex: number | undefined) => {
    const rowLink = `${match.url}/${record.id}`
    return {
      onClick: () => {
        history.push(rowLink)
      },
    }
  }

  return (
    <PageContent>
      <Header>
        <Title level={2}>
          Suppliers
        </Title>
      </Header>

      <Actions>
        <Link to={`${match.url}/new`}>
          <Button type="primary">
            Add New Supplier
          </Button>
        </Link>
      </Actions>

      <Body>
        {error && (
          <ErrorMessage>
            <Paragraph>{error.message}</Paragraph>
          </ErrorMessage>
        )}
        <Table
          columns={columns}
          rowKey="id"
          dataSource={suppliers}
          pagination={false}
          onRow={onRow}
          locale={{
            emptyText: loading ? 'Loading' : undefined,
          }}
          bordered
        />
      </Body>
    </PageContent>
  )
}

const Show = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id: urlId, edit: urlEdit } = useParams<{ id: string, edit?: string }>()

  const isNew = urlId === 'new'
  const id = isNew ? undefined : parseInt(urlId)
  const basePath = history.location.pathname.slice(0, match.path.indexOf('/:id'))

  const props: SupplierFormProps = {
    id,
    editing: isNew || urlEdit === 'edit',
    onSubmission: (customer, action) => {
      if (action === 'create' && customer?.id) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: `${basePath}/${customer?.id}`,
        })
      }
    },
    onCancel: () => {
      if (isNew) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: basePath,
        })
      }
    },
  }

  return <SupplierDrawer {...props} />
}

export const SuppliersPage = (_args: any) => {
  const history = useHistory()
  const match = useRouteMatch()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  return (
    <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
      <Col flex="auto" style={{ minWidth: 0 }}>
        <Route path={match.path} component={Index} />
      </Col>
      <RoutableDrawer showClose={false} onClose={() => { history.replace(basePath) }} width={475}>
        <Route path={`${match.path}/:id/:edit?`} component={Show} />
        <Route path={match.path} hideOnMobile>
          <div className="padded">
            Select a Supplier to view and edit.
          </div>
        </Route>
      </RoutableDrawer>
    </Row>
  )
}

export default SuppliersPage
