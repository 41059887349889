import { useCreation } from 'ahooks'
import { DataGridMiddleware, fieldMiddleware, SuggestableDataGrid } from 'helpers/DataGrid'
import { roundTo } from 'helpers/rounding'
import { useOrganization } from 'hooks/useOrganization'
import { isNil } from 'lodash'
import { Duration } from 'luxon'
import { isMoment, max as momentMax } from 'moment'
import { SetRequired } from 'type-fest'
import { FullOrder } from './data'

const QUARTER_HOUR_MILLIS = Duration.fromObject({ hours: 0.25 }).as('milliseconds')
const HOUR_MILLIS = Duration.fromObject({ hour: 1 }).as('milliseconds')

const travelHoursRoundToQuarterHour = fieldMiddleware(
  'billable|travelHours|duration',
  (args, next) => {
    const result = next(args)
    if (isNil(result)) return result
    return roundTo(result, QUARTER_HOUR_MILLIS)
  }
)

const travelHoursMinimumHour = fieldMiddleware(
  'billable|travelHours|duration',
  (args, next) => {
    const result = next(args)
    if (isNil(result)) return result
    return Math.max(result, HOUR_MILLIS)
  }
)

const onSiteHoursRoundToQuarterHour = fieldMiddleware(
  'billable|onSiteTotal|duration',
  (args, next) => {
    const result = next(args)
    if (isNil(result)) return result
    return roundTo(result, QUARTER_HOUR_MILLIS)
  }
)

const onSiteHoursMinimum = (min: number) => (
  fieldMiddleware(
    'billable|onSiteTotal|duration',
    (args, next) => {
      const result = next(args)
      if (isNil(result)) return result
      return Math.max(result, HOUR_MILLIS * min)
    }
  )
)

const onSiteStartTimeMinimumOfPlannend = fieldMiddleware(
  'billable|onSite.0|startTime',
  (args, next) => {
    const reportedTime = next(args)

    if (args.current !== undefined) {
      return reportedTime
    }

    if (!isMoment(reportedTime)) {
      return reportedTime
    }

    const scheduledStartTime = args.data['planned|onSite.0|startTime']
    if (!scheduledStartTime) {
      return reportedTime
    }

    const suggestedEndTime = args.data['actual.operator|onSite.0|endTime']
    if (isMoment(suggestedEndTime)) {
      const reportedWithinScheduled = (
        reportedTime.isSameOrBefore(scheduledStartTime) &&
        suggestedEndTime.isSameOrAfter(scheduledStartTime)
      )

      if (reportedWithinScheduled) {
        return momentMax(reportedTime, scheduledStartTime)
      }
    }

    return reportedTime
  }
)

type MiddlwareByTenant = SetRequired<Record<'_default' | number, DataGridMiddleware>, '_default'>
type TenantScopableMiddleware = DataGridMiddleware | MiddlwareByTenant

const SUGGEST_MIDDLEWARE: TenantScopableMiddleware[] = [
  travelHoursRoundToQuarterHour,
  travelHoursMinimumHour,
  onSiteStartTimeMinimumOfPlannend,
  onSiteHoursRoundToQuarterHour,
  {
    _default: onSiteHoursMinimum(4),
    26: onSiteHoursMinimum(3),
  },
]

export const useOrderBillingCalculator = (order: FullOrder | undefined) => {
  const organization = useOrganization()

  const dataGrid = useCreation(() => {
    const grid = new SuggestableDataGrid()

    SUGGEST_MIDDLEWARE.forEach((middleware) => {
      let resolvedMiddleware: DataGridMiddleware

      if ('_default' in middleware) {
        resolvedMiddleware = middleware[organization?.id || -1] || middleware._default
      } else {
        resolvedMiddleware = middleware
      }

      grid.applyOnSuggestMiddleware(resolvedMiddleware)
    })

    return grid
  }, [order, organization])

  return dataGrid
}
