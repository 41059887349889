import {
  compact, groupBy, isNil, isString, pick, round, sumBy,
} from 'lodash'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { IInvoiceBalanceTotal } from '../../../hooks/useBalance'
import {
  INVOICE_STATUSES,
  Invoice, InvoiceServiceLineItem, InvoiceSubtotalLineItem,
} from '../../../hooks/useInvoices'
import { useTimezone } from '../../../hooks/useTimezone'
import { GetOrderForBillingQuery, useGetOrderForBillingQuery } from '../../../pages/Orders/OrderShow/OrderBillingState/__generated__/data'
import { Order } from '../../../schema'
import {
  IInvoiceSettings, ItemRow, ROW_TYPE,
} from '../../../types/ItemTypes'
import { EquipmentList } from './productServiceList'

const prefillInvoiceFromOrder = (order: GetOrderForBillingQuery['order']) => {
  if (!order) return {}

  const services = EquipmentList

  const orderProperties = pick(order, [
    'dateOfService',
    'dateOfServiceLocal',
    'purchaseOrder',
    'customer',
    'site',
  ])

  const lot = compact(order.planned?.schedule?.map((entry) => entry?.pour?.name)).join(', ') || undefined
  const plannedEquipment = order?.planned?.equipment
  const billableEquipment = order?.billable?.equipment

  const billableEquipmentSlugPrefix = (billableEquipment?.displayName || plannedEquipment?.displayName)
  const servicesBySlug = groupBy(services, 'slug')

  const lineItems: Array<Omit<InvoiceServiceLineItem, 'id'> | Omit<InvoiceSubtotalLineItem, 'id'>> = []

  const metrics = order.billable?.metrics || []

  for (const metric of metrics) {
    const slug = `${billableEquipmentSlugPrefix}.${metric.key}`
    const service = servicesBySlug[slug][0]
    if (service) {
      const quantity = (isNil(metric.value) ? undefined : isString(metric.value) ? parseInt(metric.value) : metric.value)
      const total = isNil(quantity) ? service.price : round(service.price * quantity, 2)

      lineItems.push({
        type: ROW_TYPE.PRODUCT,
        itemId: service.id,
        item: service.name,
        description: service.description || '',
        qty: quantity || 0,
        rate: service.price,
        baseRate: service.price,
        amount: round(total, 2),
        tax: false,
      })
    }
  }

  const primer = servicesBySlug.primer[0]

  lineItems.push({
    type: ROW_TYPE.PRODUCT,
    itemId: primer.id,
    item: primer.name,
    description: primer.description || '',
    qty: 1,
    rate: primer.price,
    baseRate: primer.price,
    amount: round(primer.price * 1, 2),
    tax: false,
  })

  // const sum = sumBy(lineItems, 'total')

  // lineItems.push({
  //   type: ROW_TYPE.SUBTOTAL,
  //   total: sum,
  // })

  // const surcharge = servicesBySlug.fuel_surcharge[0]

  // lineItems.push({
  //   type: ROW_TYPE.PRODUCT,
  //   itemId: surcharge.id,
  //   item: surcharge.name,
  //   description: surcharge.description || '',
  //   qty: 1,
  //   rate: surcharge.price,
  //   amount: round(surcharge.price * 1, 2),
  //   tax: false,
  // })

  const amountDue = sumBy(lineItems, (item) => (item.type === ROW_TYPE.SUBTOTAL ? 0 : item.amount))
  const invoiceDate = order?.dateOfServiceLocal?.slice(0, 10) || DateTime.local().toISODate()

  const getMetricValue = (key: string) => metrics.find((m) => m.key === key)?.value
  const travelDuration = getMetricValue('travelHours')
  const jobDuration = getMetricValue('onSiteHours')
  const cubicYards = getMetricValue('cubicYards')
  const pourType = compact(order.planned?.schedule?.map((entry) => entry?.pour?.type?.name)).join(', ') || undefined

  const invoice = {
    ...orderProperties,
    id: order.id.toString(),
    status: 'new',
    orderIds: [order.id],
    lot,
    plannedEquipment,
    billableEquipment,
    invoiceDate,
    amountDue,
    travelDuration,
    jobDuration,
    cubicYards,
    pourType,
    balance: amountDue,
    lineItems: lineItems.map((item, index) => ({ ...item, id: index + 1 })),
  } as Partial<Invoice>

  return invoice
}

interface IConvertToInvoice {
  // order: GetOrderForBillingQuery['order'],
  settings: IInvoiceSettings,
  // jobDetails: IInvoiceJobDetails,
  balance: IInvoiceBalanceTotal,
  items: ItemRow[],
  timezone: string,
}

type ConvertToInvoice = (data: IConvertToInvoice) => Invoice

export const convertToInvoice: ConvertToInvoice = (data) => {
  const {
    balance, items, settings, timezone,
  } = data
  return {
    id: settings.invoiceNumber,
    status: 'new',
    statusDetails: INVOICE_STATUSES.new,
    payments: [],
    amountDue: balance.total,
    amountPaid: 0,
    invoiceDate: DateTime.fromJSDate(settings.invoiceDate).setZone(timezone).startOf('day').toISODate(),
    dueDate: DateTime.fromJSDate(settings.dueDate).setZone(timezone).startOf('day').toISODate(),
    lineItems: items.map((item) => item),
    balance: balance.total,
    // site: order?.site,
    // customer: order?.customer,
  }
}

type UseNewInvoiceProps = {
  orderId?: Order['id']
}

export const useNewInvoice = ({ orderId }: UseNewInvoiceProps = {}) => {
  const timezone = useTimezone()
  const { data: queryData, loading } = useGetOrderForBillingQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: orderId || 0 },
    skip: !orderId,
  })

  const order = queryData?.order

  const invoice: Partial<Invoice> = useMemo(() => {
    const base: Partial<Invoice> = {
      status: 'new',
      invoiceDate: DateTime.local().setZone(timezone).startOf('day').toISODate(),
      ...prefillInvoiceFromOrder(order),
    }

    base.dueDate ||= base.invoiceDate ?
      DateTime.fromISO(base.invoiceDate, { zone: timezone }).plus({ days: 30 }).toISODate()
      :
      undefined

    return base
  }, [order])

  return { data: { invoice }, loading }
}
