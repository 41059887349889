import { gql } from '@apollo/client'
import { PHONE_FRAGMENT } from './phones'

export const OPERATORS_FRAGMENT = gql`
  fragment OperatorFields on Operator {
    id
    firstName
    lastName
    active
    createdAt
    updatedAt
    phones {
      ...PhoneFields
    }
  }
  ${PHONE_FRAGMENT}
`

export const GET_OPERATORS = gql`
  query GetOperator(
    $orderBy: [QueryOperatorsOrderByInput!],
    $where: QueryOperatorsWhereInput
  ) {
    operators(
      orderBy: $orderBy,
      where: $where
    ) {
      ...OperatorFields
    }
  }
  ${OPERATORS_FRAGMENT}
`
