import {
  Col, Row,
} from 'antd'
import { FormProps } from 'antd/lib/form'

export const formLayout: Pick<FormProps, 'labelCol' | 'wrapperCol'> = {
  labelCol: { flex: '130px' },
  wrapperCol: { flex: 'auto' },
}

export const AlignWithInputs: React.FC = ({ children }) => (
  <Row className="ant-form-item">
    <Col {...formLayout.labelCol} />
    <Col {...formLayout.wrapperCol}>
      {children}
    </Col>
  </Row>
)

export const AlignWithLabels: React.FC = ({ children }) => (
  <Row className="ant-form-item">
    <Col {...formLayout.labelCol}>
      {children}
    </Col>
    <Col {...formLayout.wrapperCol} />
  </Row>
)
