import { gql, useQuery } from '@apollo/client'
import { AdditionalDataButton } from 'components/buttons'
import { SiteForm, SiteFormProps } from 'components/form/SiteForm'
import { SiteActionsMenu } from 'components/menus/SiteActionsMenu'
import { TagsEditor } from 'components/tags/TagsEditor'
import { TAG_SLIM_FRAGMENT } from 'gql/tags'
import { CloseButton } from 'hooks/useClosable'
import {
  Actions, Body, ContentContext, Footer, Header, useContentLayoutState,
} from 'hooks/useContentLayout'
import { isNil } from 'lodash'
import { Query } from 'schema'
import { DrawerContent } from '../../layouts/Content/DrawerContent'
import { FooterSection } from './FooterSection'

export type SiteDrawerProps = SiteFormProps

export const SiteDrawer = (props: SiteDrawerProps) => {
  const { id, customerId } = props
  const isNew = isNil(id)

  const { data } = useQuery<Query, any>(GET_SITE_WITH_TAGS, {
    skip: isNew,
    variables: { id },
    nextFetchPolicy: 'cache-only',
  })

  const FormContentLayout = useContentLayoutState()

  return (
    <DrawerContent>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <TagsEditor scope="sites" id={id!} selected={data?.sites?.[0]?.tags || []} enabled={!isNew}>
        <Header>
          {id ? <FormContentLayout.Header.Target /> : 'New Site'}
          <TagsEditor.Tags style={{ marginTop: '5px' }} />

        </Header>

        <Actions>
          <TagsEditor.Selector />
          <SiteActionsMenu site={{ id, customerId }} />
          <CloseButton />
        </Actions>
      </TagsEditor>

      <Body>
        <ContentContext.Provider value={FormContentLayout}>
          <SiteForm key={`site-${id}`} {...props} />
        </ContentContext.Provider>
      </Body>

      <Footer>
        <FooterSection>
          <FormContentLayout.Footer.Target />
        </FooterSection>

        {id && (
          <>
            <AdditionalDataButton to={`/customers/${customerId}`}>
              View Customer
            </AdditionalDataButton>

            <AdditionalDataButton to={`/orders?customerId=${customerId}&siteId=${id}`}>
              View Orders
            </AdditionalDataButton>
          </>
        )}
      </Footer>
    </DrawerContent>
  )
}

const GET_SITE_WITH_TAGS = gql`
 query GetSiteWithTags($id: Int!) {
    sites(
      where: {
        id: {
          equals: $id
        }
      }
    ) {
      id
      tags {
        ...TagFields
      }
    }
  }

  ${TAG_SLIM_FRAGMENT}
`
