import styled from 'styled-components'

export const Wrapped = styled.span<{ in: 'parens' }>`
  &:empty {
    display: none
  }

  ${(props) => props.in === 'parens' && `

    &:before {
      content: "("
    }
    &:after {
      content: ")"
    }
  `}
`
