import * as Types from 'schema';

import { gql } from '@apollo/client';
export type OrderShowOverviewFragmentFragment = { __typename?: 'Order', id: number, dateOfService?: Types.Scalars['DateTime'] | null, flatRate: boolean, cashOnDelivery: boolean, assignments?: Array<{ __typename?: 'Assignment', id: number, operator: { __typename?: 'Operator', id: number, firstName?: string | null, lastName?: string | null }, roleDetails: { __typename?: 'AssignmentRole', slug: string, name: string } }> | null, billable?: { __typename?: 'OrderDetails', equipment?: { __typename?: 'Equipment', id: number, name: string, displayName?: string | null } | null } | null, contacts: Array<{ __typename?: 'OrderContact', id: number, default: boolean, contact: { __typename?: 'Contact', id: number, firstName?: string | null, lastName?: string | null }, role?: { __typename?: 'ContactRole', slug: string, name: string } | null }>, customer?: { __typename?: 'Customer', id: number, name: string, emails: Array<string>, billingAddress?: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null } | null, phones: Array<{ __typename?: 'Phone', id: number, number: string, typeDetails: { __typename?: 'PhoneType', slug: string, name: string } }> } | null, planned?: { __typename?: 'OrderDetails', id: number, equipment?: { __typename?: 'Equipment', id: number, name: string, displayName?: string | null } | null, schedule: Array<{ __typename?: 'OrderScheduleEntry', id: number, pour?: { __typename?: 'OrderPour', id: number, name?: string | null } | null }> } | null, site?: { __typename?: 'Site', id: number, name?: string | null, address: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null, timezone?: string | null } } | null, route?: { __typename?: 'Route', id: number, status: Types.RouteStatus } | null };

export const OrderShowOverviewFragmentFragmentDoc = gql`
    fragment OrderShowOverviewFragment on Order {
  id
  dateOfService
  flatRate
  cashOnDelivery
  assignments {
    id
    operator {
      id
      firstName
      lastName
    }
    roleDetails {
      slug
      name
    }
  }
  billable {
    equipment {
      id
      name
      displayName
    }
  }
  contacts {
    id
    default
    contact {
      id
      firstName
      lastName
    }
    role {
      slug
      name
    }
  }
  customer {
    id
    name
    emails
    billingAddress {
      id
      street
      street2
      city
      state
      zip
    }
    phones {
      id
      number
      typeDetails {
        slug
        name
      }
    }
  }
  planned {
    id
    equipment {
      id
      name
      displayName
    }
    schedule {
      id
      pour {
        id
        name
      }
    }
  }
  site {
    id
    name
    address {
      id
      street
      street2
      city
      state
      zip
      timezone
    }
  }
  route {
    id
    status
  }
}
    `;