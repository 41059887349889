import colors from 'constants/colors'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  match as MatchInterface, matchPath, useLocation,
} from 'react-router-dom'

import { FixedWrapper } from 'components/common/FixedWrapper'
import { ClosableElement, CloseButton } from 'hooks/useClosable'
import { useIsMobile } from 'hooks/useViewportMode'
import { DRAWER_BOX_SHADOW } from './constants'

const DrawerPositioner = styled.div<{ isMobile?: boolean, drawerVisible?: boolean }>`
  overflow: hidden;
  height: 100%;
  flex: 0 0 0px;
  transition: flex-basis 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  // transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  box-shadow: ${DRAWER_BOX_SHADOW};
  z-index: 1;

  opacity: ${(props) => (props.drawerVisible ? '1' : '0')};

  ${(props) => props.isMobile && `
    position: absolute;
    z-index: 20; // to get above layered content scrollbars
    width: 100% !important;
    height: 100% !important;
    background: ${colors.backgroundPrimary};
  `}

  ${(props) => props.isMobile && props.drawerVisible === false && 'display: none;'})
`

interface RoutableDrawerProps {
  width?: number | string
  showClose?: boolean
  className?: string
  style?: React.CSSProperties
  onClose?: () => void
}

type ElementType = React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal | null

const FixedCloseButton = styled(({ className }: { className?: string }) => (
  <FixedWrapper.Header className={className}>
    <CloseButton />
  </FixedWrapper.Header>
))`
  top: 0;
  right: 0;
  width: auto;
  background: ${colors.backgroundPrimary};
  padding: 0 12px 0 0;

  > .ant-btn {
    z-index: 10;
    cursor: pointer;
    padding: 8px 0 8px 8px;
    font-size: 12px;
  }
`

export const RoutableDrawer: React.FC<RoutableDrawerProps> = (props) => {
  const { className, style, children } = props
  const location = useLocation()
  const isMobile = useIsMobile()

  const [renderContext, setRenderContext] = useState<{
    width: number | string,
    clonedElement: React.ReactElement | null,
  }>({ width: '0px', clonedElement: null })

  let element: ElementType = null
  let match: MatchInterface | null = null
  let width: number | string = props.width || '350px'

  if (isMobile) {
    width = '100%'
  }

  // https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/Switch.js
  React.Children.forEach(children, (child) => {
    if (match === null && React.isValidElement(child)) {
      if (isMobile && child.props.hideOnMobile) {
        return
      }

      const path = child.props.path || child.props.from
      match = path ? matchPath(location.pathname, { ...child.props, path }) : null

      if (match) {
        element = child
        width = isMobile ? '100%' : (child.props.width || width)
      }
    }
  })

  useEffect(() => {
    if (element) {
      setRenderContext({
        width,
        clonedElement: React.cloneElement(element, { location, computedMatch: match }),
      })
    }
  }, [element])

  const drawerVisible = element !== null

  if (!drawerVisible) {
    width = '0px'
  }

  // eslint-disable-next-line no-prototype-builtins
  const showClose = renderContext?.clonedElement?.props?.hasOwnProperty('showClose') ?
    renderContext.clonedElement.props.showClose :
    // eslint-disable-next-line no-prototype-builtins
    props.hasOwnProperty('showClose') ? props.showClose : true

  return (
    <ClosableElement close={props.onClose}>
      <DrawerPositioner isMobile={isMobile} drawerVisible={drawerVisible} className={className} style={{ flexBasis: width, width }}>
        <FixedWrapper style={{ width: renderContext.width }}>
          {match && showClose && <FixedCloseButton />}
          <FixedWrapper.Body style={{ width: renderContext.width, ...style }}>
            {renderContext.clonedElement}
          </FixedWrapper.Body>
        </FixedWrapper>
      </DrawerPositioner>
    </ClosableElement>
  )
}
