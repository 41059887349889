import {
  Button, Col, Row, Typography,
} from 'antd'
import { SupportLink } from 'components/common/SupportLink'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import { useNotification } from 'hooks/useNotification'
import { useSamsaraSettings } from 'hooks/useSamsaraSettings'
import { useEffect } from 'react'
import {
  Link, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom'
import qs from 'utils/qs'

const { Paragraph } = Typography

export const SamsaraSettings = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const notification = useNotification()
  const { data: settings, loading, error } = useSamsaraSettings()
  const enabled = settings?.enabled

  const queryString = qs.parse(location.search)

  useEffect(() => {
    if (!queryString.message) return
    const { message, ...newQuery } = queryString
    notification.success({ message })
    history.push({ search: qs.stringify(newQuery) })
  }, [queryString.message])

  useEffect(() => {
    if (!queryString.error) return
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { error, ...newQuery } = queryString
    notification.error({ message: error })
    history.push({ search: qs.stringify(newQuery) })
  }, [queryString.error])

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }
  if (loading) {
    return <Loading />
  }

  return (
    <Row style={{ padding: '40px' }}>
      <Col span={24}>
        <Typography.Title level={3}>Samsara Integration</Typography.Title>

        <Paragraph>
          Here you can configure automated syncing between CreteSuite and Samsara.
        </Paragraph>
      </Col>

      {!enabled && (
        <Col span={24}>
          <Paragraph>
            Your CreteSuite dashboard is not currently connected to Samsara. Please <SupportLink /> to configure this integration.
          </Paragraph>
        </Col>
      )}

      {enabled && (
        <Col span={24}>
          <Typography.Title level={5}>Manage Operator Mapping</Typography.Title>
          <Paragraph>
            You must match CreteSuite Operators to Samsara Drivers in order for Samsara Routes to be created.
          </Paragraph>

          <Link to={`${match.url}/operators`}>
            <Button type="primary"> Manage Operators </Button>
          </Link>

          <Typography.Title level={5} style={{ marginTop: '30px' }}>Manage Equipment Mapping</Typography.Title>
          <Paragraph>
            You must match CreteSuite Equipment to Samsara Vehicles in order to see Vehicle stats in CreteSuite.
          </Paragraph>

          <Link to={`${match.url}/equipment`}>
            <Button type="primary"> Manage Equipment</Button>
          </Link>
        </Col>
      )}
    </Row>
  )
}
