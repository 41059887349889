import Icon from '@ant-design/icons'
import { ReactComponent as QuestionCircleOutlinedSvg } from '@ant-design/icons-svg/inline-svg/outlined/question-circle.svg'
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon'
import { Badge } from 'antd'
import { useSupportWidget } from 'hooks/useSupportWidget'
import React from 'react'
import styled from 'styled-components'

const IconWithBadge = styled((props: { className?: string }) => {
  const { unreadCount } = useSupportWidget()

  return (
    <Badge
      className={props.className}
      showZero={false}
      count={unreadCount || 0}
      size="small"
      dot
    >
      <QuestionCircleOutlinedSvg {...props} />
    </Badge>
  )
})`
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
  height: 100%;
`

export const SupportIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={IconWithBadge} />
))
