import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEquipmentDocumentsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetEquipmentDocumentsQuery = { __typename?: 'Query', equipment: Array<{ __typename?: 'Equipment', id: number, name: string, documents: Array<{ __typename?: 'Document', id: number, attachments?: Types.Scalars['Json'] | null, expirationDate?: any | null, pinned: boolean, fields: Array<{ __typename?: 'KeyValue', key?: string | null, value?: Types.Scalars['Json'] | null }>, typeDetails: { __typename?: 'DocumentType', name: string, slug: string } }> }> };


export const GetEquipmentDocumentsDocument = gql`
    query GetEquipmentDocuments($id: Int!) {
  equipment(where: {id: {equals: $id}}) {
    id
    name
    documents(where: {active: {equals: true}}) {
      id
      attachments
      expirationDate
      pinned
      fields {
        key
        value
      }
      typeDetails {
        name
        slug
      }
    }
  }
}
    `;

/**
 * __useGetEquipmentDocumentsQuery__
 *
 * To run a query within a React component, call `useGetEquipmentDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEquipmentDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetEquipmentDocumentsQuery, GetEquipmentDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEquipmentDocumentsQuery, GetEquipmentDocumentsQueryVariables>(GetEquipmentDocumentsDocument, options);
      }
export function useGetEquipmentDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEquipmentDocumentsQuery, GetEquipmentDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEquipmentDocumentsQuery, GetEquipmentDocumentsQueryVariables>(GetEquipmentDocumentsDocument, options);
        }
export type GetEquipmentDocumentsQueryHookResult = ReturnType<typeof useGetEquipmentDocumentsQuery>;
export type GetEquipmentDocumentsLazyQueryHookResult = ReturnType<typeof useGetEquipmentDocumentsLazyQuery>;
export type GetEquipmentDocumentsQueryResult = Apollo.QueryResult<GetEquipmentDocumentsQuery, GetEquipmentDocumentsQueryVariables>;
export function refetchGetEquipmentDocumentsQuery(variables: GetEquipmentDocumentsQueryVariables) {
      return { query: GetEquipmentDocumentsDocument, variables: variables }
    }