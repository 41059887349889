import React, { useEffect, useMemo, useState } from 'react'

import { Divider } from 'antd'
import { AddItemButton } from 'components/buttons'
import { SecondaryDrawer } from 'components/layout/SecondaryDrawer'
import { compact } from 'lodash'
import { ReferenceSelectProps } from './ReferenceSelect'

export type GenericFormAction = 'create' | 'update'
export type GenericFormSubmit = (resource: any, action: GenericFormAction, opts?: { shouldClose?: boolean }) => void
export type GenericOnSubmitCallback = (resource: any, action: GenericFormAction) => void

type ResourceFormProps = {
  editing?: boolean
  onSubmission?: GenericOnSubmitCallback
  onCancel?: () => void
  [propName: string]: any
}

export type ResourceForm = (props: ResourceFormProps) => React.ReactElement<any, any> | null

type ResourceSelectProps = Pick<
  ReferenceSelectProps<any>, 'resources' | 'onChange' | 'value' | 'dropdownRender'
> & { [propName: string]: any }

type ResourceSelect = (props: ResourceSelectProps) => React.ReactElement<any, any> | null

interface SelectWithAddFormConfig {
  name: string
  form: ResourceForm
  formProps?: ResourceFormProps
  select: ResourceSelect
  selectProps: ResourceSelectProps
}

export const SelectWithAddForm = (config: SelectWithAddFormConfig): JSX.Element => {
  const {
    name,
    form: Form,
    formProps,
    select: Select,
    selectProps,
  } = config

  const {
    resources: inputResources,
    ...args
  } = selectProps

  const [formVisible, setFormVisible] = useState<boolean>(false)
  const [resourcesViaForm, setResourcesViaForm] = useState<any[]>([]) // add new resources to the select when it was created using the form

  const resources = useMemo(() => (
    compact([...(inputResources || []), ...resourcesViaForm])
  ), [inputResources, resourcesViaForm])

  useEffect(() => {
    const last = resourcesViaForm[resourcesViaForm.length - 1] as any
    if (!last?.id) return

    args.value = last.id

    // Trigger onChange for controlled components (Form.Item)
    if (args.onChange) {
      args.onChange(last.id, { value: last.id, label: null })
    }
  }, [resourcesViaForm])

  const formSubmit: GenericFormSubmit = (resource, action, opts) => {
    if (formProps && typeof formProps.onSubmission === 'function') {
      formProps.onSubmission(resource, action)
    }
    setResourcesViaForm((previous) => [...previous, resource])
    const shouldKeepFormVisible = opts !== undefined && typeof opts.shouldClose === 'boolean' ? !opts.shouldClose : false
    setFormVisible(shouldKeepFormVisible)
  }

  const dropdownRender: ResourceSelectProps['dropdownRender'] = (menu) => (
    <div>
      {menu}
      <Divider style={{ margin: '4px 0' }} />
      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
        <AddItemButton onClick={() => { setFormVisible(true) }}>
          Add a {name}
        </AddItemButton>
      </div>
    </div>
  )

  return (
    <>
      <Select
        {...args}
        resources={resources}
        dropdownRender={dropdownRender}
      />

      <SecondaryDrawer
        destroyOnClose
        closable={false}
        visible={formVisible}
        onClose={() => setFormVisible(false)}
        noStyle
      >
        <Form
          editing
          {...formProps}
          onSubmission={formSubmit}
          onCancel={() => setFormVisible(false)}
        />
      </SecondaryDrawer>
    </>
  )
}

export { }
