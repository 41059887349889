import { compact } from 'lodash'
import React from 'react'

import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse as AntCollapse } from 'antd'
import { CollapseProps as AntCollapseProps } from 'antd/lib/collapse'

export * from './Panel'

export interface CollapseProps extends AntCollapseProps {
  mode?: 'default' | 'list'
}

export const Collapse: React.FC<CollapseProps> = ({ mode, ...props }) => {
  if (mode === 'list') {
    props.ghost = props.ghost !== undefined ? props.ghost : true
    props.expandIcon = props.expandIcon || (({ isActive }) => (
      <CaretRightOutlined rotate={isActive ? 90 : 0} />
    ))

    props.children = React.Children.toArray(props.children).map((child, index, _arr) => {
      if (!React.isValidElement(child)) return child

      return React.cloneElement<any>(child, {
        key: (child.key || String(index)).toString().replace('.$', ''),
        type: child.props.type || 'list',
        className: compact([child.props.className, props.ghost && 'ghost']).join(' '),
        noGutter: props.ghost,
      })
    })
  }

  return <AntCollapse {...props} />
}
