import { useCreation } from 'ahooks'
import { Row as AntRow, RowProps } from 'antd'
import { groupBy, isEmpty } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { useOrderBillingState } from '../OrderBillingState'
import { TableCol } from './TableCol'

export type TableRowProps = RowProps

const Row = styled(AntRow)`
  margin-bottom: 4px;
`

export const TableRow = (props: TableRowProps) => {
  const { details } = useOrderBillingState()

  const sections = useCreation(() => {
    if (!details) return []

    const actuals = Object.keys(details || {}).filter((detail) => detail.startsWith('actual.')).sort()

    return [
      'title',
      'planned',
      ...actuals,
      'billable',
    ]
  }, [details])

  const children = useCreation(() => {
    const grouped = groupBy(
      React.Children.toArray(props.children || []),
      (child) => React.isValidElement(child) && child.props?.section
    )

    return sections.map((section) => (
      <React.Fragment key={section}>
        {
          isEmpty(grouped[section]) ?
            <TableCol section={section} />
            :
            grouped[section]
        }
      </React.Fragment>
    ))
  }, [sections, props.children])

  return (
    <Row wrap={false} {...props}>
      {children}
    </Row>
  )
}
