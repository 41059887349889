import { BranchSelect, BranchSelectProps } from 'components/form/reference-selects/BranchSelect'
import { useSelectedBranch } from 'hooks/useBranch'
import styled from 'styled-components'

export type CurrentBranchSelectorProps = Omit<BranchSelectProps, 'value'>

export const CurrentBranchSelector = (props: CurrentBranchSelectorProps) => {
  const { branchId, setBranchId } = useSelectedBranch()

  const onChange: CurrentBranchSelectorProps['onChange'] = (val, ...rest) => {
    setBranchId(val as number)

    if (props.onChange) {
      props.onChange(val, ...rest)
    }
  }

  return <BranchSelect dropdownMatchSelectWidth={false} {...props} value={branchId || undefined} onChange={onChange} />
}

export const CurrentBranchSelectorInline = styled(CurrentBranchSelector).attrs({
  bordered: false,
  dropdownMatchSelectWidth: false,
  nonEditableIfOne: true as boolean,
})`
  &.ant-select:not(.read-only) {
    .ant-select-selection-item {
      padding-bottom: 1.4em;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    }
  }

  &.ant-select {
    font-size: inherit;
    font-weight: bolder;

    .ant-select-selector {
      padding-left: 0.1em;
    }
  }
`
