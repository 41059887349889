import colors from 'constants/colors'
import styled from 'styled-components'

import { Drawer, DrawerProps } from './Drawer'

export type PermanentDrawerProps = Omit<DrawerProps, 'closable' | 'visible' | 'mask'>

const StyledDrawer = styled(Drawer)`
  & > * {
    transition: none !important;
  }
`

export const PermanentDrawer: React.FC<PermanentDrawerProps> = (props: PermanentDrawerProps) => <StyledDrawer width="475px" {...props} closable={false} visible mask={false} />

export const PermanentDrawerV2 = styled(PermanentDrawer)`
  &.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none;
    border-left: 3px solid ${colors.greyscale5};
  }
`

export default PermanentDrawer
