import * as Types from 'schema';

import { gql } from '@apollo/client';
export type OrderShowHeaderFragmentFragment = { __typename?: 'Order', id: number, name: string, status: string, dateOfServiceLocal?: Types.Scalars['DateTime'] | null, branch?: { __typename?: 'Branch', id: number } | null, additionalIds?: Array<{ __typename?: 'KeyValue', key?: string | null, value?: Types.Scalars['Json'] | null }> | null, cancellationReasonDetails?: { __typename?: 'OrderCancellationReason', slug: string, name: string } | null, integrations?: { __typename?: 'OrderIntegrations', quickbooks?: { __typename?: 'OrderQuickbooksIntegration', invoice?: { __typename?: 'QuickbooksInvoice', id: string } | null } | null } | null, statusDetails: { __typename?: 'OrderStatus', slug: string, name: string, color?: string | null }, tags: Array<{ __typename?: 'Tag', id: number, name: string, color?: string | null }> };

export const OrderShowHeaderFragmentFragmentDoc = gql`
    fragment OrderShowHeaderFragment on Order {
  id
  name
  status
  dateOfServiceLocal
  branch {
    id
  }
  additionalIds {
    key
    value
  }
  cancellationReasonDetails {
    slug
    name
  }
  integrations {
    quickbooks {
      invoice {
        id
      }
    }
  }
  statusDetails {
    slug
    name
    color
  }
  tags {
    id
    name
    color
  }
}
    `;