import { byFieldAsc } from 'helpers/sortting'
import { useMemo } from 'react'
import styled from 'styled-components'

import { useNotification } from 'hooks/useNotification'

import { gql, useMutation, useQuery } from '@apollo/client'
import { Mutation, Query, QuickbooksMutationNamespaceLinkItemArgs } from 'schema'

import { Skeleton } from 'antd'
import { LinkableLists, LinkableListsProps } from 'components/common/LinkableLists'
import ErrorMessage from 'components/ErrorMessage'
import { compact, groupBy, mapValues } from 'lodash'

const ROW_GUTTER: [number, number] = [40, 20]
// eslint-disable-next-line unused-imports/no-unused-vars
const COLUMN_SPANS = { span: 10 }

// eslint-disable-next-line unused-imports/no-unused-vars
const LineItemSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    .ant-skeleton-title {
      width: 80% !important;
      height: 12px;
      margin-top: 0;
    }

    .ant-skeleton-paragraph {
      display: none;
    }
  }
`

// eslint-disable-next-line unused-imports/no-unused-vars
const Wrapper = styled.div`
  padding: ${ROW_GUTTER[0]}px;
  width: 900px;

  &.loading {
    opacity: 0.7;

    &, *, input, button {
      pointer-events: none;
    }
  }
`

export const QuickbooksLineItemsMatch = () => {
  const notification = useNotification()

  const quickbooksQuery = useQuery<Query>(GET_QUICKBOOKS_ITEMS, {
    nextFetchPolicy: 'cache-only',
  })

  const [
    updateLineItem,
    updateLineItemResponse,
  ] = useMutation<Mutation, QuickbooksMutationNamespaceLinkItemArgs>(LINK_ITEM)

  const loading = quickbooksQuery.loading || updateLineItemResponse.loading
  const { error } = quickbooksQuery

  const lineItemsRaw = quickbooksQuery?.data?.integrations?.quickbooks?.items?.linkable || []
  const lineItems = useMemo(() => (
    lineItemsRaw.slice().sort(byFieldAsc('name'))
  ), [lineItemsRaw])

  const lineItemSlugsToQuickbooksIds = useMemo(() => (
    mapValues(
      groupBy(lineItems, 'slug'),
      (val) => compact(val.map((item) => item.itemId))
    )
  ), [lineItems])

  const quickbooksLineItemsRaw = quickbooksQuery?.data?.integrations?.quickbooks?.items?.all || []

  const quickbooksLineItems = useMemo(() => (
    quickbooksLineItemsRaw.slice().sort(byFieldAsc('name'))
  ), [quickbooksLineItemsRaw])

  const updateMatch = async (lineItem: typeof lineItems[0], qbLineItem: typeof quickbooksLineItems[0] | null) => {
    const action = qbLineItem?.id ? 'update' : 'delete'

    const resp = await updateLineItem({
      variables: {
        itemId: qbLineItem?.id,
        slug: lineItem.slug,
      },
    })

    if (resp.errors) {
      notification.error({
        message: 'Error Saving Match',
        description: resp.errors.map((err) => err.message).join('\n'),
        duration: 5,
      })
      return false
    }
    notification.success({
      message: action === 'update' ? 'Saved Match' : 'Deleted Match',
      description: `${action === 'update' ? 'Saved match' : 'Deleted match'} for ${lineItem.name}`,
      duration: 3,
    })

    return true
  }

  const props: LinkableListsProps<typeof lineItems[0], typeof quickbooksLineItems[0]> = {
    mode: 'one-to-one',
    loading,
    linked: (lineItem, qbLineItem) => lineItemSlugsToQuickbooksIds[lineItem.slug]?.includes(qbLineItem.id) || false,
    onLink: async (lineItem, qbLineItem) => updateMatch(lineItem, qbLineItem),
    onDelete: async (lineItem, qbLineItem) => updateMatch(lineItem, null),
    list1: {
      title: 'CreteSuite Metrics',
      resources: lineItems,
      generateOption: (lineItem) => lineItem.name,
      renderCard: (lineItem) => lineItem.name,
    },
    list2: {
      title: 'Quickbooks Line Items',
      resources: quickbooksLineItems,
      generateOption: (qbLineItem) => qbLineItem.name,
      renderCard: (qbLineItem) => qbLineItem.name,
    },
  }
  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <LinkableLists {...props} />
    </>
  )
}

export default QuickbooksLineItemsMatch

const LINK_ITEM = gql`
  mutation QuickbooksLinkItemMutation (
    $slug: String!
    $itemId: String
  ) {
    integrations {
      quickbooks {
        linkItem(slug: $slug, itemId: $itemId) {
          slug
          itemId
        }
      }
    }
  }
`

const GET_QUICKBOOKS_ITEMS = gql`
  query GetQuickbooksItems {
    integrations {
      quickbooks {
        items {
          linkable {
            slug
            name
            itemId
          }
          all {
            id
            name
          }
        }
      }
    }
  }
`
