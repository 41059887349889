import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import colors from '../../constants/colors'
import { SingleCollapse } from '../common/Collapse/SingleCollapse'
import { ReceivePayment, ReceivePaymentProps } from './ReceivePayment'

const useUniqueToggle = (initialValue?: boolean): [boolean, string, React.Dispatch<React.SetStateAction<boolean>>] => {
  const id = useRef<string>(Date.now().toString())
  const [visible, setVisibleBoolean] = useState<boolean>(initialValue || false)

  const setVisible: typeof setVisibleBoolean = (value) => {
    setVisibleBoolean((prev) => {
      const resolved = typeof value === 'function' ? value(prev) : value
      if (resolved) {
        id.current = Date.now().toString()
      }
      return resolved
    })
  }
  return [visible, id.current, setVisible]
}

export const TriggerButton = styled.div<{ contentVisible?: boolean, disabled?: boolean }>`
  display: inline-block;
  background-color: #fafafa;
  padding: 7px 15px;
  color: ${colors.ant.primary};
  border: 1px solid #f0f0f0;
  border-bottom-color: #f0f0f0;
  transition: border 20ms ease-in 200ms;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  z-index: 1;

  ${(props) => props?.disabled && `
    color: ${colors.greyscale40};
    cursor: not-allowed;
  `}

  ${(props) => props?.contentVisible && `
    border-bottom-color: #fafafa;
    transition: none;
  `}
`

const ContentCollapse = styled(SingleCollapse).attrs({
  noStyle: true,
  ghost: true,
})`
  clear: both;
  background-color: #fafafa;
  position: relative;
  top: -2px;
  width: 100%;
`

type ReceivePaymentToggleProps = ReceivePaymentProps & {
  buttonPosition?: 'left' | 'right'
}

export const ReceivePaymentToggle = (props: ReceivePaymentToggleProps) => {
  const { buttonPosition, onSubmission, ...receivePaymentArgs } = props
  const [showReceiveSection, receiveSectionKey, setShowReceiveSection] = useUniqueToggle()
  const disabled = isEmpty(props.invoiceIds || [])

  useEffect(() => {
    if (disabled) {
      setShowReceiveSection(false)
    }
  }, [disabled])

  return (
    <div>
      <TriggerButton
        contentVisible={showReceiveSection}
        disabled={disabled}
        onClick={() => !disabled && setShowReceiveSection(!showReceiveSection)}
        style={{ float: buttonPosition }}
      >
        Receive Payment {showReceiveSection ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </TriggerButton>
      <ContentCollapse
        visible={showReceiveSection}
      >
        <div style={{ padding: '15px 15px 0px 15px', border: '1px solid #f0f0f0' }}>
          <ReceivePayment
            key={receiveSectionKey}
            onSubmission={(...args) => {
              setShowReceiveSection(false)
              onSubmission?.(...args)
            }}
            {...receivePaymentArgs}
          />
        </div>
      </ContentCollapse>
    </div>
  )
}
