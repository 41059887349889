import { Col, Row } from 'antd'
import { Panel } from 'components/common/Collapse/Panel'
import { formLayout } from 'components/form/common/Layout'
import { Form } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'

import { DurationPicker } from 'components/form/inline-edit/DurationPicker'
import { ScheduleStepSelectRightAlign } from 'components/form/reference-selects/ScheduleStepSelect'

import { useFormState } from 'components/form/OrderForm/OrderFormState'
import { OrderFormPanelProps } from './PanelProps'

export const SchedulePanel = (props: OrderFormPanelProps): JSX.Element => {
  const {
    editing,
  } = useFormState()

  return (
    <Panel {...props} header="Schedule">
      <Form.List name={['details', 'schedule']}>
        {(fields, _, { errors }) => (
          fields.map((field, index) => (
            <Row key={field.key}>
              <Col {...formLayout.labelCol}>
                <FormItem
                  noStyle
                  listPrefix={field}
                  name="step"
                >
                  <ScheduleStepSelectRightAlign
                    editing={false}
                  />
                </FormItem>
              </Col>
              <Col {...formLayout.wrapperCol}>
                <FormItem
                  listPrefix={field}
                  name={['duration']}
                >
                  <DurationPicker
                    allowClear="zero-out"
                    editing={editing}
                    placeholder="Duration for this task"
                    unit="seconds"
                    minuteStep={15}
                  />
                </FormItem>
              </Col>
            </Row>
          ))
        )}
      </Form.List>
    </Panel>
  )
}
