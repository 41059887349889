import React, { useCallback, useContext, useState } from 'react'
import { Equipment } from 'schema'

import { byFieldPathDirectional } from 'helpers/sortting'
import { compact, intersection } from 'lodash'
import { RecursivePartial } from 'types'

type EquipmentLike = RecursivePartial<Equipment>

export type EquipmentSortOption = 'type' | 'size' | '-size' | 'name' | '-name'

const EQUIPMENT_SORT_KEYS: EquipmentSortOption[] = ['type', 'size', '-size', 'name', '-name']

type SortEquipmentFunction = <T extends EquipmentLike[]>(equipment: T) => T

export interface EquipmentSortState {
  sortEquipment: SortEquipmentFunction
  equipmentSortter: (a: EquipmentLike, b: EquipmentLike) => number
  equipmentSort: EquipmentSortOption[]
  setEquipmentSort: React.Dispatch<React.SetStateAction<EquipmentSortState['equipmentSort']>>
}

export const EquipmentSortContext = React.createContext<EquipmentSortState>({
  equipmentSortter: () => 0,
  sortEquipment: (args) => args,
  equipmentSort: [],
  setEquipmentSort: () => { },
})

export const EquipmentSortProvider: React.FC = ({ children }) => {
  const [equipmentSort, setEquipmentSortState] = useState<EquipmentSortState['equipmentSort']>(() => {
    const stored = localStorage.getItem('equipmentSort')?.split(',')
    const valid = intersection(EQUIPMENT_SORT_KEYS, stored)
    if (valid.length > 0) {
      return valid as EquipmentSortOption[]
    }
    return ['type', '-size', 'name']
  })

  const setEquipmentSort: typeof setEquipmentSortState = (value) => {
    setEquipmentSortState((previous) => {
      const resolved = typeof value === 'function' ? value(previous) : value
      localStorage.setItem('schedulerEquipmentSort', resolved.join(','))
      return resolved
    })
  }

  const equipmentSortter: EquipmentSortState['equipmentSortter'] = useCallback((a, b) => {
    const sortResult = equipmentSort.map((pathWithDirection) => {
      const direction = pathWithDirection.startsWith('-') ? -1 : 1
      const path = pathWithDirection.startsWith('-') ? pathWithDirection.slice(1) : pathWithDirection

      if (path === 'type') {
        if (a.type === b.type) return 0
        if (a.type === 'boom_pump') return -1
        if (b.type === 'boom_pump') return 1
        if (a.type === 'trailer_pump') return -1
        if (b.type === 'trailer_pump') return 1
      }

      return byFieldPathDirectional(path.split('.'), direction)(a, b)
    })

    return compact(sortResult)[0] || 0
  }, [equipmentSort])

  const sortEquipment = useCallback<SortEquipmentFunction>((equipment) => equipment.sort(equipmentSortter), [equipmentSortter])

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
    <EquipmentSortContext.Provider value={{
      equipmentSort, setEquipmentSort, equipmentSortter, sortEquipment,
    }}
    >
      {children}
    </EquipmentSortContext.Provider>
  )
}

export const useEquipmentSort = () => useContext(EquipmentSortContext)
