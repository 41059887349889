import colors from 'constants/colors'
import {
  compact,
  isFunction, isNumber,
  get as lodashGet,
  set as lodashSet, startCase,
} from 'lodash'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { Form, useCurrentForm } from 'components/form/Form'

import { Address } from 'schema'

import { WarningOutlined } from '@ant-design/icons'
import { useCreation } from 'ahooks'
import {
  Col, Row,
} from 'antd'
import { Rule } from 'antd/lib/form'
import { AddressAutocomplete } from 'components/common/AddressAutocomplete'
import { Collapse, Panel } from 'components/common/Collapse'
import { Input } from 'components/form/inline-edit/Input'
import { useBranchGeoFeatures } from 'components/mapping/useBranchGeoFeatures'
import { adjustCoordinateForUserErrors } from 'helpers/adjustCoordinateForUserErrors'
import { parseCoordinates } from 'helpers/parseCoordinates'
import { getNamePath } from 'rc-field-form/es/utils/valueUtil'
import { FormItemProps } from '../FormItem'
import { LatitudeInput } from '../inline-edit/LatitudeInput'
import { LongitudeInput } from '../inline-edit/LongitudeInput'
import { StateSelect } from './StateSelect'

type AddressFields = Pick<Address, 'name' | 'street' | 'street2' | 'city' | 'state' | 'zip' | 'lat' | 'lng' | 'timezone'>

type AddressRules = Partial<Record<keyof AddressFields, Rule[]>>

export interface AddressInputProps {
  name: FormItemProps['name']
  path?: FormItemProps['name']
  labels?: boolean
  initialValue?: AddressFields | null
  editing?: boolean
  hideStreet2?: boolean
  showCoordinates?: boolean
  rules?: AddressRules
}

const Callout = styled.div.attrs((props) => ({
  children: (!props.children ? null : (
    <Row wrap={false} gutter={12} align="middle">
      <Col>
        <WarningOutlined />
      </Col>
      <Col flex="auto">
        {props.children}
      </Col>
    </Row>
  )) as React.ReactNode,
}))`
  color: ${colors.error};
  font-weight: bold;
  margin-bottom: 5px;
  white-space: pre-wrap;
  font-size: 0.9em;

  .anticon {
    font-size: 1.5em;
  }
`

const reverseGeocode = async (lat: number, lng: number) => {
  const response = await fetch(`https://api.geocod.io/v1.7/reverse?q=${lat},${lng}&limit=1&fields=timezone&api_key=816a4e019608e10d994e66ea11d18ea2e60416d`)
  if (response.ok) {
    return response.json()
  }
  return null
}

export const AddressInput: React.FC<AddressInputProps> = (props: AddressInputProps) => {
  const {
    name: nameRaw,
    path: pathRaw,
    labels,
    initialValue,
    editing,
    hideStreet2,
    showCoordinates,
  } = props

  const form = useCurrentForm()
  const name = getNamePath(nameRaw || [])
  const path = pathRaw ? getNamePath(pathRaw) : name

  const streetKey = [...path, 'street']
  const [callout, setCallout] = useState<string>()

  const [autoShowCoordinates, setAutoShowCoordinates] = useState<boolean>(false)
  const showCoords = showCoordinates !== undefined ? showCoordinates : autoShowCoordinates

  const branchGeo = useBranchGeoFeatures()

  const rules = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const rules = { ...props.rules }
    for (const [field, fieldRules] of Object.entries(rules)) {
      for (const rule of (fieldRules || [])) {
        if (!isFunction(rule) && rule.required && !rule.message) {
          rule.message = `${startCase(field)} is required`
        }
      }
    }

    return rules
  }, [props.rules])

  const updateAddress = (address: Partial<Address>, opts: { overwrite?: boolean } = {}) => {
    const { overwrite } = opts
    const data = form.getFieldValue([])
    const currentAddress = overwrite ? {} : (lodashGet(data, path) || {})
    lodashSet(data, path, { ...currentAddress, ...address })
    form.setFieldsValue(data)
  }

  const onPaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = evt?.clipboardData?.getData('text')
    if (!pastedValue) return

    const coords = parseCoordinates(pastedValue)

    if (coords) {
      updateAddress({ lat: coords[0], lng: coords[1] })
      evt.preventDefault()
    }
  }

  const reverseGeocodingCache = useCreation<Record<string, ReturnType<typeof reverseGeocode> | undefined>>(() => ({}), [])
  const setAddressFromLatLng = async (lat: number, lng: number) => {
    const key = `${lat},${lng}`
    if (!reverseGeocodingCache[key]) {
      reverseGeocodingCache[key] = reverseGeocode(lat, lng)
    }

    const response = await reverseGeocodingCache[key]
    const geocodedAddress = response?.results?.[0]?.address_components
    const geocodedTimezone = response?.results?.[0]?.fields?.timezone?.name
    if (!geocodedAddress) return

    const current = form.getFieldValue([])
    const address = lodashGet(current, path) || {}

    address.timezone = geocodedTimezone

    const setIfEmptyOrUntouched = (field: keyof Address, value: string) => {
      if (address[field]) return
      if (form.isFieldTouched(field)) return
      address[field] = value
    }

    address.name ||= compact([geocodedAddress.formatted_street, geocodedAddress.city]).join(' in ')
    setIfEmptyOrUntouched('street', compact([geocodedAddress.number, geocodedAddress.formatted_street]).join(' '))
    setIfEmptyOrUntouched('city', geocodedAddress.city)
    setIfEmptyOrUntouched('state', geocodedAddress.state)
    setIfEmptyOrUntouched('zip', geocodedAddress.zip)

    updateAddress(address)
  }

  return (
    <>
      <Form.Item
        hidden
        name={[...name, 'name']}
        initialValue={initialValue?.name}
      >
        <Input placeholder="Name" editing={editing} />
      </Form.Item>

      <Row gutter={6}>
        <Col flex="auto">
          <Form.Item
            noStyle
            children={() => null}
            shouldUpdate={(prevValues, curValues) => {
              const address = lodashGet(curValues, path)
              if (address) {
                const hasCoords = isNumber(address.lat) && isNumber(address.lng)
                if (hasCoords) {
                  const previous = lodashGet(prevValues, path) || {}
                  const coordinatesChanged = previous.lat !== address.lat || previous.lng !== address.lng

                  if (coordinatesChanged && branchGeo?.center) {
                    const nearbyLatLng = adjustCoordinateForUserErrors([address.lng, address.lat], branchGeo.center)
                    // eslint-disable-next-line prefer-destructuring
                    address.lat = nearbyLatLng[1]
                    // eslint-disable-next-line prefer-destructuring
                    address.lng = nearbyLatLng[0]
                  }

                  const someEmpty = Boolean(!address.street || !address.city || !address.state || !address.zip || !address.timezone)
                  if (someEmpty) {
                    setAddressFromLatLng(address.lat, address.lng)
                  }
                } else {
                  const isFilled = Boolean(address.city && address.state && address.zip)
                  if (isFilled !== autoShowCoordinates) {
                    setAutoShowCoordinates(isFilled)
                  }
                }
              }
              return false
            }}
          />

          <Form.Item
            style={{ marginBottom: '10px' }}
            name={[...name, 'street']}
            initialValue={initialValue?.street}
            rules={rules?.street}
            getValueFromEvent={() => form.getFieldValue(streetKey)}
          >
            <AddressAutocomplete
              value={form.getFieldValue(streetKey)}
              editing={editing}
              placeholder="Address"
              onAddressSelect={(address, meta) => {
                const current = form.getFieldValue([])
                lodashSet(current, path, address)
                form.setFieldsValue(current)

                const hasCoords = isNumber(address?.lat) && isNumber(address?.lng)
                if (hasCoords) {
                  setAutoShowCoordinates(false)
                }

                setCallout(meta?.callout)
              }}
              onSearch={(value) => {
                const current = form.getFieldValue([])
                lodashSet(current, [...path, 'street'], value)
                form.setFieldsValue(current)
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={6} wrap={false} hidden={hideStreet2}>
        <Col flex="auto">
          <Form.Item
            label={labels && 'Suite / Unit #'}
            name={[...name, 'street2']}
            initialValue={initialValue?.street2}
            style={{ marginBottom: '10px' }}
            rules={rules?.street2}
          >
            <Input placeholder="Suite / Unit #" editing={editing} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={6} wrap={false} style={{ marginBottom: '10px' }}>
        <Col flex="auto">
          <Form.Item
            label={labels && 'City'}
            name={[...name, 'city']}
            initialValue={initialValue?.city}
            rules={rules?.city}
            style={{ marginBottom: 0 }}
          >
            <Input placeholder="City" editing={editing} />
          </Form.Item>
        </Col>
        <Col flex="73px">
          <Form.Item
            label={labels && 'State'}
            name={[...name, 'state']}
            initialValue={initialValue?.state}
            rules={rules?.state}
            style={{ marginBottom: 0 }}
          >
            <StateSelect placeholder="State" editing={editing} />
          </Form.Item>
        </Col>
        <Col flex="80px">
          <Form.Item
            label={labels && 'Zipcode'}
            name={[...name, 'zip']}
            initialValue={initialValue?.zip}
            rules={rules?.zip}
            style={{ marginBottom: 0 }}
          >
            <Input placeholder="Zip" editing={editing} />
          </Form.Item>
        </Col>
      </Row>

      <Collapse bordered={false} ghost activeKey={showCoords ? 'show' : undefined} style={{ margin: 0, padding: '0 0 0 14px' }}>
        <Panel key="show" forceRender hideHeader noGutter style={{ margin: 0 }}>
          <Form.Item
            label="Latitude"
            name={[...name, 'lat']}
            initialValue={initialValue?.lat}
            rules={rules?.lat}
            style={{ margin: '0 0 5px 0' }}
            labelAlign="left"
            trigger="onBlur"
            validateTrigger="onBlur"
            getValueFromEvent={(blurEvt) => {
              const value = parseFloat(blurEvt.target.value)
              // eslint-disable-next-line no-restricted-globals
              if (isNaN(value)) {
                return undefined
              }
              return Math.min(90, Math.max(-90, value))
            }}
            labelCol={{
              style: {
                width: '86px',
              },
            }}
          >
            <LatitudeInput
              editing={editing}
              onPaste={onPaste}
            />
          </Form.Item>
          <Form.Item
            label="Longitude"
            name={[...name, 'lng']}
            initialValue={initialValue?.lng}
            rules={rules?.lng}
            style={{ margin: 0 }}
            labelAlign="left"
            trigger="onBlur"
            validateTrigger="onBlur"
            getValueFromEvent={(blurEvt) => {
              const value = parseFloat(blurEvt.target.value)
              // eslint-disable-next-line no-restricted-globals
              if (isNaN(value)) {
                return undefined
              }
              return Math.min(180, Math.max(-180, value))
            }}
            labelCol={{
              style: {
                width: '86px',
              },
            }}
          >
            <LongitudeInput
              editing={editing}
              onPaste={onPaste}
            />
          </Form.Item>
        </Panel>
      </Collapse>

      <Form.Item
        name={[...name, 'timezone']}
        initialValue={initialValue?.timezone}
        rules={rules?.timezone}
        hidden
      >
        <Input placeholder="Timezone" editing={editing} />
      </Form.Item>

      {callout && <Callout>{callout}</Callout>}

      <div style={{ height: '10px' }} />
    </>
  )
}

export default AddressInput
