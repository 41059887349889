import { useParams } from 'react-router-dom'

import {
  Query,
  QueryNotesArgs,
} from 'schema'

import { useQuery } from '@apollo/client'
import { GET_NOTES } from 'gql/notes'

import { ErrorMessage } from 'components/ErrorMessage'
import { Loading } from 'components/Loading'

import { NoteForm } from 'components/form/NoteForm'
import { NotesIcon } from 'components/icons'
import { NoteActionsMenu } from 'components/menus/NoteActionsMenu'
import { CloseButton } from 'hooks/useClosable'
import {
  Actions, Body, ContentContext, Footer, Header, useContentLayoutState,
} from 'hooks/useContentLayout'
import { DrawerContent } from 'layouts/Content/DrawerContent'

export const ScheduleNoteShow = () => {
  const { noteId } = useParams<{ noteId?: string }>()
  const id = noteId ? parseInt(noteId) : undefined

  const FormContentLayout = useContentLayoutState()

  if (!id) {
    return <ErrorMessage>Must pass note id</ErrorMessage>
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const response = useQuery<Query, QueryNotesArgs>(GET_NOTES, {
    fetchPolicy: 'network-only',
    skip: !id,
    variables: {
      where: {
        id: {
          equals: id,
        },
      },
    },
  })

  if (!id) {
    return <ErrorMessage>Must pass note id</ErrorMessage>
  }

  if (response.loading) {
    return <Loading />
  }

  const note = response.data?.notes[0]

  if (!note) {
    return <ErrorMessage>Note #{id} not found</ErrorMessage>
  }

  return (
    <DrawerContent>
      <Header>
        <NotesIcon style={{ opacity: '0.8', fontSize: '0.8em' }} />
        {/* <FormContentLayout.Header.Target /> */}
      </Header>

      <Actions>
        <NoteActionsMenu note={note} style={{ padding: 8 }} />
        <CloseButton />
      </Actions>

      <Body>
        <ContentContext.Provider value={FormContentLayout}>
          <NoteForm key={note?.id} note={note} />
        </ContentContext.Provider>
      </Body>

      <Footer>
        <FormContentLayout.Footer.Target />
      </Footer>
    </DrawerContent>
  )
}
