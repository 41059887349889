import { colors } from 'constants/colors'
import {
  compact,
  debounce, isEmpty, isNil, trim,
} from 'lodash'
import styled from 'styled-components'

import calculateDistance from '@turf/distance'
import { point } from '@turf/helpers'

import { useCallback, useEffect, useState } from 'react'

import geocodingService from '@mapbox/mapbox-sdk/services/geocoding'
import { convertStateToAbbr } from 'helpers/states'

import { WarningOutlined } from '@ant-design/icons'
import { Select as AntSelect, Col, Row } from 'antd'
import { AutoComplete, AutoCompleteProps } from 'components/form/inline-edit/AutoComplete'
import { OptionType } from 'components/form/reference-selects/ReferenceSelect'
import { useBranchGeoFeatures } from 'components/mapping/useBranchGeoFeatures'
import { Address } from 'schema'
import { GeocoderFeature, GeocoderResult } from 'utils/mapbox/types'
import { Tooltip } from './Tooltip'

interface AddressAutocompleteProps extends Omit<AutoCompleteProps<string>, 'options'> {
  onAddressSelect?: (address: Partial<Address> | null, metadata?: { callout?: string }) => void
  near?: {
    lat: number
    lng: number
  }
}

const { Option } = AntSelect

const StyledDropdown = styled.div`
  .ant-select-item-option {
    border-bottom: 1px solid ${colors.borderPrimary};
  }
  .ant-select-item-option:last-child {
    border-bottom: none;
  }


  .ant-select-item-option-content {
    white-space: pre-wrap;
    font-size: 0.9rem;
  }
`

// TODO: pull api keys from graphql
const mapboxGeocodingClient = geocodingService({
  accessToken: 'pk.eyJ1Ijoic2djcGZsLWRldiIsImEiOiJja3hqN3FkazIza2hqMzJyeXRnNnN1bnlwIn0.G3H0zXf7tVjeD94DJzUYJg',
})

// TODO: pull api keys from graphql
const geocodioFetch = (text: string) => fetch(`https://api.geocod.io/v1.7/geocode?q=${encodeURIComponent(text)}&fields=timezone&api_key=816a4e019608e10d994e66ea11d18ea2e60416d`)

const zipRegex = /.*(?:\D|^)(\d+)/

const parseAddressFromMapbox = (feature: GeocoderFeature): Partial<Address> => {
  const addressParts = feature.place_name.split(',').map(trim)

  const [_country, stateAndZip, city, ...rest] = addressParts.reverse()

  const street = rest.join(' ')
  const zip = stateAndZip.match(zipRegex)?.[1] || ''
  const state = convertStateToAbbr(stateAndZip.replace(zip, '').trim())

  // const locale = (
  //   feature?.context?.find(({ id }) => id.startsWith('neighborhood.'))?.text ||
  //   feature?.context?.find(({ id }) => id.startsWith('locality.'))?.text ||
  //   city
  // )

  return {
    // name: compact([feature.text, locale]).join(' in '),
    name: compact([feature.text, city]).join(' in '),
    street,
    city,
    state,
    zip,
    lat: feature.geometry?.coordinates?.[1],
    lng: feature.geometry?.coordinates?.[0],
  }
}

const isApproximate = (feature: GeocoderFeature): boolean => (
  ['approximate', 'street'].includes(feature.properties.accuracy) ||
  (feature.geometry as any).omitted ||
  false
)

const calloutForFeature = (feature: GeocoderFeature) => {
  if (isApproximate(feature)) {
    return 'This is an approximate address.\nPlease confirm map pin is correct.'
  }

  return null
}

const nearestGeocodeioResult = async (feature: GeocoderFeature) => {
  const geocodeioResponse = await geocodioFetch(feature.place_name)
  if (!geocodeioResponse.ok) {
    return
  }

  const results = (await geocodeioResponse.json())?.results

  if (isEmpty(results)) {
    return
  }

  const [lng, lat] = feature.geometry?.coordinates || []
  if (isNil(lng) || isNil(lat)) {
    return results[0]
  }

  const sourcePoint = point([lng, lat])

  const resultsWithDistance = results.map((result: any) => {
    if (isNil(result.location.lng) || isNil(result.location.lat)) {
      return result
    }

    const thisPoint = point([result.location.lng, result.location.lat])
    const distance = calculateDistance(sourcePoint, thisPoint, { units: 'miles' })

    return {
      ...result,
      distance,
    }
  }).sort((a: any, b: any) => {
    if (isNil(b.distance)) return -1
    if (isNil(a.distance)) return 1
    return a.distance - b.distance
  })

  return resultsWithDistance[0]
}

type AddressAutocompleteOption = Omit<OptionType<string>, 'label'> & {
  label?: string
}

export const AddressAutocomplete = (props: AddressAutocompleteProps): JSX.Element => {
  const branchGeo = useBranchGeoFeatures()
  const { near, onAddressSelect, ...args } = props
  const [options, setOptions] = useState<AddressAutocompleteOption[]>([])

  const proximity: number[] | undefined = near ? [near.lng, near.lat] : branchGeo?.center

  const searchAddress = useCallback(debounce(async (searchText: string) => {
    const geocoder = mapboxGeocodingClient.forwardGeocode({
      query: searchText,
      mode: 'mapbox.places',
      countries: ['US'],
      proximity,
      types: ['address'],
      autocomplete: true,
      limit: 5,
    })

    const response: GeocoderResult = (await geocoder.send()).body

    setOptions((response.features || []).map((feature) => ({
      value: feature?.place_name?.replace(', United States', ''),
      feature,
      callout: calloutForFeature(feature),
    })))
  }, 200), [])

  useEffect(() => searchAddress.cancel, [searchAddress])

  const onSearch: typeof props['onSearch'] = (searchText) => {
    if (args.onSearch) {
      args.onSearch(searchText)
    }
    if (searchText.length > 3) {
      searchAddress(searchText)
    }
  }

  const onSelect: typeof props['onSelect'] = async (value, option) => {
    if (args.onSelect) {
      args.onSelect(value, option)
    }

    if (!onAddressSelect) return
    if (!option?.feature) return

    let callout: string | undefined = option?.callout

    const address = parseAddressFromMapbox(option.feature)
    onAddressSelect(address, { callout })

    let timezone: string | undefined

    const xrefAddress = await nearestGeocodeioResult(option.feature)
    const xrefMatched = !isNil(xrefAddress.distance) && xrefAddress.distance < 0.2

    if (xrefMatched) {
      timezone = xrefAddress?.fields?.timezone?.name
    } else {
      callout = callout || 'This address could not be fully verified.\nPlease confirm map pin is correct.'
    }

    onAddressSelect({
      timezone,
      ...address,
    }, {
      callout,
    })
  }

  const onKeyDown: typeof props['onKeyDown'] = (event) => {
    if (event.key === 'Enter') {
      const option = options[0]

      if (option?.value) {
        onSelect(option.value, {
          label: option.value,
          ...option,
        })
      }
    }
  }

  return (
    <AutoComplete
      {...args}
      onSelect={onSelect}
      onSearch={onSearch}
      onKeyDown={onKeyDown}
      listHeight={300}
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender={(menu) => (
        <StyledDropdown>
          {menu}
        </StyledDropdown>
      )}
    >
      {options.map((option) => (
        <Option key={option.value} {...(option as any)}>
          <Row wrap={false} gutter={2}>
            <Col flex="auto">
              {(option.label || option.value).replace(', ', '\n')}
            </Col>
            {option.callout && (
              <Col>
                <Tooltip title={option.callout} placement="topLeft">
                  <WarningOutlined style={{ fontSize: '0.8em' }} />
                </Tooltip>
              </Col>
            )}
          </Row>
        </Option>
      ))}
    </AutoComplete>
  )
}
