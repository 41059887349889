import { Route } from 'react-router-dom'
import { RouteShowRedirect } from './RouteShow'
import { RouteWaypointShowRedirect } from './RouteWaypointShow'
import { ToCatchAll, TO_CATCH_ALL_PATH } from './ToCatchAll'

export const Redirects = () => (
  <>
    <Route path="/route-waypoints/:id">
      <RouteWaypointShowRedirect />
    </Route>,
    <Route path="/routes/:id">
      <RouteShowRedirect />
    </Route>,
    <Route path={`${TO_CATCH_ALL_PATH}/*`}>
      <ToCatchAll />
    </Route>
  </>
)

export default Redirects
