import { byFieldPathAsc } from 'helpers/sortting'
import moment from 'moment'
import { Route, RouteWaypoint } from 'schema'
import { RecursivePartial } from 'types'
import { RouteFormValues } from './RouteFormValues'

export const routeToFormValues = (route: RecursivePartial<Route>): RouteFormValues => ({
  ...route,
  assignments: [...(route.assignments || [])].sort(byFieldPathAsc(['roleDetails', 'sort'])),
  waypoints: (route?.waypoints || []).map((waypoint) => (
    {
      ...waypoint,
      scheduledArrivalTime: waypoint?.scheduledArrivalTime ? moment(waypoint?.scheduledArrivalTime) : undefined,
      scheduledDepartureTime: waypoint?.scheduledDepartureTime ? moment(waypoint?.scheduledDepartureTime) : undefined,
      actualArrivalTime: waypoint?.actualArrivalTime ? moment(waypoint?.actualArrivalTime) : undefined,
      actualDepartureTime: waypoint?.actualDepartureTime ? moment(waypoint?.actualDepartureTime) : undefined,
    } as RouteWaypoint
  )),
})
