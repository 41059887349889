import React from 'react'
import { Branch } from 'schema'

import { gql } from '@apollo/client'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { byFieldAsc } from 'helpers/sortting'

export type BranchSelectProps = ReferenceSelectProps<Branch>

const GET_BRANCHES = gql`
  query GetBranches {
    branches {
      id
      name
      active
    }
  }
`

export const BranchSelect: React.FC<BranchSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Branch',
  queryField: 'branches',
  gql: GET_BRANCHES,
  fetchPolicy: 'cache-first',
  defaults: {
    optionsGenerator: (branches: Branch[]) => (
      branches
        .filter((branch) => branch.active || props.value === branch.id)
        .sort(byFieldAsc('name'))
        .map((branch) => ({
          label: branch.name,
          value: branch.id,
        }))
    ),
  },
}, props))

export default BranchSelect
