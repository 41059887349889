import {
  compact, isNil, omit, pick,
} from 'lodash'
import {
  OrderCreateInput, OrderDetailsUpsertInput, OrderInventoryItemInput, OrderUpdateInput,
} from 'schema'
import FormValues from '../FormValues'
import { fillScheduleTimes } from './fillTimesFromDurations'

function must<T>(value: T): NonNullable<T> {
  if (value === undefined || value === null) throw new Error('Value not set')
  return value as any
}

const formDetailsToOrderUpdate = (details: FormValues['details']): OrderDetailsUpsertInput => {
  const inventory: OrderDetailsUpsertInput['inventory'] = !details.inventory ? undefined : {
    set: Object.values(
      details.inventory.reduce((collect, item) => {
        const { itemId } = item
        if (!itemId) return collect

        if (isNil(item.quantity)) {
          collect[itemId] = { itemId, quantity: null }
        } else {
          collect[itemId] ||= { itemId, quantity: 0 }
          collect[itemId].quantity = (collect[itemId].quantity || 0) + (item.quantity || 0)
        }

        return collect
      }, {} as Record<number, OrderInventoryItemInput>)
    ),
  }

  const metrics: OrderDetailsUpsertInput['metrics'] = !details.metrics ? undefined : {
    upsert: compact(Object.entries(details.metrics).map(([key, value]) => {
      if (value === undefined) return
      return { key, value }
    })),
  }

  const scheduleFilled = fillScheduleTimes(details.schedule)

  const schedule: OrderDetailsUpsertInput['schedule'] = !scheduleFilled ? undefined : {
    set: scheduleFilled.map((scheduleEntry) => {
      const {
        id,
        duration,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        metrics,
        startTime,
        endTime,
        address,
        ...data
      } = scheduleEntry

      const addressCleaned = omit(address, ['__typename', 'id'])
      const addressEmpty = Object.values(addressCleaned).every((v) => v === undefined)

      return {
        data: {
          ...data,
          address: addressEmpty ? undefined : addressCleaned,
          startTime: startTime?.toISOString() || null,
          endTime: endTime?.toISOString() || null,
          metrics: !metrics ? undefined : {
            upsert: Object.entries(metrics).map(([k, v]) => ({
              key: k,
              value: v,
            })),
          },
        },
        where: !scheduleEntry.id ? undefined : {
          id: scheduleEntry.id,
        },
      }
    }),
  }

  return {
    equipmentId: details.equipmentId || null,
    inventory,
    metrics,
    schedule,
  }
}

export const formValuesToOrderUpdate = (order: FormValues['order']): OrderUpdateInput => {
  const missingDefault = !order.contacts?.some((contact) => contact.default)
  const contacts: OrderUpdateInput['contacts'] = !order.contacts ? undefined : {
    set: compact(order.contacts.map((contact, index) => {
      if (contact.contactId) {
        return {
          contactId: contact.contactId,
          roleId: contact.roleId,
          default: contact.default || (missingDefault && index === 0),
        }
      }
      // eslint-disable-next-line no-useless-return
      return
    })),
  }

  const comments: OrderUpdateInput['comments'] = {
    create: compact((order.comments || []).map((comment) => {
      if (comment.id) return
      if (!comment.text) return
      return {
        text: comment.text,
        metadata: {
          create: compact(
            comment.metadata?.map((meta) => {
              const { key, value } = meta
              if (isNil(key) || isNil(value)) return
              return { key, value }
            })
          ),
        },
        ...pick(comment, ['createdAt', 'personId', 'updatedAt']),
      }
    })),
    connect: compact((order.comments || []).map((comment) => comment.id)),
  }

  const planned = !order.planned ? undefined : formDetailsToOrderUpdate(order.planned)

  const billable = !order.billable ? undefined : formDetailsToOrderUpdate(order.billable)

  const actuals = !order.actuals ? undefined : order.actuals.map((actual) => ({
    data: formDetailsToOrderUpdate(actual),
    where: {
      subType: must(actual.subType),
    },
  }))

  const baseAttributes = pick(order, [
    'branchId', 'cashOnDelivery', 'customerId', 'flatRate', 'linesOnSite',
    'instructions', 'bulletinToCustomer', 'purchaseOrder', 'siteId',
    'status', 'cancellationReason', 'supplierId',
  ])

  Object.entries(baseAttributes).forEach(([k, v]) => {
    if (v === undefined) {
      (baseAttributes as any)[k] = null
    }
  })

  const revision = order.revision || 0

  return {
    ...baseAttributes,
    additionalIds: order.additionalIds,
    revision,
    comments,
    contacts,
    planned,
    billable,
    actuals: !actuals ? undefined : {
      upsert: actuals,
    },
  }
}

export const formValuesToOrderCreate = (order: FormValues['order']): OrderCreateInput => {
  const { revision, status, ...rest } = formValuesToOrderUpdate(order)
  return {
    ...rest,
    status: status || 'pending',
  }
}
