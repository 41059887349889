import styled from 'styled-components'
import { DefaultLayout } from './DefaultLayout'

export const ScheduleLayout = styled(DefaultLayout)`
  .ant-layout-sider.position-left, .ant-layout-sider.position-left .ant-menu-vertical-left {
    border-right: none;
    padding-left: 1px;
    margin-left: -1px;
  }
  .ant-layout-sider.position-right, .ant-layout-sider.position-right .ant-menu-vertical-left {
    border-left: none;
  }

  &.ant-layout .ant-layout-sider-zero-width-trigger {
    z-index: 2;
    top: 4px;
    background: transparent;
    border: none;
  }
`
