import colors from 'constants/colors'
import styled from 'styled-components'

export const HeaderItem = styled.div`
  background-color: ${colors.fontPrimaryNoAlpha};
  border-top: 1px solid ${colors.borderPrimary};
  color: ${colors.borderPrimary};
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
`
