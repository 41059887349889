import React, { createContext, useContext, useMemo } from 'react'

import { byFieldAsc } from 'helpers/sortting'
import { uniqBy } from 'lodash'
import styled from 'styled-components'

import { useMutation, useQuery } from '@apollo/client'
import { CONNECT_TAGS_MUTATION, DISCONNECT_TAGS_MUTATION, GET_TAGS_FULL } from 'gql/tags'
import {
  Mutation, MutationConnectTagsArgs, Query, QueryTagsArgs, Tag as TagModel,
} from 'schema'

import { TagOutlined } from '@ant-design/icons'
import {
  Col, Dropdown, Empty, Menu, Row,
} from 'antd'
import { RowProps } from 'antd/lib/row'
import ErrorMessage from 'components/ErrorMessage'
import { ResourceTag } from './ResourceTag'

interface TagsEditorState {
  enabled?: boolean
  error?: string
  selected: number[]
  tags: TagModel[]
  toggleTag: (id: number) => Promise<void>
}

const TagsEditorContext = createContext<TagsEditorState>({} as TagsEditorState)

export const useTagsEditorState = () => useContext(TagsEditorContext)

export type TagsEditorProviderProps = React.PropsWithChildren<{
  scope: 'orders' | 'customers' | 'contacts' | 'sites'
  id: number
  selected: TagModel[]
  enabled?: boolean
}>

export const TagsEditorProvider = (props: TagsEditorProviderProps) => {
  const {
    children, scope, id: resourceId, selected: selectedTags, enabled,
  } = props

  const selected = selectedTags.map(({ id }) => id)

  const { data, error } = useQuery<Query, QueryTagsArgs>(GET_TAGS_FULL, {
    variables: {
      where: {
        active: {
          equals: true,
        },
        path: {
          startsWith: `/${scope}/`,
        },
      },
    },
  })

  const tags = useMemo(() => (
    uniqBy(
      [
        ...(data?.tags || []),
        ...(selectedTags || []),
      ],
      (tag) => tag.id
    ).sort(byFieldAsc('sort'))
  ), [data?.tags, selectedTags])

  const [connectTags] = useMutation<Mutation, MutationConnectTagsArgs>(CONNECT_TAGS_MUTATION)
  const [disconnectTags] = useMutation<Mutation, MutationConnectTagsArgs>(DISCONNECT_TAGS_MUTATION)

  const toggleTag = async (id: number) => {
    const mutation = selected.includes(id) ? disconnectTags : connectTags

    await mutation({
      variables: {
        data: {
          [scope]: [
            { id: resourceId },
          ],
        },
        where: [{
          id,
        }],
      },
    })
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
    <TagsEditorContext.Provider value={{
      tags, error: error?.message, selected, toggleTag, enabled,
    }}
    >
      {children}
    </TagsEditorContext.Provider>
  )
}

export interface TagsEditorSelectorProps {
  style?: React.CSSProperties
  className?: HTMLElement['className']
  dropdownStyle?: React.CSSProperties
  dropdownClassName?: HTMLElement['className']
}

const TagMenu = styled(Menu)`
  li {
    padding: 0 10px;
    margin-bottom: 6px;

    &.ant-dropdown-menu-item-divider {
      margin-bottom: 10px;
    }

    .ant-tag {
      padding-top: 2px;
      padding-bottom: 2px;
      padding-right: 10px;
    }
  }
`

export const TagsEditorSelector = (props: TagsEditorSelectorProps) => {
  const {
    tags, selected, error, toggleTag, enabled,
  } = useTagsEditorState()

  const menu = useMemo(() => {
    if (error) {
      return (
        <TagMenu>
          <li><ErrorMessage>{error}</ErrorMessage></li>
        </TagMenu>
      )
    }

    if (tags.length === 0) {
      return (
        <TagMenu>
          <li><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Tags Configured" /></li>
        </TagMenu>
      )
    }

    const withoutSystemTags = tags.filter((tag) => !tag?.path?.startsWith('system'))

    return (
      <TagMenu>
        <li style={{ fontWeight: 600, textAlign: 'center' }}>Tags</li>
        <Menu.Divider />
        {withoutSystemTags.map((tag) => (
          <li key={tag.id}>
            <ResourceTag
              tag={tag}
              block
              checkable
              checked={selected.includes(tag.id)}
              onClick={() => { toggleTag(tag.id) }}
            />
          </li>
        ))}
      </TagMenu>
    )
  }, [tags, selected, error])

  if (enabled === false) {
    return null
  }

  return (
    <Dropdown
      trigger={['click']}
      overlayClassName={props.dropdownClassName}
      overlayStyle={props.dropdownStyle}
      overlay={menu}
    >
      <TagOutlined className={props.className} style={props.style} />
    </Dropdown>
  )
}

export const TagsEditorTags = (props: RowProps) => {
  const { tags, selected, enabled } = useTagsEditorState()

  const selectedTags = useMemo(() => tags.filter((tag) => selected.includes(tag.id)), [tags, selected])

  if (enabled === false) {
    return null
  }

  return (
    <Row gutter={[12, 12]} align="middle" {...props}>
      {selectedTags.map((tag) => (
        <Col key={tag.id}><ResourceTag block tag={tag} /></Col>
      ))}
    </Row>
  )
}

const TagsEditor: typeof TagsEditorProvider & {
  Selector: typeof TagsEditorSelector,
  Tags: typeof TagsEditorTags,
} = TagsEditorProvider as any
TagsEditor.Selector = TagsEditorSelector
TagsEditor.Tags = TagsEditorTags

export { TagsEditor }
