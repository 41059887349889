import { gql } from '@apollo/client'
import { ORDER_FULL_FRAGMENT } from './orders'

export const ROUTE_FRAGMENT = gql`
  fragment RouteFields on Route {
    id
    active
    revision
    startTime
    endTime
    dispatched
    dispatchedAt
    equipmentId
    equipment {
      id
      name
    }
    additionalIds {
      key
      value
    }
    integrations {
      samsara {
        route {
          id
        }
      }
    }
    assignments {
      id
      acknowledged
      acknowledgedAt
      role
      roleDetails {
        slug
        name
        sort
      }
      operatorId
      operator {
        id
        firstName
        lastName
      }
    }
    waypoints {
      id
      name
      status
      scheduledArrivalTime
      actualArrivalTime
      arrivalPerformance
      estimatedArrivalTime
      scheduledDepartureTime
      actualDepartureTime
      departurePerformance
      addressId
      address {
        ...AddressFields
      }
      order {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FULL_FRAGMENT}
`

export const ROUTE_SLIM_FRAGMENT = gql`
  fragment RouteFields on Route {
    id
    active
    equipmentId
    equipment {
      id
      name
    }
    dispatched
    waypoints {
      id
      name
      status
      scheduledArrivalTime
      estimatedArrivalTime
      scheduledDepartureTime
      actualArrivalTime
      arrivalPerformance
      scheduledDepartureTime
      actualDepartureTime
      departurePerformance
      addressId
      address {
        id
        timezone
      }
      order {
        ...OrderFragment
      }
    }
  }
`

export const GET_ROUTES = gql`
  query GetRoute(
    $where: QueryRoutesWhereInput
  ) {
    routes(
      where: $where
    ) {
      ...RouteFields
    }
  }
  ${ROUTE_FRAGMENT}
`

export const UPDATE_ROUTE = gql`
  mutation UpdateRoute(
    $data: RouteUpdateInput!
    $where: RouteWhereUniqueInput!
  ) {
    updateRoute(
      data: $data
      where: $where
    ) {
      ...RouteFields
    }
  }
  ${ROUTE_FRAGMENT}
`

export const DISPATCH_ROUTE = gql`
  mutation DispatchRoute(
    $where: RouteWhereUniqueInput!
  ) {
    dispatchRoute(
      where: $where
    ) {
      id
      dispatched
      dispatchedAt
    }
  }
`
