import { CustomerDrawer } from 'components/drawers/CustomerDrawer'
import { CustomerSelect, CustomerSelectProps } from './CustomerSelect'
import { SelectWithAddForm } from './SelectWithAddForm'

export const CustomerSelectWithAdd = (props: Omit<CustomerSelectProps, 'dropdownRender'>) => (
  SelectWithAddForm({
    name: 'Customer',
    select: CustomerSelect,
    selectProps: props,
    form: CustomerDrawer,
  })
)
