import { gql, useQuery } from '@apollo/client'
import { Query } from 'schema'

export const useQuickbooksSettings = () => {
  const settingsQuery = useQuery<Query>(GET_QUICKBOOKS_SETTINGS, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  })

  const { data, previousData, ...rest } = settingsQuery

  return {
    data: data?.integrations?.quickbooks?.settings,
    previousData: previousData?.integrations?.quickbooks?.settings,
    ...rest,
  }
}

const GET_QUICKBOOKS_SETTINGS = gql`
  query GetQuickbooksSettings {
    integrations {
      quickbooks {
        settings {
          enabled
        }
      }
    }
  }
`
