import {
  gql, useMutation,
} from '@apollo/client'
import React, {
  ReactChild, useContext, useState,
} from 'react'
import { useHistory } from 'react-router-dom'

// eslint-disable-next-line import/no-mutable-exports
export let Token: string | null = localStorage.getItem('token')

type LoginFunc = (idJwtToken: string) => Promise<{ success: boolean, error?: Error }>

export interface Session {
  token: typeof Token,
  setToken: (value: React.SetStateAction<string | null>) => void
  loggedIn: () => boolean
  login: LoginFunc
  logout: () => void
}

const initialContext: Session = {
  token: null,
  setToken: (_value: React.SetStateAction<string | null>) => { },
  loggedIn: () => false,
  login: (_) => Promise.resolve({ success: false }),
  logout: () => { },
}

const SessionContext = React.createContext(initialContext)

export const useSession = () => useContext(SessionContext)

export interface SessionProviderProps {
  children?: ReactChild
}

const AUTHORIZE = gql`
  mutation Authorize($jwt: String!) {
    authorize(jwt: $jwt) {
      token
    }
  }
`

export const SessionProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState(Token)
  const history = useHistory()
  const [authorizeMutation] = useMutation(AUTHORIZE, {
    fetchPolicy: 'no-cache',
  })

  Token = token
  if (token) {
    // TODO: don't use localstorage, it's unsafe
    localStorage.setItem('token', token)
  } else {
    // TODO: don't use localstorage, it's unsafe
    localStorage.removeItem('token')
  }

  const logout = () => {
    (history || window.location).replace('/auth/logout')
  }

  const login = async (idJwtToken: string) => {
    try {
      const resp = await authorizeMutation({
        variables: {
          jwt: idJwtToken,
        },
      })

      if (resp.errors) {
        throw new Error(resp.errors.map((err) => err.message).join(', '))
      }

      Token = resp.data?.authorize?.token
      setToken(Token)
      return { success: true }
    } catch (error: any) {
      return {
        success: false,
        error: new Error(error),
      }
    }
  }

  const loggedIn = () => Boolean(token)

  // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
  const session: Session = {
    token,
    setToken,
    loggedIn,
    login,
    logout,
  }

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  )
}
