import React, { useState } from 'react'
import styled from 'styled-components'

import { useOrganization } from 'hooks/useOrganization'

import {
  Mutation,
  MutationUpdateOrganizationArgs, Organization, OrganizationInput,
} from 'schema'

import { useMutation } from '@apollo/client'
import { UPDATE_ORGANIZATION } from 'gql/organization'

import { PictureOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Row,
  Typography,
} from 'antd'
import { VerticallyCentered } from 'components/common/VerticallyCentered'
import { ErrorMessage } from 'components/ErrorMessage'
import { LoadingIcon } from 'components/icons'
import { Loading } from 'components/Loading'

import { UploadChangeParam } from 'antd/lib/upload'
import { Form } from 'components/form/Form'
import { Input } from 'components/form/inline-edit/Input'
import { Upload } from 'components/form/inline-edit/Upload'
import { useCloudinaryUploadParams } from 'hooks/useCloudinaryUploadParams'
import { HexColorPicker } from '../inline-edit/HexColorPicker'

const { Paragraph } = Typography

const LabelTitle = styled.div`
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 0.2em;
`

const LabelTooltip = styled.div`
  font-size: 0.9em;
`

const InputStyled = styled(Input)`
  max-width: 400px;

  &[disabled] {
    color: rgba(0,0,0,0.85);
    cursor: default;
  }
`

const CustomFormItem = styled((props: Omit<React.ComponentProps<typeof Form.Item>, 'tooltip'> & { tooltip?: string }) => {
  const { tooltip, label, ...rest } = props

  const labelMerged = (
    <div>
      <LabelTitle>{label}</LabelTitle>
      <LabelTooltip>{tooltip}</LabelTooltip>
    </div>
  )

  return <Form.Item {...rest} label={labelMerged} />
})`
  ${(props) => props.position === 'horizontal' && `
    &.ant-form-item > .ant-form-item-row {
      flex-direction: row;
    }
  `}
`

const LoadingOverlay = styled.div.attrs({
  children: <VerticallyCentered>
    <LoadingIcon />
    <div>Uploading</div>
  </VerticallyCentered>,
})`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.9);
  color: rgba(0, 0, 0, 0.85);
  font-size: 15px;
  font-weight: bold;

  .anticon {
    font-size: 25px;
  }
`

const LogoUpload = (
  {
    editing,
    src,
    onUpload,
  }: {
    editing?: boolean,
    src?: string | null,
    onUpload: (file: UploadChangeParam['file']) => void
  }
) => {
  const cloudinary = useCloudinaryUploadParams('logo')

  const [state, setState] = useState<{
    loading: boolean,
    src?: string
  }>({ loading: false })

  const imgSrc = state.src || src

  return (
    <Upload
      name="file"
      listType="picture-card"
      className="avatar-uploader"
      editing={editing}
      showUploadList={false}
      action={cloudinary.url}
      data={cloudinary.data()}
      beforeUpload={(file) => {
        setState({
          loading: true,
          src: URL.createObjectURL(file),
        })
        return true
      }}
      onChange={({ file }) => {
        if (file.status === 'done' && file.response?.url) {
          onUpload(file)
          setState({
            loading: false,
          })
        }
      }}
    >
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        {state.loading && <LoadingOverlay />}
        <div style={{ padding: 8, width: '100%', height: '100%' }}>
          {imgSrc ? (
            <img
              src={imgSrc}
              style={{
                width: '100%', height: '100%', objectFit: 'contain', borderRadius: '10px',
              }}
            />
          )
            : (
              <VerticallyCentered>

                {editing ? (
                  <>
                    <PlusOutlined style={{ display: 'block', marginBottom: '4px' }} />
                    Upload
                  </>
                ) :
                  <PictureOutlined style={{ opacity: 0.15, fontSize: '35px' }} />}
              </VerticallyCentered>
            )}
        </div>
      </div>
    </Upload>
  )
}

export interface OrganizationFormProps {
  id?: number
  editing?: boolean
  onSubmission?: (user: Organization, action: 'update' | 'create') => void
  onCancel?: () => void
}

export const OrganizationForm: React.FC<OrganizationFormProps> = (props) => {
  const { id, onSubmission } = props

  const [editing, setEditing] = useState<boolean>(props.editing === undefined)

  const organization = useOrganization()

  const [
    updateOrganization,
    { error, loading },
  ] = useMutation<Mutation, MutationUpdateOrganizationArgs>(UPDATE_ORGANIZATION)

  const [form] = Form.useForm<Organization>()

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    const data: OrganizationInput = values

    const resp = await updateOrganization({
      variables: { data, where: { id } },
    })

    if (resp.errors) return

    setEditing(false)

    const updatedOrganization = resp?.data?.updateOrganization

    if (updatedOrganization && onSubmission) {
      onSubmission(updatedOrganization, 'update')
    }
  }

  if (!organization) {
    return <Loading />
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      {error && (
        <ErrorMessage>
          <Paragraph>{error.message}</Paragraph>
        </ErrorMessage>
      )}

      <Form
        form={form}
        name="organization-form"
        layout="vertical"
        labelCol={{
          style: {
            marginLeft: '0px',
          },
        }}
        onFinish={onFinish}
        requiredMark={false}
        initialValues={organization}
      >
        <CustomFormItem
          label="Company Name"
          name="name"
          tooltip="This is the name that will be displayed to your customers."
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
          ]}
        >
          <InputStyled placeholder="Enter company name here" disabled={!editing} />
        </CustomFormItem>

        <CustomFormItem
          noStyle
          shouldUpdate={(prevValues, curValues) => (
            prevValues?.logo !== curValues?.logo
          )}
        >
          {() => (
            <Row gutter={30}>
              <Col flex="300px">
                <LabelTitle>Company Logo</LabelTitle>
                <LabelTooltip>This logo will be used throughout the admin, crew, and customer facing apps.</LabelTooltip>
              </Col>
              <Col>
                <LogoUpload
                  editing={editing}
                  src={form.getFieldValue('logo')}
                  onUpload={(file) => {
                    form.setFieldsValue({ logo: file.response?.secure_url || file.response?.url })
                  }}
                />
              </Col>
            </Row>
          )}
        </CustomFormItem>
        <CustomFormItem name="logo" hidden><Input /></CustomFormItem>

        <CustomFormItem
          label="Theme Color"
          name="primaryColor"
          position="horizontal"
          tooltip="The theme color selected will be used throughout the crew and customer apps."
          labelCol={{
            style: {
              flex: '0 0 283px',
              maxWidth: '283px',
              marginRight: 30,
            },
          }}
        >
          <HexColorPicker editing={editing} style={{ marginTop: 20, marginLeft: 20 }} />
        </CustomFormItem>
      </Form>

      <Row gutter={2}>
        <Col>
          {editing && <Button onClick={onCancel}>Cancel</Button>}
        </Col>
        <Col>
          {!editing && <Button type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
          {editing && (
            <Button
              type="primary"
              onClick={form.submit}
              loading={loading ? {
                delay: 200,
              } : undefined}
            >Save
            </Button>
          )}
        </Col>
      </Row>
    </div>
  )
}
