import { orderScheduleUrl } from 'helpers/url'
import { ComponentProps } from 'react'
import { Link } from 'react-router-dom'
import { PartialOrder } from 'types/PartialOrder'

type OrderScheduleLinkProps = Omit<ComponentProps<Link>, 'to'> & {
  order: PartialOrder
}

export const OrderScheduleLink = ({ order, ...rest }: OrderScheduleLinkProps) => {
  const url = orderScheduleUrl(order)
  const to = url ? { pathname: url } : {}
  return <Link {...rest} to={to} />
}
