import { useControllableValue } from 'ahooks'
import { Modal, ModalProps } from 'antd'
import { RequestLocationViaSmsForm, RequestLocationViaSmsFormProps } from './RequestLocationViaSmsForm'

type RequestLocationViaSmsModalProps = RequestLocationViaSmsFormProps & Omit<ModalProps, 'children'>

export const RequestLocationViaSmsModalTrigger = (props: React.PropsWithChildren<RequestLocationViaSmsModalProps>) => {
  const { children, ...rest } = props
  const [visible, setVisible] = useControllableValue(props, { valuePropName: 'visible', defaultValue: false })

  return (
    <>
      <span onClick={() => setVisible(true)}>
        {children}
      </span>
      <RequestLocationViaSmsModal
        {...rest}
        visible={visible}
        onCancel={() => {
          setVisible(false)
          if (props.onCancel) props.onCancel()
        }}
        onSubmission={(...args) => {
          setVisible(false)
          if (props.onSubmission) props.onSubmission(...args)
        }}
      />
    </>
  )
}

export const RequestLocationViaSmsModal = (props: RequestLocationViaSmsModalProps) => {
  const {
    customerId, onSubmission, submitContent, onCancel, ...rest
  } = props
  const [visible, setVisible] = useControllableValue(props, { valuePropName: 'visible' })

  const modalProps: ModalProps = {
    title: 'Request Site Location via SMS',
    footer: null,
    width: '400px',
    ...rest,
    visible,
    onCancel: (el) => {
      setVisible(false)
      if (onCancel) {
        onCancel(el)
      }
    },
  }

  return (
    <Modal {...modalProps}>
      <RequestLocationViaSmsForm
        customerId={customerId}
        submitContent={submitContent}
        onCancel={(...args) => {
          setVisible(false)
          if (onCancel) onCancel(...args)
        }}
        onSubmission={(...args) => {
          setVisible(false)
          if (onSubmission) onSubmission(...args)
        }}
      />
    </Modal>
  )
}
