import { intersection, isEmpty, round } from 'lodash'
import { Customer } from '../../../schema'
import { ItemRow, ROW_TYPE } from '../../../types/ItemTypes'

export type PriceSheet = {
  id: string
  name: string
  itemsAdjuster: <T extends ItemRow>(items: T[]) => T[]
  customerIds?: Array<Customer['id']>
}

const PRICE_SHEETS: PriceSheet[] = [
  {
    id: 'default',
    name: 'Default Pricing Rate',
    itemsAdjuster: (items) => items.map((item) => {
      if (item.type === ROW_TYPE.PRODUCT) {
        const rate = item.baseRate

        return {
          ...item,
          rate,
          amount: round(rate * item.qty, 2),
        }
      }

      return item
    }),
  },
  {
    id: 'walton',
    name: 'Walton Builders',
    customerIds: [8056],
    itemsAdjuster: (items) => items.map((item) => {
      if (item.type === ROW_TYPE.PRODUCT) {
        let rate = item.baseRate

        if (item?.item.includes('Travel')) {
          rate = round(100 / item.qty, 3)
        }

        return {
          ...item,
          rate,
          amount: round(rate * item.qty, 2),
        }
      }

      return item
    }),
  },

  {
    id: 'vip',
    name: 'VIP Pricing',
    itemsAdjuster: (items) => items.map((item) => {
      if (item.type === ROW_TYPE.PRODUCT) {
        const rate = round(item.baseRate * 0.9, 2)

        return {
          ...item,
          rate,
          amount: round(rate * item.qty, 2),
        }
      }

      return item
    }),
  },
]

type UsePriceSheetsProps = {
  customerIds?: Array<Customer['id']>
}

export const usePriceSheets = (props?: UsePriceSheetsProps) => (
  PRICE_SHEETS.filter((sheet) => {
    let include = true

    if (props?.customerIds && sheet.customerIds) {
      include = !isEmpty(intersection(props.customerIds, sheet.customerIds))
    }

    return include
  })
)
