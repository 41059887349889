import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomersIndexQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomersIndexQuery = { __typename?: 'Query', customers: Array<{ __typename?: 'Customer', id: number, name: string, abbreviation: string, active: boolean, createdAt: Types.Scalars['DateTime'] }> };


export const GetCustomersIndexDocument = gql`
    query GetCustomersIndex {
  customers {
    id
    name
    abbreviation
    active
    createdAt
  }
}
    `;

/**
 * __useGetCustomersIndexQuery__
 *
 * To run a query within a React component, call `useGetCustomersIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersIndexQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersIndexQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersIndexQuery, GetCustomersIndexQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersIndexQuery, GetCustomersIndexQueryVariables>(GetCustomersIndexDocument, options);
      }
export function useGetCustomersIndexLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersIndexQuery, GetCustomersIndexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersIndexQuery, GetCustomersIndexQueryVariables>(GetCustomersIndexDocument, options);
        }
export type GetCustomersIndexQueryHookResult = ReturnType<typeof useGetCustomersIndexQuery>;
export type GetCustomersIndexLazyQueryHookResult = ReturnType<typeof useGetCustomersIndexLazyQuery>;
export type GetCustomersIndexQueryResult = Apollo.QueryResult<GetCustomersIndexQuery, GetCustomersIndexQueryVariables>;
export function refetchGetCustomersIndexQuery(variables?: GetCustomersIndexQueryVariables) {
      return { query: GetCustomersIndexDocument, variables: variables }
    }