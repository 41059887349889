import { useDebounceFn, usePrevious } from 'ahooks'
import { Button, Col, Row } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { Form, FormProps as FormPropsUntyped } from 'components/form/Form'
import { round, sumBy } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import colors from '../../constants/colors'
import { byFieldAsc } from '../../helpers/sortting'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import {
  InvoiceStatus,
  Payment, useGetInvoicesQuery, useUpdateInvoiceMutation,
} from '../../hooks/useInvoices'
import { formatToCurrency } from '../../utils/format'
import { SingleCollapse } from '../common/Collapse/SingleCollapse'
import { FormItem, FormItemProps } from '../form/FormItem'
import Checkbox from '../form/inline-edit/Checkbox'
import CurrencyInput from '../form/inline-edit/CurrencyInput'
import DatePicker from '../form/inline-edit/DatePicker'
import Input from '../form/inline-edit/Input'
import { PaymentMethodSelect } from '../form/reference-selects/PaymentMethodSelect'

export type ReceivePaymentProps = {
  invoiceIds: string[]
  onSubmission?: (payment: Payment, action: 'update') => void
}

const MainRow = styled(Row)`
  width: 100%;

  > * {
    flex-grow: 1;
  }
`

type FormValues = {
  amount: number,
  date: moment.Moment,
  method?: string
  reference?: string

}

type FormProps = FormPropsUntyped<FormValues>

export const ReceivePayment = ({ invoiceIds, onSubmission }: ReceivePaymentProps) => {
  const user = useCurrentUser()
  const [form] = Form.useForm<FormValues>()
  const [loading, setLoading] = useState<boolean>(false)
  const [showAutoAdjust, setShowAutoAdjust] = useState<boolean>(false)
  const [showCreditCardAdjust, setShowCreditCardAdjust] = useState<boolean>(false)
  const [ccFeeChecked, setCCFeeChecked] = useState<boolean>(false)

  const { data: { invoices } } = useGetInvoicesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      where: {
        id: {
          in: invoiceIds.map((v) => parseInt(v)),
        },
      },
    },
  })

  const [updateInvoice] = useUpdateInvoiceMutation()

  const method = useWatch('method', form)

  useEffect(() => {
    setShowCreditCardAdjust(method?.startsWith('cc_') || false)
  }, [method])

  const enteredBalance = useWatch('amount', form)

  const balanceDue = useMemo(() => {
    let sum = round(sumBy(invoices, (invoice) => invoice.balance || 0) || 0, 2)
    if (ccFeeChecked) {
      sum = round(sum * 1.05, 2)
    }
    return sum
  }, [invoices, ccFeeChecked])

  const remainingBalance = useMemo(() => (
    Boolean(enteredBalance) &&
    Boolean(balanceDue) &&
    balanceDue - enteredBalance
  ), [balanceDue, enteredBalance])
  const previousRemainingBalance = usePrevious(remainingBalance)

  const { run: runShowAutoAdjust } = useDebounceFn(
    () => {
      setShowAutoAdjust((remainingBalance || 0) > 0)
    },
    {
      wait: 200,
    }
  )

  useEffect(() => {
    runShowAutoAdjust()
  }, [remainingBalance])

  useEffect(() => {
    form.setFieldValue('amount', balanceDue)
  }, [balanceDue])

  const onFinish: FormProps['onFinish'] = async (values) => {
    setLoading(true)

    const payment: Payment = {
      id: Date.now().toString(),
      amount: values.amount,
      reference: values.reference,
      method: values.method,
      date: values.date.utc().toISOString(),
      invoiceIds,
      userId: user?.id,
    }

    const invoicesOldestFirst = invoices.sort(byFieldAsc('dueDate'))

    let { amount } = values

    await invoicesOldestFirst.map((invoice) => {
      const payments = [...(invoice.payments || []), payment]

      let status: InvoiceStatus['slug'] | undefined
      const amountPaid = Math.min(amount, invoice.balance || 0)
      if (amountPaid === (invoice.balance)) {
        status = 'paid'
      }

      amount -= amountPaid

      return updateInvoice({
        variables: {
          data: {
            ...invoice,
            status,
            payments,
            amountPaid,
          },
          where: {
            id: parseInt(invoice.id),
          },
        },
      })
    })

    if (onSubmission) {
      onSubmission(payment, 'update')
    }

    setLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="receive-payment-form"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          date: moment(),
          method: 'check',
        }}
        labelCol={{
          style: {},
        }}
      >

        <MainRow gutter={16}>
          <Col>
            <VerticalFormItem
              label="Payment Date"
              name="date"
            >
              <DatePicker
                editing
                allowClear={false}
              />
            </VerticalFormItem>
          </Col>
          <Col>
            <VerticalFormItem
              label="Payment Method"
              name="method"
            >
              <PaymentMethodSelect
                style={{ minWidth: '170px' }}
                editing
              />
            </VerticalFormItem>

            <SingleCollapse
              noStyle
              ghost
              style={{ position: 'relative', top: '-9px' }}
              visible={showCreditCardAdjust}
            >
              <div style={{ margin: '0 0 7px 0', fontSize: '0.8em', cursor: 'pointer' }}>
                <Checkbox
                  editing
                  style={{ marginRight: 5, position: 'relative', top: '2px' }}
                  value={ccFeeChecked}
                  onChange={(e) => setCCFeeChecked(e.target.checked)}
                />
                Apply 5% processing fee
              </div>
            </SingleCollapse>

          </Col>
          <Col>
            <VerticalFormItem
              label="Reference Number"
              name="reference"
            >
              <Input
                editing
                placeholder="e.g. check number"
              />
            </VerticalFormItem>
          </Col>
          <Col>
            <VerticalFormItem
              label="Amount Paid"
              name="amount"
            >
              <CurrencyInput
                editing
                prefix="$"
                placeholder="Amount Paid"
                step={undefined}
              />
            </VerticalFormItem>

            <SingleCollapse
              noStyle
              ghost
              style={{ position: 'relative', top: '-9px' }}
              visible={showAutoAdjust}
            >
              <div style={{ margin: '0 0 7px 0', fontSize: '0.8em', cursor: 'pointer' }}>
                <Checkbox
                  editing
                  style={{ marginRight: 5, position: 'relative', top: '2px' }}
                />
                Apply {formatToCurrency(remainingBalance || previousRemainingBalance || 0)} adjustment credit
              </div>
            </SingleCollapse>
          </Col>
          <Col style={{ alignSelf: 'top' }}>
            <Button
              block
              type="primary"
              onClick={form.submit}
              loading={loading}
              style={{ margin: 0, marginTop: 23 }}
            >
              Save
            </Button>
          </Col>
        </MainRow>
      </Form>
    </>
  )
}

const Label = styled.span`
  font-size: 0.9em;
  font-weight: 600;
  color: ${colors.greyscale60};
`

const VerticalFormItemWrap = styled.div`
  margin-bottom: 15px;

  > ${Label} {
    display: block;
    margin-bottom: 3px;
  }

  .ant-form-item-explain {
    max-width: 250px;
  }

  .ant-select-borderless,
  .ant-picker-borderless,
  .ant-input-affix-wrapper-borderless,
  .ant-select-borderless .ant-select-selector {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    border-top: none!important;
    border-right: none !important;
    border-left: none !important;

    .ant-input-suffix, .ant-picker-suffix {
      margin-right: 7px;
    }
    .ant-select-arrow {
      right: 7px;
    }
  }

  .ant-select-borderless,
  .ant-picker-borderless,
  .ant-input-affix-wrapper-borderless {
    border-bottom: 1px solid transparent !important;

    &:not(.read-only) {
      border-bottom: 1px solid #d9d9d9 !important;

      &:hover,
      &:focus,
      &.ant-picker-focused,
      &.ant-input-affix-wrapper-focused,
      &.ant-input-focused,
      &.ant-select-focused {
        border-bottom-color: #40a9ff !important;
      }
    }
  }
`

const VerticalFormItem = ({ label, ...props }: FormItemProps) => (
  <VerticalFormItemWrap>
    <Label>{label}</Label>
    <FormItem {...props} />
  </VerticalFormItemWrap>
)
