import { Form, FormListProps } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'
import { Input } from 'components/form/inline-edit/Input'
import { useEffect } from 'react'

interface EmailsFormListProps extends Omit<FormListProps, 'name' | 'children'> {
  name?: FormListProps['name']
  editing?: boolean
}

interface FormListFieldsProps {
  editing?: boolean
  listProps: Parameters<FormListProps['children']>
}

const FormListFields = ({ editing, listProps }: FormListFieldsProps) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [fields, { add, remove }, { errors }] = listProps

  useEffect(() => {
    if (fields.length === 0) {
      add()
    }
  }, [fields])

  return (
    <>
      {fields.map((field) => (
        <FormItem
          label="Email"
          {...field}
        >
          <Input placeholder="Email Address" editing={editing} />
        </FormItem>
      ))}
    </>
  )
}

// eslint-disable-next-line unused-imports/no-unused-vars
export const EmailsFormList = (props: EmailsFormListProps) => {
  const {
    editing,
    name,
    initialValue,
    ...args
  } = props

  return (
    <Form.List
      name={name || 'emails'}
      initialValue={initialValue}
      {...args}
    >
      {(...listProps) => <FormListFields editing={editing} listProps={listProps} />}
    </Form.List>
  )
}
