import { Comment as CommentModel } from 'schema'

import { timeWithZone } from 'helpers/datetime'
import { compact } from 'lodash'
import styled from 'styled-components'

import { useBranch } from 'hooks/useBranch'

import { Comment as AntComment, CommentProps as AntCommentProps } from 'antd'
import { FullName } from './FullName'
// eslint-disable-next-line import/no-cycle
import { Markdown } from './Markdown'

export const EXTERNAL_LINK_UNICODE = '↗'

const StyledComment = styled(AntComment)`
  margin-bottom: 12px;

  .ant-comment-content-author-name {
    flex: 1 1 auto;
  }

  .ant-comment-inner {
    padding: 0;
  }

  .ant-comment-content-detail img {
    max-width: 100%;
  }
`

interface CommentProps extends Partial<AntCommentProps> {
  comment: CommentModel
}

export const Comment = (props: CommentProps) => {
  const branch = useBranch()
  const { comment, className, ...rest } = props

  const classes = className ? [className] : []
  if (!comment.id) {
    classes.push('new-comment')
  }

  return (
    <StyledComment
      className={compact(classes).join(' ')}
      author={comment.person && <FullName {...comment.person} />}
      content={<Markdown children={comment.text} />}
      datetime={comment.createdAt && timeWithZone(comment.createdAt, branch?.timezone).toFormat('MM/dd hh:mma')}
      {...rest}
    />
  )
}
