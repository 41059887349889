import { useEffect, useMemo } from 'react'
import {
  generatePath, useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { SchedulerStateProvider, useNewSchedulerState } from 'components/scheduler/SchedulerState'

import { EditModeProvider } from 'components/scheduler/EditModeState'
import { SchedulerEventsStateProvider } from 'components/scheduler/SchedulerEventsState'

import { SchedulerResourcesStateProvider } from 'components/scheduler/SchedulerResourcesState'
import { useIsMobile } from 'hooks/useViewportMode'
import { ScheduleDesktopLayout } from './ScheduleDesktopLayout'
import { ScheduleMobileLayout } from './ScheduleMobileLayout'
import { ScheduleUrlParams } from './ScheduleUrlParams'

export const SchedulePage = () => {
  const history = useHistory()
  const match = useRouteMatch<ScheduleUrlParams>()
  const location = useLocation()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  const isMobile = useIsMobile()

  const state = useNewSchedulerState({
    date: match.params.date,
    basePath,
  })

  useEffect(() => {
    const newBasePath = generatePath(match.path, { ...match.params, date: state.selectedDateSlug })
    const pathChanged = newBasePath !== match.url
    if (pathChanged) {
      history.replace({
        ...location,
        pathname: location.pathname.replace(match.url, newBasePath),
      })
    }
  }, [state.selectedDateSlug])

  return (
    <SchedulerStateProvider value={state}>
      <SchedulerEventsStateProvider>
        <SchedulerResourcesStateProvider>
          <EditModeProvider>
            {isMobile ?
              <ScheduleMobileLayout />
              :
              <ScheduleDesktopLayout />}
          </EditModeProvider>
        </SchedulerResourcesStateProvider>
      </SchedulerEventsStateProvider>
    </SchedulerStateProvider>
  )
}

export default SchedulePage
