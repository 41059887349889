import {
  generatePath, matchPath, useHistory, useLocation, useRouteMatch,
} from 'react-router'

export const useRouteParamState = (param: string): [string | undefined, (value: string) => void] => {
  const match = useRouteMatch<Record<string, string | undefined>>()
  const location = useLocation()
  const history = useHistory()

  const currentValue = match.params[param]
  const setRouteParam = (value: string) => {
    const newParams = { ...match.params, [param]: value }

    const newMatch = matchPath(location.pathname, {
      path: `${match.path}/:rest?`,
    })

    if (newMatch) {
      const additionalPath = location.pathname.slice(match.url.length)
      const newPath = generatePath(newMatch.path, newParams) + additionalPath
      history.replace(newPath)
    }
  }

  return [currentValue, setRouteParam]
}
