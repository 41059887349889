import { useOrganization } from 'hooks/useOrganization'
import { ImgHTMLAttributes } from 'react'
import styled from 'styled-components'

const Img = styled.img`
  max-width: 100%;
  padding: 13% 30%;
`

type LogoProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>

export const Logo = (props: LogoProps) => {
  const organization = useOrganization()

  if (!organization?.logo) return null

  return <Img alt={organization.name} {...props} src={organization.logo} />
}
