import { Col, Row } from 'antd'
import { RemoveItemButton } from 'components/buttons'
import { FormListProps, useCurrentForm } from 'components/form/Form'
import { FormItem, FormItemProps } from 'components/form/FormItem'
import { Input } from 'components/form/inline-edit/Input'
import colors from 'constants/colors'
import { getNamePath } from 'rc-field-form/es/utils/valueUtil'
import styled from 'styled-components'

const KeyInput = styled(Input)`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${colors.greyscale60};
`

interface CustomFieldFormItemProps {
  name: FormListProps['name']
  editing?: boolean
  listPrefix?: FormItemProps['listPrefix']
  onRemove: () => void
}

export const CustomFieldFormItem = ({
  editing,
  name,
  onRemove,
  listPrefix,
}: CustomFieldFormItemProps) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const form = useCurrentForm()
  // eslint-disable-next-line unused-imports/no-unused-vars
  const path = getNamePath(name)

  return (
    <Row gutter={16} key={listPrefix?.key} wrap={false}>
      <Col>
        <FormItem
          listPrefix={listPrefix}
          name="key"
        >
          <KeyInput
            placeholder="Field Name"
            editing={editing}
          />
        </FormItem>
      </Col>
      <Col>
        <FormItem
          listPrefix={listPrefix}
          name="value"
        >
          <Input
            placeholder="Field Value"
            editing={editing}
          />
        </FormItem>
      </Col>
      <Col flex="50px">
        {editing && <RemoveItemButton onClick={onRemove} />}
      </Col>
    </Row>
  )
}
