import { DatePicker as AntDatePicker } from 'antd'
import { RangePickerProps as AntRangePickerProps } from 'antd/lib/date-picker'
import React, { ComponentRef } from 'react'
import styled from 'styled-components'

const AntRangePicker = AntDatePicker.RangePicker

export type RangerPickerRef = ComponentRef<typeof AntRangePicker>

export type RangePickerProps = AntRangePickerProps & {
  editing?: boolean
}

const StyledAntRangePicker = styled(AntRangePicker)`
  &.read-only,
  &.read-only input
  {
    border-color: transparent;
    background-color: transparent;
    outline-color: transparent;
    box-shadow: none;

    .ant-picker-active-bar, .ant-picker-range-separator {
      visibility: hidden;
    }
  }
`

export const RangePicker = React.forwardRef<RangerPickerRef, RangePickerProps>((props, ref) => {
  const { editing, ...args } = props
  if (editing === false) {
    args.className = `${args.className} read-only`
    args.inputReadOnly = true
    args.open = false
    args.suffixIcon = null
    args.allowClear = false
  }
  if (args.format === undefined) {
    args.format = 'MM/DD/YYYY'
  }
  if (args.autoComplete === undefined) {
    args.autoComplete = 'off'
  }
  return <StyledAntRangePicker data-lpignore="true" {...args} ref={ref} />
})

export default RangePicker
