import { gql } from '@apollo/client'
import { ADDRESS_FRAGMENT } from './addresses'
import { TAG_SLIM_FRAGMENT } from './tags'

export const SITE_FULL_FRAGMENT = gql`
  fragment SiteFields on Site {
    id
    name
    name2
    customerId
    notes
    active
    verified
    address {
      ...AddressFields
    }
    tags {
      ...TagFields
    }
  }

  ${ADDRESS_FRAGMENT}
  ${TAG_SLIM_FRAGMENT}
`

export const GET_SITES_FOR_CUSTOMER = gql`
  query GetSitesForCustomer($customerId: Int, $where: CustomerSitesWhereInput) {
    customers(where: { id: { equals: $customerId } } ) {
      sites(where: $where) {
        ...SiteFields
      }
    }
  }
  ${SITE_FULL_FRAGMENT}
`

export const GET_SITES_WITH_CUSTOMER = gql`
  query GetSitesForCustomer($customerId: Int, $where: CustomerSitesWhereInput) {
    customers(where: { id: { equals: $customerId } } ) {
      id
      name
      abbreviation
      sites(where: $where) {
        ...SiteFields
      }
    }
  }
  ${SITE_FULL_FRAGMENT}
`

export const CREATE_SITE = gql`
  mutation CreateSite(
    $data: SiteInput!
  ) {
    createSite(
      data: $data
    ) {
      ...SiteFields
    }
  }
  ${SITE_FULL_FRAGMENT}
`

export const UPDATE_SITE = gql`
  mutation UpdateSite(
    $data: SiteUpdateInput!
    $where: SiteWhereUniqueInput!
  ) {
    updateSite(
      data: $data
      where: $where
    ) {
      ...SiteFields
    }
  }
  ${SITE_FULL_FRAGMENT}
`
