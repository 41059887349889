import { Form } from 'antd'
import { FormItemProps as AntdFormItemProps } from 'antd/lib/form'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { compact, flatten } from 'lodash'
import styled from 'styled-components'

export interface FormItemProps extends AntdFormItemProps {
  listPrefix?: FormListFieldData
  noMargin?: boolean
  position?: 'horizontal' | 'vertical'
}

const FormItemStyled = styled(Form.Item) <FormItemProps>`
  &.ant-form-item > .ant-form-item-row {
    ${(props) => props.position === 'horizontal' && `
      flex-wrap: nowrap;
    `}

    ${(props) => props.position === 'vertical' && `
      .ant-form-item-label {
        padding-left: 10px;
        flex-basis: 100% !important;

        > label:after {
          display: none;
        }
      }
    `}

    &.no-margin {
      margin: 0;
    }
  }

  .ant-col.ant-form-item-control {
    overflow: auto;
  }

  .ant-form-item-control-input-content > *:not(.ant-switch):not(.no-style) {
    width: 100%;
  }
`

export const FormItem = (props: FormItemProps) => {
  const { listPrefix, noMargin, ...args } = props as FormItemProps

  if (listPrefix && props.name) {
    args.name = flatten([listPrefix.name, props.name])
  }

  if (args.position === 'vertical') {
    args.labelAlign ||= 'left'
  }

  if (noMargin) {
    args.className = compact([args.className, 'no-margin']).join(' ')
  }

  return <FormItemStyled position="horizontal" {...args} />
}
