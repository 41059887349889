import { DateTime } from 'utils/luxon'

import { useNotification } from 'hooks/useNotification'

import { Redirect, useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Query, QueryRouteWaypointsArgs } from 'schema'

import Loading from 'components/Loading'
import { dateToSlug } from 'helpers/url'

export const RouteWaypointShowRedirect = (_args: any) => {
  const notification = useNotification()
  const { id: urlId } = useParams<{ id: string }>()
  const id = parseInt(urlId)

  const { loading, data } = useQuery<Query, QueryRouteWaypointsArgs>(gql`
    query GetRoute(
      $where: QueryRouteWaypointsWhereInput
    ) {
      routeWaypoints(
        where: $where
      ) {
        scheduledArrivalTime
        scheduledDepartureTime
        address {
          timezone
        }
        route {
          id
          branch {
            id
          }
        }
      }
    }
  `, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: {
          equals: id,
        },
      },
    },
  })

  if (loading) {
    return <Loading />
  }

  const waypoint = data?.routeWaypoints?.[0]
  if (!waypoint) {
    notification.error({
      message: 'Route not found',
      duration: 5,
    })
    return <Redirect to="/" />
  }

  const date = DateTime.fromISO(waypoint.scheduledDepartureTime || waypoint.scheduledArrivalTime || DateTime.utc().toISO())

  return <Redirect to={`/branches/${waypoint.route.branch.id}/schedule/${dateToSlug(date)}/route/${waypoint.route.id}`} />
}

export default RouteWaypointShowRedirect
