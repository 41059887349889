import { gql, useQuery } from '@apollo/client'
import { Query } from 'schema'

export const useGeotabSettings = () => {
  const settingsQuery = useQuery<Query>(GET_GEOTAB_SETTINGS, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  })

  const { data, previousData, ...rest } = settingsQuery

  return {
    data: data?.integrations?.geotab?.settings,
    previousData: previousData?.integrations?.geotab?.settings,
    ...rest,
  }
}

export const GET_GEOTAB_SETTINGS = gql`
  query GetGeotabSettings {
    integrations {
      geotab {
        settings {
          enabled
          database
          server
        }
      }
    }
  }
`
