import { isEmpty } from 'lodash'
import { FormValues } from '../FormValues'
import { DEFAULT_CLEAN_TIME, DEFAULT_PREP_TIME } from './constants'

export const defaultScheduleSteps = [
  {
    duration: DEFAULT_PREP_TIME,
    step: 'prep',
  },
  {
    step: 'pour',
  },
  {
    duration: DEFAULT_CLEAN_TIME,
    step: 'clean',
  },
]

export const setFormValueDefaults = (values: FormValues, isNewForm: boolean): FormValues => {
  if (isEmpty(values.order.contacts)) {
    values.order.contacts = [{
      default: true,
    }]
  }

  if (isNewForm) {
    const defaultSchedule = defaultScheduleSteps
    const schedule = values.details.schedule || []
    const newSchedule: typeof schedule = []

    Object.values(defaultSchedule).forEach((defaultEntry, index) => {
      const matchingInputEntry = schedule.find((entry) => entry.step === defaultEntry.step)

      newSchedule[index] = {
        ...defaultEntry,
        ...matchingInputEntry,
      }
    })

    values.details.schedule = newSchedule
  }
  return values
}
