import { Moment } from 'moment'
import { DateTime, toMoment, Zone } from 'utils/luxon'

export function timeWithZone(time: null | undefined, timezone: string | null | undefined): undefined
export function timeWithZone(time: string, timezone: string | null | undefined): DateTime
export function timeWithZone(time: null | undefined | string, timezone: string | null | undefined): DateTime | undefined
export function timeWithZone(time: null | undefined | string, timezone: string | null | undefined): DateTime | undefined {
  if (!time) return

  if (timezone) {
    return DateTime.fromISO(time as string).setZone(timezone)
  }
  return DateTime.fromISO(time as string)
}

export function timeWithZoneToMoment(time: null | undefined, timezone: string | null | undefined): undefined
export function timeWithZoneToMoment(time: string, timezone: string | null | undefined): Moment
export function timeWithZoneToMoment(time: null | undefined | string, timezone: string | null | undefined): Moment | undefined
export function timeWithZoneToMoment(time: null | undefined | string, timezone: string | null | undefined): Moment | undefined {
  const asLuxon = timeWithZone(time, timezone)
  if (!asLuxon) return
  return toMoment(asLuxon as DateTime)
}

export const isToday = (date: DateTime) => {
  const today = DateTime.utc().setZone(date.zone).toISODate()
  const dateSlug = date.toISODate()
  return today === dateSlug
}

export const todayInZone = (timezone: string | Zone) => DateTime.utc().setZone(timezone).startOf('day')

export const formatRelativeDateTime = (
  displayDate: DateTime,
  relativeDate: DateTime,
  options: {
    trimTopOfHour?: boolean
    spaceBeforeMeridiem?: boolean
  } = {}
) => {
  const timePart = formatRelativeTime(displayDate, relativeDate, options)

  if (displayDate.toISODate() !== relativeDate.toISODate()) {
    return `${displayDate.toFormat('M/d')} ${timePart}`
  }
  return timePart
}

export const formatRelativeTime = (
  displayDate: DateTime,
  relativeDate: DateTime,
  options: {
    trimTopOfHour?: boolean
    spaceBeforeMeridiem?: boolean
  } = {}
) => {
  let format = 'h:mm'

  if (displayDate.minute === 0 && options.trimTopOfHour) {
    format = 'h'
  }

  if (options.spaceBeforeMeridiem) {
    format += ' '
  }

  format += 'a'

  if (displayDate.offset !== relativeDate.offset) {
    format += ' ZZZZ'
  }

  return displayDate.toFormat(format)
}
