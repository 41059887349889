import colors from 'constants/colors'
import { compactAndJoin } from 'helpers/formatting'

import { Note } from 'schema'

import { Col, Row } from 'antd'
import { NotesIcon } from 'components/icons'
import { dateToSlug } from 'helpers/url'
import { useBranch } from 'hooks/useBranch'
import { useHistory } from 'react-router'
import { EventCollapse } from '../EventCollapse'
import {
  EventProps, EventWrap,
  EventWrapInnerBorder,
  Title,
} from './Card'
import { customerContactTitle } from './OrderEvent'

const noteBackgroundColor = colors.greyscale15
const noteTextColor = colors.greyscale60

export interface NoteEventProps extends EventProps {
  note: Note
}

export const NoteEvent = (props: NoteEventProps) => {
  const history = useHistory()
  const branch = useBranch()

  const { note, selectedDate } = props
  const title = compactAndJoin(' | ', [customerContactTitle(note?.customer, note?.contact), note.title])

  return (
    <EventWrap
      onClick={() => {
        history.push(`/branches/${branch?.id}/schedule/${dateToSlug(selectedDate)}/note/${note.id}`)
      }}
      style={{
        borderLeftColor: noteBackgroundColor,
        borderRightColor: noteBackgroundColor,
        backgroundColor: noteBackgroundColor,
      }}
    >
      <EventWrapInnerBorder style={{
        borderBottomColor: noteBackgroundColor,
        borderTopColor: noteBackgroundColor,
      }}
      >
        <EventCollapse
          minified
          header={(
            <Row wrap={false} align="middle" style={{ width: '100%' }}>
              <Col>
                <NotesIcon style={{ color: noteTextColor }} />
              </Col>
              <Col flex="auto">
                <Title
                  style={{ color: noteTextColor, fontWeight: 500 }}
                  ellipsis={{
                    tooltip: true,
                  }}
                >
                  {title}
                </Title>
              </Col>
            </Row>
          )}
        />
      </EventWrapInnerBorder>
    </EventWrap>
  )
}
