import { Typography } from 'antd'
import { Tooltip } from 'components/common/Tooltip'
import { Customer } from 'schema'

const { Text } = Typography

export const CustomerShortName = ({ customer }: { customer: Partial<Customer> }) => {
  const hasAbbreviation = customer.abbreviation && customer.abbreviation !== customer.name
  const name = customer.name || customer.name2 || customer.abbreviation || (customer.id ? `Customer #${customer.id}` : null)

  if (hasAbbreviation) {
    return (
      <Tooltip title={name}>
        {customer.abbreviation}
      </Tooltip>
    )
  }

  return (
    <Text ellipsis={{ tooltip: name }}>
      {name}
    </Text>
  )
}
