import { Switch as AntSwitch } from 'antd'
import { SwitchProps as AntSwitchProps } from 'antd/lib/switch'
import React, { ComponentRef } from 'react'
import styled from 'styled-components'

export type SwitchRef = ComponentRef<typeof AntSwitch>

export interface SwitchProps extends AntSwitchProps {
  editing?: boolean
}

const StyledAntSwitch = styled(AntSwitch)`
  &.ant-switch.read-only,
  &.ant-switch.read-only .ant-switch-handle
  {
    cursor: inherit;
  }
`

export const Switch = React.forwardRef<SwitchRef, SwitchProps>((props, ref) => {
  const { editing, ...args } = props
  if (editing === false && args.disabled === undefined) {
    args.className = `${args.className || ''} read-only`
    args.disabled = true
  }
  return <StyledAntSwitch {...args} ref={ref} />
})

export default Switch
