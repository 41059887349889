import colors from 'constants/colors'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import qs from 'utils/qs'

import { useHistory, useLocation } from 'react-router'

import { Col, Row, Typography } from 'antd'
import { Form } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'
import { RangePicker } from 'components/form/inline-edit/RangePicker'
import BranchSelect from 'components/form/reference-selects/BranchSelect'
import { ContactSelect } from 'components/form/reference-selects/ContactSelect'
import { CustomerSelect } from 'components/form/reference-selects/CustomerSelect'
import { OrderStatusSelect } from 'components/form/reference-selects/OrderStatusSelect'
import SiteSelect from 'components/form/reference-selects/SiteSelect'
import TagSelect from 'components/form/reference-selects/TagSelect'
import { useBranch } from 'hooks/useBranch'
import { compact } from 'lodash'

export interface FilterValues {
  status?: Array<string> | null
  branchId?: Array<number> | null
  customerId?: number | null
  contactId?: Array<number> | null
  tagId?: Array<number> | null
  siteId?: Array<number> | null
  date?: Array<moment.Moment | null>
}

export interface OrderFiltersProps {
  onChange?: (changedValues: Partial<FilterValues>, allValues: FilterValues) => void
}

const FormStyles = styled.div`
  .ant-form-item {
    margin-bottom: 10px
  }

  .ant-form-item-label label {
    font-size: 0.8rem;
    font-weight: 600;
    color: ${colors.greyscale70};
  }
`

export const OrdersFilters = (props: OrderFiltersProps) => {
  const history = useHistory()
  const location = useLocation()
  const branch = useBranch()
  const [form] = Form.useForm<FilterValues>()

  const initialValues = useMemo(() => {
    const {
      branchId, customerId, contactId, siteId, startDate, endDate, status, tagId, ...rest
    } = qs.parse(location.search)
    const values: FilterValues = rest

    if (branchId) {
      values.branchId = [branchId].flat().map((id) => parseInt(id.toString()))
    } else if (branch) {
      values.branchId = [branch.id]
    }
    if (customerId) {
      values.customerId = parseInt([customerId].flat()[0].toString())
    }
    if (contactId) {
      values.contactId = [contactId].flat().map((id) => parseInt(id.toString()))
    }
    if (siteId) {
      values.siteId = [siteId].flat().map((id) => parseInt(id.toString()))
    }
    if (startDate || endDate) {
      values.date = [[startDate].flat()[0], [endDate].flat()[0]].map((item) => (!item ? null : moment(item)))
    }
    if (status) {
      values.status = compact([status].flat().map((s) => `${s}`))
    }
    if (tagId) {
      values.tagId = [tagId].flat().map((id) => parseInt(id.toString()))
    }

    return values
  }, [])

  const onValuesChange = (changedValues: Partial<FilterValues>, allValues: FilterValues) => {
    if ('customerId' in changedValues) {
      form.setFieldsValue({
        contactId: [],
      })
      changedValues.contactId = []
      allValues.contactId = []
    }

    const { date, ...rest } = allValues

    const historyValues: any = {
      ...qs.parse(location.search),
      ...rest,
    }
    historyValues.startDate = date?.[0]?.format('YYYY-MM-DD')
    historyValues.endDate = date?.[1]?.format('YYYY-MM-DD')
    history.push({
      search: qs.stringify(historyValues),
    })

    if (props.onChange) {
      props.onChange(changedValues, allValues)
    }
  }

  // on first load, emit current values
  useEffect(() => {
    if (props.onChange) {
      props.onChange({}, initialValues)
    }
  }, [])

  return (
    <FormStyles>
      <Form<FilterValues>
        form={form}
        name="order-index-filters-form"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        requiredMark={false}
        layout="vertical"
        labelCol={{
          style: {},
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Typography.Title level={3}>
              Filters
            </Typography.Title>
          </Col>

          <Col span={24}>
            <FormItem
              label="Branch"
              name="branchId"
            >
              <BranchSelect
                allowClear
                defaultActiveFirstOption={false}
                mode="multiple"
                placeholder="Filter by Branch"
                style={{ width: '100%' }}
                editing
              />
            </FormItem>
          </Col>

          <Col span={24}>
            <FormItem
              label="Date Range"
              name="date"
            >
              <RangePicker
                allowEmpty={[true, true]}
                style={{ width: '100%' }}
              />
            </FormItem>
          </Col>

          <Col span={24}>
            <FormItem
              label="Order Status"
              name="status"
            >
              <OrderStatusSelect
                allowClear
                defaultActiveFirstOption={false}
                mode="multiple"
                placeholder="Filter by Order Status"
                style={{ width: '100%' }}
                editing
              />
            </FormItem>
          </Col>

          <Col span={24}>
            <FormItem
              label="Customer"
              name="customerId"
            >
              <CustomerSelect
                allowClear
                showExternalLink={false}
                placeholder="Filter by Customer"
                style={{ width: '100%' }}
                editing
              />
            </FormItem>
          </Col>

          <Col span={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.customerId !== next.customerId}
            >
              {() => (
                <FormItem
                  label="Contact"
                  name="contactId"
                >
                  <ContactSelect
                    allowClear
                    showExternalLink={false}
                    mode="multiple"
                    placeholder="Filter by Contact"
                    style={{ width: '100%' }}
                    customerId={form.getFieldValue('customerId')}
                    resources={
                      compact([form.getFieldValue('contactId')].flat()).map((id) => ({ id }))
                    }
                  />
                </FormItem>
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.customerId !== next.customerId}
            >
              {() => (
                <FormItem
                  label="Site"
                  name="siteId"
                >
                  <SiteSelect
                    allowClear
                    showExternalLink={false}
                    mode="multiple"
                    placeholder={form.getFieldValue('customerId') ? 'Filter by Site' : 'Select Customer First'}
                    style={{ width: '100%' }}
                    customerId={form.getFieldValue('customerId')}
                  />
                </FormItem>
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <FormItem
              label="Tag"
              name="tagId"
            >
              <TagSelect
                allowClear
                showExternalLink={false}
                mode="multiple"
                placeholder="Filter by Tag"
                style={{ width: '100%' }}
                scope="orders"
                resources={
                  compact([form.getFieldValue('tagId')].flat()).map((id) => ({ id }))
                }
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </FormStyles>
  )
}
