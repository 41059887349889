import { useMutation } from '@apollo/client'
import { Tabs } from 'antd'
import { NoteForm } from 'components/form/NoteForm'
import { OrderForm } from 'components/form/OrderForm/OrderForm'
import { OrderFormProps } from 'components/form/OrderForm/OrderFormProps'
import { DELETE_NOTE } from 'gql/notes'
import { sha256 } from 'hash.js'
import { timeWithZone } from 'helpers/datetime'
import { useBranch } from 'hooks/useBranch'
import { CloseButton } from 'hooks/useClosable'
import {
  Actions, Body, ContentContext, Footer, Header, useContentLayoutState,
} from 'hooks/useContentLayout'
import { useNotification } from 'hooks/useNotification'
import { DrawerContent, DrawerContentSections } from 'layouts/Content/DrawerContent'
import { omit } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import {
  useHistory,
  useLocation,
} from 'react-router-dom'
import { Mutation, Note } from 'schema'
import styled from 'styled-components'

const { TabPane } = Tabs

const TabsStyled = styled(Tabs)`
  &, .ant-tabs-nav, .ant-tabs-nav-wrap {
    width: 100%;
  }
`

interface TabContentProps {
  locationState: any
}

const OrderTab = ({ locationState }: TabContentProps) => {
  const notification = useNotification()

  const orderFormProps = useMemo(() => {
    const props = {
      editing: true,
      detailsScope: {
        type: 'planned',
      },
      order: {},
    }

    if (!locationState) return props

    return {
      ...props,
      ...(locationState.routeId ? {
        route: {
          connect: {
            id: locationState.routeId,
          },
        },
      } : {}),
      order: {
        planned: {
          equipmentId: locationState?.equipmentId,
          schedule: [
            {
              step: 'pour',
              startTime: locationState.startTime,
            },
          ],
        },
        billable: {
          equipmentId: locationState?.equipmentId,
        },
        ...locationState?.fullOrder,
      },
      rescheduleFrom: locationState?.rescheduleFrom,
    }
  }, [locationState]) as OrderFormProps

  const key = useMemo(() => sha256().update(JSON.stringify(orderFormProps)).digest('hex'), [orderFormProps])

  const afterSave: Array<Record<string, any>> = locationState?.afterSave || []

  // eslint-disable-next-line @typescript-eslint/ban-types
  const [deleteNoteMutation] = useMutation<Mutation, {}>(DELETE_NOTE)

  const deleteNote = async (id: Note['id']) => {
    const response = await deleteNoteMutation({
      variables: {
        where: { id },
      },
    })

    if ((response.errors || []).length > 0) {
      notification.error({
        message: 'Error Deleting Note',
        description: response?.errors?.map((error) => error.message).join('\n'),
        duration: 5,
      })
    }
  }

  return (
    <OrderForm
      key={key}
      {...orderFormProps}
      onSubmission={async (order) => {
        await Promise.all(
          afterSave.map(async (command) => {
            if (command.action === 'deleteNote' && command.noteId) {
              await deleteNote(command.noteId)
            }
          })
        )
      }}
    />
  )
}

const NoteTab = ({ locationState }: TabContentProps) => {
  const history = useHistory()
  const location = useLocation<any>()
  const branch = useBranch()

  const newNote: Partial<Note> = useMemo(() => {
    const startTime = timeWithZone(locationState?.startTime, branch?.timezone) || DateTime.local()

    return {
      equipmentId: locationState?.equipmentId,
      event: {
        branchId: branch?.id,
        startTime: startTime?.minus({ hours: 1 }).toISO(),
        endTime: startTime?.plus({ hours: 4 }).toISO(),
      },
      ...locationState?.fullNote,
    }
  }, [locationState])

  const key = useMemo(() => sha256().update(JSON.stringify(newNote)).digest('hex'), [newNote])

  return (
    <NoteForm
      key={key}
      note={newNote}
      onCancel={() => {
        history.push({
          // eslint-disable-next-line prefer-regex-literals
          pathname: location.pathname.replace(new RegExp('/new$'), ''),
        })
      }}
      onSubmission={(note, action) => {
        if (action === 'create') {
          history.push({
            // eslint-disable-next-line prefer-regex-literals
            pathname: location.pathname.replace(new RegExp('/new$'), `/note/${note.id}`),
          })
        }
      }}
    />
  )
}

const TabDrawerContent = styled(DrawerContent)`
  ${DrawerContentSections.Header} {
    ${DrawerContentSections.HeaderCol} {
      padding: 0;
      position: relative;
      font-weight: normal;

      .ant-tabs-top > .ant-tabs-nav {
        position: absolute;
        top: 2px;

        margin: 0;

        &:before {
          border-bottom: 0px;
        }
      }
    }
  }
`

export const ScheduleEventNew: React.FC = () => {
  const location = useLocation<any>()
  const history = useHistory()

  const [locationState, setLocationState] = useState<any>()

  const FormContentLayout = useContentLayoutState()

  useEffect(() => {
    if (!location.state) return
    setLocationState(location.state)
    history.replace(omit(location, ['state']))
  }, [location.state])

  const defaultActiveKey = location?.state?.fullNote ? 'note' : 'order'
  const tabTitle = locationState?.tabTitle

  const showTab = (key: 'note' | 'order') => {
    if (!tabTitle) return true
    return defaultActiveKey === key
  }

  return (
    <TabDrawerContent>
      <Header>
        <ContentContext.Provider value={FormContentLayout}>
          <TabsStyled
            defaultActiveKey={defaultActiveKey}
            destroyInactiveTabPane
          >
            {showTab('order') && (
              <TabPane tab={tabTitle || 'New Order'} key="order">
                <FormContentLayout.Body.Source>
                  <OrderTab locationState={locationState} />
                </FormContentLayout.Body.Source>
              </TabPane>
            )}
            {showTab('note') && (
              <TabPane tab={tabTitle || 'New Note'} key="note">
                <FormContentLayout.Body.Source>
                  <NoteTab locationState={locationState} />
                </FormContentLayout.Body.Source>
              </TabPane>
            )}
          </TabsStyled>
        </ContentContext.Provider>
      </Header>

      <Actions>
        <FormContentLayout.Actions.Target />
        <CloseButton />
      </Actions>

      <Body>
        <FormContentLayout.Body.Target />
      </Body>

      <Footer>
        <FormContentLayout.Footer.Target />
      </Footer>
    </TabDrawerContent>
  )
}
