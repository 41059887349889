import { codesByState, statesByCode } from 'constants/states'

export const convertStateToAbbr = (input: string | undefined | null) => {
  if (input === undefined || input === null) return input
  const strInput = input.trim()
  if (strInput.length === 2) {
    const upStrInput = strInput.toUpperCase()
    return (statesByCode as any)[upStrInput] ? upStrInput : undefined
  }
  const strStateToFind = strInput.toLowerCase().replace(/\s+/g, '')
  const foundAbbr = (codesByState as any)[strStateToFind]
  return foundAbbr
}

export const convertAbbrToState = (input: string | undefined | null) => {
  if (input === undefined || input === null) return input
  const strInput = input.trim()
  if (strInput.length !== 2) {
    return (statesByCode as any)[convertStateToAbbr(strInput)]
  }
  const strStateToFind = strInput.toLowerCase().replace(/\s+/g, '')
  const foundFullName = (statesByCode as any)[strStateToFind]
  return foundFullName
}
