export const colors = {
  fontPrimary: 'rgba(0,0,0,0.85)',
  fontPrimaryNoAlpha: '#262626',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#f6f7f9',
  borderPrimary: '#e7eaee',
  emptyStatus: '#eeeeee',
  link: '#1890ff',
  error: '#af1313',
  dispatched: '#0f6aa6',
  greyscale100: '#000000',
  greyscale95: '#1f2225',
  greyscale85: '#25292d',
  greyscale80: '#2d3136',
  greyscale75: '#363b41',
  greyscale70: '#41474e',
  greyscale65: '#4f565f',
  greyscale60: '#606973',
  greyscale55: '#7b838c',
  greyscale50: '#9298a0',
  greyscale45: '#a5aab1',
  greyscale40: '#b4b9be',
  greyscale35: '#c1c5c9',
  greyscale30: '#cccfd2',
  greyscale25: '#d5d7da',
  greyscale20: '#dcdee0',
  greyscale15: '#e2e4e6',
  greyscale10: '#e9eaec',
  greyscale5: '#eff0f1',
  greyscale0: '#ffffff',
  ant: {
    placeholder: 'rgba(0, 0, 0, 0.25)',
    primary: '#1890ff',
  },
  performance: {
    onTime: '#17820d',
    late: '#c48612',
    veryLate: '#af1313',
  },
  editing: {
    fontPrimary: '#fff',
    backgroundPrimary: '#20A9CF',
    backgroundSecondary: '#2BBBE3',
    borderPrimary: '#20A9CF',
  },
}

export default colors
