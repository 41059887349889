import { FormListFieldData } from 'antd/lib/form/FormList'
import { AddItemButton, Button } from 'components/buttons'
import { Collapse, Panel } from 'components/common/Collapse'
import { Footnote } from 'components/common/Footnote'
import { YardFormItem } from 'components/form/common/YardFormList/YardFormItem'
import { Form, FormListProps, useCurrentForm } from 'components/form/Form'
import { groupBy } from 'lodash'
import { getNamePath } from 'rc-field-form/es/utils/valueUtil'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface YardFormListOptions {
  editing?: boolean
}

interface YardsFormListProps extends Omit<FormListProps, 'name' | 'children'>, YardFormListOptions {
  name?: FormListProps['name']
}

const InactiveSection = styled(({ children, className, show }: React.PropsWithChildren<{ className?: string, show?: boolean }>) => {
  const [showInactive, setShowInactive] = useState(show || false)
  const showToggle = show !== true

  useEffect(() => {
    if (show !== undefined) {
      setShowInactive(show)
    }
  }, [show])

  return (
    <>
      <Collapse ghost activeKey={showInactive ? 'inactive' : undefined} className={className}>
        <Panel key="inactive" forceRender hideHeader noGutter>
          {children}
        </Panel>
      </Collapse>
      {showToggle && (
        <div style={{ textAlign: 'center', marginTop: 3 }}>
          <Button
            type="plain"
            size="small"
            onClick={() => setShowInactive((last) => !last)}
          >
            {showInactive ? 'Hide' : 'Show'} inactive
          </Button>
        </div>
      )}
    </>
  )
})`
  > .ant-collapse-item {
    margin-top: 0;


    > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
`

export const YardsFormList = (props: YardsFormListProps) => {
  const {
    name: nameProp,
    editing,
    ...args
  } = props

  const name = nameProp || 'yards'

  return (
    <Form.List
      name={name}
      {...args}
    >
      {(...listProps) => <FormListFields name={name} editing={editing} listProps={listProps} />}
    </Form.List>
  )
}

interface FormListFieldsProps extends YardFormListOptions {
  name: FormListProps['name']
  listProps: Parameters<FormListProps['children']>
}

const FormListFields = (props: FormListFieldsProps) => {
  const path = getNamePath(props.name)
  const form = useCurrentForm()

  const { editing, listProps } = props
  const [fields, { add, remove }, { errors }] = listProps

  const addYard = () => {
    add({
      _isNew: true,
      active: true,
    })
  }

  useEffect(() => {
    if (fields.length === 0) {
      addYard()
    }
  }, [fields])

  const sorted = fields.sort((a, b) => {
    const aName = form.getFieldValue([...path, a.key, 'name'])
    const bName = form.getFieldValue([...path, b.key, 'name'])
    return aName <= bName ? -1 : 1
  })

  const byActiveStatus = groupBy(sorted, (field) => form.getFieldValue([...path, field.key, 'active']) !== false)

  const renderFormItem = (field: FormListFieldData, index: number) => (
    <YardFormItem
      key={field.key}
      listPrefix={field}
      name={[...path, field.key]}
      onRemove={() => remove(index)}
      editing={editing}
    />
  )

  return (
    <>
      <Form.ErrorList errors={errors} />

      {byActiveStatus.true?.map(renderFormItem)}

      {byActiveStatus.false?.length && (
        <InactiveSection show={byActiveStatus.true === undefined ? true : undefined}>
          {byActiveStatus.false.map(renderFormItem)}
        </InactiveSection>
      )}

      {
        fields.length === 0 &&
        <Footnote block>Each Branch must have at least one yard</Footnote>
      }

      {editing && (
        <AddItemButton
          type="default"
          size="small"
          onClick={addYard}
          style={{ marginTop: 20 }}
        >
          Add New Yard
        </AddItemButton>
      )}
    </>
  )
}
