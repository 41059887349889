import { Duration } from 'luxon'

export const formatDuration = (duration: Duration) => {
  const shifted = duration.shiftTo('hours', 'minutes')
  const asObject = shifted.toObject()

  if (asObject.hours) {
    const time = shifted.toFormat('h:mm')
    const plural = time === '1:00' ? '' : 's'
    return `${shifted.toFormat('h:mm')} hour${plural}`
  }
  if (asObject.minutes !== undefined) {
    const time = shifted.toFormat('m')
    const plural = time === '1' ? '' : 's'
    return `${shifted.toFormat('m')} minute${plural}`
  }
  return null
}
