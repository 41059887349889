import { gql, useQuery } from '@apollo/client'
import { Col, Row, Typography } from 'antd'
import { Button } from 'components/buttons'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import { API_URL } from 'constants/environment'
import { useNotification } from 'hooks/useNotification'
import { useSession } from 'hooks/useSession'
import { useEffect } from 'react'
import {
  Link, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom'
import { Query } from 'schema'
import qs from 'utils/qs'

const { Paragraph } = Typography

export const QuickbooksSettings = () => {
  const session = useSession()
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const notification = useNotification()
  const { data, loading, error } = useQuery<Query>(GET_QUICKBOOKS_SETTINGS)

  const queryString = qs.parse(location.search)

  const config = data?.integrations?.quickbooks || {}
  const enabled = config?.settings?.enabled

  useEffect(() => {
    if (!queryString.message) return
    const { message, ...newQuery } = queryString
    notification.success({ message })
    history.push({ search: qs.stringify(newQuery) })
  }, [queryString.message])

  useEffect(() => {
    if (!queryString.error) return
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { error, ...newQuery } = queryString
    notification.error({ message: error })
    history.push({ search: qs.stringify(newQuery) })
  }, [queryString.error])

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }
  if (loading) {
    return <Loading />
  }

  return (
    <Row style={{ padding: '40px' }}>
      <Col span={24}>
        <Typography.Title level={3}>QuickBooks Online Integration</Typography.Title>

        <Paragraph>
          Here you can configure automated syncing between CreteSuite and QuickBooks Online.
        </Paragraph>
      </Col>
      {!enabled && (
        <Col span={24}>
          <Paragraph>
            Your CreteSuite portal is not currently connected to QuickBooks Online. Click the button below to configure this integration.
          </Paragraph>

          <a onClick={() => {
            const currentUrl = window.location.href
            const authUrl = new URL(`${API_URL}/oauth/inuit/authorize`)
            authUrl.searchParams.append('redirect', currentUrl)
            authUrl.searchParams.append('jwt', session.token || '')
            window.location.href = authUrl.toString()
          }}
          >
            <img src="https://static.developer.intuit.com/images/C2QB_auth.png" style={{ width: '175px' }} />
          </a>
        </Col>
      )}
      {enabled && (
        <Col span={24}>
          <Paragraph>
            Your CreteSuite portal is connected to your QuickBooks account: <strong>{config.name}</strong>
          </Paragraph>

          <Typography.Title level={5}>Manage Customer Mapping</Typography.Title>
          <Paragraph>
            You must match CreteSuite Customers to QuickBooks Customers in order for Invoices to be created.
          </Paragraph>

          <Link to={`${match.url}/customers`}>
            <Button type="primary"> Manage Customers </Button>
          </Link>

          <Typography.Title level={5} style={{ marginTop: '30px' }}>Manage Item Mapping</Typography.Title>
          <Paragraph>
            You must match CreteSuite reported metrics to QuickBooks Items in order for Line Items on Invoices to be created.
          </Paragraph>

          <Link to={`${match.url}/items`}>
            <Button type="primary"> Manage Items </Button>
          </Link>
        </Col>
      )}
    </Row>
  )
}

const GET_QUICKBOOKS_SETTINGS = gql`
  query GetQuickbooksSettings {
    integrations {
      quickbooks {
        name
        realmId
        settings {
          enabled
          syncCustomers
          syncInvoices
        }
      }
    }
  }
`
