import { gql } from '@apollo/client'
import { ADDRESS_FRAGMENT } from './addresses'
import { PHONE_FRAGMENT } from './phones'

export const CUSTOMERS_FRAGMENT = gql`
  fragment CustomerFields on Customer {
    id
    name
    name2
    abbreviation
    notes
    emails
    active
    createdAt
    updatedAt
    phones {
      ...PhoneFields
    }
    physicalAddress {
      ...AddressFields
    }
    billingSameAsPhysical
    billingAddress {
      ...AddressFields
    }
  }
  ${ADDRESS_FRAGMENT}
  ${PHONE_FRAGMENT}
`

export const GET_CUSTOMERS = gql`
  query GetCustomer(
    $orderBy: [QueryCustomersOrderByInput!],
    $where: QueryCustomersWhereInput
  ) {
    customers(
      orderBy: $orderBy,
      where: $where
    ) {
      ...CustomerFields
    }
  }
  ${CUSTOMERS_FRAGMENT}
`

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $data: CustomerInput!
  ) {
    createOneCustomer(
      data: $data
    ) {
      ...CustomerFields
    }
  }
  ${CUSTOMERS_FRAGMENT}
`

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $data: CustomerUpdateInput!
    $where: CustomerWhereUniqueInput!
  ) {
    updateOneCustomer(
      data: $data
      where: $where
    ) {
      ...CustomerFields
    }
  }
  ${CUSTOMERS_FRAGMENT}
`
