import { PickerProps } from 'antd/lib/date-picker/generatePicker'
import { compact, isNil } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import {
  DatePicker, DatePickerCustomProps, DatePickerProps, DatePickerRef,
} from './DatePicker'

type DatePickerStringProps =
  DatePickerCustomProps &
  Omit<PickerProps<string>, 'onChange' | 'picker'> &
  {
    onChange?: (date: string | null) => void
    displayFormat?: string | ((date: string) => string)
  }

export const DatePickerString = React.forwardRef<DatePickerRef, DatePickerStringProps>((props, ref) => {
  const {
    value,
    defaultPickerValue,
    defaultValue,
    format,
    displayFormat,
    onChange,
    onSelect,
    disabledDate,
    dateRender,
    monthCellRender,
    onPanelChange,
    onOk,
    ...rest
  } = props

  const args: DatePickerProps = { ...rest }

  args.format = compact([displayFormat, format]).flat().map((formatter) => (typeof formatter === 'function' ? (val: any) => formatter(val.toISOString()) : formatter))
  args.value = useMemo(() => (isNil(value) ? value : moment(value)), [value])
  args.defaultValue = useMemo(() => (isNil(defaultValue) ? defaultValue : moment(defaultValue)), [value])
  args.defaultPickerValue = useMemo(() => (isNil(defaultPickerValue) ? defaultPickerValue : moment(defaultPickerValue)), [value])

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const formatMomentToString = (value: moment.Moment) => {
    if (!value.isValid()) return ''
    const firstFormat = [props.format].flat()[0]
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const format = typeof firstFormat === 'function' ? firstFormat(value.toISOString()) : firstFormat
    return props.format ? value.format(format) : value.toISOString()
  }

  if (onChange) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    args.onChange = (value, dateString) => {
      onChange(value === null ? null : formatMomentToString(value))
    }
  }

  if (onSelect) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    args.onSelect = (value) => {
      onSelect(formatMomentToString(value))
    }
  }

  if (disabledDate) {
    args.disabledDate = (date) => disabledDate(formatMomentToString(date))
  }
  if (dateRender) {
    args.dateRender = (current, today) => dateRender(formatMomentToString(current), formatMomentToString(today))
  }
  if (monthCellRender) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    args.monthCellRender = (date, ...rest) => monthCellRender(formatMomentToString(date), ...rest)
  }

  if (onPanelChange) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    args.onPanelChange = (date, ...rest) => onPanelChange(formatMomentToString(date), ...rest)
  }

  if (onOk) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    args.onOk = (date, ...rest) => onOk(formatMomentToString(date), ...rest)
  }

  return <DatePicker {...args} ref={ref} />
})

export default DatePickerString
