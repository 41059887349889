import { SetNonNullable } from 'types'

import { useMemo } from 'react'

import { dateToSlug } from 'helpers/url'
import { compact } from 'lodash'
import styled from 'styled-components'
import { DateTime } from 'utils/luxon'

import { useRoutesEditMode } from 'components/scheduler/EditModeState'
import { useBranch } from 'hooks/useBranch'
import { useHistory } from 'react-router'

import { EventProps, EVENT_WRAP_EDIT_MODE_CSS } from 'components/scheduler/events/Card'
import { Route, RouteWaypoint } from 'schema'

import { OrderEvent } from 'components/scheduler/events/OrderEvent'
import { TravelSegment } from './TravelSegment'
import { WaypointMarker } from './WaypointMarker'

const CARET_DURATION_ADJUST = 250000

const RouteEventWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: default;

  > * {
    cursor: pointer;
  }

  ${EVENT_WRAP_EDIT_MODE_CSS}
`

export interface RouteEventProps extends EventProps {
  route: Route
}

export const RouteEvent = (props: RouteEventProps) => {
  if (!props.event.start || !props.event.end) {
    return null
  }
  return RouteEventWithTimes(props as RouteEventWithTimesProps)
}

interface RouteEventWithTimesProps extends Omit<RouteEventProps, 'event'> {
  event: SetNonNullable<RouteEventProps['event'], 'start' | 'end'>
}

const RouteEventWithTimes = (props: RouteEventWithTimesProps) => {
  const { route, ...rest } = props
  const history = useHistory()
  const branch = useBranch()
  const routesEditMode = useRoutesEditMode()

  const waypoints = route.waypoints || []

  const steps: Array<{
    key: React.Key,
    duration: number,
    durationStart: number,
    durationEnd: number,
    waypoint?: RouteWaypoint
    travel?: {
      startWaypoint: RouteWaypoint
      endWaypoint: RouteWaypoint
    }
  }> = useMemo(() => {
    const stepsFilled = compact(waypoints.map((waypoint, index) => {
      let arrivalTime = waypoint.scheduledArrivalTime ? DateTime.fromISO(waypoint.scheduledArrivalTime) : null
      let departureTime = waypoint.scheduledDepartureTime ? DateTime.fromISO(waypoint.scheduledDepartureTime) : null

      if (!arrivalTime) {
        arrivalTime = departureTime
      }
      if (!departureTime) {
        departureTime = arrivalTime
      }

      if (
        arrivalTime === null ||
        departureTime === null
      ) {
        return null
      }

      return {
        waypoint,
        arrivalTime,
        departureTime,
        duration: departureTime.diff(arrivalTime).as('milliseconds'),
      }
    }))

    const withTravelSegments = compact(stepsFilled.flatMap((step, index) => {
      const nextStep = stepsFilled[index + 1]
      const travelDuration = nextStep ? nextStep.arrivalTime.diff(step.departureTime).as('milliseconds') : 0

      const waypointStep = {
        duration: step.duration,
        waypoint: step.waypoint,
      }

      if (travelDuration <= 0) {
        return [waypointStep]
      }

      return [
        waypointStep,
        {
          travel: {
            startWaypoint: step.waypoint,
            endWaypoint: nextStep.waypoint,
          },
          duration: travelDuration,
        },
      ]
    }))

    let durationAgg = 0

    return withTravelSegments.flatMap((step, index) => {
      const durationStart = durationAgg
      durationAgg += step.duration

      return {
        ...step,
        key: index,
        durationStart,
        durationEnd: durationStart + step.duration,
      }
    })
  }, [waypoints])

  const eventTimes = useMemo(() => {
    const viewStartTime = DateTime.fromJSDate(props.view.activeStart)
    const viewEndTime = DateTime.fromJSDate(props.view.activeEnd)

    const eventStartTime = DateTime.fromJSDate(props.event.start)
    const eventEndTime = DateTime.fromJSDate(props.event.end)

    let startDuration = DateTime.max(viewStartTime, eventStartTime).diff(eventStartTime).as('milliseconds')
    const endDuration = DateTime.min(viewEndTime, eventEndTime).diff(eventStartTime).as('milliseconds')

    if (eventStartTime < viewStartTime) {
      startDuration += CARET_DURATION_ADJUST
    }

    return {
      eventStartTime,
      startDuration,
      endDuration,
      duration: endDuration - startDuration,
    }
  }, [props.view.activeStart, props.view.activeEnd, props.event.start, props.event.end])

  const hasStarted = props.event.start.getTime() < Date.now() || waypoints.some((wp) => wp?.actualArrivalTime || wp?.actualDepartureTime)

  const routeOnClick = () => {
    if (routesEditMode.enabled) return
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    history.push(`/branches/${branch!.id}/schedule/${dateToSlug(props.selectedDate)}/route/${route?.id}`)
  }

  const editModeClass = `editing-${routesEditMode.status(route.id) || 'off'}`
  const onWrapperClick = () => {
    routesEditMode.toggleSelected(route.id)
  }

  return (
    <RouteEventWrap
      className={editModeClass}
      onClick={onWrapperClick}
    >
      {
        steps.map((step) => {
          const startOutOfView = step.durationStart < eventTimes.startDuration || step.durationStart > eventTimes.endDuration
          const endOutOfView = step.durationEnd < eventTimes.startDuration || step.durationEnd > eventTimes.endDuration
          const spansAllDay = step.durationStart < eventTimes.startDuration && eventTimes.endDuration < step.durationEnd

          if (startOutOfView && endOutOfView && !spansAllDay) {
            return null
          }

          const trimmedStart = Math.max(step.durationStart, eventTimes.startDuration)
          const trimmedEnd = Math.min(step.durationEnd, eventTimes.endDuration)
          const duration = trimmedEnd - trimmedStart
          const widthPercent = (duration / (eventTimes.duration || 1)) * 100

          if (step.travel) {
            return (
              <div key={step.key} style={{ flex: `1 1 ${widthPercent}%` }}>
                <TravelSegment
                  routeStarted={hasStarted}
                  onClick={routeOnClick}
                  startWaypoint={step.travel.startWaypoint}
                  endWaypoint={step.travel.endWaypoint}
                />
              </div>
            )
          }

          if (step.waypoint?.order) {
            return (
              <div key={step.key} style={{ flex: `0 0 ${widthPercent}%`, minWidth: 0, maxWidth: `${widthPercent}%` }}>
                <OrderEvent startOutOfView={startOutOfView} endOutOfView={endOutOfView} {...{ ...rest, order: step?.waypoint?.order }} />
              </div>
            )
          }

          if (step.waypoint) {
            return (
              <div key={step.key} style={{ flex: `0 0 ${widthPercent}%`, minWidth: 0, zIndex: 3 }}>
                <WaypointMarker
                  routeStarted={hasStarted}
                  onClick={routeOnClick}
                  waypoint={step.waypoint}
                  dispatched={Boolean(route.dispatched)}
                />
              </div>
            )
          }

          return null
        })
      }
    </RouteEventWrap>
  )
}
