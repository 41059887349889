import { Popover as AntPopover } from 'antd'
import { PopoverProps as AntdPopoverProps } from 'antd/lib/popover'
import { compact } from 'lodash'

export type PopoverProps = AntdPopoverProps & {
  hideArrow?: boolean
  noContentPadding?: boolean
}

export const Popover: React.FC<PopoverProps> = (props) => {
  const { hideArrow, noContentPadding, ...args } = props

  if (hideArrow) {
    args.overlayClassName = compact([args.overlayClassName, 'ant-popover-hide-arrow']).join(' ')
  }

  if (noContentPadding) {
    args.overlayClassName = compact([args.overlayClassName, 'ant-popover-no-content-padding']).join(' ')
  }

  return <AntPopover {...args} />
}
