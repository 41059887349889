import colors from 'constants/colors'
import { compact } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import {
  Mutation,
  MutationCreateNoteArgs,
  MutationUpdateNoteArgs, Note, NoteInput,
} from 'schema'
import { RecursivePartial } from 'types'

import { BaseMutationOptions, useMutation } from '@apollo/client'
import { CREATE_NOTE, UPDATE_NOTE } from 'gql/notes'

import { EditOutlined } from '@ant-design/icons'
import {
  Button, Col, InputNumber, Row, Typography,
} from 'antd'

import { Comment } from 'components/common/Comment'
import { ErrorMessage } from 'components/ErrorMessage'
import { Form } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'

import { Input } from 'components/form/inline-edit/Input'
import { TextArea } from 'components/form/inline-edit/TextArea'
import { ContactSelectWithAdd } from 'components/form/reference-selects/ContactSelectWithAdd'
import { CustomerSelectWithAdd } from 'components/form/reference-selects/CustomerSelectWithAdd'
import { EquipmentSelect } from 'components/form/reference-selects/EquipmentSelect'
import { useBranch } from 'hooks/useBranch'
import { Footer } from 'hooks/useContentLayout'
import { StartTimeInput } from '../OrderForm/common/StartTimeInput'

const { Paragraph } = Typography

export interface NoteFormProps {
  note?: RecursivePartial<Note>
  editing?: boolean
  onSubmission?: (note: Note, action: 'update' | 'create') => void
  onCancel?: () => void
}

const Label = styled.span`
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 12px;
  color: ${colors.greyscale50};
`

const FormBody = styled.div`
  > * {
    margin-bottom: 15px;
  }
`

const TitleInput = styled(Input)`
  &, input {
    font-size: 1.1rem;
    font-weight: 600;
  }
`

interface NoteFormValues extends Omit<RecursivePartial<Note>, 'event'> {
  event: Omit<Note['event'], 'startTime' | 'endTime'> & {
    startTime?: moment.Moment
    endTime?: moment.Moment
  }
}

const noteToFormValues = (note: RecursivePartial<Note>): NoteFormValues => ({
  ...note,
  comments: [...(note?.comments || []), {}],
  event: {
    ...note?.event,
    startTime: note?.event?.startTime ? moment(note?.event?.startTime) : undefined,
    endTime: note?.event?.endTime ? moment(note?.event?.endTime) : undefined,
  },
})

export const NoteForm: React.FC<NoteFormProps> = (props) => {
  const { note, onSubmission } = props
  const id = note?.id
  const isNew = id === undefined
  const branch = useBranch()
  const [editing, setEditing] = useState<boolean>(props.editing || isNew)
  const noteFormValues = useMemo(() => noteToFormValues(note || {}), [note])
  const [form] = Form.useForm<NoteFormValues>()

  useEffect(() => {
    form.setFieldsValue(noteFormValues)
  }, [noteFormValues])

  const mutationOptions: BaseMutationOptions<Mutation, any> = {
    refetchQueries: ['GetEvents', 'GetNote'],
  }

  const [
    createNote,
    createNoteResponse,
  ] = useMutation<Mutation, MutationCreateNoteArgs>(CREATE_NOTE, mutationOptions)

  const [
    updateNote,
    updateNoteResponse,
  ] = useMutation<Mutation, MutationUpdateNoteArgs>(UPDATE_NOTE, mutationOptions)

  const { error: mutationError } = isNew ? createNoteResponse : updateNoteResponse

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.setFieldsValue(noteFormValues as any)
  }

  const onValuesChange = (changedValues: NoteFormValues, _allValues: NoteFormValues) => {
    if ('customerId' in changedValues) {
      form.setFieldsValue({
        contactId: null,
      })
    }
  }

  const onFinish = async (values: NoteFormValues) => {
    const { comments, ...rest } = values
    const newComments = (comments || []).filter((comment) => !comment.id && comment.text)

    const data: NoteInput = {
      ...rest,
      comments: {
        create: newComments.map((comment) => ({
          text: comment.text,
        })),
      },
    } as any

    Object.entries(data).forEach(([k, v]) => {
      if (v === undefined) {
        (data as any)[k] = null
      }
    })

    if (id) {
      const resp = await updateNote({
        variables: { data, where: { id } },
      })

      if (resp.errors) return

      setEditing(false)

      const updatedNote = resp?.data?.updateNote

      if (updatedNote && onSubmission) {
        onSubmission(updatedNote, 'update')
      }
    } else {
      const resp = await createNote({
        variables: { data },
      })

      if (resp.errors) return

      setEditing(false)

      const newNote = resp?.data?.createNote

      if (newNote && onSubmission) {
        onSubmission(newNote, 'create')
      }
    }
  }

  if (!isNew && !note) {
    return (
      <ErrorMessage>
        <Paragraph>Note #{id} does not exist</Paragraph>
      </ErrorMessage>
    )
  }

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="note-form"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        requiredMark={editing}
        initialValues={noteFormValues}
        layout="vertical"
        labelCol={{
          style: {},
        }}
      >
        <FormBody>
          <FormItem
            name="title"
            rules={[
              {
                required: true,
                message: 'Title is required',
              },
            ]}
          >
            <TitleInput
              bordered={false}
              placeholder="Short title for note"
              editing={editing}
              suffix={editing ? <Label><EditOutlined /></Label> : null}
            />
          </FormItem>

          <Row wrap={false} gutter={[16, 16]} align="middle">
            <Col>
              <Label>Equipment</Label>
            </Col>
            <Col flex="auto">
              <FormItem
                noStyle
                name="equipmentId"
              >
                <EquipmentSelect
                  allowClear
                  bordered={false}
                  style={{ width: '100%' }}
                  editing={editing}
                  resources={compact([note?.equipment])}
                />
              </FormItem>
            </Col>
          </Row>

          <Row wrap={false} gutter={[16, 16]}>
            <Col span={12}>
              <FormItem
                style={{ marginBottom: 0 }}
                label={<Label>Date Start</Label>}
                name={['event', 'startTime']}
              >
                <StartTimeInput
                  branchTimezone={branch?.timezone}
                  bordered={false}
                  style={{ width: '100%' }}
                  editing={editing}
                  placeholder="Select Start Time"
                  allowClear={false}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                style={{ marginBottom: 0 }}
                label={<Label>Date End</Label>}
                name={['event', 'endTime']}
              >
                <StartTimeInput
                  branchTimezone={branch?.timezone}
                  bordered={false}
                  style={{ width: '100%' }}
                  editing={editing}
                  placeholder="Select End Time"
                  allowClear={false}
                />
              </FormItem>
            </Col>
          </Row>
          <Row wrap={false} gutter={[16, 16]}>
            <Col span={12}>
              <FormItem
                label={<Label>Customer</Label>}
                name="customerId"
              >
                <CustomerSelectWithAdd
                  bordered={false}
                  allowClear
                  placeholder="Relate to Customer"
                  style={{ width: '100%' }}
                  editing={editing && !note?.customer}
                  resources={compact([note?.customer])}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => prev.customerId !== next.customerId}
              >
                {() => (
                  <FormItem
                    label={<Label>Contact</Label>}
                    name="contactId"
                  >
                    <ContactSelectWithAdd
                      bordered={false}
                      allowClear
                      placeholder="Relate to Contact"
                      style={{ width: '100%' }}
                      editing={editing && !note?.contact}
                      customerId={form.getFieldValue('customerId')}
                      resources={note?.customerId === form.getFieldValue('customerId') ? compact([note?.contact]) : []}
                    />
                  </FormItem>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Label style={{ display: 'block' }}>Notes</Label>

          <div>
            <Form.List
              name="comments"
            >
              {(fields) => (
                fields.map((field, index) => (
                  (index < (fields.length - 1)) ? (
                    <Comment
                      key={index}
                      comment={form.getFieldValue(['comments', index])}
                      style={{
                        padding: '0 12px',
                      }}
                    />
                  )
                    : (
                      <FormItem
                        key={index}
                        noStyle
                        listPrefix={field}
                        name="text"
                        hidden={!editing}
                      >
                        <TextArea
                          autoSize={{ minRows: 2, maxRows: 20 }}
                          placeholder="Add comments / notes here"
                        />
                      </FormItem>
                    )
                ))
              )}
            </Form.List>
          </div>

          <Form.Item name={['event', 'branchId']} hidden>
            <InputNumber />
          </Form.Item>
        </FormBody>
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}
