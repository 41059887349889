import { TimePicker, TimePickerProps, TimePickerRef } from 'components/form/inline-edit/TimePicker'
import moment from 'moment'
import React, { useMemo } from 'react'

export interface DurationPickerProps extends Omit<TimePickerProps,
  'allowClear' | 'showNow' | 'defaultValue' | 'value' | 'onChange' | 'onSelect' |
  'format' | 'defaultPickerValue' | 'disabledDate' | 'monthCellRender' |
  'disabledHours' | 'disabledMinutes' | 'disabledSeconds' | 'locale' | 'use12Hours'
> {
  allowClear?: boolean | 'zero-out'
  value?: number
  defaultValue?: number
  maxDuration?: number,
  unit?: moment.DurationInputArg2
  onChange?: (value: number | undefined) => void
  onSelect?: (value: number | undefined) => void
}

const baseTime = moment(0).utcOffset(0)

const rebaseTime = (time: moment.Moment): moment.Moment => baseTime.clone().add(time.clone().hours(), 'hours').add(time.clone().minutes(), 'minutes').add(time.clone().seconds(), 'seconds')

const sequenceArray = (length: number) => Array(length).fill(true).map((_, i) => i)

export const DurationPicker = React.forwardRef<TimePickerRef, DurationPickerProps>((props, ref) => {
  const newArgs = useMemo(() => {
    const {
      defaultValue,
      value,
      maxDuration,
      onChange,
      onSelect,
      onOpenChange,
      unit: unitConst,
      allowClear,
      ...rest
    } = props

    let unit = unitConst
    if (!unit) {
      unit = 'minutes'
    }

    const args: TimePickerProps = {
      showHour: true,
      showMinute: true,
      showSecond: false,
      hideDisabledOptions: true,
      ...rest,
      showNow: false,
      allowClear: allowClear !== false,
    }

    const format = [
      args.showHour ? 'H' : undefined,
      args.showMinute ? 'mm' : undefined,
      args.showSecond ? 'ss' : undefined,
    ].filter(Boolean).join(':')
    args.format = format

    args.value = value !== undefined ? baseTime.clone().add(value, unit) : undefined
    args.defaultValue = args.defaultValue !== undefined ? baseTime.clone().add(defaultValue, unit) : undefined

    if (maxDuration) {
      const maxParts = baseTime.clone().add(maxDuration, unit)

      args.disabledHours = () => sequenceArray(24).filter((i) => i > maxParts.hours())
      args.disabledMinutes = (hour) => {
        if (hour === maxParts.hours()) {
          return sequenceArray(60).filter((i) => i > maxParts.minutes())
        }
        return []
      }
      args.disabledSeconds = (hour, minute) => {
        if (hour === maxParts.hours() && minute === maxParts.minutes()) {
          return sequenceArray(60).filter((i) => i > maxParts.seconds())
        }
        return []
      }
    }

    const processValue = (time: moment.Moment | null) => {
      if (time === null && allowClear === 'zero-out') {
        return 0
      }
      return time ? rebaseTime(time).diff(baseTime, unit) : undefined
    }

    if (onChange) {
      args.onChange = (time) => {
        onChange(processValue(time))
      }
    }

    if (onSelect) {
      args.onSelect = (time) => {
        onSelect(processValue(time))
      }
    }

    args.onBlur = (elem: any) => {
      const newValue = elem.target.value ? rebaseTime(moment(elem.target.value, format)) : undefined

      if (newValue && newValue.isValid()) {
        if (args.onChange) {
          args.onChange(newValue, elem.target.value)
        }
        if (args.onSelect) {
          args.onSelect(newValue)
        }
      }
    }

    return args
  }, [props])

  return <TimePicker data-lpignore="true" {...newArgs} ref={ref} />
})

export default TimePicker
