import colors from 'constants/colors'
import { compact, isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Mutation, MutationDispatchRouteArgs, Route } from 'schema'

import { WarningOutlined } from '@ant-design/icons'
import { LoadingIcon } from 'components/icons'

import {
  Popconfirm,
  Tag,
  TagProps,
} from 'antd'

import { useMutation } from '@apollo/client'
import { DISPATCH_ROUTE } from 'gql/routes'
import { timeWithZone } from 'helpers/datetime'
import { useBranch } from 'hooks/useBranch'
import { useNotification } from 'hooks/useNotification'
import { DateTime } from 'luxon'
import { Tooltip } from './Tooltip'

interface DispatchButtonProps extends TagProps {
  dispatched?: boolean
  dispatchedAt?: DateTime
  loading?: boolean
}

export const DispatchButton = styled((props: DispatchButtonProps) => {
  const {
    dispatched, dispatchedAt, loading, ...rest
  } = props

  const args: TagProps = rest

  if (dispatched) {
    args.color = colors.dispatched
    args.className = compact([args.className, 'dispatched']).join(' ')
    args.onClick = undefined
  }

  if (loading) {
    args.icon = <LoadingIcon />
  }

  return (
    <Tooltip
      noWrap
      placement="bottom"
      enabled={Boolean(dispatchedAt)}
      mouseEnterDelay={0.4}
      title={dispatchedAt && `Dispatched at ${dispatchedAt?.toFormat('M/d h:mm a')}`}
    >
      <Tag {...args}>
        {dispatched ? 'Dispatched' : 'Dispatch'}
      </Tag>
    </Tooltip>
  )
})`
  &:not(.dispatched) {
    cursor: pointer;
  }

  &.dispatched:hover {
    opacity: 100;

    &:after {
      display: none;
    }
  }
`

export const RouteDispatchButton = (props: { route: Route }) => {
  const { route } = props
  const branch = useBranch()
  const notification = useNotification()

  const [dispatchRoute, { loading, error }] = useMutation<Mutation, MutationDispatchRouteArgs>(DISPATCH_ROUTE, {
    variables: {
      where: {
        id: route.id,
      },
    },
  })

  if (error) {
    notification.error({
      message: 'Error Dispatching Route',
      description: error.message,
      duration: 5,
    })
  }

  const EnsureAtLeastOneAssignmentWrapper = useCallback(({ children }: React.PropsWithChildren<unknown>) => (
    isEmpty(route.assignments) ? (
      <Popconfirm
        placement="bottomRight"
        title={(
          <>
            This route has no assigned operators.<br />
            Are you sure you want to continue?
          </>
        )}
        icon={<WarningOutlined style={{ color: `var(--ant-error-color, ${colors.error}` }} />}
        onConfirm={() => { dispatchRoute() }}
        okText="Continue"
        cancelText="Cancel"
      >
        {children}
      </Popconfirm>
    )
      : (
        <span onClick={() => dispatchRoute()}>
          {children}
        </span>
      )
  ), [route.assignments, dispatchRoute])

  return (
    <EnsureAtLeastOneAssignmentWrapper>
      <DispatchButton
        dispatched={route.dispatched || false}
        dispatchedAt={timeWithZone(route.dispatchedAt, branch?.timezone)}
        loading={loading}
      />
    </EnsureAtLeastOneAssignmentWrapper>
  )
}
