import colors from 'constants/colors'
import { useState } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'
import { CUSTOMERS_FRAGMENT, GET_CUSTOMERS } from 'gql/customers'
import {
  Customer, Mutation, MutationMergeCustomersArgs, Query, QueryCustomersArgs,
} from 'schema'

import { ForkOutlined } from '@ant-design/icons'
import { Modal, ModalProps, Typography } from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'
import { CustomerSelect, defaults as customerSelectDefaults } from 'components/form/reference-selects/CustomerSelect'
import { defaultOptionsGenerator } from 'components/form/reference-selects/ReferenceSelect'
import { Loading } from 'components/Loading'

const { Text } = Typography

export interface MergeCustomerModalProps extends ModalProps {
  customerId: Customer['id']
}

export const MergeCustomerModal = (props: MergeCustomerModalProps) => {
  const { customerId, ...modalInputProps } = props

  const [selectedCustomerId, setSelectedCustomerId] = useState<Customer['id']>()

  const { data, error: queryError, loading: queryLoading } = useQuery<Query, QueryCustomersArgs>(
    GET_CUSTOMERS,
    {
      fetchPolicy: 'cache-first',
      variables: { where: { id: { equals: customerId } } },
    }
  )

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [mergeCustomers, { loading: mutationLoading, error: mutationError }] = useMutation<Mutation, MutationMergeCustomersArgs>(MERGE_CUSTOMERS, {
    refetchQueries: ['GetCustomers'],
  })

  const customer = (data?.customers || [])[0] as Customer | undefined

  const modalProps: ModalProps = {
    title: <><ForkOutlined rotate={-180} style={{ color: colors.editing.backgroundPrimary, marginRight: 5 }} /> Merge Customer</ >,
    cancelText: 'Cancel',
    okText: 'Merge Customer',
    width: '85%',
    ...modalInputProps,
    confirmLoading: mutationLoading,
    style: {
      ...modalInputProps?.style,
      minWidth: '300px',
      maxWidth: '700px',
    },
    okButtonProps: {
      disabled: selectedCustomerId === undefined,
      loading: mutationLoading,
    },
    destroyOnClose: true,
    onOk: async (evt) => {
      if (!selectedCustomerId) return false

      await mergeCustomers({
        variables: {
          customers: [
            { id: customerId },
            { id: selectedCustomerId },
          ],
        },
      })

      if (props.onOk) {
        return props.onOk(evt)
      }

      return true
    },
  }

  return (
    <Modal {...modalProps}>
      {queryLoading ?
        <Loading style={{ fontSize: '1.2rem' }} />
        : (queryError || !customer) ? (
          <ErrorMessage
            title="Error fetching customer"
            size="small"
            style={{ marginTop: 20 }}
          >
            {queryError?.message}
          </ErrorMessage>
        )
          : (
            <div style={{ lineHeight: '1.7em' }}>
              Select a customer below to merge with <Text strong>{customer?.name}</Text>.

              <CustomerSelect
                allowClear
                showExternalLink
                style={{ width: '450px', maxWidth: '100%', margin: '20px 0' }}
                optionsGenerator={(customers) => {
                  const customersWithoutCurrent = customers.filter((selectableCustomer) => selectableCustomer.id !== customer.id)
                  const generator = customerSelectDefaults.optionsGenerator || defaultOptionsGenerator
                  return generator(customersWithoutCurrent)
                }}
                onClear={() => {
                  setSelectedCustomerId(undefined)
                }}
                onSelect={(val: any) => {
                  setSelectedCustomerId(val)
                }}
              />

              <Text>
                <Text strong>Please double check before proceeding.</Text><br />
                All Contacts, Notes, Orders, and Sites will be moved to <Text strong>{customer?.name}</Text>.<br />
                <Text strong type="danger">This action cannot be undone.</Text>
              </Text>
            </div>
          )}
    </Modal>
  )
}

export const MERGE_CUSTOMERS = gql`
  mutation UpdateCustomer(
    $customers: [CustomerWhereUniqueInput!]!
  ) {
    mergeCustomers(customers: $customers) {
      deleted
      customer {
        ...CustomerFields
      }
    }
  }
  ${CUSTOMERS_FRAGMENT}
`
