import { gql } from '@apollo/client'
import { ContactDrawer } from 'components/drawers/ContactDrawer'
import {
  buildPropsFromGql, OptionsType, ReferenceSelect, ReferenceSelectProps,
} from 'components/form/reference-selects/ReferenceSelect'
import { ViewMoreDrawer } from 'components/layout/ViewMoreDrawer'
import { TAG_SLIM_FRAGMENT } from 'gql/tags'
import { byFieldAsc } from 'helpers/sortting'
import { compact, isNumber, isString } from 'lodash'
import React, { useMemo } from 'react'
import { Contact, Tag } from 'schema'
import { RecursivePartial } from 'types'
import { LabelWithTags } from './components/LabelWithTags'

export type ContactSelectProps = ReferenceSelectProps<Contact> & {
  customerId?: number
}

const GET_SELECT_CONTACTS = gql`
  query GetContacts($where: QueryContactsWhereInput) {
    contacts(where: $where) {
      id
      firstName
      lastName
      customers {
        id
      }
      tags {
        ...TagFields
      }
    }
  }

  ${TAG_SLIM_FRAGMENT}
`

const contactOptionGenerator = (contact?: RecursivePartial<Contact> | null) => {
  if (!contact?.id) return null

  const name = compact([contact?.firstName, contact?.lastName]).join(' ')
  if (!name) return

  return {
    name,
    label: <LabelWithTags tags={contact.tags as Tag[]}>
      {name}
    </LabelWithTags>,
    value: contact.id,
  }
}

const defaults: Partial<ContactSelectProps> = {
  showSearch: true,
  generateExternalLink: (contactId) => {
    const id = isNumber(contactId) ? contactId : (isString(contactId) ? parseInt(contactId) : undefined)

    return (
      <ViewMoreDrawer
        buttonStyle={{ opacity: id ? 1 : 0, visibility: id ? 'visible' : 'hidden' }}
        closeIcon={false}
      >
        <ContactDrawer key={id} id={id} />
      </ViewMoreDrawer>
    )
  },
}

export const ContactSelect: React.FC<ContactSelectProps> = (inputProps) => {
  const { customerId, ...props } = inputProps

  const resourcesById = Object.fromEntries((props.resources || []).map((c) => [c.id, c]))

  const optionsGenerator = useMemo<typeof inputProps['optionsGenerator']>(() => (
    (contacts) => (
      compact(
        contacts.filter((contact) => (
          contact.id && // quick check so that we can assert OptionsType below
          (
            resourcesById[contact.id] !== undefined || // was passed in via resources={}
            contact?.customers?.some((customer) => customer.id === customerId) // else is customer id
          )
        )).map(contactOptionGenerator)
      ).sort(byFieldAsc('name')) as OptionsType
    )
  ), [customerId, resourcesById])

  if (!customerId && (props?.resources || []).length === 0) {
    props.disabled = true
    props.placeholder = 'Select Customer First'
  }

  return ReferenceSelect(buildPropsFromGql({
    name: 'Contact',
    queryField: 'contacts',
    gql: GET_SELECT_CONTACTS,
    defaults: {
      ...defaults,
      showExternalLink: !props.mode,
      optionsGenerator,
    },
  }, props))
}

export default ContactSelect
