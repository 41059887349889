import { gql } from '@apollo/client'

export const PHONE_FRAGMENT = gql`
  fragment PhoneFields on Phone {
    id
    number
    type
    sms
    active
  }
`
