import {
  Query,
  QueryRoutesArgs,
} from 'schema'

import { useQuery } from '@apollo/client'
import { GET_ROUTES } from 'gql/routes'
import { useParams } from 'react-router-dom'

import { ErrorMessage } from 'components/ErrorMessage'
import { Loading } from 'components/Loading'

import { RouteForm } from 'components/form/RouteForm'
import { RouteActionsMenu } from 'components/menus/RouteActionsMenu'
import { CloseButton } from 'hooks/useClosable'
import {
  Actions, Body, ContentContext, Footer, Header, useContentLayoutState,
} from 'hooks/useContentLayout'
import { DrawerContent } from 'layouts/Content/DrawerContent'

export const ScheduleRouteShow = () => {
  const { routeId } = useParams<{ routeId?: string }>()
  const id = routeId ? parseInt(routeId) : undefined

  const FormContentLayout = useContentLayoutState()

  if (!id) {
    return <ErrorMessage>Must pass route id</ErrorMessage>
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const response = useQuery<Query, QueryRoutesArgs>(GET_ROUTES, {
    fetchPolicy: 'network-only',
    skip: !id,
    variables: {
      where: {
        id: {
          equals: id,
        },
      },
    },
  })

  if (!id) {
    return <ErrorMessage>Must pass route id</ErrorMessage>
  }

  if (response.loading) {
    return <Loading />
  }

  const route = response.data?.routes[0]

  if (!route) {
    return <ErrorMessage>Route #{id} not found</ErrorMessage>
  }

  return (
    <DrawerContent>
      <Header>
        <FormContentLayout.Header.Target />
      </Header>

      <Actions>
        <div style={{ margin: '0 10px' }}>
          <FormContentLayout.Actions.Target />
        </div>
        <RouteActionsMenu route={route} />
        <CloseButton />
      </Actions>

      <Body>
        <ContentContext.Provider value={FormContentLayout}>
          <RouteForm key={`route-${id}`} route={route} />
        </ContentContext.Provider>
      </Body>

      <Footer>
        <FormContentLayout.Footer.Target />
      </Footer>
    </DrawerContent>
  )
}
