import colors from 'constants/colors'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { formatDuration } from 'helpers/formatDuration'
import { RouteWaypoint } from 'schema'
import { DateTime, Duration } from 'utils/luxon'

const formatPerformanceDuration = (duration: Duration) => {
  const asMillis = duration.valueOf()
  const absMillis = Math.abs(asMillis)
  if (absMillis <= 60000) {
    return 'On Time'
  }

  const absDuration = Duration.fromMillis(absMillis)
  const durationPart = formatDuration(absDuration)
  if (!durationPart) {
    return null
  }

  const earlyOrLate = asMillis > 0 ? 'late' : 'early'
  return `${durationPart} ${earlyOrLate}`
}

export interface RoutePerformanceLabelProps extends React.HTMLProps<HTMLElement> {
  scheduledTime: RouteWaypoint['scheduledArrivalTime']
  actualTime: RouteWaypoint['actualArrivalTime']
  performance?: RouteWaypoint['arrivalPerformance']
  wrappingElement?: React.ComponentType<PropsWithChildren<any>>
}

export const RoutePerformanceLabel = styled(({
  scheduledTime, actualTime, performance, wrappingElement, ...rest
}: RoutePerformanceLabelProps) => {
  if (!scheduledTime || !actualTime) return null

  const difference = DateTime.fromISO(actualTime).diff(DateTime.fromISO(scheduledTime))
  const durationText = formatPerformanceDuration(difference)

  if (!durationText) return null

  const color = performance ? colors.performance[performance] : colors.greyscale65

  const Wrapper: typeof wrappingElement = wrappingElement || (({ children }) => children)
  return (
    <span
      {...rest}
      style={{ ...rest.style, color }}
    >
      <Wrapper>
        {durationText}
      </Wrapper>
    </span>
  )
})`
  font-weight: bold;
`
