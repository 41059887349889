import { AspectRatioBox } from 'components/common/AspectRatioBox'
import { Logo } from 'components/common/Logo'
import { CollapsibleSider } from 'components/layout/Sider'
import { PrimaryMenu } from './PrimaryMenu'

export const PrimarySider: typeof CollapsibleSider = (props) => (
  <CollapsibleSider {...props}>
    <AspectRatioBox ratio={0.66}>
      <Logo />
    </AspectRatioBox>

    <PrimaryMenu />
  </CollapsibleSider>
)
