import { LinkOutlined } from '@ant-design/icons'
import { Col, Row, Select } from 'antd'
import { RefSelectProps } from 'antd/lib/select'
import colors from 'constants/colors'
import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import styled from 'styled-components'

const DROPDOWN_HEIGHT = 380

const DropdownWrapper = styled.div`
  min-height: ${DROPDOWN_HEIGHT}px;
  width: 100%;
  position: relative;

  .ant-select-dropdown {
    min-height: ${DROPDOWN_HEIGHT}px;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    top: 0 !important;
    z-index: 0;
  }
`

const AlwaysOpenSelect = styled(Select)`
  &.ant-select-open .ant-select-selection-item {
    color: ${colors.fontPrimary};
  }
`

const MatchedIcon = (props: Parameters<typeof LinkOutlined>[0]) => <LinkOutlined {...props} style={{ color: '#168a15', ...props.style }} />

export interface ListSelectorOption {
  label: string
  value: string | number
  matched?: boolean
}

export interface ListSelectorProps {
  mode: 'single' | 'multiple'
  options: Array<ListSelectorOption>
  selected?: Array<ListSelectorOption>
  onChange?: (options: Array<ListSelectorOption>) => void
}

export const ListSelector = (props: ListSelectorProps) => {
  const {
    mode,
    options,
    selected: selectedInput,
    onChange,
  } = props

  const dropdownRef = useRef<HTMLDivElement>(null)
  const selectRef = useRef<RefSelectProps>(null)

  const [search, setSearch] = useState<string>('')
  const [selected, setSelected] = useState<NonNullable<ListSelectorProps['selected']>>(selectedInput || [])

  useEffect(() => {
    if (selectedInput) {
      setSelected(selectedInput)
    }
  }, [selectedInput])

  const onSelect = (option: ListSelectorOption) => {
    setSearch('')

    if (selectRef.current) {
      selectRef.current.blur()
    }

    setSelected((prev) => {
      const newValue = mode === 'single' ? [option] : [...prev, option]

      if (onChange) {
        onChange(newValue)
        return prev
      }
      return newValue
    })
  }

  const onClear = () => {
    setSearch('')

    if (selectRef.current) {
      selectRef.current.blur()
    }

    setSelected((prev) => {
      if (onChange) {
        onChange([])
        return prev
      }
      return []
    })
  }

  const searchLowerCase = search.toLowerCase()
  const searchLength = searchLowerCase.length

  const optionsWithHighlights = useMemo(() => options.map((option) => {
    const highlightIndex = option.label.toLowerCase().indexOf(searchLowerCase)

    return {
      search: option.label.toLowerCase(),
      value: option.value,
      rawlabel: option.label,
      label: <Row gutter={10}>
        <Col flex="auto">
          {highlightIndex > -1 ? (
            <>
              {option.label.slice(0, highlightIndex)}
              <strong>{option.label.slice(highlightIndex, highlightIndex + searchLength)}</strong>
              {option.label.slice(highlightIndex + searchLength)}
            </>
          ) : option.label}
        </Col>
        {option.matched &&
          <Col><MatchedIcon /></Col>}
      </Row>,
    }
  }), [options, search])

  return (
    <>
      <AlwaysOpenSelect
        showSearch
        allowClear
        ref={selectRef}
        open
        style={{ width: '100%' }}
        listHeight={DROPDOWN_HEIGHT}
        placeholder="Search for record"
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getPopupContainer={() => dropdownRef.current!}
        searchValue={search === '' ? undefined : search}
        value={selected.map((item) => item?.label)}
        onSearch={setSearch}
        onClear={onClear}
        onSelect={(_value, option) => {
          onSelect({
            label: option.rawlabel,
            value: option.value,
            matched: option.matched,
          })
        }}
        filterOption={(input, option) => {
          if (option?.search) {
            return option.search.includes(input.toLowerCase())
          }
          return false
        }}
        options={optionsWithHighlights}
      />
      <DropdownWrapper ref={dropdownRef} />
    </>
  )
}
