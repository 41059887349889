import React, { useState } from 'react'

import {
  Mutation,
  MutationCreateSupplierArgs,
  MutationUpdateSupplierArgs, Phone, Query,
  QuerySuppliersArgs, Supplier, SupplierInput,
} from 'schema'

import { useMutation, useQuery } from '@apollo/client'
import { CREATE_SUPPLIER, GET_SUPPLIERS_FULL, UPDATE_SUPPLIER } from 'gql/suppliers'

import {
  Button,
  Col,
  Row,
  Typography,
} from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'

import { Form } from 'components/form/Form'
import { Input } from 'components/form/inline-edit/Input'
import SwitchInput from 'components/form/inline-edit/Switch'
import { Loading } from 'components/Loading'
import { Footer, Header } from 'hooks/useContentLayout'
import { compact, uniq } from 'lodash'
import { PhonesFormList } from '../common/PhonesFormList'

const { Paragraph } = Typography

export interface SupplierFormProps {
  id?: number
  editing?: boolean
  onSubmission?: (user: Supplier, action: 'update' | 'create') => void
  onCancel?: () => void
}

export const SupplierForm: React.FC<SupplierFormProps> = (props) => {
  const { id, onSubmission } = props
  const isNew = id === undefined

  const [editing, setEditing] = useState<boolean>(props.editing || isNew)

  const { data, error: queryError, loading: queryLoading } = useQuery<Query, QuerySuppliersArgs>(
    GET_SUPPLIERS_FULL,
    {
      variables: {
        where: {
          id: {
            equals: id,
          },
        },
      },
      skip: isNew,
    }
  )

  const [
    createSupplier,
    createSupplierResponse,
  ] = useMutation<Mutation, MutationCreateSupplierArgs>(CREATE_SUPPLIER, {
    update: (cache) => {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'suppliers' })
    },
  })

  const [
    updateSupplier,
    updateSupplierResponse,
  ] = useMutation<Mutation, MutationUpdateSupplierArgs>(UPDATE_SUPPLIER)

  const { error: mutationError } = isNew ? createSupplierResponse : updateSupplierResponse

  const supplier = (data?.suppliers || [])[0] as Supplier | undefined

  const [form] = Form.useForm<Supplier>()

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data: SupplierInput = values

    data.phones = compact(
      compact<any>(values.phones || []).map(({ number, sms, type }: Phone) => {
        if (!number) return
        return { number, sms, type: type || 'primary' }
      })
    )

    if (id) {
      const resp = await updateSupplier({
        variables: { data, where: { id } },
      })

      if (resp.errors) return

      setEditing(false)

      const updatedSupplier = resp?.data?.updateSupplier

      if (updatedSupplier && onSubmission) {
        onSubmission(updatedSupplier, 'update')
      }
    } else {
      const resp = await createSupplier({
        variables: { data },
      })

      if (resp.errors) return

      setEditing(false)

      const newSupplier = resp?.data?.createSupplier

      if (newSupplier && onSubmission) {
        onSubmission(newSupplier, 'create')
      }
    }
  }

  if (queryError) {
    return (
      <ErrorMessage>
        <Paragraph>{queryError.message}</Paragraph>
      </ErrorMessage>
    )
  }

  if (!supplier && queryLoading) return <Loading />

  const hasMultiplePhones = (supplier?.phones.length || 0) > 1

  const fullName = () => (
    uniq(compact(Object.values(form.getFieldsValue(['name', 'name2'])))).join(' | ')
  )

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="supplier-form"
        onFinish={onFinish}
        requiredMark={editing}
        initialValues={{
          ...supplier,
          active: isNew ? true : supplier?.active,
        }}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) => (
            prev.name !== current.name ||
            prev.name2 !== current.name2
          )}
        >
          {() => (
            <Header hideIfOrphan>
              {fullName()}
            </Header>
          )}
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
          ]}
        >
          <Input placeholder="Supplier name" editing={editing} />
        </Form.Item>

        <Form.Item
          label="Additional Name"
          name="name2"
        >
          <Input placeholder="Additional descriptor, such as 'Orlando'" editing={editing} />
        </Form.Item>

        <PhonesFormList
          singleMode={!hasMultiplePhones}
          hideType={!hasMultiplePhones}
          editing={editing}
        />

        <Form.Item
          label="Active"
          name="active"
          valuePropName="checked"
        >
          <SwitchInput
            editing={editing}
          />
        </Form.Item>
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}
