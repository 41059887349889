import { cloneMoment } from 'helpers/cloneMoment'
import * as luxon from 'luxon'
import moment from 'moment'

declare module 'luxon' {
  interface DateTime {
    toMoment(): moment.Moment
  }
}

export const toMoment = (dateTime: luxon.DateTime): moment.Moment => {
  const value = moment(dateTime.toJSDate()).utcOffset(dateTime.offset)
  if (dateTime.zoneName) {
    value.zoneName = () => dateTime.zoneName
    return cloneMoment(value)
  }
  return value
}

// eslint-disable-next-line @typescript-eslint/no-shadow
export const fromMoment = (moment: moment.Moment): luxon.DateTime => {
  const asLuxon = luxon.DateTime.fromISO(moment.toISOString(true), { setZone: true })

  const zone = new luxon.IANAZone(moment.zoneName())
  if (zone.isValid) {
    return asLuxon.setZone(zone)
  }
  return asLuxon
}

// eslint-disable-next-line func-names
luxon.DateTime.prototype.toMoment = function () {
  return toMoment(this)
}

export * from 'luxon'
export default luxon
