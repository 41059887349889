import { compact } from 'lodash'
import { useSamsaraSettings } from './useSamsaraSettings'

const SAMSARA_DAHSBOARD_URL = 'https://cloud.samsara.com'

export const useSamsaraUrl = (): (path?: string) => string | null => {
  const { data: settings } = useSamsaraSettings()
  const id = settings?.organizationId

  if (!settings?.enabled || !id) {
    return () => null
  }

  const url = `${SAMSARA_DAHSBOARD_URL}/o/${id}`

  return (path?: string) => {
    const pathCleaned = path?.replace(/^\/+/, '')
    return compact([url, pathCleaned]).join('/')
  }
}
