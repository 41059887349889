import React, { useEffect } from 'react'
import {
  Route as ReactRouterRoute,
  RouteProps,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'

import { AutoUpdater } from 'AutoUpdater'
import { SelectedBranchProvider } from 'hooks/useBranch'
import { CurrentUserProvider } from 'hooks/useCurrentUser'
import { EquipmentSortProvider } from 'hooks/useEquipmentSort'
import { SessionProvider, useSession } from 'hooks/useSession'
import { SupportWidgetProvider } from 'hooks/useSupportWidget'
import { ViewportProvider } from 'hooks/useViewportMode'
import { BranchRoutes } from 'router/BranchRoutes'

import { useOrganization } from 'hooks/useOrganization'

import Layout from 'layouts/Layout'
import AuthPage from 'pages/Auth'
import ContactsPage from 'pages/Contacts'
import CustomersPage from 'pages/Customers'
import HomePage from 'pages/Home'
import InsightsPage from 'pages/Insights'
import InvoicesPage from 'pages/Invoices'
import OrdersPage from 'pages/Orders'
import SchedulePage from 'pages/Schedule'
import SettingsPage from 'pages/Settings'
import TeamPage from 'pages/Team'
import Redirects from 'redirects'

import 'assets/styles/App.css'
import { NetworkAlert } from 'components/common/NetworkAlert'
import { BranchesProvider } from 'hooks/useBranches'
import { ScheduleLayout } from 'layouts/Layout/ScheduleLayout'

import { ApolloProvider } from './apollo'

const Route = (props: RouteProps & { layout?: React.ComponentType }) => {
  const { layout, children, ...args } = props
  const LayoutComponent = layout || Layout

  return (
    <ReactRouterRoute {...args}>
      <LayoutComponent>
        {children}
      </LayoutComponent>
    </ReactRouterRoute>
  )
}

const AuthenticatedRoutes = () => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const organization = useOrganization()

  return (
    <Switch>
      <Route path="/branches/:branchId" layout={React.Fragment}>
        <BranchRoutes>
          <Route path="/schedule/:date?" layout={ScheduleLayout}>
            <SchedulePage />
          </Route>
        </BranchRoutes>
      </Route>
      <Route path="/orders">
        <OrdersPage />
      </Route>
      <Route path="/invoices">
        <InvoicesPage />
      </Route>
      <Route path="/insights">
        <InsightsPage />
      </Route>
      <Route path="/contacts">
        <ContactsPage />
      </Route>
      <Route path="/customers">
        <CustomersPage />
      </Route>
      <Route path="/team">
        <TeamPage />
      </Route>
      <Route path="/settings">
        <SettingsPage />
      </Route>
      <Route path="/auth/logout">
        <AuthPage />
      </Route>
      <Route path="/" exact>
        <HomePage />
      </Route>
      <Redirects />
      <Route>
        <HomePage />
      </Route>
    </Switch>
  )
}

const Routes = () => {
  const session = useSession()

  if (!session.loggedIn()) {
    return <AuthPage />
  }

  return (
    <BranchesProvider>
      <SelectedBranchProvider>
        <EquipmentSortProvider>
          <NetworkAlert />
          <AuthenticatedRoutes />
        </EquipmentSortProvider>
      </SelectedBranchProvider>
    </BranchesProvider>
  )
}

const App = () => {
  useEffect(() => {
    if (window?.navigator?.platform?.startsWith('Win')) {
      document.body.classList.add('os-windows')
    }
  }, [])

  return (
    <ViewportProvider>
      <Router>
        <LastLocationProvider>
          <AutoUpdater>
            <ApolloProvider>
              <SessionProvider>
                <CurrentUserProvider>
                  <SupportWidgetProvider>
                    <Routes />
                  </SupportWidgetProvider>
                </CurrentUserProvider>
              </SessionProvider>
            </ApolloProvider>
          </AutoUpdater>
        </LastLocationProvider>
      </Router>
    </ViewportProvider>
  )
}

export default App
