import styled from 'styled-components'

import { gql, useQuery } from '@apollo/client'
import { Query, QueryInsightsUrlArgs } from 'schema'

import { Tabs as AntdTabs } from 'antd'
import Loading from 'components/Loading'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect } from 'react'

const IframeWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;

  iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    z-index: 0;
  }
`

const Tabs = styled(AntdTabs)`
  width: 100%;
  height: 100%;

  .ant-tabs-nav-list {
    padding-left: 30px;
  }

  .ant-tabs-content, .ant-tabs-tabpane {
    width: 100%;
    height: 100%;
  }
`

const INSIGHTS_DASHBOARDS_QUERY = gql`
  query GetInsightsDashboards {
    insightsDashboards {
      slug
      title
      url
      expires
    }
  }
`

export const InsightsPage = (_args: any) => {
  const { data, refetch, error } = useQuery<Query, QueryInsightsUrlArgs>(INSIGHTS_DASHBOARDS_QUERY)
  const dashboards = data?.insightsDashboards || []
  const loading = isEmpty(dashboards)

  useEffect(() => {
    if (isEmpty(dashboards || [])) return

    const allExpires = dashboards.map((dashboard) => DateTime.fromISO(dashboard.expires))
    const firstExpires = DateTime.min(...allExpires)

    const timeout = setTimeout(() => {
      refetch()
    }, firstExpires.toMillis() - Date.now())

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [dashboards])

  if (error) {
    return (
      <div style={{ padding: 30 }}>
        An error occurred generating the dashboard: {error.message}
      </div>
    )
  }

  if (loading) {
    return (
      <div style={{ padding: 30 }}>
        <Loading />
      </div>
    )
  }

  return (
    <Tabs
      defaultActiveKey="0"
      size="large"
    >
      {dashboards.map((dashboard, i) => (
        <Tabs.TabPane tab={dashboard.title} key={i}>
          <IframeWrap>
            <iframe
              src={`${dashboard.url}#bordered=false&titled=false&hide_parameters=flat_rate&`}
            />
          </IframeWrap>
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}

export default InsightsPage
