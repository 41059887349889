import { FormListFieldData } from 'antd/lib/form/FormList'
import { AddItemButton, Button } from 'components/buttons'
import { Collapse, Panel } from 'components/common/Collapse'
import { Footnote } from 'components/common/Footnote'
import { DocumentFormItem } from 'components/form/common/DocumentFormList/DocumentFormItem'
import { Form, FormListProps, useCurrentForm } from 'components/form/Form'
import { byAsc } from 'helpers/sortting'
import { groupBy } from 'lodash'
import { getNamePath } from 'rc-field-form/es/utils/valueUtil'
import React, { useMemo, useState } from 'react'
import { DocumentTypeScope } from 'schema'
import styled from 'styled-components'
import { SetRequired } from 'type-fest'

interface DocumentFormListOptions {
  scope: DocumentTypeScope
  editing?: boolean
}

interface DocumentsFormListProps extends Omit<FormListProps, 'name' | 'children'>, DocumentFormListOptions {
  name?: FormListProps['name']
}

const InactiveSection = styled(({ children, className }: React.PropsWithChildren<{ className?: string }>) => {
  const [showInactive, setShowInactive] = useState(false)

  return (
    <>
      <Collapse ghost activeKey={showInactive ? 'inactive' : undefined} className={className}>
        <Panel key="inactive" forceRender hideHeader noGutter>
          {children}
        </Panel>
      </Collapse>
      <div style={{ textAlign: 'center', marginTop: 3 }}>
        <Button
          type="plain"
          size="small"
          onClick={() => setShowInactive((last) => !last)}
        >
          {showInactive ? 'Hide' : 'Show'} inactive
        </Button>
      </div>
    </>
  )
})`
  > .ant-collapse-item {
    margin-top: 0;


    > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
`

const FormListFields = (props: SetRequired<DocumentsFormListProps, 'name'> & { listProps: Parameters<FormListProps['children']> }) => {
  const {
    name,
    editing,
    scope,
    listProps,
  } = props

  const path = getNamePath(name)
  const form = useCurrentForm()
  const [fields, { add, remove }, { errors }] = listProps

  const byActiveStatus = useMemo(() => {
    const sorted = fields.sort((a, b) => {
      const aType = form.getFieldValue([...path, a.name, 'typeDetails', 'name'])
      const bType = form.getFieldValue([...path, b.name, 'typeDetails', 'name'])
      return byAsc(aType, bType)
    })

    return groupBy(sorted, (field) => form.getFieldValue([...path, field.name, 'active']))
  }, [form, path, fields])

  const renderFormItem = (field: FormListFieldData) => (
    <DocumentFormItem
      key={field.key}
      listPrefix={field}
      name={[...path, field.name]}
      scope={scope}
      onRemove={() => remove(field.name)}
      editing={editing}
    />
  )

  return (
    <>
      <Form.ErrorList errors={errors} />

      {byActiveStatus.true?.map(renderFormItem)}

      {byActiveStatus.false?.length && (
        <InactiveSection>
          {byActiveStatus.false.map(renderFormItem)}
        </InactiveSection>
      )}

      {
        fields.length === 0 &&
        <Footnote block>No documents exist here yet.</Footnote>
      }

      {editing && (
        <AddItemButton
          type="default"
          size="small"
          onClick={() => add({
            _isNew: true,
            active: true,
          })}
          style={{ marginTop: 20 }}
        >
          Add New Document
        </AddItemButton>
      )}
    </>
  )
}

export const DocumentsFormList = (props: DocumentsFormListProps) => {
  const name = props.name || 'documents'
  const args = { ...props, name }

  return (
    <Form.List
      {...args}
    >
      {(...listProps) => <FormListFields {...args} listProps={listProps} />}
    </Form.List>
  )
}
