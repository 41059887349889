import { useQuery } from '@apollo/client'
import { Col, Divider, Row } from 'antd'
import { StatusBadge } from 'components/common/StatusBadge'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import { GET_ORDER_STATUSES } from 'gql/order-statuses'
import { bySortField } from 'helpers/sortting'
import { Query, QueryOrderStatusesArgs } from 'schema'

export const OrderStatusLegend = () => {
  const { data, loading, error } = useQuery<Query, QueryOrderStatusesArgs>(GET_ORDER_STATUSES, {
    fetchPolicy: 'cache-first',
    variables: {
      where: {
        active: {
          equals: true,
        },
      },
    },
  })

  return (
    <>
      <Divider> Status Legend </Divider>
      {loading && <Loading />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Row gutter={[20, 4]}>
        {[...(data?.orderStatuses || [])].sort(bySortField).map((status) => (
          <Col key={status.slug} style={{ flex: '0 1 33%', whiteSpace: 'nowrap' }}>
            <StatusBadge status={status} size="large" />
          </Col>
        ))}
      </Row>
    </>
  )
}
