import React, { useMemo, useState } from 'react'
import {
  Contact, ContactInput,
  ContactMutationsCreateResponse,
  ContactMutationsResponseWarnings,
  ContactMutationsUpdateResponse,
  MessagePreference, Mutation,
  MutationCreateContactArgs, MutationUpdateContactArgs,
  Query,
  QueryContactsArgs,
} from 'schema'

import { useMutation, useQuery } from '@apollo/client'
import { CREATE_CONTACT_V2, GET_CONTACTS_FULL, UPDATE_CONTACT_V2 } from 'gql/contacts'
import {
  compact, flatten, get as getPropertyAtPath, isEmpty, isNil, set as setPropertyAtPath,
} from 'lodash'

import {
  Button, Col, Collapse,
  FormProps, Row, Typography,
} from 'antd'

import { ErrorMessage } from 'components/ErrorMessage'

import { Loading } from 'components/Loading'
import { Panel } from 'components/common/Collapse/Panel'
import { FullName } from 'components/common/FullName'
import { PhonesFormList } from 'components/form//common/PhonesFormList'
import { Form } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'
import { Input } from 'components/form/inline-edit/Input'
import { Switch } from 'components/form/inline-edit/Switch'
import { ContactRoleSelect } from 'components/form/reference-selects/ContactRoleSelect'
import { CustomerSelect } from 'components/form/reference-selects/CustomerSelect'
import { GenericFormAction, GenericFormSubmit } from 'components/form/reference-selects/SelectWithAddForm'
import { Footer, Header } from 'hooks/useContentLayout'
import { RecursivePartial } from 'types'
import { ContactInvalidNotification } from '../../common/ContactInvalidNotification'
import { WithIconTooltip } from '../../common/WithIconTooltip'
import { EmailsFormList } from '../common/EmailsFormList'
import { Checkbox } from '../inline-edit/Checkbox'
import { TextArea } from '../inline-edit/TextArea'

const { Paragraph } = Typography

export interface ContactFormProps {
  id?: number
  editing?: boolean
  onSubmission?: GenericFormSubmit
  onCancel?: () => void
  customerId?: number
}

interface ContactFormValues extends RecursivePartial<Contact> {
  roleIds: number[]
  customerIds: number[]
}

const sortMessagePreferences = (preferences: MessagePreference[]) => {
  const sorted = preferences.slice().sort((a, b) => (a < b ? -1 : 1))

  const toExclude = sorted.map(({ path }) => path.split('/').slice(0, -1).join('/'))

  return sorted.filter(({ path }) => !toExclude.includes(path))
}

const shouldCloseForm = (w: ContactMutationsResponseWarnings | undefined) => {
  if (w?.landlinePhones?.length) return false
  if (w?.invalidPhones?.length) return false
  return true
}

export const ContactForm: React.FC<ContactFormProps> = (props) => {
  const { id, onSubmission } = props
  const isNew = id === undefined
  const [editing, setEditing] = useState<boolean>(props.editing || isNew)
  const [warnings, setWarnings] = useState<ContactMutationsResponseWarnings | undefined>(undefined)
  const presetCustomerId = props.customerId

  const where: any = {}

  if (id) {
    where.id = {
      equals: id,
    }
  }

  const { data, error: queryError, loading: queryLoading } = useQuery<Query, QueryContactsArgs>(
    GET_CONTACTS_FULL,
    {
      variables: { where },
      skip: isNew,
    }
  )

  const contact = (data?.contacts || [])[0] as Contact | undefined

  const initialValues: ContactFormValues = useMemo(() => ({
    ...contact,
    roleIds: compact(contact?.roles?.map((c) => c?.id)) || [],
    customerIds: compact(flatten([contact?.customers?.map((c) => c?.id), presetCustomerId])),
    active: isNew ? true : contact?.active,
    preferences: !contact?.preferences ? undefined : {
      messages: sortMessagePreferences(contact.preferences.messages),
    },
  }), [contact])

  const [
    createContact,
    createContactResponse,
  ] = useMutation<Mutation, MutationCreateContactArgs>(CREATE_CONTACT_V2)

  const [
    updateContact,
    updateContactResponse,
  ] = useMutation<Mutation, MutationUpdateContactArgs>(UPDATE_CONTACT_V2)

  // eslint-disable-next-line unused-imports/no-unused-vars
  const { loading: mutationLoading, error: mutationError } = isNew ? createContactResponse : updateContactResponse

  const [form] = Form.useForm<ContactFormValues>()

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.resetFields()
  }

  const onValuesChange: FormProps<ContactFormValues>['onValuesChange'] = (changedValues: RecursivePartial<ContactFormValues>, allValues) => {
    const messagesPath = ['preferences', 'messages']
    const messagePreferencesChange = getPropertyAtPath(changedValues, messagesPath) as NonNullable<ContactFormValues['preferences']>['messages']

    if (messagePreferencesChange && messagePreferencesChange.length > 0) {
      messagePreferencesChange.forEach((value, index) => {
        if ('optedIn' in value) {
          const isDefaultPath = [...messagesPath, index, 'isDefault']
          form.setFieldsValue(
            setPropertyAtPath(allValues, isDefaultPath, false)
          )
        }
      })
    }
  }

  const onFinish: FormProps<ContactFormValues>['onFinish'] = async (values) => {
    const {
      preferences, additionalIds, phones, ...rest
    } = values
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data: ContactInput = rest

    data.phones = compact(
      compact<any>(values.phones || []).map(({ number, type }) => {
        if (!number) return
        return { number, type }
      })
    )

    data.emails = compact(values.emails || [])

    if (preferences) {
      const explicitlySetMessages = (preferences.messages || []).filter(({ isDefault }) => !isDefault)

      data.preferences = {
        ...preferences,
        messages: {
          upsert: compact(
            explicitlySetMessages.map(({ path, optedIn }) => {
              if (path === undefined || optedIn === undefined || optedIn == null) return
              return { path, optedIn }
            })
          ),
        },
      }
    }

    let response: ContactMutationsCreateResponse | ContactMutationsUpdateResponse | undefined | null
    let action: GenericFormAction

    if (id) {
      action = 'update'
      const mutation = await updateContact({
        variables: { data, where: { id } },
      })

      if (mutation.errors) return

      response = mutation?.data?.contact?.update
    } else {
      action = 'create'
      const mutation = await createContact({
        variables: { data },
      })

      if (mutation.errors) return

      response = mutation?.data?.contact?.create
    }

    setEditing(false)

    if (isNil(response)) return

    setWarnings(response.warnings || undefined)

    if (!isNil(response.contact) && onSubmission) {
      onSubmission(response.contact, action, {
        shouldClose: shouldCloseForm(response.warnings || undefined),
      })
    }
  }

  const getValues = () => {
    const formVals = form.getFieldsValue()
    if (!isEmpty(formVals)) return formVals
    return contact || {}
  }

  if (queryError) {
    return (
      <ErrorMessage to="/contacts">
        <Paragraph>{queryError.message}</Paragraph>
      </ErrorMessage>
    )
  }

  if (queryLoading) return <Loading />

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      {warnings && (
        <ContactInvalidNotification warnings={warnings} onClose={() => { setWarnings(undefined) }} />
      )}
      <Form
        form={form}
        name="contact-form"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        requiredMark={editing}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) => (
            prev.firstName !== current.firstName ||
            prev.lastName !== current.lastName
          )}
        >
          {() => (
            <Header hideIfOrphan>
              <FullName {...getValues()} />
            </Header>
          )}
        </Form.Item>

        <FormItem
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'First name is required',
            },
          ]}
        >
          <Input placeholder="First name" editing={editing} />
        </FormItem>
        <FormItem
          label="Last Name"
          name="lastName"
          rules={compact([
            !isNew ? undefined : {
              required: true,
              message: 'Last name is required',
            },
          ])}
        >
          <Input placeholder="Last name" editing={editing} />
        </FormItem>
        <FormItem
          label="Title"
          name="title"
        >
          <Input placeholder="Contact's Title / Position" editing={editing} />
        </FormItem>

        <EmailsFormList
          editing={editing}
        />

        <PhonesFormList
          editing={editing}
          rules={compact([
            !isNew ? undefined : {
              message: 'At least one Phone is required',
              validator: (rule, items) => {
                let valid = false

                if (items.length === 1 && items[0] === undefined) {
                  valid = true
                } else {
                  valid = items.every((item: any) => item.number && item.type)
                }

                if (valid) {
                  return Promise.resolve()
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('At least one Phone is required')
              },
            },
          ])}
        />

        <FormItem
          label="Contact Roles"
          name="roleIds"
        >
          <ContactRoleSelect
            defaultActiveFirstOption={false}
            showSearch={false}
            editing={editing}
            mode="multiple"
            singlePerLine
            style={{
              width: '100%',
            }}
          />
        </FormItem>

        <FormItem
          label="Customers"
          name="customerIds"
          hidden={presetCustomerId !== undefined}
        >
          <CustomerSelect
            showSearch
            editing={editing}
            singlePerLine
            mode="multiple"
            style={{
              width: '100%',
            }}
          />
        </FormItem>

        <Form.Item
          label="Notes"
          name="notes"
        >
          <TextArea
            editing={editing}
            placeholder="Notes about this Contact"
            autoSize={{ minRows: 1 }}
          />
        </Form.Item>

        <FormItem
          label="Active"
          name="active"
          valuePropName="checked"
        >
          <Switch
            editing={editing}
          />
        </FormItem>

        {!isNew && (
          <Collapse defaultActiveKey={['notifications']} ghost>
            <Panel key="notifications" header="Notifications">

              <Row gutter={16} style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>
                <Col flex="auto">
                  Message Type
                </Col>
                <Col>
                  Enabled
                </Col>
              </Row>

              <Form.List name={['preferences', 'messages']}>
                {(fields, _, { errors }) => (
                  fields.map((field, index) => {
                    const messageName = form.getFieldValue(['preferences', 'messages', index, 'name'])
                    const messageDescription = form.getFieldValue(['preferences', 'messages', index, 'description'])

                    return (
                      <React.Fragment key={index}>
                        <FormItem
                          listPrefix={field}
                          name="path"
                          hidden
                        >
                          <Input />
                        </FormItem>
                        <FormItem
                          listPrefix={field}
                          name="isDefault"
                          valuePropName="checked"
                          hidden
                        >
                          <Checkbox />
                        </FormItem>
                        <Row gutter={16} style={{ marginBottom: '10px' }}>
                          <Col flex="auto">
                            <WithIconTooltip title={messageDescription}>
                              {messageName}
                            </WithIconTooltip>
                          </Col>
                          <Col>
                            <FormItem
                              noStyle
                              listPrefix={field}
                              name="optedIn"
                              valuePropName="checked"
                            >
                              <Switch
                                editing={editing}
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                  })
                )}
              </Form.List>
            </Panel>
          </Collapse>
        )}
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}
