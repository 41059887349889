import { Input as AntInput } from 'antd'
import { InputProps as AntInputProps } from 'antd/lib/input/Input'
import { compact } from 'lodash'
import React, { ComponentRef } from 'react'
import styled from 'styled-components'

export type InputRef = ComponentRef<typeof AntInput>

export interface InputProps extends AntInputProps {
  editing?: boolean
}

const StyledAntInput = styled(AntInput)`
  &.read-only,
  &.read-only input,
  &.ant-input-affix-wrapper.read-only,
  &.ant-input-affix-wrapper.read-only input
  {
    border-color: transparent;
    background-color: transparent;
    outline-color: transparent;
    box-shadow: none;
  }

  &.ant-input-borderless {
    padding: 0;
  }
`

export const Input = React.forwardRef<InputRef, InputProps>((props, ref) => {
  const { editing, ...args } = props
  if (editing === false) {
    args.className = compact([args.className, 'read-only']).join(' ')
    args.readOnly = true
  }
  if (args.autoComplete === undefined) {
    args.autoComplete = 'off'
  }
  return <StyledAntInput data-lpignore="true" ref={ref} {...args} />
})

export default Input
