import { useCreation } from 'ahooks'
import Form, { FormInstance, FormProps } from 'components/form/Form'
import { InputNumber } from 'components/form/inline-edit/InputNumber'
import { SuggestableDataGridRegistration } from 'helpers/DataGrid'
import { useNotification } from 'hooks/useNotification'
import { useValuesReducerRegistry, ValuesReducerHook } from 'hooks/useValuesReducerRegistry'
import {
  get, isEmpty, isEqual, isNil,
} from 'lodash'
import React, {
  PropsWithChildren, useContext, useEffect, useState,
} from 'react'
import { OrderUpdateInput } from 'schema'
import { useOrderBillingCalculator } from './calculator'
import {
  FullOrder, OrderBillingData, PartialOrder, useOrderBillingData,
} from './data'
import { OrderBillingUpdateOrderFunction, useOrderBillingUpdate } from './update'

type EditingSections = 'billable'

export type OrderBillingFieldRegister<ValueType> = SuggestableDataGridRegistration<ValueType> & {
  autofill?: boolean,
  value?: ValueType,
  onSave?: ValuesReducerHook<OrderUpdateInput, ValueType>
}

type OrderBillingFieldRegisterFunction = <ValueType>(args: OrderBillingFieldRegister<ValueType>) => void

export type OrderBillingState<
  Order extends PartialOrder = FullOrder
> = OrderBillingData<Order> & {
  form: FormInstance<Record<string, any>>
  placeholders: Record<string, any>
  updateOrder: OrderBillingUpdateOrderFunction
  autofillValues: (section: EditingSections) => boolean
  updateValues: (values: Record<string, any>) => void
  isEditing: (section: EditingSections) => boolean
  setEditing: (section: EditingSections, editing: boolean) => void
  register: OrderBillingFieldRegisterFunction
}

const OrderBillingContext = React.createContext<OrderBillingState>({} as any)

export const useOrderBillingState = <
  Order extends PartialOrder = FullOrder
>() => (
  useContext(OrderBillingContext) as OrderBillingState<Order>
)

type OrderBillingStateProviderProps = PropsWithChildren<{ orderId: number }>

export const OrderBillingStateProvider = (props: OrderBillingStateProviderProps) => (
  // Ensures fresh state on each order
  <OrderBillingStateProviderInner key={props.orderId} {...props} />
)

const OrderBillingStateProviderInner = ({ orderId, children }: OrderBillingStateProviderProps) => {
  const notification = useNotification()

  const data = useOrderBillingData(orderId)
  const { order, loading: queryLoading } = data
  const [updateOrder] = useOrderBillingUpdate(orderId)

  const loading = queryLoading && !order

  const [editingMap, setEditingMap] = useState<Partial<Record<EditingSections, boolean>>>({})
  const isEditing: OrderBillingState['isEditing'] = (key) => editingMap[key] === true
  const setEditing: OrderBillingState['setEditing'] = (key, val) => {
    setEditingMap((prev) => ({ ...prev, [key]: val }))
  }

  const dataGrid = useOrderBillingCalculator(order)
  const [placeholders, setPlaceholders] = useState(dataGrid.suggestions)

  const autofillFields = useCreation<Record<string, boolean>>(() => ({}), [])
  const onSaveRegistry = useValuesReducerRegistry<OrderUpdateInput>()

  const [form] = Form.useForm()

  const register: OrderBillingState['register'] = (args) => {
    const { name } = args
    const {
      onSave, autofill, value, ...dataGridRegister
    } = args
    dataGrid.register(dataGridRegister)
    autofillFields[name] = autofill || false

    if (value !== undefined) {
      form.setFieldsValue({ [name]: value })
    }
    if (onSave) {
      onSaveRegistry.register(name, onSave)
    } else {
      onSaveRegistry.unregister(name)
    }
  }

  const autofillValues = (section: EditingSections) => {
    const collect: Record<string, any> = {}

    const values = form.getFieldsValue()

    Object.entries(dataGrid.suggestions).forEach(([field, suggestion]) => {
      const fieldSection = field.split('|')[0]

      if (fieldSection !== section) return
      if (!autofillFields[field]) return
      if (suggestion === undefined) return
      const currentValue = get(values, field)

      if (isNil(currentValue) || currentValue === '') {
        collect[field] = suggestion
      }
    })

    if (isEmpty(collect)) {
      return false
    }

    onValuesChange(collect, values)
    return true
  }

  const billableEditing = isEditing('billable')
  useEffect(() => {
    if (!billableEditing) return
    autofillValues('billable')
  }, [billableEditing])

  const updateValues = (changedValues: any) => {
    form.setFieldsValue(dataGrid.data)
    onValuesChange(changedValues, form.getFieldsValue())
  }

  const onValuesChange: NonNullable<FormProps['onValuesChange']> = (changedValues, _allValues) => {
    const didUpdate = dataGrid.patch(changedValues)
    if (!didUpdate) return

    form.setFieldsValue(dataGrid.data)

    // TODO: this is inefficient, needs to be moved to more
    // isolated state with consumers near fields
    setPlaceholders((old) => {
      if (isEqual(old, dataGrid.suggestions)) return old
      return dataGrid.suggestions
    })
  }

  const onFinish = async (values: any) => {
    const update = onSaveRegistry.reduce(values, {
      revision: values.revision || order?.revision,
    })

    try {
      const updated = await updateOrder(update, { errorPolicy: 'all' })

      if (updated.errors) {
        throw new Error(updated.errors.map(({ message }) => message).join('\n'))
      }

      setEditingMap({})
    } catch (err: any) {
      notification.error({
        message: 'Error Saving Order',
        description: err.message,
        duration: 8,
      })
    }
  }

  useEffect(() => {
    const currentValues = form.getFieldsValue()
    onValuesChange(currentValues, currentValues)
  }, [order])

  const formProps: FormProps = {
    name: 'billingForm',
    form,
    onValuesChange,
    onFinish,
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
  const state: OrderBillingState = {
    ...data,
    loading,
    form,
    placeholders,
    isEditing,
    setEditing,
    autofillValues,
    register,
    updateOrder,
    updateValues,
  }

  return (
    <OrderBillingContext.Provider value={state}>
      <Form {...formProps} style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        {children}

        <Form.Item name={['revision']} hidden>
          <InputNumber />
        </Form.Item>
      </Form>
    </OrderBillingContext.Provider>
  )
}
