import {
  Button, Col, Popover, PopoverProps, Row,
} from 'antd'
import Color from 'color'
import { VerticallyCentered } from 'components/common/VerticallyCentered'
import { EyeDropperIcon } from 'components/icons'
import { compact } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { HexColorPicker as ReactColorfulHex } from 'react-colorful'
import styled from 'styled-components'
import Input from './Input'

const CurrentColor = styled(VerticallyCentered).attrs((props) => ({
  ...props,
  className: 'no-style',
} as typeof props))`
  width: 40px;
  height: 40px;
  text-align: center;

  box-shadow:0px 0px 0px 3px rgba(0, 0, 0, 0.3) inset;
  cursor: pointer;

  &.read-only {
    cursor: default;
  }

  &.no-color {
    box-shadow:0px 0px 0px 3px #999 inset;
    background-image: linear-gradient(45deg, #dadada 25%, transparent 25%), linear-gradient(-45deg, #dadada 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #dadada 75%), linear-gradient(-45deg, transparent 75%, #dadada 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  }
`

const ColorfulPicker = styled(ReactColorfulHex)`
  .react-colorful__saturation {
    border-radius: 2px 2px 0 0;
  }
  .react-colorful__hue {
    border-radius: 0 0 2px 2px;
  }
`

type ColorfulPickerProps = React.ComponentProps<typeof ColorfulPicker>

interface HexColorPickerBaseProps {
  value?: ColorfulPickerProps['color']
  onChange?: ColorfulPickerProps['onChange']
  editing?: boolean
  pickerConfig?: Omit<ColorfulPickerProps, 'color' | 'onChange'>
  popoverConfig?: PopoverProps
}

interface HexColorPickerProps extends
  HexColorPickerBaseProps,
  Omit<React.HTMLAttributes<HTMLDivElement>, keyof HexColorPickerBaseProps> {
}

const hexClean = (val: string | undefined) => val?.replace(/[^a-f0-9]/ig, '').slice(0, 6)

export const HexColorPicker = (props: HexColorPickerProps) => {
  const {
    value: color,
    onChange,
    editing,
    pickerConfig,
    popoverConfig,
    ...rest
  } = props
  const [visible, setVisible] = useState<boolean>(false)

  const [pickerColorState, setPickerColor] = useState<string | undefined>(undefined)

  const pickerColor = pickerColorState || '#1890ff'

  useEffect(() => { setPickerColor(color) }, [color])

  const popupContent = (
    <div style={{ width: pickerConfig?.style?.width || '200px' }}>
      <ColorfulPicker
        {...pickerConfig}
        color={pickerColor}
        onChange={setPickerColor}
      />
      <Row style={{ marginTop: 10 }} align="stretch" gutter={15} wrap={false}>
        <Col flex="auto">
          <Input
            value={`#${hexClean(pickerColor)}`}
            onChange={(evt) => {
              setPickerColor(`#${hexClean(evt.target?.value)}`)
            }}
          />
        </Col>
        <Col>
          <Button
            type="primary"
            size="small"
            style={{ height: '100%', minWidth: '50px' }}
            onClick={() => {
              if (onChange) {
                onChange(pickerColor || '')
              }
              setVisible(false)
            }}
          >OK
          </Button>
        </Col>
      </Row>
    </div>
  )

  const eyeDropperColor = useMemo(() => {
    if (!color) return '#000'
    return Color(color).isLight() ? '#000' : '#fff'
  }, [color])

  return (
    <Popover
      trigger="click"
      {...popoverConfig}
      visible={visible}
      onVisibleChange={(isVisible) => {
        if (isVisible && editing) {
          setVisible(isVisible)
        } else {
          setVisible(false)
        }
      }}
      content={popupContent}
    >
      <CurrentColor
        {...rest}
        className={
          compact([
            !color ? 'no-color' : undefined,
            !editing ? 'read-only' : undefined,
            rest.className,
          ]).join(' ')
        }
        style={{
          ...(rest.style || {}),
          backgroundColor: color,
        }}
      >
        <EyeDropperIcon style={{ color: eyeDropperColor, opacity: editing ? 0.95 : 0 }} />
      </CurrentColor>
    </Popover>
  )
}
