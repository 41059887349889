import React from 'react'
import styled from 'styled-components'

import { Col, Row } from 'antd'
import { ContentContext, useContentLayoutState } from 'hooks/useContentLayout'
import { useIsMobile } from 'hooks/useViewportMode'

const Wrapper = styled.div.attrs((props) => ({
  isMobile: false as boolean,
  ...props,
}))`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px 30px;

  ${({ isMobile }) => isMobile && `
    ${Header} {
      padding-left: 15px;
    }
  `}
`

const Header = styled.div`
  width: 100%;
`

const Footer = styled.div`
  width: 100%;
`

const Body = styled.div`
  width: 100%;
  overflow: auto;
`

const ActionsCol = styled(Col)`
  display: flex;
  align-items: center;

  &:empty {
    display: none;
  }

  > * {
    padding: 10px 3px;
    margin-left: 4px;
    margin-right: 4px;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
`

const HeaderCol = styled(Col)`
  flex: 1 0 140px;
  padding: 10px 0;
  font-size: 1.2rem;
  font-weight: 600;

  &:not(:empty) + ${ActionsCol} {
    min-height: 48px;
  }
`

export const PageContentSections = {
  Header,
  HeaderCol,
  ActionsCol,
  Body,
  Footer,
}

export type PageContentProps = React.ComponentProps<typeof Wrapper> & {
  components?: Partial<Record<keyof typeof PageContentSections, React.FC>>
}

export const PageContent = ({ children, components, ...props }: PageContentProps) => {
  const isMobile = useIsMobile()

  const layoutContext = useContentLayoutState()

  const {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Header, Body, Footer, Actions,
  } = layoutContext

  const Components = { ...PageContentSections, ...(components || {}) }

  return (
    <ContentContext.Provider value={layoutContext}>
      <Wrapper {...props} isMobile={isMobile}>
        <Components.Header>
          <Row gutter={12} align="top">
            <Header.Target as={Components.HeaderCol} />
            <Actions.Target as={Components.ActionsCol} />
          </Row>
        </Components.Header>
        <Body.Target as={Components.Body} />
        <Footer.Target as={Components.Footer} />
      </Wrapper>
      {children}
    </ContentContext.Provider>
  )
}
