import { Col, Popconfirm, Row } from 'antd'
import { RemoveItemButton } from 'components/buttons'
import { Collapse, Panel } from 'components/common/Collapse'
import { DocumentExpirationDate } from 'components/common/DocumentExpirationDate'
import { WithIconTooltip } from 'components/common/WithIconTooltip'
import { FormListProps, useCurrentForm } from 'components/form/Form'
import { FormItem, FormItemProps } from 'components/form/FormItem'
import { DatePickerString } from 'components/form/inline-edit/DatePickerString'
import Switch from 'components/form/inline-edit/Switch'
import { DocumentTypeSelect } from 'components/form/reference-selects/DocumentTypeSelect'
import colors from 'constants/colors'
import { compact, get as getPropertyAtPath } from 'lodash'
import { getNamePath } from 'rc-field-form/es/utils/valueUtil'
import { useState } from 'react'
import { DocumentTypeScope } from 'schema'
import styled from 'styled-components'
import { CustomFieldsFormList } from '../CustomFieldsFormList'
import { DocumentUpload } from './DocumentUpload'

const PanelStyled = styled(Panel)`
  &.inactive {
    .ant-collapse-header {
      opacity: 0.7;
      color: ${colors.fontPrimary};

      .ant-collapse-extra ${DocumentExpirationDate} {
        color: ${colors.fontPrimary};
        font-weight: normal;
      }
    }
  }
`

const Label = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${colors.greyscale60};
`

const VerticalFormItemWrap = styled.div`
  margin-bottom: 10px;

  > ${Label} {
    display: block;
    margin-bottom: 3px;
  }

  .ant-form-item-explain {
    max-width: 250px;
  }

  .ant-select-borderless,
  .ant-picker-borderless,
  .ant-input-affix-wrapper-borderless,
  .ant-select-borderless .ant-select-selector {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    border-top: none!important;
    border-right: none !important;
    border-left: none !important;

    .ant-input-suffix, .ant-picker-suffix {
      margin-right: 7px;
    }
    .ant-select-arrow {
      right: 7px;
    }
  }

  .ant-select-borderless,
  .ant-picker-borderless,
  .ant-input-affix-wrapper-borderless {
    border-bottom: 1px solid transparent !important;

    &:not(.read-only) {
      border-bottom: 1px solid #d9d9d9 !important;

      &:hover,
      &:focus,
      &.ant-picker-focused,
      &.ant-input-affix-wrapper-focused,
      &.ant-input-focused,
      &.ant-select-focused {
        border-bottom-color: #40a9ff !important;
      }
    }
  }
`

const Tiled = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  gap: 10px;

  > * {
    flex: 1 1 0;
    min-width: 120px;
  }
`

const VerticalFormItem = ({ label, style, ...props }: FormItemProps) => (
  <VerticalFormItemWrap style={style}>
    <Label>{label}</Label>
    <FormItem noMargin {...props} />
  </VerticalFormItemWrap>
)

interface DocumentFormItemProps {
  name: FormListProps['name']
  scope: DocumentTypeScope,
  editing?: boolean
  listPrefix?: FormItemProps['listPrefix']
  onRemove: () => void
}

export const DocumentFormItem = ({
  editing,
  name,
  scope,
  onRemove,
  listPrefix,
}: DocumentFormItemProps) => {
  const form = useCurrentForm()

  const path = getNamePath(name)
  const typeDetailsPath = [...path, 'typeDetails']
  const typeNamePath = [...typeDetailsPath, 'name']
  const expirationPath = [...path, 'expirationDate']
  const activePath = [...path, 'active']
  const fieldsPath = [...path, 'fields']
  const panelKey = `panel-${form.getFieldValue([...path, 'id']) || listPrefix?.key}`

  const [defaultShow] = useState(() => {
    const value = form.getFieldValue(path)
    const isNew = value === undefined || value._isNew
    return isNew
  })

  const [active] = useState(() => form.getFieldValue(activePath))

  return (
    <Collapse mode="list" defaultActiveKey={defaultShow ? panelKey : undefined}>
      <PanelStyled
        key={panelKey}
        forceRender
        className={!active ? 'inactive' : undefined}
        header={(
          <FormItem
            noStyle
            shouldUpdate={(prevValues, curValues) => (
              getPropertyAtPath(prevValues, typeNamePath) !== getPropertyAtPath(curValues, typeNamePath)
            )}
          >
            {() => (
              <span style={{ fontWeight: 500 }}>{form.getFieldValue(typeNamePath) || 'New Document'}</span>
            )}
          </FormItem>
        )}
        extra={(
          <FormItem
            noStyle
            shouldUpdate={(prevValues, curValues) => (
              getPropertyAtPath(prevValues, expirationPath) !== getPropertyAtPath(curValues, expirationPath)
            )}
          >
            {() => (
              <DocumentExpirationDate
                expirationDate={form.getFieldValue(expirationPath)}
              />
            )}
          </FormItem>
        )}
      >
        <Tiled>
          <VerticalFormItem
            label="Type"
            name={['typeDetails', 'slug']}
            listPrefix={listPrefix}
            style={{ minWidth: '200px' }}
            rules={[
              {
                required: true,
                message: 'Must select or create a type',
              },
            ]}
            getValueFromEvent={(value, option) => {
              if (option.label) {
                form.setFields([
                  {
                    name: [...typeDetailsPath, 'name'],
                    value: option.label,
                  },
                  {
                    name: [...typeDetailsPath, 'slug'],
                    value,
                  },
                ])
              }
              return value
            }}
          >
            <DocumentTypeSelect
              bordered={false}
              editing={editing}
              scope={scope}
              style={{ width: '100%', overflow: 'hidden' }}
              dropdownMatchSelectWidth={false}
              placeholder="Select or create type"
              resources={compact([
                form.getFieldValue(typeDetailsPath),
              ])}
            />
          </VerticalFormItem>
          <VerticalFormItem
            label={(
              <WithIconTooltip title="By setting an expiration date, a notification will appear when this document is near expiration.">
                Expiration Date
              </WithIconTooltip>
            )}
            name="expirationDate"
            listPrefix={listPrefix}
          >
            <DatePickerString
              bordered={false}
              placeholder="Date document expires"
              editing={editing}
              format="YYYY-MM-DD"
              displayFormat="MM/DD/YYYY"
              style={{ marginTop: '2px' }}
            />
          </VerticalFormItem>
        </Tiled>

        <VerticalFormItem
          label={(
            <WithIconTooltip title="Fields added here will be available at a glance when viewing documents.">
              Additional Fields
            </WithIconTooltip>
          )}
        >
          <CustomFieldsFormList
            name={fieldsPath}
            editing={editing}
          />
        </VerticalFormItem>
        <VerticalFormItem
          name="attachments"
          label="Attachments"
          listPrefix={listPrefix}
        >
          <DocumentUpload editing={editing} />
        </VerticalFormItem>

        <Row wrap={false} align="middle" gutter={10}>
          <Col>
            <Label>
              <WithIconTooltip title="Always show this document on the Schedule" tooltipProps={{ noWrap: true }}>
                Pinned
              </WithIconTooltip>
            </Label>
          </Col>
          <Col flex="auto">
            <FormItem
              noStyle
              name="pinned"
              listPrefix={listPrefix}
              valuePropName="checked"
            >
              <Switch editing={editing} />
            </FormItem>
          </Col>
          <Col>
            <Label>Active</Label>
          </Col>
          <Col flex="auto">
            <FormItem
              noStyle
              name="active"
              listPrefix={listPrefix}
              valuePropName="checked"
            >
              <Switch editing={editing} />
            </FormItem>
          </Col>
          <Col style={{ opacity: editing ? 1 : 0 }}>
            <Popconfirm
              title="Are you sure you want to delete this Document?"
              onConfirm={onRemove}
              okText="Yes"
              cancelText="No"
              placement="topLeft"
            >
              <RemoveItemButton />
            </Popconfirm>
          </Col>
        </Row>
      </PanelStyled>
    </Collapse>
  )
}
