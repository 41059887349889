import { OptionsType, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { PaymentMethod } from '../../../hooks/useInvoices'

export type PaymentMethodSelectProps = ReferenceSelectProps<PaymentMethod>

const OPTIONS: OptionsType = [
  {
    value: 'ach',
    label: 'ACH',
  },
  {
    value: 'cash',
    label: 'Cash',
  },
  {
    value: 'check',
    label: 'Check',
  },
  {
    label: 'Credit Card',
    value: 'cc_generic',
    options: [
      {
        value: 'cc_Visa',
        label: 'Visa',
      },
      {
        value: 'cc_amex',
        label: 'American Express',
      },
      {
        value: 'cc_mastercard',
        label: 'Mastercard',
      },
      {
        value: 'cc_discover',
        label: 'Discover',
      },
      {
        value: 'cc_generic',
        label: 'Credit Card',
      },
    ],
  },
]

const defaults: Partial<PaymentMethodSelectProps> = {
  showSearch: true,
  placeholder: 'Select Payment Method',
  optionsGenerator: () => OPTIONS,
}

export const PaymentMethodSelect = (props: PaymentMethodSelectProps) => (
  <ReferenceSelect
    remoteResources={[]}
    {...defaults}
    {...props}
  />
)
