import colors from 'constants/colors'
import styled from 'styled-components'

import { useSchedulerState } from 'components/scheduler/SchedulerState'

import { Menu } from 'antd'
import { CurrentBranchSelector } from 'components/common/CurrentBranchSelector'
import { Divider } from 'components/common/Divider'
import { CollapsibleSider, SiderProps } from 'components/layout/Sider'
import { MenuLink } from 'components/menus/Menu'
import { SiderMenu } from 'components/menus/SiderMenu'

import { PlusOutlined, StopOutlined } from '@ant-design/icons'

export type ScheduleMobileSiderProps = SiderProps & {
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export const ScheduleMobileSider = styled((props: ScheduleMobileSiderProps) => {
  const {
    inactiveEventsVisible,
    toggleInactiveEvents,
    basePath,
  } = useSchedulerState()

  const { setCollapsed, ...rest } = props

  return (
    <CollapsibleSider
      trigger={null}
      collapsedWidth={0}
      width="180px"
      position="right"
      {...rest}
      onCollapse={(collapsed, type) => {
        setCollapsed(collapsed)
        if (props.onCollapse) {
          props.onCollapse(collapsed, type)
        }
      }}
    >
      <BranchSelectorSection>
        <CurrentBranchSelector
          bordered={false}
          onChange={() => {
            setCollapsed(true)
          }}
        />
      </BranchSelectorSection>

      <Divider thin />

      <SiderMenu>
        <MenuLink
          key="addEvent"
          icon={<PlusOutlined />}
          link={`${basePath}/new`}
        >
          Add Event
        </MenuLink>
        <Menu.Item
          key="inactiveToggle"
          icon={inactiveEventsVisible ? <StopOutlined /> : <StopOutlined style={{ opacity: 0.25 }} />}
          onClick={() => {
            toggleInactiveEvents()
            setCollapsed(true)
          }}
        >
          {inactiveEventsVisible ? 'Hide Cancelled' : 'Show Cancelled'}
        </Menu.Item>
      </SiderMenu>
    </CollapsibleSider>
  )
})`
  &.ant-layout-sider {
    position: absolute;
    z-index: 30;
    right: 0;
    top: 0;
    margin-top: 50px;

    .ant-layout-sider-children {
      min-width: 180px;
    }
  }
`

const BranchSelectorSection = styled.div.attrs((props) => ({
  ...props,
  children: <>
    <div>Branch</div>
    {props.children}
  </>,
}))`
  display: block;
  margin: 10px 3px;

  > * {
    width: 100%;
  }

  > div:first-child {
    margin-bottom: 3px;
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 12px;
    color: ${colors.greyscale50};
  }
`
