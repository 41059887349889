import { useQuery } from '@apollo/client'
import { Select as AntSelect } from 'antd'
import ReferenceSelect, { errorDropdownRender, generateOptionsFromResources, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { formatAddress } from 'helpers/formatAddress'
import { byFieldAsc } from 'helpers/sortting'
import {
  compact, isNil, isNumber, isString, uniq,
} from 'lodash'
import React, { useMemo } from 'react'
import { Query, Site, Tag } from 'schema'
import styled from 'styled-components'
import { RecursivePartial } from 'types'

import { SiteDrawer } from 'components/drawers/SiteDrawer'
import { ViewMoreDrawer } from 'components/layout/ViewMoreDrawer'
import { GET_SITES_FOR_CUSTOMER } from 'gql/sites'
import { withSystemTags } from 'helpers/order/withSystemTags'
import { LabelWithTags } from './components/LabelWithTags'

const { Option } = AntSelect

const OptionTitle = styled.div`
  font-weight: bold;
  white-space: normal;
`
const OptionSubtitle = styled.div`
  font-size: 0.8em;
  white-space: pre-wrap;
`

export type SiteSelectProps = ReferenceSelectProps<Site> & {
  customerId?: number
}

const siteOptionGenerator = (site?: RecursivePartial<Site> | null) => {
  if (!site?.id) return
  const name = uniq(compact([site.name, site.name2]))
  const tags = withSystemTags({ site })

  return {
    label: <LabelWithTags tags={tags as Tag[]}>
      {name}
    </LabelWithTags>,
    value: site?.id || '',
  }
}

const sitesOptionsGenerator: SiteSelectProps['optionsGenerator'] = (sites) => (
  compact([...sites].sort(byFieldAsc('name')).map(siteOptionGenerator))
)

export const SiteSelect: React.FC<SiteSelectProps> = (inputProps) => {
  const { customerId, ...rest } = inputProps
  const showSites = Boolean(customerId)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const { data, loading, error } = useQuery<Query, {}>(GET_SITES_FOR_CUSTOMER, {
    skip: !showSites,
    variables: {
      customerId,
      where: {
        active: { equals: true },
      },
    },
  })

  const props: ReferenceSelectProps<Site> = {
    showSearch: true,
    placeholder: showSites ? 'Select Site' : 'Select Customer First',
    showExternalLink: !inputProps.mode,
    // eslint-disable-next-line react/no-unstable-nested-components
    generateExternalLink: (siteId) => {
      const id = isNumber(siteId) ? siteId : (isString(siteId) ? parseInt(siteId) : undefined)

      return (
        <ViewMoreDrawer
          closeIcon={false}
          buttonStyle={{ opacity: id ? 1 : 0, visibility: id ? 'visible' : 'hidden' }}
        >
          {customerId &&
            <SiteDrawer key={id} id={id} customerId={customerId} />}
        </ViewMoreDrawer>
      )
    },
    ...rest,
    disabled: !showSites,
    optionLabelProp: 'label',
    loading: inputProps.loading || loading,
  }

  const sites = [...((data?.customers || [])[0]?.sites || [])]
  const sitesById = Object.fromEntries(sites.map((site) => [site.id, site]))

  const options = generateOptionsFromResources(
    sitesOptionsGenerator,
    props.resources,
    sites
  )

  props.filterOption = useMemo<SiteSelectProps['filterOption']>(() => (
    (input, option) => {
      if (!input || !option || isNil(option?.value)) return true

      const site = sitesById[option.value]
      if (!site) return true

      const search = input.toLowerCase()

      return (
        site.name?.toLowerCase().includes(search) ||
        site.name2?.toLowerCase().includes(search) ||
        site.address?.city?.toLowerCase().includes(search) ||
        site.address?.street?.toLowerCase().includes(search) ||
        site.address?.zip?.toLowerCase().includes(search)
      ) || false
    }
  ), [sitesById])

  if (error) {
    props.dropdownRender = errorDropdownRender('Site')
  }

  return (
    <ReferenceSelect {...props}>
      {options.map((option) => {
        if (!option.value) return

        return (
          <Option key={option.value} {...option as any}>
            <OptionTitle>{option.label}</OptionTitle>
            <OptionSubtitle>{formatAddress(sitesById[option.value]?.address || {}, { multiline: true })}</OptionSubtitle>
          </Option>
        )
      })}
    </ReferenceSelect>
  )
}

export default SiteSelect
