import {
  Button, Empty, Space, Typography,
} from 'antd'
import { Comment } from 'components/common/Comment'
import { Form, FormListProps, useCurrentForm } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'
import { TextArea } from 'components/form/inline-edit/TextArea'
import colors from 'constants/colors'
import React from 'react'
import styled from 'styled-components'

interface CommentsFormListProps extends Omit<FormListProps, 'name' | 'children'> {
  title?: React.ReactNode
  name?: FormListProps['name']
  editing?: boolean
}

const CommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  > * {
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
  }

  > *:first-child {
    padding-top: 25px;
  }
  > *:last-child {
    padding-bottom: 25px;
  }
`

const CommentsBody = styled.div`
  overflow: auto;
  flex-grow: 1;
`

const AddCommentWrapper = styled.div`
  margin-top: 8px;
  align-self: flex-end;
  box-shadow: 0 0 15px 10px ${colors.greyscale0};
  z-index: 2;
`

export const CommentsFormList = (props: CommentsFormListProps) => {
  const {
    editing,
    title,
    name: inputName,
    ...args
  } = props

  const form = useCurrentForm()

  const titleWithDefault = title || <Typography.Title level={4}>Comments</Typography.Title>

  const name = inputName || 'comments'

  return (
    <Form.List
      name={name}
      {...args}
    >
      {(fields, _, { errors }) => (
        <CommentsWrapper>
          <CommentsBody>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Form.ErrorList errors={errors} />

              {titleWithDefault}

              <div>
                {fields.length === 0 && (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No comments"
                  />
                )}
                {fields.map((field, index) => (
                  <FormItem valuePropName="comment" key={field.key} name={field.name} noStyle>
                    <Comment key={index} comment={null as any} />
                  </FormItem>
                ))}
              </div>
            </Space>
          </CommentsBody>

          {editing !== false && (
            <AddCommentWrapper>
              <FormItem name={[fields.length, 'text']} noStyle>
                <TextArea
                  style={{ margin: '15px 0' }}
                  autoSize={{ minRows: 2, maxRows: 20 }}
                  placeholder="Add comments / notes here"
                />
              </FormItem>

              <FormItem
                noStyle
                dependencies={[[name.toString(), fields.length, 'text']]}
              >
                {() => (
                  <Button
                    style={{ width: '100%' }}
                    type="primary"
                    onClick={form.submit}
                    disabled={!form.getFieldValue([name.toString(), fields.length, 'text'])}
                  >Save Comment
                  </Button>
                )}
              </FormItem>
            </AddCommentWrapper>
          )}
        </CommentsWrapper>
      )}
    </Form.List>
  )
}
