import { compact, set as setPropertyAtPath } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useEffect, useRef, useState } from 'react'
import { RecursivePartial } from 'types'

import { WithIconTooltip } from 'components/common/WithIconTooltip'
import { JoinOrderToRouteModal } from 'components/modals/JoinOrderToRouteModal'
import { MoveOrderToNewRouteModal } from 'components/modals/MoveOrderToNewRouteModal'
import { EditMode, mergeEditModeState, useEditMode } from '../EditModeState'
import { useSchedulerEvents } from '../SchedulerEventsState'

import { ToolbarTitle } from './ToolbarTitle'

const DEAFULT_EDIT_MODE: RecursivePartial<EditMode> = {
  equipment: {
    enabled: true,
  },
  orders: {
    enabled: false,
  },
  routes: {
    enabled: true,
  },
}

export const MoveOrderToRoute = () => {
  const [Modal, setModal] = useState<() => React.ReactNode>(() => null)

  const editMode = useEditMode()
  const { setEditMode } = editMode

  const [events] = useSchedulerEvents()

  const selectedOrderTimes = useRef<Interval[]>([])

  const selectedOrderIds = editMode.orders.selected
  const selectedEquipmentIds = editMode.equipment.selected
  const selectedRouteIds = editMode.routes.selected

  useEffect(() => {
    setEditMode((prev) => (
      mergeEditModeState(prev, DEAFULT_EDIT_MODE)
    ))
  }, [])

  useEffect(() => {
    const routes = compact(events.map((event) => event.route))

    routes.forEach((route) => {
      route.waypoints?.forEach((wp) => {
        const arrivalTime = wp.scheduledArrivalTime || wp.scheduledArrivalTimeLocal
        const departureTime = wp.scheduledDepartureTime || wp.scheduledDepartureTimeLocal

        if (
          wp.order?.id &&
          selectedOrderIds.includes(wp?.order?.id) &&
          arrivalTime &&
          departureTime
        ) {
          selectedOrderTimes.current.push(
            Interval.fromDateTimes(
              DateTime.fromISO(arrivalTime),
              DateTime.fromISO(departureTime)
            )
          )
        }
      })
    })

    const unselectableRouteIds = compact(routes.map((route) => {
      const hasInvalid = (route.waypoints || []).some((wp) => {
        const arrivalTime = wp.scheduledArrivalTime || wp.scheduledArrivalTimeLocal
        if (!arrivalTime) return

        const departureTime = wp.scheduledDepartureTime || wp.scheduledDepartureTimeLocal
        if (!departureTime) return

        if (departureTime && arrivalTime) {
          const interval = Interval.fromDateTimes(
            DateTime.fromISO(arrivalTime),
            DateTime.fromISO(departureTime)
          )

          return selectedOrderTimes.current.some((orderInterval) => orderInterval.overlaps(interval))
        }
        // eslint-disable-next-line no-useless-return
        return
      })

      if (hasInvalid) {
        return route.id
      }
      // eslint-disable-next-line no-useless-return
      return
    }))

    setEditMode((prev) => (
      mergeEditModeState(prev, {
        routes: {
          ...prev.routes,
          disabled: unselectableRouteIds,
        },
      })
    ))
  }, [events])

  useEffect(() => {
    const equipmentId = selectedEquipmentIds[0]
    if (equipmentId === undefined) return
    if (selectedOrderIds.length === 0) return
    const key = Date.now()

    setModal(() => (
      <MoveOrderToNewRouteModal
        key={key}
        orderIds={selectedOrderIds}
        equipmentId={equipmentId}
        onCancel={() => {
          setEditMode((prev) => {
            const updated = { ...prev }
            setPropertyAtPath(updated, ['equipment', 'selected'], [])
            return updated
          })
        }}
        onOk={() => {
          setEditMode(false)
        }}
      />
    ))
  }, [selectedOrderIds, selectedEquipmentIds])

  useEffect(() => {
    if (selectedRouteIds.length === 0) return
    if (selectedOrderIds.length === 0) return
    const key = Date.now()

    setModal(() => (
      <JoinOrderToRouteModal
        key={key}
        orderIds={selectedOrderIds}
        routeId={selectedRouteIds[0]}
        onCancel={() => {
          setEditMode((prev) => {
            const updated = { ...prev }
            setPropertyAtPath(updated, ['routes', 'selected'], [])
            return updated
          })
        }}
        onOk={() => {
          setEditMode(false)
        }}
      />
    ))
  }, [selectedOrderIds, selectedRouteIds])

  return (
    <div style={{ display: 'flex' }}>
      <ToolbarTitle>
        <WithIconTooltip title="Click another Route to move the current Order to that Route, or click a piece of Equipment to split the Order onto a New Route for that Equipment.">
          Move Order to Another Route
        </WithIconTooltip>
      </ToolbarTitle>
      {Modal}
    </div>
  )
}
