import { Col, Row } from 'antd'
import { TagsList } from 'components/common/TagsList'
import { Tooltip } from 'components/common/Tooltip'
import { ResourceTag, ResourceTagProps } from 'components/tags/ResourceTag'
import { useMemo } from 'react'
import styled from 'styled-components'

export interface LabelWithTagsProps {
  children?: React.ReactNode,
  tooltip?: React.ReactNode,
  tags?: Array<ResourceTagProps['tag']>
}

const SelectTagsList = styled(TagsList).attrs(() => ({
  minified: 'vertical',
}))`
  height: 18px;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(2, auto);

  .ant-tag {
    height: 8px;
    width: 4px;
    margin-right: 2px;
    margin-bottom: 1px;
  }

  .ant-tag:only-child {
    grid-row: span 2;
    height: 100%;
  }
`

const TextCol = styled(Col)`
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TagsCol = styled(Col)`
  flex: 0 0 auto;
  padding: 0 2px 0 4px;
`

export const LabelWithTags = (props: LabelWithTagsProps) => {
  const tags = useMemo(() => (
    (props.tags || []).map((tag: any) => (
      <ResourceTag key={tag.id} tag={tag} />
    ))
  ), [props.tags])

  return (
    <Tooltip
      noWrap
      mouseEnterDelay={0.9}
      title={(
        <>
          {props.tooltip || props.children}

          {tags.length > 0 &&
            <TagsList>{tags}</TagsList>}
        </>
      )}
    >
      <Row wrap={false} align="middle">
        <TextCol>
          {props.children}
        </TextCol>
        <TagsCol>
          {tags.length > 0 &&
            <SelectTagsList>{tags}</SelectTagsList>}
        </TagsCol>
      </Row>
    </Tooltip>
  )
}
