import { gql } from '@apollo/client'

export const GET_ORDER_STATUSES = gql`
  query GetOrderStatuses($where: QueryOrderStatusesWhereInput) {
    orderStatuses(where: $where) {
      slug
      name
      sort
      color
    }
  }
`
