import React, { useMemo, useState } from 'react'

import { LinkExternalButton } from 'components/buttons'
import { Drawer, DrawerProps } from './Drawer'
import { SecondaryDrawer } from './SecondaryDrawer'

export interface ViewMoreDrawerProps extends React.PropsWithChildren<DrawerProps> {
  drawer?: typeof Drawer
  button?: React.ReactElement
  buttonStyle?: React.CSSProperties
}

export const ViewMoreDrawer = (props: ViewMoreDrawerProps) => {
  const {
    visible, drawer, button, buttonStyle, ...args
  } = props
  const [drawerVisible, setDrawerVisible] = useState<boolean>(visible || false)

  const toggleDrawer = () => {
    setDrawerVisible((prev) => !prev)
  }

  const onClose: DrawerProps['onClose'] = (evt) => {
    if (props.onClose) {
      props.onClose(evt)
    }
    setDrawerVisible(false)
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const Drawer = drawer || SecondaryDrawer

  const Button = useMemo(() => {
    if (button && React.isValidElement(button)) {
      return React.cloneElement(button, {
        onClick: toggleDrawer,
        style: buttonStyle,
      } as any)
    }

    return <LinkExternalButton size="small" onClick={toggleDrawer} style={buttonStyle} />
  }, [button, buttonStyle])

  return (
    <>
      <Drawer
        {...args}
        onClose={onClose}
        visible={drawerVisible}
        noStyle
      />

      {Button}
    </>
  )
}
