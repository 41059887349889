import { useExternal, useLocalStorageState } from 'ahooks'
import { Button } from 'components/buttons'
import { ReplyIcon } from 'components/icons'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { compact } from 'lodash'
import React, {
  ReactChild, useContext, useEffect, useRef,
} from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useNotification } from './useNotification'

export interface SupportWidgetState {
  toggle: () => void
  hide: () => void
  show: (message?: string) => void
  unreadCount: number | undefined
}

const initialContext: SupportWidgetState = {
  toggle: () => { },
  hide: () => { },
  show: () => { },
  unreadCount: undefined,
}

const SupportWidgetContext = React.createContext(initialContext)

export const useSupportWidget = () => useContext(SupportWidgetContext)

export interface SupportWidgetProviderProps {
  children?: ReactChild
}

type CrispPushAction = 'set' | 'do' | 'on' | 'off' | 'config'
type CrispMethod = string

declare global {
  interface Window {
    $crisp: Array<[CrispPushAction, CrispMethod, any?]> & {
      push?: (...commands: [CrispPushAction, CrispMethod, any?]) => void
      get?: (method: CrispMethod) => any
      is?: (method: CrispMethod) => boolean
    }
    CRISP_WEBSITE_ID: string
    CRISP_READY_TRIGGER?: () => void
    CRISP_RUNTIME_CONFIG?: Record<string, any>
    CRISP_TOKEN_ID?: string
    Upscope?: (...args: any[]) => void
  }
}

window.CRISP_WEBSITE_ID = '2d88acfd-f90a-46d6-9fbf-ea32cad8deaf'

window.$crisp = [] as any
window.$crisp.push(['do', 'chat:hide'])
window.$crisp.push(['set', 'session:segments', [['chat', 'dashboard', 'user']]])

interface Message {
  type: string
  from: string
  origin: string
  content: string
  fingerprint: number
  timestamp: number
  user?: {
    nickname: string
    user_id: string
  }
}

const NOTIFICATION_KEY = 'most-recent-support-message'

const ReplyButton = styled(Button).attrs({
  type: 'primary',
  size: 'small',
  children: <>Reply <ReplyIcon /></>,
})`
  border-color: #666d75 !important;
  background: #6b737c !important;
  margin-top: 12px;
  padding-left: 14px;
  padding-right: 10px;
  display: block;
  font-weight: 600;
  font-size: 0.9em;

  .anticon {
    margin-left: 5px;
  }
`

const LoadCrispClient = () => {
  useExternal('https://client.crisp.chat/l.js', { js: { async: true } })
  return null
}

const LocationListener = () => {
  const location = useLocation()
  const { show } = useSupportWidget()

  useEffect(() => {
    if (location.search.includes('crisp_sid')) {
      show()
    }
  }, [location.search])

  return null
}

export const SupportWidgetProvider: React.FC = (props) => {
  const notification = useNotification()
  const user = useCurrentUser()
  const [unreadCount, setUnreadCount] = useLocalStorageState<number>('crisp_unread', { defaultValue: 0 })
  const showCalledOnce = useRef<boolean>(false)

  const hide = () => {
    if (!window.$crisp?.is?.('chat:closed')) {
      window.$crisp.push(['do', 'chat:close'])
    }
    if (!window.$crisp?.is?.('chat:hidden')) {
      window.$crisp.push(['do', 'chat:hide'])
    }
  }

  const show = (message?: string) => {
    showCalledOnce.current = true

    setUnreadCount(0)

    notification.close(NOTIFICATION_KEY)

    if (!window.$crisp?.is?.('chat:visible')) {
      window.$crisp.push(['do', 'chat:show'])
    }
    if (!window.$crisp?.is?.('chat:opened')) {
      window.$crisp.push(['do', 'chat:open'])
    }

    if (message) {
      window.$crisp.push(['set', 'message:text', [message]])
    }
  }

  const toggle = () => {
    if (!window.$crisp?.is?.('chat:opened')) {
      show()
    } else {
      hide()
    }
  }

  const onMessageReceived = (msg: Message) => {
    window.$crisp.push(['do', 'message:read'])

    if (!window.$crisp?.is?.('chat:opened')) {
      setUnreadCount((last) => (last || 0) + 1)

      const name = msg.user?.nickname?.split(' ')?.[0] || 'Support'

      notification.open({
        duration: 0,
        message: <>From <strong>{name}</strong></>,
        key: NOTIFICATION_KEY,
        placement: 'bottomLeft',
        className: 'support-message',
        description: <>
          {msg.content}
          <ReplyButton onClick={() => show()} />
        </>,
      })
    }
  }

  useEffect(() => {
    window.CRISP_READY_TRIGGER = () => {
      if (!showCalledOnce.current) {
        hide()
      }

      setTimeout(() => {
        const newUnreadCount = window.$crisp.get?.('chat:unread:count') || 0
        const maxUnread = Math.max(newUnreadCount, unreadCount)
        setUnreadCount(maxUnread)
      }, 1000)
    }

    return () => {
      delete window.CRISP_READY_TRIGGER
    }
  }, [])

  useEffect(() => {
    window.$crisp.push(['on', 'chat:closed', hide])
    window.$crisp.push(['on', 'message:received', onMessageReceived])

    return () => {
      if (!window.$crisp) return
      window.$crisp.push(['off', 'chat:closed'])
      window.$crisp.push(['off', 'message:received'])
    }
  }, [])

  useEffect(() => {
    if (!user) {
      delete window.CRISP_TOKEN_ID
      window.$crisp.push(['do', 'session:reset'])
      setUnreadCount(0)
      return
    }

    window.CRISP_TOKEN_ID = user.id.toString()
    if (window.$crisp?.is?.('session:ongoing')) {
      window.$crisp.push(['do', 'session:reset'])
    }

    if ('username' in user) {
      window.$crisp.push(['set', 'user:email', [user.username]])

      window.Upscope?.('updateConnection', {
        identities: [user.username],
      })
    }

    const phone = user?.phones?.[0]?.number
    if (phone) {
      const cleanedPhone = phone.length === 10 ? `+1${phone}` : phone
      window.$crisp.push(['set', 'user:phone', [cleanedPhone]])
    }

    window.$crisp.push(['set', 'user:nickname', [compact([user.firstName, user.lastName]).join(' ')]])
    window.$crisp.push(['set', 'user:company', [user.organization?.name]])
  }, [user])

  useEffect(() => {
    window.Upscope?.('getWatchLink', (link: string) => {
      if (link) {
        window.$crisp.push(['set', 'session:data', ['Cobrowse', link]])
      }
    })
  }, [])

  // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
  const state: SupportWidgetState = {
    hide,
    show,
    toggle,
    unreadCount,
  }

  return (
    <>
      <LoadCrispClient />
      <SupportWidgetContext.Provider value={state}>
        {props.children}
        <LocationListener />
      </SupportWidgetContext.Provider>
    </>
  )
}
