import { useNetwork } from 'ahooks'
import Icon from '@ant-design/icons'
import { Typography } from 'antd'

import { ReactComponent as CloudOfflineSvg } from '../../assets/images/cloud-offline-outline.svg'
import { NotificationBanner } from '../NotificationBanner'

const { Text } = Typography

export const NetworkAlert = () => {
  const networkState = useNetwork()
  if (networkState.online) {
    return null
  }
  return <NotificationBanner icon={<Icon component={CloudOfflineSvg} style={{ color: '#000000' }} />} showIcon type="warning" message={<div><Text strong>Your computer may be offline. </Text><Text>Please check your internet connection.</Text></div>} />
}
