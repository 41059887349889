import { Col, Row } from 'antd'
import {
  Route, Switch, useHistory, useRouteMatch,
} from 'react-router-dom'
import { InvoicePage } from '../../components/invoicing/InvoicePage'
import { InvoicesIndex } from './InvoicesIndex'

export const InvoicesPage = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const goToInvoiceIndex = () => {
    history.push('/invoices')
  }
  return (
    <>
      <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
        <Col flex="auto" style={{ minWidth: 0 }}>
          <Switch>
            <Route exact path={match.path} component={InvoicesIndex} />
            <Route exact path={`${match.path}/:id`} render={() => (<InvoicePage onCancelBuild={goToInvoiceIndex} />)} />
          </Switch>
        </Col>
      </Row>
    </>
  )
}

export default InvoicesPage
