import { parseLongitude } from 'helpers/parseCoordinates'
import React from 'react'
import { CoordinateInput, CoordinateInputProps, CoordinateInputRef } from './CoordinateInput'

export type LongitudeInputProps = Omit<CoordinateInputProps, 'min' | 'max' | 'stringMode' | 'coordinateParser'>

export const LongitudeInput = React.forwardRef<CoordinateInputRef, LongitudeInputProps>((props, ref) => {
  const args: CoordinateInputProps = {
    placeholder: 'Longitude',
    ...props,
    coordinateParser: parseLongitude,
    min: -180,
    max: 180,
  }

  return <CoordinateInput {...args} />
})

export default LongitudeInput
