import { compact, remove, uniq } from 'lodash'
import { useMemo, useRef } from 'react'

import { useSelectedBranch } from 'hooks/useBranch'
import { useFeatures } from 'hooks/useFeature'

import {
  matchPath, Route, RouteProps, useLocation,
} from 'react-router-dom'
import { BranchRoutes } from 'router/BranchRoutes'

import { Menu, MenuLink } from 'components/menus/Menu'

import EquipmentPage from 'pages/Equipment'
import InventoryPage from 'pages/Inventory'
import OrganizationPage from 'pages/Organization'
import PourTypesPage from 'pages/PourTypes'
import QuickbooksPage from 'pages/Settings/Integrations/Quickbooks'
import SubscriptionPage from 'pages/Subscription'
import SuppliersPage from 'pages/Suppliers'
import UserPage from 'pages/Users'
import BranchesPage from '../Branches'
import GeotabPage from './Integrations/Geotab'
import SamsaraPage from './Integrations/Samsara'

type RoutesDefGroup = { group: React.ReactNode, children: RoutesDef }
type RoutesDefRoute = Omit<RouteProps, 'path'> & { title: React.ReactNode, feature?: string, path: string }
type RoutesDef = Array<RoutesDefRoute | RoutesDefGroup>

const NESTED_IN_BRANCH_PATH = '/branches/:branchId'

const ROUTES: RoutesDef = [
  {
    group: 'General',
    children: [
      {
        path: '/company',
        component: OrganizationPage,
        title: 'Company',
      },
      {
        path: '/subscription',
        component: SubscriptionPage,
        title: 'Subscription',
      },
      {
        path: '/users',
        component: UserPage,
        title: 'Users',
      },
    ],
  },
  {
    group: 'Data',
    children: [
      {
        path: '/branches',
        component: BranchesPage,
        title: 'Branches',
      },
      {
        title: 'Equipment',
        path: `${NESTED_IN_BRANCH_PATH}/equipment`,
        component: EquipmentPage,
      },
      {
        title: 'Inventory',
        path: `${NESTED_IN_BRANCH_PATH}/inventory`,
        component: InventoryPage,
        feature: 'inventory.admin.enabled',
      },
      {
        title: 'Pour Types',
        path: '/pour-types',
        component: PourTypesPage,
      },
      {
        title: 'Suppliers',
        path: '/suppliers',
        component: SuppliersPage,
      },
    ],
  },
  {
    group: 'Integrations',
    children: [
      {
        title: 'Geotab',
        path: '/integrations/geotab',
        component: GeotabPage,
      },
      {
        title: 'Quickbooks',
        path: '/integrations/quickbooks',
        component: QuickbooksPage,
      },
      {
        title: 'Samsara',
        path: '/integrations/samsara',
        component: SamsaraPage,
      },
    ],
  },
]

const getRoutesFeatures = (routesDef: RoutesDef): string[] => (
  compact(uniq(
    routesDef.flatMap((route) => (
      ('group' in route) ?
        getRoutesFeatures(route.children)
        :
        route.feature
    ))
  ))
)

const filterRoutesByFeatures = (routesDef: RoutesDef, features: Record<string, boolean>): RoutesDef => (
  compact(routesDef.map((route) => {
    if ('group' in route) {
      const filteredChildren = filterRoutesByFeatures(route.children, features)

      if (filteredChildren.length === 0) {
        return null
      }

      return {
        ...route,
        children: filteredChildren,
      }
    } if (route.feature) {
      if (features[route.feature]) {
        return route
      }
      return null
    }

    return route
  }))
)

export const useSettingsMenuAndRoutes = (basePath = '/settings') => {
  const location = useLocation()

  const { branchId: selectedBranchId } = useSelectedBranch()

  const branchId = useMemo(() => {
    const pathBasedBranchId = matchPath<any>(location.pathname, {
      path: basePath + NESTED_IN_BRANCH_PATH,
    })?.params?.branchId
    if (pathBasedBranchId) {
      return parseInt(pathBasedBranchId)
    }
    return selectedBranchId
  }, [basePath, selectedBranchId, location.pathname])

  const featuresNeeded = useRef(getRoutesFeatures(ROUTES)).current
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [features, { loading }] = useFeatures(...featuresNeeded)
  const filteredRoutes = useMemo(() => filterRoutesByFeatures(ROUTES, features), [features])

  const recursiveMenuRender = (routesDef: RoutesDef): JSX.Element[] => routesDef.map((route, index) => {
    if ('group' in route) {
      return <Menu.ItemGroup key={index} title={route.group} children={recursiveMenuRender(route.children)} />
    }
    const link = basePath + (route.path || '').replace(/\/:branchId\//g, `/${branchId}/`)
    return <MenuLink key={(route.path || index).toString()} link={link} children={route?.title} />
  })

  const recursiveRoutesRender = (routesDef: RoutesDef): JSX.Element[] => {
    const collectRoutes = (routes: RoutesDef): RoutesDefRoute[] => (
      routes.flatMap((route) => (
        ('group' in route) ?
          collectRoutes(route.children)
          : route
      ))
    )

    const routes = collectRoutes(routesDef)

    const routesNestedInBranches = remove(
      routes,
      (route) => route.path.startsWith('/branches')
    ).sort((a, b) => b.path.length - a.path.length)

    return compact([
      ...routes.map((route) => (
        <Route key={route.path} {...route} path={basePath + route.path} />
      )),
      <Route key="-1" path={basePath + NESTED_IN_BRANCH_PATH.replace(':branchId', ':branchId?')}>
        <BranchRoutes>
          {routesNestedInBranches.map((route) => (
            <Route key={route.path} {...route} path={route.path.slice(NESTED_IN_BRANCH_PATH.length)} />
          ))}
        </BranchRoutes>
      </Route>,
    ])
  }

  const menu = useMemo(() => recursiveMenuRender(filteredRoutes), [filteredRoutes, branchId])
  const routes = useMemo(() => recursiveRoutesRender(filteredRoutes), [filteredRoutes])

  return { menu, routes }
}
