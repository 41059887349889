import { isEmpty, isNil, isString } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import {
  Col, Row, Skeleton, Space, Typography,
} from 'antd'
import { Button } from 'components/buttons'
import { ListSelector, ListSelectorProps } from 'components/common/ListSelector'

import Checkbox from 'antd/lib/checkbox/Checkbox'
import { Promisable } from 'type-fest'

const DefaultSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    .ant-skeleton-title {
      height: 20px;
      margin-top: 0;
    }

    .ant-skeleton-paragraph {
      display: none
    }
  }
`

const Card = styled(Typography.Paragraph)`
  font-weight: bold;
`

export type LinkableList<Resource> = {
  title: string
  header?: React.ReactNode
  skeleton?: React.ReactNode
  resources: Resource[],
  generateOption: (resource: Resource) => string
  renderCard: (resource: Resource) => React.ReactNode
}

export type LinkableListsProps<Resource1, Resource2> = {
  loading?: boolean
  mode?: 'one-to-one' | 'one-to-many'
  flipLists?: boolean

  list1: LinkableList<Resource1>
  list2: LinkableList<Resource2>

  canCreate?: boolean | string

  linked: (value1: Resource1, value2: Resource2) => boolean

  onCreate?: (value1: Resource1) => Promisable<boolean>
  onLink: (value1: Resource1, value2: Resource2) => Promisable<boolean>
  onDelete: (value1: Resource1, value2: Resource2) => Promisable<boolean>
}

const ROW_GUTTER: [number, number] = [40, 20]
const COLUMN_SPANS = { span: 10 }

const Wrapper = styled.div`
  padding: ${ROW_GUTTER[0]}px;
  width: 1050px;

  &.loading {
    opacity: 0.7;

    &, *, input, button {
      pointer-events: none;
    }
  }
`

type UseLinkableListProps<Resource extends object> = LinkableList<Resource> & {
  selected: number | null
  onSelect: (value: number | null) => void
  linked: Record<number, number[]>
}

const useLinkableList = <Resource extends object>(props: UseLinkableListProps<Resource>) => {
  const {
    selected,
    onSelect,
    linked,
    resources,
    header,
    title,
    skeleton,
    renderCard,
    generateOption,
  } = props

  const [showLinked, setShowLinked] = useState<boolean>(true)
  const [showUnlinked, setShowUnlinked] = useState<boolean>(true)

  const selectedResource = useMemo(() => (
    isNil(selected) ? null : resources[selected]
  ), [resources, selected])

  const optionsRaw: ListSelectorProps['options'] = useMemo(() => (
    resources.map((item, index) => {
      const label = generateOption(item)

      return {
        label,
        value: index,
      }
    })
  ), [resources])

  const options = useMemo(() => (
    optionsRaw.map((item, index) => (
      { ...item, matched: !isEmpty(linked[index]) }
    )).filter(({ matched }) => {
      if (showLinked && matched) {
        return true
      }
      if (showUnlinked && matched === false) {
        return true
      }
      return false
    })
  ), [optionsRaw, linked, showLinked, showUnlinked])

  return {
    selected,
    selectedResource,
    options,
    Header: <>
      <Typography.Title level={3}>
        {header || title}
      </Typography.Title>

      {selectedResource ? (
        <Card>
          {renderCard(selectedResource)}
        </Card>
      )
        : (skeleton || <DefaultSkeleton />)}
    </>,
    List: <Space direction="vertical" style={{ width: '100%' }}>
      <strong>Search for {title}:</strong>

      <Space style={{ width: '100%' }}>
        <Checkbox
          style={{ fontSize: '0.7rem' }}
          checked={showLinked}
          onChange={() => setShowLinked((prev) => !prev)}
        > Show Matched
        </Checkbox>
        <Checkbox
          style={{ fontSize: '0.7rem' }}
          checked={showUnlinked}
          onChange={() => setShowUnlinked((prev) => !prev)}
        > Show Unmatched
        </Checkbox>
      </Space>

      <ListSelector
        mode="single"
        options={options}
        selected={selected ? [optionsRaw[selected]] : []}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onChange={(options) => {
          const value = options?.[0]?.value as number | null
          if (isNil(value)) {
            onSelect(null)
          } else {
            onSelect(value)
          }
        }}
      />
    </Space>,
  }
}

export const LinkableLists = <Resource1 extends object, Resource2 extends object>(props: LinkableListsProps<Resource1, Resource2>) => {
  const {
    loading,
    linked,
    onLink,
    onCreate,
    onDelete,
    // eslint-disable-next-line unused-imports/no-unused-vars
    flipLists,
    canCreate,
    mode,
  } = props

  const [
    linkedIndicies,
    setLinkedIndicies,
  ] = useState<{
    from1: Record<number, number[]>,
    from2: Record<number, number[]>
  }>({
    from1: {},
    from2: {},
  })

  useEffect(() => {
    const collect: typeof linkedIndicies = {
      from1: {},
      from2: {},
    }

    props.list1.resources.forEach((resource1, index1) => {
      props.list2.resources.forEach((resource2, index2) => {
        if (linked(resource1, resource2)) {
          if (!collect.from1[index1]) {
            collect.from1[index1] = []
          }
          collect.from1[index1].push(index2)

          if (!collect.from2[index2]) {
            collect.from2[index2] = []
          }
          collect.from2[index2].push(index1)
        }
      })
    })

    setLinkedIndicies(collect)
  }, [props.list1.resources, props.list2.resources])

  const [
    [selected1, selected2],
    setSelected,
  ] = useState<[number | null, number | null]>([null, null])

  const list1 = useLinkableList({
    ...props.list1,
    linked: linkedIndicies.from1,
    selected: selected1,
    onSelect: (value) => setSelected((prev) => {
      if (isNil(value)) {
        return [null, prev[1]]
      }

      const linkedIds = linkedIndicies.from1[value]
      if (isEmpty(linkedIds)) {
        const prevLinkedIds = isNil(prev[1]) ? [] : linkedIndicies.from2?.[prev[1]] || []
        const canLinkPrev = mode === 'one-to-many' || prevLinkedIds.includes(value) || isEmpty(prevLinkedIds)
        return [value, canLinkPrev ? prev[1] : null]
      }

      if (mode === 'one-to-many') {
        return [value, null]
      }
      return [value, linkedIds[0]]
    }),
  })
  const list2 = useLinkableList({
    ...props.list2,
    linked: linkedIndicies.from2,
    selected: selected2,
    onSelect: (value) => setSelected((prev) => {
      if (isNil(value)) {
        return [prev[0], null]
      }

      const linkedIds = linkedIndicies.from2[value] || []
      if (prev[0] === null) {
        return [linkedIds[0], value]
      }

      if (
        prev[0] !== null &&
        isEmpty(linkedIndicies.from1[prev[0]])
      ) {
        return [prev[0], value]
      }

      if (
        mode === 'one-to-one' &&
        prev[1] !== null &&
        !linkedIndicies.from2[value]?.includes(prev[0])
      ) {
        return [linkedIds[0], value]
      }

      return [prev[0], value]
    }),
  })

  const selectedResource1 = list1.selectedResource
  const selectedResource2 = list2.selectedResource
  const hasSelection = Boolean(selectedResource1 || selectedResource2)

  const onClear = () => {
    setSelected([null, null])
  }

  return (
    <Wrapper className={loading ? 'loading' : undefined}>
      <Row gutter={ROW_GUTTER}>
        <Col {...COLUMN_SPANS} style={{ minHeight: '80px' }}>
          {list1.Header}
        </Col>
        <Col {...COLUMN_SPANS} style={{ minHeight: '80px' }}>
          {list2.Header}
        </Col>
        <Col span={4}>
          {selectedResource1 && selectedResource2 ? (
            <>
              <Typography.Title level={3}>&nbsp;</Typography.Title>
              <Space direction="vertical">
                {selected1 !== null && selected2 !== null && linkedIndicies.from1[selected1]?.includes(selected2) ? (
                  <Button
                    block
                    danger
                    type="primary"
                    onClick={async () => {
                      const success = await onDelete(selectedResource1, selectedResource2)
                      if (success) {
                        onClear()
                      }
                    }}
                  >Delete Match
                  </Button>
                )
                  : (
                    <Button
                      block
                      type="primary"
                      onClick={async () => {
                        const success = await onLink(selectedResource1, selectedResource2)
                        if (success) {
                          onClear()
                        }
                      }}
                    >Save Match
                    </Button>
                  )}
              </Space>
            </>
          )
            : canCreate && onCreate && selectedResource1 && (
              <>
                <Typography.Title level={3}>&nbsp;</Typography.Title>
                <Space direction="vertical">
                  <Button
                    block
                    type="primary"
                    onClick={async () => {
                      const success = await onCreate(selectedResource1)
                      if (success) {
                        onClear()
                      }
                    }}
                  >Create New {isString(canCreate) && canCreate}
                  </Button>
                </Space>
              </>
            )}
        </Col>
        <Col span={24}>
          <Button disabled={!hasSelection} onClick={onClear}>Clear Selection</Button>
        </Col>
        <Col {...COLUMN_SPANS}>
          {list1.List}
        </Col>
        <Col {...COLUMN_SPANS}>
          {list2.List}
        </Col>
      </Row>
    </Wrapper>
  )
}
