export function formatToCurrency(n: number, opts?: Intl.NumberFormatOptions) {
  const defaultFormattingOpts = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }
  const formatter = new Intl.NumberFormat('en-US', (opts || defaultFormattingOpts))
  return formatter.format(n)
}

export function formatCurrencyStringToNumber(currencyString: string) {
  const replaced = currencyString.replace('$', '').trim()
  const i = replaced.indexOf('.')
  const parsingFunction = i > -1 ? Number.parseFloat : Number.parseInt
  return parsingFunction(replaced)
}
