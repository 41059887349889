import { useCreation } from 'ahooks'
import { FormItem } from 'components/form/FormItem'
import colors from 'constants/colors'
import { cloneMoment } from 'helpers/cloneMoment'
import { timeWithZoneToMoment } from 'helpers/datetime'
import { pushAt } from 'helpers/pushAt'
import { byFieldAsc, mulitSort } from 'helpers/sortting'
import {
  compact, has, isEmpty, isNil, isNumber, omit, pick, set, size, sortBy,
} from 'lodash'
import { Duration } from 'luxon'
import {
  isMoment, max as momentMax, min as momentMin, Moment,
} from 'moment'
import React, { useEffect } from 'react'
import { OrderMetric } from 'schema'
import styled from 'styled-components'
import { SetRequired } from 'type-fest'
import { OrderBillingFieldRegister, useOrderBillingState } from '../OrderBillingState'
import { HoursInput } from './fields/helpers/HoursInput'
import { TimeRangeFields } from './fields/helpers/TimeRangeFields'
import { Table } from './Table'
import { TableCol } from './TableCol'
import { TableRow } from './TableRow'
import { OrderCompareTableFragmentFragment } from './__generated__'

type Row = {
  title: React.ReactNode
  cells: Array<Cell>
  total: true
} | {
  title: React.ReactNode
  cells: Array<Cell>
  step: string
  scheduleIndex: number
}

interface Cell {
  section: string
  durationField: string
  startField?: string
  endField?: string
}

const momentIsEqual = (currentValue: Moment | undefined, newValue: Moment | undefined) => {
  if (!currentValue && !newValue) return true

  if (isMoment(currentValue) && isMoment(newValue)) {
    return currentValue.isSame(newValue)
  }

  return false
}

const buildTotalCellRegisters = (section: string, sectionCells: Cell[]) => {
  const namespace = `${section}|onSiteTotal`

  const cell = {
    durationField: `${namespace}|duration`,
    startField: `${namespace}|startTime`,
    endField: `${namespace}|endTime`,
  }

  const startFields = compact(sectionCells.map((item) => item.startField))
  const endFields = compact(sectionCells.map((item) => item.endField))

  const durationField: OrderBillingFieldRegister<number | undefined> = {
    name: cell.durationField,
    autofill: true,
    dependsOn: [cell.startField, cell.endField],
    calculate: ({ current, dependentData, changedData }) => {
      if (has(changedData, cell.durationField)) {
        return
      }

      if (
        isNil(current) ||
        (changedData[cell.startField] && !changedData[cell.endField]) ||
        (!changedData[cell.startField] && changedData[cell.endField])
      ) {
        const start = dependentData[cell.startField]
        const end = dependentData[cell.endField]
        return (isMoment(start) && isMoment(end)) ?
          end.diff(start)
          : undefined
      }
    },
  }
  durationField.suggest = durationField.calculate

  const startField: OrderBillingFieldRegister<Moment | undefined> = {
    name: cell.startField,
    autofill: true,
    isEqual: momentIsEqual,
    dependsOn: startFields,
    calculate: ({ data, changedData }) => {
      if (has(changedData, cell.startField)) {
        return
      }

      const startTimes = compact(Object.values(pick(data, startFields)))
      return momentMin(startTimes).clone()
    },
  }
  startField.suggest = startField.calculate

  const endField: OrderBillingFieldRegister<Moment | undefined> = {
    name: cell.endField,
    autofill: true,
    isEqual: momentIsEqual,
    dependsOn: [cell.durationField, cell.startField, ...endFields],
    calculate: ({ current, data, changedData }) => {
      if (has(changedData, cell.endField)) {
        return undefined
      }

      if (
        size(changedData) === 1 && (
          has(changedData, cell.startField) ||
          has(changedData, cell.durationField)
        )
      ) {
        const startTime = data[cell.startField]
        const duration = data[cell.durationField]

        if (isMoment(startTime) && !isNil(duration)) {
          const newEnd = startTime.clone().add({ milliseconds: duration })
          if (isMoment(current) && current.isSame(newEnd)) {
            return
          }
          return newEnd
        }
        return
      }

      const endTimes = compact(Object.values(pick(data, endFields)))
      return momentMax(endTimes).clone()
    },
  }
  endField.suggest = endField.calculate

  return {
    startField,
    endField,
    durationField,
  }
}

const genericRegisters = (registers: Array<OrderBillingFieldRegister<any>>) => registers

export const ScheduleTable = () => {
  const {
    details,
    form,
    order,
    placeholders,
    isEditing,
    register,
    updateValues,
  } = useOrderBillingState<OrderCompareTableFragmentFragment>()

  const rows = useCreation(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const rows: Row[] = [{
      title: 'Total',
      cells: [],
      total: true,
    }]

    if (!details) return rows
    const timezone = order?.site?.address.timezone

    const detailsArrayWithoutBillable = Object.entries(omit(details, 'billable'))

    detailsArrayWithoutBillable.forEach(([section, detail]) => {
      const sectionCells: Cell[] = []

      const scheduleSortted = (detail?.schedule || []).slice()?.sort(
        mulitSort(byFieldAsc('scheduleIndex'), byFieldAsc('startTime'))
      )

      if (isEmpty(scheduleSortted)) return

      Object.values(scheduleSortted).forEach((entry) => {
        if (entry.scheduleIndex === undefined) return

        // Total line is first
        const rowIndex = entry.scheduleIndex + 1
        if (!rows[rowIndex]) {
          rows[rowIndex] = {
            title: entry.stepDetails.name || `Step ${rowIndex}`,
            step: entry.step,
            scheduleIndex: entry.scheduleIndex,
            cells: [],
          }
        }

        const row = rows[rowIndex]
        const previousCell = rows[rowIndex - 1]?.cells.find((col) => col.section === section)

        const fieldNamespace = `${section}|onSite.${entry.scheduleIndex}`
        const cell: Cell = {
          section,
          durationField: `${fieldNamespace}|duration`,
        }
        row.cells.push(cell)
        sectionCells.push(cell)

        if (entry.startTime) {
          cell.startField = `${fieldNamespace}|startTime`
          register({
            dependsOn: compact([previousCell?.endField]),
            name: cell.startField,
            value: timeWithZoneToMoment(entry.startTime, timezone),
            isEqual: momentIsEqual,
            calculate: ({ current, changedData }) => {
              const lastEnd: Moment | undefined = changedData[previousCell?.endField || '']
              if (!lastEnd) return current
              if (!lastEnd.isSame(current)) {
                return lastEnd
              }
              return current
            },
          })
        }
        if (entry.endTime) {
          cell.endField = `${fieldNamespace}|endTime`
          register({
            dependsOn: compact([cell.startField, cell.durationField]),
            name: cell.endField,
            value: timeWithZoneToMoment(entry.endTime, timezone),
            isEqual: momentIsEqual,
            calculate: ({ current, dependentData }) => {
              const start: Moment | undefined = cell.startField && dependentData[cell.startField]
              if (!start) return current

              const durationInMillis: number | undefined = dependentData[cell.durationField]
              if (durationInMillis === undefined) return current

              const calculatedEnd = cloneMoment(start).add({ milliseconds: durationInMillis })

              if (!calculatedEnd.isSame(current)) {
                return calculatedEnd
              }

              return current
            },
          })
        }

        register({
          name: cell.durationField,
          dependsOn: compact([cell.startField, cell.endField]),
          calculate: ({ changedData }) => {
            const start: Moment | undefined = cell.startField && changedData[cell.startField]
            const end: Moment | undefined = cell.endField && changedData[cell.endField]

            if (start && end) {
              return end.diff(start)
            }
          },
        })
      })

      const totalCell = buildTotalCellRegisters(section, sectionCells)
      genericRegisters(Object.values(totalCell)).forEach(register)

      rows[0].cells.push({
        section,
        durationField: totalCell.durationField.name,
        startField: totalCell.startField.name,
        endField: totalCell.endField.name,
      })
    })

    const section = 'billable'
    const sourceSection = sortBy(rows[0].cells.map((c) => c.section), (aSection) => {
      if (aSection === 'actual.operator') return 0
      if (aSection.startsWith('actual.')) return 1
      if (aSection.startsWith('planned')) return 2
    })[0] || 'planned'

    const stepRows = compact(rows.map((row) => (('step' in row && row.step) ? row : undefined)))
    const billableCells: Cell[] = []

    stepRows.forEach((row, rowIndex) => {
      const { scheduleIndex } = row
      const fieldNamespace = `${section}|onSite.${scheduleIndex}`
      const cell: SetRequired<Cell> = {
        section,
        durationField: `${fieldNamespace}|duration`,
        startField: `${fieldNamespace}|startTime`,
        endField: `${fieldNamespace}|endTime`,
      }
      row.cells.push(cell)
      billableCells.push(cell)

      const previousCell = stepRows[rowIndex - 1]?.cells.find((col) => col.section === section)
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const entry = details.billable?.schedule.find((entry) => entry.scheduleIndex === scheduleIndex)

      const suggestionCell: SetRequired<Cell> = {
        section: sourceSection,
        durationField: `${sourceSection}|onSite.${scheduleIndex}|duration`,
        startField: `${sourceSection}|onSite.${scheduleIndex}|startTime`,
        endField: `${sourceSection}|onSite.${scheduleIndex}|endTime`,
      }

      register({
        name: cell.startField,
        value: timeWithZoneToMoment(entry?.startTime, timezone),
        isEqual: momentIsEqual,
        autofill: true,
        suggestDependsOn: [suggestionCell.startField],
        suggest: ({ dependentData }) => {
          const startTime = dependentData[suggestionCell.startField]
          if (!isMoment(startTime)) return startTime
          return startTime.clone().seconds(0)
        },
        dependsOn: compact([previousCell?.endField]),
        calculate: ({ current, changedData }) => {
          if (changedData[cell.startField]) {
            return changedData[cell.startField]
          }

          const lastEnd: Moment | undefined = changedData[previousCell?.endField || '']
          if (!lastEnd) return current
          if (!lastEnd.isSame(current)) {
            return lastEnd
          }
          return current
        },
        onSave: (update, value) => {
          set(update, ['billable', 'schedule', 'set', scheduleIndex, 'data', 'step'], row.step)
          set(update, ['billable', 'schedule', 'set', scheduleIndex, 'data', 'startTime'], value?.toISOString())
          return update
        },
      })

      register({
        name: cell.endField,
        value: timeWithZoneToMoment(entry?.endTime, timezone),
        isEqual: momentIsEqual,
        autofill: true,
        suggestDependsOn: [suggestionCell.endField],
        suggest: ({ dependentData, data }) => {
          const suggestedEnd = dependentData[suggestionCell.endField]
          if (!isMoment(suggestedEnd)) return suggestedEnd

          const suggestedStart = data[cell.startField]

          if (isMoment(suggestedStart)) {
            if (suggestedEnd.isBefore(suggestedStart)) {
              return suggestedStart.clone().seconds(0)
            }
          }

          return suggestedEnd.clone().seconds(0)
        },
        dependsOn: compact([cell.startField, cell.durationField]),
        calculate: ({ current, dependentData, changedData }) => {
          if (changedData[cell.endField]) {
            return changedData[cell.endField]
          }

          const start: Moment | undefined = cell.startField && dependentData[cell.startField]
          if (!start) return current

          const durationInMillis: number | undefined = dependentData[cell.durationField]
          if (durationInMillis === undefined) return current

          const calculatedEnd = cloneMoment(start).add({ milliseconds: durationInMillis })

          if (!calculatedEnd.isSame(current)) {
            return calculatedEnd
          }

          return current
        },
        onSave: (update, value) => {
          set(update, ['billable', 'schedule', 'set', scheduleIndex, 'data', 'step'], row.step)
          set(update, ['billable', 'schedule', 'set', scheduleIndex, 'data', 'endTime'], value?.toISOString())
          return update
        },
      })

      const durationRegister: OrderBillingFieldRegister<number | undefined> = {
        name: cell.durationField,
        dependsOn: compact([cell.startField, cell.endField]),
        calculate: ({ current, changedData, dependentData }) => {
          const hasChanges = changedData[cell.startField] || changedData[cell.endField]
          if (!hasChanges) return current

          const start: Moment | undefined = dependentData[cell.startField]
          const end: Moment | undefined = dependentData[cell.endField]

          if (start && end) {
            return end.diff(start)
          }
        },
      }
      durationRegister.suggest = durationRegister.calculate
      register(durationRegister)
    })

    const billableTotalCell = buildTotalCellRegisters('billable', billableCells)

    billableTotalCell.durationField = {
      ...billableTotalCell.durationField,
      autofill: true,
      value: hoursToMillis(
        details?.billable?.metrics?.find((item) => item.key === 'onSiteHours')?.value
      ),
      onSave: (update, value) => {
        if (isNil(value)) return update
        const asHours = Duration.fromMillis(value).as('hours')
        return pushAt(update, ['billable', 'metrics', 'upsert'], {
          key: 'onSiteHours',
          value: asHours,
        })
      },
    }

    genericRegisters(Object.values(billableTotalCell)).forEach(register)

    rows[0].cells.push({
      section,
      durationField: billableTotalCell.durationField.name,
      startField: billableTotalCell.startField.name,
      endField: billableTotalCell.endField.name,
    })

    return rows
  }, [details])

  // this ensures the billable value doesn't get overwritten on initial calculated
  // probably a better way to do this though
  useEffect(() => {
    const initialBillableValue = hoursToMillis(
      details?.billable?.metrics?.find((item) => item.key === 'onSiteHours')?.value
    )

    if (isNil(initialBillableValue)) return

    const currentValues = form.getFieldsValue()
    const scheduleKeys = rows.flatMap((row) => row.cells.flatMap((cell) => compact([cell.durationField, cell.startField, cell.endField])))
    const scheduleValues = pick(currentValues, scheduleKeys)

    updateValues(scheduleValues)
  }, [order])

  const formValues = form.getFieldsValue()

  const highlightFor = ([section, cell]: string[]) => {
    if (!isEditing('billable')) return
    if (!section.startsWith('actual.')) return
    if (cell !== 'onSiteTotal') return

    const durationActuals = Object.keys(formValues).filter((key) => {
      const k = key.split('|')
      return k[0].startsWith('actual.') && k[1] === cell && k[2] === 'duration'
    })

    if (durationActuals.length <= 1) return

    const durationValues = Object.values(pick(formValues, durationActuals))
    const minDuration = Math.min(...durationValues)
    const maxDuration = Math.max(...durationValues)

    const diffPercent = ((maxDuration - minDuration) / maxDuration) * 100

    if (diffPercent > 20) {
      return colors.performance.veryLate
    }
    if (diffPercent > 10) {
      return colors.performance.late
    }
  }

  return (
    <Table title="On-Site Schedule">
      {rows.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          <TableCol section="title">
            {row.title}
          </TableCol>

          {row.cells.map((cell, colIndex) => (
            <HiglightableTableCol
              section={cell.section}
              key={colIndex}
              highlight={highlightFor(cell.durationField.split('|').slice(0, 2))}
            >
              <FormItem name={cell.durationField}>
                <HoursInput
                  editing={isEditing(cell.section as any)}
                  placeholder={placeholders[cell.durationField]}
                />
              </FormItem>

              <div style={{ visibility: (cell.section === 'billable' && rowIndex > 0) ? 'hidden' : undefined }}>
                <TimeRangeFields
                  editing={isEditing('billable') && cell.section === 'billable' && rowIndex === 0}
                  startField={cell.startField}
                  endField={cell.endField}
                  relativeTimezone={order?.branch?.timezone}
                />
              </div>
            </HiglightableTableCol>
          ))}
        </TableRow>
      ))}
    </Table>
  )
}

const HiglightableTableCol = styled(TableCol) <{ highlight?: string }>`
  ${(props) => props.highlight && `
    &, input {
      color: ${props.highlight};
      font-weight: 500;
    }
  `}
`

const hoursToMillis = (value: OrderMetric['value']) => {
  if (isNil(value)) return undefined
  const hours = isNumber(value) ? value : parseFloat(value)
  return Duration.fromObject({ hours }).as('milliseconds')
}
