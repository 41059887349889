import { bySortField } from 'helpers/sortting'
import { compact } from 'lodash'

import { StatusBadge } from 'components/common/StatusBadge'
import { ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { RecursivePartial } from 'types'
import { InvoiceStatus, useInvoiceStatuses } from '../../../hooks/useInvoices'

export type InvoiceStatusSelectProps = ReferenceSelectProps<InvoiceStatus> & {
  iconOnly?: boolean
}

const invoiceStatusOptionGenerator = (invoiceStatus?: RecursivePartial<InvoiceStatus> | null) => {
  if (!invoiceStatus?.slug) return

  return {
    label: <StatusBadge status={invoiceStatus} />,
    labelIconOnly: <StatusBadge iconOnly size="large" status={invoiceStatus} style={{ top: '-1px' }} />,
    title: invoiceStatus.name,
    value: invoiceStatus.slug,
  }
}

const defaults: Partial<InvoiceStatusSelectProps> = {
  defaultActiveFirstOption: true,
  showSearch: true,
  filterOption: (val: string, opt) => opt?.title?.toLowerCase().includes(val.toLowerCase()),
  optionsGenerator: (invoiceStatuses) => (
    compact([...invoiceStatuses].sort(bySortField).map(invoiceStatusOptionGenerator))
  ),
}

export const InvoiceStatusSelect = (props: InvoiceStatusSelectProps) => {
  const { iconOnly, ...rest } = props

  const args = {
    ...defaults,
    remoteResources: useInvoiceStatuses(),
    ...rest,
  }

  if (iconOnly) {
    args.optionLabelProp = 'labelIconOnly'
  }

  return ReferenceSelect(args)
}

export default InvoiceStatusSelect
