import { defaults } from 'lodash'

type ItemWithChildren<
  T extends object,
  ChildrenKey extends string
> = T & {
  [C in ChildrenKey]?: Array<ItemWithChildren<T, C>>
}

export function treeFromFlatArray<
  Item extends object,
  ChildrenKey extends string = 'children'
>(
  arr: Array<Item>,
  idKey: keyof Item,
  parentKey: keyof Item,
  childrenKey: ChildrenKey = ('children' as ChildrenKey)
): Array<ItemWithChildren<Item, ChildrenKey>> {
  const byId = new Map<any, ItemWithChildren<Item, ChildrenKey>>()
  arr.forEach((item) => {
    byId.set(item[idKey], { ...item })
  })

  const nodes = Array.from(byId.values())

  return nodes.filter((item) => {
    const id = item[idKey]
    if (!id) return false

    byId.set(id, defaults(item, byId.get(id)))

    const parentId = byId.get(id)?.[parentKey]
    const parent = parentId && byId.get(parentId)

    if (parent) {
      if (!parent[childrenKey]) {
        parent[childrenKey] = [] as any
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parent[childrenKey]!.push(item)
    }

    return parentId === null || parentId === undefined
  })
}
