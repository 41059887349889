import { Divider as AntDivider } from 'antd'
import styled from 'styled-components'

export const Divider = styled(AntDivider).attrs((props) => ({
  thin: false as boolean,
  ...props,
}))`

  ${(props) => props.thin && `
    margin-top: 10px;
    margin-bottom: 10px;
  `}
`
