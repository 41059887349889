import { useMutation, useQuery } from '@apollo/client'
import {
  Typography,
} from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'
import { Form } from 'components/form/Form'
import { GET_ORDER_COMMENTS, UPDATE_ORDER_COMMENTS } from 'gql/comments'
import { byFieldAsc } from 'helpers/sortting'
import { omit } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import {
  Comment, Mutation, MutationUpdateOrderArgs, Query, QueryOrdersArgs,
} from 'schema'
import styled from 'styled-components'
import { CommentsFormList } from '../common/CommentsFormList'

const { Paragraph } = Typography

const CommentsLoadingWrapper = styled.div`
  height: 100%;
  width: 100%;

  &.loading .ant-comment.new-comment {
    opacity: 0;
  }
`

interface CommentsFormBaseProps {
  id?: number
  editing?: boolean
  onSubmission?: (comments: Comment[]) => void
  onCancel?: () => void
}

interface CommentsFormProps extends CommentsFormBaseProps {
  orderId?: number
}

export interface OrderCommentsFormProps extends CommentsFormBaseProps {
  id?: number
}

export const OrderCommentsForm = (props: OrderCommentsFormProps) => {
  const { id, ...rest } = props
  if (!id) return null
  return <CommentsForm {...rest} orderId={id} />
}

const useOrderComments = (orderId: number) => {
  const { data, loading: queryLoading, error: queryError } = useQuery<Query, QueryOrdersArgs>(
    GET_ORDER_COMMENTS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
      variables: {
        where: {
          id: {
            equals: orderId,
          },
        },
      },
    }
  )

  const order = queryLoading ? undefined : data?.orders?.[0]
  const comments = useMemo(() => (
    (order?.comments || []).slice().sort(byFieldAsc('createdAt'))
  ), [order?.comments])

  const [
    updateOrder,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<Mutation, MutationUpdateOrderArgs>(UPDATE_ORDER_COMMENTS, {
    refetchQueries: [
      'GetOrderComments',
    ],
  })

  const updateComments = async (commentsInput: Comment[]): Promise<Comment[]> => {
    if (!order) return comments

    const newComments = (commentsInput || [])
      .filter((comment) => !comment.id && comment.text)
      .map((comment) => omit(comment, ['additionalIds', 'metadata', 'person']))

    if (newComments.length === 0) {
      return comments
    }

    const updated = await updateOrder({
      variables: {
        data: {
          revision: order.revision,
          comments: {
            create: newComments,
          },
        },
        where: {
          id: order.id,
        },
      },
    })

    return updated?.data?.updateOrder?.comments || []
  }

  return {
    comments,
    updateComments,
    loading: queryLoading || mutationLoading,
    error: queryError || mutationError,
  }
}

const CommentsForm: React.FC<CommentsFormProps> = (props) => {
  if (!props.orderId) return null

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form] = Form.useForm<{ comments: Comment[] }>()

  const {
    comments, updateComments, loading, error,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useOrderComments(props.orderId)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    form.setFieldsValue({ comments })
  }, [comments])

  const onFinish = async (values: any) => {
    const updated = await updateComments(values?.comments)

    if (props.onSubmission) {
      props.onSubmission(updated)
    }
  }

  return (
    <>
      {error && (
        <ErrorMessage>
          <Paragraph>{error.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="comments-form"
        onFinish={onFinish}
        requiredMark={props.editing}
        initialValues={{ comments }}
        style={{ height: '100%' }}
      >
        <CommentsLoadingWrapper className={loading ? 'loading' : ''}>
          <CommentsFormList />
        </CommentsLoadingWrapper>
      </Form>
    </>
  )
}
