import { gql } from '@apollo/client'
import { PHONE_FRAGMENT } from './phones'

export const CONTACT_SLIM_FRAGMENT = gql`
  fragment ContactFields on Contact {
    id
    firstName
    lastName
    title
    emails
    active
    createdAt
    updatedAt
    phones {
      ...PhoneFields
    }
    roles {
      id
      name
      active
    }
    customers {
      id
      name
      active
    }
  }
  ${PHONE_FRAGMENT}
`

const CONTACT_FULL_FRAGMENT = gql`
  fragment ContactFieldsFull on Contact {
    id
    firstName
    lastName
    title
    notes
    emails
    active
    createdAt
    updatedAt
    additionalIds {
      key
      value
    }
    preferences {
      messages {
        path
        name
        description
        optedIn
        isDefault
      }
    }
    phones {
      ...PhoneFields
    }
    roles {
      id
      name
      active
    }
    customers {
      id
      name
      active
    }
  }
  ${PHONE_FRAGMENT}
`

export const GET_CONTACTS = gql`
  query GetContact(
    $orderBy: [QueryContactsOrderByInput!],
    $where: QueryContactsWhereInput
  ) {
    contacts(
      orderBy: $orderBy,
      where: $where
    ) {
      ...ContactFields
    }
  }
`

export const GET_CONTACTS_SLIM = gql`
  ${GET_CONTACTS}
  ${CONTACT_SLIM_FRAGMENT}
`

export const GET_CONTACTS_FULL = gql`
  query GetContactsFull(
    $orderBy: [QueryContactsOrderByInput!],
    $where: QueryContactsWhereInput
  ) {
    contacts(
      orderBy: $orderBy,
      where: $where
    ) {
      ...ContactFieldsFull
    }
  }
  ${CONTACT_FULL_FRAGMENT}
`

export const CREATE_CONTACT = gql`
  mutation CreateContact(
    $data: ContactInput!
  ) {
    createContact(
      data: $data
    ) {
      ...ContactFields
    }
  }
  ${CONTACT_SLIM_FRAGMENT}
`

export const CREATE_CONTACT_V2 = gql`
  mutation CreateContactV2(
    $data: ContactInput!
  ) {
    contact {
      create(data: $data) {
        contact { ...ContactFields }
        warnings {
          invalidPhones
          landlinePhones
        }
      }
    }
  }
  ${CONTACT_SLIM_FRAGMENT}
`

export const UPDATE_CONTACT = gql`
  mutation UpdateContact(
    $data: ContactUpdateInput!
    $where: ContactWhereUniqueInput!
  ) {
    updateContact(
      data: $data
      where: $where
    ) {
      ...ContactFields
    }
  }
  ${CONTACT_SLIM_FRAGMENT}
`

export const UPDATE_CONTACT_V2 = gql`
  mutation UpdateContactV2(
    $data: ContactUpdateInput!
    $where: ContactWhereUniqueInput!
  ) {
    contact {
      update(data: $data, where: $where) {
        contact { ...ContactFields }
        warnings {
          invalidPhones
          landlinePhones
        }
      }
    }
  }
  ${CONTACT_SLIM_FRAGMENT}
`
