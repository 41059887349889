import { gql } from '@apollo/client'
import { Divider } from 'antd'
import { AddItemButton } from 'components/buttons'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { kebabCase, uniqBy } from 'lodash'
import React, { useRef, useState } from 'react'
import { CredentialType } from 'schema'
import Input from '../inline-edit/Input'

export type CredentialTypeSelectProps = ReferenceSelectProps<CredentialType>

const GET_CREDENTIAL_TYPES = gql`
  query GetCredentialTypes($where: QueryCredentialTypesWhereInput) {
    credentialTypes(where: $where) {
      slug
      name
    }
  }
`

const CredentialTypeDropdown = ({
  menu,
  onChange,
}: {
  menu: Parameters<NonNullable<CredentialTypeSelectProps['dropdownRender']>>[0],
  onChange: CredentialTypeSelectProps['onChange']
}) => {
  const [value, setValue] = useState<string>()

  const onSubmit = () => {
    if (!onChange) return
    if (!value) return

    const slug = kebabCase(value)
    onChange(slug, {
      key: slug,
      label: value,
      value: slug,
    })
    setValue('')
  }

  return (
    <div>
      {menu}
      {onChange && (
        <>
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              value={value}
              onChange={(event) => {
                setValue(event.currentTarget.value)
              }}
              onPressEnter={onSubmit}
            />
            <AddItemButton
              onClick={onSubmit}
            >
              Add a Type
            </AddItemButton>
          </div>
        </>
      )}
    </div>
  )
}

export const CredentialTypeSelect: React.FC<CredentialTypeSelectProps> = (props) => {
  const localCreatedResources = useRef<{
    slug: any,
    name: any
  }[]>([])

  const propsFromGql = buildPropsFromGql({
    name: 'Credential Type',
    queryField: 'credentialTypes',
    gql: GET_CREDENTIAL_TYPES,
  }, props)

  propsFromGql.dropdownRender = (menu) => (
    <CredentialTypeDropdown
      menu={menu}
      onChange={(value, option) => {
        if (propsFromGql.onChange) {
          if ('label' in option) {
            localCreatedResources.current.push({
              slug: option.value,
              name: option.label,
            })
          }
          propsFromGql.onChange(value, option)
        }
      }}
    />
  )

  propsFromGql.resources = uniqBy([
    ...localCreatedResources.current,
    ...(props.resources || []),
  ], 'slug')

  return <ReferenceSelect {...propsFromGql} />
}

export default CredentialTypeSelect
