import { Button, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { BaseType } from 'antd/lib/typography/Base'

export interface ErrorMessageProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string
  to?: string
  size?: 'small' | 'medium'
  type?: BaseType
}

export const ErrorMessage = styled(({
  children, to, size, title, type, ...divProps
}: ErrorMessageProps) => {
  const typographyType = type || 'danger'
  return (
    (
      <div {...divProps}>
        {title !== null &&
          <Typography.Title level={size === 'small' ? 5 : 3} type={typographyType}>{title || 'Error'}</Typography.Title>}
        {children}
        {to && (
          <Link to={to}>
            <Button>
              Go Back
            </Button>
          </Link>
        )}
      </div>
    )
  )
})``

export default ErrorMessage
