import { gql } from '@apollo/client'
import {
  Col, Descriptions, Divider, Row,
} from 'antd'
import { SkeletonParagraphProps } from 'antd/lib/skeleton/Paragraph'
import { Address } from 'components/common/Address'
import { FullName } from 'components/common/FullName'
import { OrderContactList } from 'components/common/OrderContactList'
import { PhoneList } from 'components/common/PhoneList'
import { Skeleton } from 'components/common/Skeleton'
import { WithViewMore } from 'components/common/WithViewMore'
import { CustomerDrawer } from 'components/drawers/CustomerDrawer'
import { SiteDrawer } from 'components/drawers/SiteDrawer'
import { FormItem } from 'components/form/FormItem'
import { BillableEquipmentSelect } from 'components/form/reference-selects/BillableEquipmentSelect'
import { formatRelativeTime, timeWithZone } from 'helpers/datetime'
import { pourNames } from 'helpers/pourNames'
import { compact, isNil, set } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { RouteStatus } from 'schema'
import styled from 'styled-components'
import { useOrderBillingState } from './OrderBillingState'
import { OrderShowOverviewFragmentFragment } from './__generated__/Overview'

gql`
  fragment OrderShowOverviewFragment on Order {
    id
    dateOfService
    flatRate
    cashOnDelivery
    assignments {
      id
      operator {
        id
        firstName
        lastName
      }
      roleDetails {
        slug
        name
      }
    }
    billable {
      equipment {
        id
        name
        displayName
      }
    }
    contacts {
      id
      default
      contact {
        id
        firstName
        lastName
      }
      role {
        slug
        name
      }
    }
    customer {
      id
      name
      emails
      billingAddress {
        id
        street
        street2
        city
        state
        zip
      }
      phones {
        id
        number
        typeDetails {
          slug
          name
        }
      }
    }
    planned {
      id
      equipment {
        id
        name
        displayName
      }
      schedule {
        id
        pour {
          id
          name
        }
      }
    }
    site {
      id
      name
      address {
        id
        street
        street2
        city
        state
        zip
        timezone
      }
    }
    route {
      id
      status
    }
  }
`

const SentEquipmentCallout = styled(({ order, className }: { className?: string, order: OrderShowOverviewFragmentFragment | undefined }) => {
  if (!order) return null

  const billableEquipment = order?.billable?.equipment
  const plannedEquipment = order?.planned?.equipment
  const plannedEquipmentIsSame = Boolean(plannedEquipment && billableEquipment && plannedEquipment.displayName === billableEquipment.displayName)

  if (plannedEquipmentIsSame) return null

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const statusText = [RouteStatus.Completed, RouteStatus.InProgress].includes(order?.route?.status!) ? 'Sent' : 'Planned'

  return <span className={className}>{statusText}: {plannedEquipment?.name} </span>
})`
  display: block;
  font-size: 0.8em;
  font-style: italics;
  margin-top: 3px;
`

const BillableEquipmentSelectInline = styled(BillableEquipmentSelect).attrs({
  bordered: false,
  placeholder: '',
  dropdownMatchSelectWidth: false,
})`
  min-width: 100px;

  &.ant-select .ant-select-selector {
    padding-left: 0;

    .ant-select-selection-search {
      left: 0;
    }
  }
`

export const Overview = () => {
  const {
    loading, order, register, isEditing,
  } = useOrderBillingState<OrderShowOverviewFragmentFragment>()
  const customer = order?.customer
  const dateOfService = timeWithZone(order?.dateOfService, order?.site?.address?.timezone)
  const lots = pourNames(order || undefined).join(', ')

  useEffect(() => {
    register({
      name: 'billable|equipmentId',
      value: order?.billable?.equipment?.id,
      onSave: (update, value) => {
        if (isNil(value)) return update
        if (value === order?.billable?.equipment?.id) return update
        set(update, ['billable', 'equipmentId'], value)
        return update
      },
    })
  }, [order])

  // eslint-disable-next-line @typescript-eslint/no-shadow, react/no-unstable-nested-components
  const Skeleton: typeof WithSkeleton = (props) => <WithSkeleton loading={loading} {...props} />

  return (
    <Row gutter={16}>
      <Col flex="1 0 350px">
        <Divider orientation="left">Order Info</Divider>
        <Descriptions bordered column={1} size="small" labelStyle={{ width: '170px' }}>
          <Descriptions.Item label="Date of Service">
            <Skeleton>
              {dateOfService && (
                <>
                  {dateOfService?.toFormat('D')} @ {formatRelativeTime(dateOfService, DateTime.local())}
                </>
              )}
            </Skeleton>
          </Descriptions.Item>

          <Descriptions.Item label="Billable Equipment">
            <Skeleton>
              <FormItem name="billable|equipmentId" noStyle>
                <BillableEquipmentSelectInline
                  editing={isEditing('billable')}
                  resources={compact([order?.billable?.equipment])}
                />
              </FormItem>
              <SentEquipmentCallout order={order} />
            </Skeleton>
          </Descriptions.Item>

          <Descriptions.Item label="Site">
            <WithViewMore
              // eslint-disable-next-line react/no-unstable-nested-components
              drawer={() => {
                const siteId = order?.site?.id
                if (!siteId) return
                const customerId = order?.customer?.id
                if (!customerId) return
                return <SiteDrawer key={siteId} id={siteId} customerId={customerId} />
              }}
            >
              <Skeleton rows={3}>
                {order?.site?.name}<br />
                {lots && <> {lots} <br /> </>}
                <Address address={order?.site?.address} />
              </Skeleton>
            </WithViewMore>
          </Descriptions.Item>

          <Descriptions.Item label="Operators">
            <Skeleton>
              {order?.assignments?.map((assignment) => (
                assignment.operator && (
                  <React.Fragment key={assignment.id}>
                    <FullName {...assignment?.operator} />
                    ( {assignment?.roleDetails?.name} ) <br />
                  </React.Fragment>
                )
              ))}
            </Skeleton>
          </Descriptions.Item>

          <Descriptions.Item label="Flat rate">
            <Skeleton width="30px">
              {order?.flatRate ? 'Yes' : 'No'}
            </Skeleton>
          </Descriptions.Item>

          <Descriptions.Item label="Collect on delivery">
            <Skeleton width="30px">
              {order?.cashOnDelivery ? 'Yes' : 'No'}
            </Skeleton>
          </Descriptions.Item>
        </Descriptions>
      </Col>

      <Col flex="1 0 350px">
        <Divider orientation="left">Customer Info</Divider>
        <Descriptions bordered column={1} size="middle" labelStyle={{ width: '170px' }}>
          <Descriptions.Item label="Customer">
            <WithViewMore
              // eslint-disable-next-line react/no-unstable-nested-components
              drawer={() => {
                const customerId = order?.customer?.id
                if (!customerId) return
                return <CustomerDrawer key={customerId} id={customerId} />
              }}
            >
              <Skeleton width="80%">
                {customer?.name}
              </Skeleton>
            </WithViewMore>
          </Descriptions.Item>

          <Descriptions.Item label="Phones">
            <Skeleton>
              <PhoneList phones={customer?.phones || []} />
            </Skeleton>
          </Descriptions.Item>

          <Descriptions.Item label="Billing Address">
            <Skeleton rows={2}>
              <Address address={customer?.billingAddress} />
            </Skeleton>
          </Descriptions.Item>

          <Descriptions.Item label="Contact">
            <Skeleton>
              <OrderContactList contacts={order?.contacts} />
            </Skeleton>
          </Descriptions.Item>
        </Descriptions>

      </Col>
    </Row>
  )
}

interface WithSkeletonProps extends SkeletonParagraphProps {
  children?: React.ReactNode
  loading?: boolean
}

const WithSkeleton = ({ children, loading, ...paragraphProps }: WithSkeletonProps) => {
  if (loading) {
    const paragraph: SkeletonParagraphProps = {
      rows: 1,
      className: 'thin',
      style: {
        marginBottom: 0,
        ...paragraphProps.style,
      },
      ...paragraphProps,
    }
    return <Skeleton paragraph={paragraph} title={false} active loading />
  }
  return <>{children}</>
}
