import {
  DataGrid, DataGridCalculateArgs, DataGridMiddleware, DataGridRegistration,
} from './DataGrid'

type Data = Record<string, any>

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export interface SuggestableDataGridRegistration<ValueType extends any = any> extends DataGridRegistration<ValueType> {
  suggestDependsOn?: string[]
  suggest?: (args: DataGridCalculateArgs<any>) => any
}

export class SuggestableDataGrid {
  protected _dataGrid: DataGrid = new DataGrid()
  protected _suggestionGrid: DataGrid = new DataGrid()
  protected _now: number = Date.now()

  get data() {
    return this._dataGrid.data
  }

  get suggestions() {
    return this._suggestionGrid.data
  }

  applyOnCalculateMiddleware(middleware: DataGridMiddleware) {
    this._dataGrid.applyOnCalculateMiddleware(middleware)
  }

  applyOnSuggestMiddleware(middleware: DataGridMiddleware) {
    this._suggestionGrid.applyOnCalculateMiddleware(middleware)
  }

  put(updatedData: Data) {
    const dataUpdate = this._dataGrid.put(updatedData)
    const suggestionUpdate = this._suggestionGrid.put({
      ...this._suggestionGrid.data,
      ...this._dataGrid.data,
    })
    return {
      ...dataUpdate,
      changedSuggestions: suggestionUpdate.changedData,
      calculatedSuggestions: suggestionUpdate.calculatedData,
    }
  }

  patch(updatedData: Partial<Data>) {
    const dataUpdate = this._dataGrid.patch(updatedData)
    if (!dataUpdate.updated) {
      return {
        updated: false,
      }
    }

    const suggestionUpdate = this._suggestionGrid.patch({
      ...dataUpdate.changedData,
      ...dataUpdate.calculatedData,
    })

    return {
      ...dataUpdate,
      changedSuggestions: suggestionUpdate.changedData,
      calculatedSuggestions: suggestionUpdate.calculatedData,
    }
  }

  register(registration: SuggestableDataGridRegistration) {
    const { suggestDependsOn, suggest, ...dataGridRegistration } = registration

    this._dataGrid.register(dataGridRegistration)

    if (suggest) {
      this._suggestionGrid.register({
        name: dataGridRegistration.name,
        dependsOn: suggestDependsOn || dataGridRegistration.dependsOn,
        calculate: suggest,
        isEqual: dataGridRegistration.isEqual,
      })
    }
  }

  unregister(name: SuggestableDataGridRegistration['name']) {
    this._dataGrid.unregister(name)
    this._suggestionGrid.unregister(name)
  }
}
