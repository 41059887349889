import { useSelectedBranch } from '../../../hooks/useBranch'

type SchedulerSettings = {
  defaultScrollTime: string
}

const BRANCHES = {
  jmcp_0: 535,
}

const BRANCH_SETTINGS: Record<number, SchedulerSettings> = {
  [BRANCHES.jmcp_0]: {
    defaultScrollTime: '06:00:00',
  },
}

const DEFAULTS: SchedulerSettings = {
  defaultScrollTime: '00:00:00',
}

export const useSchedulerSettings = (): SchedulerSettings => {
  const { branchId } = useSelectedBranch()
  return (branchId && BRANCH_SETTINGS[branchId]) || DEFAULTS
}
