import CoordinatesParser from 'coordinate-parser'
import { round } from 'lodash'

type Coordinate = string | number

export type CoordinateParser = (coordinate: Coordinate) => number | null

// takes a coordinate in a certain format and returns a 0'ed out version
const mirroredEmptyCoordinate = (coordinate: Coordinate) => (
  coordinate.toString().replaceAll(/\d/g, '0').replaceAll(/[a-z]/gi, '')
)

export const parseLatitude: CoordinateParser = (lat) => {
  const emptyLng = mirroredEmptyCoordinate(lat)
  const coordinates = parseCoordinates(`${lat} ${emptyLng}`)
  if (!coordinates) return null
  return coordinates[0]
}

export const parseLongitude: CoordinateParser = (lng) => {
  const emptyLat = mirroredEmptyCoordinate(lng)
  const coordinates = parseCoordinates(`${emptyLat} ${lng}`)
  if (!coordinates) return null
  return coordinates[1]
}

export const parseCoordinates = (coordinates: string): [number, number] | null => {
  try {
    const parsed = new CoordinatesParser(coordinates)
    return [round(parsed.getLatitude(), 6), round(parsed.getLongitude(), 6)]
  } catch (err: any) {
    // console.error(`Coordinates parsing error: ${err.message}`)
    return null
  }
}
