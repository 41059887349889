import { gql, useQuery } from '@apollo/client'
import { compact, isEmpty } from 'lodash'
import { Duration } from 'luxon'
import { useMemo } from 'react'
import { Address, Query } from 'schema'

export const useOutgoingTravelEstimates = (fromAddressId: Address['id'] | undefined | null, toAddressIds: Array<Address['id']>) => {
  const skip = !fromAddressId || isEmpty(toAddressIds)

  const { data, ...rest } = useQuery<Query>(ADRESSES_WITH_OUTGOING_TRAVEL_ESTIMATES, {
    skip,
    variables: {
      addressId: fromAddressId,
      toAddressIds,
    },
  })

  const dataProcessesed = useMemo(() => (
    compact((data?.addresses?.[0]?.estimateTravelTo || []).map((estimate) => {
      if (!estimate.toAddressId || !estimate.duration) return

      return {
        fromAddressId,
        toAddressId: estimate.toAddressId,
        duration: Duration.fromMillis(estimate.duration * 1000),
      }
    }))
  ), [data])

  return {
    data: dataProcessesed,
    ...rest,
  }
}

const ADRESSES_WITH_OUTGOING_TRAVEL_ESTIMATES = gql`
  query GetAddressTravelEstimates($addressId: Int!, $toAddressIds: [Int!]!) {
    addresses(where: { id: { equals: $addressId }}) {
      id
      estimateTravelTo(addressIds: $toAddressIds) {
        toAddressId
        duration
      }
    }
  }
`
