import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOperatorForFormQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetOperatorForFormQuery = { __typename?: 'Query', operator?: { __typename?: 'Operator', id: number, firstName?: string | null, lastName?: string | null, active: boolean, createdAt: Types.Scalars['DateTime'], updatedAt: Types.Scalars['DateTime'], credentials: Array<{ __typename?: 'Credential', id: number, number?: string | null, images: Array<string>, expirationDate?: any | null, type: string, active: boolean, typeDetails: { __typename?: 'CredentialType', slug: string, name: string } }>, phones: Array<{ __typename?: 'Phone', id: number, number: string, type: string, sms: boolean, active: boolean }> } | null };

export type CreateOperatorMutationVariables = Types.Exact<{
  data: Types.OperatorInput;
}>;


export type CreateOperatorMutation = { __typename?: 'Mutation', createOperator?: { __typename?: 'Operator', id: number, firstName?: string | null, lastName?: string | null, active: boolean, createdAt: Types.Scalars['DateTime'], updatedAt: Types.Scalars['DateTime'], credentials: Array<{ __typename?: 'Credential', id: number, number?: string | null, images: Array<string>, expirationDate?: any | null, type: string, active: boolean, typeDetails: { __typename?: 'CredentialType', slug: string, name: string } }>, phones: Array<{ __typename?: 'Phone', id: number, number: string, type: string, sms: boolean, active: boolean }> } | null };

export type UpdateOperatorMutationVariables = Types.Exact<{
  data: Types.OperatorUpdateInput;
  where: Types.OperatorWhereUniqueInput;
}>;


export type UpdateOperatorMutation = { __typename?: 'Mutation', updateOperator?: { __typename?: 'Operator', id: number, firstName?: string | null, lastName?: string | null, active: boolean, createdAt: Types.Scalars['DateTime'], updatedAt: Types.Scalars['DateTime'], credentials: Array<{ __typename?: 'Credential', id: number, number?: string | null, images: Array<string>, expirationDate?: any | null, type: string, active: boolean, typeDetails: { __typename?: 'CredentialType', slug: string, name: string } }>, phones: Array<{ __typename?: 'Phone', id: number, number: string, type: string, sms: boolean, active: boolean }> } | null };

export type OperatorForFormFragment = { __typename?: 'Operator', id: number, firstName?: string | null, lastName?: string | null, active: boolean, createdAt: Types.Scalars['DateTime'], updatedAt: Types.Scalars['DateTime'], credentials: Array<{ __typename?: 'Credential', id: number, number?: string | null, images: Array<string>, expirationDate?: any | null, type: string, active: boolean, typeDetails: { __typename?: 'CredentialType', slug: string, name: string } }>, phones: Array<{ __typename?: 'Phone', id: number, number: string, type: string, sms: boolean, active: boolean }> };

export const OperatorForFormFragmentDoc = gql`
    fragment OperatorForForm on Operator {
  id
  firstName
  lastName
  active
  createdAt
  updatedAt
  credentials {
    id
    number
    images
    expirationDate
    type
    typeDetails {
      slug
      name
    }
    active
  }
  phones {
    id
    number
    type
    sms
    active
  }
}
    `;
export const GetOperatorForFormDocument = gql`
    query GetOperatorForForm($id: Int!) {
  operator(id: $id) {
    ...OperatorForForm
  }
}
    ${OperatorForFormFragmentDoc}`;

/**
 * __useGetOperatorForFormQuery__
 *
 * To run a query within a React component, call `useGetOperatorForFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorForFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorForFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOperatorForFormQuery(baseOptions: Apollo.QueryHookOptions<GetOperatorForFormQuery, GetOperatorForFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperatorForFormQuery, GetOperatorForFormQueryVariables>(GetOperatorForFormDocument, options);
      }
export function useGetOperatorForFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorForFormQuery, GetOperatorForFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperatorForFormQuery, GetOperatorForFormQueryVariables>(GetOperatorForFormDocument, options);
        }
export type GetOperatorForFormQueryHookResult = ReturnType<typeof useGetOperatorForFormQuery>;
export type GetOperatorForFormLazyQueryHookResult = ReturnType<typeof useGetOperatorForFormLazyQuery>;
export type GetOperatorForFormQueryResult = Apollo.QueryResult<GetOperatorForFormQuery, GetOperatorForFormQueryVariables>;
export function refetchGetOperatorForFormQuery(variables: GetOperatorForFormQueryVariables) {
      return { query: GetOperatorForFormDocument, variables: variables }
    }
export const CreateOperatorDocument = gql`
    mutation CreateOperator($data: OperatorInput!) {
  createOperator(data: $data) {
    ...OperatorForForm
  }
}
    ${OperatorForFormFragmentDoc}`;
export type CreateOperatorMutationFn = Apollo.MutationFunction<CreateOperatorMutation, CreateOperatorMutationVariables>;

/**
 * __useCreateOperatorMutation__
 *
 * To run a mutation, you first call `useCreateOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOperatorMutation, { data, loading, error }] = useCreateOperatorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOperatorMutation(baseOptions?: Apollo.MutationHookOptions<CreateOperatorMutation, CreateOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOperatorMutation, CreateOperatorMutationVariables>(CreateOperatorDocument, options);
      }
export type CreateOperatorMutationHookResult = ReturnType<typeof useCreateOperatorMutation>;
export type CreateOperatorMutationResult = Apollo.MutationResult<CreateOperatorMutation>;
export type CreateOperatorMutationOptions = Apollo.BaseMutationOptions<CreateOperatorMutation, CreateOperatorMutationVariables>;
export const UpdateOperatorDocument = gql`
    mutation UpdateOperator($data: OperatorUpdateInput!, $where: OperatorWhereUniqueInput!) {
  updateOperator(data: $data, where: $where) {
    ...OperatorForForm
  }
}
    ${OperatorForFormFragmentDoc}`;
export type UpdateOperatorMutationFn = Apollo.MutationFunction<UpdateOperatorMutation, UpdateOperatorMutationVariables>;

/**
 * __useUpdateOperatorMutation__
 *
 * To run a mutation, you first call `useUpdateOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperatorMutation, { data, loading, error }] = useUpdateOperatorMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOperatorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOperatorMutation, UpdateOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOperatorMutation, UpdateOperatorMutationVariables>(UpdateOperatorDocument, options);
      }
export type UpdateOperatorMutationHookResult = ReturnType<typeof useUpdateOperatorMutation>;
export type UpdateOperatorMutationResult = Apollo.MutationResult<UpdateOperatorMutation>;
export type UpdateOperatorMutationOptions = Apollo.BaseMutationOptions<UpdateOperatorMutation, UpdateOperatorMutationVariables>;