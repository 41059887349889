import { gql } from '@apollo/client'
import { Divider } from 'antd'
import { AddItemButton } from 'components/buttons'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { kebabCase, uniqBy } from 'lodash'
import React, { useRef, useState } from 'react'
import { DocumentType, DocumentTypeScope } from 'schema'
import Input from '../inline-edit/Input'

export type DocumentTypeSelectProps = ReferenceSelectProps<DocumentType> & {
  scope: DocumentTypeScope
}

const GET_DOCUMENT_TYPES = gql`
  query GetDocumentTypes($where: DocumentTypesWhereInput) {
    documentTypes(where: $where) {
      slug
      name
    }
  }
`

const DocumentTypeDropdown = ({
  menu,
  onChange,
}: {
  menu: Parameters<NonNullable<DocumentTypeSelectProps['dropdownRender']>>[0],
  onChange: DocumentTypeSelectProps['onChange']
}) => {
  const [value, setValue] = useState<string>()

  const onSubmit = () => {
    if (!onChange) return
    if (!value) return

    const slug = kebabCase(value)
    onChange(slug, {
      key: slug,
      label: value,
      value: slug,
    })
    setValue('')
  }

  return (
    <div>
      {menu}
      {onChange && (
        <>
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              value={value}
              onChange={(event) => {
                setValue(event.currentTarget.value)
              }}
              onPressEnter={onSubmit}
            />
            <AddItemButton
              onClick={onSubmit}
            >
              Add a Type
            </AddItemButton>
          </div>
        </>
      )}
    </div>
  )
}

export const DocumentTypeSelect: React.FC<DocumentTypeSelectProps> = (props) => {
  const { scope, ...args } = props

  const localCreatedResources = useRef<{
    slug: any,
    name: any
  }[]>([])

  const propsFromGql = buildPropsFromGql({
    name: 'Document Type',
    queryField: 'documentTypes',
    gql: GET_DOCUMENT_TYPES,
    variables: {
      where: {
        scope,
        active: {
          equals: true,
        },
      },
    },
  }, {
    placeholder: 'Select the type of this Document',
    ...args,
  })

  propsFromGql.dropdownRender = (menu) => (
    <DocumentTypeDropdown
      menu={menu}
      onChange={(value, option) => {
        if (propsFromGql.onChange) {
          if ('label' in option) {
            localCreatedResources.current.push({
              slug: option.value,
              name: option.label,
            })
          }
          propsFromGql.onChange(value, option)
        }
      }}
    />
  )

  propsFromGql.resources = uniqBy([
    ...localCreatedResources.current,
    ...(args.resources || []),
  ], 'slug')

  return <ReferenceSelect {...propsFromGql} />
}

export default DocumentTypeSelect
