import { AutoComplete as AntAutoComplete } from 'antd'
import { AutoCompleteProps as AntAutoCompleteProps } from 'antd/lib/auto-complete/index'
import type { BaseOptionType, DefaultOptionType } from 'antd/lib/select'
import React, { ComponentRef } from 'react'
import styled from 'styled-components'

export type AutoCompleteRef = ComponentRef<typeof AntAutoComplete>

type InternalOptionType = DefaultOptionType | BaseOptionType

export interface AutoCompleteProps<
  ValueType = any,
  OptionType extends InternalOptionType = DefaultOptionType
> extends AntAutoCompleteProps<ValueType, OptionType> {
  editing?: boolean
}

const StyledAntAutoComplete = styled(AntAutoComplete)`
  cursor: text;
  &.read-only,
  &.read-only .ant-select-selector,
  &.read-only input,
  &.read-only.ant-select-focused .ant-select-selector,
  &.read-only.ant-select-focused input
  {
    border-color: transparent !important;
    background-color: transparent !important;
    outline-color: transparent !important;
    box-shadow: none !important;
    caret-color: transparent !important;
  }
`

type AnyProps = AutoCompleteProps<any, InternalOptionType>

export const AutoComplete = React.forwardRef<AutoCompleteRef, AnyProps>((props: AnyProps, ref) => {
  const { editing, ...args } = props
  if (editing === false) {
    args.className = `${args.className} read-only`
    args.open = false
    args.onKeyDown = (event) => {
      event.preventDefault()
    }
  }
  return <StyledAntAutoComplete data-lpignore="true" {...args} ref={ref} />
}) as <
  ValueType = any,
  OptionType extends InternalOptionType = DefaultOptionType
>(
  props: AutoCompleteProps<ValueType, OptionType> & {
    ref?: React.Ref<AutoCompleteRef> | undefined
  }
) => ReturnType<typeof StyledAntAutoComplete>

export default AutoComplete
