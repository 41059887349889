import { IInvoiceBalanceTotal } from '../hooks/useBalance'

export enum DISCOUNT_TYPE {
  NONE = 'none',
  FIXED = 'fixed',
  PERCENTAGE = 'percentage'
}

export enum ROW_TYPE {
  PRODUCT = 'product',
  SUBTOTAL = 'subtotal'
}

export type SubtotalRow = {
  type: ROW_TYPE.SUBTOTAL,
  id: number,
  total: number
}

export type ProductRow = {
  type: ROW_TYPE.PRODUCT
  id: number,
  itemId?: number,
  item: string,
  description: string,
  qty: number,
  rate: number,
  baseRate: number
  amount: number,
  tax: boolean
}

export type ItemRow = SubtotalRow | ProductRow
export type TableProductRow = ProductRow & { editable?: boolean }
export type TableItemRow = SubtotalRow | TableProductRow

type TypeInvoiceSettings = Omit<IInvoiceSettings, 'includeCompanyLogo' | 'priceRateID' | 'discountType' | 'discountValue'> & { companyLogo?: string }

export interface IInvoiceJobDetails {
  jobDate: Date,
  travelDurationHours: number,
  onSiteDurationHours: number,
  cubicYards: number,
  pourType?: string
}

export interface IInvoiceSettings {
  customerName: string,
  invoiceNumber: string,
  companySection: string,
  billAddress: string,
  siteAddress: string,
  lot?: string,
  invoiceDate: Date,
  dueDate: Date,
  terms: TERMS_ENUM,
  priceRateID: string,
  discountType: DISCOUNT_TYPE,
  discountValue: number,
  thankYouMessage: string
}

export interface PDFInvoiceSchema {
  jobDetails: IInvoiceJobDetails,
  settings: IInvoiceSettings,
  items: ItemRow[],
  balance: IInvoiceBalanceTotal,
}

export enum TERMS_ENUM {
  CUSTOM = 'custom',
  DUE = 'Due on Receipt',
  TERMS_10 = 'Net 10',
  TERMS_15 = 'Net 15',
  TERMS_30 = 'Net 30'
}
