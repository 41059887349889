// TODO: pull api keys from graphql
const geocodioReverseFetch = (lat: number | string, lng: number | string) => fetch(`https://api.geocod.io/v1.7/reverse?q=${lat},${lng}&limit=1&fields=timezone&api_key=816a4e019608e10d994e66ea11d18ea2e60416d`)

export const timezoneForCoordinates = async (lat: number | string, lng: number | string): Promise<string | undefined> => {
  const fetchTimezone = await geocodioReverseFetch(lat, lng)
  if (!fetchTimezone.ok) return

  const response = await fetchTimezone.json()
  return response?.results?.[0]?.fields?.timezone?.name
}
