import {
  flatten, flow, isEmpty, isEqual, mapValues, pick, reduce, remove, uniq,
} from 'lodash'

type Data = Record<string, any>

export type DataGridCalculateArgs<
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  ValueType extends any = any
> = {
  current: ValueType
  data: Data
  dependentData: Partial<Data>
  changedData: Partial<Data>
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export interface DataGridRegistration<ValueType extends any = any> {
  name: string
  dependsOn?: string[]
  calculate?: (args: DataGridCalculateArgs<ValueType>) => ValueType
  isEqual?: (currentValue: ValueType, newValue: ValueType) => boolean
}

const defaultCalculate: NonNullable<DataGridRegistration['calculate']> = (args) => args.current

type Registrations = Record<string, DataGridRegistration>

export type DataGridMiddleware = (field: string, args: DataGridCalculateArgs<any>, next: (args: DataGridCalculateArgs<any>) => any) => any

export class DataGrid {
  protected _registrations: Registrations = {}
  protected _registrationsByDependencies: Record<string, string[]> = {}
  protected _data: Data = {}
  protected _defaultIsEqual: NonNullable<DataGridRegistration<any>['isEqual']> = isEqual
  protected _onCalculateMiddlewares: DataGridMiddleware[] = []
  protected _onCalculateMiddlewareFn: undefined | ((
    field: string,
    params: DataGridCalculateArgs<any>,
    fn: NonNullable<DataGridRegistration['calculate']>
  ) => any)

  get data() {
    return this._data
  }

  applyOnCalculateMiddleware(middleware: DataGridMiddleware) {
    this._onCalculateMiddlewares.push(middleware)

    this._onCalculateMiddlewareFn = (field, params, fn) => {
      let index = -1

      const consumer = (innerParams: DataGridCalculateArgs<any>): any => {
        // eslint-disable-next-line no-plusplus
        const nextMiddleware = this._onCalculateMiddlewares[++index]

        if (nextMiddleware) {
          return nextMiddleware(field, innerParams, (p) => consumer(p))
        }
        return fn(innerParams)
      }

      return consumer(params)
    }
  }

  put(updatedData: Data) {
    const allData = {
      ...mapValues(this._data, () => undefined),
      ...updatedData,
    }
    return this.patch(allData)
  }

  patch(updatedData: Partial<Data>) {
    const changedData = reduce(updatedData, (result, newValue, key) => {
      const equalityCheck = this._registrations[key]?.isEqual || this._defaultIsEqual
      const equal = equalityCheck(this._data[key], newValue)

      if (!equal) {
        result[key] = newValue
      }

      return result
    }, {} as Partial<Data>)

    if (isEmpty(changedData)) {
      return {
        updated: false,
      }
    }

    const allData = { ...this._data, ...changedData }

    const { newData, calculatedData } = this._recursiveCollect(changedData, allData, this._doCalculate)

    this._data = newData

    return {
      updated: true,
      changedData,
      calculatedData,
    }
  }

  register(registration: DataGridRegistration) {
    const { name } = registration

    // unregister this cell first
    // ensures dependencies are correctly mapped
    this.unregister(name)

    this._registrations[name] = registration

    for (const depdenency of (registration.dependsOn || [])) {
      this._registrationsByDependencies[depdenency] ||= []
      this._registrationsByDependencies[depdenency].push(name)
    }
  }

  unregister(name: DataGridRegistration['name']) {
    const registration = this._registrations[name]
    if (!registration) return
    delete this._registrations[name]

    for (const depdenency of (registration.dependsOn || [])) {
      remove(this._registrationsByDependencies[depdenency] || [], name)
    }
  }

  protected _dependenciesFor(keys: string[]): string[] {
    if (keys.length === 0) {
      return []
    }
    if (keys.length === 1) {
      return this._registrationsByDependencies[keys[0]] || []
    }

    return flow([
      Object.values,
      flatten,
      uniq,
    ])(pick(this._registrationsByDependencies, keys))
  }

  protected _recursiveCollect(
    changedData: Partial<Data>,
    allData: Data,
    fn: (changedData: Partial<Data>, allData: Data) => Partial<Data>
  ): {
    calculatedData: Partial<Data>,
    newData: Partial<Data>
  } {
    const calculatedData = fn.bind(this)(changedData, allData)
    const newData = { ...allData, ...changedData, ...calculatedData }

    if (!isEmpty(calculatedData)) {
      const recursiveData = this._recursiveCollect(calculatedData, newData, fn)
      return {
        calculatedData: {
          ...calculatedData,
          ...recursiveData.calculatedData,
        },
        newData: recursiveData.newData,
      }
    }
    return { calculatedData, newData }
  }

  protected _doCalculate(changedData: Partial<Data>, data: Data) {
    const newData: Partial<Data> = {}
    const fieldsToNotify = this._dependenciesFor(Object.keys(changedData))

    fieldsToNotify.forEach((field) => {
      const registration = this._registrations[field]
      if (!registration) return

      const mergedData = { ...data, ...newData }
      const dependentData = pick(mergedData, registration.dependsOn || [])

      const current = data[field]

      const calculated = this._executeOnCalculateMiddleware(
        field,
        {
          current, dependentData, changedData, data: mergedData,
        },
        registration.calculate || defaultCalculate
      )
      if (calculated === undefined) return

      const equalityCheck = registration?.isEqual || this._defaultIsEqual
      if (equalityCheck(current, calculated)) return

      newData[field] = calculated
    })

    return newData
  }

  protected _executeOnCalculateMiddleware(
    field: string,
    params: DataGridCalculateArgs<any>,
    fn: NonNullable<DataGridRegistration['calculate']>
  ): any {
    if (this._onCalculateMiddlewareFn) {
      return this._onCalculateMiddlewareFn(field, params, fn)
    }
    return fn(params)
  }
}
