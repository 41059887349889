import Color from 'color'
import { useMemo } from 'react'
import { EventCollapse } from '../EventCollapse'

import {
  EventProps, EventWrap,
  EventWrapInnerBorder,
  Title,
} from './Card'

export const PendingEvent = (props: EventProps) => {
  const { minifiedView } = props
  const statusColor = 'rgb(226, 51, 148)'
  const lightStatusColor = useMemo(() => Color(statusColor).alpha(0.5).hsl().toString(), [statusColor])

  return (
    <EventWrap style={{
      borderLeftColor: statusColor,
      borderRightColor: statusColor,
    }}
    >
      <EventWrapInnerBorder style={{
        borderBottomColor: lightStatusColor,
        borderTopColor: lightStatusColor,
      }}
      >
        <EventCollapse
          minified={minifiedView}
          header={(
            <Title
              style={{ color: statusColor }}
              ellipsis={{
                tooltip: true,
              }}
            >New Event
            </Title>
          )}
        />
      </EventWrapInnerBorder>
    </EventWrap>
  )
}
