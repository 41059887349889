import { Note } from 'schema'

import { compact } from 'lodash'

import { Col } from 'antd'
import { NotesIcon } from 'components/icons'
import { customerContactTitle } from '../events/OrderEvent'
import { Item, ItemProps, ItemTitle } from './Item'

export interface NoteItemProps extends ItemProps {
  note: Pick<Note, 'title' | 'contact' | 'customer'>
}

export const NoteItem = (props: NoteItemProps) => {
  const { note, ...rest } = props

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const title = compact([customerContactTitle(note?.customer!, note?.contact), note.title]).join(' | ')

  return (
    <Item {...rest}>
      <Col>
        <NotesIcon />
      </Col>
      <Col>
        <ItemTitle>{title}</ItemTitle>
      </Col>
    </Item>
  )
}
