import { useCurrentForm } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'
import { DateTimePicker } from 'components/form/inline-edit/DateTimePicker'
import colors from 'constants/colors'
import { formatRelativeTime } from 'helpers/datetime'
import { compact, isEmpty } from 'lodash'
import { Moment } from 'moment'
import { useOrderBillingState } from 'pages/Orders/OrderShow/OrderBillingState'
import { useState } from 'react'
import styled from 'styled-components'
import { fromMoment } from 'utils/luxon'

type TimeFormItemProps = {
  name: string
  relativeTimezone?: string
  placeholder?: Moment
  editing?: boolean
}

const TimeDisplay = styled.span<{ faded?: boolean, editing?: boolean, open?: boolean }>`
  font-size: 0.8em;

  ${(props) => props.editing && `
    cursor: pointer;
  `}

  ${(props) => props.faded && `
    color: ${colors.ant.placeholder}
  `}

  ${(props) => props.open && `
    color: ${colors.ant.primary}
  `}

`

const TimeDisplayWrap = styled.span<{ editing?: boolean }>`
  padding-bottom: 2px;

  ${(props) => props.editing && `
    border-bottom: 1px dotted ${colors.ant.placeholder};
  `}
`

const TimeFormItem = (props: TimeFormItemProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const {
    name, relativeTimezone, placeholder, editing,
  } = props
  const form = useCurrentForm()

  const toggleOpen = () => {
    if (!editing) {
      setOpen(false)
    } else {
      setOpen((prev) => !prev)
    }
  }

  return (
    <span>
      <FormItem name={name} noStyle>
        <DateTimePicker
          hideInput
          editing={editing}
          showTime={{
            minuteStep: 1,
            // hideDisabledOptions: true,
            // disabledMinutes(hour) {
            //   const minutes = range(0, 60)
            //   const non5Minutes = minutes.filter((minute) => (minute % 5) !== 0)
            //   const value: Moment | undefined = form.getFieldValue(name)
            //   if (value && value.hour() === hour) {
            //     remove(non5Minutes, (n) => n === value.minutes())
            //   }
            //   return non5Minutes
            // },
          }}
          open={open}
          onOpenChange={setOpen}
        />
      </FormItem>
      <FormItem noStyle dependencies={[name]}>
        {() => {
          const value: Moment | undefined = form.getFieldValue(name)
          const toDisplay = value || placeholder

          if (!toDisplay) return
          const asLuxon = fromMoment(toDisplay)
          const relativeTime = relativeTimezone ? asLuxon.setZone(relativeTimezone) : asLuxon

          return (
            <TimeDisplay faded={!value} editing={editing} onClick={toggleOpen} open={open}>
              {formatRelativeTime(asLuxon, relativeTime, { spaceBeforeMeridiem: true })}
            </TimeDisplay>
          )
        }}
      </FormItem>
    </span>
  )
}

interface TimeRangeFieldsProps {
  startField?: string,
  endField?: string,
  relativeTimezone?: string
  editing?: boolean
}

export const TimeRangeFields = (props: TimeRangeFieldsProps) => {
  const { form, placeholders } = useOrderBillingState()

  const {
    startField, endField, relativeTimezone, editing,
  } = props

  const fields = compact([startField, endField])
  if (isEmpty(fields)) return null

  return (
    <TimeDisplayWrap editing={editing}>
      {startField && (
        <TimeFormItem
          name={startField}
          relativeTimezone={relativeTimezone}
          placeholder={placeholders[startField]}
          editing={editing}
        />
      )}
      <FormItem noStyle dependencies={fields}>
        {() => {
          const items = fields.map((name) => [
            form.getFieldValue([name]),
            placeholders[name],
          ])

          const hasValues = items.some(([value]) => Boolean(value))
          const hasPlaceholders = items.some(([_, placeholder]) => Boolean(placeholder))
          if (!hasValues && !hasPlaceholders) return
          return <TimeDisplay faded={!hasValues}>{' - '}</TimeDisplay>
        }}
      </FormItem>
      {endField && (
        <TimeFormItem
          name={endField}
          relativeTimezone={relativeTimezone}
          placeholder={placeholders[endField]}
          editing={editing}
        />
      )}
    </TimeDisplayWrap>
  )
}
