import {
  Button,
  Col, Row,
  Typography,
} from 'antd'
import { TableProps } from 'antd/es/table'
import AntTable from 'antd/lib/table/Table'
import { ErrorMessage } from 'components/ErrorMessage'
import { Body, Footer, Header } from 'hooks/useContentLayout'
import { isEmpty, isNil, uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import { GetComponentProps } from 'rc-table/lib/interface'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { InvoicePageProps } from '../../components/invoicing/InvoicePage'
import { InvoiceStatusBadge } from '../../components/invoicing/InvoiceStatusBadge'
import { InvoicesFiltersProps } from '../../components/invoicing/InvoicesFilters'
import { ReceivePaymentToggle } from '../../components/invoicing/ReceivePaymentToggle'
import { timeWithZone } from '../../helpers/datetime'
import { useBranch } from '../../hooks/useBranch'
import { Invoice, useGetInvoicesQuery } from '../../hooks/useInvoices'
import { PageContent } from '../../layouts/Content/PageContent'
import { QueryOrdersArgs, SortOrder } from '../../schema'
import { formatToCurrency } from '../../utils/format'
import { InvoicesFiltersDrawer } from './InvoicesFiltersDrawer'

const { Paragraph, Title, Text } = Typography

const Table = styled(AntTable)`
  table {
    font-size: 12px;

    .ant-comment-content {
      font-size: 12px;
    }
  }

  .ant-table-row:hover {
    cursor: pointer;
  }

  .ant-table-cell {
    padding: 11px;
    min-width: 70px;
  }
` as React.FC<TableProps<Invoice>>

const OrderPageBody = styled.div``

const ColumnTitle = styled(Text).attrs({
  ellipsis: { tooltip: true },
})`
  width: 100%;
`

const perPage = 25

const columns: NonNullable<TableProps<Invoice>['columns']> = [
  {
    title: 'Number',
    dataIndex: 'id',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (_, invoice) => (
      invoice && <InvoiceStatusBadge invoice={invoice} size="medium" />
    ),
  },
  {
    title: 'Invoice Date',
    dataIndex: 'invoiceDate',
    render: (_, invoice) => {
      const date = invoice.invoiceDate || invoice.dateOfServiceLocal
      if (!date) return
      return DateTime.fromISO(date, { setZone: false }).toFormat('D')
    },
  },
  {
    title: 'Due Date',
    dataIndex: 'dueDate',
    render: (_, invoice) => {
      if (!invoice?.dueDate) return
      return DateTime.fromISO(invoice.dueDate, { setZone: false }).toFormat('D')
    },
  },
  {
    title: 'Customer',
    dataIndex: ['customer', 'name'],
    render: (_, { customer }: Invoice) => customer?.name,
  },
  {
    title: 'Amount',
    dataIndex: 'amountDue',
    render: (_, { amountDue }) => {
      if (isNil(amountDue)) return
      return formatToCurrency(amountDue)
    },
  },
  AntTable.SELECTION_COLUMN,
]

const withDefaultTitleRender = (columnsArr: typeof columns) => (
  columnsArr.map((column) => {
    if (column === AntTable.SELECTION_COLUMN) {
      return column
    }

    return {
      ...column,
      title: typeof column.title === 'string' ? <ColumnTitle>{column.title}</ColumnTitle> : column.title,
    }
  })
)

export const InvoicesIndex = (_args: any) => {
  const branch = useBranch()
  const match = useRouteMatch()
  const history = useHistory<InvoicePageProps>()
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [query, setQuery] = useState<QueryOrdersArgs | undefined>({ skip: 0 })

  const [selectedInvoiceIds, setSelecectedInvoiceIds] = useState<Array<Invoice['id']>>([])

  const onFiltersChange: InvoicesFiltersProps['onChange'] = (_changedValues, allValues) => {
    setInvoices([])

    const startDate = timeWithZone(allValues?.date?.[0]?.toISOString(), branch?.timezone)?.startOf('day')
    const endDate = timeWithZone(allValues?.date?.[1]?.toISOString(), branch?.timezone)?.endOf('day')

    setQuery((prev) => {
      if (allValues.id && !isEmpty(allValues.id)) {
        setSelecectedInvoiceIds(allValues.id)

        return {
          ...prev,
          skip: 0,
          take: 10000,
          where: {
            id: {
              in: allValues.id.map((v) => parseInt(v)),
            },
          },
        }
      }

      return {
        skip: 0,
        where: {
          branchId: isEmpty(allValues.branchId) ? undefined : {
            in: allValues.branchId,
          },
          customerId: !allValues.customerId ? undefined : {
            equals: allValues.customerId,
          },
          contactId: isEmpty(allValues.contactId) ? undefined : {
            in: allValues.contactId,
          },
          siteId: isEmpty(allValues.siteId) ? undefined : {
            in: allValues.siteId,
          },
          status: isEmpty(allValues.status) ? undefined : {
            in: allValues.status,
          },
          tagId: isEmpty(allValues.tagId) ? undefined : {
            in: allValues.tagId,
          },
          dateOfService: {
            gte: startDate?.toISO(),
            lte: endDate?.toISO(),
          },
        },
      }
    })
  }

  const { data, loading, error } = useGetInvoicesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: query?.where,
      orderBy: [
        { dateOfService: SortOrder.Desc },
        { id: SortOrder.Desc },
      ],
      take: (query?.take || perPage) + 40,
      skip: query?.skip || 0,
    },
  })

  useEffect(() => {
    setInvoices((previous) => uniqBy(previous.concat(data?.invoices || []).reverse(), ({ id }) => id).reverse())
  }, [data?.invoices])

  const rowOnClickEdit: GetComponentProps<Invoice> = (invoice) => ({
    onClick: () => {
      history.push(`${match.url}/${invoice.id}`)
    },
  })

  const loadMore = () => {
    setQuery((prev) => ({
      ...prev,
      skip: (prev?.skip || 0) + perPage,
    }))
  }

  return (
    <Row wrap={false} style={{ height: '100%', width: '100%' }}>
      <Col style={{ width: '100%', height: '100%' }} flex="auto">
        <PageContent style={{ paddingTop: 0 }} components={{ Body: OrderPageBody }}>
          <Header>
            <Row align="middle">
              <Col flex="auto">
                <Title level={2} style={{ paddingTop: 10 }}>
                  Invoices
                </Title>
              </Col>
              <Col style={{ width: '163px', minHeight: '38px' }} />
            </Row>
          </Header>

          <Body>
            {error && (
              <ErrorMessage>
                <Paragraph>{error.message}</Paragraph>
              </ErrorMessage>
            )}

            <div
              style={{
                width: '100%',
                marginBottom: '20px',
                marginTop: '-55px',
              }}
            >
              <ReceivePaymentToggle
                buttonPosition='right'
                invoiceIds={selectedInvoiceIds}
                onSubmission={() => {
                  setSelecectedInvoiceIds([])
                }}
              />
            </div>

            <Table
              rowKey="id"
              tableLayout="fixed"
              loading={loading}
              columns={withDefaultTitleRender(columns)}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedInvoiceIds,
                onChange: (_, selectedInvoices) => (
                  setSelecectedInvoiceIds(selectedInvoices.map((i) => i.id))
                ),
              }}
              dataSource={invoices}
              pagination={false}
              sticky
              bordered
              scroll={{
                x: true,
              }}
              onRow={rowOnClickEdit}
            />

          </Body>

          <Footer>
            {invoices.length > 0 && invoices.length % perPage === 0 &&
              <Button disabled={loading} style={{ marginTop: '20px' }} type="primary" onClick={loadMore}> Load More Invoices </Button>}
          </Footer>
        </PageContent>
      </Col>
      <InvoicesFiltersDrawer onChange={onFiltersChange} />
    </Row>
  )
}
