import { ensureAddressTimezone } from 'helpers/ensureAddressTimezone'
import { isMissingAddress } from 'helpers/order/isMissingAddress'
import { useBranch } from 'hooks/useBranch'
import { useFeature } from 'hooks/useFeature'
import { useRequestSiteLocation } from 'hooks/useRequestSiteLocation'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  Customer, CustomerSitesArgs,
  Mutation,
  MutationCreateSiteArgs,
  MutationUpdateSiteArgs, Query, Site, SiteInput,
} from 'schema'

import {
  BaseMutationOptions, BaseQueryOptions, useMutation, useQuery,
} from '@apollo/client'
import { CREATE_SITE, GET_SITES_FOR_CUSTOMER, UPDATE_SITE } from 'gql/sites'

import {
  Button, Col, Row, Typography,
} from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loading } from 'components/Loading'
import { Footer, Header } from 'hooks/useContentLayout'

import { Form, useCurrentForm } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'
import { Checkbox } from 'components/form/inline-edit/Checkbox'
import { Input } from 'components/form/inline-edit/Input'
import { Switch } from 'components/form/inline-edit/Switch'
import { AddressInputWithMap } from '../common/AdressInputWithMap'
import { AlignWithInputs } from '../common/Layout'
import { TextArea } from '../inline-edit/TextArea'
import { RequestLocationViaSmsPopover } from './RequestLocationViaSmsPopover'

const { Paragraph } = Typography

export interface SiteFormProps {
  id?: number
  customerId: number
  editing?: boolean
  onSubmission?: (site: Site, action: 'update' | 'create') => void
  onCancel?: () => void
}

type QueryVariables = CustomerSitesArgs & { customerId: number }

export const SiteForm: React.FC<SiteFormProps> = (props) => {
  const { id, onSubmission } = props
  const isNew = id === undefined
  const [editing, setEditing] = useState<boolean>(props.editing || isNew)

  const branch = useBranch()
  const { customerId } = props

  const [requestPinViaSms] = useRequestSiteLocation()

  const queryArgs: BaseQueryOptions<QueryVariables> = {
    variables: {
      customerId,
      where: {
        id: { equals: id },
      },
    },
  }

  const { data, loading: queryLoading, error: queryError } = useQuery<Query, QueryVariables>(GET_SITES_FOR_CUSTOMER, {
    skip: isNew,
    ...queryArgs,
  })

  const site = (data?.customers || [])?.[0]?.sites?.[0] as Site | undefined

  const mutationOptions: BaseMutationOptions<Mutation, any> = {
    refetchQueries: [{
      query: GET_SITES_FOR_CUSTOMER,
      variables: queryArgs.variables,
    }],
    awaitRefetchQueries: true,
  }

  const [
    createSite,
    createSiteResponse,
  ] = useMutation<Mutation, MutationCreateSiteArgs>(CREATE_SITE, mutationOptions)

  const [
    updateSite,
    updateSiteResponse,
  ] = useMutation<Mutation, MutationUpdateSiteArgs>(UPDATE_SITE, mutationOptions)

  const { error: mutationError } = isNew ? createSiteResponse : updateSiteResponse

  const [form] = Form.useForm<Site>()

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    const {
      requestSiteLocationInput, name, address: addressRaw, ...rest
    } = values
    const { name: addressName, ...address } = addressRaw || {}

    const input: SiteInput = {
      ...rest,
      address,
      name: name || addressName,
    }

    if (!isEmpty(requestSiteLocationInput)) {
      input.address = input.address || {}

      if (!input.address.lat) {
        input.address.lat = -42
      }
      if (!input.address.lng) {
        input.address.lng = -42
      }
      if (!input.address.timezone) {
        input.address.timezone = branch?.timezone
      }
    }

    input.address = await ensureAddressTimezone(input.address, site?.address)

    input.customerId = customerId

    if (id) {
      const resp = await updateSite({
        variables: { data: input, where: { id } },
      })

      if (resp.errors) return

      setEditing(false)

      const updatedSite = resp?.data?.updateSite

      if (updatedSite && onSubmission) {
        if (!isEmpty(requestSiteLocationInput)) {
          requestPinViaSms({
            variables: {
              data: requestSiteLocationInput,
              where: {
                id: updatedSite.id,
              },
            },
          })
        }

        onSubmission(updatedSite, 'update')
      }
    } else {
      const resp = await createSite({
        variables: { data: input },
      })

      if (resp.errors) return

      setEditing(false)

      const newSite = resp?.data?.createSite

      if (newSite && onSubmission) {
        if (!isEmpty(requestSiteLocationInput)) {
          requestPinViaSms({
            variables: {
              data: requestSiteLocationInput,
              where: {
                id: newSite.id,
              },
            },
          })
        }

        onSubmission(newSite, 'create')
      }
    }
  }

  if (queryLoading) return <Loading />

  if (queryError) {
    return (
      <ErrorMessage to="/sites">
        <Paragraph>{queryError.message}</Paragraph>
      </ErrorMessage>
    )
  }

  if (!isNew && !site) {
    return (
      <ErrorMessage to="/sites">
        <Paragraph>Site #{id} does not exist for Customer #{customerId}</Paragraph>
      </ErrorMessage>
    )
  }

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="site-form"
        onFinish={onFinish}
        requiredMark={editing}
        initialValues={{
          ...site,
          address: {
            ...site?.address,
            lat: site?.address?.lat === -42 ? undefined : site?.address?.lat,
            lng: site?.address?.lng === -42 ? undefined : site?.address?.lng,
          },
          active: isNew ? true : site?.active,
        }}
      >
        <FormItem
          noStyle
          shouldUpdate={(prev, current) => (
            prev.name !== current.name
          )}
        >
          {() => (
            <Header hideIfOrphan>
              {form.getFieldValue('name') || site?.name}
            </Header>
          )}
        </FormItem>

        <FormItem
          noStyle
          shouldUpdate={(prev, current) => (
            prev?.address?.name !== current?.address?.name
          )}
        >
          {() => {
            const placeholder = isNew && form.getFieldValue(['address', 'name'])?.trim()

            return (
              <FormItem
                label="Name"
                name="name"
                rules={[
                  {
                    message: 'Name is required',
                    required: !placeholder,
                    validator: (rule, value) => {
                      if (value?.trim() || placeholder) {
                        return Promise.resolve()
                      }
                      return Promise.reject(rule.message)
                    },
                  },
                ]}
              >
                <Input placeholder={placeholder || 'Site Name'} editing={editing} />
              </FormItem>
            )
          }}
        </FormItem>

        <FormItem
          label="Secondary Name"
          name="name2"
        >
          <Input placeholder="Secondary Name" editing={editing} />
        </FormItem>

        <Form.Item
          label="Notes"
          name="notes"
        >
          <TextArea
            editing={editing}
            placeholder="Notes about this Site"
            autoSize={{ minRows: 1 }}
          />
        </Form.Item>

        <AddressInputSection site={site} customerId={customerId} editing={editing} />

        <FormItem
          label="Active"
          name="active"
          valuePropName="checked"
          style={{ marginTop: 13, marginBottom: 3 }}
        >
          <Switch
            editing={editing}
          />
        </FormItem>
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}

interface AddressInputSectionProps {
  site?: Site
  customerId?: Customer['id']
  editing?: boolean
}

const AddressInputSection = (props: AddressInputSectionProps) => {
  const [canRequestPin] = useFeature('site.requestPinViaSms.enabled')

  if (canRequestPin) {
    return <AddressInputSectionWithPinRequest {...props} />
  }
  return <AddressInputSectionAutocomplete {...props} />
}

const AddressInputSectionWithPinRequest = (props: AddressInputSectionProps) => {
  const { site, editing } = props
  const [useSmsPin, setUseSmsPin] = useState<boolean>(true)
  const form = useCurrentForm()

  const awaitingResponse = Boolean(site?.address) && isMissingAddress({ site })
  useEffect(() => {
    setUseSmsPin(awaitingResponse)
  }, [awaitingResponse])

  return (
    <>
      {useSmsPin && (
        <FormItem
          label="Address"
          required
        >
          <span>
            {/* eslint-disable-next-line @typescript-eslint/no-unused-expressions */}
            <div style={{ marginLeft: '5px', cursor: editing ? 'pointer' : undefined }} onClick={() => { editing && setUseSmsPin(false) }}>
              <Checkbox editing={editing} checked={useSmsPin} style={{ marginRight: 10 }} />Request Site Location via SMS
            </div>
          </span>
        </FormItem>
      )}

      {!useSmsPin && (
        <>
          <AddressInputSectionAutocomplete {...props} />

          {(!site?.id || awaitingResponse) && editing && (
            <AlignWithInputs>
              <RequestLocationViaSmsPopover
                customerId={props.customerId}
                onCancel={() => {
                  setUseSmsPin(false)
                }}
                onSubmission={(requestSiteLocationInput) => {
                  setUseSmsPin(true)
                  form.setFieldsValue({ requestSiteLocationInput })
                }}
              >
                <div style={{ margin: '10px 0', cursor: 'pointer' }}>
                  <Checkbox editing={editing} checked={useSmsPin} style={{ marginRight: 10 }} />Request Site Location via SMS
                </div>
              </RequestLocationViaSmsPopover>
            </AlignWithInputs>
          )}
        </>
      )}

      <FormItem hidden name="requestSiteLocationInput">
        <Input />
      </FormItem>
    </>
  )
}

const AddressInputSectionAutocomplete = ({ site, editing }: AddressInputSectionProps) => (
  <>
    <FormItem
      label="Address"
      required
      style={{ marginBottom: 6 }}
    >
      <AddressInputWithMap
        name="address"
        editing={editing}
        hideStreet2
        rules={{
          city: [{ required: true }],
          state: [{ required: true }],
          zip: [{ required: true }],
          lat: [{ required: true }],
          lng: [{ required: true }],
        }}
      />
    </FormItem>

    <SiteVerificationCallout site={site} />
  </>
)

const SiteVerificationCallout = styled(({ site, className }: { site?: Site, className?: string }) => {
  if (site?.verified) return null

  return (
    <div className={className}>
      <AlignWithInputs>
        Customer has not yet verified this location
      </AlignWithInputs>
    </div>
  )
})`
  .ant-row.ant-form-item {
    flex-wrap: nowrap;
    font-size: 0.8em;
    margin-bottom: 10px;
    text-align: center;
  }
`
