import colors from 'constants/colors'
import { compact, pick } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import {
  Mutation,
  MutationUpdateRouteArgs, Route, RouteUpdateInput,
} from 'schema'

import { BaseMutationOptions, useMutation } from '@apollo/client'
import { UPDATE_ROUTE } from 'gql/routes'

import {
  Button, Col, InputNumber, Row, Typography,
} from 'antd'

import { ErrorMessage } from 'components/ErrorMessage'
import { Form } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'

import { RouteDispatchButton } from 'components/common/RouteDispatchButton'
import { Input } from 'components/form/inline-edit/Input'
import { EquipmentSelect } from 'components/form/reference-selects/EquipmentSelect'
import { timeWithZone } from 'helpers/datetime'
import { useBranch } from 'hooks/useBranch'
import { Actions, Footer, Header } from 'hooks/useContentLayout'
import { OperatorAssignmentsFormList } from '../common/OperatorAssignmentsFormList'
import { RouteFormValues } from './RouteFormValues'
import { routeToFormValues } from './routeToFormValues'
import { RouteWaypointsFormList } from './RouteWaypointsFormList'

const { Paragraph } = Typography

export interface RouteFormProps {
  route?: Route
  editing?: boolean
  onSubmission?: (route: Route, action: 'update' | 'create') => void
  onCancel?: () => void
}

const Label = styled.span`
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 12px;
  color: ${colors.greyscale50};
`

const FormBody = styled.div`
  > * {
    margin-bottom: 15px;
  }
`

// eslint-disable-next-line unused-imports/no-unused-vars
const TitleInput = styled(Input)`
  input {
    font-size: 1.2rem;
    font-weight: 600;
  }
`

export const RouteForm: React.FC<RouteFormProps> = (props) => {
  const { route, onSubmission } = props
  const id = route?.id
  const branch = useBranch()
  const [editing, setEditing] = useState<boolean>(props.editing || false)
  const routeFormValues = useMemo(() => routeToFormValues(route || {}), [route])
  const [form] = Form.useForm<RouteFormValues>()

  useEffect(() => {
    form.setFieldsValue(routeFormValues)
  }, [routeFormValues])

  const mutationOptions: BaseMutationOptions<Mutation, any> = {
    refetchQueries: ['GetEvents', 'GetRoute'],
  }

  const [updateRoute, { error: mutationError }] = useMutation<Mutation, MutationUpdateRouteArgs>(UPDATE_ROUTE, mutationOptions)

  const routeTitle = useMemo(() => {
    if (!route) return ''

    const startTime = timeWithZone(route.startTime, branch?.timezone)
    const endTime = timeWithZone(route.endTime, branch?.timezone)
    const midTime = startTime.plus(endTime.diff(startTime))

    return [
      midTime.toFormat('M/d'),
      route.active ? (route.equipment?.name || 'Unassigned') : 'Cancelled',
    ].join(' - ')
  }, [route])

  if (!id || !route) {
    return (
      <ErrorMessage>
        <Paragraph>Route #{id} does not exist</Paragraph>
      </ErrorMessage>
    )
  }

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.setFieldsValue(routeFormValues as any)
  }

  const onFinish = async (values: RouteFormValues) => {
    const {
      waypoints, assignments, additionalIds, ...rest
    } = values

    Object.entries(rest).forEach(([k, v]) => {
      if (v === undefined) {
        (rest as any)[k] = null
      }
    })

    const data: RouteUpdateInput = {
      ...rest,
      revision: rest.revision || -1,
      waypoints: {
        // Ignore any changes to order times
        upsert: (waypoints || []).filter((waypoint) => waypoint.order === null).map((waypoint) => ({
          data: pick(waypoint, [
            'scheduledArrivalTime', 'scheduledDepartureTime',
          ]),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          where: { id: waypoint.id! },
        })),
      },
      assignments: {
        set: (assignments || []).filter((assignment) => (
          assignment.operatorId && assignment.role
        )).map((assignment) => ({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          operatorId: assignment.operatorId!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          role: assignment.role!,
        })),
      },
    }

    const resp = await updateRoute({
      variables: { data, where: { id } },
    })

    if (resp.errors) return

    setEditing(false)

    const updatedRoute = resp?.data?.updateRoute

    if (updatedRoute && onSubmission) {
      onSubmission(updatedRoute, 'update')

      form.setFieldsValue({
        revision: updatedRoute.revision,
      })
    }
  }

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="route-form"
        onFinish={onFinish}
        requiredMark={editing}
        initialValues={routeFormValues}
        layout="vertical"
        labelCol={{
          style: {},
        }}
      >
        <FormBody>
          <Row wrap={false} gutter={[16, 16]} align="middle">
            <Col flex="auto">
              <Header>
                {routeTitle}
              </Header>
            </Col>
            <Col>
              <Actions>
                <RouteDispatchButton route={route} />
              </Actions>
            </Col>
          </Row>

          <Row wrap={false} gutter={[16, 16]} align="middle">
            <Col>
              <Label>Equipment</Label>
            </Col>
            <Col flex="auto">
              <FormItem
                noStyle
                name="equipmentId"
              >
                <EquipmentSelect
                  allowClear
                  bordered={false}
                  style={{ width: '100%' }}
                  editing={editing}
                  resources={compact([route?.equipment])}
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Label>Operators</Label>
            </Col>
            <Col span={24}>
              <OperatorAssignmentsFormList
                bordered={false}
                editing={editing}
                resources={compact(route.assignments)}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]} align="middle">
            <Col span={24}>
              <Label>Schedule</Label>
            </Col>
            <Col span={24}>
              <div style={{ paddingLeft: 12 }}>
                <RouteWaypointsFormList editing={editing} />
              </div>
            </Col>
          </Row>

          {/* <Row gutter={[16, 16]} align='middle'>
          <Col span={24}>
            <AspectRatioBox ratio={1}>
              <RouteMap route={route} />
            </AspectRatioBox>
          </Col>
        </Row> */}

          <Form.Item name={['branchId']} hidden>
            <InputNumber />
          </Form.Item>
          <Form.Item name={['revision']} hidden>
            <InputNumber />
          </Form.Item>
        </FormBody>
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}
