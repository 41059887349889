import { gql } from '@apollo/client'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import React from 'react'
import { PhoneType } from 'schema'

export type PhoneTypeSelectProps = ReferenceSelectProps<PhoneType>

const GET_PHONE_TYPES = gql`
  query GetPhoneTypes($where: QueryPhoneTypesWhereInput) {
    phoneTypes(where: $where) {
      name
      slug
      sort
    }
  }
`

const defaults: Partial<PhoneTypeSelectProps> = {
  defaultActiveFirstOption: true,
}

export const PhoneTypeSelect: React.FC<PhoneTypeSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Phone Types',
  queryField: 'phoneTypes',
  gql: GET_PHONE_TYPES,
  defaults,
  fetchPolicy: 'cache-first',
}, props))

export default PhoneTypeSelect
