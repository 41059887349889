import { PropsWithChildren, useMemo } from 'react'

import {
  generatePath,
  Route, useHistory, useRouteMatch,
} from 'react-router-dom'

import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'
import { ScheduleEventNew } from 'pages/Schedule/SchduleEventNew'
import { ScheduleNoteShow } from 'pages/Schedule/ScheduleNoteShow'
import { ScheduleOrderShow } from 'pages/Schedule/ScheduleOrderShow'
import { ScheduleRouteShow } from 'pages/Schedule/ScheduleRouteShow'
import { ScheduleCalendarShow } from './ScheduleCalendarShow'
import { ScheduleUrlParams } from './ScheduleUrlParams'

export const SchedulerDrawer = ({ children }: PropsWithChildren<unknown>) => {
  const history = useHistory()
  const match = useRouteMatch<ScheduleUrlParams>()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  return (
    <RoutableDrawer onClose={() => { history.replace(basePath) }}>
      <Route path={`${match.path}/route/:routeId`} component={ScheduleRouteShow} width={450} showClose={false} />
      <Route path={`${match.path}/note/:noteId`} component={ScheduleNoteShow} width={450} showClose={false} />
      <Route path={`${match.path}/order/:orderId`} component={ScheduleOrderShow} width={450} showClose={false} />
      <Route path={`${match.path}/calendar`} component={ScheduleCalendarShow} width={350} hideOnMobile />
      <Route path={`${match.path}/new`} component={ScheduleEventNew} width={450} showClose={false} />
      {children}
    </RoutableDrawer>
  )
}
