import { gql } from '@apollo/client'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import React from 'react'
import { ContactRole } from 'schema'

export type ContactRoleSelectProps = ReferenceSelectProps<ContactRole>

const GET_CONTACT_ROLES = gql`
  query GetContactRoles($where: QueryContactRolesWhereInput) {
    contactRoles(where: $where) {
      id
      name
      sort
    }
  }
`

const defaults: Partial<ContactRoleSelectProps> = {
  defaultActiveFirstOption: true,
}

export const ContactRoleSelect: React.FC<ContactRoleSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Contact Role',
  queryField: 'contactRoles',
  gql: GET_CONTACT_ROLES,
  defaults,
  fetchPolicy: 'cache-first',
}, props))

export default ContactRoleSelect
