import colors from 'constants/colors'
import styled from 'styled-components'

import { PlusCircleOutlined } from '@ant-design/icons'
import { ResourceLabelContentArg } from '@fullcalendar/resource-common'
import { Col, Row, Typography } from 'antd'
import { Tooltip } from 'components/common/Tooltip'
import { ViewEquipmentDocsPopover } from 'components/equipment/ViewEquipmentDocsPopover'
import { ResourceEditableStatus, useEquipmentEditMode } from './EditModeState'

export const ResourceLabelWrapper = styled.div`
  width: 100%;
  padding: 11px 8px;

  &.editing-selectable {
    cursor: pointer;

    .anticon {
      color: ${colors.editing.backgroundPrimary};

      &:hover, &:active, &:focus {
        color: ${colors.editing.backgroundSecondary};
      }
    }
  }

  &.editing-disabled {
     cursor: not-allowed;

    .anticon {
      display: none;
    }
  }
`

export const ResourceLabel = (props: ResourceLabelContentArg) => {
  const showContent = !(props.resource.extendedProps?.eventRowIndex > 0)
  const equipment = props.resource.extendedProps?.equipment
  const equipmentId = equipment?.id || null

  const equipmentEditMode = useEquipmentEditMode()
  const status = equipmentEditMode.status(equipmentId)
  const className = `editing-${status || 'off'}`

  const onClick = () => equipmentEditMode.toggleSelected(equipmentId)

  return (
    <ResourceLabelWrapper className={className} onClick={onClick}>
      {showContent && (
        <Row align="middle" wrap={false} style={{ width: '100%' }}>
          <Col flex="auto">
            <Typography.Text ellipsis={{ tooltip: props.resource.title }} style={{ color: 'inherit', width: '100%' }}>
              {props.resource.title}
            </Typography.Text>
          </Col>
          <Col>
            {(
              status === ResourceEditableStatus.Selectable ||
              status === ResourceEditableStatus.Selected
            ) ? (
              <Tooltip noWrap title={`Click here to create a new Route for ${props.resource.title || 'this equipment.'}`}>
                <PlusCircleOutlined />
              </Tooltip>
            )
              : equipmentId ?
                <ViewEquipmentDocsPopover equipmentId={equipmentId} />
                : undefined}
          </Col>
        </Row>
      )}
    </ResourceLabelWrapper>
  )
}
