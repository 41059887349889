import {
  buildPropsFromGql, defaultOptionsGenerator, ReferenceSelect, ReferenceSelectProps,
} from 'components/form/reference-selects/ReferenceSelect'
import { GET_INVENTORY } from 'gql/inventory'
import { byFieldAsc } from 'helpers/sortting'
import { groupBy, startCase } from 'lodash'
import React from 'react'
import { InventoryItem } from 'schema'

export type InventoryItemSelectProps = ReferenceSelectProps<InventoryItem>

export const inventoryOptionsGenerator: NonNullable<InventoryItemSelectProps['optionsGenerator']> = (items) => {
  const byCategory = groupBy(items || [], (item) => item.category || 'other')

  return Object.entries(byCategory).map(([category, options]) => ({
    key: category,
    label: startCase(category),
    value: category,
    options: defaultOptionsGenerator(options.sort(byFieldAsc('name'))),
  }))
}

const defaults: Partial<InventoryItemSelectProps> = {
  showSearch: true,
  optionsGenerator: inventoryOptionsGenerator,
}

export const InventoryItemSelect: React.FC<InventoryItemSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Inventory Item',
  queryField: 'inventory',
  gql: GET_INVENTORY,
  withCurrentBranchFilter: true,
  defaults,
}, props))

export default InventoryItemSelect
