import { DateTime } from 'luxon'
import { isToday } from '../datetime'

export const dateToSlug = (date: DateTime) => (isToday(date) ? 'today' : date.toISODate())

export const dateFromSlug = (date: string | undefined, timezone?: string) => {
  let datetime: DateTime | undefined
  if (date && date !== 'today') {
    datetime = DateTime.fromISO(date, { zone: timezone })
  }
  if (date === 'today' || !datetime || !datetime.isValid) {
    if (timezone) {
      datetime = DateTime.utc().setZone(timezone)
    } else {
      datetime = DateTime.local()
    }
  }
  if (datetime.zoneName === null) {
    console.error('Luxon: Invalid zone name')
  }
  return datetime.startOf('day')
}
