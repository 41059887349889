import { Row, RowProps } from 'antd'
import colors from 'constants/colors'
import { omit } from 'lodash'
import styled from 'styled-components'

export const ItemTitle = styled.div`
  font-weight: bold;
`

export type ItemProps = RowProps

export const Item = styled(Row).attrs((props) => ({
  gutter: 12,
  wrap: false,
  align: 'middle',
  ...omit(props, 'theme'),
}))`
  color: ${colors.fontPrimary};
  padding: 8px;
  font-size: 15px;
  line-height: 1.4;
`
