import { FileTextOutlined } from '@ant-design/icons'
import { Col, Row, Typography } from 'antd'
import { CloseButton } from 'components/buttons'
import { DocumentExpirationStyle, getDocumentExpirationWarning } from 'components/common/DocumentExpirationDate'
import { Popover, PopoverProps } from 'components/common/Popover'
import { LinkExternalIcon } from 'components/icons'
import { useBranch } from 'hooks/useBranch'
import { isEmpty, min } from 'lodash'
import { DateTime } from 'luxon'
import {
  useEffect, useLayoutEffect, useMemo, useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { Equipment } from 'schema'
import styled from 'styled-components'
import { DocumentList } from './DocumentList'
import { useGetEquipmentDocumentsQuery } from './__generated__/ GetEquipmentDocuments'

export type ViewEquipmentDocsPopoverProps = PopoverProps & {
  equipmentId: Equipment['id']
}

const DefaultTrigger = styled(FileTextOutlined)`
  padding: 3px;
  cursor: pointer;
`

const PopoverBody = styled.div.attrs((props) => ({
  ...props,
  children: <div>{props.children}</div>,
}))`
  width: 300px;
  overflow: hidden;
  position: relative;

  > div {
    max-height: 300px;
    height: 100%;
    padding: 12px 16px;
    width: 100%;
    overflow: scroll;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    right: 18px;
    height: 16px;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(139,167,32,0) 0%, rgba(255,255,255,1) 100%);
    pointer-events: none;
  }
`

export const ViewEquipmentDocsPopover = (props: ViewEquipmentDocsPopoverProps) => {
  const history = useHistory()
  const branch = useBranch()
  const [visible, setVisible] = useState<boolean>(false)

  const { children, equipmentId, ...rest } = props

  // only use cache on initial load
  const { data, refetch } = useGetEquipmentDocumentsQuery({
    fetchPolicy: 'cache-only',
    variables: { id: equipmentId },
  })

  // if query has never been fetched, fetch after page loads
  useLayoutEffect(() => {
    if (data === undefined) {
      refetch()
    }
  }, [])

  // when popup loads, get recent data
  useEffect(() => {
    if (visible) {
      refetch()
    }
  }, [visible])

  const documents = useMemo(() => (
    (data?.equipment?.[0]?.documents || []).filter((doc) => (
      doc.pinned || Boolean(
        doc.expirationDate &&
        getDocumentExpirationWarning(
          DateTime.fromISO(doc?.expirationDate)
        )
      )
    ))
  ), [data])

  const minExpirationDate = useMemo(() => (
    min(documents.map((doc) => doc.expirationDate))
  ), [documents])

  if (isEmpty(documents)) {
    return null
  }

  return (
    <Popover
      autoAdjustOverflow
      hideArrow
      noContentPadding
      placement="right"
      trigger={['click']}
      onVisibleChange={setVisible}
      visible={visible}
      title={(
        <Row align="middle">
          <Col flex="auto">
            <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>
              Documents
            </Typography.Title>
          </Col>
          <Col>
            <LinkExternalIcon onClick={() => {
              history.push(`/settings/branches/${branch?.id || 0}/equipment/${equipmentId}`)
              setVisible(false)
            }}
            />
          </Col>
          <Col>
            <CloseButton onClick={() => setVisible(false)} />
          </Col>
        </Row>
      )}
      content={(
        <PopoverBody>
          <DocumentList documents={documents} />
        </PopoverBody>
      )}
      {...rest}
    >
      {children || (
        <DocumentExpirationStyle expirationDate={minExpirationDate}>
          <DefaultTrigger style={{ cursor: 'pointer' }} />
        </DocumentExpirationStyle>
      )}
    </Popover>
  )
}
