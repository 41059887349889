import { get as lodashGet, isNil } from 'lodash'

export const mulitSort = (...sorters: Array<(a: any, b: any) => number>) => (a: any, b: any) => {
  for (const sorter of sorters) {
    const val = sorter(a, b)
    if (val !== 0) return val
  }
  return 0
}

const byDirection = (direction: 1 | -1) => (
  (a: any, b: any) => {
    // eslint-disable-next-line eqeqeq
    if (a == b) return 0
    if (isNil(b)) return -1 * direction
    if (isNil(a)) return 1 * direction

    if (typeof a.toLowerCase === 'function') {
      a = a.toLowerCase()
    }
    if (typeof b.toLowerCase === 'function') {
      b = b.toLowerCase()
    }

    if (
      typeof a.localeCompare === 'function' &&
      typeof b.toString === 'function'
    ) {
      return a.localeCompare(b.toString()) * direction
    }

    if (
      typeof b.localeCompare === 'function' &&
      typeof a.toString === 'function'
    ) {
      return b.localeCompare(a.toString()) * -1 * direction
    }

    return a < b ? -1 * direction : 1 * direction
  }
)

export const byAsc = byDirection(1)

export const byDesc = byDirection(-1)

export const byFieldAsc = (field: string) => byFieldPathAsc([field])

export const byFieldDesc = (field: string) => byFieldPathDesc([field])

export const byFieldPathAsc = (fieldPath: string[]) => byFieldPathDirectional(fieldPath, 1)

export const byFieldPathDesc = (fieldPath: string[]) => byFieldPathDirectional(fieldPath, -1)

export const byPersonNameAsc = (a: any, b: any) => (
  byFieldAsc('firstName')(a, b) || byFieldAsc('lastName')(a, b)
)

export const byFieldPathDirectional = (fieldPath: string[], direction: 1 | -1) => (
  (a: any, b: any) => {
    const aVal = lodashGet(a, fieldPath)
    const bVal = lodashGet(b, fieldPath)
    return byDirection(direction)(aVal, bVal)
  }
)

export const bySortField = byFieldAsc('sort')
