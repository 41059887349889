import { bySortField } from 'helpers/sortting'
import { compact } from 'lodash'
import React from 'react'

import { Tag } from 'schema'
import { RecursivePartial } from 'types'

import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { ResourceTag } from 'components/tags/ResourceTag'
import { GET_TAGS_SLIM } from 'gql/tags'

export type TagSelectProps = ReferenceSelectProps<Tag> & {
  scope: 'orders'
}

const tagOptionGenerator = (tag?: RecursivePartial<Tag> | null) => {
  if (!tag?.id) return

  return {
    label: <ResourceTag checkable tag={tag as any} />,
    title: tag.name,
    value: tag.id,
  }
}

const defaults: Partial<TagSelectProps> = {
  defaultActiveFirstOption: false,
  showSearch: true,
  filterOption: (val: string, opt) => opt?.title?.toLowerCase().includes(val.toLowerCase()),
  optionsGenerator: (tags) => (
    compact([...tags].sort(bySortField).map(tagOptionGenerator))
  ),
}

export const TagSelect: React.FC<TagSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Tags',
  queryField: 'tags',
  gql: GET_TAGS_SLIM,
  defaults,
  fetchPolicy: 'cache-first',
  variables: {
    where: {
      path: {
        startsWith: `/${props.scope}/`,
      },
    },
  },
}, props))

export default TagSelect
