import colors from 'constants/colors'
import { useMemo } from 'react'
import styled from 'styled-components'

import { dateToSlug } from 'helpers/url'
import { generatePath, useHistory, useRouteMatch } from 'react-router'

import { CollapsibleSider } from 'components/layout/Sider'
import { LocationAwareMenu } from 'components/menus/LocationAwareMenu'
import { Menu, MenuLinkToggleable } from 'components/menus/Menu'
import { SiderMenu } from 'components/menus/SiderMenu'

import {
  CalendarOutlined, EditOutlined, MergeCellsOutlined, PlusOutlined, SplitCellsOutlined, StopOutlined,
} from '@ant-design/icons'
import { MenuItemProps } from 'antd'
import { compact } from 'lodash'
import { MENU_ITEM_BOX_SHADOW } from './constants'
import { useEditMode } from './EditModeState'
import { useSchedulerState } from './SchedulerState'

const SchedulerSidebarMenu = styled(SiderMenu)`
  &.ant-menu {
    margin-top: 47px;

    .ant-menu-item.ant-menu-item-selected, .ant-menu-item.ant-menu-item-selected:hover {
      background-color: ${colors.backgroundPrimary} !important;
      box-shadow: ${MENU_ITEM_BOX_SHADOW};
      z-index: 2;
    }

    .ant-menu-item::after {
      border-right: none;
    }
  }
`

// eslint-disable-next-line unused-imports/no-unused-vars
const EditModeMenuItem = styled((props: MenuItemProps) => {
  const { enabled, setEditMode } = useEditMode()

  const className = compact([enabled ? 'edit-mode ant-menu-item-selected' : undefined, props.className]).join(' ')

  const onClick: MenuItemProps['onClick'] = (...clickArgs) => {
    setEditMode(
      enabled ?
        false
        :
        { mode: 'selector' }
    )

    if (props.onClick) {
      props.onClick(...clickArgs)
    }
  }

  return (
    <Menu.Item
      icon={<EditOutlined />}
      children="Edit Mode"
      {...props}
      className={className}
      onClick={onClick}
    />
  )
})`
  &.ant-menu-item.ant-menu-item-selected.edit-mode {
    &, &:hover {
      background-color: ${colors.editing.backgroundPrimary} !important;
      color: ${colors.editing.fontPrimary};

      > * {
        color: ${colors.editing.fontPrimary};
      }
    }
  }
`

const DEFAULT_START_HOUR = 7 // 7am local time

export const SchedulerSidebar = () => {
  const history = useHistory()
  const match = useRouteMatch()
  // const editMode = useEditMode()
  const basePath = useMemo(() => generatePath(match.path, match.params), [match.path, match.params])

  const {
    branch,
    selectedDate,
    showMinifiedView,
    toggleMinifiedView,
    inactiveEventsVisible,
    toggleInactiveEvents,
  } = useSchedulerState()

  const addEvent = () => {
    const pourTime = selectedDate.plus({ hours: DEFAULT_START_HOUR })
    history.push({
      pathname: `/branches/${branch?.id}/schedule/${dateToSlug(pourTime)}/new`,
      state: {
        startTime: pourTime.toISO(),
      },
    })
  }

  return (
    <CollapsibleSider localStorageKey="scheduleSidebarCollapsed" position="right" width={170}>
      <LocationAwareMenu
        menu={SchedulerSidebarMenu}
        theme="light"
      >
        <MenuLinkToggleable
          basePath={basePath}
          key={`${basePath}/new`}
          link={`${basePath}/new`}
          icon={<PlusOutlined />}
          onClick={addEvent}
          preventNavigation
        >
          Add Event
        </MenuLinkToggleable>
        <MenuLinkToggleable
          basePath={basePath}
          key={`${basePath}/calendar`}
          link={`${basePath}/calendar`}
          icon={<CalendarOutlined />}
        >
          Calendar
        </MenuLinkToggleable>
        <Menu.Item
          key="minifiedToggle"
          icon={showMinifiedView ? <SplitCellsOutlined rotate={90} /> : <MergeCellsOutlined rotate={90} />}
          onClick={(_info) => {
            toggleMinifiedView()
          }}
        >
          {showMinifiedView ? 'Expanded Mode' : 'Minified Mode'}
        </Menu.Item>
        {/* <EditModeMenuItem key="editingToggle" /> */}
        <Menu.Item
          key="inactiveToggle"
          icon={inactiveEventsVisible ? <StopOutlined /> : <StopOutlined style={{ opacity: 0.25 }} />}
          onClick={(_info) => {
            toggleInactiveEvents()
          }}
        >
          {inactiveEventsVisible ? 'Hide Cancelled' : 'Show Cancelled'}
        </Menu.Item>
      </LocationAwareMenu>
    </CollapsibleSider>
  )
}
