import { ApolloError } from '@apollo/client'
import { UninitializedAccessHandler } from 'errors/UninitializedAccessHandler'
import React, { useContext, useEffect, useState } from 'react'
import { BranchesContextValueType, useBranches } from './useBranches'

export type SelectedBranch = BranchesContextValueType['branches'][0]

interface SelectedBranchContextValueType {
  setBranchId: (id: number) => void
  branchId: number | null
  branch: SelectedBranch | null
  loading: boolean
  error?: ApolloError | null
}

const SelectedBranchContext = React.createContext<SelectedBranchContextValueType>(
  UninitializedAccessHandler('SelectedBranchProvider was not initialized')
)

export const SelectedBranchProvider: React.FC = ({ children }) => {
  const { branches, previousBranches, ...useQueryRest } = useBranches()

  const [selectedBranchId, setSelectedBranchId] = useState<number | null>(() => {
    const last = localStorage.getItem('lastBranch')
    return last ? parseInt(last) : null
  })

  const setBranchId = (id: number) => {
    localStorage.setItem('lastBranch', id.toString())
    setSelectedBranchId(id)
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const branch = branches.find((branch) => branch.id === selectedBranchId) || null

  useEffect(() => {
    if (selectedBranchId === null && branches.length) {
      setBranchId(branches[0].id)
    }
  }, [branches, selectedBranchId])

  // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
  const value: SelectedBranchContextValueType = {
    ...useQueryRest,
    branchId: selectedBranchId,
    branch,
    setBranchId,
  }

  return (
    <SelectedBranchContext.Provider value={value}>
      {children}
    </SelectedBranchContext.Provider>
  )
}

export const useSelectedBranch = () => useContext(SelectedBranchContext)

export const useBranch = () => useContext(SelectedBranchContext).branch
