import { gql, useQuery } from '@apollo/client'
import { Query } from 'schema'

export const useSamsaraSettings = () => {
  const settingsQuery = useQuery<Query>(GET_SAMSARA_SETTINGS, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  })

  const { data, previousData, ...rest } = settingsQuery

  return {
    data: data?.integrations?.samsara?.settings,
    previousData: previousData?.integrations?.samsara?.settings,
    ...rest,
  }
}

export const GET_SAMSARA_SETTINGS = gql`
  query GetSamsaraSettings {
    integrations {
      samsara {
        settings {
          enabled
          organizationId
        }
      }
    }
  }
`
