import { EditMode } from 'components/scheduler/EditModeState'
import { timeWithZone } from 'helpers/datetime'
import { orderScheduleUrl } from 'helpers/url'
import { omit } from 'lodash'
import { useMemo } from 'react'
import { RecursivePartial } from 'types'
import { DateTime } from 'utils/luxon'

import { useBranch } from 'hooks/useBranch'
import { useNotification } from 'hooks/useNotification'
import { Link, useHistory } from 'react-router-dom'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

import { gql, useMutation } from '@apollo/client'
import {
  MessageStatus, Mutation, MutationSendOrderMessageArgs, Order,
} from 'schema'

import { ExternalNavigate } from 'components/common/ExternalNavigate'
import { isMissingAddress } from 'helpers/order/isMissingAddress'
import { quickbooksLinkFromOrder } from 'helpers/quickbooksLinkFromOrder'
import { PartialOrder } from 'types/PartialOrder'
import { DropdownMenu, DropdownMenuProps } from './DropdownMenu'
import { Menu, MenuDivider } from './Menu'

export interface OrderActionsMenuProps extends Omit<DropdownMenuProps, 'dropdownProps' | 'menuProps'> {
  order: PartialOrder
}

const SEND_ORDER_MESSAGE_MUTATION = gql`
  mutation SendOrderMessage(
    $data: SendOrderMessageInput!,
    $where: OrderWhereUniqueInput!
  ) {
    sendOrderMessage(
      data: $data
      where: $where
    ) {
      id
      subject
      status
      recipient {
        id
        firstName
        lastName
      }
    }
  }
`

export const OrderActionsMenu = ({ order, ...rest }: OrderActionsMenuProps): JSX.Element | null => {
  const history = useHistory()
  const notification = useNotification()
  const branch = useBranch()

  const [sendMessage] = useMutation<Mutation, MutationSendOrderMessageArgs>(SEND_ORDER_MESSAGE_MUTATION)

  const orderId = order?.id
  const addressMissing = isMissingAddress(order)

  if (!orderId) return null

  const orderScheduleLink = orderScheduleUrl(order)
  const moveOrderToNewRoute = !orderScheduleLink ? null : () => {
    const editMode: RecursivePartial<EditMode> = {
      mode: 'move-order-to-route',
      orders: {
        selected: [orderId],
      },
    }

    history.push(orderScheduleLink.split('/').slice(0, -2).join('/') || '', {
      editMode,
    })
  }

  const sendMessageTemplate = async (type: string, forceResend = false) => {
    const response = await sendMessage({
      variables: {
        data: { type, forceResend },
        where: { id: orderId },
      },
    })

    if ((response.errors || []).length > 0) {
      const isDuplicate = (response.errors || []).every((error) => error.extensions?.code === 'DUPLICATE_ERROR')

      if (isDuplicate) {
        Modal.confirm({
          title: 'Message Already Sent',
          icon: <ExclamationCircleOutlined />,
          content: <>
            This message has already been sent to this contact for this Order. <strong>Do you want to Resend?</strong>
          </>,
          cancelText: 'Cancel',
          okText: 'Resend',
          onOk() {
            sendMessageTemplate(type, true)
          },
        })
      } else {
        notification.error({
          top: 60,
          message: 'Error Sending Message',
          description: response?.errors?.map((error) => error.message).join('\n'),
          duration: 5,
        })
      }
    } else {
      const message = response?.data?.sendOrderMessage
      const messageSubject = message?.subject || 'Message'

      if (message?.status === MessageStatus.Skipped) {
        notification.error({
          top: 60,
          message: 'Message NOT Sent',
          description: 'Contact has opted out of these notifications',
          duration: 5,
        })
      } else {
        notification.success({
          top: 60,
          message: 'Message Sent',
          description: `${messageSubject} has been sent to ${message?.recipient?.firstName} ${message?.recipient?.lastName}`,
          duration: 3,
        })
      }
    }
  }
  const sendGetCustomerConfirmation = () => sendMessageTemplate('contact/order/request_confirmation')
  const sendSendOperatorInfo = () => sendMessageTemplate('contact/order/dispatched')

  const duplicateOrder = (extraState: object = {}) => {
    // TODO: switch to a delete all ids, except a keep list
    const omitFields: Array<keyof Order> = ['id', 'revision', 'name', 'comments', 'status', 'statusDetails', 'cancellationReason', 'cancellationReasonDetails']
    const newOrder = omit(JSON.parse(JSON.stringify(order)), omitFields) as PartialOrder

    [newOrder.planned, newOrder.billable, ...(newOrder.actuals || [])].forEach((detail) => {
      if (!detail) return
      detail.id = undefined

      detail?.inventory?.forEach((obj) => {
        obj.id = undefined
      })
      detail?.schedule?.forEach((obj) => {
        obj.id = undefined

        if (obj.pour) {
          obj.pour.id = undefined
        }
      })
    })

    history.push({
      // eslint-disable-next-line no-restricted-globals, prefer-regex-literals
      pathname: location.pathname.replace(new RegExp('/order/.*$'), '/new'),
      state: {
        fullOrder: newOrder,
        ...extraState,
      },
    })
  }

  const rescheduleOrder = () => {
    duplicateOrder({
      rescheduleFrom: order?.id,
      tabTitle: 'Reschedule Order',
    })
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const quickbooksLink = useMemo(() => quickbooksLinkFromOrder(order), [order?.integrations])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const weatherLink = useMemo(() => {
    const zip = order?.site?.address?.zip
    const timezone = order?.site?.address?.timezone || branch?.timezone || DateTime.local().zoneName
    const dos = timeWithZone(order?.dateOfService, timezone)
    if (!zip || !dos) return null
    const now = DateTime.utc().setZone(timezone)
    const rangeStart = now.startOf('day')
    const rangeEnd = now.plus({ days: 8 }).endOf('day')
    if (rangeStart < dos && dos < rangeEnd) {
      return `https://www.google.com/search?q=${zip}+weather+${dos.toISODate()}`
    }
  }, [order?.site?.address?.zip, order?.dateOfService])

  return (
    <DropdownMenu {...rest}>
      <MenuDivider>Actions</MenuDivider>
      <Menu.Item key="actions.reschedule" onClick={rescheduleOrder}>Reschedule Order</Menu.Item>
      <Menu.Item key="actions.duplicate" onClick={() => duplicateOrder()}>Duplicate Order</Menu.Item>

      {moveOrderToNewRoute &&
        <Menu.Item key="actions.newRoute" onClick={() => moveOrderToNewRoute()}>Move to Another Route</Menu.Item>}

      <MenuDivider>Messaging</MenuDivider>
      <Menu.Item key="msg.customerConfirmation" onClick={sendGetCustomerConfirmation}>
        Get Customer Confirmation
      </Menu.Item>
      <Menu.Item key="msg.operatorInfo" onClick={sendSendOperatorInfo}>
        Send Operator Info
      </Menu.Item>

      <MenuDivider>Links</MenuDivider>
      <Menu.Item key="links.navigateOnGoogle" disabled={addressMissing}>
        <ExternalNavigate customerId={order?.customerId || undefined} siteId={order?.siteId || undefined} />
      </Menu.Item>
      <Menu.Item key="links.viewFull">
        <Link to={`/orders/${order.id}`}>View Full Order</Link>
      </Menu.Item>

      {quickbooksLink && (
        <Menu.Item key="links.viewQuickbooks">
          <a target="_blank" href={quickbooksLink} rel="noreferrer">View Invoice</a>
        </Menu.Item>
      )}
      {weatherLink && (
        <Menu.Item key="links.viewWeather">
          <a target="_blank" href={weatherLink} rel="noreferrer">View Weather Forecast</a>
        </Menu.Item>
      )}
    </DropdownMenu>
  )
}
