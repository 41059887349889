import { Promisable } from 'type-fest'

import { quickbooksLinkFromOrder } from 'helpers/quickbooksLinkFromOrder'
import { isEmpty } from 'lodash'

import { gql, useMutation, useQuery } from '@apollo/client'
import {
  Mutation, Order, Query, QueryOrderArgs, QuickbooksMutationNamespaceCreateInvoiceArgs,
} from 'schema'

import { useFeature } from 'hooks/useFeature'
import { useNotification } from 'hooks/useNotification'
import { useState } from 'react'

import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { QuickbooksIcon } from 'components/icons'
import { Menu } from 'components/menus/Menu'
import { DownloadQuickbooksInvoiceButton } from './DownloadQuickbooksInvoiceButton'

const GET_ORDER_WITH_QUICKBOOKS_ID = gql`
  query GetOrderForQuickbooks($id: Int!) {
    order(id: $id) {
      id
      integrations {
        quickbooks {
          invoice {
            id
          }
        }
      }
    }
  }
`

interface ManageQuickbooksInvoiceButtonProps {
  disabled?: boolean
  orderId: Order['id']
  beforeSubmit?: () => Promise<void>
  onSubmission?: (orderId: Order['id'], action: 'create') => Promisable<void>
}

export const ManageQuickbooksInvoiceButton = (props: ManageQuickbooksInvoiceButtonProps) => {
  const [quickbooksEnabled] = useFeature('integrations.quickbooks.enabled')
  if (!quickbooksEnabled) return null
  return <EnabledButton {...props} />
}

const EnabledButton = (props: ManageQuickbooksInvoiceButtonProps) => {
  const { orderId, onSubmission, disabled } = props
  const notification = useNotification()
  const [loading, setLoading] = useState<boolean>(false)

  const { data } = useQuery<Query, QueryOrderArgs>(GET_ORDER_WITH_QUICKBOOKS_ID, {
    fetchPolicy: 'cache-only',
    variables: {
      id: orderId,
    },
  })

  const [createInvoiceMutation] = useMutation<Mutation, QuickbooksMutationNamespaceCreateInvoiceArgs>(
    gql`
      mutation createInvoice($where: OrderWhereUniqueInput!, $forceRecreate: Boolean) {
        integrations {
          quickbooks {
            createInvoice(where: $where, forceRecreate: $forceRecreate) {
              success
            }
          }
        }
      }
    `,
    {
      refetchQueries: ['GetOrderForQuickbooks'],
      awaitRefetchQueries: true,
    }
  )

  const createInvoiceHandler = async (forceRecreate?: boolean) => {
    setLoading(true)

    if (props.beforeSubmit) {
      await props.beforeSubmit()
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { errors, data } = await createInvoiceMutation({
        variables: {
          forceRecreate,
          where: {
            id: orderId,
          },
        },
      })

      if (!isEmpty(errors)) {
        throw new Error(errors?.map((err) => err.message).join(', '))
      }
      const success = data?.integrations?.quickbooks?.createInvoice?.success
      if (!success) {
        throw new Error('Quickbooks invoice creation is not enabled for this account. Please contact support.')
      }

      if (onSubmission) {
        await onSubmission(orderId, 'create')
      }
    } catch (err: any) {
      notification.error({
        message: 'Quickbooks Error',
        description: err?.message,
        duration: 15,
      })
    } finally {
      setLoading(false)
    }
  }

  const createInvoice = () => createInvoiceHandler(false)
  const recreateInvoice = () => createInvoiceHandler(true)

  const order = data?.order
  if (!order) {
    return null
  }

  const quickbooksLink = quickbooksLinkFromOrder(order)

  return quickbooksLink ? (
    <div className="ant-btn-group">
      <Button
        disabled={disabled}
        loading={loading}
        href={quickbooksLink}
        target="_blank"
        icon={<QuickbooksIcon />}
      >
        View Invoice
      </Button>

      <Dropdown
        disabled={disabled}
        placement="bottomRight"
        overlay={(
          <Menu>
            <Menu.Item icon={<QuickbooksIcon />}>
              <DownloadQuickbooksInvoiceButton orderId={order.id} icon={null}>
                Download Invoice
              </DownloadQuickbooksInvoiceButton>
            </Menu.Item>
            <Menu.Item key="recreate" onClick={recreateInvoice} icon={<QuickbooksIcon />}>
              Recreate Invoice
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Button
          disabled={disabled}
          icon={<MoreOutlined />}
          className={loading ? 'ant-btn-loading' : undefined}
        />
      </Dropdown>
    </div>
  )
    : (
      <Button
        disabled={disabled}
        icon={<QuickbooksIcon />}
        onClick={createInvoice}
        loading={loading}
      > Create Invoice
      </Button>
    )
}
