import {
  Redirect, Route, Switch, useRouteMatch,
} from 'react-router-dom'
import { SamsaraEquipmentMatch } from './EquipmentMatch'
import { SamsaraOperatorsMatch } from './OperatorsMatch'
import { SamsaraSettings } from './Settings'

export const SamsaraPage = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/operators`} component={SamsaraOperatorsMatch} />
      <Route path={`${match.path}/equipment`} component={SamsaraEquipmentMatch} />
      <Route path={`${match.path}/`} exact component={SamsaraSettings} />
      <Redirect to="/" />
    </Switch>
  )
}

export default SamsaraPage
