import {
  Button,
  Col, Row,
  Space,
} from 'antd'
import { useLastLocation } from 'react-router-last-location'
import styled from 'styled-components'
import colors from '../../constants/colors'
import { PartialInvoice } from '../../hooks/useInvoices'
import { PreviousButton } from '../buttons'
import { InvoiceStatusBadge } from './InvoiceStatusBadge'
import { ReceivePaymentToggle, TriggerButton } from './ReceivePaymentToggle'

interface InvoicePageHeaderProps {
  invoice?: PartialInvoice
  isEditing: boolean,
  isNew: boolean,
  onPrintPDF?: () => void,
  onSubmitInvoice?: () => void,
  onCancelEdit: () => void,
  onEdit: () => void,
  onSave: () => void
}

const InvoiceStatusBadgeTitle = styled(InvoiceStatusBadge)`
  .ant-badge-status-text {
    font-weight: 500;
    font-size: 16px;
  }

  .ant-badge-status-dot {
    margin-top: -1px;
    width: 10px;
    height: 10px;
  }
`

// orderId, onGoBack, onPreview, onSubmitInvoice,
export const InvoiceHeader = ({
  invoice, isNew, isEditing, onPrintPDF, onSubmitInvoice, onCancelEdit, onEdit, onSave,
}: InvoicePageHeaderProps) => {
  const lastLocation = useLastLocation()
  let previousUrl = lastLocation?.pathname || '/invoices'

  if (previousUrl === '/invoices/new') {
    const orderId = new URLSearchParams(lastLocation?.search || '?').get('orderId')

    if (orderId) {
      previousUrl = `/orders/${orderId}`
    } else {
      previousUrl = '/invoices'
    }
  }

  return (
    <>
      <Row gutter={[16, 16]} align="middle">
        <Col style={{ margin: 0 }}>
          <PreviousButton href={previousUrl}>Back</PreviousButton>
        </Col>
        <Col flex="auto" style={{ marginLeft: '170px', textAlign: 'center' }}>
          {
            invoice && <InvoiceStatusBadgeTitle invoice={invoice as any} size="large" />
          }
        </Col>
        <Col>
          <Space wrap>
            <ActionButton onClick={onPrintPDF}>Print PDF</ActionButton>
            <ActionButton onClick={onSubmitInvoice}>Send email</ActionButton>
            <div style={{ width: '162.72px' }} />
          </Space>
        </Col>
      </Row>
      <ReceivePaymentToggleWrap>
        <ReceivePaymentToggle
          buttonPosition='right'
          invoiceIds={invoice?.id && (invoice.balance || 0) > 0 ? [invoice.id] : []}
        />
      </ReceivePaymentToggleWrap>
    </>
  )
}

const ActionButton = styled(Button)`
  background-color: #fafafa;
  border-color: #f0f0f0;
`

const ReceivePaymentToggleWrap = styled.div`
  margin-top: -32px;

  ${TriggerButton} {
    color: ${colors.fontPrimary};
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 400;
  }
`
