import { SupplierDrawer } from 'components/drawers/SupplierDrawer'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { ViewMoreDrawer } from 'components/layout/ViewMoreDrawer'
import { GET_SUPPLIERS_SLIM } from 'gql/suppliers'
import { compactAndJoin } from 'helpers/formatting'
import { byFieldAsc } from 'helpers/sortting'
import {
  compact, isNumber, isString, uniq,
} from 'lodash'
import React from 'react'
import { Supplier } from 'schema'
import { RecursivePartial } from 'types'

export type SupplierSelectProps = ReferenceSelectProps<Supplier>

const supplierOptionGenerator = (supplier?: RecursivePartial<Supplier> | null) => {
  if (!supplier?.id) return

  return {
    label: compactAndJoin(' | ', uniq([supplier.name, supplier.name2])),
    value: supplier.id,
  }
}

const defaults: Partial<SupplierSelectProps> = {
  showSearch: true,
  optionsGenerator: (suppliers) => (
    compact(suppliers.map(supplierOptionGenerator)).sort(byFieldAsc('label'))
  ),
  generateExternalLink: (supplierId) => {
    const id = isNumber(supplierId) ? supplierId : (isString(supplierId) ? parseInt(supplierId) : undefined)

    return (
      <ViewMoreDrawer
        closeIcon={false}
        buttonStyle={{ opacity: id ? 1 : 0, visibility: id ? 'visible' : 'hidden' }}
      >
        <SupplierDrawer key={id} id={id} />
      </ViewMoreDrawer>
    )
  },
}

export const SupplierSelect: React.FC<SupplierSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Supplier',
  queryField: 'suppliers',
  gql: GET_SUPPLIERS_SLIM,
  defaults: {
    ...defaults,
    showExternalLink: !props.mode,
  },
}, props))

export default SupplierSelect
