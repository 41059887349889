import { compact } from 'lodash'
import { RouteWaypoint } from 'schema'

export interface WaypointNameOptions {
  includeCustomer?: boolean
}

export const waypointName = (waypoint: Partial<RouteWaypoint>, options?: WaypointNameOptions) => {
  const siteName = waypoint?.order?.site?.name
  if (siteName) {
    return compact([
      options?.includeCustomer === false ? null : waypoint?.order?.customer?.abbreviation,
      siteName,
    ]).join(' | ')
  }

  if (waypoint.name) {
    return waypoint.name
  }

  if (waypoint.address?.name) {
    return waypoint.address.name
  }

  const addressParts = compact([waypoint.address?.city, waypoint.address?.state])
  if (addressParts.length > 0) {
    return addressParts.join(', ')
  }

  return null
}
