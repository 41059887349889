import { ApolloError, gql } from '@apollo/client'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { RecursivePartial } from 'types'
import { GetOrderForBillingQuery, useGetOrderForBillingQuery } from './__generated__/data'

gql`
  fragment OrderForBillingFull on Order {
    id
    revision
    ...OrderShowHeaderFragment
    ...OrderShowOverviewFragment
    ...OrderCompareTableFragment
  }
`

gql`
  query GetOrderForBilling($id: Int!) {
    order(id: $id) {
      ...OrderForBillingFull
    }
  }
`

export type FullOrder = NonNullable<GetOrderForBillingQuery['order']>
export type PartialOrder = RecursivePartial<FullOrder>

type OrderDetailsByKey<Order extends PartialOrder = FullOrder> = {
  planned: Order['planned']
  billable: Order['billable']
} & {
  [key: string]: NonNullable<Order['actuals']>[0]
}

export type OrderBillingData<
  Order extends PartialOrder = FullOrder
> = {
  order?: Order,
  details?: OrderDetailsByKey<Order>,
  loading: boolean,
  error: ApolloError | undefined
}

export const useOrderBillingData = <
  Order extends PartialOrder = FullOrder
>(
  orderId: number
): OrderBillingData<Order> => {
  const { data, loading, error } = useGetOrderForBillingQuery({
    fetchPolicy: 'network-only',
    variables: { id: orderId },
    skip: !orderId,
  })

  const order = data?.order || undefined

  const details: any = useMemo(() => {
    if (!order) return undefined

    return Object.fromEntries(compact([
      order.planned,
      ...order.actuals,
      order.billable,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ]).map((details) => (
      [
        compact([details.type, details.subType]).join('.'),
        details,
      ]
    )))
  }, [order])

  return {
    order: order as Order, details, loading, error,
  }
}
