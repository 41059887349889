import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAvailableEquipmentQueryVariables = Types.Exact<{
  where: Types.QueryEquipmentAvailabilitiesWhereInput;
}>;


export type GetAvailableEquipmentQuery = { __typename?: 'Query', equipmentAvailability: Array<{ __typename?: 'EquipmentAvailabilitySnapshot', id: string, equipment: { __typename?: 'Equipment', id: number, name: string, size?: number | null, type: string, sort: number, active: boolean, group?: { __typename?: 'EquipmentGroup', id: number, name: string, sort: number, locationAware: boolean, locationSort?: Array<number> | null } | null, documents: Array<{ __typename?: 'Document', id: number, attachments?: Types.Scalars['Json'] | null, expirationDate?: any | null, pinned: boolean, fields: Array<{ __typename?: 'KeyValue', key?: string | null, value?: Types.Scalars['Json'] | null }>, typeDetails: { __typename?: 'DocumentType', name: string, slug: string } }> }, availability: Array<{ __typename?: 'EquipmentAvailability', id: number, validFrom: Types.Scalars['DateTime'], validTo?: Types.Scalars['DateTime'] | null, address: { __typename?: 'Address', id: number, name?: string | null } }> }> };


export const GetAvailableEquipmentDocument = gql`
    query GetAvailableEquipment($where: QueryEquipmentAvailabilitiesWhereInput!) {
  equipmentAvailability(where: $where) {
    id
    equipment {
      id
      name
      size
      type
      sort
      active
      group {
        id
        name
        sort
        locationAware
        locationSort
      }
      documents(where: {active: {equals: true}}) {
        id
        attachments
        expirationDate
        pinned
        fields {
          key
          value
        }
        typeDetails {
          name
          slug
        }
      }
    }
    availability {
      id
      validFrom
      validTo
      address {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetAvailableEquipmentQuery__
 *
 * To run a query within a React component, call `useGetAvailableEquipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableEquipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableEquipmentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAvailableEquipmentQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableEquipmentQuery, GetAvailableEquipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableEquipmentQuery, GetAvailableEquipmentQueryVariables>(GetAvailableEquipmentDocument, options);
      }
export function useGetAvailableEquipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableEquipmentQuery, GetAvailableEquipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableEquipmentQuery, GetAvailableEquipmentQueryVariables>(GetAvailableEquipmentDocument, options);
        }
export type GetAvailableEquipmentQueryHookResult = ReturnType<typeof useGetAvailableEquipmentQuery>;
export type GetAvailableEquipmentLazyQueryHookResult = ReturnType<typeof useGetAvailableEquipmentLazyQuery>;
export type GetAvailableEquipmentQueryResult = Apollo.QueryResult<GetAvailableEquipmentQuery, GetAvailableEquipmentQueryVariables>;
export function refetchGetAvailableEquipmentQuery(variables: GetAvailableEquipmentQueryVariables) {
      return { query: GetAvailableEquipmentDocument, variables: variables }
    }