import { byFieldAsc } from 'helpers/sortting'
import { cloneDeep, compact } from 'lodash'
import { PartialOrder } from 'types/PartialOrder'
import { sortOrderContacts } from './sortOrderContacts'

export const sortOrderData = (order: PartialOrder | undefined): PartialOrder | undefined => {
  if (!order) {
    return
  }
  const cloned = cloneDeep(order)

  if (cloned.contacts) {
    cloned.contacts = sortOrderContacts(cloned.contacts)
  }

  compact([
    cloned.planned,
    cloned.billable,
    ...(cloned.actuals || []),
  ]).forEach((details) => {
    details?.schedule?.sort((a, b) => {
      const startSort = byFieldAsc('startTime')(a, b)
      if (startSort !== 0) return startSort
      return byFieldAsc('scheduleIndex')(a, b)
    })
  })

  return cloned
}
