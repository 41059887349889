import { gql } from '@apollo/client'
import { COMMENT_FRAGMENT } from './comments'

export const NOTE_SLIM_FRAGMENT = gql`
  fragment NoteFields on Note {
    id
    active
    title
    equipmentId
    equipment {
      id
      name
    }
    customer {
      id
      abbreviation
      name
    }
    contact {
      id
      firstName
      lastName
    }
  }
`

export const NOTE_FRAGMENT = gql`
  fragment NoteFields on Note {
    id
    title
    contactId
    customerId
    equipmentId
    active
    createdAt
    updatedAt
    event {
      id
      startTime
      endTime
      branchId
      active
    }
    comments {
      ...CommentFields
    }
  }
  ${COMMENT_FRAGMENT}
`

export const GET_NOTES = gql`
  query GetNote(
    $where: QueryNotesWhereInput
  ) {
    notes(
      where: $where
    ) {
      ...NoteFields
    }
  }
  ${NOTE_FRAGMENT}
`

export const CREATE_NOTE = gql`
  mutation CreateNote(
    $data: NoteInput!
  ) {
    createNote(
      data: $data
    ) {
      ...NoteFields
    }
  }
  ${NOTE_FRAGMENT}
`

export const UPDATE_NOTE = gql`
  mutation UpdateNote(
    $data: NoteUpdateInput!
    $where: NoteWhereUniqueInput!
  ) {
    updateNote(
      data: $data
      where: $where
    ) {
      ...NoteFields
    }
  }
  ${NOTE_FRAGMENT}
`

export const DELETE_NOTE = gql`
  mutation DeleteNote(
    $where: NoteWhereUniqueInput!
  ) {
    updateNote(
      data: { active: false },
      where: $where
    ) {
      ...NoteFields
    }
  }
  ${NOTE_FRAGMENT}
`
