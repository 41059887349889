import { PourType } from 'schema'
import { byFieldAsc, byFieldDesc } from './sortting'
import { treeFromFlatArray } from './treeFromFlatArray'

export function pourTypesToTree<
  ChildrenKey extends string = 'children'
>(
  pourTypesArray: PourType[],
  childrenKey: ChildrenKey = ('children' as ChildrenKey)
) {
  if (pourTypesArray.length === 0) return []

  const pourTypesSorted = pourTypesArray.slice().sort((a, b) => byFieldDesc('pinned')(a, b) || byFieldAsc('name')(a, b))

  return treeFromFlatArray(pourTypesSorted, 'id', 'categoryId', childrenKey)
}
