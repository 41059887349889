import { UserForm, UserFormProps } from 'components/form/UserForm'
import { CloseButton } from 'hooks/useClosable'
import {
  Actions, Body, ContentContext, Footer, Header, useContentLayoutState,
} from 'hooks/useContentLayout'
import { DrawerContent } from '../../layouts/Content/DrawerContent'
import { FooterSection } from './FooterSection'

export type UserDrawerProps = UserFormProps

export const UserDrawer = (props: UserFormProps) => {
  const { id } = props

  const FormContentLayout = useContentLayoutState()

  return (
    <DrawerContent>
      <Header>
        {id ? <FormContentLayout.Header.Target /> : 'New User'}
      </Header>

      <Actions>
        <CloseButton />
      </Actions>

      <Body>
        <ContentContext.Provider value={FormContentLayout}>
          <UserForm key={`user-${id}`} {...props} />
        </ContentContext.Provider>
      </Body>

      <Footer>
        <FooterSection>
          <FormContentLayout.Footer.Target />
        </FooterSection>
      </Footer>
    </DrawerContent>
  )
}
