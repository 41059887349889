import React, { useEffect, useState } from 'react'

import {
  Mutation,
  MutationCreatePourTypeArgs,
  MutationUpdatePourTypeArgs, PourType, PourTypeInput, Query,
  QueryPourTypesArgs,
} from 'schema'

import { useMutation, useQuery } from '@apollo/client'
import { CREATE_POUR_TYPE, GET_POUR_TYPES, UPDATE_POUR_TYPE } from 'gql/pour-types'

import {
  Button,
  Col,
  Row,
  Typography,
} from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'
import { Form, FormProps as FormPropsUntyped } from 'components/form/Form'
import { Input } from 'components/form/inline-edit/Input'
import SwitchInput from 'components/form/inline-edit/Switch'
import { Loading } from 'components/Loading'
import { Footer, Header } from 'hooks/useContentLayout'
import { RecursivePartial } from 'types'
import { WithIconTooltip } from '../../common/WithIconTooltip'
import ReferenceSelect, { buildPropsFromGql, ReferenceSelectProps } from '../reference-selects/ReferenceSelect'

const { Paragraph } = Typography

type FormProps = FormPropsUntyped<PourType>

type FormValues = PourType

export interface PourTypeFormProps {
  id?: number
  editing?: boolean
  onSubmission?: (pourType: PourType, action: 'update' | 'create') => void
  onCancel?: () => void
}

export const PourTypeForm: React.FC<PourTypeFormProps> = (props) => {
  const { id, onSubmission } = props
  const isNew = id === undefined

  const [editing, setEditing] = useState<boolean>(props.editing || isNew)

  const [categoryDisabled, setCategoryDisabled] = useState<boolean>(false)

  const { data, error: queryError, loading: queryLoading } = useQuery<Query, QueryPourTypesArgs>(
    GET_POUR_TYPES,
    {
      variables: {
        where: {
          id: {
            equals: id,
          },
        },
      },
      skip: isNew,
    }
  )

  const [
    createPourType,
    createPourTypeResponse,
  ] = useMutation<Mutation, MutationCreatePourTypeArgs>(CREATE_POUR_TYPE, {
    update: (cache) => {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'pourTypes' })
    },
  })

  const [
    updatePourType,
    updatePourTypeResponse,
  ] = useMutation<Mutation, MutationUpdatePourTypeArgs>(UPDATE_POUR_TYPE)

  const { error: mutationError } = isNew ? createPourTypeResponse : updatePourTypeResponse

  const pourType = (data?.pourTypes || [])[0] as PourType | undefined

  const [form] = Form.useForm<FormValues>()

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.resetFields()
  }

  const onValuesChange: FormProps['onValuesChange'] = (changedValues: RecursivePartial<FormValues>, allValues) => {
    if (changedValues.isCategory !== undefined) {
      setCategoryDisabled(allValues.isCategory)
    }

    if (changedValues.isCategory === true) {
      form.setFieldsValue({ categoryId: null })
    }
  }

  const onFinish = async (values: FormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data: PourTypeInput = values
    data.categoryId = data.categoryId || null

    if (id) {
      const resp = await updatePourType({
        variables: { data, where: { id } },
      })

      if (resp.errors) return

      setEditing(false)

      const updatedPourType = resp?.data?.updatePourType

      if (updatedPourType && onSubmission) {
        onSubmission(updatedPourType, 'update')
      }
    } else {
      const resp = await createPourType({
        variables: { data },
      })

      if (resp.errors) return

      setEditing(false)

      const newPourType = resp?.data?.createPourType

      if (newPourType && onSubmission) {
        onSubmission(newPourType, 'create')
      }
    }
  }

  const initialValues = {
    ...pourType,
    active: isNew ? true : pourType?.active,
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  const placeholderDuration = 1.0

  useEffect(() => {
    if (queryLoading) return
    onValuesChange(initialValues, initialValues as any)
  }, [initialValues])

  if (queryError) {
    return (
      <ErrorMessage>
        <Paragraph>{queryError.message}</Paragraph>
      </ErrorMessage>
    )
  }

  if (!pourType && queryLoading) return <Loading />

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="pour-type-form"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        requiredMark={editing}
        initialValues={initialValues}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) => (
            prev.name !== current.name
          )}
        >
          {() => (
            <Header hideIfOrphan>
              {form.getFieldValue('name')}
            </Header>
          )}
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
          ]}
        >
          <Input placeholder="Pour type name" editing={editing} />
        </Form.Item>

        {/* <Form.Item
        label="Duration Multiplier"
        name="durationMultiplier"
      >
        <InputNumber
          editing={editing}
          placeholder={ placeholderDuration.toFixed( placeholderDuration.toString().split('.')[1]?.length || 1 ) }
          step={"0.1"}
          onStep={(value, evt) => {
            if (value === 0.1 && evt.type === 'up') {
              form.setFieldsValue({ durationMultiplier: placeholderDuration + 0.1 })
            }
            if (value < 0) {
              form.setFieldsValue({ durationMultiplier: placeholderDuration - 0.1 })
            }
          }}
        />
      </Form.Item> */}

        <Form.Item
          label="Parent Category"
          name="categoryId"
        >
          <PourTypeCategoriesSelect
            editing={editing}
            placeholder={categoryDisabled ? "Categories can't be nested" : 'Optionally nest in category'}
            disabled={categoryDisabled}
          />
        </Form.Item>

        <Form.Item
          label="Set as Category"
          name="isCategory"
          valuePropName="checked"
        >
          <SwitchInput
            editing={editing}
          />
        </Form.Item>

        <Form.Item
          label={(
            <WithIconTooltip title="Makes this Pour Type display at the top of the dropdown when creating an order.">
              Pinned
            </WithIconTooltip>
          )}
          name="pinned"
          valuePropName="checked"
        >
          <SwitchInput
            editing={editing}
          />
        </Form.Item>

        <Form.Item
          label={(
            <WithIconTooltip title="Controls whether or not this Pour Type is shown the dropdown when creating an order.">
              Active
            </WithIconTooltip>
          )}
          name="active"
          valuePropName="checked"
        >
          <SwitchInput
            editing={editing}
          />
        </Form.Item>
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}

const PourTypeCategoriesSelect: React.FC<ReferenceSelectProps<PourType>> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Pour Type',
  queryField: 'pourTypes',
  gql: GET_POUR_TYPES,
  defaults: {
    allowClear: true,
  },
  fetchPolicy: 'cache-and-network',
  variables: {
    where: {
      active: undefined,
      isCategory: {
        equals: true,
      },
    },
  },
}, props))
