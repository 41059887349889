import { gql } from '@apollo/client'
import { PHONE_FRAGMENT } from 'gql/phones'

export const SUPPLIER_SLIM_FRAGMENT = gql`
  fragment SupplierFragment on Supplier {
    id
    name
    name2
  }
`

export const SUPPLIER_FULL_FRAGMENT = gql`
  fragment SupplierFragment on Supplier {
    id
    name
    name2
    phones {
      ...PhoneFields
    }
    active
  }
  ${PHONE_FRAGMENT}
`

export const GET_SUPPLIERS_QUERY = gql`
  query GetSuppliers($where: QuerySuppliersWhereInput) {
    suppliers(where: $where) {
      ...SupplierFragment
    }
  }
`

export const GET_SUPPLIERS_SLIM = gql`
  ${GET_SUPPLIERS_QUERY}
  ${SUPPLIER_SLIM_FRAGMENT}
`

export const GET_SUPPLIERS_FULL = gql`
  ${GET_SUPPLIERS_QUERY}
  ${SUPPLIER_FULL_FRAGMENT}
`

export const CREATE_SUPPLIER = gql`
  mutation CreateSupplier(
    $data: SupplierInput!
  ) {
    createSupplier(
      data: $data
    ) {
      ...SupplierFragment
    }
  }
  ${SUPPLIER_FULL_FRAGMENT}
`

export const UPDATE_SUPPLIER = gql`
  mutation UpdateSupplier(
    $data: SupplierUpdateInput!
    $where: SupplierWhereUniqueInput!
  ) {
    updateSupplier(
      data: $data
      where: $where
    ) {
      ...SupplierFragment
    }
  }
  ${SUPPLIER_FULL_FRAGMENT}
`
