import { DateTimePicker, DateTimePickerProps } from 'components/form/inline-edit/DateTimePicker'
import { useEffect, useMemo, useRef } from 'react'
import { DateTime, toMoment } from 'utils/luxon'

export interface StartTimeInputProps extends Omit<DateTimePickerProps, 'format'> {
  branchTimezone?: string | null
  siteTimezone?: string | null
  format?: string | undefined
}

const DEFAULT_FORMAT = 'MM/DD hh:mm a'

export const StartTimeInput: React.FC<StartTimeInputProps> = (props) => {
  const {
    branchTimezone,
    siteTimezone,
    onChange: onChangeProp,
    ...args
  } = props

  const lastTimezone = useRef<string | null>('firstLoad')
  const timezone = siteTimezone || branchTimezone || 'UTC'
  const showTimezone = branchTimezone && timezone !== branchTimezone

  const onChangeWithTimezone = (onChangeOrig: typeof onChangeProp, tz: string | null): typeof onChangeProp => (value, dateString) => {
    if (!onChangeOrig) return
    if (!value) {
      onChangeOrig(value, dateString)
      return
    }

    const siteDateTime = DateTime.fromISO(value.toISOString(true).slice(0, 19)).setZone(tz || 'UTC', { keepLocalTime: true })
    onChangeOrig(toMoment(siteDateTime), dateString)
  }

  const onChange = useMemo(() => onChangeWithTimezone(onChangeProp, timezone), [onChangeProp, timezone])

  args.format = args.format || DEFAULT_FORMAT

  if (showTimezone) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    args.format = `${args.format}  [${DateTime.utc().setZone(timezone!).offsetNameShort}]`
  }

  const hadTimezoneChange = timezone && lastTimezone.current !== 'firstLoad' && lastTimezone.current !== timezone

  args.value = useMemo(() => {
    if (!args.value || !timezone) return args.value

    // if this was an update because of a timezone change, then keep time as is adjusted for that zone, else adjust time to that zone
    if (hadTimezoneChange) {
      const siteDateTime = DateTime.fromISO(args.value.toISOString(true).slice(0, 19)).setZone(timezone, { keepLocalTime: true })
      const asMoment = toMoment(siteDateTime)

      return asMoment
    }
    const siteDateTime = DateTime.fromMillis(args.value.valueOf()).setZone(timezone)
    return toMoment(siteDateTime)
  }, [args.value, timezone])

  useEffect(() => {
    if (hadTimezoneChange && args.value && onChange) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onChange(args.value, args.value.format(args.format!))
    }
  }, [hadTimezoneChange, args.value])

  useEffect(() => {
    lastTimezone.current = timezone || null
  }, [timezone])

  return (
    <DateTimePicker
      placeholder="Select Start Time"
      onChange={onChange}
      {...args}
    />
  )
}
