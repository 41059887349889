import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBranchesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBranchesQuery = { __typename?: 'Query', branches: Array<{ __typename?: 'Branch', id: number, active: boolean, name?: string | null, timezone: string, yards: Array<{ __typename?: 'Yard', id: number, name?: string | null, address: { __typename?: 'Address', id: number, name?: string | null, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null } }>, address: { __typename?: 'Address', id: number, name?: string | null, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null } }> };

export type AddressFieldsFragment = { __typename?: 'Address', id: number, name?: string | null, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null };

export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on Address {
  id
  name
  street
  street2
  city
  state
  zip
  lat
  lng
  timezone
}
    `;
export const GetBranchesDocument = gql`
    query GetBranches {
  branches {
    id
    active
    name
    timezone
    yards {
      id
      name
      address {
        ...AddressFields
      }
    }
    address {
      ...AddressFields
    }
  }
}
    ${AddressFieldsFragmentDoc}`;

/**
 * __useGetBranchesQuery__
 *
 * To run a query within a React component, call `useGetBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBranchesQuery(baseOptions?: Apollo.QueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
      }
export function useGetBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
        }
export type GetBranchesQueryHookResult = ReturnType<typeof useGetBranchesQuery>;
export type GetBranchesLazyQueryHookResult = ReturnType<typeof useGetBranchesLazyQuery>;
export type GetBranchesQueryResult = Apollo.QueryResult<GetBranchesQuery, GetBranchesQueryVariables>;
export function refetchGetBranchesQuery(variables?: GetBranchesQueryVariables) {
      return { query: GetBranchesDocument, variables: variables }
    }