import { round } from 'lodash'
import { DISCOUNT_TYPE, ItemRow, ROW_TYPE } from '../types/ItemTypes'

export function computeSubtotal(rows: ItemRow[]): number {
  let subtotal = 0
  for (const row of rows) {
    if (row.type === ROW_TYPE.PRODUCT) {
      subtotal += round(row.amount, 2)
    }
  }
  return subtotal
}

export function getValueFromPercentage(subtotal: number, discountValue: number) {
  return round(subtotal * (discountValue / 100), 2)
}

export function computeDiscount(subtotal: number, discountValue: number, discountType: DISCOUNT_TYPE): number {
  if (discountType === DISCOUNT_TYPE.FIXED) {
    return discountValue
  } if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
    return getValueFromPercentage(subtotal, discountValue)
  }
  return 0
}
