import { gql } from '@apollo/client'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import React from 'react'
import { ScheduleStep } from 'schema'
import styled from 'styled-components'

export type ScheduleStepSelectProps = ReferenceSelectProps<ScheduleStep>

const GET_POUR_TYPES = gql`
  query GetScheduleSteps($where: QueryScheduleStepsWhereInput) {
    scheduleSteps(where: $where) {
      name
      slug
      sort
    }
  }
`

export const ScheduleStepSelect: React.FC<ScheduleStepSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Step',
  queryField: 'scheduleSteps',
  gql: GET_POUR_TYPES,
  fetchPolicy: 'cache-first',
}, props))

export const ScheduleStepSelectRightAlign = styled(React.forwardRef<HTMLDivElement, ScheduleStepSelectProps>((props, ref) => (
  <div ref={ref}>
    <ScheduleStepSelect {...props} />
  </div>
)))`
  .ant-select {
    .ant-select-selector {
      padding-left: 0;
      padding-right: 0;
    }
    .ant-select-selection-item {
      text-align: right;
      padding-left: 0;
      padding-right: 0;

      :after {
        content: ':';
        position: relative;
        visibility: visible;
        top: -0.5px;
        margin: 0 8px 0 2px;
      }
    }
  }
`

export default ScheduleStepSelect
