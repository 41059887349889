import { AddItemButton } from 'components/buttons'
import { Form, FormListProps } from 'components/form/Form'
import { getNamePath } from 'rc-field-form/es/utils/valueUtil'
import styled from 'styled-components'
import { CustomFieldFormItem } from './CustomFieldFormItem'

interface CustomFieldFormListOptions {
  editing?: boolean
}

interface CustomFieldsFormListProps extends Omit<FormListProps, 'name' | 'children'>, CustomFieldFormListOptions {
  name: FormListProps['name']
}

const CustomFieldsTable = styled.div`
  .ant-form-item {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  // &.notediting > *:nth-child(even) {
  //   background-color: #f3f8fe;
  // }
`

export const CustomFieldsFormList = (props: CustomFieldsFormListProps) => {
  const {
    name,
    editing,
    ...args
  } = props

  const path = getNamePath(name)
  const localizedPath = path.slice(-2)

  return (
    <Form.List
      name={localizedPath}
      {...args}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <Form.ErrorList errors={errors} />

          <CustomFieldsTable>
            {fields.map((field, index) => (
              <CustomFieldFormItem
                key={field.key}
                listPrefix={field}
                name={field.key}
                onRemove={() => remove(index)}
                editing={editing}
              />
            ))}
          </CustomFieldsTable>

          {editing && (
            <AddItemButton
              type="default"
              onClick={() => add()}
              block
              style={{ marginTop: 20 }}
            >
              Add New
            </AddItemButton>
          )}
        </>
      )}
    </Form.List>
  )
}
