import { gql } from '@apollo/client'

export const EQUIPMENT_FRAGMENT = gql`
  fragment EquipmentFields on Equipment {
    id
    name
    displayName
    size
    description
    prepTime
    cleanTime
    type
    typeDetails {
      name
      slug
    }
    active
    createdAt
    updatedAt
    branch {
      id
      name
    }
  }
`

export const GET_EQUIPMENT = gql`
  query GetEquipment(
    $orderBy: [QueryEquipmentOrderByInput!],
    $where: QueryEquipmentWhereInput
  ) {
    equipment(
      orderBy: $orderBy,
      where: $where
    ) {
      ...EquipmentFields
    }
  }
  ${EQUIPMENT_FRAGMENT}
`
