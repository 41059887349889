import { ResourceTagProps } from 'components/tags/ResourceTag'
import { PartialOrder } from 'types/PartialOrder'
import { isMissingAddress } from './isMissingAddress'

export const withSystemTags = (order: PartialOrder) => {
  const tags = (order?.tags || []).slice() as ResourceTagProps['tag'][]

  if (isMissingAddress(order)) {
    tags.push({
      id: -1,
      path: 'system',
      slug: 'needs_address',
      color: '#eb5a46',
      name: 'Needs Address',
      active: true,
    })
  }

  return tags
}
