import calcuateDistance from '@turf/distance'
import { Coord, Position } from '@turf/helpers'
import { times } from 'lodash'

export const adjustCoordinateForUserErrors = (point: Position, nearby: Coord, opts?: { distance: number }): Position => {
  const options = {
    distance: 800, // 500 miles
    ...opts,
  }

  // handles mixed up and missing S / W coords
  const hemispherePointPermutations = times(4, (index) => {
    const latSign = index % 2 ? 1 : -1
    const lngSign = index / 2 >= 1 ? 1 : -1
    return [point[0] * lngSign, point[1] * latSign]
  })

  // handles swapped lat/lng
  const flippedLatLngPermutations = hemispherePointPermutations.map((arr) => arr.slice().reverse())
  const allPermuations = [...hemispherePointPermutations, ...flippedLatLngPermutations]

  const nearbyPoints = allPermuations.filter((adjustedPoint) => (
    calcuateDistance(adjustedPoint, nearby) <= options.distance
  ))

  // if there is only one close result, use that, else use the provided point
  if (nearbyPoints.length === 1) {
    return nearbyPoints[0]
  }

  return point
}
