import { ClosableElement } from 'hooks/useClosable'
import { Drawer, DrawerProps } from './Drawer'

export type SecondaryDrawerProps = DrawerProps

export const SecondaryDrawer: typeof Drawer = (props) => (
  <ClosableElement close={props?.onClose}>
    <Drawer width="475px" mask {...props} />
  </ClosableElement>
)
