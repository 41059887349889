import { isArray } from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'

import { useSelectedBranch } from 'hooks/useBranch'
import { useRouteParamState } from 'hooks/useRouteParamState'

import { Redirect, Switch, useRouteMatch } from 'react-router'

export const BranchRoutes: React.FC = ({ children }) => {
  const match = useRouteMatch()
  const baseUrl = match.url

  const [urlBranchIdString, setUrlBranchId] = useRouteParamState('branchId')
  const { branchId: selectedBranchId, setBranchId } = useSelectedBranch()

  const urlBranchId = urlBranchIdString ? parseInt(urlBranchIdString) : null

  const lastUrlBranchId = useRef<number | null>(urlBranchId)
  const lastSelectedBranchId = useRef<number | null>(selectedBranchId)

  useEffect(() => {
    if (
      urlBranchId &&
      urlBranchId !== lastUrlBranchId.current &&
      urlBranchId !== selectedBranchId
    ) {
      setBranchId(urlBranchId)
    }

    if (
      selectedBranchId &&
      selectedBranchId !== lastSelectedBranchId.current &&
      selectedBranchId !== urlBranchId
    ) {
      setUrlBranchId(selectedBranchId.toString())
    }

    lastUrlBranchId.current = urlBranchId
    lastSelectedBranchId.current = selectedBranchId
  }, [urlBranchId, selectedBranchId])

  const newChildren = useMemo(() => (
    React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child
      }

      const path = !child.props.path ? baseUrl :
        isArray(child.props.path) ? [baseUrl, child.props.path] :
          `${match.path}/${child.props.path.replace(/^\/+/, '')}`

      return React.cloneElement<any>(child, { path })
    })
  ), [baseUrl, children])

  return (
    <Switch>
      {newChildren}
      <Redirect to="/" />
    </Switch>
  )
}
