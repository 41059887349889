import styled from 'styled-components'

export const OperatorList = styled.div`
  > *:not(:last-child) {
    &::after {
      margin-left: 9px;
      margin-right: 7px;
      content: "|";
      color: rgba(0, 0, 0, 0.5);
    }
  }
`
