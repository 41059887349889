import React, {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react'

import styled from 'styled-components'

import {
  Button, Col,
  Input as AntInput, Row, Typography,
} from 'antd'
import AntTable, { TableProps as AntTableProps } from 'antd/lib/table/Table'

import { gql } from '@apollo/client'

import {
  generatePath, Link, Route, useHistory, useParams,
  useRouteMatch,
} from 'react-router-dom'

import { ColumnType } from 'antd/lib/table'
import { CustomerDrawer } from 'components/drawers/CustomerDrawer'
import { ErrorMessage } from 'components/ErrorMessage'
import { CustomerFormProps } from 'components/form/CustomerForm'
import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'
import { byFieldAsc, byFieldDesc } from 'helpers/sortting'
import { Actions, Body, Header } from 'hooks/useContentLayout'
import { useIsMobile } from 'hooks/useViewportMode'
import { PageContent } from 'layouts/Content/PageContent'
import { remove } from 'lodash'
import { DateTime } from 'luxon'
import { SitesSidebar } from './Sites'
import { GetCustomersIndexQuery, useGetCustomersIndexLazyQuery, useGetCustomersIndexQuery } from './__generated__/Customers'

const { Paragraph, Title } = Typography
const SearchInput = AntInput.Search

const Table: React.FC<AntTableProps<any>> = styled(AntTable)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

gql`
  query GetCustomersIndex {
    customers {
      id
      name
      abbreviation
      active
      createdAt
    }
  }
`

type Customer = NonNullable<GetCustomersIndexQuery>['customers'][0]

const Index = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const isMobile = useIsMobile()

  const { loading, data, error } = useGetCustomersIndexQuery()
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([])
  const [searchValue, setSearchSearchValue] = useState<string>('')

  useEffect(() => {
    const search = searchValue.toLowerCase().replace(/\W+/g, ' ')

    setFilteredCustomers(
      (data?.customers || []).filter((customer) => (
        customer.name.toLowerCase().replace(/\W+/g, ' ').includes(search) ||
        customer.abbreviation.toLowerCase().replace(/\W+/g, ' ').includes(search)
      )).sort((a, b) => (
        byFieldDesc('active')(a, b) || byFieldAsc('name')(a, b)
      ))
    )
  }, [searchValue, data?.customers])

  const columns: ColumnType<any>[] = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'abbreviation',
      sorter: {
        compare: (a: Customer, b: Customer) => (a.abbreviation.toLowerCase() > b.abbreviation.toLowerCase() ? 1 : -1),
      },
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: {
        compare: (a: Customer, b: Customer) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
      },
    },
    {
      key: 'createdAt',
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (value: Customer['createdAt']) => value && DateTime.fromISO(value).toFormat('M/dd/yyyy'),
      sorter: {
        compare: (a: Customer, b: Customer) => (a.createdAt > b.createdAt ? 1 : -1),
      },
    },
    {
      key: 'active',
      title: 'Active',
      dataIndex: 'active',
      render: (value: Customer['active'], row: Customer) => (value ? 'active' : 'inactive'),
      sorter: {
        compare: (a: Customer, b: Customer) => (a.active ? -1 : 1),
      },
    },
  ]

  if (isMobile) {
    remove(columns, (column) => (
      !(['name'] as any[]).includes(column?.key)
    ))
  }

  const onRow = (record: Customer, rowIndex: number | undefined) => {
    const rowLink = `${match.url}/${record.id}`
    return {
      onClick: () => {
        history.push(rowLink)
      },
    }
  }

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => setSearchSearchValue(event.target.value)

  return (
    <PageContent>
      <Header>
        <Title level={2}>
          Customers
        </Title>
      </Header>

      <Actions>
        <Link to={`${match.url}/new`}>
          <Button type="primary">
            Add New Customer
          </Button>
        </Link>
      </Actions>

      <Body>
        <SearchInput
          placeholder="Search for Customer"
          onChange={onSearch}
          allowClear
          style={{
            width: 400,
            maxWidth: '100%',
            marginBottom: 20,
          }}
        />

        {error && (
          <ErrorMessage>
            <Paragraph>{error.message}</Paragraph>
          </ErrorMessage>
        )}
        <Table
          columns={columns}
          rowKey="id"
          dataSource={filteredCustomers}
          pagination={false}
          onRow={onRow}
          locale={{
            emptyText: loading ? 'Loading' : undefined,
          }}
          bordered
        />

      </Body>
    </PageContent>
  )
}

// necessary for remount else Form keeps first vals
const Show = () => {
  const [refetchCustomers] = useGetCustomersIndexLazyQuery()

  const history = useHistory()
  const match = useRouteMatch()
  const { id: urlId, edit: urlEdit } = useParams<{ id: string, edit?: string }>()

  const isNew = urlId === 'new'
  const id = isNew ? undefined : parseInt(urlId)
  const currentPath = history.location.pathname
  const basePath = currentPath.slice(0, match.path.indexOf('/:id'))

  const props: CustomerFormProps = {
    id,
    editing: isNew || urlEdit === 'edit',
    onSubmission: (customer, action) => {
      if (action === 'create' && customer?.id) {
        refetchCustomers()

        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: `${basePath}/${customer?.id}`,
        })
      }
    },
    onCancel: () => {
      if (isNew) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: basePath,
        })
      }
    },
  }

  return <CustomerDrawer {...props} />
}

export const CustomersPage = (_args: any) => {
  const history = useHistory()
  const match = useRouteMatch()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  return (
    <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
      <Col flex="auto" style={{ minWidth: 0 }}>
        <Route path={match.path} component={Index} />
      </Col>
      <RoutableDrawer showClose={false} onClose={() => { history.replace(basePath) }} width={475}>
        <Route path={`${match.path}/:id/sites`} component={SitesSidebar} />
        <Route path={`${match.path}/:id/:edit?`} component={Show} />
        <Route path={match.path} hideOnMobile>
          <div className="padded">
            Select a Customer to view and edit.
          </div>
        </Route>
      </RoutableDrawer>
    </Row>
  )
}

export default CustomersPage
