import { useParams } from 'react-router-dom'

import { Col, Row } from 'antd'
import { Card } from 'components/card'
import { OrderCommentsForm } from 'components/form/CommentsForm'
import { PermanentDrawerV2 } from 'components/layout/PermanentDrawer'
import { useIsMobile } from 'hooks/useViewportMode'
import { OrderBilling } from './OrderBilling'
import { OrderBillingStateProvider } from './OrderBillingState'
import { OrdersNavigation } from './OrdersNavigation'

export const OrderShow = () => {
  const isMobile = useIsMobile()
  const { id: urlId } = useParams<{ id: string }>()
  const id = parseInt(urlId)
  return (
    <>
      <OrderBillingStateProvider orderId={id}>
        <Row wrap={false} style={{ width: '100%', height: '100%' }}>
          <Col flex="auto" style={{ width: '100%', height: '100%' }}>
            <Card block>
              <Card.Header borderless style={{ padding: isMobile ? '25px 20px 8px 60px' : undefined }}>
                <OrdersNavigation size="small" id={id} />
              </Card.Header>
              <Card.Body style={{ padding: 0, width: '100%', height: '100%' }}>
                <OrderBilling orderId={id} />
              </Card.Body>
            </Card>
          </Col>
          {!isMobile && (
            <Col flex="350px">
              <PermanentDrawerV2
                width="350px"
                placement="right"
                bodyStyle={{ padding: 0 }}
              >
                <OrderCommentsForm id={id} />
              </PermanentDrawerV2>
            </Col>
          )}
        </Row>
      </OrderBillingStateProvider>
    </>
  )
}
