import { EnterOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import colors from '../../../constants/colors'
import { Route } from '../../../schema'
import { useEditMode } from '../EditModeState'

const NonClickable = styled.div`
  pointer-events: none;
`

interface MoveItemToRouteWrapperProps extends React.ComponentPropsWithoutRef<'div'> {
  routeId: Route['id']
}

export const MoveItemToRouteWrapper = (props: MoveItemToRouteWrapperProps) => {
  const { children, routeId, ...rest } = props
  const editMode = useEditMode()

  const onClick: typeof props.onClick = (evt) => {
    editMode.routes.toggleSelected(routeId)
    props.onClick?.(evt)
  }

  return (
    <div
      {...rest}
      onClick={onClick}
    >
      <NonClickable>
        {children}
      </NonClickable>

      <MoveOrderButton>
        Move Order {editMode.orders.selected.join(', ')} Here
      </MoveOrderButton>
    </div>
  )
}

const MoveOrderButton = styled.div.attrs((props) => ({
  ...props,
  children: <>
    <EnterOutlined style={{ transform: 'scaleX(-1)' }} /> {props.children}
    {/* <AddItemIcon /> {props.children} */}
  </> as React.HTMLAttributes<HTMLDivElement>['children'],
}))`

  color: ${colors.editing.backgroundPrimary};

  text-transform: uppercase;
  padding: 0 10px 3px 15px;
  font-size: 1.2em;
  font-weight: 800;

  .anticon {
    font-size: 1.7em;
    margin-right: 5px;
  }
`
