import { DateTime } from 'luxon'
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import {
  Equipment, Event, Note, Order, Route,
} from 'schema'
import { UninitializedAccessHandler } from '../../errors/UninitializedAccessHandler'
import { RecursivePartial, SetRequired } from '../../types'

type PartialWithId<T extends { id: any }> = SetRequired<RecursivePartial<T>, 'id'>

export type SchedulerEvent = {
  id: string | number
  resourceId?: string | number
  start: DateTime
  end: DateTime
  event?: PartialWithId<Event> | null
  route?: PartialWithId<Route> | null
  note?: PartialWithId<Note> | null
  order?: PartialWithId<Order> | null
  equipment?: PartialWithId<Equipment> | null
}

export type SchedulerEvents = SchedulerEvent[]

export type SchedulerEventsState = [
  SchedulerEvents,
  React.Dispatch<React.SetStateAction<SchedulerEvents>>,
  {
    addEvent: (event: SchedulerEvent) => void
    removeEvent: (id: SchedulerEvent['id']) => void
  }
]

export const SchedulerEventsContext = React.createContext<SchedulerEventsState>(
  UninitializedAccessHandler('SchedulerEventsStateProvider was not initialized')
)

export const useSchedulerEvents = () => useContext(SchedulerEventsContext)

export const SchedulerEventsStateProvider: React.FC = ({ children }) => {
  const [events, setEvents] = useState<SchedulerEventsState[0]>([])
  const history = useHistory()

  const helpers: SchedulerEventsState[2] = useMemo(() => ({
    addEvent: (newEvent) => {
      setEvents((prev) => {
        const withoutNew = prev.filter((event) => event.id !== newEvent.id)
        return [...withoutNew, newEvent]
      })
    },
    removeEvent: (id) => {
      setEvents((prev) => {
        const withoutId = prev.filter((event) => event.id !== id)
        if (prev.length === withoutId.length) {
          return prev
        }
        return withoutId
      })
    },
  }), [setEvents])

  // hacky but workks for clearing "new" on cancel
  useEffect(() => {
    if (!history.location.pathname.endsWith('/new')) {
      helpers.removeEvent('new')
    }
  }, [history.location, helpers])

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
    <SchedulerEventsContext.Provider value={[events, setEvents, helpers]}>
      {children}
    </SchedulerEventsContext.Provider>
  )
}
