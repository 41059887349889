import { gql, useQuery } from '@apollo/client'
import { EquipmentWhereUniqueInput, Query, QueryEquipmentArgs } from 'schema'
import { useGeotabSettings } from './useGeotabSettings'

export const useGeotabEquipmentId = (id: EquipmentWhereUniqueInput['id']): string | null => {
  const { data: settings } = useGeotabSettings()
  const skip = !id || !settings?.enabled

  const { data } = useQuery<Query, QueryEquipmentArgs>(GET_EQUIPMENT_WITH_GEOTAB_ID, {
    skip,
    variables: {
      where: {
        id: {
          equals: id,
        },
      },
    },
    nextFetchPolicy: 'cache-only',
  })

  return data?.equipment?.[0]?.integrations?.geotab?.vehicle?.id || null
}

const GET_EQUIPMENT_WITH_GEOTAB_ID = gql`
  query GetEquipment($where: QueryEquipmentWhereInput) {
    equipment(where: $where) {
      id
      integrations {
        geotab {
          vehicle {
            id
          }
        }
      }
    }
  }
`
