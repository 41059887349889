import { useCreation } from 'ahooks'
import { byAsc, byFieldAsc } from 'helpers/sortting'
import { useAvailableEquipment } from 'hooks/data/useAvailableEquipment'
import { useEquipmentSort } from 'hooks/useEquipmentSort'
import {
  compact, isNil, isNumber, map, uniq,
} from 'lodash'
import { createContext, useContext } from 'react'
import { useSchedulerEvents } from './SchedulerEventsState'
import { useSchedulerState } from './SchedulerState'

const DEFAULT_RESOURCE_ID = '__unassigned__'
type DefaultResourceId = typeof DEFAULT_RESOURCE_ID
const DEFAULT_RESOURCE = {
  id: DEFAULT_RESOURCE_ID as DefaultResourceId,
  name: 'Notes / Other',
}

type Equipment = ReturnType<typeof useAvailableEquipment>['equipment'][0]
export type SchedulerResource = {
  id: string | number
  name: string
  group?: SchedulerGroup
  equipment?: Equipment
}
export type SchedulerGroup = Equipment['group'] & { key: string }

export type SchedulerResourcesState = {
  defaultResourceId: DefaultResourceId
  resources: SchedulerResource[],
  groups: SchedulerGroup[],
  loading: boolean
}

export const SchedulerResourcesContext = createContext<SchedulerResourcesState>({
  defaultResourceId: DEFAULT_RESOURCE_ID,
  resources: [DEFAULT_RESOURCE],
  groups: [],
  loading: true,
})

export const useSchedulerResources = () => useContext(SchedulerResourcesContext)

export const SchedulerResourcesStateProvider: React.FC = ({ children }) => {
  const { selectedDate, branch, loading: stateLoading } = useSchedulerState()
  const { equipmentSortter } = useEquipmentSort()
  const [events] = useSchedulerEvents()

  const {
    equipment: availableEquipmentArray,
    loading: equipmentLoading,
  } = useAvailableEquipment({
    branchId: branch?.id,
    date: [selectedDate.startOf('day'), selectedDate.endOf('day')],
  })

  const loading = stateLoading || equipmentLoading

  const equipmentArray = useCreation(() => {
    const active = (availableEquipmentArray || []).sort(equipmentSortter)
    const ids = map(active, 'id')
    const extra: typeof active = []

    events.forEach((event) => {
      const eventEquipment = event?.equipment as Equipment
      if (!eventEquipment?.id) return
      if (ids.includes(eventEquipment.id)) return

      ids.push(eventEquipment.id)
      extra.push({
        ...eventEquipment,
        availability: [],
      })
    })

    return [
      ...active,
      ...extra.sort(equipmentSortter),
    ]
  }, [availableEquipmentArray, events, equipmentSortter])

  const [resources, groups] = useCreation(() => {
    const groupsMap: Map<string, SchedulerGroup> = new Map()

    // Full calendar sorting only happens on strings of `group` field
    // Therefore this generates a map of .sort => to a sorted string representation
    const groupSortsIndexed = uniq(
      equipmentArray.map((asset) => asset.group?.sort).filter(isNumber)
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ).sort(byAsc).reduce((map, sort, index, all) => {
      const padded = String(index).padStart(Math.max(...all).toString().length % 10, '0')
      map.set(sort, padded)
      return map
    }, new Map<number, string>())

    const equipmentResources = equipmentArray.map((equipment) => {
      const resource: SchedulerResource = {
        id: equipment.id,
        name: equipment.name,
        equipment,
      }

      const { group } = equipment
      if (!group) return resource

      const location = equipment.availability[0]?.address

      let sort = groupSortsIndexed.get(group.sort)?.toString()
      if (isNil(sort)) {
        sort = 'x'
      }

      let locationSort = (group.locationSort || []).indexOf(location?.id).toString()
      if (locationSort === '-1') {
        locationSort = 'x'
      }

      const newGroup = {
        ...group,
        key: `${sort}-${locationSort}-${group.name}-${group.id}`,
      }

      if (group.locationAware && location.name) {
        newGroup.name = compact([group.name, location.name]).join(' at ')
        newGroup.key = `${newGroup.key}-${location.name}`
      }

      groupsMap.set(newGroup.key, newGroup)

      resource.group = newGroup
      return resource
    })

    const groupsSorted = Array.from(groupsMap.values()).sort(byFieldAsc('key'))
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const resources = [DEFAULT_RESOURCE, ...equipmentResources]

    return [resources, groupsSorted]
  }, [equipmentArray])

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
    <SchedulerResourcesContext.Provider value={{
      resources, groups, loading, defaultResourceId: '__unassigned__',
    }}
    >
      {children}
    </SchedulerResourcesContext.Provider>
  )
}
