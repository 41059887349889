import React from 'react'

import { formatPhone } from 'helpers/formatPhone'
import { byFieldAsc } from 'helpers/sortting'
import styled from 'styled-components'

import {
  Link, Route, useHistory,
  useParams, useRouteMatch,
} from 'react-router-dom'

import {
  Button, Col, Row, Typography,
} from 'antd'
import AntTable, { TableProps as AntTableProps } from 'antd/lib/table/Table'
import { ErrorMessage } from 'components/ErrorMessage'
import { BranchFormProps } from 'components/form/BranchForm'

import { QueryLazyOptions, useQuery } from '@apollo/client'
import { BranchDrawer } from 'components/drawers/BranchDrawer'
import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'
import { GET_BRANCHES_FULL } from 'gql/branches'
import { DocumentNode } from 'graphql'
import { Actions, Body, Header } from 'hooks/useContentLayout'
import { PageContent } from 'layouts/Content/PageContent'
import {
  Branch,
  Query,
  QueryBranchesArgs,
} from '../schema'

const { Paragraph, Title } = Typography

const PhoneCol = styled.div`
  white-space: nowrap;
`

const Table: React.FC<AntTableProps<any>> = styled(AntTable)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

const useIndexQuery = (): [DocumentNode, QueryLazyOptions<QueryBranchesArgs>] => [GET_BRANCHES_FULL, { variables: {} }]

const Index = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const indexQuery = useIndexQuery()

  const { loading, data, error } = useQuery<Query, QueryBranchesArgs>(...indexQuery)

  const branches = data?.branches || []

  const columns: NonNullable<React.ComponentProps<typeof Table>['columns']> = [
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: byFieldAsc('name'),
      },
    },
    {
      title: 'Mainline Phone',
      dataIndex: 'mainlinePhone',
      render: (number) => <PhoneCol>{formatPhone(number)}</PhoneCol>,
    },
    {
      title: 'Alert Phone',
      dataIndex: 'alertPhone',
      render: (number) => <PhoneCol>{formatPhone(number)}</PhoneCol>,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (value: Branch['active'], row: Branch) => (value ? 'active' : 'inactive'),
      sorter: {
        compare: (a: Branch, b: Branch) => (a.active ? -1 : 1),
      },
    },
  ]

  const onRow = (record: Branch, _rowIndex: number | undefined) => {
    const rowLink = `${match.url}/${record.id}`
    return {
      onClick: () => {
        history.push(rowLink)
      },
    }
  }

  return (
    <PageContent>
      <Header>
        <Title level={2}>
          Branches
        </Title>
      </Header>

      <Actions>
        <Link to={`${match.url}/new`}>
          <Button type="primary">
            Add New Branch
          </Button>
        </Link>
      </Actions>

      <Body>
        {error && (
          <ErrorMessage>
            <Paragraph>{error.message}</Paragraph>
          </ErrorMessage>
        )}
        <Table
          columns={columns}
          rowKey="id"
          dataSource={branches}
          pagination={false}
          onRow={onRow}
          locale={{
            emptyText: loading ? 'Loading' : undefined,
          }}
          bordered
        />
      </Body>
    </PageContent>
  )
}

const Show = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id: urlId, edit: urlEdit } = useParams<{ id: string, edit?: string }>()

  const isNew = urlId === 'new'
  const id = isNew ? undefined : parseInt(urlId)
  const basePath = history.location.pathname.slice(0, match.path.indexOf('/:id'))

  const props: BranchFormProps = {
    id,
    editing: isNew || urlEdit === 'edit',
    onSubmission: (branch, action) => {
      if (action === 'create' && branch?.id) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: `${basePath}/${branch?.id}`,
        })
      }
    },
    onCancel: () => {
      if (isNew) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: basePath,
        })
      }
    },
  }

  return <BranchDrawer key={urlId} {...props} />
}

export const BranchesPage = (_args: any) => {
  const history = useHistory()
  const match = useRouteMatch()
  const basePath = match.path.startsWith('/settings/') ? '/settings/branches' : match.path

  return (
    <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
      <Col flex="auto" style={{ minWidth: 0 }}>
        <Route path={basePath} component={Index} />
      </Col>
      <RoutableDrawer showClose={false} onClose={() => { history.replace(basePath) }} width={450}>
        <Route path={`${basePath}/:id/:edit?`} component={Show} />
        <Route path={match.path} hideOnMobile>
          <div className="padded">
            Select a Branch to view and edit.
          </div>
        </Route>
      </RoutableDrawer>
    </Row>
  )
}

export default BranchesPage
