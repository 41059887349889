import { assign, pick } from 'lodash'
import { useGeotabSettings } from './useGeotabSettings'
import { useOrganization } from './useOrganization'
import { useQuickbooksSettings } from './useQuickbooksSettings'
import { useSamsaraSettings } from './useSamsaraSettings'

const TENANTS = {
  solidground: 1,
  pks: 14,
  sandbox: 15,
  berry: 26,
  ohana: 28,
  keim: 359,
}

const TENANT_FEATURES: Record<number, Record<string, boolean> | undefined> = {
  [TENANTS.solidground]: {
    'integrations.quickbooks.enabled': true,
    'integrations.samsara.enabled': true,
    'integrations.aircall.enabled': true,
    'inventory.enabled': true,
    'inventory.admin.enabled': true,
    'site.requestPinViaSms.enabled': true,
    'mobile.moveRoute.enabled': true,
  },
  [TENANTS.pks]: {
    'mobile.moveRoute.enabled': true,
  },
  [TENANTS.sandbox]: {
    'site.requestPinViaSms.enabled': true,
    'mobile.moveRoute.enabled': true,
  },
  [TENANTS.berry]: {
    'site.requestPinViaSms.enabled': true,
  },
  [TENANTS.ohana]: {
    'site.requestPinViaSms.enabled': true,
    'inventory.enabled': true,
  },
  [TENANTS.keim]: {
    'mobile.moveRoute.enabled': true,
    'site.requestPinViaSms.enabled': true,
    'inventory.enabled': true,
  },
}

const DEMO_FEATURES: Record<string, boolean> = {
  'mobile.moveRoute.enabled': true,
  'integrations.quickbooks.enabled': true,
  'integrations.samsara.enabled': true,
  'site.requestPinViaSms.enabled': true,
}

type FeatureMetadata = {
  loading: boolean
}

export function useFeatures<
  T extends string,
  R extends { [K in T]: boolean }
>(
  ...names: T[]
): [R, FeatureMetadata] {
  const organization = useOrganization()
  const { data: samsaraSettings } = useSamsaraSettings()
  const { data: geotabSettings } = useGeotabSettings()
  const { data: quickbooksSettings } = useQuickbooksSettings()

  const features = Object.fromEntries(names.map((k) => [k, false])) as R
  if (organization?.isDemo) {
    assign(features, pick(DEMO_FEATURES, names))
  }

  const tenantFeatures = TENANT_FEATURES[organization?.id || -1]
  if (tenantFeatures) {
    assign(features, pick(tenantFeatures, names))
  }

  if (('integrations.samsara.enabled' in features) && samsaraSettings?.enabled) {
    assign(features, { 'integrations.samsara.enabled': true })
  }

  if (('integrations.geotab.enabled' in features) && geotabSettings?.enabled) {
    assign(features, { 'integrations.geotab.enabled': true })
  }

  if (('integrations.quickbooks.enabled' in features) && quickbooksSettings?.enabled) {
    assign(features, { 'integrations.quickbooks.enabled': true })
  }

  return [features, { loading: !organization }]
}

export function useFeature(name: string): [boolean, FeatureMetadata] {
  const [features, metadata] = useFeatures(name)
  return [features[name], metadata]
}
