import { useParams } from 'react-router-dom'
import { InvoiceBalanceProvider } from '../../hooks/useBalance'
import { InvoiceSettingsProvider } from '../../hooks/useInvoiceSettings'
import { useGetInvoicesQuery } from '../../hooks/useInvoices'
import { InvoiceItemsProvider } from '../../hooks/useItems'
import { useSearchParams } from '../../hooks/useSearchParams'
import Loading from '../Loading'
import { OrderInvoiceForm } from './OrderInvoiceForm'
import { useNewInvoice } from './data/useNewInvoice'

export interface InvoicePageProps {
  onCancelBuild: () => void
}

const useSearchParamOrderId = () => {
  const { id: invoiceId } = useParams<{ id: string }>()
  const isNew = invoiceId === 'new'
  const [searchParams] = useSearchParams()

  if (!isNew) return

  const orderId = searchParams.get('orderId')
  if (!orderId) return

  return parseInt(orderId)
}

export const InvoicePage = ({ onCancelBuild }: InvoicePageProps) => {
  const { id: invoiceId } = useParams<{ id: string }>()
  const isNew = invoiceId === 'new'
  const orderId = useSearchParamOrderId()

  const newResponse = useNewInvoice({ orderId })
  const getResponse = useGetInvoicesQuery({
    skip: isNew,
    variables: isNew ? undefined : {
      where: {
        id: {
          equals: parseInt(invoiceId),
        },
      },
    },
  })

  const loading = newResponse.loading || getResponse.loading
  const invoice = isNew ? newResponse.data.invoice : getResponse.data.invoices[0]

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <InvoiceBalanceProvider>
        <InvoiceItemsProvider>
          <InvoiceSettingsProvider>
            <div style={{
              width: '100%', height: '100%', overflow: 'auto',
            }}
            >
              <OrderInvoiceForm invoice={invoice} isNew={isNew} onCancelBuild={onCancelBuild} />
            </div>
          </InvoiceSettingsProvider>
        </InvoiceItemsProvider>
      </InvoiceBalanceProvider>
    </>
  )
}
