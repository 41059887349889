import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react'

import { sha256 } from 'hash.js'
import { isNil, omit, pick } from 'lodash'

import { SelectedBranch, useBranch } from 'hooks/useBranch'
import { useSite } from 'hooks/useSite'

import { useQuery } from '@apollo/client'
import { Form, FormInstance } from 'components/form/Form'
import { GET_EQUIPMENT } from 'gql/equipment'
import {
  Equipment, Order, OrderDetails, OrderDetailsType, Query, QueryEquipmentArgs,
} from 'schema'
import { RecursivePartial } from 'types'
import { PartialOrder } from 'types/PartialOrder'
import { FormValues } from './FormValues'
import { OrderFormProps } from './OrderFormProps'

import { sortOrderData } from '../../../helpers/sortOrderData'
import { orderDetailsToFormValue, orderToFormValue } from './logic/orderToFormValues'
import { setFormValueDefaults } from './logic/setFormValueDefaults'

type SetStateFor<T extends keyof OrderFormState> = React.Dispatch<React.SetStateAction<OrderFormState[T]>>

interface OrderFormState {
  isNew: boolean
  isReschedule: boolean
  order: PartialOrder | null
  details: RecursivePartial<OrderDetails> | null
  branch: SelectedBranch | null
  form: FormInstance<FormValues>
  initialValues: FormValues
  editing: boolean
  setEditing: React.Dispatch<React.SetStateAction<boolean | 'submitting'>>
  selectedCustomer: RecursivePartial<Order['customer']>
  setSelectedCustomer: SetStateFor<'selectedCustomer'>
  selectedSite: RecursivePartial<Order['site']>
  setSelectedSite: SetStateFor<'selectedSite'>
  selectedEquipment: Equipment | undefined,
  setSelectedEquipmentId: React.Dispatch<React.SetStateAction<number | undefined>>
  lastSelectedEquipment: Equipment | undefined,
  timezone: string | null
}

const OrderFormStateContext = React.createContext<OrderFormState>({} as OrderFormState)

export const OrderFormStateProvider = OrderFormStateContext.Provider

export const useNewOrderFormState = (props: OrderFormProps): OrderFormState => {
  const {
    order: orderInput,
    detailsScope,
    editing: defaultEditing,
  } = props

  const order = useMemo(() => sortOrderData(orderInput) || null, [orderInput])
  const isNew = !order?.id
  const isReschedule = isNew && !isNil(props.rescheduleFrom)

  const details = useMemo(() => getCurrentDetails(order, detailsScope), [order, detailsScope])

  const branch = useBranch()

  const [form] = Form.useForm<FormValues>()

  const [editingRaw, setEditing] = useState<boolean | 'submitting'>(defaultEditing || false)
  const editing = Boolean(editingRaw)

  const [selectedCustomer, setSelectedCustomer] = useState<OrderFormState['selectedCustomer']>(() => {
    if (order?.customer) return order.customer
    if (order?.customerId) return { id: order.customerId }
  })
  const [selectedSiteUnfilled, setSelectedSite] = useState<OrderFormState['selectedSite']>(() => {
    if (order?.site) return order.site
    if (order?.siteId) return { id: order.siteId }
  })

  const selectedSite = useSite(selectedCustomer?.id, selectedSiteUnfilled?.id)

  const [selectedEquipment, lastSelectedEquipment, setSelectedEquipmentId] = useEquipment({ branchId: { equals: branch?.id } })

  const timezone = selectedSite?.address?.timezone || branch?.timezone || null

  const initialValues = useMemo(() => {
    const values = {
      order: orderToFormValue(order || undefined),
      details: orderDetailsToFormValue(
        details || undefined,
        order?.site?.address?.timezone ||
        order?.branch?.timezone ||
        timezone
      ),
    }
    if (isNew) {
      values.order.branchId = branch?.id
    }
    return setFormValueDefaults(values, isNew)
  }, [order, details])

  form.resetFields = () => {
    form.setFieldsValue(initialValues)
  }

  const lastInitialValuesHash = useRef<string>(formValuesHash(initialValues))
  useEffect(() => {
    const newHash = formValuesHash(initialValues)
    const valuesChanged = newHash !== lastInitialValuesHash.current
    const isActivelyEditing = editingRaw === true

    if (!isActivelyEditing && valuesChanged) {
      form.setFieldsValue(initialValues)
    } else if (!isActivelyEditing || !valuesChanged) {
      form.setFieldsValue(pick(initialValues, ['order.revision']))
    }

    lastInitialValuesHash.current = newHash
  }, [order?.revision])

  return {
    isNew,
    isReschedule,
    order,
    details,
    branch,
    form,
    initialValues,
    editing,
    setEditing,
    selectedCustomer,
    setSelectedCustomer,
    selectedSite,
    setSelectedSite,
    selectedEquipment,
    setSelectedEquipmentId,
    lastSelectedEquipment,
    timezone,
  }
}

export const useFormState = () => useContext(OrderFormStateContext)

const useEquipment = (
  where: QueryEquipmentArgs['where']
): [
    Equipment | undefined,
    Equipment | undefined,
    React.Dispatch<React.SetStateAction<number | undefined>>
  ] => {
  const lastEquipment = useRef<Equipment | undefined>()

  const [equipmentId, setEquipmentIdRaw] = useState<Equipment['id'] | undefined>()

  const { data } = useQuery<Query, QueryEquipmentArgs>(
    GET_EQUIPMENT,
    {
      variables: { where },
      skip: equipmentId === undefined,
    }
  )

  const equipment = data?.equipment?.find((record) => record.id === equipmentId)

  const setEquipmentId: typeof setEquipmentIdRaw = (...val) => {
    lastEquipment.current = equipment
    return setEquipmentIdRaw(...val)
  }

  return [equipment, lastEquipment.current, setEquipmentId]
}

const formValuesHash = (values: FormValues) => (
  sha256().update(JSON.stringify(omit(values, ['order.revision']))).digest('hex')
)

const getCurrentDetails = (
  order: OrderFormState['order'],
  detailsScope: OrderFormProps['detailsScope']
): RecursivePartial<OrderDetails> => {
  if (detailsScope.type === 'actuals') {
    return order?.actuals?.find((actual) => actual?.subType === detailsScope.subType) || {
      type: OrderDetailsType.Actual,
      subType: detailsScope.subType,
    }
  } if (detailsScope.type === OrderDetailsType.Billable) {
    return order?.billable || { type: OrderDetailsType.Billable }
  } if (detailsScope.type === OrderDetailsType.Planned) {
    return order?.planned || { type: OrderDetailsType.Planned }
  }
  return {}
}
