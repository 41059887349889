import { UploadOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/lib/upload/interface'
import { Button } from 'components/buttons'
import { Upload } from 'components/form/inline-edit/Upload'
import { useCloudinaryUploadParams } from 'hooks/useCloudinaryUploadParams'
import { useOrganization } from 'hooks/useOrganization'
import { compact, differenceBy, pick } from 'lodash'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

const UploadStyled = styled(Upload)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  > * {
    flex: 1 0 100%;
  }

  .ant-upload-list {
    margin-bottom: 20px;

    .ant-upload-list-text-container {
      min-height: 30px;
    }

    .ant-upload-list-item  {
      font-size: 1.1em;
      line-height: 1.1em;
    }
  }
`

export interface Attachment {
  [key: string]: any
  url: string
  size?: number
  name?: string
  type?: string
}

interface DocumentUploadProps {
  value?: Attachment[]
  onChange?: (value: Attachment[]) => void
  editing?: boolean
}

const fileUrl = (file: UploadFile) => file.url || file.response?.secure_url || file.response?.url

const randomID = () => {
  const uuid = uuidv4().replace(/[\W_]+/g, '')
  return btoa(uuid).slice(0, 24)
}

const fileToAttachment = (file: UploadFile<any>) => {
  if (!file.url) return
  return pick(file, ['url', 'name', 'type', 'size']) as Attachment
}

export const DocumentUpload = ({ editing, value, ...args }: DocumentUploadProps) => {
  const tid = useOrganization()?.id
  const onChange = args.onChange || (() => { })
  const cloudinary = useCloudinaryUploadParams('document')

  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    if (value === undefined) return

    setFileList((current) => {
      const existingFiles = compact(current)

      const newAttachments = differenceBy(value, existingFiles, (file) => file.url)

      const newFiles = newAttachments.map((file, index) => ({
        uid: (index + existingFiles.length).toString(),
        status: 'done',
        ...file,
      }))

      return [...current, ...newFiles] as any
    })
  }, [value])

  if (!tid) {
    console.error('Organization not fetched, document upload disabled')
    return null
  }

  return (
    <UploadStyled
      name="file"
      listType="text"
      accept="image/*,.pdf"
      editing={editing}
      multiple
      progress={{
        strokeWidth: 3,
        format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
      }}
      showUploadList={{
        showPreviewIcon: false,
        showRemoveIcon: editing,
        showDownloadIcon: true,
      }}
      action={cloudinary.url}
      data={(file) => {
        const name = file.name || file.fileName || randomID()
        const withoutExt = name.replace(/\.[^.$]+$/, '')
        return cloudinary.data({
          public_id: `${tid}/${randomID()}/${withoutExt}`,
        })
      }}
      fileList={fileList}
      onRemove={(toDelete) => {
        if (!toDelete.url) return true

        const newFiles = compact(fileList.map((file) => {
          if (toDelete.url === file.url) return
          return fileToAttachment(file)
        }))

        onChange(newFiles)
      }}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onChange={({ file, fileList }) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const withExistingFiles = fileList.map((file) => ({
          ...file,
          thumbUrl: file.url,
          url: fileUrl(file),
        }))

        setFileList(withExistingFiles)

        if (file.status === 'done') {
          onChange(compact(withExistingFiles.map(fileToAttachment)))
        }
      }}
    >
      {editing &&
        <Button block type="ghost" icon={<UploadOutlined />}>Upload Attachment</Button>}
    </UploadStyled>
  )
}
