export const isExternalUrl = (url: any): url is string => {
  if (!url) return false
  if (typeof url !== 'string') return false
  try {
    // eslint-disable-next-line no-restricted-globals
    return new URL(url).origin !== location.origin
  } catch {
    return false
  }
}
