import { Collapse as AntCollapse } from 'antd'
import { CollapsePanelProps } from 'antd/lib/collapse'
import styled from 'styled-components'

const panelKey = 'body'

const Collapse = styled(AntCollapse)`
  border-radius: 0;
`

const Panel = styled(AntCollapse.Panel)`
  &.ant-collapse-item  {
    > .ant-collapse-header, > .ant-collapse-content, > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 !important;
      margin: 0 !important;
      border-width: 0 !important;
    }

    > .ant-collapse-header {
      transition-duration: 0s !important;

      > .ant-collapse-header-text {
        width: 100%;
      }
    }
  }
`

export interface EventCollapseProps {
  minified?: boolean
  header: CollapsePanelProps['header']
}

export const EventCollapse: React.FC<EventCollapseProps> = (props) => {
  const { minified, ...rest } = props

  const panelArgs: CollapsePanelProps = {
    ...rest,
    showArrow: false,
    key: panelKey,
    forceRender: true,
  }

  return (
    <Collapse bordered={false} ghost activeKey={minified ? [] : [panelKey]}>
      <Panel {...panelArgs} />
    </Collapse>
  )
}
