import {
  FetchResult, gql, MutationHookOptions, MutationResult, useApolloClient, useMutation,
} from '@apollo/client'
import { EXTERNAL_LINK_UNICODE } from 'components/common/Comment'
import { CREATE_ORDER, UPDATE_ORDER } from 'gql/orders'
import { GraphQLError } from 'graphql'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useNotification } from 'hooks/useNotification'
import { isEmpty, omit } from 'lodash'
import { DateTime } from 'luxon'
import { TO_CATCH_ALL_PATH } from 'redirects/ToCatchAll'

import {
  Mutation, MutationCreateOrderArgs, MutationUpdateOrderArgs, Query, QueryOrderArgs,
} from 'schema'
import FormValues from '../FormValues'

import { formValuesToOrderCreate, formValuesToOrderUpdate } from './formValuesToOrderInputs'

type UpsertOrderFunction = (order: FormValues['order']) => Promise<FetchResult<Mutation>>
type CreateOrderFunction = UpsertOrderFunction
type UpdateOrderFunction = UpsertOrderFunction

export const useCreateOrder = (
  args?: MutationHookOptions<Mutation, MutationCreateOrderArgs> | undefined
): [CreateOrderFunction, MutationResult<Mutation>] => {
  const [runMutation, mutationResponse] = useMutation<Mutation, MutationCreateOrderArgs>(CREATE_ORDER, args)

  const createOrder: CreateOrderFunction = async (order) => {
    const data = formValuesToOrderCreate(order)
    data.route = data.route || order.route || { create: true }

    return runMutation({
      variables: {
        data,
      },
    })
  }

  return [createOrder, mutationResponse]
}

export const useUpdateOrder = (
  orderId: number,
  args?: MutationHookOptions<Mutation, MutationUpdateOrderArgs> | undefined
): [UpdateOrderFunction, MutationResult<Mutation>] => {
  const [runMutation, mutationResponse] = useMutation<Mutation, MutationUpdateOrderArgs>(UPDATE_ORDER, args)

  const updateOrder: UpdateOrderFunction = async (order) => {
    const data = formValuesToOrderUpdate(order)

    return runMutation({
      variables: {
        data,
        where: {
          id: orderId,
        },
      },
    })
  }

  return [updateOrder, mutationResponse]
}

const GET_ORDER_FOR_RESCHEDULE_QUERY = gql`
  query GetOrderForReschedule($id: Int!) {
    order(id: $id) {
      id
      dateOfServiceLocal
      comments {
        id
      }
    }
  }
`

const orderLinkForComment = (id: number) => (
  `[${EXTERNAL_LINK_UNICODE}](${TO_CATCH_ALL_PATH}/orders/${id}  "View Order")`
)

export const useRescheduleOrder = (
  rescheduleFrom: number,
  args?: MutationHookOptions<Mutation, MutationCreateOrderArgs> | undefined
): [UpdateOrderFunction, MutationResult<Mutation>] => {
  const user = useCurrentUser()
  const notification = useNotification()
  const apollo = useApolloClient()

  const [createOrder, mutationResponse] = useCreateOrder(args)
  const [updateRescheduleOfOrder] = useUpdateOrder(rescheduleFrom)

  const rescheduleOrder: CreateOrderFunction = async (order) => {
    const oldOrderRequest = await apollo.query<Query, QueryOrderArgs>({
      query: GET_ORDER_FOR_RESCHEDULE_QUERY,
      variables: {
        id: rescheduleFrom,
      },
    })

    if (!isEmpty(oldOrderRequest.errors)) {
      return omit(oldOrderRequest, ['data'])
    }

    const oldOrder = oldOrderRequest?.data?.order
    if (!oldOrder) {
      return { errors: [new GraphQLError('Could not find order to reschedule from.')] }
    }

    const rescheduleReason = order.cancellationReason
    order.cancellationReason = undefined

    order.additionalIds ||= {}
    order.additionalIds.create ||= []
    order.additionalIds.create.push({
      key: 'rescheduledFrom',
      value: rescheduleFrom,
    })

    const oldDateOfService = oldOrder.dateOfServiceLocal ? DateTime.fromISO(oldOrder.dateOfServiceLocal) : undefined

    order.comments ||= []
    order.comments.push(
      ...(oldOrderRequest.data?.order?.comments || []),
      {
        text: `Rescheduled from ${oldDateOfService?.toFormat('EEE M/d')} ${orderLinkForComment(rescheduleFrom)}`,
        personId: user?.id,
        metadata: [{
          key: 'rescheduleFromComment',
          value: rescheduleFrom,
        }],
      }
    )

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const mutationResponse = await createOrder(order)

    if (!isEmpty(mutationResponse.errors)) {
      return mutationResponse
    }

    const createdOrder = mutationResponse.data?.createOrder

    if (!createdOrder) {
      return { errors: [new GraphQLError('Could not create new order.')] }
    }

    const newDateOfService = createdOrder.dateOfServiceLocal ? DateTime.fromISO(createdOrder.dateOfServiceLocal) : undefined

    const rescheduleUpdate = await updateRescheduleOfOrder({
      revision: -42,
      cancellationReason: rescheduleReason,
      status: 'cancelled',
      additionalIds: {
        create: [{
          key: 'rescheduledTo',
          value: createdOrder.id,
        }],
      },
      comments: [{
        text: `Rescheduled to ${newDateOfService?.toFormat('EEE M/d')} ${orderLinkForComment(createdOrder.id)}`,
        personId: user?.id,
        metadata: [{
          key: 'rescheduleToComment',
          value: createdOrder.id,
        }],
      }],
    })

    if (rescheduleUpdate.errors) {
      notification.error({
        message: `Error cancelling originating Order ${rescheduleFrom}`,
        description: rescheduleUpdate.errors?.map((error) => error.message).join('\n'),
        duration: 5,
      })
    }

    return mutationResponse
  }

  return [rescheduleOrder, mutationResponse]
}

export const useUpsertOrder = (
  orderId?: undefined | null | number,
  args?: MutationHookOptions<Mutation, MutationCreateOrderArgs | MutationUpdateOrderArgs> & { rescheduleFrom?: number } | undefined
) => (args?.rescheduleFrom ?
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useRescheduleOrder(args?.rescheduleFrom, args as any)
  : orderId ?
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUpdateOrder(orderId, args as any)
    :
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCreateOrder(args as any))
