import { gql, useQuery } from '@apollo/client'
import { Query, QueryOrderArgs } from 'schema'

import { OrderCommentsForm } from 'components/form/CommentsForm'
import { CommentsBubbleWithCount, CommentsBubbleWithCountProps } from 'components/icons/CommentsBubbleWithCount'
import { ViewMoreDrawer } from 'components/layout/ViewMoreDrawer'
import React from 'react'

export const GET_ORDER_COMMENTS_COUNT = gql`
  query GetOrderCommentsCount($id: Int!) {
    order(id: $id) {
      id
      commentsCount
    }
  }
`

type OrderCommentsIconProps = CommentsBubbleWithCountProps & { orderId: number }

const OrderCommentsIcon = React.forwardRef<HTMLSpanElement, OrderCommentsIconProps>((props, ref) => {
  const { orderId, ...rest } = props

  const response = useQuery<Query, QueryOrderArgs>(GET_ORDER_COMMENTS_COUNT, {
    fetchPolicy: 'cache-first',
    skip: !orderId,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: orderId!,
    },
  })

  const commentsCount = response.data?.order?.commentsCount || 0

  return <CommentsBubbleWithCount {...(rest as any)} ref={ref} count={commentsCount} />
})

export const ShowOrderCommentsButton = ({ id }: { id: number }) => (
  <span>
    <ViewMoreDrawer
      button={<OrderCommentsIcon orderId={id} />}
      drawerStyle={{ padding: 20 }}
      width="350px"
    >
      <div style={{ marginTop: '-20px' }}>
        <OrderCommentsForm key={id} id={id} />
      </div>
    </ViewMoreDrawer>
  </span>
)
