import { Site } from 'schema'
import { RecursivePartial } from 'types'

import { ExternalNavigate } from 'components/common/ExternalNavigate'
import { RequestLocationViaSmsModalTrigger } from 'components/form/SiteForm/RequestLocationViaSmsModal'
import { isMissingAddress } from 'helpers/order/isMissingAddress'
import { useRequestSiteLocation } from 'hooks/useRequestSiteLocation'
import { useSite } from 'hooks/useSite'
import { DropdownMenu, DropdownMenuProps } from './DropdownMenu'
import { Menu, MenuDivider } from './Menu'

export interface SiteActionsMenuProps extends Omit<DropdownMenuProps, 'dropdownProps' | 'menuProps'> {
  site: RecursivePartial<Site>
}

export const SiteActionsMenu = ({ site: sitePartial, ...rest }: SiteActionsMenuProps): JSX.Element | null => {
  const site = useSite(sitePartial?.customerId, sitePartial?.id) || sitePartial
  const [requestSiteLocation] = useRequestSiteLocation({ showConfirmation: true })

  if (!site?.customerId) return null
  if (!site?.id) return null

  const addressMissing = isMissingAddress({ site })

  return (
    <DropdownMenu {...rest}>
      <MenuDivider>Actions</MenuDivider>
      <Menu.Item key="actions.requestSiteLocation">
        <RequestLocationViaSmsModalTrigger
          submitContent="Send"
          customerId={site?.customerId}
          onSubmission={(data) => {
            requestSiteLocation({
              variables: {
                data,
                where: {
                  id: site.id,
                },
              },
            })
          }}
        >
          Request Location
        </RequestLocationViaSmsModalTrigger>
      </Menu.Item>
      <MenuDivider>Links</MenuDivider>
      <Menu.Item key="links.navigateOnGoogle" disabled={addressMissing}>
        <ExternalNavigate customerId={site?.customerId} siteId={site?.id} origin={null} />
      </Menu.Item>
    </DropdownMenu>
  )
}
