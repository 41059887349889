import styled from 'styled-components'

export interface TagsListProps extends Omit<React.ComponentProps<'div'>, 'ref'> {
  minified?: boolean | 'vertical' | 'horizontal'
}

export const TagsList = styled.div<TagsListProps>`
  .ant-tag {
    margin-top: 1px;
    margin-bottom: 1px;
    max-width: 100%;
  }

  .ant-tag:last-child {
    margin-bottom: 0px;
    margin-right: 0px;
  }

  ${(props: TagsListProps) => props?.minified && `
    text-overflow: clip;
    font-size: 1px;
    line-height: 1px;

    .ant-tag {
      overflow: hidden;
      color: transparent;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      border-radius: 3px;
      .ant-typography {
        display: none;
      }
    }
  `}

  ${(props: TagsListProps) => (props.minified === true || props.minified === 'horizontal') && `
    .ant-tag {
      width: 18px;
      height: 4px;
    }
  `}

  ${(props: TagsListProps) => props.minified === 'vertical' && `
    .ant-tag {
      height: 18px;
      width: 4px;
      margin-right: 3px;
    }
  `}
`
