import React, { useMemo, useRef } from 'react'

import styled from 'styled-components'

import {
  Query,
  QueryUsersArgs, User,
} from 'schema'

import { useQuery } from '@apollo/client'
import { GET_USERS } from 'gql/users'

import {
  generatePath, Link, Route, useHistory, useParams,
  useRouteMatch,
} from 'react-router-dom'

import {
  Button, Col, Row, Typography,
} from 'antd'

import AntTable, { TableProps as AntTableProps } from 'antd/lib/table/Table'
import { UserDrawer } from 'components/drawers/UserDrawer'
import { ErrorMessage } from 'components/ErrorMessage'
import { UserFormProps } from 'components/form/UserForm'
import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'
import { byFieldAsc, byFieldDesc, byPersonNameAsc } from 'helpers/sortting'
import { activeColumn } from 'helpers/table/activeColumn'
import { Actions, Body, Header } from 'hooks/useContentLayout'
import { PageContent } from 'layouts/Content/PageContent'

const { Paragraph, Title } = Typography

const Table: React.FC<AntTableProps<any>> = styled(AntTable)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

const Index = () => {
  const history = useHistory()
  const match = useRouteMatch()

  const { loading, data, error } = useQuery<Query, QueryUsersArgs>(GET_USERS)

  const users = useMemo(() => (
    (data?.users || []).filter((user) => (
      user.isAdmin
    )).sort((a, b) => (
      byFieldDesc('active')(a, b) || byPersonNameAsc(a, b)
    ))
  ), [data?.users])

  const columns = useRef([
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: {
        compare: byFieldAsc('firstName'),
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: {
        compare: byFieldAsc('lastName'),
      },
    },
    {
      title: 'Email',
      dataIndex: 'username',
      sorter: {
        compare: byFieldAsc('username'),
      },
    },
    activeColumn(),
  ]).current

  const onRow = (record: User, _rowIndex: number | undefined) => {
    const rowLink = `${match.url}/${record.id}`
    return {
      onClick: () => {
        history.push(rowLink)
      },
    }
  }

  return (
    <PageContent>
      <Header>
        <Title level={2}>
          Dashboard Users
        </Title>
      </Header>

      <Actions>
        <Link to={`${match.url}/new`}>
          <Button type="primary">
            Add New User
          </Button>
        </Link>
      </Actions>

      <Body>
        {error && (
          <ErrorMessage>
            <Paragraph>{error.message}</Paragraph>
          </ErrorMessage>
        )}
        <Table
          columns={columns}
          rowKey="id"
          dataSource={users}
          pagination={false}
          onRow={onRow}
          locale={{
            emptyText: loading ? 'Loading' : undefined,
          }}
          bordered
        />
      </Body>
    </PageContent>
  )
}

const Show = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id: urlId, edit: urlEdit } = useParams<{ id: string, edit?: string }>()

  const isNew = urlId === 'new'
  const id = isNew ? undefined : parseInt(urlId)
  const basePath = history.location.pathname.slice(0, match.path.indexOf('/:id'))

  const props: UserFormProps = {
    id,
    editing: isNew || urlEdit === 'edit',
    onSubmission: (user, action) => {
      if (action === 'create' && user?.id) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: `${basePath}/${user?.id}`,
        })
      }
    },
    onCancel: () => {
      if (isNew) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: basePath,
        })
      }
    },
  }

  return <UserDrawer key={`user-${id}`} {...props} />
}

const UserPage = (_args: any) => {
  const history = useHistory()
  const match = useRouteMatch()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  return (
    <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
      <Col flex="auto" style={{ minWidth: 0 }}>
        <Route path={match.path} component={Index} />
      </Col>
      <RoutableDrawer showClose={false} onClose={() => { history.replace(basePath) }} width={450}>
        <Route path={`${match.path}/:id/:edit?`} component={Show} />
        <Route path={match.path} hideOnMobile>
          <div className="padded">
            Select a User to view and edit.
          </div>
        </Route>
      </RoutableDrawer>
    </Row>
  )
}

export default UserPage
