import { useMemo } from 'react'

import {
  generatePath, Link, Route, Switch, useHistory, useParams,
  useRouteMatch,
} from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { Badge, Button, List } from 'antd'
import { SiteDrawer } from 'components/drawers/SiteDrawer'
import { ErrorMessage } from 'components/ErrorMessage'
import { SiteFormProps } from 'components/form/SiteForm'
import Loading from 'components/Loading'
import colors from 'constants/colors'
import { GET_SITES_WITH_CUSTOMER } from 'gql/sites'
import { formatAddress } from 'helpers/formatAddress'
import { byFieldAsc, byFieldDesc } from 'helpers/sortting'
import { ClosableElement, CloseButton } from 'hooks/useClosable'
import {
  Actions, Body, Footer, Header,
} from 'hooks/useContentLayout'
import { DrawerContent } from 'layouts/Content/DrawerContent'
import { compact, uniq } from 'lodash'
import { Query } from 'schema'
import styled from 'styled-components'

const SiteListAddress = styled.span`
  white-space: pre-wrap;
`

export const SiteShow = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const params = useParams<{ id: string, customerId: string, edit?: string }>()

  const isNew = params.id === 'new'
  const id = isNew ? undefined : parseInt(params.id)
  const customerId = params.customerId && parseInt(params.customerId)

  const basePath = useMemo(() => generatePath(match.path.slice(0, match.path.indexOf('/:id')), match.params), [match.path, match.params])
  const goBack = () => {
    history.replace({
      // eslint-disable-next-line no-restricted-globals
      ...location,
      pathname: basePath,
    })
  }

  if (!customerId) {
    return (
      <ErrorMessage>
        Must pass customer id
      </ErrorMessage>
    )
  }

  const props: SiteFormProps = {
    id,
    customerId,
    editing: isNew || params.edit === 'edit',
    onSubmission: (site, action) => {
      if (action === 'create' && site?.id) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: `${basePath}/${site?.id}`,
        })
      }
    },
    onCancel: goBack,
  }

  return (
    <ClosableElement close={goBack}>
      <SiteDrawer {...props} />
    </ClosableElement>
  )
}

const SitesIndex = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const params = useParams<{ customerId: string }>()
  const customerId = params.customerId ? parseInt(params.customerId) : undefined
  const basePath = useMemo(() => generatePath(match.path.slice(0, match.path.indexOf('/sites')), match.params), [match.path, match.params])

  // eslint-disable-next-line @typescript-eslint/ban-types
  const { loading, data, error } = useQuery<Query, {}>(GET_SITES_WITH_CUSTOMER, {
    variables: {
      customerId,
    },
  })

  const customer = data?.customers?.[0]

  const sites = useMemo(() => (
    (customer?.sites || []).slice().sort((a, b) => {
      const activeSort = byFieldDesc('active')(a, b)
      if (activeSort !== 0) return activeSort
      return byFieldAsc('name')(a, b)
    })
  ), [customer])

  const goBack = () => {
    history.push(basePath)
  }

  if (!customerId) {
    return <ErrorMessage> Must pass customer id </ErrorMessage>
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage> {error} </ErrorMessage>
  }

  if (!customer) {
    return <ErrorMessage> Customer not found </ErrorMessage>
  }

  return (
    <ClosableElement close={goBack}>
      <DrawerContent>
        <Header>
          Sites for {customer?.abbreviation || customer?.name}
        </Header>

        <Actions>
          <CloseButton />
        </Actions>

        <Body>
          <List
            itemLayout="horizontal"
            dataSource={sites}
            renderItem={(site) => (
              <List.Item
                style={{ cursor: 'pointer' }}
                onClick={(evt) => {
                  const hasHref = (evt.target as any)?.hasAttribute?.('href')
                  if (hasHref) return
                  history.push(`${match.url}/${site.id}`)
                }}
                actions={[
                  <Link to={`${match.url}/${site.id}/edit`}>
                    Edit
                  </Link>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Badge color={site.active ? 'blue' : colors.greyscale30} />}
                  title={uniq(compact([site.name, site.name2])).join(' | ')}
                  description={<SiteListAddress>{formatAddress(site?.address, { multiline: true })}</SiteListAddress>}
                />
              </List.Item>
            )}
          />
        </Body>

        <Footer>
          <Link to={`${match.url}/new`}>
            <Button type="primary" block>
              Add New Site
            </Button>
          </Link>
        </Footer>
      </DrawerContent>
    </ClosableElement>
  )
}

export const SitesSidebar = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path.replace('customers/:id', 'customers/:customerId')}/:id/:edit?`} component={SiteShow} />
      <Route path={match.path.replace('customers/:id', 'customers/:customerId')} component={SitesIndex} />
    </Switch>
  )
}
