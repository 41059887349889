import React, { useMemo, useRef } from 'react'

import { byFieldAsc } from 'helpers/sortting'
import styled from 'styled-components'

import {
  InventoryItem,
  Query,
  QueryInventoryArgs,
} from 'schema'

import { useQuery } from '@apollo/client'
import { GET_INVENTORY } from 'gql/inventory'

import {
  generatePath, Link, Route, useHistory, useParams,
  useRouteMatch,
} from 'react-router-dom'

import {
  Button, Col, Row, Typography,
} from 'antd'

import AntTable, { TableProps as AntTableProps } from 'antd/lib/table/Table'
import { CurrentBranchSelectorInline } from 'components/common/CurrentBranchSelector'
import { ErrorMessage } from 'components/ErrorMessage'
import { InventoryItemForm, InventoryItemFormProps } from 'components/form/InventoryItemForm'
import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'
import { CloseButton } from 'hooks/useClosable'
import { Actions, Body, Header } from 'hooks/useContentLayout'
import { DrawerContent } from 'layouts/Content/DrawerContent'

const { Paragraph, Title } = Typography

const Table: React.FC<AntTableProps<any>> = styled(AntTable)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

const Index = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { branchId: urlBranchId } = useParams<{ id: string, branchId?: string, edit?: string }>()
  const branchId = urlBranchId ? parseInt(urlBranchId) : null

  const { loading, data, error } = useQuery<Query, QueryInventoryArgs>(GET_INVENTORY, {
    variables: {
      where: {
        branchId: !branchId ? undefined : { equals: branchId },
      },
    },
  })

  const columns = useRef([
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: {
        compare: byFieldAsc('name'),
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (value: InventoryItem['category']) => <span style={{ textTransform: 'capitalize' }}>{value}</span>,
      sorter: {
        compare: byFieldAsc('category'),
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: {
        compare: byFieldAsc('quantity'),
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (value: InventoryItem['active'], row: InventoryItem) => (value ? 'active' : 'inactive'),
      sorter: byFieldAsc('acitve'),
    },
  ]).current

  const onRow = (record: InventoryItem, _rowIndex: number | undefined) => {
    const rowLink = `${match.url}/${record.id}`
    return {
      onClick: () => {
        history.push(rowLink)
      },
    }
  }

  return (
    <>
      <Row wrap={false}>
        <Col flex="auto">
          <Title level={2}>
            Inventory at <CurrentBranchSelectorInline />
          </Title>
        </Col>
        <Col>
          <Link to={`${match.url}/new`}>
            <Button type="primary">
              Add New Item
            </Button>
          </Link>
        </Col>
      </Row>
      {error && (
        <ErrorMessage>
          <Paragraph>{error.message}</Paragraph>
        </ErrorMessage>
      )}
      <Table
        columns={columns}
        rowKey="id"
        dataSource={data?.inventory || []}
        pagination={false}
        onRow={onRow}
        locale={{
          emptyText: loading ? 'Loading' : undefined,
        }}
        bordered
      />
    </>
  )
}

const Show = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { id: urlId, edit: urlEdit } = useParams<{ id: string, edit?: string }>()

  const isNew = urlId === 'new'
  const id = isNew ? undefined : parseInt(urlId)
  // const basePath = history.location.pathname.slice(0, match.path.indexOf('/:id'))

  const basePath = useMemo(() => {
    const withoutId = match.path.slice(0, match.path.indexOf('/:id'))
    return generatePath(withoutId, match.params)
  }, [match.path, match.params])

  const props: InventoryItemFormProps = {
    id,
    editing: isNew || urlEdit === 'edit',
    onSubmission: (inventoryItem, action) => {
      if (action === 'create' && inventoryItem?.id) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: `${basePath}/${inventoryItem?.id}`,
        })
      }
    },
    onCancel: () => {
      if (isNew) {
        history.replace({
          // eslint-disable-next-line no-restricted-globals
          ...location,
          pathname: basePath,
        })
      }
    },
  }

  return (
    <DrawerContent>
      {!id && (
        <Header>
          New Inventory Item
        </Header>
      )}

      <Actions>
        <CloseButton />
      </Actions>

      <Body>
        <InventoryItemForm key={`inventory-item-${id}`} {...props} />
      </Body>
    </DrawerContent>
  )
}

export const InventoryPage = (_args: any) => {
  const history = useHistory()
  const match = useRouteMatch()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  return (
    <Row style={{ height: '100%', flexWrap: 'nowrap', width: '100%' }}>
      <Col flex="auto" style={{ padding: 30 }}>
        <Route path={match.path} component={Index} />
      </Col>

      <RoutableDrawer showClose={false} onClose={() => { history.replace(basePath) }} width={400}>
        <Route path={`${match.path}/:id/:edit?`} component={Show} />
        <Route path={match.path} hideOnMobile>
          <div className="padded">
            Select an Inventory Item to view and edit.
          </div>
        </Route>
      </RoutableDrawer>
    </Row>
  )
}

export default InventoryPage
