import { gql } from '@apollo/client'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import React from 'react'
import { EquipmentType } from 'schema'

export type EquipmentTypeSelectProps = ReferenceSelectProps<EquipmentType>

const GET_EQUIPMENT_TYPES = gql`
  query GetEquipmentTypes($where: QueryEquipmentTypesWhereInput) {
    equipmentTypes(where: $where) {
      slug
      name
      sort
    }
  }
`

const defaults: Partial<EquipmentTypeSelectProps> = {
  defaultActiveFirstOption: true,
}

export const EquipmentTypeSelect: React.FC<EquipmentTypeSelectProps> = (props) => ReferenceSelect(buildPropsFromGql({
  name: 'Equipment Type',
  queryField: 'equipmentTypes',
  gql: GET_EQUIPMENT_TYPES,
  defaults,
}, props))

export default EquipmentTypeSelect
