import { useOrganization } from 'hooks/useOrganization'
import { useParams } from 'react-router-dom'

import { Typography } from 'antd'
import { OrganizationForm, OrganizationFormProps } from 'components/form/OrganizationForm'
import { Loading } from 'components/Loading'
import { Body, Header } from 'hooks/useContentLayout'
import { PageContent } from 'layouts/Content/PageContent'

const { Title } = Typography

export const OrganizationPage = () => {
  const { edit: urlEdit } = useParams<{ edit?: string }>()

  const organization = useOrganization()
  const id = organization?.id

  if (!id) {
    return <Loading />
  }

  const props: OrganizationFormProps = {
    id,
    editing: urlEdit === 'edit',
  }

  return (
    <PageContent>
      <Header>
        <Title level={2}>
          General Settings
        </Title>
      </Header>

      <Body>
        <OrganizationForm key={id} {...props} />
      </Body>
    </PageContent>
  )
}

export default OrganizationPage
