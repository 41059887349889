import { useCreation } from 'ahooks'
import { FormItem } from 'components/form/FormItem'
import InputNumber from 'components/form/inline-edit/InputNumber'
import { pushAt } from 'helpers/pushAt'
import {
  compact, flow, groupBy, isNil, max, omit, pick, sum, toNumber,
} from 'lodash'
import fpMap from 'lodash/fp/map'
import { useMemo } from 'react'
import { useOrderBillingState } from '../../OrderBillingState'
import { TableCol } from '../TableCol'
import { TableRow } from '../TableRow'
import { OrderCompareTableFragmentFragment } from '../__generated__'

const cellFor = (detailsType: string, key: string) => `${detailsType}|${key}`

const mapToNumbers = fpMap(toNumber)

export const CubicYards = () => {
  const key = 'cubicYards'
  const {
    register, details, isEditing, placeholders,
  } = useOrderBillingState<OrderCompareTableFragmentFragment>()

  const fields = useCreation(() => {
    const detailsWithoutBillable = !details ? {} : omit(details, 'billable')
    const allFields: string[] = []
    const totalFields: string[] = []

    Object.entries(detailsWithoutBillable).forEach(([detailsType, detailsData]) => {
      if (!detailsData) return

      const cell = cellFor(detailsType, key)

      const partFields = compact(
        detailsData.schedule.map((entry) => {
          const metric = entry.metrics.find((item) => item.key === key)
          if (!metric) return
          const name = `${cell}|parts.${entry.scheduleIndex}`
          register({
            name,
            value: metric.value,
          })

          return name
        })
      )
      const value = detailsData.metrics.find((item) => item.key === key)?.value

      const totalField = `${cell}|total`
      register({
        name: totalField,
        dependsOn: partFields,
        value,
        calculate: ({ dependentData }) => (
          flow(Object.values, mapToNumbers, compact, sum)(dependentData)
        ),
      })

      allFields.push(...partFields, totalField)
      totalFields.push(totalField)
    })

    const billableValue = details?.billable?.metrics.find((item) => item.key === key)?.value

    const billableTotalField = `billable|${key}|total`
    register({
      name: billableTotalField,
      dependsOn: totalFields,
      value: billableValue,
      autofill: true,
      suggest: ({ current, dependentData: deps }) => {
        if (!isNil(current)) return current

        const actualFields = totalFields.filter((field) => field.startsWith('actual.'))
        const actualMax = flow(Object.values, mapToNumbers, compact, max)(pick(deps, actualFields))
        if (!isNil(actualMax)) {
          return actualMax
        }

        return deps[`planned|${key}|total`]
      },
      onSave: (update, value) => {
        if (isNil(value)) return update
        return pushAt(update, ['billable', 'metrics', 'upsert'], { key, value })
      },
    })

    allFields.push(billableTotalField)

    return allFields
  }, [details])

  const fieldsBySection = useMemo(() => (
    groupBy(fields, (field) => field.split('|')[0])
  ), [fields])

  return (
    <TableRow>
      <TableCol section="title">Cubic yards</TableCol>
      {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        Object.entries(fieldsBySection).map(([section, fields]) => {
          const totalField = `${section}|${key}|total`
          const otherFields = fields.filter((field) => field !== totalField)

          return (
            <TableCol section={section} key={section}>
              <FormItem name={totalField}>
                <InputNumber
                  editing={isEditing(section as any)}
                  placeholder={placeholders[totalField]}
                />
              </FormItem>

              {
                otherFields.map((otherField) => (
                  <FormItem key={otherField} name={otherField} hidden>
                    <InputNumber
                      editing={false}
                      placeholder={placeholders[otherField]}
                    />
                  </FormItem>
                ))
              }
            </TableCol>
          )
        })
      }
    </TableRow>
  )
}
