import { gql, useQuery } from '@apollo/client'
import { Col, Row, Typography } from 'antd'
import { Button } from 'components/buttons'
import { Card } from 'components/card'
import { SupportLink } from 'components/common/SupportLink'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loading } from 'components/Loading'
import { timeWithZone } from 'helpers/datetime'
import { Body, Header } from 'hooks/useContentLayout'
import { useNotification } from 'hooks/useNotification'
import { useOrganization } from 'hooks/useOrganization'
import { useTimezone } from 'hooks/useTimezone'
import { PageContent } from 'layouts/Content/PageContent'
import { Duration } from 'luxon'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Query, Subscription } from 'schema'
import styled from 'styled-components'
import qs from 'utils/qs'

const { Title } = Typography

const GET_SUBSCRIPTION = gql`
  query GetSubscription {
    organization {
      id
      subscription {
        id
        name
        status
        currentPeriodStart
        currentPeriodEnd
        trialEnd
        link
      }
    }
  }
`

const SubscriptionDescription = ({ subscription }: { subscription: Subscription }) => {
  const timezone = useTimezone()
  const renewalDate = timeWithZone(subscription.currentPeriodEnd, timezone)?.plus({ seconds: 1 })

  if (subscription.status === 'active') {
    return (
      <p>
        You are currently subscribed to <strong>{subscription.name}</strong>, which will renew on {renewalDate?.toLocaleString()}.
      </p>
    )
  }

  if (subscription.status === 'trialing') {
    const trialEnd = timeWithZone(subscription.trialEnd, timezone)
    const daysLeft = trialEnd?.diffNow().as('days')

    if (daysLeft && daysLeft > 0) {
      const daysRounded = Math.floor(daysLeft)

      return (
        <p>
          You have <strong>{daysRounded} day{daysRounded !== 1 ? 's' : ''}</strong> left on your CreteSuite trial.
        </p>
      )
    }

    return (
      <p>
        Your CreteSuite trial has expired. Please click the button below to finalize your subscription. If you need additional help, please <SupportLink />.
      </p>
    )
  }

  return null
}

export const SubscriptionPage = () => {
  const history = useHistory()
  const organization = useOrganization()
  const notification = useNotification()
  const location = useLocation()

  const queryString = qs.parse(location.search)

  useEffect(() => {
    if (!queryString.message) return
    const { message, ...newQuery } = queryString
    notification.success({ message })
    history.push({ search: qs.stringify(newQuery) })
  }, [queryString.message])

  useEffect(() => {
    if (!queryString.error) return
    const { error, ...newQuery } = queryString
    notification.error({ message: error })
    history.push({ search: qs.stringify(newQuery) })
  }, [queryString.error])

  const { data, error, loading } = useQuery<Query>(GET_SUBSCRIPTION, {
    pollInterval: Duration.fromObject({ minutes: 28 }).as('milliseconds'),
  })

  if (!organization || loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  const subscription = data?.organization?.subscription
  if (!subscription) return null

  const { link } = subscription

  const hasSubscription = Boolean(subscription.currentPeriodStart)

  return (
    <PageContent>
      <Header>
        <Title level={2}>
          Subscription
        </Title>
      </Header>

      <Body>

        <SubscriptionDescription subscription={subscription} />

        {hasSubscription && (
          <>
            <p>
              Click the button below to view invoices, update billing info, and manage payment methods.
            </p>

            <a href={link}>
              <Button type="primary">
                Manage Subscription
              </Button>
            </a>
          </>
        )}

        {!hasSubscription && (
          <>
            <p>
              Choose a plan below to finalize your subscription.
            </p>

            <div style={{ overflow: 'hidden', maxWidth: '600px', marginTop: '20px' }}>
              <Row gutter={20}>
                <PlanTile
                  title="Annual Plan"
                  subtitle="Our discounted annual plan"
                  price={5900}
                  link={`${link}&plan=annual`}
                  description={(
                    <>
                      Receive a discounted monthly rate by:
                      <ul style={{ padding: '5px 0 0 0', listStylePosition: 'inside', fontSize: '0.9em' }}>
                        <li>Agreeing to a 1 year term</li>
                        <li>Paying via ACH direct debit</li>
                      </ul>
                    </>
                  )}
                />
                <PlanTile
                  title="Monthly Plan"
                  subtitle="Our standard month to month plan"
                  price={6900}
                  link={`${link}&plan=monthly`}
                  description={(
                    <>
                      Our standard rate, payable by Credit Card and ACH direct debit.
                    </>
                  )}
                />

                <Col flex="1 0 100%" style={{ textAlign: 'center', marginTop: '20px', fontSize: '0.9em' }}>
                  If you need additional help, please <SupportLink />.
                </Col>
              </Row>
            </div>

          </>
        )}
      </Body>
    </PageContent>
  )
}

type PlanTileProps = {
  title: string
  subtitle: string
  link: string
  price: number,
  description: React.ReactNode
}

const centsFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const Price = styled(({ price, className }: { price: number, className?: string }) => {
  const prettyPrice = (price % 100 === 0 ? dollarFormatter : centsFormatter).format(price / 100.0)

  return (
    <div className={className}>
      <span className="price">{prettyPrice}</span>
      <span>
        per equipment <br />
        per month
      </span>
    </div>
  )
})`
  display: flex;
  padding-top: 10px;
  flex-wrap: nowrap;
  align-items: center;
  line-height: 1.1em;
  font-size: 0.9em;

  > .price {
    font-size: 2.2em;
    padding-right: 10px;
  }
`

const PlanTile = ({
  title, subtitle, price, link, description,
}: PlanTileProps) => (
  <Col flex="1 1 50%" style={{ marginBottom: '10px' }}>
    <Card block bordered>
      <Card.Header>
        <h3 style={{ marginBottom: 0 }}>{title}</h3>
        <span style={{ fontSize: '0.8em' }}>{subtitle}</span>
      </Card.Header>

      <Card.Body>
        <div style={{ minHeight: '70px' }}>
          {description}
        </div>

        <Price price={price} />

        <a href={link} style={{ marginTop: '20px', display: 'block' }}>
          <Button type="primary" block>
            Select {title}
          </Button>
        </a>
      </Card.Body>
    </Card>
  </Col>
)

export default SubscriptionPage
