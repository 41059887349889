import { FilterOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Card } from 'components/card'
import { OrderFiltersProps } from 'components/order/OrdersFilters'
import { useIsMobile } from 'hooks/useViewportMode'
import { useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { InvoicesFilters } from '../../components/invoicing/InvoicesFilters'
import { RoutableDrawer } from '../../components/scheduler/RoutableDrawer'

const CustomRoutableDrawer = styled(RoutableDrawer)`
  z-index: 5;

  &.mobile-hidden {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: none;
    transform: none;
  }
`

export const InvoicesFiltersDrawer = (props: OrderFiltersProps) => {
  const isMobile = useIsMobile()
  const [showFilters, setShowFilters] = useState(!isMobile)
  const match = useRouteMatch()

  return (
    <>
      <CustomRoutableDrawer
        className={(isMobile && showFilters === false) ? 'mobile-hidden' : undefined}
        showClose={isMobile}
        onClose={() => { setShowFilters(false) }}
        width={300}
      >
        <Route path={match.path}>
          <Card block>
            <Card.Body>
              <InvoicesFilters {...props} />
            </Card.Body>
            {
              isMobile && (
                <Card.Footer>
                  <Button type="primary" style={{ width: '100%' }} onClick={() => setShowFilters(false)}>Apply Filters</Button>
                </Card.Footer>
              )
            }
          </Card>
        </Route>
      </CustomRoutableDrawer>

      {isMobile && !showFilters && <ShowFiltersButton onClick={() => setShowFilters(!showFilters)} />}
    </>
  )
}

const ShowFiltersButton = styled(Button).attrs((props) => ({
  ...props,
  icon: <FilterOutlined />,
}))`
  position: fixed;
  top: 18px;
  width: 42px;
  height: 42px;
  right: 0;
  z-index: 4;
`
