export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string ignorant of time and zone, such as 2007-12-03, compliant with the `date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  Json: any;
  /** Float/String for OrderMetric.value */
  MetricValue: number | string;
};

export type AcknowledgeAssignmentInput = {
  id: Scalars['Int'];
};

export type AcknowledgeMessageInput = {
  id: Scalars['Int'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  estimateTravelFrom?: Maybe<Array<TravelEstimate>>;
  estimateTravelTo?: Maybe<Array<TravelEstimate>>;
  id: Scalars['Int'];
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zip?: Maybe<Scalars['String']>;
};


export type AddressEstimateTravelFromArgs = {
  addressIds: Array<Scalars['Int']>;
};


export type AddressEstimateTravelToArgs = {
  addressIds: Array<Scalars['Int']>;
};

export type AddressInput = {
  city?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type Assignment = {
  __typename?: 'Assignment';
  acknowledged?: Maybe<Scalars['Boolean']>;
  acknowledgedAt?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  operator: Operator;
  operatorId: Scalars['Int'];
  role: Scalars['String'];
  roleDetails: AssignmentRole;
  route?: Maybe<Route>;
  updatedAt: Scalars['DateTime'];
};

export type AssignmentInput = {
  operatorId: Scalars['Int'];
  role?: Maybe<Scalars['String']>;
};

export type AssignmentRole = {
  __typename?: 'AssignmentRole';
  active: Scalars['Boolean'];
  billable: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type AssignmentsInput = {
  set?: Maybe<Array<AssignmentInput>>;
};

export type Authorization = {
  __typename?: 'Authorization';
  /** jwt token granting access to api */
  token?: Maybe<Scalars['String']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type Branch = {
  __typename?: 'Branch';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  /** @deprecated DEPRECATED: use yards instead */
  address: Address;
  /** @deprecated DEPRECATED: use yards instead */
  addressId: Scalars['Int'];
  alertPhone?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  equipment: Array<Equipment>;
  id: Scalars['Int'];
  inventory: Array<InventoryItem>;
  mainlinePhone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operators: Array<Operator>;
  organization: Organization;
  phones: Array<Phone>;
  suppliers: Array<Supplier>;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  yards: Array<Yard>;
};

export type BranchInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  address?: Maybe<AddressInput>;
  alertPhone?: Maybe<Scalars['String']>;
  mainlinePhone?: Maybe<Scalars['String']>;
  metadata?: Maybe<KeyValuesInput>;
  name?: Maybe<Scalars['String']>;
  yards?: Maybe<YardsInput>;
};

export type BranchUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  address?: Maybe<AddressInput>;
  alertPhone?: Maybe<Scalars['String']>;
  mainlinePhone?: Maybe<Scalars['String']>;
  metadata?: Maybe<KeyValuesInput>;
  name?: Maybe<Scalars['String']>;
  yards?: Maybe<YardsInput>;
};

export type BranchWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type CheckOrdersFitInRouteInput = {
  orderIds: Array<Scalars['Int']>;
  routeId: Scalars['Int'];
};

export type CheckWaypointsFitInRouteAddressInput = {
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type CheckWaypointsFitInRouteInput = {
  routeId: Scalars['Int'];
  waypoints: Array<CheckWaypointsFitInRouteWaypointInput>;
};

export type CheckWaypointsFitInRouteWaypointInput = {
  address: CheckWaypointsFitInRouteAddressInput;
  arrivalTime: Scalars['DateTime'];
  departureTime: Scalars['DateTime'];
};

export type CollectSignatureInput = {
  image: Scalars['String'];
  metadata?: Maybe<KeyValuesInput>;
  personId?: Maybe<Scalars['Int']>;
};

export type Comment = {
  __typename?: 'Comment';
  active: Scalars['Boolean'];
  additionalIds: Array<KeyValue>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  metadata: Array<KeyValue>;
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CommentInput = {
  additionalIds?: Maybe<KeyValuesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<KeyValuesInput>;
  personId?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentUpdateArgs = {
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateInput = {
  additionalIds?: Maybe<KeyValuesInput>;
  metadata?: Maybe<KeyValuesInput>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type CommentsInput = {
  add?: Maybe<Array<CommentInput>>;
  connect?: Maybe<Array<Scalars['Int']>>;
  create?: Maybe<Array<CommentInput>>;
  update?: Maybe<Array<CommentUpdateArgs>>;
};

export type Contact = PersonInterface & {
  __typename?: 'Contact';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  createdAt: Scalars['DateTime'];
  customers: Array<Customer>;
  emails: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  notes?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Order>>;
  organization: Organization;
  phones: Array<Phone>;
  preferences: ContactPreferences;
  roles: Array<ContactRole>;
  tags: Array<Tag>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type ContactOrdersArgs = {
  orderBy?: Maybe<Array<QueryOrdersOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryOrdersWhereInput>;
};

export type ContactInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  customerIds?: Maybe<Array<Scalars['Int']>>;
  emails?: Maybe<Array<Scalars['String']>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
  preferences?: Maybe<ContactPreferencesInput>;
  roleIds?: Maybe<Array<Scalars['Int']>>;
  title?: Maybe<Scalars['String']>;
};

export type ContactMutations = {
  __typename?: 'ContactMutations';
  create?: Maybe<ContactMutationsCreateResponse>;
  update?: Maybe<ContactMutationsUpdateResponse>;
};


export type ContactMutationsCreateArgs = {
  data: ContactInput;
};


export type ContactMutationsUpdateArgs = {
  data: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};

export type ContactMutationsCreateResponse = {
  __typename?: 'ContactMutationsCreateResponse';
  contact: Contact;
  warnings?: Maybe<ContactMutationsResponseWarnings>;
};

export type ContactMutationsResponseWarnings = {
  __typename?: 'ContactMutationsResponseWarnings';
  invalidPhones: Array<Scalars['String']>;
  landlinePhones: Array<Scalars['String']>;
};

export type ContactMutationsUpdateResponse = {
  __typename?: 'ContactMutationsUpdateResponse';
  contact: Contact;
  warnings?: Maybe<ContactMutationsResponseWarnings>;
};

export type ContactPreferences = {
  __typename?: 'ContactPreferences';
  messages: Array<MessagePreference>;
};

export type ContactPreferencesInput = {
  messages?: Maybe<MessagePreferencesInput>;
};

export type ContactRole = {
  __typename?: 'ContactRole';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ContactUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  customerIds?: Maybe<Array<Scalars['Int']>>;
  emails?: Maybe<Array<Scalars['String']>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
  preferences?: Maybe<ContactPreferencesInput>;
  roleIds?: Maybe<Array<Scalars['Int']>>;
  title?: Maybe<Scalars['String']>;
};

export type ContactWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type Credential = {
  __typename?: 'Credential';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  typeDetails: CredentialType;
  updatedAt: Scalars['DateTime'];
};

export type CredentialInput = {
  active?: Maybe<Scalars['Boolean']>;
  expirationDate?: Maybe<Scalars['Date']>;
  images?: Maybe<Array<Scalars['String']>>;
  number?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  typeName?: Maybe<Scalars['String']>;
};

export type CredentialType = {
  __typename?: 'CredentialType';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CredentialUpsertInput = {
  data: CredentialInput;
  where?: Maybe<CredentialWhereUniqueInput>;
};

export type CredentialWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type CredentialsInput = {
  delete?: Maybe<Array<CredentialWhereUniqueInput>>;
  upsert?: Maybe<Array<CredentialUpsertInput>>;
};

export type Customer = {
  __typename?: 'Customer';
  abbreviation: Scalars['String'];
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  billingAddress?: Maybe<Address>;
  billingAddressId?: Maybe<Scalars['Int']>;
  billingSameAsPhysical?: Maybe<Scalars['Boolean']>;
  contacts: Array<Contact>;
  createdAt: Scalars['DateTime'];
  emails: Array<Scalars['String']>;
  id: Scalars['Int'];
  integrations?: Maybe<CustomerIntegrations>;
  name: Scalars['String'];
  name2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phones: Array<Phone>;
  physicalAddress?: Maybe<Address>;
  physicalAddressId?: Maybe<Scalars['Int']>;
  sites: Array<Site>;
  tags: Array<Tag>;
  updatedAt: Scalars['DateTime'];
};


export type CustomerContactsArgs = {
  where?: Maybe<CustomerContactsWhereInput>;
};


export type CustomerSitesArgs = {
  where?: Maybe<CustomerSitesWhereInput>;
};

export type CustomerContactsWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
};

export type CustomerInput = {
  abbreviation: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  billingAddress?: Maybe<AddressInput>;
  billingSameAsPhysical?: Maybe<Scalars['Boolean']>;
  emails?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  name2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
  physicalAddress?: Maybe<AddressInput>;
};

export type CustomerIntegrations = {
  __typename?: 'CustomerIntegrations';
  quickbooks?: Maybe<CustomerQuickbooksIntegration>;
};

export type CustomerQuickbooksIntegration = {
  __typename?: 'CustomerQuickbooksIntegration';
  customer?: Maybe<QuickbooksCustomer>;
};

export type CustomerSitesWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
};

export type CustomerUpdateInput = {
  abbreviation?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  billingAddress?: Maybe<AddressInput>;
  billingSameAsPhysical?: Maybe<Scalars['Boolean']>;
  emails?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
  physicalAddress?: Maybe<AddressInput>;
};

export type CustomerWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};



export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type Document = {
  __typename?: 'Document';
  active: Scalars['Boolean'];
  attachments?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['Date']>;
  fields: Array<KeyValue>;
  id: Scalars['Int'];
  pinned: Scalars['Boolean'];
  type: Scalars['String'];
  typeDetails: DocumentType;
  updatedAt: Scalars['DateTime'];
};

export type DocumentInput = {
  active?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Scalars['Json']>;
  expirationDate?: Maybe<Scalars['Date']>;
  fields?: Maybe<Array<KeyValueInput>>;
  pinned?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
  typeName?: Maybe<Scalars['String']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum DocumentTypeScope {
  Equipment = 'equipment'
}

export type DocumentTypesWhereInput = {
  active?: Maybe<BoolFilter>;
  scope?: Maybe<DocumentTypeScope>;
};

export type DocumentUpsertInput = {
  data: DocumentInput;
  where?: Maybe<DocumentWhereUniqueInput>;
};

export type DocumentWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type DocumentsInput = {
  delete?: Maybe<Array<DocumentWhereUniqueInput>>;
  upsert?: Maybe<Array<DocumentUpsertInput>>;
};

export type Equipment = {
  __typename?: 'Equipment';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  branch: Branch;
  branchId: Scalars['Int'];
  cleanTime?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  defaultOperator?: Maybe<Operator>;
  defaultOperatorId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  documents: Array<Document>;
  group?: Maybe<EquipmentGroup>;
  groupId?: Maybe<Scalars['Int']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  integrations?: Maybe<EquipmentIntegrations>;
  name: Scalars['String'];
  prepTime?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  sort: Scalars['Int'];
  telematics: EquipmentTelematics;
  travelRate?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  typeDetails: EquipmentType;
  updatedAt: Scalars['DateTime'];
  yardageRate?: Maybe<Scalars['Float']>;
};


export type EquipmentDocumentsArgs = {
  where?: Maybe<EquipmentDocumentsWhereInput>;
};

export type EquipmentAvailability = {
  __typename?: 'EquipmentAvailability';
  address: Address;
  branch: Branch;
  createdAt: Scalars['DateTime'];
  equipment: Equipment;
  id: Scalars['Int'];
  status: EquipmentAvailabilityStatus;
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

export type EquipmentAvailabilitySnapshot = {
  __typename?: 'EquipmentAvailabilitySnapshot';
  availability: Array<EquipmentAvailability>;
  equipment: Equipment;
  id: Scalars['String'];
};

export enum EquipmentAvailabilityStatus {
  Available = 'available'
}

export type EquipmentDocumentsWhereInput = {
  active?: Maybe<BoolFilter>;
  expirationDate?: Maybe<StringNullableFilter>;
  pinned?: Maybe<BoolFilter>;
};

export type EquipmentGeotabIntegration = {
  __typename?: 'EquipmentGeotabIntegration';
  vehicle?: Maybe<GeotabVehicle>;
};

export type EquipmentGroup = {
  __typename?: 'EquipmentGroup';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  locationAware: Scalars['Boolean'];
  locationSort?: Maybe<Array<Scalars['Int']>>;
  name: Scalars['String'];
  /** @deprecated use locationAware instead */
  prefixWithYard?: Maybe<Scalars['Boolean']>;
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type EquipmentInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  branchId: Scalars['Int'];
  cleanTime?: Maybe<Scalars['Int']>;
  defaultOperatorId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentsInput>;
  groupId?: Maybe<Scalars['Int']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  prepTime?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  travelRate?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  yardageRate?: Maybe<Scalars['Float']>;
};

export type EquipmentIntegrations = {
  __typename?: 'EquipmentIntegrations';
  geotab?: Maybe<EquipmentGeotabIntegration>;
  quickbooks?: Maybe<EquipmentQuickbooksIntegration>;
  samsara?: Maybe<EquipmentSamsaraIntegration>;
};

export type EquipmentQuickbooksIntegration = {
  __typename?: 'EquipmentQuickbooksIntegration';
  cancellationRate?: Maybe<QuickbooksItem>;
  flatRate?: Maybe<QuickbooksItem>;
  hourlyRate?: Maybe<QuickbooksItem>;
  travelRate?: Maybe<QuickbooksItem>;
  yardageRate?: Maybe<QuickbooksItem>;
};

export type EquipmentSamsaraIntegration = {
  __typename?: 'EquipmentSamsaraIntegration';
  vehicle?: Maybe<SamsaraVehicle>;
};

export type EquipmentTelematics = {
  __typename?: 'EquipmentTelematics';
  gps?: Maybe<TelematicsGps>;
  stats?: Maybe<TelematicsStats>;
};

export type EquipmentType = {
  __typename?: 'EquipmentType';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type EquipmentUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  branchId?: Maybe<Scalars['Int']>;
  cleanTime?: Maybe<Scalars['Int']>;
  defaultOperatorId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentsInput>;
  groupId?: Maybe<Scalars['Int']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  prepTime?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  travelRate?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  yardageRate?: Maybe<Scalars['Float']>;
};

export type EquipmentWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type Event = {
  __typename?: 'Event';
  active: Scalars['Boolean'];
  branch?: Maybe<Branch>;
  branchId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  id: Scalars['Int'];
  note?: Maybe<Note>;
  noteId?: Maybe<Scalars['Int']>;
  route?: Maybe<Route>;
  routeId?: Maybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventInput = {
  branchId?: Maybe<Scalars['Int']>;
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type EventsInput = {
  set?: Maybe<Array<EventInput>>;
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Geotab = {
  __typename?: 'Geotab';
  credentials: GeotabCredentials;
  settings: GeotabSettings;
  vehicles: Array<GeotabVehicle>;
};


export type GeotabCredentialsArgs = {
  forceRefresh?: Maybe<Scalars['Boolean']>;
};

export type GeotabConnectCredentialsInput = {
  database: Scalars['String'];
  password: Scalars['String'];
  server?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type GeotabCredentials = {
  __typename?: 'GeotabCredentials';
  createdAt: Scalars['DateTime'];
  database: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  server: Scalars['String'];
  sessionId: Scalars['String'];
  userName: Scalars['String'];
};

export type GeotabMutationNamespace = {
  __typename?: 'GeotabMutationNamespace';
  connect: GenericResponse;
  linkVehicle: Equipment;
};


export type GeotabMutationNamespaceConnectArgs = {
  credentials: GeotabConnectCredentialsInput;
};


export type GeotabMutationNamespaceLinkVehicleArgs = {
  equipment: EquipmentWhereUniqueInput;
  vehicleId?: Maybe<Scalars['String']>;
};

export type GeotabSettings = {
  __typename?: 'GeotabSettings';
  database: Scalars['String'];
  enabled: Scalars['Boolean'];
  server: Scalars['String'];
};

export type GeotabVehicle = {
  __typename?: 'GeotabVehicle';
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type InsightsDashboard = {
  __typename?: 'InsightsDashboard';
  expires: Scalars['DateTime'];
  slug: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type InsightsDashboardsInput = {
  title?: Maybe<StringFilter>;
};

export type InsightsUrl = {
  __typename?: 'InsightsUrl';
  expires: Scalars['DateTime'];
  url: Scalars['String'];
};

export type InsightsUrlInput = {
  dashboard: Scalars['String'];
};

export type IntArrayInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntegrationToken = {
  __typename?: 'IntegrationToken';
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['Json']>;
  token: Scalars['String'];
};

export type Integrations = {
  __typename?: 'Integrations';
  geotab?: Maybe<Geotab>;
  quickbooks?: Maybe<Quickbooks>;
  samsara?: Maybe<Samsara>;
};

export type IntegrationsMutationNamespace = {
  __typename?: 'IntegrationsMutationNamespace';
  geotab?: Maybe<GeotabMutationNamespace>;
  quickbooks?: Maybe<QuickbooksMutationNamespace>;
  samsara?: Maybe<SamsaraMutationNamespace>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  active: Scalars['Boolean'];
  branch: Branch;
  branchId: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  consumable: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayMultiplier?: Maybe<Scalars['Float']>;
  displayUnit?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  visibility: InventoryItemVisibilities;
};

export type InventoryItemInput = {
  active?: Maybe<Scalars['Boolean']>;
  branchId: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayMultiplier?: Maybe<Scalars['Float']>;
  displayUnit?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
};

export type InventoryItemUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  branchId?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayMultiplier?: Maybe<Scalars['Float']>;
  displayUnit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type InventoryItemVisibilities = {
  __typename?: 'InventoryItemVisibilities';
  customerConfirmation: InventoryItemVisibilitySimple;
  customerWorkTicket: InventoryItemVisibilitySimple;
  operatorAssignment: InventoryItemVisibilitySimple;
  operatorWorkTicket: InventoryItemVisibility;
  orderBilling: InventoryItemVisibility;
  orderForm: InventoryItemVisibilitySimple;
};

export enum InventoryItemVisibility {
  Always = 'always',
  Auto = 'auto',
  Hidden = 'hidden'
}

export enum InventoryItemVisibilitySimple {
  Auto = 'auto',
  Hidden = 'hidden'
}

export type InventoryItemWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type InvoiceEstimate = {
  __typename?: 'InvoiceEstimate';
  id: Scalars['String'];
  total: Scalars['Float'];
};

export type JobCostDimension = {
  __typename?: 'JobCostDimension';
  misc: Scalars['Float'];
  onSite: Scalars['Float'];
  total: Scalars['Float'];
  travelFrom: Scalars['Float'];
  travelTo: Scalars['Float'];
};

export type JobCostEstimate = {
  __typename?: 'JobCostEstimate';
  fuel: JobCostDimension;
  labor: JobCostDimension;
  total: Scalars['Float'];
};


export type KeyValue = {
  __typename?: 'KeyValue';
  createdAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type KeyValueInput = {
  key: Scalars['String'];
  value?: Maybe<Scalars['Json']>;
};

export type KeyValuesInput = {
  create?: Maybe<Array<KeyValueInput>>;
  delete?: Maybe<Array<Scalars['String']>>;
  set?: Maybe<Array<KeyValueInput>>;
  update?: Maybe<Array<KeyValueInput>>;
  upsert?: Maybe<Array<KeyValueInput>>;
};

export type MergeCustomerResponse = {
  __typename?: 'MergeCustomerResponse';
  customer: Customer;
  deleted: Array<Scalars['Int']>;
};

export type Message = {
  __typename?: 'Message';
  ackedAt?: Maybe<Scalars['DateTime']>;
  additionalIds?: Maybe<Array<KeyValue>>;
  assignments: Array<Assignment>;
  body: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  from: Scalars['String'];
  handler: Scalars['String'];
  id: Scalars['Int'];
  orders: Array<Order>;
  recipient?: Maybe<Person>;
  recipientId?: Maybe<Scalars['Int']>;
  routes: Array<Route>;
  sender?: Maybe<Person>;
  senderId?: Maybe<Scalars['Int']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  status: MessageStatus;
  subject: Scalars['String'];
  to: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  typeDetails?: Maybe<MessageType>;
  updatedAt: Scalars['DateTime'];
};

export type MessagePreference = {
  __typename?: 'MessagePreference';
  description?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  optedIn?: Maybe<Scalars['Boolean']>;
  path: Scalars['String'];
};

export type MessagePreferenceInput = {
  optedIn: Scalars['Boolean'];
  path: Scalars['String'];
};

export type MessagePreferencesInput = {
  upsert?: Maybe<Array<MessagePreferenceInput>>;
};

export type MessageScope = {
  __typename?: 'MessageScope';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum MessageStatus {
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  Failed = 'failed',
  Queued = 'queued',
  Scheduled = 'scheduled',
  Sending = 'sending',
  Sent = 'sent',
  Skipped = 'skipped'
}

export type MessageType = {
  __typename?: 'MessageType';
  active: Scalars['Boolean'];
  allowResend: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  defaultOptedIn: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MessageUpdateInput = {
  ackedAt?: Maybe<Scalars['DateTime']>;
  additionalIds?: Maybe<KeyValuesInput>;
  metadata?: Maybe<KeyValuesInput>;
  remoteId?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MessageStatus>;
};

export type MessageWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  remoteId?: Maybe<Scalars['String']>;
};


export type Mutation = {
  __typename?: 'Mutation';
  acknowledgeAssignment?: Maybe<Assignment>;
  acknowledgeMessage?: Maybe<Message>;
  /** Exchange auth.cretesuite.com JWT for an api access JWT */
  authorize?: Maybe<Authorization>;
  collectOrderSignature?: Maybe<Signature>;
  connectTags?: Maybe<UpdateTagsRelationsResponse>;
  contact?: Maybe<ContactMutations>;
  createBranch?: Maybe<Branch>;
  createContact?: Maybe<Contact>;
  createInventoryItem?: Maybe<InventoryItem>;
  createNote?: Maybe<Note>;
  createOneCustomer?: Maybe<Customer>;
  createOneEquipment?: Maybe<Equipment>;
  createOperator?: Maybe<Operator>;
  createOrder?: Maybe<Order>;
  createPourType?: Maybe<PourType>;
  createSite?: Maybe<Site>;
  createSupplier?: Maybe<Supplier>;
  createUser?: Maybe<User>;
  disconnectTags?: Maybe<UpdateTagsRelationsResponse>;
  dispatchRoute?: Maybe<Route>;
  integrations?: Maybe<IntegrationsMutationNamespace>;
  mergeCustomers: MergeCustomerResponse;
  notifyAssignment?: Maybe<Message>;
  requestOrderChange?: Maybe<GenericResponse>;
  requestSiteLocation?: Maybe<Message>;
  sendOrderMessage?: Maybe<Message>;
  updateBranch?: Maybe<Branch>;
  updateContact?: Maybe<Contact>;
  updateInventoryItem?: Maybe<InventoryItem>;
  updateMessage?: Maybe<Message>;
  updateNote?: Maybe<Note>;
  updateOneCustomer?: Maybe<Customer>;
  updateOneEquipment?: Maybe<Equipment>;
  updateOperator?: Maybe<Operator>;
  updateOrder?: Maybe<Order>;
  updateOrganization?: Maybe<Organization>;
  updatePourType?: Maybe<PourType>;
  updateRoute?: Maybe<Route>;
  updateRouteWaypoint?: Maybe<RouteWaypoint>;
  updateSite?: Maybe<Site>;
  updateSupplier?: Maybe<Supplier>;
  updateUser?: Maybe<User>;
};


export type MutationAcknowledgeAssignmentArgs = {
  where: AcknowledgeAssignmentInput;
};


export type MutationAcknowledgeMessageArgs = {
  where: AcknowledgeMessageInput;
};


export type MutationAuthorizeArgs = {
  jwt: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
};


export type MutationCollectOrderSignatureArgs = {
  data: CollectSignatureInput;
  where: OrderWhereUniqueInput;
};


export type MutationConnectTagsArgs = {
  data: UpdateTagsRelationsDataInput;
  where: Array<TagWhereUniqueInput>;
};


export type MutationCreateBranchArgs = {
  data: BranchInput;
};


export type MutationCreateContactArgs = {
  data: ContactInput;
};


export type MutationCreateInventoryItemArgs = {
  data: InventoryItemInput;
};


export type MutationCreateNoteArgs = {
  data: NoteInput;
};


export type MutationCreateOneCustomerArgs = {
  data: CustomerInput;
};


export type MutationCreateOneEquipmentArgs = {
  data: EquipmentInput;
};


export type MutationCreateOperatorArgs = {
  data: OperatorInput;
};


export type MutationCreateOrderArgs = {
  data: OrderCreateInput;
};


export type MutationCreatePourTypeArgs = {
  data: PourTypeInput;
};


export type MutationCreateSiteArgs = {
  data: SiteInput;
};


export type MutationCreateSupplierArgs = {
  data: SupplierInput;
};


export type MutationCreateUserArgs = {
  data: UserInput;
};


export type MutationDisconnectTagsArgs = {
  data: UpdateTagsRelationsDataInput;
  where: Array<TagWhereUniqueInput>;
};


export type MutationDispatchRouteArgs = {
  where: RouteWhereUniqueInput;
};


export type MutationMergeCustomersArgs = {
  customers: Array<CustomerWhereUniqueInput>;
};


export type MutationNotifyAssignmentArgs = {
  data: NotifyAssignmentInput;
};


export type MutationRequestOrderChangeArgs = {
  data: RequestOrderChangeInput;
  where: OrderWhereUniqueInput;
};


export type MutationRequestSiteLocationArgs = {
  data: RequestSiteLocationInput;
  where: SiteWhereUniqueInput;
};


export type MutationSendOrderMessageArgs = {
  data: SendOrderMessageInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateBranchArgs = {
  data: BranchUpdateInput;
  where: BranchWhereUniqueInput;
};


export type MutationUpdateContactArgs = {
  data: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};


export type MutationUpdateInventoryItemArgs = {
  data: InventoryItemUpdateInput;
  where: InventoryItemWhereUniqueInput;
};


export type MutationUpdateMessageArgs = {
  data: MessageUpdateInput;
  where: MessageWhereUniqueInput;
};


export type MutationUpdateNoteArgs = {
  data: NoteUpdateInput;
  where: NoteWhereUniqueInput;
};


export type MutationUpdateOneCustomerArgs = {
  data: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpdateOneEquipmentArgs = {
  data: EquipmentUpdateInput;
  where: EquipmentWhereUniqueInput;
};


export type MutationUpdateOperatorArgs = {
  data: OperatorUpdateInput;
  where: OperatorWhereUniqueInput;
};


export type MutationUpdateOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateOrganizationArgs = {
  data: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdatePourTypeArgs = {
  data: PourTypeUpdateInput;
  where: PourTypeWhereUniqueInput;
};


export type MutationUpdateRouteArgs = {
  data: RouteUpdateInput;
  where: RouteWhereUniqueInput;
};


export type MutationUpdateRouteWaypointArgs = {
  data: RouteWaypointInput;
  where: RouteWaypointWhereUniqueInput;
};


export type MutationUpdateSiteArgs = {
  data: SiteUpdateInput;
  where: SiteWhereUniqueInput;
};


export type MutationUpdateSupplierArgs = {
  data: SupplierUpdateInput;
  where: SupplierWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NavigableAddressInput = {
  city?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type Note = {
  __typename?: 'Note';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  comments: Array<Comment>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']>;
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Int']>;
  event?: Maybe<Event>;
  id: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NoteInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  comments?: Maybe<CommentsInput>;
  contactId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  equipmentId?: Maybe<Scalars['Int']>;
  event?: Maybe<EventInput>;
  title: Scalars['String'];
};

export type NoteUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  comments?: Maybe<CommentsInput>;
  contactId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  equipmentId?: Maybe<Scalars['Int']>;
  event?: Maybe<EventInput>;
  title?: Maybe<Scalars['String']>;
};

export type NoteWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type NotifyAssignmentInput = {
  id: Scalars['Int'];
};

export type Operator = PersonInterface & {
  __typename?: 'Operator';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  assignments: Array<Assignment>;
  branches: Array<Branch>;
  createdAt: Scalars['DateTime'];
  credentials: Array<Credential>;
  emails: Array<Scalars['String']>;
  equipment: Array<Equipment>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  integrations?: Maybe<OperatorIntegrations>;
  lastName?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  organization: Organization;
  phones: Array<Phone>;
  preferences: OperatorPreferences;
  updatedAt: Scalars['DateTime'];
};


export type OperatorAssignmentsArgs = {
  orderBy?: Maybe<Array<OperatorAssignmentsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OperatorAssignmentsWhereInput>;
};


export type OperatorCredentialsArgs = {
  orderBy?: Maybe<Array<OperatorCredentialsOrderByInput>>;
  where?: Maybe<OperatorCredentialsWhereInput>;
};

export type OperatorAssignmentsOrderByInput = {
  id?: Maybe<SortOrder>;
};

export type OperatorAssignmentsWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  visible?: Maybe<BoolFilter>;
};

export type OperatorCredentialsOrderByInput = {
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
};

export type OperatorCredentialsWhereInput = {
  active?: Maybe<BoolFilter>;
  expirationDate?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
};

export type OperatorInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  branchIds?: Maybe<IntArrayInput>;
  credentials?: Maybe<CredentialsInput>;
  emails?: Maybe<Array<Scalars['String']>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
};

export type OperatorIntegrations = {
  __typename?: 'OperatorIntegrations';
  samsara?: Maybe<OperatorSamsaraIntegration>;
};

export type OperatorPreferences = {
  __typename?: 'OperatorPreferences';
  messages: Array<MessagePreference>;
};

export type OperatorSamsaraIntegration = {
  __typename?: 'OperatorSamsaraIntegration';
  driver?: Maybe<SamsaraDriver>;
};

export type OperatorUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  branchIds?: Maybe<IntArrayInput>;
  credentials?: Maybe<CredentialsInput>;
  emails?: Maybe<Array<Scalars['String']>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
};

export type OperatorWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type Order = {
  __typename?: 'Order';
  active: Scalars['Boolean'];
  actuals: Array<OrderDetails>;
  additionalIds?: Maybe<Array<KeyValue>>;
  assignments?: Maybe<Array<Assignment>>;
  billable?: Maybe<OrderDetails>;
  branch?: Maybe<Branch>;
  branchId?: Maybe<Scalars['Int']>;
  bulletinToCustomer?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationReasonDetails?: Maybe<OrderCancellationReason>;
  cashOnDelivery: Scalars['Boolean'];
  comments: Array<Comment>;
  commentsCount: Scalars['Int'];
  contacts: Array<OrderContact>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']>;
  dateOfService?: Maybe<Scalars['DateTime']>;
  dateOfServiceLocal?: Maybe<Scalars['DateTime']>;
  estimatedJobCost?: Maybe<JobCostEstimate>;
  flatRate: Scalars['Boolean'];
  id: Scalars['Int'];
  instructions?: Maybe<Scalars['String']>;
  integrations?: Maybe<OrderIntegrations>;
  linesOnSite: Scalars['Boolean'];
  messages: Array<Message>;
  name: Scalars['String'];
  planned?: Maybe<OrderDetails>;
  purchaseOrder?: Maybe<Scalars['String']>;
  revision: Scalars['Int'];
  route?: Maybe<Route>;
  routeWaypoint?: Maybe<RouteWaypoint>;
  routeWaypointId?: Maybe<Scalars['Int']>;
  signature?: Maybe<Signature>;
  signatureId?: Maybe<Scalars['Int']>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  statusDetails: OrderStatus;
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['Int']>;
  tags: Array<Tag>;
  terms: OrderTerms;
  updatedAt: Scalars['DateTime'];
};


export type OrderMessagesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type OrderActualsInput = {
  upsert?: Maybe<Array<OrderActualsUpsertInput>>;
};

export type OrderActualsUpsertInput = {
  data: OrderDetailsUpsertInput;
  where?: Maybe<OrderActualsWhereUniqueInput>;
};

export type OrderActualsWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  subType?: Maybe<Scalars['String']>;
};

export type OrderCancellationReason = {
  __typename?: 'OrderCancellationReason';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type OrderContact = {
  __typename?: 'OrderContact';
  contact: Contact;
  contactId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  role?: Maybe<ContactRole>;
  roleId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type OrderContactInput = {
  contactId: Scalars['Int'];
  default?: Maybe<Scalars['Boolean']>;
  roleId?: Maybe<Scalars['Int']>;
};

export type OrderContactsInput = {
  set?: Maybe<Array<OrderContactInput>>;
};

export type OrderCreateInput = {
  actuals?: Maybe<OrderActualsInput>;
  additionalIds?: Maybe<KeyValuesInput>;
  billable?: Maybe<OrderDetailsUpsertInput>;
  branchId?: Maybe<Scalars['Int']>;
  bulletinToCustomer?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cashOnDelivery?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<CommentsInput>;
  contacts?: Maybe<OrderContactsInput>;
  customerId?: Maybe<Scalars['Int']>;
  flatRate?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
  linesOnSite?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  planned?: Maybe<OrderDetailsUpsertInput>;
  purchaseOrder?: Maybe<Scalars['String']>;
  route?: Maybe<OrderRouteInput>;
  signatureId?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  supplierId?: Maybe<Scalars['Int']>;
};

export type OrderDetails = {
  __typename?: 'OrderDetails';
  createdAt: Scalars['DateTime'];
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  inventory: Array<OrderInventoryItem>;
  metrics: Array<OrderMetric>;
  schedule: Array<OrderScheduleEntry>;
  subType?: Maybe<Scalars['String']>;
  type: OrderDetailsType;
  updatedAt: Scalars['DateTime'];
};

export enum OrderDetailsType {
  Actual = 'actual',
  Billable = 'billable',
  Planned = 'planned'
}

export type OrderDetailsUpsertInput = {
  equipmentId?: Maybe<Scalars['Int']>;
  inventory?: Maybe<OrderInventoryInput>;
  metrics?: Maybe<OrderMetricsInput>;
  schedule?: Maybe<OrderScheduleInput>;
};

export type OrderIntegrations = {
  __typename?: 'OrderIntegrations';
  quickbooks?: Maybe<OrderQuickbooksIntegration>;
};

export type OrderInventoryInput = {
  set?: Maybe<Array<OrderInventoryItemInput>>;
};

export type OrderInventoryItem = {
  __typename?: 'OrderInventoryItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  item: InventoryItem;
  itemId: Scalars['Int'];
  orderDetails: OrderDetails;
  orderDetailsId: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type OrderInventoryItemInput = {
  itemId: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
};

export type OrderMetric = {
  __typename?: 'OrderMetric';
  createdAt: Scalars['DateTime'];
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['MetricValue']>;
  valueFloat?: Maybe<Scalars['Float']>;
  valueString?: Maybe<Scalars['String']>;
};

export type OrderMetricInput = {
  key: Scalars['String'];
  value?: Maybe<Scalars['MetricValue']>;
};

export type OrderMetricsInput = {
  upsert?: Maybe<Array<OrderMetricInput>>;
};

export type OrderPour = {
  __typename?: 'OrderPour';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PourType>;
  typeId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type OrderPourInput = {
  name?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['Int']>;
};

export type OrderQuickbooksIntegration = {
  __typename?: 'OrderQuickbooksIntegration';
  invoice?: Maybe<QuickbooksInvoice>;
};

export type OrderRouteConnectInput = {
  force?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};

export type OrderRouteInput = {
  connect?: Maybe<OrderRouteConnectInput>;
  create?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type OrderScheduleEntry = {
  __typename?: 'OrderScheduleEntry';
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  /** Duration of this step in seconds */
  duration?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['DateTime']>;
  endTimezone?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  metrics: Array<OrderMetric>;
  orderDetails: OrderDetails;
  orderDetailsId: Scalars['Int'];
  pour?: Maybe<OrderPour>;
  scheduleIndex: Scalars['Int'];
  startTime?: Maybe<Scalars['DateTime']>;
  startTimezone?: Maybe<Scalars['String']>;
  step: Scalars['String'];
  stepDetails: ScheduleStep;
  updatedAt: Scalars['DateTime'];
};

export type OrderScheduleEntryInput = {
  address?: Maybe<AddressInput>;
  endTime?: Maybe<Scalars['DateTime']>;
  metrics?: Maybe<OrderMetricsInput>;
  pour?: Maybe<OrderPourInput>;
  scheduleIndex?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['DateTime']>;
  step?: Maybe<Scalars['String']>;
};

export type OrderScheduleInput = {
  /** Replace all OrderScheduleEntries with list provided here. */
  set?: Maybe<Array<OrderScheduleUpsertInput>>;
};

export type OrderScheduleUpsertInput = {
  data: OrderScheduleEntryInput;
  where?: Maybe<OrderScheduleWhereUniqueInput>;
};

export type OrderScheduleWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  active: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type OrderTerms = {
  __typename?: 'OrderTerms';
  accepted: Scalars['Boolean'];
  content: Terms;
  id: Scalars['Int'];
  needsAcceptance: Scalars['Boolean'];
  signatures: Array<Signature>;
};

export type OrderUpdateInput = {
  actuals?: Maybe<OrderActualsInput>;
  additionalIds?: Maybe<KeyValuesInput>;
  billable?: Maybe<OrderDetailsUpsertInput>;
  branchId?: Maybe<Scalars['Int']>;
  bulletinToCustomer?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cashOnDelivery?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<CommentsInput>;
  contacts?: Maybe<OrderContactsInput>;
  customerId?: Maybe<Scalars['Int']>;
  flatRate?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
  linesOnSite?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  planned?: Maybe<OrderDetailsUpsertInput>;
  purchaseOrder?: Maybe<Scalars['String']>;
  revision: Scalars['Int'];
  route?: Maybe<OrderRouteInput>;
  signatureId?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['Int']>;
};

export type OrderWhereUniqueInput = {
  id: Scalars['Int'];
};

export type OrdersFitCheck = {
  __typename?: 'OrdersFitCheck';
  results?: Maybe<Array<OrdersFitCheckResult>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OrdersFitCheckResult = {
  __typename?: 'OrdersFitCheckResult';
  incomingTravelDuration?: Maybe<Scalars['Int']>;
  invalidCode?: Maybe<Scalars['String']>;
  invalidDuration?: Maybe<Scalars['Int']>;
  invalidReason?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  outgoingTravelDuration?: Maybe<Scalars['Int']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['Int'];
  isDemo: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phones: Array<Phone>;
  primaryColor?: Maybe<Scalars['String']>;
  subscription: Subscription;
};

export type OrganizationInput = {
  additionalIds?: Maybe<KeyValuesInput>;
  logo?: Maybe<Scalars['String']>;
  metadata?: Maybe<KeyValuesInput>;
  name: Scalars['String'];
  phones?: Maybe<Array<PhoneInput>>;
  primaryColor?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type OrganizationUpdateInput = {
  additionalIds?: Maybe<KeyValuesInput>;
  logo?: Maybe<Scalars['String']>;
  metadata?: Maybe<KeyValuesInput>;
  name?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
  primaryColor?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type OrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type Person = PersonInterface & {
  __typename?: 'Person';
  createdAt: Scalars['DateTime'];
  emails: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  organization: Organization;
  phones: Array<Phone>;
  updatedAt: Scalars['DateTime'];
};

export type PersonInterface = {
  createdAt: Scalars['DateTime'];
  emails: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  organization: Organization;
  phones: Array<Phone>;
  updatedAt: Scalars['DateTime'];
};

export type Phone = {
  __typename?: 'Phone';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  number: Scalars['String'];
  sms: Scalars['Boolean'];
  type: Scalars['String'];
  typeDetails: PhoneType;
  updatedAt: Scalars['DateTime'];
};

export type PhoneInput = {
  active?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  sms?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type PhoneType = {
  __typename?: 'PhoneType';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PourType = {
  __typename?: 'PourType';
  /** @deprecated Abbreviation on PourType is deprecated and will always return null */
  abbreviation?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  category?: Maybe<PourType>;
  categoryId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  durationMultiplier?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  isCategory: Scalars['Boolean'];
  name: Scalars['String'];
  pinned: Scalars['Boolean'];
  /** @deprecated Sort on PourType is deprecated and will always return 0 */
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PourTypeInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  categoryId?: Maybe<Scalars['Int']>;
  durationMultiplier?: Maybe<Scalars['Float']>;
  isCategory?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<KeyValuesInput>;
  name: Scalars['String'];
  pinned?: Maybe<Scalars['Boolean']>;
};

export type PourTypeUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  categoryId?: Maybe<Scalars['Int']>;
  durationMultiplier?: Maybe<Scalars['Float']>;
  isCategory?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<KeyValuesInput>;
  name?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
};

export type PourTypeWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  addresses: Array<Address>;
  assignment?: Maybe<Assignment>;
  assignmentRoles: Array<AssignmentRole>;
  assignments: Array<Assignment>;
  branches: Array<Branch>;
  checkOrdersFitInRoute: OrdersFitCheck;
  checkWaypointsFitInRoute: WaypointsFitCheck;
  contactRoles: Array<ContactRole>;
  contacts: Array<Contact>;
  credentialTypes: Array<CredentialType>;
  credentials: Array<Credential>;
  customers: Array<Customer>;
  documentTypes: Array<DocumentType>;
  equipment: Array<Equipment>;
  equipmentAvailability: Array<EquipmentAvailabilitySnapshot>;
  equipmentGroups: Array<EquipmentGroup>;
  equipmentTypes: Array<EquipmentType>;
  estimateInvoice?: Maybe<InvoiceEstimate>;
  events: Array<Event>;
  insightsDashboards: Array<InsightsDashboard>;
  insightsUrl?: Maybe<InsightsUrl>;
  integrations?: Maybe<Integrations>;
  inventory: Array<InventoryItem>;
  me?: Maybe<PersonInterface>;
  notes: Array<Note>;
  operator?: Maybe<Operator>;
  operators: Array<Operator>;
  order?: Maybe<Order>;
  orderCancellationReasons: Array<OrderCancellationReason>;
  orderStatuses: Array<OrderStatus>;
  orders: Array<Order>;
  organization?: Maybe<Organization>;
  phoneTypes: Array<PhoneType>;
  pourTypes: Array<PourType>;
  route?: Maybe<Route>;
  routeWaypoints: Array<RouteWaypoint>;
  routes: Array<Route>;
  scheduleSteps: Array<ScheduleStep>;
  shortLink?: Maybe<ShortLink>;
  site?: Maybe<Site>;
  sites: Array<Site>;
  suppliers: Array<Supplier>;
  tags: Array<Tag>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryAddressesArgs = {
  orderBy?: Maybe<Array<QueryAddressesOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryAddressesWhereInput>;
};


export type QueryAssignmentArgs = {
  id: Scalars['Int'];
};


export type QueryAssignmentRolesArgs = {
  where?: Maybe<QueryAssignmentRolesWhereInput>;
};


export type QueryAssignmentsArgs = {
  orderBy?: Maybe<Array<QueryAssignmentsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryAssignmentsWhereInput>;
};


export type QueryBranchesArgs = {
  where?: Maybe<QueryBranchesWhereInput>;
};


export type QueryCheckOrdersFitInRouteArgs = {
  where?: Maybe<CheckOrdersFitInRouteInput>;
};


export type QueryCheckWaypointsFitInRouteArgs = {
  where?: Maybe<CheckWaypointsFitInRouteInput>;
};


export type QueryContactRolesArgs = {
  where?: Maybe<QueryContactRolesWhereInput>;
};


export type QueryContactsArgs = {
  orderBy?: Maybe<Array<QueryContactsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryContactsWhereInput>;
};


export type QueryCredentialTypesArgs = {
  where?: Maybe<QueryCredentialTypesWhereInput>;
};


export type QueryCredentialsArgs = {
  orderBy?: Maybe<Array<QueryCredentialsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryCredentialsWhereInput>;
};


export type QueryCustomersArgs = {
  orderBy?: Maybe<Array<QueryCustomersOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryCustomersWhereInput>;
};


export type QueryDocumentTypesArgs = {
  where?: Maybe<DocumentTypesWhereInput>;
};


export type QueryEquipmentArgs = {
  orderBy?: Maybe<Array<QueryEquipmentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryEquipmentWhereInput>;
};


export type QueryEquipmentAvailabilityArgs = {
  orderBy?: Maybe<Array<QueryEquipmentAvailabilitiesOrderByInput>>;
  where?: Maybe<QueryEquipmentAvailabilitiesWhereInput>;
};


export type QueryEquipmentTypesArgs = {
  where?: Maybe<QueryEquipmentTypesWhereInput>;
};


export type QueryEstimateInvoiceArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryEventsArgs = {
  orderBy?: Maybe<Array<QueryEventsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryEventsWhereInput>;
};


export type QueryInsightsDashboardsArgs = {
  where?: Maybe<InsightsDashboardsInput>;
};


export type QueryInsightsUrlArgs = {
  where?: Maybe<InsightsUrlInput>;
};


export type QueryInventoryArgs = {
  where?: Maybe<QueryInventoryItemsWhereInput>;
};


export type QueryNotesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryNotesWhereInput>;
};


export type QueryOperatorArgs = {
  id: Scalars['Int'];
};


export type QueryOperatorsArgs = {
  orderBy?: Maybe<Array<QueryOperatorsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryOperatorsWhereInput>;
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryOrderCancellationReasonsArgs = {
  where?: Maybe<QueryOrderCancellationReasonsWhereInput>;
};


export type QueryOrderStatusesArgs = {
  where?: Maybe<QueryOrderStatusesWhereInput>;
};


export type QueryOrdersArgs = {
  orderBy?: Maybe<Array<QueryOrdersOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryOrdersWhereInput>;
};


export type QueryPhoneTypesArgs = {
  where?: Maybe<QueryPhoneTypesWhereInput>;
};


export type QueryPourTypesArgs = {
  where?: Maybe<QueryPourTypesWhereInput>;
};


export type QueryRouteArgs = {
  id: Scalars['Int'];
};


export type QueryRouteWaypointsArgs = {
  orderBy?: Maybe<Array<QueryRouteWaypointsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryRouteWaypointsWhereInput>;
};


export type QueryRoutesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryRoutesWhereInput>;
};


export type QueryScheduleStepsArgs = {
  where?: Maybe<QueryScheduleStepsWhereInput>;
};


export type QueryShortLinkArgs = {
  where: QueryShortLinkWhereInput;
};


export type QuerySiteArgs = {
  id: Scalars['Int'];
};


export type QuerySitesArgs = {
  orderBy?: Maybe<Array<QuerySitesOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QuerySitesWhereInput>;
};


export type QuerySuppliersArgs = {
  where?: Maybe<QuerySuppliersWhereInput>;
};


export type QueryTagsArgs = {
  orderBy?: Maybe<Array<QueryTagsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryTagsWhereInput>;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUsersArgs = {
  orderBy?: Maybe<Array<QueryUsersOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<QueryUsersWhereInput>;
};

export type QueryAddressesOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QueryAddressesWhereInput = {
  id?: Maybe<IntFilter>;
};

export type QueryAssignmentRolesWhereInput = {
  active?: Maybe<BoolFilter>;
};

export type QueryAssignmentsOrderByInput = {
  id?: Maybe<SortOrder>;
};

export type QueryAssignmentsWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  visible?: Maybe<BoolFilter>;
};

export type QueryBranchesWhereInput = {
  id?: Maybe<IntFilter>;
};

export type QueryContactRolesWhereInput = {
  active?: Maybe<BoolFilter>;
};

export type QueryContactsOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QueryContactsWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  phone?: Maybe<StringFilter>;
};

export type QueryCredentialTypesWhereInput = {
  active?: Maybe<BoolFilter>;
};

export type QueryCredentialsOrderByInput = {
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
};

export type QueryCredentialsWhereInput = {
  active?: Maybe<BoolFilter>;
  expirationDate?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
};

export type QueryCustomersOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QueryCustomersWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
};

export type QueryEquipmentAvailabilitiesOrderByInput = {
  id?: Maybe<SortOrder>;
};

export type QueryEquipmentAvailabilitiesWhereInput = {
  branchId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  validFrom?: Maybe<DateTimeFilter>;
  validTo?: Maybe<DateTimeNullableFilter>;
};

export type QueryEquipmentOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  sort?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QueryEquipmentTypesWhereInput = {
  active?: Maybe<BoolFilter>;
};

export type QueryEquipmentWhereInput = {
  active?: Maybe<BoolFilter>;
  branchId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  type?: Maybe<StringFilter>;
};

export type QueryEventsOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QueryEventsWhereInput = {
  active?: Maybe<BoolFilter>;
  branchId: Scalars['Int'];
  /** YYYY-MM-DD date string to view events on */
  date?: Maybe<Scalars['String']>;
};

export type QueryInventoryItemsWhereInput = {
  active?: Maybe<BoolFilter>;
  branchId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type QueryNotesWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
};

export type QueryOperatorsOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QueryOperatorsWhereInput = {
  active?: Maybe<BoolFilter>;
  branchId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export type QueryOrderCancellationReasonsWhereInput = {
  active?: Maybe<BoolFilter>;
};

export type QueryOrderStatusesWhereInput = {
  active?: Maybe<BoolFilter>;
};

export type QueryOrdersOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  dateOfService?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QueryOrdersWhereInput = {
  active?: Maybe<BoolFilter>;
  branchId?: Maybe<IntNullableFilter>;
  contactId?: Maybe<IntFilter>;
  customerId?: Maybe<IntNullableFilter>;
  dateOfService?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<IntFilter>;
  siteId?: Maybe<IntNullableFilter>;
  status?: Maybe<StringFilter>;
  tagId?: Maybe<IntFilter>;
};

export type QueryPhoneTypesWhereInput = {
  active?: Maybe<BoolFilter>;
};

export type QueryPourTypesWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  isCategory?: Maybe<BoolFilter>;
};

export type QueryRouteWaypointsOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  scheduledArrivalTime?: Maybe<SortOrder>;
  scheduledDepartureTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QueryRouteWaypointsWhereInput = {
  addressId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export type QueryRoutesWhereInput = {
  active?: Maybe<BoolFilter>;
  endTime?: Maybe<DateTimeFilter>;
  equipmentId?: Maybe<IntNullableFilter>;
  id?: Maybe<IntFilter>;
  occursNearTime?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<DateTimeFilter>;
};

export type QueryScheduleStepsWhereInput = {
  active?: Maybe<BoolFilter>;
};

export type QueryShortLinkWhereInput = {
  slug: Scalars['String'];
};

export type QuerySitesOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QuerySitesWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
};

export type QuerySuppliersWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
};

export type QueryTagsOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  path?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QueryTagsWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  path?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
};

export type QueryUsersOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type QueryUsersWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
};

export type Quickbooks = {
  __typename?: 'Quickbooks';
  customers?: Maybe<Array<QuickbooksCustomer>>;
  items?: Maybe<QuickbooksItems>;
  name?: Maybe<Scalars['String']>;
  realmId?: Maybe<Scalars['String']>;
  settings?: Maybe<QuickbooksSettings>;
};

export type QuickbooksCustomer = {
  __typename?: 'QuickbooksCustomer';
  active: Scalars['Boolean'];
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  revision: Scalars['String'];
};

export type QuickbooksInvoice = {
  __typename?: 'QuickbooksInvoice';
  id: Scalars['String'];
};

export type QuickbooksItem = {
  __typename?: 'QuickbooksItem';
  active: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  revision: Scalars['String'];
};

export type QuickbooksItems = {
  __typename?: 'QuickbooksItems';
  all: Array<QuickbooksItem>;
  linkable: Array<QuickbooksLinkableItem>;
};

export type QuickbooksLinkableItem = {
  __typename?: 'QuickbooksLinkableItem';
  itemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type QuickbooksMutationNamespace = {
  __typename?: 'QuickbooksMutationNamespace';
  createCustomer?: Maybe<Customer>;
  createInvoice?: Maybe<GenericResponse>;
  generateInvoicePdfLink: Scalars['String'];
  linkCustomer: Customer;
  linkItem?: Maybe<QuickbooksLinkableItem>;
  syncCustomer?: Maybe<GenericResponse>;
};


export type QuickbooksMutationNamespaceCreateCustomerArgs = {
  force?: Maybe<Scalars['Boolean']>;
  where: CustomerWhereUniqueInput;
};


export type QuickbooksMutationNamespaceCreateInvoiceArgs = {
  forceRecreate?: Maybe<Scalars['Boolean']>;
  where: OrderWhereUniqueInput;
};


export type QuickbooksMutationNamespaceGenerateInvoicePdfLinkArgs = {
  where: OrderWhereUniqueInput;
};


export type QuickbooksMutationNamespaceLinkCustomerArgs = {
  customer: CustomerWhereUniqueInput;
  quickbooksId?: Maybe<Scalars['String']>;
};


export type QuickbooksMutationNamespaceLinkItemArgs = {
  itemId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};


export type QuickbooksMutationNamespaceSyncCustomerArgs = {
  where: CustomerWhereUniqueInput;
};

export type QuickbooksSettings = {
  __typename?: 'QuickbooksSettings';
  customTxnNumberFormat?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  poNumberCustomField?: Maybe<Scalars['String']>;
  syncCustomers: Scalars['Boolean'];
  syncInvoices: Scalars['Boolean'];
  vulcanOrderLinkCustomField?: Maybe<Scalars['String']>;
};

export type RequestOrderChangeInput = {
  change: Scalars['String'];
};

export type RequestSiteLocationInput = {
  forceResend?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
};

export type Route = {
  __typename?: 'Route';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  assignments: Array<Assignment>;
  branch: Branch;
  branchId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  dispatched?: Maybe<Scalars['Boolean']>;
  dispatchedAt?: Maybe<Scalars['DateTime']>;
  endTime: Scalars['DateTime'];
  endTimeLocal: Scalars['DateTime'];
  endTimezone?: Maybe<Scalars['String']>;
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** @deprecated use Order.instructions instead */
  instructions?: Maybe<Scalars['String']>;
  integrations?: Maybe<RouteIntegrations>;
  revision: Scalars['Int'];
  startTime: Scalars['DateTime'];
  startTimeLocal: Scalars['DateTime'];
  startTimezone?: Maybe<Scalars['String']>;
  status: RouteStatus;
  updatedAt: Scalars['DateTime'];
  waypoints: Array<RouteWaypoint>;
};

export type RouteIntegrations = {
  __typename?: 'RouteIntegrations';
  samsara?: Maybe<RouteSamsaraIntegration>;
};

export type RouteSamsaraIntegration = {
  __typename?: 'RouteSamsaraIntegration';
  route?: Maybe<SamsaraRoute>;
};

export enum RouteStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  InProgress = 'inProgress',
  Scheduled = 'scheduled'
}

export type RouteUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  assignments?: Maybe<AssignmentsInput>;
  branchId?: Maybe<Scalars['Int']>;
  equipmentId?: Maybe<Scalars['Int']>;
  revision?: Maybe<Scalars['Int']>;
  waypoints?: Maybe<RouteWaypointsInput>;
};

export type RouteWaypoint = {
  __typename?: 'RouteWaypoint';
  actualArrivalTime?: Maybe<Scalars['DateTime']>;
  actualArrivalTimeLocal?: Maybe<Scalars['DateTime']>;
  actualDepartureTime?: Maybe<Scalars['DateTime']>;
  actualDepartureTimeLocal?: Maybe<Scalars['DateTime']>;
  address: Address;
  addressId: Scalars['Int'];
  arrivalPerformance?: Maybe<RouteWaypointPerformance>;
  createdAt: Scalars['DateTime'];
  departurePerformance?: Maybe<RouteWaypointPerformance>;
  estimatedArrivalTime?: Maybe<Scalars['DateTime']>;
  estimatedArrivalTimeLocal?: Maybe<Scalars['DateTime']>;
  estimatedDepartureTime?: Maybe<Scalars['DateTime']>;
  estimatedDepartureTimeLocal?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  metadata?: Maybe<Array<KeyValue>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  route: Route;
  routeId: Scalars['Int'];
  scheduledArrivalTime?: Maybe<Scalars['DateTime']>;
  scheduledArrivalTimeLocal?: Maybe<Scalars['DateTime']>;
  scheduledDepartureTime?: Maybe<Scalars['DateTime']>;
  scheduledDepartureTimeLocal?: Maybe<Scalars['DateTime']>;
  status: RouteWaypointStatus;
  updatedAt: Scalars['DateTime'];
};

export type RouteWaypointInput = {
  actualArrivalTime?: Maybe<Scalars['DateTime']>;
  actualDepartureTime?: Maybe<Scalars['DateTime']>;
  addressId?: Maybe<Scalars['Int']>;
  estimatedArrivalTime?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<KeyValuesInput>;
  orderId?: Maybe<Scalars['Int']>;
  scheduledArrivalTime?: Maybe<Scalars['DateTime']>;
  scheduledDepartureTime?: Maybe<Scalars['DateTime']>;
};

export enum RouteWaypointPerformance {
  Late = 'late',
  OnTime = 'onTime',
  VeryLate = 'veryLate'
}

export enum RouteWaypointStatus {
  Completed = 'completed',
  EnRoute = 'enRoute',
  InProgress = 'inProgress',
  Scheduled = 'scheduled'
}

export type RouteWaypointUpsertInput = {
  data?: Maybe<RouteWaypointInput>;
  where?: Maybe<RouteWaypointWhereUniqueInput>;
};

export type RouteWaypointWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type RouteWaypointsInput = {
  set?: Maybe<Array<RouteWaypointInput>>;
  upsert?: Maybe<Array<RouteWaypointUpsertInput>>;
};

export type RouteWhereUniqueInput = {
  id: Scalars['Int'];
  revision?: Maybe<Scalars['Int']>;
};

export type Samsara = {
  __typename?: 'Samsara';
  accessToken?: Maybe<IntegrationToken>;
  drivers: Array<SamsaraDriver>;
  settings?: Maybe<SamsaraSettings>;
  vehicles: Array<SamsaraVehicle>;
};

export type SamsaraDriver = {
  __typename?: 'SamsaraDriver';
  driverActivationStatus?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type SamsaraMutationNamespace = {
  __typename?: 'SamsaraMutationNamespace';
  linkDriver: Operator;
  linkRoute: Route;
  linkVehicle: Equipment;
};


export type SamsaraMutationNamespaceLinkDriverArgs = {
  driverId?: Maybe<Scalars['String']>;
  operator: OperatorWhereUniqueInput;
};


export type SamsaraMutationNamespaceLinkRouteArgs = {
  route: RouteWhereUniqueInput;
  samsaraRouteId?: Maybe<Scalars['String']>;
};


export type SamsaraMutationNamespaceLinkVehicleArgs = {
  equipment: EquipmentWhereUniqueInput;
  vehicleId?: Maybe<Scalars['String']>;
};

export type SamsaraRoute = {
  __typename?: 'SamsaraRoute';
  id: Scalars['String'];
};

export enum SamsaraRouteAssignmentTarget {
  Driver = 'driver',
  Vehicle = 'vehicle'
}

export type SamsaraSettings = {
  __typename?: 'SamsaraSettings';
  autoAssignDriversToVehicles: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  organizationId: Scalars['String'];
  routeAssignmentTarget: SamsaraRouteAssignmentTarget;
};

export type SamsaraVehicle = {
  __typename?: 'SamsaraVehicle';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type ScheduleStep = {
  __typename?: 'ScheduleStep';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type SendOrderMessageInput = {
  forceResend?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
};

export type ShortLink = {
  __typename?: 'ShortLink';
  active: Scalars['Boolean'];
  authorization?: Maybe<Authorization>;
  createdAt: Scalars['DateTime'];
  expires?: Maybe<Scalars['DateTime']>;
  redirect: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Signature = {
  __typename?: 'Signature';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  image: Scalars['String'];
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type Site = {
  __typename?: 'Site';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  address: Address;
  addressId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['Int'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phones: Array<Phone>;
  tags: Array<Tag>;
  updatedAt: Scalars['DateTime'];
  verified: Scalars['Boolean'];
};

export type SiteInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  address: NavigableAddressInput;
  customerId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
};

export type SiteUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  address?: Maybe<AddressInput>;
  customerId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
};

export type SiteWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  currentPeriodEnd?: Maybe<Scalars['DateTime']>;
  currentPeriodStart?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  link: Scalars['String'];
  name: Scalars['String'];
  status: SubscriptionStatus;
  trialEnd?: Maybe<Scalars['DateTime']>;
};

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export type Supplier = {
  __typename?: 'Supplier';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  name2?: Maybe<Scalars['String']>;
  phones: Array<Phone>;
  updatedAt: Scalars['DateTime'];
};

export type SupplierInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  metadata?: Maybe<KeyValuesInput>;
  name: Scalars['String'];
  name2?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
};

export type SupplierUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  metadata?: Maybe<KeyValuesInput>;
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
};

export type SupplierWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type Tag = {
  __typename?: 'Tag';
  active: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  path: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type TagWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type TelematicsGps = {
  __typename?: 'TelematicsGPS';
  enabled: Scalars['Boolean'];
};

export type TelematicsStats = {
  __typename?: 'TelematicsStats';
  enabled: Scalars['Boolean'];
};

export type Terms = {
  __typename?: 'Terms';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

export type TravelEstimate = {
  __typename?: 'TravelEstimate';
  duration?: Maybe<Scalars['Int']>;
  fromAddressId?: Maybe<Scalars['Int']>;
  toAddressId?: Maybe<Scalars['Int']>;
};

export type UpdateTagsRelationsDataInput = {
  contacts?: Maybe<Array<ContactWhereUniqueInput>>;
  customers?: Maybe<Array<CustomerWhereUniqueInput>>;
  orders?: Maybe<Array<OrderWhereUniqueInput>>;
  sites?: Maybe<Array<SiteWhereUniqueInput>>;
};

export type UpdateTagsRelationsResponse = {
  __typename?: 'UpdateTagsRelationsResponse';
  contacts?: Maybe<Array<Contact>>;
  customers?: Maybe<Array<Customer>>;
  orders?: Maybe<Array<Order>>;
  sites?: Maybe<Array<Site>>;
};

export type User = PersonInterface & {
  __typename?: 'User';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  createdAt: Scalars['DateTime'];
  emails: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  isOperator?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  operator?: Maybe<Operator>;
  organization: Organization;
  phones: Array<Phone>;
  preferences: UserPreferences;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type UserInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  credentials?: Maybe<CredentialsInput>;
  firstName?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isOperator?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
  username: Scalars['String'];
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  messages: Array<MessagePreference>;
};

export type UserUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  credentials?: Maybe<CredentialsInput>;
  firstName?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isOperator?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhoneInput>>;
  username?: Maybe<Scalars['String']>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type WaypointsFitCheck = {
  __typename?: 'WaypointsFitCheck';
  results?: Maybe<Array<WaypointsFitCheckResult>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type WaypointsFitCheckResult = {
  __typename?: 'WaypointsFitCheckResult';
  incomingTravelDuration?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  invalidCode?: Maybe<Scalars['String']>;
  invalidDuration?: Maybe<Scalars['Int']>;
  invalidReason?: Maybe<Scalars['String']>;
  outgoingTravelDuration?: Maybe<Scalars['Int']>;
};

export type Yard = {
  __typename?: 'Yard';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  address: Address;
  branch: Branch;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  metadata?: Maybe<Array<KeyValue>>;
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type YardCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  address: NavigableAddressInput;
  metadata?: Maybe<KeyValuesInput>;
  name?: Maybe<Scalars['String']>;
};

export type YardUpdateArgs = {
  data: YardUpdateInput;
  where: YardWhereUniqueInput;
};

export type YardUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  additionalIds?: Maybe<KeyValuesInput>;
  address?: Maybe<AddressInput>;
  metadata?: Maybe<KeyValuesInput>;
  name?: Maybe<Scalars['String']>;
};

export type YardWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type YardsInput = {
  create?: Maybe<Array<YardCreateInput>>;
  update?: Maybe<Array<YardUpdateArgs>>;
};
