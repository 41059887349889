import { gql, useQuery } from '@apollo/client'
import { NOTE_SLIM_FRAGMENT } from 'gql/notes'
import { ORDER_SLIM_FRAGMENT } from 'gql/orders'
import { ROUTE_SLIM_FRAGMENT } from 'gql/routes'
import {
  compact, groupBy, isEmpty, isNil, mapValues, omit, pullAt, range,
} from 'lodash'
import { DateTime, Interval } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { generatePath, Link, useRouteMatch } from 'react-router-dom'
import { GroupedVirtuoso } from 'react-virtuoso'
import {
  Note, Order, Query, QueryEventsArgs,
} from 'schema'
import styled from 'styled-components'
import colors from '../../../constants/colors'
import { useEditMode } from '../EditModeState'
import { SchedulerEvent, SchedulerEvents, useSchedulerEvents } from '../SchedulerEventsState'
import { SchedulerGroup, SchedulerResource, useSchedulerResources } from '../SchedulerResourcesState'
import { useSchedulerState } from '../SchedulerState'
import { DisabledItemWrapper } from './DisabledItemWrapper'
import { EquipmentHeaderItem } from './EquipmentHeaderItem'
import { HeaderItem } from './HeaderItem'
import { MoveItemToRouteWrapper } from './MoveItemToRouteWrapper'
import { NoteItem } from './NoteItem'
import { OrderItem } from './OrderItem'
import { SelectedItemWrapper } from './SelectedItemWrapper'

const GroupedList = styled(GroupedVirtuoso).attrs((props) => ({
  loading: false as boolean,
  ...props,
}))`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

  ${(props) => props.loading && 'opacity: 0.7'}
`

const GroupHeaderItem = styled(HeaderItem) <{ collapsed?: boolean }>`
  // text-align: center;
  &:before {
    font-size: 0.8em;
    content: "${(props) => (props.collapsed ? '▶' : '▼')}";
    padding-right: 5px;
  }
`

export const EquipmentScheduleList = () => {
  const match = useRouteMatch()
  const basePath = useMemo(() => generatePath(match.path, match.params as any), [match.path, match.params])

  const editMode = useEditMode()

  const {
    branch,
    selectedDate,
    inactiveEventsVisible,
  } = useSchedulerState()

  const schedulerResources = useSchedulerResources()
  const { defaultResourceId } = schedulerResources
  const [events, setEvents] = useSchedulerEvents()

  const [groupCollapseState, setGroupCollapseState] = useState<Record<string, true>>({})
  const toggleGroup = (key: string) => {
    setGroupCollapseState((prev) => {
      if (prev[key]) {
        return omit(prev, key)
      }

      return {
        ...prev,
        [key]: true,
      }
    })
  }

  const eventsResponse = useQuery<Query, QueryEventsArgs>(GET_EVENTS, {
    fetchPolicy: 'network-only',
    pollInterval: 300 * 1000,
    variables: {
      where: {
        branchId: branch?.id || -1,
        date: selectedDate.toISODate(),
        active: inactiveEventsVisible === true ? undefined : {
          equals: true,
        },
      },
    },
  })

  const { loading } = eventsResponse
  const queryEvents = eventsResponse?.data?.events

  useEffect(() => {
    if (loading) {
      return setEvents([])
    }

    setEvents(
      compact((queryEvents || []).map((event) => {
        if (event?.note?.active === false) return

        const { note, route } = event
        const equipment = note?.equipment || route?.equipment

        return {
          id: event.id,
          resourceId: equipment?.id || defaultResourceId,
          start: DateTime.fromISO(event.startTime),
          end: DateTime.fromISO(event.endTime),
          event,
          equipment,
          note,
          route,
        }
      }))
    )
  }, [loading, queryEvents])

  const headers = useMemo(() => {
    const resourceByGroupKey = groupBy(schedulerResources.resources, (resource) => resource.group?.key)

    const notGrouped = resourceByGroupKey[undefined as any]

    const orderedHeaders = [
      ...notGrouped,
      ...schedulerResources.groups.flatMap((resourceGroup) => ([
        resourceGroup,
        ...resourceByGroupKey[resourceGroup.key],
      ])),
    ]

    return orderedHeaders
  }, [schedulerResources])

  const usableEvents = useMemo(() => {
    const flattened = flattenRouteEvents(events)

    const validDateRange = Interval.fromDateTimes(selectedDate.minus({ hours: 2 }), selectedDate.plus({ day: 1 }))

    const todaysEvents = flattened.filter((event) => {
      if (event.note) {
        return true
      }

      return validDateRange.contains(event.start)
    })

    const eventsWithDefaultResource = todaysEvents.map((event) => {
      if (event.resourceId) return event

      return {
        ...event,
        resourceId: schedulerResources.defaultResourceId,
      }
    })

    return eventsWithDefaultResource.map((event) => {
      const { note, order } = event

      if (note) {
        return {
          ...event,
          element: <Link to={`${basePath}/note/${note.id}`}>
            <NoteItem note={note as Note} />
          </Link>,
        }
      }

      if (order) {
        return {
          ...event,
          element: <Link to={`${basePath}/order/${order.id}`}>
            <OrderItem order={order as Order} />
          </Link>,
        }
      }

      return {
        ...event,
        element: null,
      }
    })
  }, [selectedDate, events])

  const editModeAdjustedEvents = useMemo(() => {
    if (!editMode.enabled) return usableEvents

    return usableEvents.flatMap((event, index) => {
      const order = event?.order
      if (!order) return event
      const routeId = order?.route?.id

      const selected = editMode.orders?.isSelected(order.id)

      const disabled = (
        // eslint-disable-next-line no-mixed-operators
        editMode.orders?.isDisabled(order.id) ||
        // eslint-disable-next-line no-mixed-operators
        routeId && editMode.routes?.isDisabled(routeId)
      )
      const selectable = routeId && (
        editMode.routes?.isSelectable(routeId) ||
        editMode.routes?.isSelected(routeId)
      )

      if (selected) {
        return {
          ...event,
          element: <SelectedItemWrapper>
            {event.element}
          </SelectedItemWrapper>,
        }
      }

      if (disabled) {
        return {
          ...event,
          element: <DisabledItemWrapper>
            {event.element}
          </DisabledItemWrapper>,
        }
      }

      const nextRouteId = usableEvents[index + 1]?.order?.route?.id

      if (routeId && routeId !== nextRouteId && selectable) {
        return {
          ...event,
          element: <MoveItemToRouteWrapper routeId={routeId}>
            {event.element}
          </MoveItemToRouteWrapper>,
        }
      }

      return event
    })
  }, [editMode, usableEvents, headers])

  const { items, groupCounts } = useMemo(() => collectEventsForVirtuoso(headers, editModeAdjustedEvents), [headers, editModeAdjustedEvents])

  const { filteredItems, filteredGroupCounts, filteredHeaders } = useMemo(() => {
    if (isEmpty(groupCollapseState)) {
      return {
        filteredItems: items,
        filteredHeaders: headers,
        filteredGroupCounts: groupCounts,
      }
    }

    let itemIndexSum = 0

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const filteredItems = [...items]

    const headersToDelete = headers.map((header, index) => {
      if (
        'group' in header &&
        header.group?.key &&
        groupCollapseState[header.group.key]
      ) {
        return index
      }
      return null
    }).filter((v) => !isNil(v)) as number[]

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const filteredHeaders = [...headers]
    pullAt(filteredHeaders, headersToDelete)

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const filteredGroupCounts = groupCounts.map((groupCount, index) => {
      if (headersToDelete.includes(index)) {
        return null
      }

      const hidden = Boolean(groupCollapseState[index])

      if (hidden) {
        pullAt(filteredItems, range(itemIndexSum, itemIndexSum + groupCount))
        return 0
      }

      itemIndexSum += groupCount
      return groupCount
    }).filter((v) => !isNil(v)) as number[]

    return { filteredItems, filteredGroupCounts, filteredHeaders }
  }, [items, headers, groupCounts, groupCollapseState])

  return (
    <GroupedList
      loading={loading}
      groupCounts={filteredGroupCounts}
      // eslint-disable-next-line react/no-unstable-nested-components
      groupContent={(index) => {
        const header = filteredHeaders[index]

        if ('__typename' in header && header.__typename === 'EquipmentGroup') {
          return (
            <GroupHeaderItem
              onClick={() => toggleGroup(header.key)}
              collapsed={groupCollapseState[header.key]}
            >
              {header.name}
            </GroupHeaderItem>
          )
        }
        return <EquipmentHeaderItem resource={header} />
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      itemContent={(index) => {
        const item = filteredItems[index]
        if (!item?.element) return

        const Wrapper = item.odd ? OddItemWrapper : EvenItemWrapper

        return <Wrapper children={item.element} />
      }}
    />
  )
}

const EvenItemWrapper = styled.div`
  background-color: ${colors.backgroundPrimary};
  border-bottom: 1px solid ${colors.borderPrimary};
`
const OddItemWrapper = styled.div`
  background-color: ${colors.backgroundSecondary};
  border-bottom: 1px solid ${colors.borderPrimary};
`

const flattenRouteEvents = (events: SchedulerEvents): SchedulerEvents => (
  compact(
    events.flatMap((event) => {
      if (event.route) {
        const { route, ...eventRest } = event

        return route.waypoints?.map((wp) => {
          const { order } = wp
          if (!order?.id) return

          const orderEvent: SchedulerEvent = {
            ...eventRest,
            order: {
              ...order,
              id: order.id,
              route,
            },
          }
          return orderEvent
        })
      }

      return event
    })
  )
)

const collectEventsForVirtuoso = <
  EventType extends SchedulerEvent
>(
  headers: Partial<SchedulerResource | SchedulerGroup>[],
  events: EventType[]
) => {
  const grouped = groupBy(events, 'resourceId')

  const sortedInGroup = mapValues(grouped, (equipmentEvents) => (
    equipmentEvents.sort((a, b) => {
      if (a.note && !b.note) return -1
      if (!a.note && b.note) return 1
      return a.start < b.start ? -1 : 1
    }).map((event, i) => ({
      ...event,
      odd: i % 2 !== 0,
    }))
  ))

  const sortedGroupIndiciesWithItems = headers.map((header) => {
    const isGroup = 'key' in header
    if (isGroup || !header.id) return []
    return sortedInGroup[header.id] || []
  })

  const groupCounts = sortedGroupIndiciesWithItems.map((items) => items.length)
  const items = Object.values(sortedGroupIndiciesWithItems).flat()

  return { items, groupCounts }
}

const GET_EVENTS = gql`
  query GetEvents(
    $where: QueryEventsWhereInput
  ) {
    events(
     where: $where
    ) {
      id
      startTime
      endTime
      active
      note {
        ...NoteFields
      }
      route {
        ...RouteFields
      }
    }
  }
  ${NOTE_SLIM_FRAGMENT}
  ${ROUTE_SLIM_FRAGMENT}
  ${ORDER_SLIM_FRAGMENT}
`
