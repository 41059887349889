import colors from 'constants/colors'
import { compact } from 'lodash'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { Credential } from 'schema'
import styled from 'styled-components'

const UPCOMING_EXPIRATION_DAYS = 60
const IMMINENT_EXPIRATION_DAYS = 30

export const EXPIRATION_CSS = `
  &.upcoming {
    font-weight: bolder;
    color: ${colors.performance.late};
  }

  &.expired, &.imminent {
    font-weight: bolder;
    color: ${colors.performance.veryLate};
  }
`

export const getCredentialExpirationWarning = (date?: DateTime): 'expired' | 'imminent' | 'upcoming' | undefined => {
  if (!date) return

  const now = DateTime.utc().setZone(date.zone).startOf('day')

  if (date < now) {
    return 'expired'
  }
  if (date < now.plus({ days: IMMINENT_EXPIRATION_DAYS })) {
    return 'imminent'
  }
  if (date < now.plus({ days: UPCOMING_EXPIRATION_DAYS })) {
    return 'upcoming'
  }
}

export interface CredentialExpirationStyledProps extends React.HTMLProps<HTMLSpanElement> {
  expirationDate?: Credential['expirationDate']
}

export const CredentialExpirationStyle = styled((props: CredentialExpirationStyledProps) => {
  const {
    expirationDate,
    ...rest
  } = props

  const asLuxon = useMemo(() => (expirationDate ? DateTime.fromISO(expirationDate) : undefined), [expirationDate])
  const expirationWarning = useMemo(() => getCredentialExpirationWarning(asLuxon), [asLuxon])

  return <span {...rest} className={compact([rest.className, expirationWarning]).join(' ')} />
})`
  ${EXPIRATION_CSS}
`

export const CredentialExpirationDate = styled((props: Omit<CredentialExpirationStyledProps, 'children'>) => {
  const { expirationDate } = props
  const asLuxon = useMemo(() => (expirationDate ? DateTime.fromISO(expirationDate) : undefined), [expirationDate])

  if (!asLuxon) return null

  return (
    <CredentialExpirationStyle {...(props as any)}>
      {asLuxon.toFormat('MM/dd/yyyy')}
    </CredentialExpirationStyle>
  )
})``
