import { Address } from 'schema'
import { timezoneForCoordinates } from './timezoneForCoordinates'

type SlimAddress = Pick<Address, 'lat' | 'lng' | 'timezone'>

export const ensureAddressTimezone = async <T extends SlimAddress>(newAddress: T, oldAddress?: SlimAddress | undefined): Promise<T> => {
  const newLat = newAddress.lat
  const newLng = newAddress.lng

  if (!newLat || !newLng) {
    return newAddress
  }

  const oldLat = oldAddress?.lat
  const oldLng = oldAddress?.lng

  const needsFetched = (
    !newAddress.timezone ||
    newLat !== oldLat ||
    newLng !== oldLng
  )

  if (!needsFetched) {
    return newAddress
  }

  newAddress.timezone = await timezoneForCoordinates(newLat, newLng)

  return newAddress
}
