import React, { useEffect, useMemo, useState } from 'react'

import { timeWithZone } from 'helpers/datetime'
import {
  compact, isArray, isEmpty, isFunction, set as setProperty, uniqBy,
} from 'lodash'
import styled from 'styled-components'
import qs from 'utils/qs'

import { useBranch } from 'hooks/useBranch'
import { useIsMobile } from 'hooks/useViewportMode'

import { gql, useQuery } from '@apollo/client'
import { GET_ORDERS_QUERY } from 'gql/orders'
import {
  Order,
  Query,
  QueryOrdersArgs,
  SortOrder,
} from 'schema'

import {
  Route, useHistory,
  useLocation, useRouteMatch,
} from 'react-router-dom'

import {
  Button, Col, Row, Typography,
} from 'antd'

import { FilterOutlined } from '@ant-design/icons'
import { ColumnType } from 'antd/lib/table'
import AntTable, { TableProps as AntTableProps } from 'antd/lib/table/Table'
import { ErrorMessage } from 'components/ErrorMessage'
import { RoutableDrawer } from 'components/scheduler/RoutableDrawer'

import { Card } from 'components/card'
import { ORDER_FIELDS, OrderFieldSelection } from 'components/order/OrderFieldsSelector'
import { OrderFieldsSelectorIcon } from 'components/order/OrderFieldsSelectorIcon'
import { OrderFiltersProps, OrdersFilters } from 'components/order/OrdersFilters'
import { Body, Footer, Header } from 'hooks/useContentLayout'
import { jsonToGraphQLQuery } from 'json-to-graphql-query'
import { PageContent } from 'layouts/Content/PageContent'
import { useOrderPagesState } from './OrderPagesState'

const { Paragraph, Title, Text } = Typography

const Table: React.FC<AntTableProps<any>> = styled(AntTable)`
  table {
    font-size: 12px;

    .ant-comment-content {
      font-size: 12px;
    }
  }

  .ant-table-row:hover {
    cursor: pointer;
  }

  .ant-table-cell {
    padding: 11px;
    min-width: 70px;
    // white-space: pre-wrap;
  }
`

const OrderPageBody = styled.div``

const ColumnTitle = styled(Text).attrs({
  ellipsis: { tooltip: true },
})`
  width: 100%;
`

const perPage = 25

const pickOrderFields = (values: string[]) => (
  ORDER_FIELDS.filter(({ value }) => values.includes(value)).sort((a, b) => values.indexOf(a.value) - values.indexOf(b.value))
)

const DEFAULT_ORDER_FIELDS = pickOrderFields(['id', 'status', 'customer', 'site', 'date'])

type ColumnProps = ColumnType<Order>

const COLUMN_OVERRIDES: Record<string, ColumnProps> = {
  comments: {
    width: '150px',
  },
  instructions: {
    ellipsis: false,
    width: '180px',
  },
}

export const OrdersIndex = () => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const branch = useBranch()
  const basePath = history.location.pathname.slice(0, match.path.indexOf('/orders') + '/orders'.length)

  const [query, setQuery] = useState<QueryOrdersArgs | undefined>(undefined)

  const { orders, setOrders, setPath } = useOrderPagesState()

  const [orderFields, setOrderFields] = useState<OrderFieldSelection[]>(() => {
    const fieldsQS = qs.parse(location.search).fields
    const fieldsValues = compact([fieldsQS].flat(1)).map((f) => f.toString())
    const fields = pickOrderFields(fieldsValues)
    return !isEmpty(fields) ? fields : DEFAULT_ORDER_FIELDS
  })

  useEffect(() => {
    if (orderFields === DEFAULT_ORDER_FIELDS) return

    const newSearch = qs.stringify({
      ...qs.parse(location.search),
      fields: orderFields.map((field) => field.value),
    })

    if (location.search !== newSearch) {
      history.push({
        search: newSearch,
      })
    }
  }, [orderFields])

  useEffect(() => {
    setPath(location.pathname + location.search)
  }, [location])

  const queryGQL = useMemo(() => {
    const pathsToNested = orderFields.reduce((collect, field) => {
      field.selection.forEach((select) => {
        setProperty(collect, select, true)
      })
      return collect
    }, { id: true } as object)

    const gqlOrderFragment = jsonToGraphQLQuery({
      'fragment OrderFragment on Order': pathsToNested,
    })

    return gql`
      ${GET_ORDERS_QUERY}
      ${gqlOrderFragment}
    `
  }, [orderFields])

  const { loading, data, error } = useQuery<Query, QueryOrdersArgs>(queryGQL, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: query === undefined,
    variables: {
      where: query?.where,
      orderBy: [
        { dateOfService: SortOrder.Desc },
        { id: SortOrder.Desc },
      ],
      take: query?.take || perPage,
      skip: query?.skip || 0,
    },
  })

  useEffect(() => {
    setOrders((previous) => uniqBy(previous.concat(data?.orders || []).reverse(), (order) => order.id).reverse())
  }, [data?.orders])

  const onFiltersChange: OrderFiltersProps['onChange'] = (_changedValues, allValues) => {
    setOrders([])

    const startDate = timeWithZone(allValues?.date?.[0]?.toISOString(), branch?.timezone)?.startOf('day')
    const endDate = timeWithZone(allValues?.date?.[1]?.toISOString(), branch?.timezone)?.endOf('day')

    setQuery((prev) => ({
      ...prev,
      skip: 0,
      where: {
        branchId: isEmpty(allValues.branchId) ? undefined : {
          in: allValues.branchId,
        },
        customerId: !allValues.customerId ? undefined : {
          equals: allValues.customerId,
        },
        contactId: isEmpty(allValues.contactId) ? undefined : {
          in: allValues.contactId,
        },
        siteId: isEmpty(allValues.siteId) ? undefined : {
          in: allValues.siteId,
        },
        status: isEmpty(allValues.status) ? undefined : {
          in: allValues.status,
        },
        tagId: isEmpty(allValues.tagId) ? undefined : {
          in: allValues.tagId,
        },
        dateOfService: {
          gte: startDate?.toISO(),
          lte: endDate?.toISO(),
        },
      },
    }))
  }

  const loadMore = () => {
    setQuery((prev) => ({
      ...prev,
      skip: (prev?.skip || 0) + perPage,
    }))
  }

  const columns = useMemo(() => (
    orderFields.map((field) => {
      const fieldProps: NonNullable<AntTableProps<Order>['columns']>[0] = {
        // eslint-disable-next-line react/no-unstable-nested-components
        title: () => (
          <ColumnTitle>{field.label}</ColumnTitle>
        ),
        ellipsis: !isFunction(field.render),
        ...COLUMN_OVERRIDES[field.value],
      }

      if (isArray(field.render)) {
        fieldProps.dataIndex = field.render
      } else {
        const asFunc = field.render
        fieldProps.render = (_, order) => asFunc(order)
      }

      return fieldProps
    })
  ), [orderFields])

  const onRow = (record: Order, _rowIndex: number | undefined) => ({
    onClick: () => {
      const rowLink = `${basePath}/${record.id}`
      history.push(rowLink)
    },
  })

  return (
    <Row wrap={false} style={{ height: '100%', width: '100%' }}>
      <Col style={{ width: '100%', height: '100%' }} flex="auto">
        <PageContent style={{ paddingTop: 0 }} components={{ Body: OrderPageBody }}>
          <Header>
            <Title level={2} style={{ paddingTop: 10 }}>
              Orders
              <OrderFieldsSelectorIcon
                dropdownMatchSelectWidth={false}
                value={orderFields}
                onChange={(val) => setOrderFields(val)}
                style={{ fontSize: '0.5em', marginLeft: '12px', verticalAlign: 'middle' }}
              />
            </Title>
          </Header>

          <Body>
            {error && (
              <ErrorMessage>
                <Paragraph>{error.message}</Paragraph>
              </ErrorMessage>
            )}

            {orderFields.length === 0 ?
              <ErrorMessage size="small" title="Must select at least one field from the gear icon above" />
              : (
                <Table
                  rowKey="id"
                  tableLayout="fixed"
                  loading={loading}
                  columns={columns}
                  dataSource={orders}
                  pagination={false}
                  sticky
                  onRow={onRow}
                  bordered
                  scroll={{
                    x: true,
                  }}
                />
              )}
          </Body>

          <Footer>
            {orders.length > 0 && orders.length % perPage === 0 &&
              <Button disabled={loading} style={{ marginTop: '20px' }} type="primary" onClick={loadMore}> Load More Orders </Button>}
          </Footer>
        </PageContent>
      </Col>
      <FiltersSidebar onChange={onFiltersChange} />
    </Row>
  )
}

const CustomRoutableDrawer = styled(RoutableDrawer)`
  z-index: 5;

  &.mobile-hidden {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: none;
    transform: none;
  }
`

const ShowFiltersButton = styled(Button).attrs((props) => ({
  ...props,
  icon: <FilterOutlined />,
}))`
  position: fixed;
  top: 18px;
  width: 42px;
  height: 42px;
  right: 0;
  z-index: 4;
`

const FiltersSidebar = (props: OrderFiltersProps) => {
  const isMobile = useIsMobile()
  const [showFilters, setShowFilters] = useState(!isMobile)
  const match = useRouteMatch()

  return (
    <>
      <CustomRoutableDrawer
        className={(isMobile && showFilters === false) ? 'mobile-hidden' : undefined}
        showClose={isMobile}
        onClose={() => { setShowFilters(false) }}
        width={300}
      >
        <Route path={match.path}>
          <Card block>
            <Card.Body>
              <OrdersFilters {...props} />
            </Card.Body>
            {
              isMobile && (
                <Card.Footer>
                  <Button type="primary" style={{ width: '100%' }} onClick={() => setShowFilters(false)}>Apply Filters</Button>
                </Card.Footer>
              )
            }
          </Card>
        </Route>
      </CustomRoutableDrawer>

      {isMobile && !showFilters && <ShowFiltersButton onClick={() => setShowFilters(!showFilters)} />}
    </>
  )
}

export default OrdersIndex
