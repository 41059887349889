import { formatPhone } from 'helpers/formatPhone'
import { wrapInParens } from 'helpers/formatting'
import { compact } from 'lodash'
import { Phone } from 'schema'
import styled from 'styled-components'

export interface PhoneListProps extends React.HTMLAttributes<HTMLUListElement> {
  phones: Array<
    Pick<Phone, 'number'> & {
      typeDetails?: Pick<Phone['typeDetails'], 'name'>
    }
  >
}

const BasicUL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const PhoneList = ({ phones, ...rest }: PhoneListProps) => (
  <BasicUL {...rest}>
    {phones?.map((phone, i) => (
      <li key={i}>
        {
          compact([
            formatPhone(phone.number) || ' ',
            (phones.length > 1 || phone.typeDetails?.name !== 'Primary') && wrapInParens(phone.typeDetails?.name),
          ]).join(' ')
        }
      </li>
    ))}
  </BasicUL>
)
