import { get, isEqual } from 'lodash'
import { ReactNode, useMemo } from 'react'
import styled from 'styled-components'

import { EquipmentSortOption, useEquipmentSort } from 'hooks/useEquipmentSort'

import { Dropdown, Menu } from 'antd'
import { DownArrowIcon, SortIcon, UpArrowIcon } from 'components/icons'
import { ResourceLabelWrapper } from './ResourceLabel'

export const OPTIONS: { sort: EquipmentSortOption[], label: ReactNode }[] = [
  {
    sort: ['type', '-size', 'name'],
    label: <>Size <DownArrowIcon /></>,
  },
  {
    sort: ['type', 'size', 'name'],
    label: <>Size <UpArrowIcon /></>,
  },
  {
    sort: ['type', '-name'],
    label: <>Name <DownArrowIcon /></>,
  },
  {
    sort: ['type', 'name'],
    label: <>Name <UpArrowIcon /></>,
  },
]

const SortMenu = styled(Menu)`
  min-width: 110px;

  .ant-dropdown-menu-name-content .anticon {
    font-size: 0.8em;
  }
`

// slow TS resolutio
// export type ResourceAreaHeaderProps = StyledComponentPropsWithRef<typeof ResourceLabelWrapper>

export const ResourceAreaHeader = styled((props: { className?: string }) => {
  const { equipmentSort, setEquipmentSort } = useEquipmentSort()

  const selectedIndex = useMemo(() => (
    OPTIONS.findIndex((item) => isEqual(item.sort, equipmentSort))?.toString()
  ), [equipmentSort])

  return (
    <Dropdown
      placement="bottomCenter"
      trigger={['click']}
      overlay={(
        <SortMenu
          selectedKeys={[selectedIndex]}
          onClick={({ key: optionIndex }) => {
            const selected = get(OPTIONS, optionIndex)
            setEquipmentSort(selected.sort)
          }}
        >
          {OPTIONS.map((option, index) => (
            <Menu.Item key={index}>
              {option.label}
            </Menu.Item>
          ))}
        </SortMenu>
      )}
    >
      <ResourceLabelWrapper {...props}>Equipment <SortIcon /></ResourceLabelWrapper>
    </Dropdown>
  )
})`
  cursor: pointer;

  .anticon {
    font-size 0.9em;
    opacity: 0.8;
  }
`
