import { byFieldAsc } from 'helpers/sortting'
import { compact } from 'lodash'
import { useMemo } from 'react'

import { useNotification } from 'hooks/useNotification'

import { gql, useMutation, useQuery } from '@apollo/client'
import {
  Equipment, Mutation, Query, QueryEquipmentArgs, SamsaraMutationNamespaceLinkVehicleArgs, SamsaraVehicle,
} from 'schema'

import { LinkExternalButton } from 'components/buttons'
import { ListSelectorOption } from 'components/common/ListSelector'
import ErrorMessage from 'components/ErrorMessage'
import { useSamsaraUrl } from 'hooks/useSamsaraUrl'

import { LinkableLists, LinkableListsProps } from 'components/common/LinkableLists'
import { RecursivePartial } from 'types'

const vehicleName = (vehicle: SamsaraVehicle) => vehicle.name || vehicle.vin || vehicle.id

// eslint-disable-next-line unused-imports/no-unused-vars
const vehicleToLabel = (
  vehicle: SamsaraVehicle & {
    matched?: ListSelectorOption['matched']
  }
): ListSelectorOption => ({
  label: vehicleName(vehicle),
  value: vehicle.id,
  matched: vehicle.matched,
})

// eslint-disable-next-line unused-imports/no-unused-vars
const equipmentToLabel = (
  equipment: Equipment & {
    matched?: ListSelectorOption['matched']
  }
): ListSelectorOption => ({
  label: compact([equipment.name, formatStatus(equipment.active)]).join(' '),
  value: equipment.id,
  matched: equipment.matched,
})

const formatStatus = (status: string | boolean | undefined | null) => {
  if (status === true) {
    return undefined
  }
  if (status === 'active') {
    return undefined
  }
  if (status === false) {
    return '(inactive)'
  }
  if (!status) {
    return undefined
  }

  return `(${status})`
}

const samsaraIdFromEquipment = (equipment: RecursivePartial<Equipment>): string | undefined => (
  equipment?.integrations?.samsara?.vehicle?.id
)

export const SamsaraEquipmentMatch = () => {
  const notification = useNotification()
  const generateSamsaraUrl = useSamsaraUrl()

  const equipmentQuery = useQuery<Query, QueryEquipmentArgs>(GET_EQUIPMENTS)
  const samsaraQuery = useQuery<Query>(GET_SAMSARA_VEHICLES)

  const [
    linkVehicle,
    linkVehicleResponse,
  ] = useMutation<Mutation, SamsaraMutationNamespaceLinkVehicleArgs>(LINK_VEHICLE_TO_EQUIPMENT)

  const loading = equipmentQuery.loading || samsaraQuery.loading || linkVehicleResponse.loading
  const error = equipmentQuery.error || samsaraQuery.error

  const samsaraVehicles = useMemo(() => (samsaraQuery?.data?.integrations?.samsara?.vehicles || []).slice().sort(byFieldAsc('name')), [samsaraQuery?.data])

  const equipment = useMemo(() => (equipmentQuery?.data?.equipment || []).slice().sort(byFieldAsc('name')), [equipmentQuery?.data])

  const equipmentIdToSamsaraId = useMemo(() => Object.fromEntries(compact(
    equipment.map((item) => [item.id, samsaraIdFromEquipment(item)])
  )), [equipment, samsaraVehicles])

  const updateMatch = async (equipmentId: number, samsaraId: string | null) => {
    const action = equipmentId ? 'update' : 'delete'

    const resp = await linkVehicle({
      variables: {
        equipment: {
          id: equipmentId,
        },
        vehicleId: samsaraId,
      },
    })

    if (resp.errors) {
      notification.error({
        message: 'Error Saving Match',
        description: resp.errors.map((err) => err.message).join('\n'),
        duration: 5,
      })
      return false
    }
    const linkedEquipment = resp?.data?.integrations?.samsara?.linkVehicle

    notification.success({
      message: action === 'update' ? 'Saved Match' : 'Deleted Match',
      description: `${action === 'update' ? 'Saved match' : 'Deleted match'} for ${linkedEquipment?.name}`,
      duration: 3,
    })
    return true
  }

  const props: LinkableListsProps<typeof equipment[0], typeof samsaraVehicles[0]> = {
    mode: 'one-to-many',
    loading,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    linked: (equipment, vehicle) => equipmentIdToSamsaraId[equipment.id] === vehicle.id,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onLink: async (equipment, vehicle) => updateMatch(equipment.id, vehicle.id),
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onDelete: async (equipment, _vehicle) => updateMatch(equipment.id, null),
    list1: {
      title: 'CreteSuite Equipment',
      resources: equipment,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      generateOption: (equipment) => equipment.name,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      renderCard: (equipment) => equipment.name,
    },
    list2: {
      title: 'Samsara Vehicles',
      resources: samsaraVehicles,
      generateOption: (vehicle) => vehicle.name || vehicle.id,
      renderCard: (vehicle) => {
        const vehicleUrl = generateSamsaraUrl(`/devices/${vehicle.id}/vehicle`)
        return (
          <>
            {vehicle.name}
            {vehicleUrl && <a href={vehicleUrl} target="_blank" rel="noreferrer"><LinkExternalButton size="small" /></a>}
          </>
        )
      },
    },
  }

  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <LinkableLists {...props} />
    </>
  )
}

const EQUIPMENT_FRAGMENT = gql`
  fragment EquipmentMatchListFields on Equipment {
    id
    name
    active
    integrations {
      samsara {
        vehicle {
          id
        }
      }
    }
  }
`

const VEHICLE_FRAGMENT = gql`
  fragment SamsaraVehicleFields on SamsaraVehicle {
    id
    name
    vin
  }
`

const GET_SAMSARA_VEHICLES = gql`
  query GetSamsaraVehicles {
    integrations {
      samsara {
        vehicles {
          ...SamsaraVehicleFields
        }
      }
    }
  }
  ${VEHICLE_FRAGMENT}
`

const GET_EQUIPMENTS = gql`
  query GetEquipmentMatchList {
    equipment {
      ...EquipmentMatchListFields
    }
  }
  ${EQUIPMENT_FRAGMENT}
`

const LINK_VEHICLE_TO_EQUIPMENT = gql`
  mutation LinkVehicleToEquipment(
    $equipment: EquipmentWhereUniqueInput!,
    $vehicleId: String
  ) {
    integrations {
      samsara {
        linkVehicle(equipment: $equipment, vehicleId: $vehicleId) {
          ...EquipmentMatchListFields
        }
      }
    }
  }
  ${EQUIPMENT_FRAGMENT}
`
