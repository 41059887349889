import React from 'react'
import {
  Address as AddressObject,
} from 'schema'

import { formatAddressLines, FormatAddressOptions } from 'helpers/formatAddress'

interface AddressProps extends FormatAddressOptions {
  address?: Partial<AddressObject> | null
}

export const Address = (props: AddressProps): JSX.Element | null => {
  const {
    address,
    ...formatOptions
  } = props

  if (!address) return null

  if (formatOptions.multiline === undefined) {
    formatOptions.multiline = true
  }

  const linesArray = formatAddressLines(address, formatOptions)

  return (
    <>
      {linesArray.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < linesArray.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  )
}
