import React, {
  useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react'

import colors from 'constants/colors'
import {
  compact, groupBy, isEmpty, isNumber, keyBy,
} from 'lodash'
import styled from 'styled-components'

import { isToday, timeWithZone } from 'helpers/datetime'
import { compactAndJoin } from 'helpers/formatting'
import { byFieldAsc, bySortField } from 'helpers/sortting'
import { dateToSlug } from 'helpers/url'
import { DateTime } from 'utils/luxon'

import { usePrevious, useUpdateEffect } from 'ahooks'
import { useOrganization } from 'hooks/useOrganization'
import { useHistory } from 'react-router-dom'

import FullCalendar, {
  CalendarOptions, EventClickArg, EventContentArg, EventInput,
} from '@fullcalendar/react'
import { ResourceApi, ResourceInput } from '@fullcalendar/resource-common'

import interactionPlugin from '@fullcalendar/interaction'
import luxonPlugin, { toLuxonDateTime as toLuxonDateTimeNoApi } from '@fullcalendar/luxon'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'

import {
  Button, Col, Row, Space, Typography,
} from 'antd'

import { NextButton, PreviousButton } from 'components/buttons'
import { CurrentBranchSelector } from 'components/common/CurrentBranchSelector'
import { EventCard } from 'components/scheduler/EventCard'

import {
  Query, QueryEventsArgs,
} from 'schema'

import {
  gql,
  NetworkStatus,
  useQuery,
} from '@apollo/client'

import { NOTE_SLIM_FRAGMENT } from 'gql/notes'
import { ORDER_SLIM_FRAGMENT } from 'gql/orders'
import { ROUTE_SLIM_FRAGMENT } from 'gql/routes'
import { TIMELINE_SLOT_WIDTH } from './constants'
import { useEditMode } from './EditModeState'
import { ResourceAreaHeader } from './ResourceAreaHeader'
import { ResourceLabel } from './ResourceLabel'
import { useSchedulerState } from './SchedulerState'
import { SchedulerToolbar } from './SchedulerToolbar'

import { useSchedulerSettings } from './hooks/useSchedulerSettings'
import { SchedulerEvents, useSchedulerEvents } from './SchedulerEventsState'
import { useSchedulerResources } from './SchedulerResourcesState'

export interface EventStatus {
  id: string
  color: string
  title: string
}

export interface EventExtendedProps {
  customer?: string
  description?: string
  statuses?: EventStatus
}

const CalendarHeader = styled.div`
  background-color: ${colors.backgroundSecondary};
  padding: 7px 15px;
  width: 100%;
  flex: 0 1 auto;

  > .ant-row {
    align-items: center;
  }
`

const CalendarBody = styled.div`
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  overflow: hidden;

  &#main-calendar {
    --fc-neutral-bg-color: ${colors.greyscale5};
    --fc-border-color: ${colors.greyscale20};

    border-left: 1px solid ${colors.borderPrimary};
    border-right: 1px solid ${colors.borderPrimary};
    border-top: 1px solid ${colors.borderPrimary};

    &.gridLoading {
      opacity: 0.6
    }

    .fc-scrollgrid {
      border: none;
    }

    .fc-scrollgrid-section-header td:last-child {
      border-right: none;
    }

    .fc-scrollgrid-section td:last-child {
      border-right: none;
    }

    &.isScrolling {
      cursor: move;
      user-select: none;
    }

    .fc-timeline-event {
      margin-top: 5px;
      margin-bottom: 4px;
      background: none;
      padding: 0;
    }

    .fc-timeline-events {
      padding: 0;
    }

    .fc-datagrid-cell-cushion {
      padding: 0;
    }

    .fc-datagrid-cell-main {
      display: flex;
      width: 100%;
    }

    .fc-h-event {
      border-width: 0;
    }

    .fc-timeline-lane .fc-timeline-bg {
      display: none;
    }

    .fc-timeline-header-row a {
      color: rgba(0, 0, 0, 0.85);
      cursor: default;
    }

    .fc-datagrid-body tbody .extra-resource-row, .fc-timeline-body tbody .extra-resource-row {
      border: 0px;
    }
    .fc-datagrid-body tbody .first-resource-row, .fc-timeline-body tbody .first-resource-row {
      border-bottom: 0px;
    }

    .fc-resource-group {
      &.resource-group-default {
        display: none;
      }

      &.resource-group-label {
        .fc-datagrid-cell-cushion {
          padding-left: 3px;
        }

        .fc-datagrid-cell-main {
          font-weight: 700;
          opacity: 0.85;
          width: auto;
          display: inline-block;
        }

        .fc-datagrid-expander {
          opacity: 0.85;
          font-size: 0.85em;
        }
      }
    }

    .fc-datagrid-cell:not(.fc-resource-group) > .fc-datagrid-cell-frame > .fc-datagrid-cell-cushion  {
      padding: 0;
      min-height: 44px;

      > .fc-datagrid-expander {
        display: none;
      }

      > .fc-icon {
        display: none;
      }
    }
  }
`

const CalendarWrap = styled.div`
  height: 100%;
  width: 100%;
  flex: auto;
  display: flex;
  flex-flow: column;
  overflow: hidden;

  &.edit-mode ${CalendarBody}#main-calendar {
    border-left: 1px solid ${colors.editing.borderPrimary};
    border-right: 1px solid ${colors.editing.borderPrimary};
    border-top: 1px solid ${colors.editing.borderPrimary};
    border-bottom: 1px solid ${colors.editing.borderPrimary};
  }
`

const GET_EVENTS = gql`
  query GetEvents(
    $where: QueryEventsWhereInput
  ) {
    events(
     where: $where
    ) {
      id
      startTime
      endTime
      active
      note {
        ...NoteFields
      }
      route {
        ...RouteFields
      }
    }
  }
  ${NOTE_SLIM_FRAGMENT}
  ${ROUTE_SLIM_FRAGMENT}
  ${ORDER_SLIM_FRAGMENT}
`

const DEFAULT_GROUP_ID = '__default_group__'

const CLICK_TO_SCROLL_CLASS_NAMES = [
  'fc-timeline-slot',
  'fc-timeline-slot-frame',
  'fc-timeline-slot-cushion',
]

const classNamesForResource = (resource: ResourceApi) => {
  const evenOddClass = resource.extendedProps.resourceIndex % 2 ? 'even-resource' : 'odd-resource'
  const firstOrExtraClass = resource.extendedProps.eventRowIndex > 0 ? 'extra-resource-row' : 'first-resource-row'
  return [evenOddClass, firstOrExtraClass]
}

// eslint-disable-next-line unused-imports/no-unused-vars
const eventsWithoutNew = (events: EventInput[]) => events.filter((evt) => evt.id !== 'new')

export const Scheduler = () => {
  const organization = useOrganization()

  const state = useSchedulerState()
  const {
    branch,
    timezone,
    selectedDate,
    setSelectedDate,
    showMinifiedView,
    inactiveEventsVisible,
    today,
  } = state

  const settings = useSchedulerSettings()

  const { resources, groups, defaultResourceId } = useSchedulerResources()

  const editMode = useEditMode()

  const history = useHistory()

  const selectedIsToday = useMemo(() => isToday(selectedDate), [selectedDate.toMillis()])
  const selectedIsSameYear = useMemo(() => today.toFormat('yyyy') === selectedDate.toFormat('yyyy'), [selectedDate.toMillis()])
  const [gridLoading, setGridLoading] = useState<boolean>(false)

  const [scroller, setScroller] = useState({
    isScrolling: false,
    elementStartX: 0,
    elementStartY: 0,
    mouseStartX: 0,
    mouseStartY: 0,
    start: 0,
    totalMove: 0,
  })

  const [eventsRaw, setEvents, { addEvent }] = useSchedulerEvents()
  const lastEvents = usePrevious(eventsRaw)

  // For some reason, fullcalendar won't rerender height AFTER `events` is set to an empty array and then to
  // populated array right after. Switching from a 0 events day to a day with events works though
  const events = (gridLoading && isEmpty(eventsRaw) && !isEmpty(lastEvents) && lastEvents) || eventsRaw

  const calendarRef = React.useRef<FullCalendar | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const timelineScrollEl = useMemo(() => {
    if (!calendarRef.current) return null
    if (!wrapperRef.current) return null

    const mainArea = wrapperRef.current?.getElementsByClassName('fc-timeline-body')[0]
    return mainArea ? mainArea.parentElement : null
  }, [calendarRef.current, wrapperRef.current])

  const calendar = () => (calendarRef.current as FullCalendar)?.getApi()
  const toLuxonDateTime = (date: Date) => toLuxonDateTimeNoApi(date, calendar())

  const navigateNext = () => calendar().next()
  const navigatePrev = () => calendar().prev()

  useUpdateEffect(() => {
    if (!calendarRef.current) return

    const interval = setInterval(() => {
      calendar().setOption('contentHeight', undefined)
    }, 33)
    const timer = setTimeout(() => {
      clearInterval(interval)
    }, 800) // matches antd timer plus interval padding

    return () => {
      if (timer) clearTimeout(timer)
      if (interval) clearInterval(interval)
    }
  }, [showMinifiedView])

  useEffect(() => {
    const cal = calendar()
    if (!cal) return
    cal.setOption('timeZone', timezone)
    cal.gotoDate(selectedDate.toMillis())
  }, [selectedDate])

  // changing events / days sometimes causes events to overlap multiple rows
  // this triggers a size recalc a few times after events are painted
  // NOTE, adding updateSize at start/0ms causes an ugly flash
  useLayoutEffect(() => {
    if (!calendarRef.current) return

    const interval = setInterval(() => {
      calendar()?.updateSize()
    }, 100)
    const timer = setTimeout(() => {
      clearInterval(interval)
    }, 500)

    return () => {
      if (timer) clearTimeout(timer)
      if (interval) clearInterval(interval)
    }
  }, [events])

  const eventsResponse = useQuery<Query, QueryEventsArgs>(GET_EVENTS, {
    fetchPolicy: 'network-only',
    pollInterval: (organization?.name?.startsWith('CreteSuite') ? 10 : 300) * 1000, // 5 mins poll for real, 10 sec for demo
    variables: {
      where: {
        branchId: branch?.id || -1,
        date: selectedDate.toISODate(),
        active: inactiveEventsVisible === true ? undefined : {
          equals: true,
        },
      },
    },
  })

  const eventClick = (evt: EventClickArg) => {
    evt.jsEvent.preventDefault()

    if (editMode.enabled) return

    if (evt.event.url) {
      history.push({
        pathname: evt.event.url,
      })
    }
  }

  useEffect(() => {
    setGridLoading(eventsResponse.networkStatus !== NetworkStatus.ready)
  }, [eventsResponse.networkStatus])

  useEffect(() => {
    const sortedEvents = (eventsResponse.data?.events || []).slice().sort((a, b) => {
      if (a.note && !b.note) return -1
      if (!a.note && b.note) return 1
      return byFieldAsc('startTime')(a, b)
    })

    setEvents(
      compact(sortedEvents.map((event) => {
        if (event?.note?.active === false) return

        const { note, route } = event
        const equipment = note?.equipment || route?.equipment
        const resourceId = equipment?.id ? `${equipment?.id}-${event.id.toString()}` : defaultResourceId

        return {
          id: event.id,
          resourceId,
          start: DateTime.fromISO(event.startTime),
          end: DateTime.fromISO(event.endTime),
          event,
          equipment,
          note,
          route,
        }
      }))
    )
  }, [eventsResponse.data?.events])

  const createEvent = (pourTime: DateTime, resource: ResourceApi) => {
    const eventSelectedDateSlug = dateToSlug(pourTime)
    let equipmentId = resource.extendedProps?.equipment?.id

    if (!isNumber(equipmentId)) {
      equipmentId = undefined
    }
    // const routeId = editMode.enabled ? resource.extendedProps?.routeId : undefined

    addEvent({
      id: 'new',
      resourceId: (resource.id || defaultResourceId),
      start: pourTime.minus({ hours: 1 }),
      end: pourTime.plus({ hours: 3 }),
    })

    history.push({
      pathname: `/branches/${branch?.id}/schedule/${eventSelectedDateSlug}/new`,
      state: {
        startTime: pourTime.toISO(),
        equipmentId,
        // routeId,
      },
    })
  }

  const dateClick: CalendarOptions['dateClick'] = (selected) => {
    if (scroller.isScrolling) return
    if (editMode.enabled) return

    const clickTime = Date.now() - scroller.start
    const shouldCreate = (clickTime < 200 || (scroller.totalMove < 10 && clickTime < 1000))

    if (shouldCreate && selected.resource) {
      const pourStart = toLuxonDateTime(selected.date)
      createEvent(pourStart, selected.resource)
    }
  }

  const datesSet: CalendarOptions['datesSet'] = (args) => {
    const start = timeWithZone(args.start.toISOString(), args.timeZone)
    setSelectedDate((prev) => {
      if (start.equals(prev)) {
        return prev
      }

      return start
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const eventContent = (eventContent: EventContentArg) => (
    <EventCard {...eventContent} selectedDate={selectedDate} minifiedView={showMinifiedView} />
  )

  const fcResources: ResourceInput[] = useMemo(() => {
    const resourcesById = groupBy(resources, 'id')
    const eventsByResourceId = groupBy(events, (evt) => evt.resourceId?.toString()?.split('-')[0] || defaultResourceId)

    if (isEmpty(resourcesById) && isEmpty(eventsByResourceId)) return []

    const collect: ResourceInput[] = resources.flatMap((resource, sort) => {
      const eventRows = eventsByResourceId[resource.id] || [{ resourceId: resource.id.toString() }]

      return eventRows.map((event, eventRowIndex) => ({
        id: event.resourceId?.toString(),
        sort,
        title: resource.name,
        group: resource.group?.key || DEFAULT_GROUP_ID,
        routeId: event?.route?.id,
        eventId: event.id,
        eventRowIndex,
        equipment: resource.equipment,
      }))
    }).sort(bySortField)

    return collect
  }, [resources, events])

  const groupsByKey = useMemo(() => keyBy(groups, 'key'), [groups])

  const options: CalendarOptions = {
    schedulerLicenseKey: '0360215894-fcs-1617194931',
    plugins: [luxonPlugin, interactionPlugin, resourceTimelinePlugin],
    headerToolbar: false,
    timeZone: timezone,
    height: '100%',
    initialView: 'resourceTimelineDay',
    // initialView: 'resourceTimelineWeek',
    // initialView: 'resourceTimeGridDay',
    // initialView: 'listWeek',
    editable: false, // TODO: change back to true and add mutation
    eventClassNames: ['custom-event'],
    resourceLaneClassNames: ({ resource }) => ['custom-resource', ...classNamesForResource(resource)],
    resourceLabelClassNames: ({ resource }) => classNamesForResource(resource),
    selectable: true,
    dayMinWidth: 100,
    slotMinWidth: TIMELINE_SLOT_WIDTH,
    initialDate: selectedDate.toMillis(),
    weekends: true,
    events: useConvertSchedulerEventsToFullCalendarEvents(events),
    resources: fcResources,
    resourceOrder: 'group,sort', // `resourceOrder` first arg must match `resourceGroupField`
    // eslint-disable-next-line react/no-unstable-nested-components
    resourceLabelContent: (args) => <ResourceLabel {...args} />,
    resourceAreaWidth: '130px',
    resourceAreaHeaderContent: <ResourceAreaHeader />,
    resourceGroupLabelClassNames: (hookProps) => (
      compact(['resource-group-label', hookProps.groupValue === DEFAULT_GROUP_ID && 'resource-group-default'])
    ),
    resourceGroupLaneClassNames: (hookProps) => (
      compact(['resource-group-lane', hookProps.groupValue === DEFAULT_GROUP_ID && 'resource-group-default'])
    ),
    resourceGroupField: 'group',
    resourceGroupLabelContent: (hookProps) => groupsByKey[hookProps.groupValue]?.name,
    stickyHeaderDates: true,
    eventOrder: 'start,-duration,allDay,title',
    nowIndicator: true,
    scrollTime: settings.defaultScrollTime,
    dragScroll: true,
    eventStartEditable: false,
    eventDurationEditable: false,
    eventInteractive: false,
    eventContent, // custom render function
    eventClick,
    dateClick,
    datesSet,
    // eventDrop: (...args) => {console.log('eventDrop', args)},
    // eventDrop: (...args) => {console.log('eventDrop', args)},
    // eventReceive: (...args) => {console.log('eventReceive', args)},
    // eventAdd: (...args) => {console.log('eventAdd', args)},
    // eventChange: (...args) => {console.log('eventChange', args)},
    // eventRemove: (...args) => {console.log('eventRemove', args)},
    // eventDragStart: (...args) => {console.log('eventDragStart', args)},
    // eventDragStop: (...args) => {console.log('eventDragStop', args)},
    // eventResizeStart: (...args) => {console.log('eventResizeStart', args)},
    // eventResizeStop: (...args) => {console.log('eventResizeStop', args)},
    // drop: (...args) => {console.log('drop', args)},
    // eventLeave: (...args) => {console.log('eventLeave', args)},
  }

  const onMouseDown = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const targetRaw = evt.target as typeof evt.currentTarget

    const scrollableClass = CLICK_TO_SCROLL_CLASS_NAMES.some((className) => targetRaw.classList.contains(className))

    if (scrollableClass) {
      setScroller((prev) => ({
        ...prev,
        isScrolling: true,
        start: Date.now(),
        mouseStartX: evt.clientX,
        mouseStartY: evt.clientY,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        elementStartX: timelineScrollEl!.scrollLeft,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        elementStartY: timelineScrollEl!.scrollTop,
        totalMove: 0,
      }))
    }
  }

  const onMouseUp = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setScroller((prev) => ({
      ...prev,
      isScrolling: false,
      totalMove: (
        Math.abs(scroller.mouseStartX - evt.clientX) +
        Math.abs(scroller.mouseStartY - evt.clientY)
      ),
    }))
  }

  const onMouseLeave = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setScroller((prev) => ({
      ...prev,
      isScrolling: false,
      totalMove: (
        Math.abs(scroller.mouseStartX - evt.clientX) +
        Math.abs(scroller.mouseStartY - evt.clientY)
      ),
    }))
  }

  const onMouseMove = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (scroller.isScrolling && timelineScrollEl) {
      const y = Math.max(0, scroller.elementStartY + scroller.mouseStartY - evt.clientY)
      const x = Math.max(0, scroller.elementStartX + scroller.mouseStartX - evt.clientX)
      timelineScrollEl.scrollTo(x, y)
    }
  }

  return (
    <CalendarWrap className={editMode.enabled ? 'edit-mode' : undefined}>
      <CalendarHeader>
        <Row gutter={16}>
          <Col>
            <Space>
              <PreviousButton
                onClick={navigatePrev}
              />
              <NextButton
                onClick={navigateNext}
              />
              <Button
                onClick={() => setSelectedDate(today)}
                style={{ fontWeight: selectedIsToday ? 'bold' : undefined }}
              >
                Today
              </Button>
            </Space>
          </Col>
          <Col flex="auto" style={{ textAlign: 'center' }}>
            <Typography.Title level={4} style={{ margin: 0 }}>
              {selectedDate.toFormat(`EEE, MMM d${selectedIsSameYear ? '' : ', yyyy'}`)}
            </Typography.Title>
          </Col>
          <Col>
            <CurrentBranchSelector placement="bottomRight" />
          </Col>
        </Row>
      </CalendarHeader>
      <SchedulerToolbar />
      <CalendarBody
        id="main-calendar"
        ref={wrapperRef}
        className={compactAndJoin(' ', [
          scroller.isScrolling ? 'isScrolling' : null,
          gridLoading ? 'gridLoading' : '',
        ])}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        <FullCalendar key={`tz-${selectedDate.offset}`} ref={calendarRef} {...options} />
      </CalendarBody>
    </CalendarWrap>
  )
}

const useConvertSchedulerEventsToFullCalendarEvents = (events: SchedulerEvents): EventInput[] => (
  useMemo(() => (
    events.map((event) => {
      const {
        id, resourceId, start, end, ...extendedProps
      } = event
      return {
        id: id.toString(),
        resourceId: resourceId?.toString(),
        start: start.toISO(),
        end: end.toISO(),
        extendedProps,
      }
    })
  ), [events])
)

// eslint-disable-next-line unused-imports/no-unused-vars
const withBlankEventToFixRenderBug = (events: EventInput[]): EventInput[] | undefined => {
  if (isEmpty(events)) {
    return [{
      id: '___render_bug_fix____',
      resourceId: undefined,
      start: '1970-01-01T00:00:00.000Z',
      end: '1970-01-01T00:00:00.000Z',
    }]
  }
  return events
}

export default Scheduler
