import { bySortField } from 'helpers/sortting'
import { compact } from 'lodash'

import { OrderStatus } from 'schema'
import { RecursivePartial } from 'types'

import { StatusBadge } from 'components/common/StatusBadge'
import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { GET_ORDER_STATUSES } from 'gql/order-statuses'

export type OrderStatusSelectProps = ReferenceSelectProps<OrderStatus> & {
  iconOnly?: boolean
}

const orderStatusOptionGenerator = (orderStatus?: RecursivePartial<OrderStatus> | null) => {
  if (!orderStatus?.slug) return

  return {
    label: <StatusBadge status={orderStatus} />,
    labelIconOnly: <StatusBadge iconOnly size="large" status={orderStatus} style={{ top: '-1px' }} />,
    title: orderStatus.name,
    value: orderStatus.slug,
  }
}

const defaults: Partial<OrderStatusSelectProps> = {
  defaultActiveFirstOption: true,
  showSearch: true,
  filterOption: (val: string, opt) => opt?.title?.toLowerCase().includes(val.toLowerCase()),
  optionsGenerator: (orderStatuses) => (
    compact([...orderStatuses].sort(bySortField).map(orderStatusOptionGenerator))
  ),
}

export const OrderStatusSelect = (props: OrderStatusSelectProps) => {
  const { iconOnly, ...rest } = props

  const args = buildPropsFromGql({
    name: 'Order Status',
    queryField: 'orderStatuses',
    gql: GET_ORDER_STATUSES,
    defaults,
    fetchPolicy: 'cache-first',
  }, rest)

  if (iconOnly) {
    args.optionLabelProp = 'labelIconOnly'
  }

  return ReferenceSelect(args)
}

export default OrderStatusSelect
