import {
  Col, Row, Table, Typography,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { DateTime } from 'luxon'
import { useContext, useEffect } from 'react'
import colors from '../../constants/colors'
import { formatAddress } from '../../helpers/formatAddress'
import { InvoiceSettingsContext } from '../../hooks/useInvoiceSettings'
import { PartialInvoice } from '../../hooks/useInvoices'
import { useOrganization } from '../../hooks/useOrganization'
import { LogoCompany } from './LogoCompany'

interface InvoiceFormProps {
  invoice?: PartialInvoice
}

interface JobDetailsColumns {
  jobDate: string,
  pourType: string,
  jobDuration: string,
  travelDuration: string,
  cubicYards: number,
}

export const InvoiceForm = (props: InvoiceFormProps) => {
  const org = useOrganization()
  const { invoice } = props
  const { settings, jobDetails, updateInvoiceJobDetails } = useContext(InvoiceSettingsContext)
  const buildText = (v: string) => {
    if (v === '') return ''
    return v.split('\n').map((s, i, slf) => (
      <>
        {s}
        {i < slf.length - 1 && <br />}
      </>
    ))
  }

  useEffect(() => {
    if (invoice) {
      updateInvoiceJobDetails({
        ...jobDetails,
        ...invoice,
        jobDate: invoice.dateOfServiceLocal ? DateTime.fromISO(invoice.dateOfServiceLocal).toJSDate() : new Date(),
        travelDurationHours: invoice?.travelDuration || 0,
        onSiteDurationHours: invoice?.jobDuration || 0,
      })
    }
  }, [invoice])

  const columns: ColumnsType<JobDetailsColumns> = [
    { title: 'Job Date', dataIndex: 'jobDate', key: 'jobDate' },
    { title: 'Pour Type', dataIndex: 'pourType', key: 'pourType' },
    { title: 'Job Duration', dataIndex: 'jobDuration', key: 'jobDuration' },
    { title: 'Travel Duration', dataIndex: 'travelDuration', key: 'travelDuration' },
    { title: 'Cubic Yards', dataIndex: 'cubicYards', key: 'cubicYards' },
  ]
  const dataTable: JobDetailsColumns[] = [
    {
      jobDate: jobDetails.jobDate.toLocaleDateString(),
      jobDuration: `${jobDetails.onSiteDurationHours} hours`,
      travelDuration: `${jobDetails.travelDurationHours} hours`,
      cubicYards: jobDetails.cubicYards,
      pourType: jobDetails.pourType || 'Slab',
    },
  ]
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{
          display: 'flex', flexGrow: 1, justifyContent: 'space-between', marginBottom: '20px',
        }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text editable={false} strong>BIG BUCK PUMPING, LLC</Typography.Text>
            <Typography.Text editable={false}>6526 Old Brick Rd 120-306<br />Windermere, FL  34786 US<br />admin@bigbuck.com<br />bigbuck.com</Typography.Text>
          </div>
          <div style={{ padding: '0 4em' }}>
            <Typography.Title style={{ lineHeight: '0.8em', fontSize: '1.7em', color: colors.greyscale85 }}>INVOICE</Typography.Title>
          </div>
          <div style={{ minWidth: '292px', paddingRight: 10, textAlign: 'center' }}>
            {org && org.logo && <LogoCompany imageSource={org.logo} height={95} />}
          </div>
        </div>
      </div>
      <Row gutter={16}>
        <Col span={8}>
          <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
            <Typography.Text strong>BILL TO</Typography.Text>
            <Typography.Text>{settings.customerName}</Typography.Text>
            <Typography.Text>{buildText(settings.billAddress)}</Typography.Text>
          </div>
        </Col>
        <Col span={8} />
        <Col span={8}>
          <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
            {invoice?.purchaseOrder ? (
              <Row gutter={16}>
                <Col span={12}>
                  <Typography.Text strong>Purchase Order: </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{invoice?.purchaseOrder}</Typography.Text>
                </Col>
              </Row>
            )
              : undefined}
            <Row gutter={16}>
              <Col span={12}>
                <Typography.Text strong>Invoice Number: </Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text>{invoice?.id || 0}</Typography.Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Typography.Text strong>Invoice Date: </Typography.Text>
              </Col>
              <Col span={12}>
                {settings.invoiceDate.toLocaleDateString()}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Typography.Text strong>Due Date: </Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text>{settings.dueDate.toLocaleDateString()}</Typography.Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Typography.Text strong>Terms: </Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text>{settings.terms}</Typography.Text>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Typography.Title level={5}>Jobsite: {invoice?.site?.address && formatAddress(invoice?.site?.address, { multiline: false })}</Typography.Title>
        {invoice?.lot && <Typography.Text strong>{invoice?.lot}</Typography.Text>}
      </div>
      <Table pagination={false} bordered={false} columns={columns} dataSource={dataTable} />
    </>
  )
}
