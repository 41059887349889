import { statesByCode } from 'constants/states'
import { Select, SelectProps } from '../inline-edit/Select'

export type StateSelectProps = Omit<SelectProps<any>, 'options' | 'children'>

export const StateSelect: React.FC<StateSelectProps> = (props) => (
  <Select
    {...props}
    showSearch={props.showSearch === undefined ? true : props.showSearch}
    value={props.value ? props.value.toUpperCase() : undefined}
    options={Object.entries(statesByCode).map(([code]) => ({ value: code, label: code }))}
  />
)
