import { Redirect, useLocation } from 'react-router-dom'

export const TO_CATCH_ALL_PATH = '/to'

// This exists as a catch all route that can be used in
// persisted data (comments, external integrations, etc)
// which could allow logic for certain routing conditions
// in the future
export const ToCatchAll = () => {
  const location = useLocation()
  const path = location.pathname.replace(TO_CATCH_ALL_PATH, '')
  return <Redirect to={path} />
}
