import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { OrderPagesStateProvider } from './OrderPagesState'
import { OrderShow } from './OrderShow'
import { OrdersIndex } from './OrdersIndex'

export const OrdersPage = (_args: any) => {
  const match = useRouteMatch()

  return (
    <OrderPagesStateProvider>
      <Switch>
        <Route path={`${match.path}/:id`} component={OrderShow} />
        <Route path={match.path} component={OrdersIndex} />
      </Switch>
    </OrderPagesStateProvider>
  )
}

export default OrdersPage
