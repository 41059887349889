import { Col } from 'antd'
import styled from 'styled-components'

export const TableCol = styled(Col) <{
  section: string
}>`
  flex: 1 1 100%;
  // max-width: 220px;

  ${(props) => props.section === 'title' && `
    max-width: 135px;
    flex: 1 0 135px;
    font-weight: bold;
    padding-top: 6px;
    padding-right: 5px;
  `}

  padding-left: 12px;

  .ant-form-item {
    margin-left: -12px;
    margin-bottom: 0;
    width: 95px;
  }
`
