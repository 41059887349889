import { useControllableValue } from 'ahooks'
import { CoordinateParser } from 'helpers/parseCoordinates'
import { isNumber, omit } from 'lodash'
import React from 'react'
import { InputNumber, InputNumberProps, InputNumberRef } from './InputNumber'

export type CoordinateInputRef = InputNumberRef

export interface CoordinateInputProps extends Omit<InputNumberProps, 'stringMode'> {
  coordinateParser: CoordinateParser
}

const deleteValueIfUndefined = (props: InputNumberProps): InputNumberProps => {
  if ('value' in props && props.value === undefined) {
    return omit(props, 'value')
  }
  return props
}

export const CoordinateInput = React.forwardRef<CoordinateInputRef, CoordinateInputProps>((props, ref) => {
  const [value, setValue] = useControllableValue<any>(deleteValueIfUndefined(props))
  const { coordinateParser, ...rest } = props

  const args: InputNumberProps = rest
  args.hideStep = args.hideStep !== undefined ? args.hideStep : true

  args.onBlur = (evt) => {
    const coordinate = coordinateParser(evt.currentTarget.value)
    if (isNumber(coordinate)) {
      setValue(coordinate)
      evt.currentTarget.value = coordinate.toString()
    }

    if (props.onBlur) {
      props.onBlur(evt)
    }
  }

  args.onPaste = (evt) => {
    if (props.onPaste) {
      props.onPaste(evt)
    }

    if (evt.defaultPrevented) return

    const pastedValue = evt?.clipboardData?.getData('text')
    if (!pastedValue) return

    const coordinate = coordinateParser(pastedValue)
    if (isNumber(coordinate)) {
      setValue(coordinate)
      evt.preventDefault()
    }
  }

  args.value = value
  args.onChange = (v) => setValue(v)

  return <InputNumber {...args} ref={ref} />
})
