import { ViewMoreDrawer } from 'components/layout/ViewMoreDrawer'
import { ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: none;
  align-items: center;

  > * {
    flex-grow: 1;
  }

  > .ant-btn {
    margin-left: 10px;
    flex-grow: 0;
  }
`

export interface WithViewMoreProps {
  drawer?: () => React.ReactNode
  children?: ReactNode | undefined
}

export const WithViewMore = (props: WithViewMoreProps): JSX.Element | null => {
  const drawer = props?.drawer?.()

  if (!props.children) {
    return null
  }

  return (
    <Wrapper>
      <div>
        {props.children}
      </div>
      {drawer && (
        <ViewMoreDrawer closeIcon={false}>
          {drawer}
        </ViewMoreDrawer>
      )}
    </Wrapper>
  )
}
