import { Collapse, CollapseProps, Panel } from '.'

type SingleCollapseProps = Omit<CollapseProps, 'activeKey'> & {
  noStyle?: boolean
  visible?: boolean
}

export const SingleCollapse = (props: SingleCollapseProps) => {
  const {
    visible, children, noStyle, ...rest
  } = props

  return (
    <Collapse activeKey={visible ? 'show' : undefined} {...rest}>
      <Panel key="show" forceRender hideHeader noGutter noStyle={noStyle}>
        {children}
      </Panel>
    </Collapse>
  )
}
