import Icon, {
  AlignLeftOutlined, CheckOutlined,
  CloseOutlined, LeftOutlined, LoadingOutlined, MinusCircleOutlined,
  PlusCircleOutlined, RightOutlined,
} from '@ant-design/icons'
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon'
import { set as setProperty } from 'lodash'
import React from 'react'

import { ReactComponent as AircallSvg } from 'assets/images/aircall-icon.svg'
import { ReactComponent as ReplySvg } from 'assets/images/arrow-undo.svg'
import { ReactComponent as ArrowSvg } from 'assets/images/arrow.svg'
import { ReactComponent as CompanyOutlineSvg } from 'assets/images/business-outline-icon.svg'
import { ReactComponent as ChatBubbleEllipsesOutlineSvg } from 'assets/images/chatbubble-ellipses-outline.svg'
import { ReactComponent as ChatBubbleOutlineSvg } from 'assets/images/chatbubble-outline.svg'
import { ReactComponent as ChatBubbleSvg } from 'assets/images/chatbubble.svg'
import { ReactComponent as ExitOutlineSvg } from 'assets/images/exit-outline.svg'
import { ReactComponent as EyeDropperSvg } from 'assets/images/eye-dropper-icon.svg'
import { ReactComponent as GeotabSvg } from 'assets/images/geotab-icon.svg'
import { ReactComponent as LinkExternalSvg } from 'assets/images/link-external-icon.svg'
import { ReactComponent as MapMarkerSvg } from 'assets/images/map-marker.svg'
import { ReactComponent as NavigateCircleSvg } from 'assets/images/navigate-circle-outline.svg'
import { ReactComponent as NavigateSvg } from 'assets/images/navigate.svg'
import { ReactComponent as QuickbooksSvg } from 'assets/images/quickbooks-icon.svg'
import { ReactComponent as SamsaraSvg } from 'assets/images/samsara-icon.svg'
import { ReactComponent as SortSvg } from 'assets/images/sort-arrows.svg'

import colors from 'constants/colors'

export * from './CommentsBubbleWithCount'
export * from './SupportIcon'

export const AddItemIcon = PlusCircleOutlined
export const RemoveItemIcon = MinusCircleOutlined
export const PreviousIcon = LeftOutlined
export const NextIcon = RightOutlined
export const LoadingIcon = LoadingOutlined
export const CloseIcon = CloseOutlined
export const NotesIcon = AlignLeftOutlined

export const ErrorIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <CloseOutlined {...props} style={{ color: colors.error, ...props.style }} ref={ref} />
))

export const AssignmentConfirmedIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <CheckOutlined {...props} style={{ color: '#168a15', ...props.style }} ref={ref} />
))

export const ChatBubbleOutlineIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={ChatBubbleOutlineSvg} />
))

export const ChatBubbleEllipsesOutlineIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={ChatBubbleEllipsesOutlineSvg} />
))

export const ChatBubbleFilledIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={ChatBubbleSvg} />
))

export const NavigateArrowIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={NavigateSvg} />
))

export const NaviateCircleIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={NavigateCircleSvg} />
))

export const NavigateIcon = NaviateCircleIcon

export const MapMarkerIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={MapMarkerSvg} />
))

export const ExitOutlineIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={ExitOutlineSvg} />
))

export const LinkExternalIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={LinkExternalSvg} />
))

export const AircallIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={AircallSvg} />
))

export const EyeDropperIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={EyeDropperSvg} />
))

export const CompanyOutlineIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={CompanyOutlineSvg} />
))

export const SortIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={SortSvg} />
))

export const DownArrowIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={ArrowSvg} />
))

export const UpArrowIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} rotate={180} component={ArrowSvg} />
))

export const QuickbooksIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => {
  const args = { ...props }
  setProperty(args, ['style', 'color'], args.style?.color || '#2ca01c')
  return <Icon {...args} ref={ref} component={QuickbooksSvg} />
})

export const SamsaraIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={SamsaraSvg} />
))

export const GeotabIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={GeotabSvg} />
))

export const ReplyIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => (
  <Icon {...props} ref={ref} component={ReplySvg} />
))
