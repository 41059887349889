import { gql } from '@apollo/client'
import { useCreation } from 'ahooks'
import { useCachedQueryFetchPolicy } from 'hooks/useCachedQueryFetchPolicy'
import { compact, isArray, isEmpty } from 'lodash'
import { DateTime, Interval } from 'luxon'
import { useEffect, useState } from 'react'
import { Branch } from 'schema'
import { useGetAvailableEquipmentQuery } from './__generated__/useAvailableEquipment'

export interface UseAvailableEquipmentArgs {
  date?: DateTime | Interval | [DateTime, DateTime]
  branchId?: Branch['id']
}

const queryWindowFromInterval = (interval: Interval) => (
  Interval.fromDateTimes(
    interval.start.startOf('week').minus({ weeks: 3 }),
    interval.end.endOf('week').plus({ weeks: 3 })
  )
)

export const useAvailableEquipment = (args: UseAvailableEquipmentArgs = {}) => {
  const { branchId } = args

  const now = useCreation(() => DateTime.utc(), [])
  const date = args.date || now
  const [startDate, endDate] = Interval.isInterval(date) ? [date.start, date.end] : isArray(date) ? date : [date, date]

  const dateWindow = useCreation(() => (
    Interval.fromDateTimes(startDate, endDate)
  ), [startDate.toSeconds(), endDate.toSeconds()])

  const [queryWindow, setQueryWindow] = useState<Interval>(queryWindowFromInterval(dateWindow))

  useEffect(() => {
    // date already in dateWindow
    if (
      queryWindow &&
      queryWindow.contains(dateWindow.start.minus({ days: 2 }).startOf('day')) &&
      queryWindow.contains(dateWindow.end.plus({ days: 2 }).startOf('day'))
    ) {
      return
    }

    setQueryWindow(queryWindowFromInterval(dateWindow))
  }, [dateWindow])

  const fetchPolicy = useCachedQueryFetchPolicy(`availableEquipment-${queryWindow.toISO()}`, { ttl: { hours: 1 } })

  const { data, previousData, loading } = useGetAvailableEquipmentQuery({
    fetchPolicy,
    variables: {
      where: {
        branchId: !branchId ? undefined : {
          equals: branchId,
        },
        validFrom: {
          lte: queryWindow.end.toISO(),
        },
        validTo: {
          gte: queryWindow.start.toISO(),
        },
      },
    },
  })

  const loadedData = data?.equipmentAvailability || previousData?.equipmentAvailability || []

  const equipment = useCreation(() => {
    if (isEmpty(loadedData)) return []

    const filtered = loadedData.map((item) => {
      const availability = item.availability.filter(({ validTo, validFrom }) => {
        if (!validTo) return true
        return dateWindow.overlaps(
          Interval.fromDateTimes(
            DateTime.fromISO(validFrom),
            DateTime.fromISO(validTo)
          )
        )
      })

      if (isEmpty(availability)) return

      return {
        ...item.equipment,
        availability,
      }
    })

    return compact(filtered)
  }, [loadedData, dateWindow])

  return {
    loading: isEmpty(loadedData) && loading,
    equipment,
  }
}

gql`
  query GetAvailableEquipment($where: QueryEquipmentAvailabilitiesWhereInput!) {
    equipmentAvailability(where: $where) {
      id
      equipment {
        id
        name
        size
        type
        sort
        active
        group {
          id
          name
          sort
          locationAware
          locationSort
        }
        documents(where: { active: { equals: true }}) {
          id
          attachments
          expirationDate
          pinned
          fields {
            key
            value
          }
          typeDetails {
            name
            slug
          }
        }
      }
      availability {
        id
        validFrom
        validTo
        address {
          id
          name
        }
      }
    }
  }
`
