import { TimePicker as AntTimePicker } from 'antd'
import { TimePickerProps as AntTimePickerProps } from 'antd/lib/time-picker'
import React, { ComponentRef } from 'react'
import styled from 'styled-components'

export type TimePickerRef = ComponentRef<typeof AntTimePicker>

export interface TimePickerProps extends AntTimePickerProps {
  editing?: boolean
}

const StyledAntTimePicker = styled(AntTimePicker)`
  &.read-only,
  &.read-only input
  {
    border-color: transparent;
    background-color: transparent;
    outline-color: transparent;
    box-shadow: none;
  }
`

export const TimePicker = React.forwardRef<TimePickerRef, TimePickerProps>((props, ref) => {
  const { editing, ...args } = props
  if (editing === false) {
    args.className = `${args.className} read-only`
    args.inputReadOnly = true
    args.open = false
    args.suffixIcon = null
    args.allowClear = false
  }
  if (args.autoComplete === undefined) {
    args.autoComplete = 'off'
  }
  return <StyledAntTimePicker data-lpignore="true" {...args} ref={ref} />
})

export default TimePicker
