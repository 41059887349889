import { compact } from 'lodash'
import styled from 'styled-components'

import { useFormState } from 'components/form/OrderForm/OrderFormState'
import { useIsMobile } from 'hooks/useViewportMode'

import { Button, Col, Row } from 'antd'

import { RemoveItemButton } from 'components/buttons'
import { Panel } from 'components/common/Collapse/Panel'
import { AlignWithInputs, formLayout } from 'components/form/common/Layout'
import { Form } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'

import { Checkbox } from 'components/form/inline-edit/Checkbox'
import { ContactRoleSelect } from 'components/form/reference-selects/ContactRoleSelect'
import { ContactSelectWithAdd } from 'components/form/reference-selects/ContactSelectWithAdd'
import { CustomerSelectWithAdd } from 'components/form/reference-selects/CustomerSelectWithAdd'
import { SiteSelectWithAdd } from 'components/form/reference-selects/SiteSelectWithAdd'

import { ExternalNavigate } from 'components/common/ExternalNavigate'
import { NavigateIcon } from 'components/icons'
import { OrderFormPanelProps } from './PanelProps'

export const CustomerPanel = (props: OrderFormPanelProps): JSX.Element => {
  const {
    editing,
    order,
    selectedCustomer,
  } = useFormState()

  const isInitialCustomer = order?.customer?.id === selectedCustomer?.id

  return (
    <Panel {...props} header="Customer Info">
      <FormItem
        label="Customer"
        name={['order', 'customerId']}
        rules={[
          {
            required: true,
            message: 'Customer is required',
          },
        ]}
      >
        <CustomerSelectWithAdd
          allowClear
          editing={editing}
          resources={compact([order?.customer])}
        />
      </FormItem>

      <Form.List
        name={['order', 'contacts']}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Row key={field.key} wrap={false}>
                <Col flex="auto">
                  <Row wrap={false}>
                    {index > 0 && (
                      <Col {...formLayout.labelCol} style={{ ...formLayout?.labelCol?.style, overflow: 'hidden' }}>
                        <Form.Item
                          listPrefix={field}
                          name="roleId"
                        >
                          <ContactRoleSelect
                            editing={editing}
                            resources={compact([order?.contacts?.[index]?.role])}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col {...(index > 0 ? formLayout.wrapperCol : { flex: 'auto' })}>
                      <FormItem
                        listPrefix={field}
                        name="contactId"
                        label={index === 0 && 'Contact'}
                      >
                        <ContactSelectWithAdd
                          allowClear={fields.length <= 1}
                          editing={editing}
                          customerId={selectedCustomer?.id}
                          resources={isInitialCustomer ? compact([order?.contacts?.[index]?.contact]) : []}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <FormItem
                    listPrefix={field}
                    name="default"
                    hidden
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </FormItem>
                </Col>
                <Col flex="none">
                  {index > 0 && editing &&
                    <RemoveItemButton onClick={() => { remove(index) }} />}
                </Col>
              </Row>
            ))}
            <AlignWithInputs>
              {editing &&
                <Button onClick={() => { add() }}>Add Additional Contact</Button>}
            </AlignWithInputs>
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
      <FormItem
        label={(
          <>
            Site <NavigateToSelectedSiteButton />
          </>
        )}
        name={['order', 'siteId']}
        rules={[
          {
            required: true,
            message: 'Site is required',
          },
        ]}
      >
        <SiteSelectWithAdd
          allowClear
          editing={editing}
          resources={isInitialCustomer ? compact([order?.site]) : []}
          customerId={selectedCustomer?.id}
        />
      </FormItem>
    </Panel>
  )
}

const NavigateToSelectedSiteButton = styled(({ className }: { className?: string }) => {
  const isMobile = useIsMobile()
  const { selectedCustomer, selectedSite } = useFormState()

  if (!isMobile) return null
  if (!selectedCustomer?.id) return null
  if (!selectedSite?.id) return null

  return (
    <ExternalNavigate
      className={className}
      customerId={selectedCustomer?.id}
      siteId={selectedSite?.id}
      origin={null}
      providerSelectEnabled
    >
      <NavigateIcon />
    </ExternalNavigate>
  )
})`
  margin-top: 1px;
  padding: 14px;
  font-size: 1.3em;
  color: inherit !important;
`
