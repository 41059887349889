import { FormListFieldData } from 'antd/lib/form/FormList'
import { AddItemButton, Button } from 'components/buttons'
import { Collapse, Panel } from 'components/common/Collapse'
import { Footnote } from 'components/common/Footnote'
import { CredentialFormItem } from 'components/form/common/CredentialFormList/CredentialFormItem'
import { Form, FormListProps, useCurrentForm } from 'components/form/Form'
import { groupBy } from 'lodash'
import { getNamePath } from 'rc-field-form/es/utils/valueUtil'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { SetRequired } from 'type-fest'

interface CredentialFormListOptions {
  editing?: boolean
}

interface CredentialsFormListProps extends Omit<FormListProps, 'name' | 'children'>, CredentialFormListOptions {
  name?: FormListProps['name']
}

const InactiveSection = styled(({ children, className }: React.PropsWithChildren<{ className?: string }>) => {
  const [showInactive, setShowInactive] = useState(false)

  return (
    <>
      <Collapse ghost activeKey={showInactive ? 'inactive' : undefined} className={className}>
        <Panel key="inactive" forceRender hideHeader noGutter>
          {children}
        </Panel>
      </Collapse>
      <div style={{ textAlign: 'center', marginTop: 3 }}>
        <Button
          type="plain"
          size="small"
          onClick={() => setShowInactive((last) => !last)}
        >
          {showInactive ? 'Hide' : 'Show'} inactive
        </Button>
      </div>
    </>
  )
})`
  > .ant-collapse-item {
    margin-top: 0;


    > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
`

const FormListFields = (props: SetRequired<CredentialsFormListProps, 'name'> & { listProps: Parameters<FormListProps['children']> }) => {
  const {
    name,
    editing,
    listProps,
  } = props

  const path = getNamePath(name)
  const form = useCurrentForm()
  const [fields, { add, remove }, { errors }] = listProps

  const byActiveStatus = useMemo(() => {
    const sorted = fields.sort((a, b) => {
      const aType = form.getFieldValue([...path, a.name, 'type'])
      const bType = form.getFieldValue([...path, b.name, 'type'])
      return aType <= bType ? -1 : 1
    })

    return groupBy(sorted, (field) => form.getFieldValue([...path, field.name, 'active']))
  }, [form, path, fields])

  const renderFormItem = (field: FormListFieldData) => (
    <CredentialFormItem
      key={field.key}
      listPrefix={field}
      name={[...path, field.name]}
      onRemove={() => remove(field.name)}
      editing={editing}
    />
  )

  return (
    <>
      <Form.ErrorList errors={errors} />

      {byActiveStatus.true?.map(renderFormItem)}

      {byActiveStatus.false?.length && (
        <InactiveSection>
          {byActiveStatus.false.map(renderFormItem)}
        </InactiveSection>
      )}

      {
        fields.length === 0 &&
        <Footnote block>No credentials exist for this Operator yet</Footnote>
      }

      {editing && (
        <AddItemButton
          type="default"
          size="small"
          onClick={() => add({
            _isNew: true,
            active: true,
          })}
          style={{ marginTop: 20 }}
        >
          Add New Credential
        </AddItemButton>
      )}
    </>
  )
}

export const CredentialsFormList = (props: CredentialsFormListProps) => {
  const name = props.name || 'credentials'
  const args = { ...props, name }

  return (
    <Form.List
      {...args}
    >
      {(...listProps) => <FormListFields {...args} listProps={listProps} />}
    </Form.List>
  )
}
