import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { GET_POUR_TYPES } from 'gql/pour-types'
import { pourTypesToTree } from 'helpers/pourTypes'
import { compact } from 'lodash'
import React from 'react'
import { PourType } from 'schema'

export type PourTypeSelectProps = ReferenceSelectProps<PourType>

const defaults: Partial<PourTypeSelectProps> = {
  showSearch: true,
  allowClear: true,
}

export const PourTypeSelect: React.FC<PourTypeSelectProps> = (props) => {
  const {
    optionsGenerator,
    ...rest
  } = buildPropsFromGql({
    name: 'Pour Type',
    queryField: 'pourTypes',
    gql: GET_POUR_TYPES,
    defaults,
  }, props)

  const generator: PourTypeSelectProps['optionsGenerator'] = (items) => {
    const withOptionProps = compact(items.map((item) => {
      if (item.id === undefined) return
      if (item.name === undefined) return

      if (item.isCategory) {
        return {
          ...item,
          key: item.id,
          label: item.name,
          options: [],
        }
      }
      return {
        ...item,
        key: item.id,
        label: item.name,
        value: item.id,
      }
    }))

    const asTree = pourTypesToTree(withOptionProps as any, 'options') as any

    if (optionsGenerator) {
      return optionsGenerator(asTree)
    }

    return asTree
  }

  return (
    <ReferenceSelect
      {...rest}
      optionsGenerator={generator}
    />
  )
}

export default PourTypeSelect
