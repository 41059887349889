import { Col, FormItemProps, Row } from 'antd'
import { AddItemButton, RemoveItemButton } from 'components/buttons'
import { PhoneNumberInput } from 'components/form/common/PhoneNumberInput'
import { Form, FormListProps } from 'components/form/Form'
import { FormItem } from 'components/form/FormItem'
import { Checkbox } from 'components/form/inline-edit/Checkbox'
import { PhoneTypeSelect } from 'components/form/reference-selects/PhoneTypeSelect'
import { useEffect } from 'react'

interface PhoneFormListOptions {
  editing?: boolean
  singleMode?: boolean
  hideType?: boolean
}

interface PhonesFormListProps extends Omit<FormListProps, 'name' | 'children'>, PhoneFormListOptions {
  name?: FormListProps['name']
  label?: FormItemProps['label']
}

interface FormListFieldsProps extends PhoneFormListOptions {
  label?: FormItemProps['label']
  listProps: Parameters<FormListProps['children']>
}

const FormListFields = (props: FormListFieldsProps) => {
  const {
    editing, singleMode, hideType, listProps, label,
  } = props
  const [fields, { add, remove }, { errors }] = listProps

  const showAdd = !singleMode
  const showRemove = !singleMode
  const showType = !hideType

  useEffect(() => {
    if (fields.length === 0) {
      add()
    }
  }, [fields])

  return (
    <>
      <Form.ErrorList errors={errors} />
      {fields.map((field, index) => (
        <FormItem
          key={field.key}
          label={label || 'Phone Number'}
          style={{ marginBottom: 0 }}
        >
          <Row gutter={5}>
            <Col style={{ flex: '1 1 145px', minWidth: '145px' }}>
              <FormItem
                listPrefix={field}
                rules={[
                  {
                    len: 10,
                    message: 'Phone number must be exactly 10 digits',
                  },
                ]}
                validateTrigger={['onBlur']}
                name="number"
              >
                <PhoneNumberInput
                  clickToCall
                  placeholder="Phone Number"
                  editing={editing}
                />
              </FormItem>
            </Col>
            {showType && (
              <Col style={{ flex: '1 1 auto' }}>
                <FormItem
                  listPrefix={field}
                  name="type"
                >
                  <PhoneTypeSelect editing={editing} defaultActiveFirstOption={editing} placeholder={false} />
                </FormItem>
                <FormItem
                  listPrefix={field}
                  label="Send Texts"
                  name="sms"
                  valuePropName="checked"
                  hidden
                >
                  <Checkbox />
                </FormItem>
              </Col>
            )}
            {showRemove && (
              <Col style={{ flex: '0 0 auto', visibility: editing ? 'visible' : 'hidden' }}>
                <RemoveItemButton onClick={() => { remove(index) }} />
              </Col>
            )}
          </Row>
        </FormItem>
      ))}
      {editing && showAdd && (
        <FormItem
          label=" "
          colon={false}
        >
          <AddItemButton
            type="default"
            onClick={() => {
              add({
                type: 'primary',
              })
            }}
          >
            Add Phone Number
          </AddItemButton>
        </FormItem>
      )}
    </>
  )
}

export const PhonesFormList = (props: PhonesFormListProps) => {
  const {
    name,
    editing,
    singleMode,
    hideType,
    label,
    ...args
  } = props

  const options = {
    editing, label, singleMode, hideType,
  }

  return (
    <Form.List
      name={name || 'phones'}
      {...args}
    >
      {(...listProps) => <FormListFields {...options} listProps={listProps} />}
    </Form.List>
  )
}
