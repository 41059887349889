import React from 'react'
import { InputNumber, InputNumberProps, InputNumberRef } from './InputNumber'

type CurrencyInputProps = InputNumberProps<number>

const defaults: Partial<CurrencyInputProps> = {
  prefix: '$',
}

export const CurrencyInput = React.forwardRef<InputNumberRef, CurrencyInputProps>((props, ref) => {
  const args = { ...defaults, ...props }
  return <InputNumber {...args} ref={ref} />
})

export default CurrencyInput
