import { byFieldAsc, byPersonNameAsc } from 'helpers/sortting'
import { compact } from 'lodash'
import { useMemo } from 'react'

import { useNotification } from 'hooks/useNotification'

import { gql, useMutation, useQuery } from '@apollo/client'
import {
  Mutation, Operator, Query, QueryOperatorsArgs, SamsaraDriver, SamsaraMutationNamespaceLinkDriverArgs,
} from 'schema'

import { LinkableLists, LinkableListsProps } from 'components/common/LinkableLists'
import ErrorMessage from 'components/ErrorMessage'
import { RecursivePartial } from 'types'

const driverToLabel = (
  driver: SamsaraDriver
): string => (
  compact([driver.name, formatStatus(driver.driverActivationStatus)]).join(' ')
)

const operatorToLabel = (
  operator: Operator
): string => (
  compact([operator.firstName, operator.lastName, formatStatus(operator.active)]).join(' ')
)

const formatStatus = (status: string | boolean | undefined | null) => {
  if (status === true) {
    return undefined
  }
  if (status === 'active') {
    return undefined
  }
  if (status === false) {
    return '(inactive)'
  }
  if (!status) {
    return undefined
  }

  return `(${status})`
}

const samsaraIdFromOperator = (operator: RecursivePartial<Operator>): string | undefined => (
  operator?.integrations?.samsara?.driver?.id
)

export const SamsaraOperatorsMatch = () => {
  const notification = useNotification()

  const operatorsQuery = useQuery<Query, QueryOperatorsArgs>(GET_OPERATORS)
  const samsaraQuery = useQuery<Query>(GET_SAMSARA_DRIVERS)

  const [
    linkDriver,
    linkDriverResponse,
  ] = useMutation<Mutation, SamsaraMutationNamespaceLinkDriverArgs>(LINK_DRIVER_TO_OPERATOR)

  const loading = operatorsQuery.loading || samsaraQuery.loading || linkDriverResponse.loading
  const error = operatorsQuery.error || samsaraQuery.error

  const samsaraDrivers = useMemo(() => (samsaraQuery?.data?.integrations?.samsara?.drivers || []).slice().sort(byFieldAsc('name')), [samsaraQuery?.data])

  const operators = useMemo(() => (operatorsQuery?.data?.operators || []).slice().sort(byPersonNameAsc), [operatorsQuery?.data])

  const operatorIdToSamsaraId = useMemo(() => Object.fromEntries(compact(
    operators.map((item) => [item.id, samsaraIdFromOperator(item)])
  )), [operators, samsaraDrivers])

  const updateMatch = async (operatorId: number, samsaraId: string | null) => {
    const action = operatorId ? 'update' : 'delete'

    const resp = await linkDriver({
      variables: {
        driverId: samsaraId,
        operator: {
          id: operatorId,
        },
      },
    })

    if (resp.errors) {
      notification.error({
        message: 'Error Saving Match',
        description: resp.errors.map((err) => err.message).join('\n'),
        duration: 5,
      })
      return false
    }
    const operator = resp?.data?.integrations?.samsara?.linkDriver

    notification.success({
      message: action === 'update' ? 'Saved Match' : 'Deleted Match',
      description: `${action === 'update' ? 'Saved match' : 'Deleted match'} for ${operator && operatorToLabel(operator)}`,
      duration: 3,
    })
    return true
  }

  const props: LinkableListsProps<typeof operators[0], typeof samsaraDrivers[0]> = {
    mode: 'one-to-one',
    loading,
    linked: (operator, driver) => operatorIdToSamsaraId[operator.id] === driver.id,
    onLink: async (operator, driver) => updateMatch(operator.id, driver.id),
    onDelete: async (operator, _driver) => updateMatch(operator.id, null),
    list1: {
      title: 'CreteSuite Operator',
      resources: operators,
      generateOption: (operator) => operatorToLabel(operator),
      renderCard: (operator) => operatorToLabel(operator),
    },
    list2: {
      title: 'Samsara Drivers',
      resources: samsaraDrivers,
      generateOption: (driver) => driverToLabel(driver),
      renderCard: (driver) => driverToLabel(driver),
    },
  }

  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <LinkableLists {...props} />
    </>
  )
}

const DRIVER_FRAGMENT = gql`
  fragment SamsaraDriverFields on SamsaraDriver {
    id
    name
    driverActivationStatus
  }
`

const GET_SAMSARA_DRIVERS = gql`
  query GetSamsaraDrivers {
    integrations {
      samsara {
        drivers {
          ...SamsaraDriverFields
        }
      }
    }
  }
  ${DRIVER_FRAGMENT}
`

const OPERATOR_FRAGMENT = gql`
  fragment OperatorMatchListFields on Operator {
    id
    firstName
    lastName
    active
    integrations {
      samsara {
        driver {
          id
        }
      }
    }
  }
`

const GET_OPERATORS = gql`
  query GetOperatorsMatchList {
    operators {
      ...OperatorMatchListFields
    }
  }
  ${OPERATOR_FRAGMENT}
`

const LINK_DRIVER_TO_OPERATOR = gql`
  mutation LinkDriverToOperator(
    $operator: OperatorWhereUniqueInput!,
    $driverId: String
  ) {
    integrations {
      samsara {
        linkDriver(operator: $operator, driverId: $driverId) {
          ...OperatorMatchListFields
        }
      }
    }
  }
  ${OPERATOR_FRAGMENT}
`
