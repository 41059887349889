import {
  Typography,
} from 'antd'
import styled from 'styled-components'
import { formatPhone } from '../../helpers/formatPhone'
import { ContactMutationsResponseWarnings } from '../../schema'
import { NotificationBanner } from '../NotificationBanner'

const { Paragraph } = Typography

const NotificationBannerStyled = styled(NotificationBanner)`
  margin-bottom: 20px;
`

interface ContactInvalidNotificationProps {
  warnings: ContactMutationsResponseWarnings
  onClose?: () => void
}

export const ContactInvalidNotification = (props: ContactInvalidNotificationProps) => {
  const { onClose, warnings: { landlinePhones, invalidPhones } } = props

  if (landlinePhones.length === 0 && invalidPhones.length === 0) return null

  return (
    <NotificationBannerStyled
      closable
      type='warning'
      onClose={onClose}
      description={(
        <>
          <Paragraph>The following numbers won&apos;t receive SMS confirmations. We recommended changing them. If you want to continue, just ignore this message.</Paragraph>
          {landlinePhones.length > 0 && <Paragraph>Landline numbers: <br /><ul>{landlinePhones.map((i) => (<li>{formatPhone(i)}</li>))}</ul></Paragraph>}
          {invalidPhones.length > 0 && <Paragraph>Invalid numbers: <br /><ul>{invalidPhones.map((i) => (<li>{formatPhone(i)}</li>))}</ul></Paragraph>}
        </>
      )}
    />
  )
}
