import colors from 'constants/colors'
import styled from 'styled-components'

import { useEffect, useRef, useState } from 'react'
import { useEditMode } from '../EditModeState'

import { MoveOrderToRoute } from './MoveOrderToRoute'

import { ToolbarButton } from './ToolbarButton'

const ToolbarWrap = styled.div`
  display: flex;
  padding: 7px 15px;
  max-height: min-content;

  background: ${colors.editing.backgroundPrimary};
  color: ${colors.editing.fontPrimary};

  .ant-btn.ant-btn-background-ghost {
    color: ${colors.editing.fontPrimary};
    border-color: ${colors.editing.fontPrimary};

    &:hover, &:focus, &:active {
      border-color: rgba(255, 255, 255, 0.5);
      background: rgba(255, 255, 255, 0.05);
    }
  }
`

const ToolbarPositioner = styled.div`
  width: 100%;
  z-index: 1;
  flex: 1 0 auto;
  overflow: hidden;

  max-height: 0;
  transition: max-height 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

  ${ToolbarWrap} {
    > * {
      opacity: 0;
      transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    }
  }

  &.visible {
    max-height: 500px;

    ${ToolbarWrap} {
      > * {
        opacity: 1;
      }
    }
  }
`

const Main = styled.div`
  flex: 1 1 auto;
  display: flex;
`

const CancelButtons = styled.div`
  margin-left: 10px;
  flex: 0 0 auto;
`

export const SchedulerToolbar = (): JSX.Element | null => {
  const { enabled, mode, setEditMode } = useEditMode()
  const [componentKey, setComponentKey] = useState(Date.now())
  const ModeComponentRef = useRef(() => <></>)

  useEffect(() => {
    const newComponent = mode === 'move-order-to-route' ?
      MoveOrderToRoute
      : mode === 'selector' ?
        () => <></>
        : undefined

    if (newComponent) {
      ModeComponentRef.current = newComponent
      setComponentKey(Date.now())
    }
  }, [mode])

  const ModeComponent = ModeComponentRef.current

  return (
    <ToolbarPositioner className={enabled ? 'visible' : ''}>
      <ToolbarWrap>
        <Main>
          <ModeComponent key={componentKey} />
        </Main>
        <CancelButtons>
          <ToolbarButton onClick={() => setEditMode(false)}>Cancel</ToolbarButton>
        </CancelButtons>
      </ToolbarWrap>
    </ToolbarPositioner>
  )
}
