import colors from 'constants/colors'
import styled from 'styled-components'

import { Layout as AntLayout } from 'antd'
import { SiderProps as AntSiderProps } from 'antd/lib/layout/Sider'
import { useIsMobile } from 'hooks/useViewportMode'
import { compact, isBoolean } from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const SiderStyled = styled(AntLayout.Sider)`
  height: 100%;
  background-color: ${colors.backgroundSecondary};

  &.position-left {
    border-right: 1px solid ${colors.borderPrimary};
  }

  &.position-right {
    border-left: 1px solid ${colors.borderPrimary};
  }

  .ant-layout-sider-trigger {
    background-color: ${colors.backgroundSecondary};
  }

  &.position-left .ant-layout-sider-trigger {
    margin-left: -1px;
    padding-left: 1px;
  }

  &.position-right .ant-layout-sider-trigger {
    margin-right: -1px;
    padding-right: 1px;
  }

  .scrollable {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
  }

  &.ant-layout-sider-zero-width {
    border-left: none;
    border-right: none;
  }

  .ant-layout-sider-zero-width-trigger {
    top: 18px;
    background: ${colors.backgroundSecondary};
    border: 1px solid ${colors.borderPrimary};
    border-left: none;
  }
`

export interface SiderProps extends AntSiderProps {
  localStorageKey?: string
  position?: 'left' | 'right'
}

export const CollapsibleSider: typeof CollapsibleSiderInner = (props) => {
  const isMobile = useIsMobile()

  return isMobile ?
    <CollapsibleSiderMobile {...props} />
    :
    <CollapsibleSiderInner {...props} />
}

const CollapsibleSiderMobile: typeof CollapsibleSiderInner = (props) => {
  const [collapsed, setCollapsed] = useState(
    firstBoolean(props.collapsed, props.defaultCollapsed, true)
  )

  useEffect(() => {
    if (isBoolean(props.collapsed)) {
      setCollapsed(props.collapsed)
    }
  }, [props.collapsed])

  const location = useLocation()

  const args: SiderProps = {
    ...props,
    collapsed,
    collapsedWidth: 0,
    localStorageKey: undefined,
    onCollapse: (value, collapseType) => {
      setCollapsed(value)
      if (props.onCollapse) {
        props.onCollapse(value, collapseType)
      }
    },
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCollapsed(true)
    }, 5)
    return () => clearTimeout(timeout)
  }, [location.pathname])

  return <CollapsibleSiderInner {...args} />
}

const firstBoolean = (...args: any[]): boolean => {
  for (const arg of args) {
    if (isBoolean(arg)) {
      return arg
    }
  }

  return Boolean(args[args.length - 1])
}

const CollapsibleSiderInner: React.FC<SiderProps> = (props) => {
  const {
    children, localStorageKey, position: positionInput, ...rest
  } = props
  const args: AntSiderProps = rest

  const localStorageValue = localStorageKey && localStorage.getItem(localStorageKey) === 'true'

  const [collapsed, setCollapsed] = useState(
    firstBoolean(args.collapsed, localStorageValue, args.defaultCollapsed, false)
  )

  useEffect(() => {
    if (isBoolean(args.collapsed)) {
      setCollapsed(args.collapsed)
    }
  }, [args.collapsed])

  const onCollapse: SiderProps['onCollapse'] = (value, collapseType) => {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, value ? 'true' : 'false')
    }
    setCollapsed(value)

    if (args.onCollapse) {
      args.onCollapse(value, collapseType)
    }
  }

  const position: SiderProps['position'] = positionInput || 'left'
  if (args.reverseArrow === undefined) {
    args.reverseArrow = position === 'right'
  }

  args.className = compact([args.className, `position-${position}`]).join(' ')

  return (
    <SiderStyled
      theme="light"
      width={150}
      collapsedWidth={70}
      collapsible
      collapsed={collapsed}
      {...args}
      onCollapse={onCollapse}
    >
      <div className="scrollable">
        {children}
      </div>
    </SiderStyled>
  )
}
