import React, { useContext, useState } from 'react'

import { createTeleporter, TeleporterType } from 'react-teleporter'

export type ContentSection = 'Header' | 'Actions' | 'Body' | 'Footer'

type ContentContextState = Partial<Record<ContentSection, TeleporterType>>

export const ContentContext = React.createContext<ContentContextState>({})

export const useContentLayoutState = () => {
  const [Header] = useState(createTeleporter())
  const [Actions] = useState(createTeleporter())
  const [Body] = useState(createTeleporter())
  const [Footer] = useState(createTeleporter())

  return {
    Header,
    Actions,
    Body,
    Footer,
  }
}

const teleporterSource = (name: keyof ContentContextState) => (
  ({ children, hideIfOrphan }: React.PropsWithChildren<{ hideIfOrphan?: boolean }>) => {
    const Section = useContext(ContentContext)[name]

    if (!Section?.Target) {
      if (hideIfOrphan) return null
      return <>{children}</>
    }

    return <Section.Source>{children}</Section.Source>
  }
)

export const Header = teleporterSource('Header')
export const Body = teleporterSource('Body')
export const Actions = teleporterSource('Actions')
export const Footer = teleporterSource('Footer')
