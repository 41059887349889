import { gql } from '@apollo/client'
import { ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { byFieldAsc } from 'helpers/sortting'
import { useAvailableEquipment, UseAvailableEquipmentArgs } from 'hooks/data/useAvailableEquipment'
import { useBranch } from 'hooks/useBranch'
import { useEquipmentSort } from 'hooks/useEquipmentSort'
import { compact, groupBy, uniqBy } from 'lodash'
import React, { useMemo } from 'react'
import { Equipment } from 'schema'

export type EquipmentSelectProps = ReferenceSelectProps<Equipment> & {
  date?: UseAvailableEquipmentArgs['date']
}

export const GET_EQUIPMENT = gql`
  query GetEquipment($where: QueryEquipmentWhereInput) {
    equipment(where: $where) {
      id
      displayName
      name
      type
      size
    }
  }
`

export const EquipmentSelect: React.FC<EquipmentSelectProps> = (props) => {
  const branch = useBranch()
  const { date, resources, ...rest } = props
  const { sortEquipment } = useEquipmentSort()
  const { equipment, loading } = useAvailableEquipment({
    date,
    branchId: branch?.id,
  })

  const remoteResources = useMemo(() => {
    const uniqueEquipment = uniqBy([...equipment, ...(resources || [])], 'id')

    return sortEquipment(
      uniqueEquipment.map((item) => {
        const group = item?.group || null

        if (!group) return { ...item, group: null }

        const location = 'availability' in item ? item.availability[0]?.address : undefined

        let locationSort = (group.locationSort || []).indexOf(location?.id || -1).toString()
        if (locationSort === '-1') {
          locationSort = 'x'
        }

        const newItem = {
          ...item,
          group: {
            ...group,
            key: `${group.sort}-${locationSort}-${group.name}-${group.id}`,
          },
        }

        if (group.locationAware && location?.name) {
          newItem.group.name = compact([group.name, location.name]).join(' - ')
          newItem.group.key = `${newItem.group.key}-${location.name}`
        }

        return newItem
      })
    )
  }, [equipment, resources, sortEquipment])

  const selectArgs: ReferenceSelectProps<typeof remoteResources[0]> = {
    remoteResources,
    placeholder: 'Select Equipment',
    showSearch: true,
    ...rest,
    loading: loading || props.loading,
    optionsGenerator: (items) => {
      const groups = uniqBy(
        compact(items.map((item) => item?.group)),
        (group) => group.key
      ).sort(byFieldAsc('key'))

      const byGroupKey = groupBy(items, ({ group }) => group?.key)

      const ungroupedResources = byGroupKey[undefined as any] || []

      return [
        ...ungroupedResources.map((item) => ({
          ...item,
          key: item.id,
          label: item.name,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          value: item.id!,
        })),
        ...groups.map((group) => ({
          key: group.key,
          label: group.name,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          options: byGroupKey[group.key!]?.map((item) => ({
            ...item,
            key: item.id,
            label: item.name,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value: item.id!,
          })),
        })),
      ]
    },
  }

  return ReferenceSelect(selectArgs)
}

export default EquipmentSelect
