import { compact } from 'lodash'
import { Address } from 'schema'
import { compactAndJoin } from './formatting'

export interface FormatAddressOptions {
  multiline?: boolean
  includeStreet2?: boolean
}

export const formatAddressLines = (address: Partial<Omit<Address, '__typename'>>, opts: FormatAddressOptions = {}) => {
  const line1 = compactAndJoin(' ', [
    address.street,
    opts.includeStreet2 ? address.street2 : null,
  ])

  const line2 = compactAndJoin(', ', [
    address.city,
    compactAndJoin(' ', [
      address.state,
      address.zip,
    ]),
  ])

  return compact(
    opts.multiline ?
      compact([line1, line2]) :
      [compactAndJoin(', ', [line1, line2])]
  )
}

export const formatAddress = (address: Partial<Omit<Address, '__typename'>>, opts: FormatAddressOptions = {}) => formatAddressLines(address, opts).join('\n')
