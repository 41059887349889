import Title from 'antd/lib/typography/Title'
import { ComponentProps } from 'react'
import styled from 'styled-components'
import { LoadingIcon as LoadingIconRaw } from './icons'

const LoadingIcon = styled(LoadingIconRaw)`
  font-size: 0.8em;
  margin-left: 10px;
`

export const Loading = (props: ComponentProps<typeof Title>) => <Title {...props}>Loading <LoadingIcon /> </Title>

export default Loading
