import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import { Layout as ADLayout } from 'antd'
import { useIsMobile } from 'hooks/useViewportMode'
import { LayoutProps } from './LayoutProps'
import { PrimarySider } from './PrimarySider'

const LayoutStyled = styled(ADLayout)`
  background-color: white;
  height: 100%;
  width: 100%;
`

type ContentProps = ComponentProps<typeof ADLayout.Content> & {
  isMobile?: boolean
}

const Content = styled(ADLayout.Content) <ContentProps>`
  display: flex;
  max-height: 100vh;
  overflow: auto;
  padding: 0;
  margin: 0;

  ${(props) => props.isMobile && `
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
  `}
`

export const DefaultLayout: React.FC<LayoutProps> = ({ children, ...args }) => {
  const isMobile = useIsMobile()

  return (
    <LayoutStyled {...args}>
      <PrimarySider localStorageKey="sidebarCollapsed" />

      <Content isMobile={isMobile}>
        {children}
      </Content>
    </LayoutStyled>
  )
}
