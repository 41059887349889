import styled from 'styled-components'
import colors from '../../../constants/colors'

export const SelectedItemWrapper = styled.div`
  // border: 2px solid ${colors.editing.backgroundPrimary};
  margin-bottom: -1px;

  box-shadow:
    inset 2px 2px 4px ${colors.editing.backgroundPrimary},
    inset -2px -2px 4px ${colors.editing.backgroundPrimary};

  > * {
    opacity: 0.8;
    pointer-events: none;
  }
`
