import colors from 'constants/colors'
import { compact, get as getPropertyAtPath } from 'lodash'
import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'

import { FormListProps, useCurrentForm } from 'components/form/Form'
import { FormItem, FormItemProps } from 'components/form/FormItem'
import { getNamePath } from 'rc-field-form/es/utils/valueUtil'

import { DatePickerString } from 'components/form/inline-edit/DatePickerString'
import { Input } from 'components/form/inline-edit/Input'
import Switch from 'components/form/inline-edit/Switch'
import { CredentialTypeSelect } from 'components/form/reference-selects/CredentialTypeSelect'

import { EditOutlined } from '@ant-design/icons'
import { Col, Popconfirm, Row } from 'antd'
import { RemoveItemButton } from 'components/buttons'
import { Collapse, Panel } from 'components/common/Collapse'
import { CredentialExpirationDate } from 'components/common/CredentialExpirationDate'
import { CredentialImageUpload } from './CredentialImageUpload'

const ItemLayout = styled(({
  image,
  fields,
  ...rest
}: {
  image: ReactNode,
  fields: ReactNode,
} & React.HTMLProps<HTMLDivElement>) => (
  <div {...rest}>
    <div className="image-slot">{image}</div>
    <div className="fields-slot">{fields}</div>
  </div>
))`
  display: flex;
  flex-flow: row wrap-reverse;

  .image-slot {
    flex: 1 0 130px;
  }
  .fields-slot {
    flex: 9999 0 auto;
    margin-bottom: 10px
  }
`

const PanelStyled = styled(Panel)`
  &.inactive {
    .ant-collapse-header {
      opacity: 0.7;
      color: ${colors.fontPrimary};

      .ant-collapse-extra ${CredentialExpirationDate} {
        color: ${colors.fontPrimary};
        font-weight: normal;
      }
    }
  }
`

const Label = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${colors.greyscale60};
`

const VerticalFormItemWrap = styled.div`
  margin-bottom: 15px;

  > ${Label} {
    display: block;
    margin-bottom: 3px;
  }

  .ant-form-item-explain {
    max-width: 250px;
  }

  .ant-select-borderless,
  .ant-picker-borderless,
  .ant-input-affix-wrapper-borderless,
  .ant-select-borderless .ant-select-selector {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    border-top: none!important;
    border-right: none !important;
    border-left: none !important;

    .ant-input-suffix, .ant-picker-suffix {
      margin-right: 7px;
    }
    .ant-select-arrow {
      right: 7px;
    }
  }

  .ant-select-borderless,
  .ant-picker-borderless,
  .ant-input-affix-wrapper-borderless {
    border-bottom: 1px solid transparent !important;

    &:not(.read-only) {
      border-bottom: 1px solid #d9d9d9 !important;

      &:hover,
      &:focus,
      &.ant-picker-focused,
      &.ant-input-affix-wrapper-focused,
      &.ant-input-focused,
      &.ant-select-focused {
        border-bottom-color: #40a9ff !important;
      }
    }
  }
`

const VerticalFormItem = ({ label, ...props }: FormItemProps) => (
  <VerticalFormItemWrap>
    <Label>{label}</Label>
    <FormItem {...props} />
  </VerticalFormItemWrap>
)

interface CredentialFormItemProps {
  name: FormListProps['name']
  editing?: boolean
  listPrefix?: FormItemProps['listPrefix']
  onRemove: () => void
}

export const CredentialFormItem = ({
  editing,
  name,
  onRemove,
  listPrefix,
}: CredentialFormItemProps) => {
  const form = useCurrentForm()

  const path = getNamePath(name)
  const typeDetailsPath = [...path, 'typeDetails']
  const typeNamePath = [...typeDetailsPath, 'name']
  const expirationPath = [...path, 'expirationDate']
  const activePath = [...path, 'active']

  const [defaultShow] = useState(() => {
    const value = form.getFieldValue(path)
    const isNew = value === undefined || value._isNew
    return isNew
  })

  const [active] = useState(() => form.getFieldValue(activePath))

  return (
    <Collapse mode="list" defaultActiveKey={defaultShow ? 'panel-0' : undefined}>
      <PanelStyled
        key="panel-0"
        forceRender
        className={!active ? 'inactive' : undefined}
        header={(
          <FormItem
            noStyle
            shouldUpdate={(prevValues, curValues) => (
              getPropertyAtPath(prevValues, typeNamePath) !== getPropertyAtPath(curValues, typeNamePath)
            )}
          >
            {() => (
              form.getFieldValue(typeNamePath) || 'New Credential'
            )}
          </FormItem>
        )}
        extra={(
          <FormItem
            noStyle
            shouldUpdate={(prevValues, curValues) => (
              // eslint-disable-next-line eqeqeq
              getPropertyAtPath(prevValues, expirationPath) != getPropertyAtPath(curValues, expirationPath)
            )}
          >
            {() => (
              <CredentialExpirationDate
                expirationDate={form.getFieldValue(expirationPath)}
              />
            )}
          </FormItem>
        )}
      >
        <ItemLayout
          image={(
            <FormItem
              name="images"
              listPrefix={listPrefix}
            >
              <CredentialImageUpload editing={editing} />
            </FormItem>
          )}
          fields={(
            <>
              <VerticalFormItem
                label="Type"
                name="type"
                listPrefix={listPrefix}
                rules={[
                  {
                    required: true,
                    message: 'Must select or create a type',
                  },
                ]}
                getValueFromEvent={(value, option) => {
                  if (option.label) {
                    form.setFields([
                      {
                        name: [...typeDetailsPath, 'name'],
                        value: option.label,
                      },
                      {
                        name: [...typeDetailsPath, 'slug'],
                        value,
                      },
                    ])
                  }
                  return value
                }}
              >
                <CredentialTypeSelect
                  bordered={false}
                  editing={editing}
                  style={{ width: '100%', overflow: 'hidden' }}
                  resources={compact([
                    form.getFieldValue(typeDetailsPath),
                  ])}
                />
              </VerticalFormItem>
              <VerticalFormItem
                label="Number"
                name="number"
                listPrefix={listPrefix}
              >
                <Input
                  bordered={false}
                  placeholder="Credential or Document Identifier"
                  editing={editing}
                  suffix={editing ? <EditOutlined style={{ color: colors.ant.placeholder }} /> : null}
                />
              </VerticalFormItem>
              <VerticalFormItem
                label="Expiration Date"
                name="expirationDate"
                listPrefix={listPrefix}
              >
                <DatePickerString
                  bordered={false}
                  placeholder="(Optional) Date Credential Expires"
                  editing={editing}
                  format="YYYY-MM-DD"
                  displayFormat="MM/DD/YYYY"
                />
              </VerticalFormItem>
              <Row wrap={false} align="middle" gutter={5}>
                <Col>
                  <Label style={{ marginRight: '11px', marginTop: '6px' }}>Active</Label>
                </Col>
                <Col flex="auto">
                  <FormItem
                    noStyle
                    name="active"
                    listPrefix={listPrefix}
                    valuePropName="checked"
                  >
                    <Switch editing={editing} />
                  </FormItem>
                </Col>
                <Col style={{ opacity: editing ? 1 : 0 }}>
                  <Popconfirm
                    title="Are you sure you want to delete this Credential?"
                    onConfirm={onRemove}
                    okText="Yes"
                    cancelText="No"
                    placement="topLeft"
                  >
                    <RemoveItemButton />
                  </Popconfirm>
                </Col>
              </Row>
            </>
          )}
        />
      </PanelStyled>
    </Collapse>
  )
}
