import { useQuery } from '@apollo/client'
import { Popover, PopoverProps, Typography } from 'antd'
import { Button } from 'components/buttons'
import Form from 'components/form/Form'
import { routeToFormValues } from 'components/form/RouteForm/routeToFormValues'
import Loading from 'components/Loading'
import { GET_ROUTES } from 'gql/routes'
import { timeWithZone } from 'helpers/datetime'
import { useBranch } from 'hooks/useBranch'
import { useEffect, useState } from 'react'
import { Query, QueryRoutesArgs } from 'schema'
import { RouteWaypointsFormList } from '../form/RouteForm/RouteWaypointsFormList'

export type ViewRoutePopoverProps = PopoverProps & {
  routeId: number
}

export const ViewRoutePopover = (props: ViewRoutePopoverProps) => {
  const { children, routeId, ...rest } = props

  const branch = useBranch()

  const [popoverProps, setPopoverProps] = useState<Partial<PopoverProps>>({
    content: <Loading />,
  })

  const response = useQuery<Query, QueryRoutesArgs>(GET_ROUTES, {
    fetchPolicy: 'network-only',
    skip: !routeId,
    variables: {
      where: {
        id: {
          equals: routeId,
        },
      },
    },
  })

  const route = response?.data?.routes?.[0]

  useEffect(() => {
    if (!route) return

    const routeFormValues = routeToFormValues(route)

    const timezone = route.waypoints[0].address.timezone || branch?.timezone
    const startTime = timeWithZone(route.startTime, timezone)
    const endTime = timeWithZone(route.endTime, timezone)
    const midTime = startTime.plus(endTime.diff(startTime))

    const content = (
      <Form initialValues={routeFormValues} style={{ padding: '5px 20px' }}>
        <RouteWaypointsFormList editing={false} />
      </Form>
    )

    const title = (
      <Typography.Title level={4} style={{ padding: '8px 20px' }}>
        {[
          route.active ? `Route for ${route.equipment?.name} on` : 'Cancelled route on',
          midTime.toFormat('M/d'),
        ].join(' ')}
      </Typography.Title>
    )

    setPopoverProps({ title, content })
  }, [route])

  return (
    <Popover {...rest} {...popoverProps} placement="right">
      {children || <Button type="link" style={{ padding: '2px 0', height: 'auto', fontSize: '0.7rem' }}>View Route</Button>}
    </Popover>
  )
}
