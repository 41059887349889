import { ColumnType } from 'antd/lib/table'
import { byFieldDesc } from 'helpers/sortting'

export const activeColumn = <T extends ColumnType<any>>(props: T = {} as T): T => ({
  title: 'Active',
  dataIndex: 'active',
  defaultFilteredValue: ['true'],
  filters: [
    {
      text: 'Active',
      value: 'true',
    },
    {
      text: 'Inactive',
      value: 'false',
    },
  ],
  filterMode: 'menu',
  onFilter: (value, record) => {
    const asBoolean = value === 'true' ? true : value === 'false' ? false : undefined
    if (asBoolean === undefined) return true
    return record.active === asBoolean
  },
  render: (_, { active }) => (active ? 'active' : 'inactive'),
  sorter: {
    compare: byFieldDesc('active'),
  },
  ...props,
})
