import { omit } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import ReactMarkdown from 'react-markdown'
import { NormalComponents } from 'react-markdown/lib/complex-types'
import remarkGfm from 'remark-gfm'

import { LinkExternalIcon } from 'components/icons'
import { Link } from 'react-router-dom'
// eslint-disable-next-line import/no-cycle
import { EXTERNAL_LINK_UNICODE } from './Comment'

type MarkdownProps = React.ComponentProps<typeof ReactMarkdown>

// eslint-disable-next-line no-restricted-globals
const isDashboardUrl = (url: string) => new URL(url, location.origin).origin === location.origin

type ExternalLinkProps = React.ComponentProps<typeof Link>

const ExternalLink = styled(Link).attrs<ExternalLinkProps>((props) => ({
  ...props,
  children: <LinkExternalIcon />,
}))`
  color: inherit;
  padding: 1px 3px;
`

const RouterLink: NormalComponents['a'] = (props) => {
  const linkArgs = omit(props, ['node', 'sourcePosition', 'index', 'siblingCount', 'theme'])

  if (linkArgs.href && !isDashboardUrl(linkArgs.href)) {
    linkArgs.target ||= '_blank'
  }

  if (linkArgs.children === EXTERNAL_LINK_UNICODE || linkArgs.children?.[0] === EXTERNAL_LINK_UNICODE) {
    return <ExternalLink {...linkArgs} to={{ pathname: linkArgs.href }} />
  }

  return <Link {...linkArgs} to={{ pathname: linkArgs.href }} />
}

export const Markdown = (props: MarkdownProps) => {
  const remarkPlugins: typeof props.remarkPlugins = [...(props.remarkPlugins || []), remarkGfm]

  return (
    <ReactMarkdown
      {...props}
      remarkPlugins={remarkPlugins}
      components={{
        a: RouterLink,
        ...props.components,
      }}
    />
  )
}
