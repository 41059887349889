import {
  DocumentNode, OperationVariables, useQuery, WatchQueryFetchPolicy,
} from '@apollo/client'
import { Col, Empty, Row } from 'antd'
import { Select, SelectProps } from 'components/form/inline-edit/Select'
import { byFieldAsc, bySortField, mulitSort } from 'helpers/sortting'
import { useSelectedBranch } from 'hooks/useBranch'
import { differenceBy } from 'lodash'
import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { Query } from 'schema'
import { RecursivePartial } from 'types'

export type RawValue = string | number | string[] | number[]
export type OptionsType<T extends RawValue = RawValue> = Exclude<SelectProps<T>['options'], undefined>
export type OptionType<T extends RawValue = RawValue> = OptionsType<T>[0]

export type ReferenceSelectProps<ResourceType> = PropsWithChildren<
  Omit<SelectProps<RawValue>, 'options'> & {
    resources?: RecursivePartial<ResourceType>[]
    remoteResources?: RecursivePartial<ResourceType>[]
    optionsGenerator?: (items: RecursivePartial<ResourceType>[]) => OptionsType
    showExternalLink?: boolean
    generateExternalLink?: (value: RawValue | undefined) => React.ReactNode
    nonEditableIfOne?: boolean
  }
>

export interface BuildPropsFromGqlConfig {
  name: string
  gql: DocumentNode
  queryField: keyof Pick<Query, 'contacts' | 'customers' | 'tags' |
    'equipment' | 'equipmentTypes' | 'operators' | 'orderStatuses' |
    'phoneTypes' | 'pourTypes' | 'contactRoles' | 'scheduleSteps' |
    'inventory' | 'suppliers' | 'assignmentRoles' | 'orderCancellationReasons' |
    'branches' | 'credentialTypes' | 'documentTypes' | 'equipmentGroups'>
  variables?: OperationVariables
  fetchPolicy?: WatchQueryFetchPolicy
  withCurrentBranchFilter?: boolean
  defaults?: Partial<ReferenceSelectProps<any>>
}

export const defaultOptionsGenerator: NonNullable<ReferenceSelectProps<any>['optionsGenerator']> = (items: any[]) => [...items].sort(
  mulitSort(bySortField, byFieldAsc('name'))
).map((item: any) => {
  if ('slug' in item) {
    return {
      label: item.name,
      value: item.slug,
    }
  }
  return {
    label: item.name,
    value: item.id,
  }
})

export const defaultFilterOption: SelectProps<any>['filterOption'] = (input, option) => {
  if (option?.name) {
    return option.name.toString().toLowerCase().includes(input.toLowerCase())
  }
  if (option?.label) {
    return option.label.toString().toLowerCase().includes(input.toLowerCase())
  }
  return true
}

export const errorDropdownRender = (name: string) => (
  () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={`Error loading ${name} options`}
    />
  )
)

const applyBranchFilter = (config: BuildPropsFromGqlConfig, props: ReferenceSelectProps<any>) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { branch, loading, error } = useSelectedBranch()
  if (error) {
    console.error(`ReferenceSelect branch load error for ${config.name}: ${error}`)
    return false
  }

  props.loading = props.loading || loading

  config.variables = config.variables || {}
  config.variables.where = config.variables.where || {}
  config.variables.where.branchId = { equals: branch?.id || 0 }

  return true
}

export const buildPropsFromGql = (inputConfig: BuildPropsFromGqlConfig, inputProps: ReferenceSelectProps<any>): ReferenceSelectProps<any> => {
  const config: typeof inputConfig = { ...inputConfig }
  const props: typeof inputProps = {
    ...(config.defaults || {}),
    ...inputProps,
  }

  if (config.withCurrentBranchFilter) {
    if (!applyBranchFilter(config, props)) {
      props.dropdownRender = errorDropdownRender(config.name)
      return props
    }
  }

  const variables = config.variables || {}
  const { fetchPolicy } = config

  // eslint-disable-next-line no-prototype-builtins
  if (!variables?.where?.hasOwnProperty('active')) {
    variables.where = variables.where || {}
    variables.where.active = { equals: true }
  }

  /* eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/ban-types */
  const { data: queryData, loading, error } = useQuery<Query, {}>(config.gql, { variables, fetchPolicy })

  if (error) {
    props.dropdownRender = errorDropdownRender(config.name)
    console.error(`ReferenceSelect load error for ${config.name}: ${error}`)
    return props
  }

  props.loading = props.loading || loading
  props.remoteResources = queryData?.[config.queryField]

  if (props.placeholder === undefined) {
    const aOrAn = ['a', 'e', 'i', 'o', 'u'].includes(config.name.toLowerCase()[0]) ? 'an' : 'a'
    props.placeholder = `Select ${aOrAn} ${config.name}`
  }

  return props
}

// Takes a generator function and 2 sets of resources and combines
// any options not present in resourceSet2 that are in resourceSet1
// are added to beginning of options, then the rest of resourceSet2
export function generateOptionsFromResources<ResourceType>(
  generator: NonNullable<ReferenceSelectProps<ResourceType>['optionsGenerator']>,
  resourceSet1: RecursivePartial<ResourceType>[] | undefined,
  resourceSet2: RecursivePartial<ResourceType>[] | undefined
): OptionsType {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const options1 = useMemo(() => {
    if (!resourceSet1) return []
    return generator(resourceSet1)
  }, [generator, resourceSet1])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const options2 = useMemo(() => {
    if (!resourceSet2) return []
    return generator(resourceSet2)
  }, [generator, resourceSet2])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const options = useMemo(() => {
    if (!options1) return options1
    if (!options2) return options2

    const extraOptions = differenceBy(options1, options2, 'value')
    return [
      ...extraOptions,
      ...options2,
    ]
  }, [options1, options2])

  return options
}

export const ReferenceSelect = (selectProps: ReferenceSelectProps<any>) => {
  const {
    optionsGenerator,
    resources,
    remoteResources,
    showExternalLink,
    generateExternalLink,
    nonEditableIfOne,
    ...props
  } = selectProps

  let { editing } = props

  const options = generateOptionsFromResources(optionsGenerator || defaultOptionsGenerator, resources, remoteResources)

  if (nonEditableIfOne && options.length === 1 && props.value) {
    editing = false
  }

  useEffect(() => {
    if (!props.onChange) return
    if (!options || options.length === 0) return

    // if defaultActiveFirstOption is set, and no other value has been set, change to first value
    if (props.defaultActiveFirstOption && !props.defaultValue && !props.value) {
      props.onChange(options[0].value as any, options[0])
    }
  }, [props, options])

  if (props.showSearch && !props.filterOption) {
    props.filterOption = defaultFilterOption
  }

  const ResourceSelect = (
    <Select
      {...props}
      editing={editing}
      options={selectProps.children ? undefined : options}
    />
  )

  if (selectProps.showExternalLink && selectProps.generateExternalLink) {
    const link = selectProps.generateExternalLink(props.value || undefined)

    return (
      <Row wrap={false} align="middle">
        <Col flex="auto">
          {ResourceSelect}
        </Col>
        <Col style={{ paddingLeft: 5 }}>
          {link}
        </Col>
      </Row>
    )
  }
  return ResourceSelect
}

export default ReferenceSelect
