import {
  Redirect, Route, Switch, useRouteMatch,
} from 'react-router-dom'
import { QuickbooksCustomersMatch } from './CustomersMatch'
import { QuickbooksLineItemsMatch } from './LineItemsMatch'
import { QuickbooksSettings } from './Settings'

export const QuickbooksPage = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/customers`} component={QuickbooksCustomersMatch} />
      <Route path={`${match.path}/items`} component={QuickbooksLineItemsMatch} />
      <Route path={`${match.path}/`} exact component={QuickbooksSettings} />
      <Redirect to="/" />
    </Switch>
  )
}

export default QuickbooksPage
