import { timeWithZoneToMoment } from 'helpers/datetime'
import { OrderDetails, OrderMetric } from 'schema'
import { RecursivePartial } from 'types'
import { PartialOrder } from 'types/PartialOrder'
import { FormValues } from '../FormValues'

const getNumericMetric = (metrics: Array<RecursivePartial<OrderMetric>> | undefined, key: string) => {
  const val = metrics?.find((metric) => metric.key === key)?.value
  if (typeof val === 'string') {
    return parseFloat(val)
  }
  return val
}

export const orderDetailsToFormValue = (orderDetails?: RecursivePartial<OrderDetails> | null, timezone?: string | null): FormValues['details'] => {
  if (!orderDetails) return {}

  const values: FormValues['details'] = {
    subType: orderDetails.subType,
    equipmentId: orderDetails.equipmentId,
    inventory: orderDetails.inventory?.map(({ itemId, quantity }) => ({ itemId, quantity })),
    metrics: {
      cubicYards: getNumericMetric(orderDetails.metrics, 'cubicYards'),
      travelHours: getNumericMetric(orderDetails.metrics, 'travelHours'),
      onSiteHours: getNumericMetric(orderDetails.metrics, 'onSiteHours'),
    },
    schedule: orderDetails.schedule?.map((schedule) => {
      const pourFields = schedule.step === 'pour' ? {
        pour: {
          name: schedule.pour?.name ? schedule.pour?.name : null,
          typeId: schedule.pour?.typeId,
        },
        metrics: {
          cubicYards: getNumericMetric(schedule.metrics, 'cubicYards'),
        },
      } : null

      return {
        id: schedule.id,
        step: schedule.step,
        scheduleIndex: schedule.scheduleIndex,
        address: schedule.address || undefined,
        startTime: timeWithZoneToMoment(schedule.startTime, timezone) || undefined,
        endTime: timeWithZoneToMoment(schedule.endTime, timezone) || undefined,
        ...pourFields,
      }
    }),
  }

  values.schedule?.forEach((schedule) => {
    if (!schedule?.duration && schedule?.startTime && schedule.endTime) {
      schedule.duration = schedule.endTime.diff(schedule.startTime, 'seconds')
    }
  })

  return values
}

export const orderToFormValue = (order: PartialOrder | undefined): FormValues['order'] => {
  if (!order) return {}

  const timezone = order?.site?.address?.timezone || order?.branch?.timezone

  return {
    branchId: order.branchId,
    cashOnDelivery: order.cashOnDelivery,
    customerId: order.customerId,
    flatRate: order.flatRate,
    linesOnSite: order.linesOnSite,
    instructions: order.instructions,
    bulletinToCustomer: order.bulletinToCustomer,
    assignments: order.assignments,
    purchaseOrder: order.purchaseOrder,
    revision: order.revision,
    status: order.status,
    cancellationReason: order.cancellationReason,
    siteId: order.siteId,
    supplierId: order.supplierId,
    contacts: order.contacts,
    comments: order.comments,
    billable: orderDetailsToFormValue(order.billable, timezone),
    planned: orderDetailsToFormValue(order.planned, timezone),
    actuals: order.actuals?.map((details) => orderDetailsToFormValue(details, timezone)),
  }
}

export default orderToFormValue
