import { Branch, Equipment, Organization } from 'schema'

const DEFAULT_ROUND_TO_IN_MINUTES = 15

interface EstimatePourDurationProps {
  yards?: number | null
  equipment?: Equipment | null
  branch?: { id: Branch['id'] } | null
  organizationId?: Organization['id']
}

const yardsRegression = ({
  powerIntercept,
  powerExponent,
  linearIntercept,
  linearSlope,
  linearThreshold = 100,
  roundToInMinutes = DEFAULT_ROUND_TO_IN_MINUTES,
}: {
  powerIntercept: number,
  powerExponent: number,
  linearIntercept: number,
  linearSlope: number,
  linearThreshold?: number,
  roundToInMinutes?: number
}) => (yards: number) => Math.max(
  Math.ceil(
    Math.max(
      powerIntercept * (yards ** powerExponent),
      (yards < linearThreshold ? (
        (linearSlope * yards) + linearIntercept
      ) : 0)
    // eslint-disable-next-line no-mixed-operators
    ) * 60 / roundToInMinutes
  ) * roundToInMinutes
) * 60

const DEFAULT_REGRESSION = yardsRegression({
  powerIntercept: 0.744,
  powerExponent: 0.406,
  linearIntercept: 2.8,
  linearSlope: 0.0124,
})

const ONE_HOUR_IN_SEC = 3600

export const estimatePourDuration = (props: EstimatePourDurationProps) => {
  if (props.organizationId === 14) {
    return estimatePourDurationForPKS(props)
  }

  const { yards } = props
  if (!yards) return null
  return DEFAULT_REGRESSION(yards)
}

// HACK - compromise till we get more automated stats per org
const estimatePourDurationForPKS = (props: EstimatePourDurationProps) => {
  const { yards, equipment, branch } = props
  if (!yards) return null

  // PKS TRAILER
  // IF(A2<50,0.0639*A2+3.0626-1,1.297*(A2^0.4014)-1)
  if (equipment?.type === 'trailer_pump') {
    return yardsRegression({
      powerIntercept: 1.297,
      powerExponent: 0.4014,
      linearIntercept: 3.0626,
      linearSlope: 0.0639,
      linearThreshold: 50,
    })(yards) - ONE_HOUR_IN_SEC
  }

  // PKS GEORGIA 38m
  // IF(A2<150,0.0195*A2+3.5425-1,0.9434*(A2^0.3921)-1)
  if (
    branch?.id === 14 &&
    (equipment?.size || 1000) <= 42
  ) {
    return yardsRegression({
      powerIntercept: 0.9434,
      powerExponent: 0.3921,
      linearIntercept: 3.5425,
      linearSlope: 0.0195,
      linearThreshold: 150,
    })(yards) - ONE_HOUR_IN_SEC
  }

  if (branch?.id === 14) {
    return yardsRegression({
      powerIntercept: 1.7357,
      powerExponent: 0.2745,
      linearIntercept: 4.7041,
      linearSlope: 0.0135,
      linearThreshold: 150,
    })(yards) - ONE_HOUR_IN_SEC
  }

  // PKS ALABAMA 38m
  // IF(A2<150,0.0242*A2+3.4494-1,1.0121*(A2^0.3843)-1)
  if ((equipment?.size || 1000) <= 42) {
    return yardsRegression({
      powerIntercept: 1.0121,
      powerExponent: 0.3843,
      linearIntercept: 3.4494,
      linearSlope: 0.0242,
      linearThreshold: 150,
    })(yards) - ONE_HOUR_IN_SEC
  }

  // PKS ALABAMA 47m
  // IF(A2<150,0.0163*A2+4.4251-1,1.4397*(A2^0.3139)-1)
  return yardsRegression({
    powerIntercept: 1.4397,
    powerExponent: 0.3139,
    linearIntercept: 4.4251,
    linearSlope: 0.0163,
    linearThreshold: 150,
  })(yards) - ONE_HOUR_IN_SEC
}
