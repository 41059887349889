import { Input as AntInput } from 'antd'
import { TextAreaProps as AntTextAreaProps } from 'antd/lib/input/TextArea'
import React, { ComponentRef } from 'react'
import styled from 'styled-components'

export type TextAreaRef = ComponentRef<typeof AntTextArea>

const AntTextArea = AntInput.TextArea

export interface TextAreaProps extends AntTextAreaProps {
  editing?: boolean
}

const StyledAntTextArea = styled(AntTextArea)`
  &.read-only,
  &.read-only textarea
  {
    border-color: transparent;
    background-color: transparent;
    outline-color: transparent;
    box-shadow: none;
    resize: none;
  }
`

export const TextArea = React.forwardRef<TextAreaRef, TextAreaProps>((props, ref) => {
  const { editing, ...args } = props
  if (editing === false && args.readOnly === undefined) {
    args.className = `${args.className} read-only`
    args.readOnly = true
  }
  if (args.autoComplete === undefined) {
    args.autoComplete = 'off'
  }
  return <StyledAntTextArea data-lpignore="true" {...args} ref={ref} />
})

export default TextArea
