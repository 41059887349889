import { useLocationStateDestructive } from 'hooks/useLocationStateDestructive'
import { difference } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Equipment, Order, Route } from 'schema'
import { RecursivePartial } from 'types'

export enum ResourceEditableStatus {
  Selected = 'selected',
  Disabled = 'disabled',
  Selectable = 'selectable'
}

interface ResourceEditModeState<Key> {
  enabled: boolean
  selected: Key[]
  disabled: Key[]
}

const RESOURCE_EDIT_MODE_STATE_DEFAULT: ResourceEditModeState<any> = {
  enabled: false,
  selected: [],
  disabled: [],
}

export interface EditModeState {
  mode: false | 'selector' | 'move-order-to-route'
  orders: ResourceEditModeState<Order['id']>
  routes: ResourceEditModeState<Route['id']>
  equipment: ResourceEditModeState<Equipment['id'] | null>
}

const EDIT_MODE_STATE_DEFAULT: EditModeState = {
  mode: false,
  orders: RESOURCE_EDIT_MODE_STATE_DEFAULT,
  routes: RESOURCE_EDIT_MODE_STATE_DEFAULT,
  equipment: RESOURCE_EDIT_MODE_STATE_DEFAULT,
}

interface ResourceEditMode<Key> extends ResourceEditModeState<Key> {
  toggleSelected: (id: Key) => boolean
  isSelected: (id: Key) => boolean
  isSelectable: (id: Key) => boolean
  isDisabled: (id: Key) => boolean
  status: (id: Key) => ResourceEditableStatus | undefined
}

const RESOURCE_EDIT_MODE_DEFAULT: ResourceEditMode<any> = {
  ...RESOURCE_EDIT_MODE_STATE_DEFAULT,
  toggleSelected: () => false,
  isSelected: () => false,
  isSelectable: () => false,
  isDisabled: () => false,
  status: () => undefined,
}

export interface EditMode extends EditModeState {
  mode: false | 'selector' | 'move-order-to-route'
  setEditMode: React.Dispatch<Partial<EditModeState> | false | ((prevState: EditModeState) => Partial<EditModeState> | false)>,
  enabled: boolean
  orders: ResourceEditMode<Order['id']>
  routes: ResourceEditMode<Route['id']>
  equipment: ResourceEditMode<Equipment['id'] | null>
}

const EDIT_MODE_DEFAULT: EditMode = {
  mode: false,
  setEditMode: () => { },
  enabled: false,
  orders: RESOURCE_EDIT_MODE_DEFAULT,
  routes: RESOURCE_EDIT_MODE_DEFAULT,
  equipment: RESOURCE_EDIT_MODE_DEFAULT,
}

export const EditModeContext = React.createContext<EditMode>(EDIT_MODE_DEFAULT)

type KeyTypeForState<Key> = Key extends ResourceEditModeState<infer X> ? X : never

const attachResourceFunctions = <
  State extends ResourceEditModeState<any>,
  KeyType extends KeyTypeForState<State>
>(
  editingEnabled: boolean,
  state: State,
  setState: React.Dispatch<React.SetStateAction<State>>
): ResourceEditMode<KeyType> => {
  const resourceState = state as unknown as ResourceEditModeState<KeyType>

  const disabled = !editingEnabled
  const disabledFunction = disabled ? () => false : undefined

  const resource: ResourceEditMode<KeyType> = {
    ...resourceState,
    isSelected: disabledFunction || ((id) => resourceState.selected.includes(id)),
    isDisabled: disabledFunction || ((id) => resourceState.disabled.includes(id)),
    isSelectable: disabledFunction || ((id) => state.enabled && !resource.isSelected(id) && !resource.isDisabled(id)),
    status: disabled ? (() => undefined) : ((id) => (
      resource.isSelected(id) ?
        ResourceEditableStatus.Selected
        : resource.isDisabled(id) ?
          ResourceEditableStatus.Disabled
          : resource.isSelectable(id) ?
            ResourceEditableStatus.Selectable
            :
            undefined
    )),
    toggleSelected: disabledFunction || ((id) => {
      let newValue = false

      if (resource.isDisabled(id)) {
        newValue = false
        return newValue
      }

      setState((prev) => {
        if (prev.selected.includes(id)) {
          newValue = false
          return {
            ...prev,
            selected: difference(prev.selected, [id]),
          }
        }
        newValue = true
        return {
          ...prev,
          selected: [...prev.selected, id],
        }
      })

      return newValue
    }),
  }

  return resource
}

const useResourceState = <
  Key extends Extract<keyof EditModeState, 'orders' | 'routes' | 'equipment'>,
  KeyType extends KeyTypeForState<EditModeState[Key]>
>(
  key: Key,
  [state, setState]: [
    EditModeState,
    React.Dispatch<React.SetStateAction<EditModeState>>
  ]
): [
    ResourceEditModeState<KeyType>,
    React.Dispatch<React.SetStateAction<ResourceEditModeState<KeyType>>>
  ] => (
  [
    state[key] as ResourceEditModeState<KeyType>,
    (val) => {
      setState((prev) => {
        const resource = prev[key] as ResourceEditModeState<KeyType>
        const processedValue = typeof val === 'function' ? val(resource) : val

        if (resource === processedValue) {
          return prev
        }

        return {
          ...prev,
          [key]: processedValue,
        }
      })
    },
  ]
)

export const mergeEditModeState = (state1: EditModeState, state2: RecursivePartial<EditModeState>): EditModeState => ({
  ...state1,
  ...state2,
  equipment: {
    ...(state1.equipment || {}),
    ...(state2.equipment || {}),
  },
  orders: {
    ...(state1.orders || {}),
    ...(state2.orders || {}),
  },
  routes: {
    ...(state1.routes || {}),
    ...(state2.routes || {}),
  },
})

export const EditModeProvider: React.FC = ({ children }) => {
  const locationEditMode = useLocationStateDestructive<Partial<EditMode>>('editMode')

  const state = useState<EditModeState>(EDIT_MODE_STATE_DEFAULT)
  const [editModeState, setEditModeState] = state

  const {
    orders, routes, equipment, ...rest
  } = editModeState

  const enabled = rest.mode !== false

  // eslint-disable-next-line react/jsx-no-constructed-context-values -- TODO FIXME
  const value: EditMode = {
    ...EDIT_MODE_DEFAULT,
    ...rest,
    enabled,
    orders: attachResourceFunctions(enabled, ...useResourceState('orders', state)),
    routes: attachResourceFunctions(enabled, ...useResourceState('routes', state)),
    equipment: attachResourceFunctions(enabled, ...useResourceState('equipment', state)),
  }

  value.setEditMode = (val) => {
    setEditModeState((prev) => {
      const processedValue = typeof val === 'function' ? val(prev) : val
      if (processedValue === false) {
        return mergeEditModeState(EDIT_MODE_STATE_DEFAULT, {})
      }
      return mergeEditModeState(EDIT_MODE_STATE_DEFAULT, processedValue as any)
    })
  }

  useEffect(() => {
    if (locationEditMode === null) return
    if (locationEditMode.enabled === false) {
      value.setEditMode(false)
      return
    }

    value.setEditMode((prev) => ({
      ...prev,
      ...locationEditMode,
    }))
  }, [locationEditMode])

  return (
    <EditModeContext.Provider value={value}>
      {children}
    </EditModeContext.Provider>
  )
}

export const useEditMode = () => useContext(EditModeContext)

export const useEquipmentEditMode = () => useEditMode().equipment
export const useOrdersEditMode = () => useEditMode().orders
export const useRoutesEditMode = () => useEditMode().routes
