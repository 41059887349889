import { gql, useQuery } from '@apollo/client'
import { useFeature } from 'hooks/useFeature'
import { useSamsaraUrl } from 'hooks/useSamsaraUrl'
import { ComponentProps, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Query, QueryRouteArgs } from 'schema'

type ViewOnSamsaraLinkProps = Omit<ComponentProps<Link>, 'to'> & {
  routeId: number
}

export const QUERY = gql`
  query GetSamsaraIntegrationsForRoute($id: Int!) {
    route(id: $id) {
      id
      integrations {
        samsara {
          route {
            id
          }
        }
      }
    }
  }
`

export const ViewOnSamsaraLink = ({ routeId, ...rest }: ViewOnSamsaraLinkProps) => {
  const [samsaraEnabled] = useFeature('integrations.samsara.enabled')
  const generateSamsaraUrl = useSamsaraUrl()
  const { data } = useQuery<Query, QueryRouteArgs>(QUERY, {
    fetchPolicy: 'cache-first',
    skip: !samsaraEnabled,
    variables: {
      id: routeId,
    },
  })

  const samsaraLink = useMemo(() => {
    const samsaraRouteId = data?.route?.integrations?.samsara?.route?.id
    if (!samsaraRouteId) return
    return generateSamsaraUrl(`/fleet/dispatch/v2/route/${samsaraRouteId}`)
  }, [data, generateSamsaraUrl])

  if (!samsaraLink) return null

  const to = samsaraLink ? { pathname: samsaraLink } : {}
  return <Link {...rest} to={to} target="_blank" />
}
