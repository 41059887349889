import { gql } from '@apollo/client'

export const TAG_SLIM_FRAGMENT = gql`
  fragment TagFields on Tag {
    id
    name
    color
    sort
  }
`

export const TAG_FULL_FRAGMENT = gql`
  fragment TagFields on Tag {
    id
    slug
    name
    color
    path
    sort
    active
    createdAt
    updatedAt
  }
`

export const GET_TAGS_QUERY = gql`
  query GetTags($where: QueryTagsWhereInput) {
    tags(where: $where) {
      ...TagFields
    }
  }
`

export const GET_TAGS_SLIM = gql`
  ${GET_TAGS_QUERY}
  ${TAG_SLIM_FRAGMENT}
`

export const GET_TAGS_FULL = gql`
  ${GET_TAGS_QUERY}
  ${TAG_FULL_FRAGMENT}
`

const TAGS_RELATIONS_FRAGMENT = gql`
  fragment UpdateTagsRelationsResponseFields on UpdateTagsRelationsResponse {
    contacts {
      id
      tags {
        ...TagFields
      }
    }
    customers {
      id
      tags {
        ...TagFields
      }
    }
    orders {
      id
      tags {
        ...TagFields
      }
    }
    sites {
      id
      tags {
        ...TagFields
      }
    }
  }
  ${TAG_SLIM_FRAGMENT}
`

export const CONNECT_TAGS_MUTATION = gql`
  mutation connectTags(
    $data: UpdateTagsRelationsDataInput!
    $where: [TagWhereUniqueInput!]!
  ) {
    connectTags(
      data: $data
      where: $where
    ) {
      ...UpdateTagsRelationsResponseFields
    }
  }
  ${TAGS_RELATIONS_FRAGMENT}
`

export const DISCONNECT_TAGS_MUTATION = gql`
  mutation disconnectTags(
    $data: UpdateTagsRelationsDataInput!
    $where: [TagWhereUniqueInput!]!
  ) {
    disconnectTags(
      data: $data
      where: $where
    ) {
      ...UpdateTagsRelationsResponseFields
    }
  }
  ${TAGS_RELATIONS_FRAGMENT}
`
