import { Mutation, Note } from 'schema'

import { useMutation } from '@apollo/client'
import { useNotification } from 'hooks/useNotification'
import { useHistory } from 'react-router-dom'

import { DELETE_NOTE } from 'gql/notes'
import { omit } from 'lodash'
import { PartialOrder } from 'types/PartialOrder'
import { DropdownMenu, DropdownMenuProps } from './DropdownMenu'
import { Menu, MenuDivider } from './Menu'

export interface NoteActionsMenuProps extends Omit<DropdownMenuProps, 'dropdownProps' | 'menuProps'> {
  note: Note | null
}

export const NoteActionsMenu = ({ note, ...dropdownProps }: NoteActionsMenuProps): JSX.Element | null => {
  const history = useHistory()
  const notification = useNotification()
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [deleteNoteMutation] = useMutation<Mutation, {}>(DELETE_NOTE)

  if (!note) return null

  const deleteNote = async () => {
    const response = await deleteNoteMutation({
      variables: {
        where: {
          id: note.id,
        },
      },
    })

    if ((response.errors || []).length > 0) {
      notification.error({
        message: 'Error Deleting Note',
        description: response?.errors?.map((error) => error.message).join('\n'),
        duration: 5,
      })
    } else {
      history.push({
        // eslint-disable-next-line no-restricted-globals, prefer-regex-literals
        pathname: location.pathname.replace(new RegExp('/note/.*$'), ''),
      })
    }
  }

  const duplicateNote = () => {
    const uniqueFields: Array<keyof Note> = ['id']

    const newNote = omit(JSON.parse(JSON.stringify(note)), uniqueFields)

    if (newNote.event.id) {
      newNote.event.id = undefined
    }

    for (const comment of (newNote.comments || [])) {
      (comment as any).id = undefined
    }

    history.push({
      // eslint-disable-next-line no-restricted-globals, prefer-regex-literals
      pathname: location.pathname.replace(new RegExp('/note/.*$'), '/new'),
      state: {
        fullNote: newNote,
      },
    })
  }

  const convertToOrder = () => {
    const order: PartialOrder = {
      customerId: note.customerId,
      comments: note.comments,
      contacts: note.contactId ? [{
        default: true,
        contactId: note.contactId,
      }] : undefined,
      planned: {
        equipmentId: note.equipmentId,
        schedule: [{
          step: 'pour',
          startTime: note.event?.startTime,
        }],
      },
      billable: {
        equipmentId: note.equipmentId,
      },
    }

    history.push({
      // eslint-disable-next-line no-restricted-globals, prefer-regex-literals
      pathname: location.pathname.replace(new RegExp('/note/.*$'), '/new'),
      state: {
        fullOrder: order,
        tabTitle: 'Convert Note to Order',
        afterSave: [{
          action: 'deleteNote',
          noteId: note.id,
        }],
      },
    })
  }

  return (
    <DropdownMenu {...dropdownProps}>
      <MenuDivider>Actions</MenuDivider>
      <Menu.Item key="0" onClick={convertToOrder}>Convert to Order</Menu.Item>
      <Menu.Item key="1" onClick={duplicateNote}>Duplicate Note</Menu.Item>
      <Menu.Item key="2" onClick={deleteNote}>Delete Note</Menu.Item>
    </DropdownMenu>
  )
}
