import colors from 'constants/colors'
import styled from 'styled-components'
import { Menu } from './Menu'

export const SiderMenu = styled(Menu)`
  background-color: transparent;
  border-right: none;
  width: 100%;

  .ant-menu-item, .ant-menu-item-icon  {
    font-size: 14px;
    color: ${colors.fontPrimary};
  }

  .ant-menu-item.ant-menu-item-selected,
  .ant-menu-item.ant-menu-item-selected:hover,
  .ant-menu-submenu.ant-menu-submenu-selected,
  .ant-menu-submenu.ant-menu-submenu-selected:hover
  {
    background-color: ${colors.greyscale10};

    a, .ant-menu-item-icon, .ant-menu-submenu-title  {
      color: ${colors.fontPrimary};
    }
  }

  .ant-menu-item:hover, .ant-menu-item:hover .ant-menu-item-icon  {
    color: ${colors.link};
  }
`
