import { EventContentArg } from '@fullcalendar/react'
import { Typography } from 'antd'
import colors from 'constants/colors'
import { DateTime } from 'luxon'
import styled from 'styled-components'

export interface EventWrapProps extends React.HTMLProps<HTMLDivElement> {
  startOutOfView?: boolean
  endOutOfView?: boolean
}

export const EVENT_WRAP_EDIT_MODE_CSS = `
  transition-property: -moz-filter, -ms-filter, -o-filter, -webkit-filter, filter, opacity;
  transition-duration: 0.3s;

  &.editing-disabled {
    opacity: 0.5;
  }
  &.editing-selectable:hover {
    cursor: pointer;
    filter:
      drop-shadow(0px 0px 1px #fff)
      drop-shadow(0px 0px 1px #fff)
      drop-shadow(0px 0px 1px #fff)
      drop-shadow(0px 0px 1px ${colors.editing.backgroundSecondary})
      drop-shadow(0px 0px 1px ${colors.editing.backgroundSecondary})
    ;
  }
  &.editing-selected {
    filter:
      drop-shadow(0px 0px 1px #fff)
      drop-shadow(0px 0px 1px #fff)
      drop-shadow(0px 0px 1px #fff)
      drop-shadow(0px 0px 1px ${colors.editing.backgroundPrimary})
      drop-shadow(0px 0px 1px ${colors.editing.backgroundPrimary})
      drop-shadow(0px 0px 1px ${colors.editing.backgroundPrimary})
      drop-shadow(0px 0px 1px ${colors.editing.backgroundPrimary})
    ;
  }
}`

export const EventWrap = styled.div<EventWrapProps>`
  // background-color: rgb(0, 188, 212);
  // padding: 10px;
  text-color: white;
  border-radius: 5px;
  width: 100%;
  background-color: ${colors.backgroundSecondary};
  border-left: 7px solid ${colors.emptyStatus};
  border-right: 7px solid ${colors.emptyStatus};
  // box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);

  ${(props) => props.startOutOfView && `
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `}
  ${(props) => props.endOutOfView && `
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}

  ${EVENT_WRAP_EDIT_MODE_CSS}
`

//   &.cancelled {
//     background: repeating-linear-gradient(
//       45deg,
//       ${colors.backgroundSecondary},
//       ${colors.backgroundSecondary} 7px,
//       ${Color(colors.backgroundSecondary).darken(0.05).hsl().toString()} 7px,
//       ${Color(colors.backgroundSecondary).darken(0.05).hsl().toString()} 14px
//     );
//   }
// `

export const EventWrapInnerBorder = styled.div`
  border-top: 1px solid ${colors.borderPrimary};
  border-bottom: 1px solid ${colors.borderPrimary};
  padding: 5px 0;
`

// export const Title = styled(Typography.Text)`
export const Title = styled(Typography.Text)`
  display: block !important;
  font-weight: 700;
  font-size: 14.4px;
  letter-spacing: -0.4px;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  white-space: nowrap;
  // text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
`

export const EventBody = styled.div`
  padding: 0 10px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.75);
`

export const Subtitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export interface EventProps extends EventContentArg {
  minifiedView?: boolean
  selectedDate: DateTime
}
