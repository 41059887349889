import colors from 'constants/colors'
import styled from 'styled-components'

import {
  Collapse, Divider,
} from 'antd'

import { compact } from 'lodash'
import { PanelProps } from './PanelProps'

const PanelStyled = styled(Collapse.Panel)`
  &.panel-list.ant-collapse-item.ghost {
    .ant-collapse-header {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    &.arrow-shown .ant-collapse-content {
      margin-left: 22px;
      padding-left: 10px;
      border-left: 1px solid ${colors.borderPrimary};
    }

    &.arrow-shown.no-gutter .ant-collapse-content {
      margin-left: 5px;
    }
  }

  &.no-header {
    margin-top: 24px;

    > .ant-collapse-header {
      display: none;
    }
  }

  &.no-gutter.ant-collapse-item, &.no-style.ant-collapse-item {
    > .ant-collapse-header {
      padding-left: 0;
      padding-right: 0;
    }

    > .ant-collapse-content > .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.no-style.ant-collapse-item, &.no-style.ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    margin: 0;
    padding: 0;
  }
`

export const Panel: React.FC<PanelProps> = (props) => {
  const {
    type,
    hideHeader,
    noGutter,
    noStyle,
    ...args
  } = props

  const panelType = type || 'primary'

  if (panelType === 'primary') {
    args.header = panelType === 'primary' ? <Divider>{args.header}</Divider> : args.header
    args.showArrow = args.showArrow !== undefined ? args.showArrow : false
  }

  args.className = compact([
    `panel-${panelType}`,
    hideHeader && 'no-header',
    noGutter && 'no-gutter',
    noStyle && 'no-style',
    args.showArrow !== false && 'arrow-shown',
    args.className,
  ]).join(' ')

  return <PanelStyled {...args} header={args.header || null} />
}
