import { Image } from 'antd'

interface LogoCompanyProps {
  imageSource: string
  height?: number,
  width?: number,
}

export const LogoCompany = (props: LogoCompanyProps) => {
  const { imageSource, height: customHeight, width: customWidth } = props
  const height = customHeight || '150px'
  const width = customWidth || '150px'
  return (
    <Image src={imageSource} height={height} width={width} preview={false} />
  )
}
