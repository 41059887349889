import colors from 'constants/colors'
import { formatRelativeDateTime } from 'helpers/datetime'
import { compactAndJoin, wrapInParens } from 'helpers/formatting'
import { compact, isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import Color from 'color'
import { DateTime } from 'luxon'
import { Contact, Customer, Order } from 'schema'

import { FullName } from 'components/common/FullName'
import { Tooltip, TooltipProps, TooltipTitle } from 'components/common/Tooltip'

import { OperatorList } from 'components/common/OperatorList'
import { TagsList, TagsListProps } from 'components/common/TagsList'
import { AssignmentConfirmedIcon } from 'components/icons'
import { ResourceTag } from 'components/tags/ResourceTag'
import { withSystemTags } from 'helpers/order/withSystemTags'
import { byFieldPathAsc } from 'helpers/sortting'
import { dateToSlug } from 'helpers/url'
import { useBranch } from 'hooks/useBranch'
import { useHistory } from 'react-router'
import { useEditMode } from '../EditModeState'
import { EventCollapse } from '../EventCollapse'
import {
  EventBody,
  EventProps, EventWrap,
  EventWrapInnerBorder, EventWrapProps, Subtitle, Title,
} from './Card'

export const customerContactTitle = (customer?: Customer | null, contact?: Contact | null) => compactAndJoin(' | ', [
  customer?.abbreviation || customer?.name,
  compactAndJoin(' ', [
    contact?.firstName,
    contact?.lastName ?
      `${contact?.lastName[0]}.` :
      null,
  ]),
])

export interface OrderEventProps extends EventProps, Pick<EventWrapProps, 'startOutOfView' | 'endOutOfView'> {
  order: Order
}

const TagsListStyled = styled(TagsList)`
  width: 100%;
  margin-top: 3px;
  margin-bottom: 0;

  > .ant-tag {
    max-width: 100%;
  }
`

export const OrderEvent = (props: OrderEventProps) => {
  const history = useHistory()
  const branch = useBranch()
  const editMode = useEditMode()
  const ordersEditMode = editMode.orders

  const { order, minifiedView, selectedDate } = props

  const pourEntries = (order.planned?.schedule || []).filter((entry) => entry.step === 'pour')
  const pourTimes = pourEntries.map((entry) => {
    if (!entry.startTime) return
    const zone = entry.address?.timezone || order.site?.address?.timezone
    if (!zone) return
    const pourTime = DateTime.fromISO(entry.startTime).setZone(zone)
    return formatRelativeDateTime(pourTime, selectedDate, { trimTopOfHour: true })
  })

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const contact = (order.contacts.find((contact) => contact.default) || order.contacts[0])?.contact

  let title = compactAndJoin(' | ', [customerContactTitle(order?.customer, contact), ...pourTimes])

  const billableEquipment = order?.billable?.equipment

  const nameLine = () => (
    <Subtitle>
      {compact([order.name, billableEquipment?.displayName || billableEquipment?.name]).join(' | ')}
    </Subtitle>
  )

  const siteLine = () => (
    <Subtitle>
      {compactAndJoin(' | ', [
        order.site?.name,
        order.site?.name !== order.site?.name2 ? wrapInParens(order.site?.name2) : null,
      ])}
    </Subtitle>
  )

  const operatorsLine = () => {
    const operatorsSorted = [...(order?.assignments || [])].sort(byFieldPathAsc(['roleDetails', 'sort']))

    return (
      <Subtitle>
        <OperatorList>
          {operatorsSorted?.map((assignment, i) => (
            <span key={i}>
              <FullName {...assignment.operator} />
              {assignment.acknowledgedAt && <AssignmentConfirmedIcon />}
            </span>
          ))}
        </OperatorList>
      </Subtitle>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const tagsLine = (props?: TagsListProps) => {
    const tags = withSystemTags(order)
    if (isEmpty(tags)) return

    return (
      <TagsListStyled {...props}>
        {tags.map((tag) => (
          <ResourceTag key={tag.id} tag={tag} />
        ))}
      </TagsListStyled>
    )
  }

  const bodyLines = [
    nameLine(),
    siteLine(),
    operatorsLine(),
  ]

  if (title === '') {
    title = order.name
  }

  const statusColor = order.statusDetails?.color || colors.emptyStatus
  const lightStatusColor = useMemo(() => Color(statusColor).alpha(0.5).hsl().toString(), [statusColor])

  const onClick = () => {
    if (editMode.enabled) return
    history.push(`/branches/${branch?.id}/schedule/${dateToSlug(selectedDate)}/order/${order?.id}`)
  }
  const editModeClass = `editing-${ordersEditMode.status(order.id) || 'off'}`

  const tooltipProps: TooltipProps = {
    noWrap: true,
    color: colors.greyscale80,
    title: <>
      <TooltipTitle>{title}</TooltipTitle>
      {bodyLines.map((line, i) => (
        <React.Fragment key={i}>{line}</React.Fragment>
      ))}
      {tagsLine()}
    </>,
  }

  return (
    <Tooltip
      {...tooltipProps}
      enabled={minifiedView}
    >
      <EventWrap
        className={editModeClass}
        startOutOfView={props.startOutOfView}
        endOutOfView={props.endOutOfView}
        onClick={onClick}
        style={{
          borderLeftColor: statusColor,
          borderRightColor: statusColor,
        }}
      >
        <EventWrapInnerBorder style={{
          borderBottomColor: lightStatusColor,
          borderTopColor: lightStatusColor,
        }}
        >
          <EventCollapse
            minified={minifiedView}
            header={(
              <Tooltip
                {...tooltipProps}
                mouseEnterDelay={0.3}
                enabled={!minifiedView}
              >
                <Title
                  style={{ color: statusColor, width: '100%' }}
                  ellipsis={{
                    tooltip: false,
                  }}
                >{title}
                </Title>
              </Tooltip>
            )}
          >
            <EventBody>
              {bodyLines.map((line, i) => <React.Fragment key={i}>{line}</React.Fragment>)}
            </EventBody>
          </EventCollapse>

          <EventBody>
            {tagsLine({ minified: minifiedView })}
          </EventBody>
        </EventWrapInnerBorder>
      </EventWrap>
    </Tooltip>
  )
}
