import React, { useEffect, useState } from 'react'

import { compact, isEmpty, omit } from 'lodash'

import { useMutation, useQuery } from '@apollo/client'
import { CREATE_CUSTOMER, GET_CUSTOMERS, UPDATE_CUSTOMER } from 'gql/customers'

import {
  Customer, CustomerInput, Mutation,
  MutationCreateOneCustomerArgs,
  MutationUpdateOneCustomerArgs, Phone, Query,
  QueryCustomersArgs,
} from 'schema'

import {
  Button, Col, Row, Typography,
} from 'antd'

import { ErrorMessage } from 'components/ErrorMessage'
import { AddressInput } from 'components/form/common/AddressInput'
import { PhonesFormList } from 'components/form/common/PhonesFormList'
import { Form, FormProps } from 'components/form/Form'
import { Input } from 'components/form/inline-edit/Input'
import { Switch } from 'components/form/inline-edit/Switch'
import { Loading } from 'components/Loading'
import { Footer, Header } from 'hooks/useContentLayout'
import { CustomerShortName } from '../common/CustomerShortName'
import { EmailsFormList } from '../common/EmailsFormList'
import { Checkbox } from '../inline-edit/Checkbox'
import { TextArea } from '../inline-edit/TextArea'

const { Paragraph } = Typography

export interface CustomerFormProps {
  id?: number
  editing?: boolean
  onSubmission?: (customer: Customer, action: 'update' | 'create') => void
  onCancel?: () => void
}

export const CustomerForm: React.FC<CustomerFormProps> = (props) => {
  const { id, onSubmission } = props
  const isNew = id === undefined
  const [editing, setEditing] = useState<boolean>(props.editing || isNew)
  const [billingVisible, setBillingVisible] = useState<boolean>(false)

  const where: any = {}

  if (id) {
    where.id = {
      equals: id,
    }
  }

  const { data, error: queryError, loading: queryLoading } = useQuery<Query, QueryCustomersArgs>(
    GET_CUSTOMERS,
    {
      variables: { where },
      skip: isNew,
    }
  )

  const customer = (data?.customers || [])[0] as Customer | undefined

  useEffect(() => {
    setBillingVisible(customer?.billingSameAsPhysical === false)
  }, [customer?.billingSameAsPhysical])

  const [
    createCustomer,
    createCustomerResponse,
  ] = useMutation<Mutation, MutationCreateOneCustomerArgs>(CREATE_CUSTOMER)

  const [
    updateCustomer,
    updateCustomerResponse,
  ] = useMutation<Mutation, MutationUpdateOneCustomerArgs>(UPDATE_CUSTOMER)

  // eslint-disable-next-line unused-imports/no-unused-vars
  const { loading: mutationLoading, error: mutationError } = isNew ? createCustomerResponse : updateCustomerResponse

  const [form] = Form.useForm<Customer>()

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data: CustomerInput = values

    if (data.billingAddress) {
      data.billingAddress = omit(data.billingAddress, 'name')
    }
    if (data.physicalAddress) {
      data.physicalAddress = omit(data.physicalAddress, 'name')
    }

    data.emails = compact(data.emails || [])

    data.phones = compact(
      compact<any>(values.phones || []).map(({ number, sms, type }: Phone) => {
        if (!number) return
        return { number, sms, type }
      })
    )

    if (id) {
      const resp = await updateCustomer({
        variables: { data, where: { id } },
      })

      if (resp.errors) return

      setEditing(false)

      const updatedCustomer = resp?.data?.updateOneCustomer

      if (typeof updatedCustomer !== 'undefined' && updatedCustomer !== null && onSubmission) {
        onSubmission(updatedCustomer, 'update')
      }
    } else {
      const resp = await createCustomer({
        variables: { data },
      })

      if (resp.errors) return

      setEditing(false)

      const newCustomer = resp?.data?.createOneCustomer

      if (typeof newCustomer !== 'undefined' && newCustomer !== null && onSubmission) {
        onSubmission(newCustomer, 'create')
      }
    }
  }

  const onValuesChange: FormProps['onValuesChange'] = (changedValues) => {
    // eslint-disable-next-line no-prototype-builtins
    if (changedValues.hasOwnProperty('billingSameAsPhysical')) {
      setBillingVisible(!changedValues.billingSameAsPhysical)
    }
  }

  if (queryError) {
    return (
      <ErrorMessage to="/customers">
        <Paragraph>{queryError.message}</Paragraph>
      </ErrorMessage>
    )
  }

  if (queryLoading) return <Loading />

  const getValues = () => {
    const formVals = form.getFieldsValue()
    if (!isEmpty(formVals)) return formVals
    return customer || {}
  }

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="customer-form"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        requiredMark={editing}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) => (
            prev.abbreviation !== current.abbreviation ||
            prev.name !== current.name
          )}
        >
          {() => (
            <Header hideIfOrphan>
              <CustomerShortName customer={getValues()} />
            </Header>
          )}
        </Form.Item>

        <Form.Item
          label="Abbreviation"
          name="abbreviation"
          initialValue={customer?.abbreviation}
          rules={[
            {
              required: true,
              message: 'Abbreviation is required',
            },
          ]}
        >
          <Input placeholder="Customer Abbreviation" editing={editing} />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          initialValue={customer?.name}
          rules={[
            {
              required: true,
              message: 'Customer name is required',
            },
          ]}
        >
          <Input placeholder="Customer name" editing={editing} />
        </Form.Item>
        <Form.Item
          label="Alternate name"
          name="name2"
          initialValue={customer?.name2}
        >
          <Input placeholder="Alternate name for customer" editing={editing} />
        </Form.Item>

        <EmailsFormList
          editing={editing}
          initialValue={customer?.emails || undefined}
        />

        <PhonesFormList
          editing={editing}
          initialValue={!isEmpty(customer?.phones) ? customer?.phones : [{}]}
        />
        <Form.Item label="Physical Address" style={{ marginBottom: '0px' }}>
          <AddressInput
            name="physicalAddress"
            editing={editing}
            initialValue={customer?.physicalAddress}
            showCoordinates={false}
          />
        </Form.Item>

        <Form.Item
          label="Billing Address"
          style={{ marginBottom: '0px' }}
        >
          {billingVisible && (
            <AddressInput
              name="billingAddress"
              editing={editing}
              initialValue={customer?.billingAddress}
              showCoordinates={false}
            />
          )}
          <Form.Item
            label="Same as Physical Address"
            name="billingSameAsPhysical"
            initialValue={customer?.billingSameAsPhysical !== false}
            valuePropName="checked"
            labelCol={{ flex: '0 0 auto' }}
          >
            <Checkbox
              editing={editing}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Notes"
          name="notes"
          initialValue={customer?.notes}
        >
          <TextArea
            editing={editing}
            placeholder="Notes about this Customer"
            autoSize={{ minRows: 1 }}
          />
        </Form.Item>

        <Form.Item
          label="Active"
          name="active"
          initialValue={isNew ? true : customer?.active}
          valuePropName="checked"
        >
          <Switch
            editing={editing}
          />
        </Form.Item>
      </Form>
      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}
