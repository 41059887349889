import { useCreation } from 'ahooks'
import { DependencyList } from 'react'

export type ValuesReducerHook<CollectType, ValueType> = (
  previous: CollectType,
  value: ValueType,
  key: string,
  allValues: Record<string, any>
) => CollectType

export const useValuesReducerRegistry = <CollectType>(deps: DependencyList = []) => (
  useCreation(() => {
    const onReduceMap = new Map<string, ValuesReducerHook<CollectType, any>>()

    const register = (key: string, hook: ValuesReducerHook<CollectType, any>) => {
      onReduceMap.set(key, hook)
    }
    const unregister = (key: string) => {
      onReduceMap.delete(key)
    }

    const reduce = (values: Record<string, any>, initial: CollectType = {} as any) => (
      Array.from(onReduceMap.entries()).reduce((collect, [key, reduceFunc]) => (
        reduceFunc(
          collect,
          values[key],
          key,
          values
        )
      ), initial)
    )

    return { register, unregister, reduce }
  }, deps)
)
