import { EditOutlined } from '@ant-design/icons'
import { useCreation } from 'ahooks'
import { Button, Col, Row } from 'antd'
import { Divider as DividerInner } from 'components/common/Divider'
import { LinkExternalIcon } from 'components/icons'
import { ViewOnSamsaraLink } from 'components/links/ViewOnSamsarsaLink'
import { useFeature } from 'hooks/useFeature'
import { capitalize } from 'lodash'
import styled from 'styled-components'
import { useOrderBillingState } from '../OrderBillingState'
import { TableCol } from './TableCol'
import { TableRow } from './TableRow'

const Divider: React.FC = ({ children }) => (
  <Row>
    <Col span={24}> <DividerInner> {children} </DividerInner> </Col>
  </Row>
)

export interface SectionProps {
  children?: React.ReactNode | undefined
  title?: string
}

const BillableTitle = () => {
  const [_quickbooksEnabled] = useFeature('integrations.quickbooks.enabled')
  const {
    order, isEditing, setEditing, form,
  } = useOrderBillingState()
  const editing = isEditing('billable')

  return (
    <Row>
      <Col>
        Adjusted
        {order?.flatRate && <small><br />Flat rate</small>}
      </Col>
      <Col>
        <Button
          type="link"
          onClick={() => {
            if (editing) {
              form.submit()
            } else {
              setEditing('billable', true)
            }
          }}
          style={{
            fontSize: '0.7rem',
            fontStyle: editing ? 'bold' : 'inherit',
            color: editing ? undefined : 'inherit',
            height: 'auto',
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          {editing ? <b>Save</b> : <EditOutlined />}
        </Button>
      </Col>
    </Row>
  )
}

const WithSamsaraLink = styled(({ className }: { className?: string }) => {
  const { order } = useOrderBillingState()
  if (!order?.route?.id) return null
  return (
    <ViewOnSamsaraLink routeId={order.route.id} className={className}>
      <LinkExternalIcon />
    </ViewOnSamsaraLink>
  )
})`
  color: inherit !important;
  font-size: 0.7em;
  margin-left: 7px;

  &:hover {
    opacity: 0.8;
  }
`

const ActualTitle = ({ slug }: { slug: string }) => {
  const title = slug.split('.').slice(1).map(capitalize).join(' ')

  return (
    <>
      Reported by {title}
      {slug === 'actual.samsara' && <WithSamsaraLink />}
    </>
  )
}

const HeaderRow = styled(({ className }: { className?: string }) => {
  const { details } = useOrderBillingState()

  const actuals = Object.keys(details || {}).filter((detail) => detail.startsWith('actual.')).sort()

  const sections = useCreation(() => [
    {
      section: 'title',
      title: <></>,
    },
    {
      section: 'planned',
      title: <>Planned</>,
    },

    ...actuals.map((section) => ({
      section,
      title: <ActualTitle slug={section} />,
    })),
    {
      section: 'billable',
      title: <BillableTitle />,
    },
  ], [details])

  return (
    <TableRow className={className}>
      {
        sections.map(({ section, title }) => (
          <TableCol section={section} key={section}>
            {title}
          </TableCol>
        ))
      }
    </TableRow>
  )
})`
  margin-bottom: 8px;
`

const TableWrap = styled.div`
  max-width: 1050px;
  margin-top: 15px;
`

export const Table = (props: SectionProps) => (
  <TableWrap>
    <Divider> {props.title} </Divider>
    <HeaderRow />
    {props.children}
  </TableWrap>
)
