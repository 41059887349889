import {
  MutationHookOptions, QueryHookOptions, gql, useMutation, useQuery,
} from '@apollo/client'
import {
  Omit, compact,
  isEmpty,
  isNil,
  pick,
} from 'lodash'
import { DateTime } from 'luxon'
import {
  useCallback,
  useMemo,
} from 'react'
import { ADDRESS_FRAGMENT } from '../gql/addresses'
import { UPDATE_ORDER } from '../gql/orders'
import { PHONE_FRAGMENT } from '../gql/phones'
import {
  Equipment,
  Maybe,
  Mutation, Order,
  OrderUpdateInput,
  OrderWhereUniqueInput,
  Query, QueryOrdersArgs,
} from '../schema'
import { RecursivePartial } from '../types'
import { ProductRow, SubtotalRow } from '../types/ItemTypes'

const PSUEDO_INVOICES_QUERY = gql`
 query GetInvoices(
    $orderBy: [QueryOrdersOrderByInput!],
    $where: QueryOrdersWhereInput
    $skip: Int
    $take: Int
  ) {
    orders(
      orderBy: $orderBy
      where: $where
      skip: $skip
      take: $take
    ) {
      id
      dateOfService
      dateOfServiceLocal
      purchaseOrder
      additionalIds {
        key
        value
      }
      customer {
        id
        name
        abbreviation
        emails
        phones {
          ...PhoneFields
        }
        billingAddress {
          ...AddressFields
        }
      }
      billable {
        equipment {
          id
          displayName
        }
      }
      planned {
        schedule {
          id
          step
          pour {
            id
            name
          }
        }
      }
      site {
        id
        name
        name2
        address {
          ...AddressFields
        }
      }
    }
  }

  ${ADDRESS_FRAGMENT}
  ${PHONE_FRAGMENT}
`

export type InvoiceServiceLineItem = ProductRow

export type InvoiceSubtotalLineItem = SubtotalRow

export type InvoiceLineItem = InvoiceServiceLineItem | InvoiceSubtotalLineItem

export type PaymentMethod = {
  slug: string
  name: string
}

export type Payment = {
  id: string
  amount: number
  date: string
  invoiceIds: string[]
  method?: string
  reference?: string
  userId?: number
}

export type LedgerItem = Payment

export type InvoiceStatus = {
  slug: 'new' | 'sent' | 'overdue' | 'paid' | 'voided'
  name: string
  color: string
}

export type Invoice = Pick<Order, 'dateOfService' | 'dateOfServiceLocal' | 'purchaseOrder' | 'customer' | 'site'> & {
  id: string,
  status: InvoiceStatus['slug'],
  statusDetails: InvoiceStatus
  balance?: number
  amountDue?: number
  amountPaid?: number
  invoiceDate?: string
  dueDate?: string
  lot?: string
  plannedEquipment?: Equipment
  billableEquipment?: Equipment
  lineItems?: InvoiceLineItem[]
  payments: Payment[]
  orderIds?: Order['id'][]
  travelDuration?: number
  jobDuration?: number
  cubicYards?: number
  pourType?: string
}

export type PartialInvoice = RecursivePartial<Invoice>

export type InvoiceInput = Omit<PartialInvoice, 'id' | 'statusDeailts'>

export const useGetInvoicesQuery = (
  options?: QueryHookOptions<Query, QueryOrdersArgs>
) => {
  const variables = options?.variables || {}
  const { status, ...where } = variables.where || {}

  const { loading, data, error } = useQuery<Query, QueryOrdersArgs>(PSUEDO_INVOICES_QUERY, {
    ...options,
    variables: {
      ...variables,
      where: {
        ...where,
        status: {
          in: ['invoiced'],
        },
      },
    },
  })

  const invoicesData = useMemo(() => (
    compact((data?.orders || []).map((order) => {
      const orderProperties = pick(order, [
        'dateOfService',
        'dateOfServiceLocal',
        'purchaseOrder',
        'customer',
        'site',
      ])

      const lot = compact(order.planned?.schedule?.map((entry) => entry?.pour?.name)).join(', ') || undefined

      const invoiceData = JSON.parse(order.additionalIds?.find(({ key }) => key === 'invoice')?.value || '{}')

      if (isEmpty(invoiceData)) {
        return
      }

      const plannedEquipment = order?.planned?.equipment
      const billableEquipment = order?.billable?.equipment

      const invoice: Invoice = {
        id: order.id.toString(),
        status: 'new',
        lot,
        plannedEquipment,
        billableEquipment,
        ...orderProperties,
        ...invoiceData,
      }

      if (!invoice.dueDate && order.dateOfServiceLocal) {
        invoice.dueDate = DateTime.fromISO(order.dateOfServiceLocal).plus({ days: 30 }).toISODate()
      }

      invoice.balance = isNil(invoice.amountDue) ? undefined : (invoice.amountDue - (invoice.amountPaid || 0))

      if (
        invoice.balance &&
        invoice.dueDate &&
        DateTime.fromISO(invoice.dueDate, { setZone: false }) < DateTime.local().startOf('day')
      ) {
        invoice.status = 'overdue'
      }

      invoice.statusDetails = INVOICE_STATUSES[invoice.status]

      return invoice
    }))
  ), [data])

  const invoices = useMemo(() => {
    if (!status) return invoicesData

    return invoicesData.filter((invoice) => {
      if (status.in) {
        return status.in.includes(invoice.status)
      }
      if (status.equals) {
        return status.equals === invoice.status
      }
      return false
    })
  }, [invoicesData, status])

  return { loading, data: { invoices }, error }
}

type InvoiceMutation = {
  __typename: Mutation['__typename'],
  invoice?: {
    __typename: 'InvoiceMutations',
    update?: Maybe<Invoice>
  }
}

type UpdateInvoiceMutationVariables = {
  data: InvoiceInput
  where: OrderWhereUniqueInput
}

export const useUpdateInvoiceMutation = (
  options?: MutationHookOptions<InvoiceMutation, UpdateInvoiceMutationVariables>
) => {
  const response = useMutation<InvoiceMutation, UpdateInvoiceMutationVariables>(UPDATE_ORDER, options)

  const [mutation, ...rest] = response

  const mutationWrapped: typeof mutation = useCallback((mutationOptions) => {
    const { data, ...variables } = mutationOptions?.variables || options?.variables || {}

    const orderInput: OrderUpdateInput = {
      revision: -42,
      status: 'invoiced',
      additionalIds: {
        upsert: [{
          key: 'invoice',
          value: JSON.stringify(data),
        }],
      },
    }

    return mutation({
      ...mutationOptions,
      variables: {
        ...variables,
        data: orderInput,
      } as any,
    })
  }, [mutation])

  return [mutationWrapped, ...rest] as typeof response
}

export const useInvoiceStatuses = () => Object.values(INVOICE_STATUSES)

export const INVOICE_STATUSES: Record<InvoiceStatus['slug'], InvoiceStatus> = {
  new: {
    slug: 'new',
    name: 'Pending',
    color: '#eb8005',
  },
  sent: {
    slug: 'sent',
    name: 'Sent',
    color: '#6a4c93',
  },
  overdue: {
    slug: 'overdue',
    name: 'Overdue',
    color: '#d24224',
  },
  paid: {
    slug: 'paid',
    name: 'Paid',
    color: '#23a1a5',
  },
  voided: {
    slug: 'voided',
    name: 'Voided',
    color: '#454545',
  },
}
