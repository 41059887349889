import colors from 'constants/colors'
import styled from 'styled-components'

export const Footnote = styled.span.attrs((props) => ({
  block: false,
  ...props,
}))`
  font-size: 0.9em;
  font-weight: 600;
  color: ${colors.greyscale50};
  text-align: center;
  ${(props) => props.block && 'display: block;'}
`
