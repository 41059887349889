import colors from 'constants/colors'
import styled from 'styled-components'

import { timeWithZone } from 'helpers/datetime'
import { dateToSlug } from 'helpers/url'
import { waypointName } from 'helpers/waypoint'

import { Form, FormListProps, useCurrentForm } from 'components/form/Form'
import { SelectedBranch, useBranch } from 'hooks/useBranch'

import {
  RouteWaypoint,
} from 'schema'

import {
  Col, Row, Timeline,
} from 'antd'

import { FormItem } from 'components/form/FormItem'

import { LinkExternalButton } from 'components/buttons'
import { StartTimeInput } from 'components/form/OrderForm/common/StartTimeInput'
import { RoutePerformanceLabel } from 'components/route/RoutePerformanceLabel'

interface RouteWaypointsFormListProps extends Omit<FormListProps, 'name' | 'children'> {
  name?: FormListProps['name']
  editing?: boolean
  externalLinkGenerator?: (waypoint: Partial<RouteWaypoint>, branch?: SelectedBranch | null) => string | undefined
}

const TimelineWaypointName = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
`

const TimelineWaypointEvent = styled.div`
  font-size: 13px;
  line-height: 20px;

  .ant-form-item  {
    margin-bottom: 0;

    .ant-form-item-control-input-content > div {
      padding-left: 5px;
    }

    .ant-picker.ant-picker-borderless {
      padding: 0;

      .ant-picker-input {
        padding: 3px 2px;
        margin: 1px 2px;

        border-bottom: 1px solid ${colors.greyscale20};
      }

      &.read-only .ant-picker-input {
        border-bottom-color: transparent;
      }
    }

    input {
      font-size: 13px;
      line-height: 20px;
    }
  }
`

const defaultExternalLinkGenerator: RouteWaypointsFormListProps['externalLinkGenerator'] = (waypoint, branch) => {
  if (!waypoint?.order?.id) return
  const unixTime = (waypoint.scheduledArrivalTime || waypoint.scheduledDepartureTime) as moment.Moment | null
  if (!unixTime) return
  const date = timeWithZone(unixTime.toISOString(), waypoint?.address?.timezone || branch?.timezone)
  if (!date) return
  return `/branches/${branch?.id}/schedule/${dateToSlug(date)}/order/${waypoint.order.id}`
}

export const RouteWaypointsFormList = (props: RouteWaypointsFormListProps) => {
  const {
    editing,
    name,
    externalLinkGenerator,
    ...args
  } = props

  const form = useCurrentForm()
  const branch = useBranch()

  return (
    <Form.List
      name={name || 'waypoints'}
      {...args}
    >
      {(fields, { add, remove }, { errors }) => (
        <Timeline>
          {fields.map((field, index) => {
            const waypoint: Partial<RouteWaypoint> = form.getFieldValue(['waypoints', index])

            const timeParts = [{
              title: waypoint.actualArrivalTime ? 'Arrived' : 'Arrive at',
              editKey: waypoint.actualArrivalTime ? 'actualArrivalTime' : 'scheduledArrivalTime',
              stats: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                scheduledTime: (waypoint.scheduledArrivalTime as moment.Moment | null)?.toISOString!(),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                actualTime: (waypoint.actualArrivalTime as moment.Moment | null)?.toISOString!(),
                performance: waypoint.arrivalPerformance,
              },
            }, {
              title: waypoint.actualDepartureTime ? 'Departed' : 'Depart at',
              editKey: waypoint.actualDepartureTime ? 'actualDepartureTime' : 'scheduledDepartureTime',
              stats: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                scheduledTime: (waypoint.scheduledDepartureTime as moment.Moment | null)?.toISOString!(),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                actualTime: (waypoint.actualDepartureTime as moment.Moment | null)?.toISOString!(),
                performance: waypoint.departurePerformance,
              },
            }]

            const externalLink = (externalLinkGenerator || defaultExternalLinkGenerator)(waypoint, branch)

            return (
              <Timeline.Item key={index}>
                <TimelineWaypointName>
                  <Row gutter={8}>
                    <Col flex="auto">
                      {waypointName(waypoint) || 'TBD'}
                    </Col>
                    {externalLink && (
                      <Col>
                        <LinkExternalButton
                          size="small"
                          href={externalLink}
                        />
                      </Col>
                    )}
                  </Row>
                </TimelineWaypointName>
                {timeParts.map((timePart) => {
                  const hasScheduled = Boolean(timePart.stats.scheduledTime)
                  const hasActual = Boolean(timePart.stats.actualTime)
                  if (!hasScheduled && !hasActual) return

                  // only allow editing non completed, non order waypoints
                  const waypointEditing = editing && !hasActual && !waypoint?.order

                  return (
                    <TimelineWaypointEvent key={timePart.editKey}>
                      <Row align="middle">
                        <Col>

                          {timePart.title}
                        </Col>
                        <Col flex="auto">
                          <FormItem
                            listPrefix={field}
                            key={timePart.editKey}
                            name={timePart.editKey}
                          >
                            <StartTimeInput
                              branchTimezone={branch?.timezone}
                              siteTimezone={waypoint?.address?.timezone}
                              editing={waypointEditing}
                              bordered={false}
                              allowClear={false}
                            />
                          </FormItem>
                        </Col>
                        {hasActual && (
                          <Col>
                            <RoutePerformanceLabel
                              style={{
                                fontSize: '0.7rem',
                              }}
                              {...timePart.stats}
                            />
                          </Col>
                        )}
                      </Row>
                    </TimelineWaypointEvent>
                  )
                })}
              </Timeline.Item>
            )
          })}
        </Timeline>
      )}
    </Form.List>
  )
}
