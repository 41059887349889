import { Select as AntSelect } from 'antd'
import { SelectProps as AntSelectProps, BaseOptionType, DefaultOptionType } from 'antd/lib/select/index'
import React, { ComponentRef } from 'react'
import styled from 'styled-components'

export type SelectRef = ComponentRef<typeof AntSelect>

type InternalOptionType = DefaultOptionType | BaseOptionType

export interface SelectProps<
  ValueType = any,
  OptionType extends InternalOptionType = DefaultOptionType
> extends AntSelectProps<ValueType, OptionType> {
  block?: boolean
  editing?: boolean
  singlePerLine?: boolean
}

const StyledAntSelect = styled(AntSelect)`
  &.read-only,
  &.read-only .ant-select-selector,
  &.read-only input,
  &.read-only.ant-select-focused .ant-select-selector,
  &.read-only.ant-select-focused input,
  &.read-only input.ant-select-selection-search-input,
  &.read-only.ant-select-multiple .ant-select-selection-item,
  &.read-only .ant-select-selection-item-remove {
    border-color: transparent !important;
    background-color: transparent !important;
    outline-color: transparent !important;
    box-shadow: none !important;
    caret-color: transparent !important;
    cursor: default;
  }
  &.read-only .ant-select-arrow {
    color: transparent;
  }
  &.read-only .ant-select-selection-item-remove {
    color: transparent;
  }
  &.single-per-line .ant-select-selector {
    flex-direction: column;
    align-items: flex-start;
    line-height: 32px;
    margin: 2px 0;

    &::after {
      display: none;
    }
  }
  &.block {
    width: 100%;
  }
`

type AnyProps = SelectProps<any, InternalOptionType>

export const Select = React.forwardRef<SelectRef, AnyProps>((props: AnyProps, ref) => {
  const {
    editing, singlePerLine, block, ...args
  } = props

  const className = args.className ? [args.className] : []

  if (editing === false) {
    className.push('read-only')
    args.showSearch = false
    args.allowClear = false
    args.open = false
  }
  if (singlePerLine) {
    className.push('single-per-line')
  }
  if (block) {
    className.push('block')
  }

  args.className = className.join(' ')

  return <StyledAntSelect data-lpignore="true" {...args} ref={ref} />
}) as <
  ValueType = any,
  OptionType extends InternalOptionType = DefaultOptionType
>(
  props: SelectProps<ValueType, OptionType> & {
    ref?: React.Ref<SelectRef> | undefined
  }
) => ReturnType<typeof StyledAntSelect>

export default Select
