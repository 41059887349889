import { compact } from 'lodash'
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react'

import { SelectedBranch, useSelectedBranch } from 'hooks/useBranch'

import { todayInZone } from 'helpers/datetime'
import { dateFromSlug, dateToSlug } from 'helpers/url'
import { DateTime } from 'utils/luxon'

type SetStateFor<T extends keyof SchedulerState> = React.Dispatch<React.SetStateAction<SchedulerState[T]>>

export interface SchedulerState {
  basePath: string
  branch: SelectedBranch | null
  loading: boolean
  errors?: Error[]
  today: DateTime
  timezone: string,
  selectedDate: DateTime
  setSelectedDate: SetStateFor<'selectedDate'>
  selectedDateSlug: string
  showMinifiedView: boolean
  toggleMinifiedView: () => void
  inactiveEventsVisible: boolean
  toggleInactiveEvents: () => void
}

const SchedulerStateContext = React.createContext<SchedulerState>({ loading: true } as any)

export const SchedulerStateProvider = SchedulerStateContext.Provider

export interface SchedulerStateProps {
  basePath: string
  date?: string
}

export const useNewSchedulerState = (props: SchedulerStateProps): SchedulerState => {
  // eslint-disable-next-line no-undef-init
  let errors: Error[] | undefined = undefined
  let loading = false
  const { branch, loading: branchLoading, error: branchError } = useSelectedBranch()

  const [showMinifiedView, setMinifiedView] = useState<boolean>(localStorage.getItem('schedulerMinifiedView') === 'true' || false)
  const toggleMinifiedView = () => {
    setMinifiedView((prev) => {
      const val = !prev
      localStorage.setItem('schedulerMinifiedView', val ? 'true' : 'false')
      return val
    })
  }

  const [inactiveEventsVisible, setInactiveEventsVisible] = useState<boolean>(localStorage.getItem('schedulerInactiveEventsVisible') === 'true' || false)
  const toggleInactiveEvents = () => {
    setInactiveEventsVisible((prev) => {
      const val = !prev
      localStorage.setItem('schedulerInactiveEventsVisible', val ? 'true' : 'false')
      return val
    })
  }

  const [selectedDate, setSelectedDate] = useState<DateTime>(() => dateFromSlug(props.date, branch?.timezone || undefined))
  useEffect(() => {
    if (!props.date) return
    const asDateTime = dateFromSlug(props.date, branch?.timezone || undefined)
    if (!asDateTime.equals(selectedDate)) {
      setSelectedDate((prev) => {
        if (!asDateTime.equals(prev)) {
          return asDateTime
        }
        return prev
      })
    }
  }, [props.date])

  const selectedDateSlug = useMemo(() => dateToSlug(selectedDate), [selectedDate])
  const [today, setToday] = useState(() => todayInZone(selectedDate.zone))

  useEffect(() => {
    if (!branch?.timezone) return
    const timezone = branch?.timezone
    setSelectedDate((previous) => previous.setZone(timezone, { keepLocalTime: true }))
  }, [branch?.timezone])

  useEffect(() => {
    setToday(todayInZone(selectedDate.zone))
  }, [selectedDate.offset])

  useEffect(() => {
    const msTillNextDay = today.plus({ day: 1 }).diffNow('milliseconds').milliseconds + 100
    const timerTillNextDay = setTimeout(() => {
      setToday(todayInZone(selectedDate.zone))
    }, msTillNextDay)
    return () => clearTimeout(timerTillNextDay)
  }, [today.toMillis()])

  errors = compact([branchError])
  loading = [branchLoading].some(Boolean)

  if (!branchLoading && !branchError && !branch?.timezone) {
    errors.push(new Error('No timezone exists for branch'))
  }

  const state = {
    basePath: props.basePath,
    loading,
    errors: errors.length === 0 ? undefined : errors,
    branch,
    today,
    timezone: selectedDate.zoneName || branch?.timezone || DateTime.local().zoneName,
    selectedDateSlug,
    selectedDate,
    setSelectedDate,
    showMinifiedView,
    toggleMinifiedView,
    inactiveEventsVisible,
    toggleInactiveEvents,
  }

  return state
}

export const useSchedulerState = () => useContext(SchedulerStateContext)
