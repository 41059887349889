import React, { useRef } from 'react'
import { Equipment, Order, OrderContact } from 'schema'

import { useFeatures } from 'hooks/useFeature'

import { customerName } from 'helpers/customerName'
import { formatRelativeDateTime } from 'helpers/datetime'
import { formatAddressLines } from 'helpers/formatAddress'
import { wrapInParens } from 'helpers/formatting'
import { sortOrderContacts } from 'helpers/sortOrderContacts'
import { byFieldAsc } from 'helpers/sortting'
import {
  camelCase, compact, get as getProperty, isEmpty, uniq,
} from 'lodash'

import { Address } from 'components/common/Address'
import { Comment } from 'components/common/Comment'
import { FullName } from 'components/common/FullName'
import { PhoneList } from 'components/common/PhoneList'
import { StatusBadge } from 'components/common/StatusBadge'
import Select, { SelectProps } from 'components/form/inline-edit/Select'
import { ResourceTag } from 'components/tags/ResourceTag'
import { unitSize } from 'helpers/equipment'
import { DateTime } from 'luxon'

export interface OrderFieldSelection {
  label: string
  value: string
  selection: string[][]
  render: string[] | ((order: Order) => React.ReactNode)
  feature?: string
  hidden?: boolean
}

const addressFields = (prefix: string[]) => ([
  [...prefix, 'street'],
  [...prefix, 'street2'],
  [...prefix, 'city'],
  [...prefix, 'state'],
  [...prefix, 'zip'],
])

export const ORDER_FIELDS: Array<OrderFieldSelection> = [
  {
    label: 'ID',
    selection: [['name']],
    render: ['name'],
  },
  {
    label: 'Cancellation Reason',
    selection: [['cancellationReasonDetails', 'name']],
    render: ['cancellationReasonDetails', 'name'],
  },
  {
    label: 'COD',
    selection: [['cashOnDelivery']],
    render: (order: Order) => (getProperty(order, 'cashOnDelivery') ? 'COD' : null),
  },
  {
    label: 'Comments',
    selection: [
      ['comments', 'text'],
      ['comments', 'person', 'firstName'],
      ['comments', 'person', 'lastName'],
      ['comments', 'createdAt'],
    ],
    render: (order: Order) => (
      <>
        {(order.comments || []).map((comment, i) => <Comment key={i} comment={comment} />)}
      </>
    ),
  },
  {
    label: 'Contact',
    selection: [
      ['contacts', 'default'],
      ['contacts', 'role', 'name'],
      ['contacts', 'role', 'sort'],
      ['contacts', 'contact', 'firstName'],
      ['contacts', 'contact', 'lastName'],
    ],
    render: (order: Order) => <OrderContactsList contacts={order.contacts || []} />,
  },
  {
    label: 'Contact with Phones',
    selection: [
      ['contacts', 'default'],
      ['contacts', 'role', 'sort'],
      ['contacts', 'contact', 'firstName'],
      ['contacts', 'contact', 'lastName'],
      ['contacts', 'contact', 'phones', 'number'],
      ['contacts', 'contact', 'phones', 'typeDetails', 'name'],
    ],
    render: (order: Order) => <OrderContactsList contacts={order.contacts || []} showPhones />,
  },
  {
    label: 'Cubic Yards (Planned)',
    selection: [
      ['planned', 'schedule', 'metrics', 'key'],
      ['planned', 'schedule', 'metrics', 'value'],
    ],
    render: (order: Order) => {
      const yardages = compact((order?.planned?.schedule || []).flatMap((entry) => (
        (entry?.metrics || []).map((metric) => {
          if (metric.key === 'cubicYards') {
            return metric.value
          }
          return null
        })
      )))

      return yardages.join(',')
    },
  },
  {
    label: 'Cubic Yards (Operator)',
    selection: [
      ['actuals', 'subType'],
      ['actuals', 'schedule', 'metrics', 'key'],
      ['actuals', 'schedule', 'metrics', 'value'],
    ],
    render: (order: Order) => {
      const operatorReportedSchedule = order.actuals?.find((actual) => actual?.subType === 'operator')?.schedule || []
      const yardages = compact(operatorReportedSchedule.flatMap((entry) => (
        (entry?.metrics || []).map((metric) => {
          if (metric.key === 'cubicYards') {
            return metric.value
          }
          return null
        })
      )))
      return yardages.join(',')
    },
  },
  {
    label: 'Customer',
    selection: [
      ['customer', 'abbreviation'],
      ['customer', 'name'],
    ],
    render: (order: Order) => customerName(order.customer || {}),
  },
  {
    label: 'Customer Address (Billing)',
    selection: [
      ...addressFields(['customer', 'billingAddress']),
    ],
    render: (order: Order) => <Address address={order?.customer?.billingAddress} />,
  },
  {
    label: 'Customer Address (Physical)',
    selection: [
      ...addressFields(['customer', 'physicalAddress']),
    ],
    render: (order: Order) => <Address address={order?.customer?.physicalAddress} />,
  },
  {
    label: 'Customer Phone',
    selection: [
      ['customer', 'phones', 'number'],
      ['customer', 'phones', 'type'],
    ],
    render: (order: Order) => {
      const phones = order?.customer?.phones || []
      return <PhoneList phones={phones} />
    },
  },
  {
    label: 'Date',
    selection: [['dateOfServiceLocal']],
    render: (order: Order) => {
      if (!order?.dateOfServiceLocal) return
      return DateTime.fromISO(order.dateOfServiceLocal, { setZone: true }).toFormat('EEE, D')
    },
  },
  {
    label: 'Equipment (Billable Name)',
    selection: [['billable', 'equipment', 'displayName']],
    render: ['billable', 'equipment', 'displayName'],
  },
  {
    label: 'Equipment (Billable)',
    selection: [['billable', 'equipment', 'name']],
    render: ['billable', 'equipment', 'name'],
    hidden: true,
  },
  {
    label: 'Equipment (Billable Display Name)',
    selection: [['billable', 'equipment', 'displayName']],
    render: ['billable', 'equipment', 'displayName'],
    hidden: true,
  },
  {
    label: 'Equipment (Planned)',
    selection: [['planned', 'equipment', 'name']],
    render: ['planned', 'equipment', 'name'],
  },
  {
    label: 'Equipment (Planned Display Name)',
    selection: [['planned', 'equipment', 'displayName']],
    render: ['planned', 'equipment', 'displayName'],
    hidden: true,
  },
  {
    label: 'Equipment (Planned Size)',
    selection: [
      ['planned', 'equipment', 'size'],
      ['planned', 'equipment', 'type'],
    ],
    render: (order: Order) => {
      const equipment: Partial<Equipment> = getProperty(order, ['planned', 'equipment'])
      if (equipment?.size && equipment?.type) {
        return `${equipment.size}${unitSize(equipment.type) || ''}`
      }
    },
  },
  {
    label: 'Flat Rate',
    selection: [['flatRate']],
    render: (order: Order) => (getProperty(order, 'flatRate') ? 'Flat Rate' : null),
  },
  {
    label: 'Instructions',
    selection: [['instructions']],
    render: ['instructions'],
  },
  {
    label: 'Inventory',
    feature: 'inventory.enabled',
    selection: [
      ['planned', 'inventory', 'quantity'],
      ['planned', 'inventory', 'item', 'name'],
      ['planned', 'inventory', 'item', 'displayUnit'],
    ],
    render: (order: Order) => {
      const inventory = order?.planned?.inventory || []
      return inventory.map((item) => (
        <div>
          {item?.item?.name}{item?.quantity && ` - ${item?.quantity}${item?.item?.displayUnit || 'x'}`}
        </div>
      ))
    },
  },
  {
    label: 'Lines On Site',
    selection: [['linesOnSite']],
    render: (order: Order) => (getProperty(order, 'linesOnSite') ? 'Lines Present' : null),
  },
  {
    label: 'Lot',
    selection: [['planned', 'schedule', 'pour', 'name']],
    render: (order: Order) => {
      const lots = compact((order?.planned?.schedule || []).map((entry) => entry?.pour?.name))
      return lots.join(', ')
    },
  },
  {
    label: 'Onsite Time (Planned)',
    selection: [
      ['dateOfServiceLocal'],
      ['routeWaypoint', 'scheduledArrivalTimeLocal'],
      ['branch', 'address', 'timezone'],
    ],
    render: (order: Order) => {
      if (!order?.routeWaypoint?.scheduledArrivalTimeLocal) return
      const onsiteTime = DateTime.fromISO(order?.routeWaypoint?.scheduledArrivalTimeLocal, { setZone: true })
      const dateOfService = order.dateOfServiceLocal ? DateTime.fromISO(order.dateOfServiceLocal, { setZone: true }) : undefined

      const branchTimezone = order?.branch?.timezone
      const inBranchTime = branchTimezone ? onsiteTime.setZone(branchTimezone) : onsiteTime

      const timeStr = formatRelativeDateTime(onsiteTime, dateOfService || onsiteTime)
      const timezoneStr = onsiteTime.offset !== inBranchTime.offset ? onsiteTime.toFormat(' ZZZZ') : ''
      return timeStr + timezoneStr
    },
  },
  {
    label: 'Operator',
    selection: [
      ['assignments', 'operator', 'firstName'],
      ['assignments', 'operator', 'lastName'],
    ],
    render: (order: Order) => (
      <>
        {(order.assignments || []).map((assignment, i) => <div key={i}><FullName key={assignment.id} {...assignment.operator} /></div>)}
      </>
    ),
  },
  {
    label: 'Pour Time',
    selection: [
      ['dateOfServiceLocal'],
      ['branch', 'address', 'timezone'],
    ],
    render: (order: Order) => {
      if (!order?.dateOfServiceLocal) return
      const dateOfService = DateTime.fromISO(order.dateOfServiceLocal, { setZone: true })
      const branchTimezone = order?.branch?.timezone
      const inBranchTime = branchTimezone ? dateOfService.setZone(branchTimezone) : dateOfService
      return formatRelativeDateTime(dateOfService, inBranchTime)
    },
  },
  {
    label: 'Pour Type',
    selection: [['planned', 'schedule', 'pour', 'type', 'name']],
    render: (order: Order) => {
      const pourTypes = compact((order?.planned?.schedule || []).map((entry) => entry?.pour?.type?.name))
      return pourTypes.join(', ')
    },
  },
  {
    label: 'Site',
    selection: [['site', 'name']],
    render: ['site', 'name'],
  },
  {
    label: 'Site with Address',
    selection: [
      ['site', 'name'],
      ...addressFields(['site', 'address']),
    ],
    render: (order: Order) => {
      const { name, address } = order.site || {}
      const addressLines = address ? formatAddressLines(address, { multiline: true }) : []
      return [name, ...addressLines].join('\n')
    },
  },
  {
    label: 'Status',
    selection: [
      ['statusDetails', 'name'],
      ['statusDetails', 'color'],
    ],
    render: (order: Order) => (
      order.statusDetails && <StatusBadge status={order.statusDetails} size="medium" />
    ),
  },
  {
    label: 'Supplier',
    selection: [
      ['supplier', 'name'],
      ['supplier', 'name2'],
    ],
    render: (order: Order) => (
      uniq(compact([order.supplier?.name, order?.supplier?.name2])).join(' | ')
    ),
  },
  {
    label: 'Tags',
    selection: [
      ['tags', 'color'],
      ['tags', 'name'],
      ['tags', 'sort'],
    ],
    render: (order: Order) => {
      const tags = (order.tags || []).slice().sort(byFieldAsc('sort'))
      return tags.map((tag, i) => <ResourceTag key={i} tag={tag} />)
    },
  },
  {
    label: 'Created At',
    selection: [['createdAt']],
    render: (order: Order) => (
      order.createdAt && DateTime.fromISO(order.createdAt).toFormat('D h:mma')
    ),
  },
  {
    label: 'Last Updated At',
    selection: [['updatedAt']],
    render: (order: Order) => (
      order.updatedAt && DateTime.fromISO(order.updatedAt).toFormat('D h:mma')
    ),
  },
].map(
  (item) => ({ ...item, value: camelCase(item.label) })
)

const ORDER_FIELDS_BY_VALUE = Object.fromEntries(ORDER_FIELDS.map((item) => [item.value, item]))

const OrderContactsList = ({ contacts, showPhones }: { contacts: OrderContact[], showPhones?: boolean }) => {
  if (isEmpty(contacts)) return null

  if (contacts.length === 1) {
    return (
      <div>
        <FullName {...contacts[0]?.contact} />
        {showPhones && <PhoneList style={{ marginLeft: '7px', listStyle: 'square inside' }} phones={contacts[0]?.contact?.phones || []} />}
      </div>
    )
  }

  return (
    <>
      {sortOrderContacts(contacts.slice()).map((contact, i) => (
        <div key={i}>
          <FullName {...contact.contact} />
          {!contact.default && wrapInParens(contact.role?.name)}
          {showPhones && <PhoneList style={{ marginLeft: '7px', listStyle: 'square inside' }} phones={contact?.contact?.phones || []} />}
        </div>
      ))}
    </>
  )
}

export type OrderFieldsSelectorProps = Omit<SelectProps<OrderFieldSelection[]>, 'mode' | 'options' | 'labelInValue'>

export const OrderFieldsSelector = (props: OrderFieldsSelectorProps) => {
  const neededFeatures = useRef(() => (
    uniq(compact(ORDER_FIELDS.map((field) => field.feature)))
  )).current()

  const [features] = useFeatures(...neededFeatures)
  const featureFilteredOptions = ORDER_FIELDS.filter((field) => !field.feature || features[field.feature])

  const withoutHidden = featureFilteredOptions.filter((field) => field.hidden !== true)

  const args: SelectProps<OrderFieldSelection[]> = {
    ...props,
    mode: 'multiple',
    labelInValue: true,
    options: withoutHidden,
    onChange: (values, ...rest) => {
      if (props.onChange) {
        props.onChange(
          values.map((item) => ORDER_FIELDS_BY_VALUE[item.value]),
          ...rest
        )
      }
    },
  }

  return <Select {...args} />
}
