import {
  Col, Form, Input, InputNumber, Row, Select,
} from 'antd'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import { useContext, useEffect, useMemo } from 'react'
import { computeDiscount } from '../../helpers/computeBalance'
import { formatAddress } from '../../helpers/formatAddress'
import { parseToNumber } from '../../helpers/parseStringToNumber'
import { InvoiceBalanceContext } from '../../hooks/useBalance'
import { InvoiceSettingsContext } from '../../hooks/useInvoiceSettings'
import { PartialInvoice } from '../../hooks/useInvoices'
import { DISCOUNT_TYPE, IInvoiceSettings, TERMS_ENUM } from '../../types/ItemTypes'
import CustomerSelect from '../form/reference-selects/CustomerSelect'
import { usePriceSheets } from './data/usePriceSheets'

interface InvoiceSettingsProps {
  invoice: PartialInvoice
  editing: boolean,
}

interface InvoiceFields {
  billing_address: string,
  site_address: string,
  customer_name: string,
  customer_id?: number,
  customer_email: string,
  price_rate: string,
  terms: TERMS_ENUM,
  invoice_date: string,
  due_date: string,
  discount_type: DISCOUNT_TYPE,
  discount_value: number,
  ty_message: string,
}

interface FutureDueDates {
  due: Date,
  due10: Date,
  due15: Date,
  due30: Date,
}

export const InvoiceSettings = ({ invoice, editing }: InvoiceSettingsProps) => {
  const priceSheets = usePriceSheets({
    customerIds: invoice.customer?.id ? [invoice.customer?.id] : undefined,
  })

  const [form] = Form.useForm<InvoiceFields>()
  const { settings, updateInvoiceSettings } = useContext(InvoiceSettingsContext)
  const { balance, updateBalance } = useContext(InvoiceBalanceContext)
  const { subtotal } = balance
  const parseDateStringToDate = (v: string) => (new Date(v)) // parser used for getting the date object of invoice and due dates
  // updates the invoice settings context
  const handleChangeAndUpdate = (v: Partial<IInvoiceSettings>) => {
    updateInvoiceSettings((prev) => ({ ...prev, ...v }))
  }
  // memoized value for defined due dates
  const futureDueDates = useMemo<FutureDueDates>(() => ({
    due: settings.invoiceDate,
    due10: DateTime.fromJSDate(settings.invoiceDate).plus({ days: 10 }).toJSDate(),
    due15: DateTime.fromJSDate(settings.invoiceDate).plus({ days: 15 }).toJSDate(),
    due30: DateTime.fromJSDate(settings.invoiceDate).plus({ days: 30 }).toJSDate(),
  }), [settings.invoiceDate])
  // handler function that updates invoice date
  useEffect(() => {
    let d
    switch (form.getFieldValue('terms')) {
      case TERMS_ENUM.DUE: d = settings.invoiceDate; break
      case TERMS_ENUM.TERMS_10: d = DateTime.fromJSDate(settings.invoiceDate).plus({ days: 10 }).toJSDate(); break
      case TERMS_ENUM.TERMS_15: d = DateTime.fromJSDate(settings.invoiceDate).plus({ days: 15 }).toJSDate(); break
      default: {
        d = DateTime.fromJSDate(settings.invoiceDate).plus({ days: 30 }).toJSDate()
      }
    }
    form.setFieldsValue({ ...form.getFieldsValue(), due_date: d.toISOString().split('T')[0] })
  }, [settings.invoiceDate])
  useEffect(() => {
    const d = parseToNumber(settings.discountValue)
    if (typeof d !== 'undefined') {
      const n = computeDiscount(subtotal, d, settings.discountType)
      updateBalance({ discount: n })
    }
  }, [settings.discountType, settings.discountValue, subtotal])
  // effect used to update the customer name
  // handler function that update terms and due date
  const handleChangeTerms = (v: TERMS_ENUM) => {
    let d
    switch (v) {
      case TERMS_ENUM.DUE: d = futureDueDates.due; break
      case TERMS_ENUM.TERMS_10: d = futureDueDates.due10; break
      case TERMS_ENUM.TERMS_15: d = futureDueDates.due15; break
      default: {
        d = futureDueDates.due30
      }
    }
    handleChangeAndUpdate({ terms: v, dueDate: d })
    form.setFieldsValue({ ...form.getFieldsValue(), due_date: d.toISOString().split('T')[0] })
  }
  const handleChangeFormValues = (changedValues: any, values: InvoiceFields) => {
    const key = Object.keys(changedValues)[0] as keyof InvoiceFields
    const value = Object.values(changedValues)[0] as any
    switch (key) {
      case 'billing_address': handleChangeAndUpdate({ billAddress: value }); break
      case 'site_address': handleChangeAndUpdate({ siteAddress: value }); break
      case 'customer_name': handleChangeAndUpdate({ customerName: value }); break
      case 'invoice_date': handleChangeAndUpdate({ invoiceDate: parseDateStringToDate(value) }); break
      case 'due_date': handleChangeAndUpdate({ dueDate: parseDateStringToDate(value) }); break
      case 'price_rate': handleChangeAndUpdate({ priceRateID: value }); break
      case 'terms': handleChangeTerms(value); break
      case 'discount_type': handleChangeAndUpdate({ discountType: value }); break
      case 'discount_value': handleChangeAndUpdate({ discountValue: parseFloat(value) }); break
      // eslint-disable-next-line no-lone-blocks
      // case 'discount_type': {
      //   switch (value as DISCOUNT_TYPE) {
      //     case DISCOUNT_TYPE.PERCENTAGE: form.setFields([{ discount_type: [{ disabled: false }] }]); break
      //     case DISCOUNT_TYPE.FIXED: form.setFields([{ name: 'discount_value', disabled: false }]); break
      //     default: {
      //       form.setFields([{ name: 'discount_value', disabled: true }])
      //     }
      //   }
      // } break
      default: { // ty message
        handleChangeAndUpdate({ thankYouMessage: value })
      }
    }
  }

  const initialValues: InvoiceFields = {
    billing_address: invoice?.customer?.billingAddress !== null && invoice?.customer?.billingAddress !== undefined ? formatAddress(invoice?.customer?.billingAddress, { multiline: true }) : '',
    site_address: invoice?.site?.address ? formatAddress(invoice?.site?.address, { multiline: false }) : '',
    customer_name: invoice?.customer?.name || '',
    customer_id: invoice?.customer?.id,
    customer_email: invoice.customer?.emails?.join(', ') || '',
    price_rate: priceSheets.find((sheet) => !isEmpty(sheet.customerIds || []))?.id || 'default',
    terms: TERMS_ENUM.TERMS_30,
    invoice_date: invoice.invoiceDate || '',
    due_date: invoice.dueDate || '',
    discount_type: DISCOUNT_TYPE.NONE,
    discount_value: 0,
    ty_message: settings.thankYouMessage,
  }

  useEffect(() => {
    updateInvoiceSettings({ ...settings, invoiceNumber: invoice.id || '', lot: invoice.lot })
    Object.entries(initialValues).forEach(([key, value]) => {
      handleChangeFormValues({ [key]: value }, initialValues)
    })
  }, [])

  return (
    <>
      {/* <Typography.Title level={3}>Invoice Settings</Typography.Title> */}
      <Form form={form} layout='vertical' initialValues={initialValues} onValuesChange={handleChangeFormValues} disabled={!editing}>
        <Form.Item label="Customer Name" name="customer_id">
          <CustomerSelect />
        </Form.Item>
        <Form.Item label="Customer email" name="customer_email">
          <Input type='email' />
        </Form.Item>
        <Form.Item label="Billing Address" name="billing_address">
          <Input.TextArea rows={2} value={settings.billAddress} />
        </Form.Item>
        <Form.Item label="Jobsite" name="site_address">
          <Input.TextArea rows={2} />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="price_rate" label="Price Rate">
              <Select placeholder='Select option'>
                {priceSheets.map((priceSheet) => (
                  <Select.Option key={priceSheet.id} value={priceSheet.id}>{priceSheet.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="terms" label="Term">
              <Select placeholder='Select option'>
                <Select.Option value={TERMS_ENUM.DUE}>Due on Receipt</Select.Option>
                <Select.Option value={TERMS_ENUM.TERMS_10}>Net 10</Select.Option>
                <Select.Option value={TERMS_ENUM.TERMS_15}>Net 15</Select.Option>
                <Select.Option value={TERMS_ENUM.TERMS_30}>Net 30</Select.Option>
                <Select.Option value={TERMS_ENUM.CUSTOM} disabled>Custom</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="invoice_date" label="Invoice Date">
              <Input
                placeholder="Select Date and Time"
                type="date"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="due_date"
              label="Due Date"
            >
              <Input
                placeholder="Select Date and Time"
                type="date"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="discount_type" label="Add Discount?">
              <Select>
                <Select.Option value={DISCOUNT_TYPE.NONE}>None</Select.Option>
                <Select.Option value={DISCOUNT_TYPE.FIXED}>Amount</Select.Option>
                <Select.Option value={DISCOUNT_TYPE.PERCENTAGE}>Percentage</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="discount_value" label="Discount Value">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="ty_message" label="Thank You Message">
          <Input.TextArea onChange={(e) => { handleChangeAndUpdate({ thankYouMessage: e.currentTarget.value }) }} />
        </Form.Item>
      </Form>
    </>
  )
}
