import { buildPropsFromGql, ReferenceSelect, ReferenceSelectProps } from 'components/form/reference-selects/ReferenceSelect'
import { useEquipmentSort } from 'hooks/useEquipmentSort'
import { uniqBy } from 'lodash'
import React from 'react'
import { Equipment } from 'schema'
import { GET_EQUIPMENT } from './EquipmentSelect'

export type BillableEquipmentSelectProps = ReferenceSelectProps<Equipment>

export const BillableEquipmentSelect: React.FC<BillableEquipmentSelectProps> = (props) => {
  const { sortEquipment } = useEquipmentSort()

  return ReferenceSelect(
    buildPropsFromGql({
      name: 'Equipment',
      queryField: 'equipment',
      gql: GET_EQUIPMENT,
      withCurrentBranchFilter: true,
      defaults: {
        placeholder: 'Select Billable Equipment',
        showSearch: true,
        optionsGenerator: (equipmentSet: Equipment[]) => {
          const allOptions = sortEquipment(equipmentSet.slice()).map((equipment) => ({
            label: equipment.displayName || equipment.name,
            value: equipment.id,
          }))

          // Ensures that if an existing "equipment" is selected, that its
          // id is used rather than the first of that size
          allOptions.sort((a, b) => {
            if (a.label !== b.label) return 0
            if (a.value === props.value) return -1
            if (b.value === props.value) return 1
            return 0
          })

          return uniqBy(allOptions, (option) => option.label)
        },
      },
    }, props)
  )
}
