import { orange } from '@ant-design/colors'
import colors from 'constants/colors'
import { formatDuration } from 'helpers/formatDuration'
import { compact } from 'lodash'
import { DateTime, Duration } from 'luxon'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { gql, useMutation, useQuery } from '@apollo/client'
import { UPDATE_ORDER } from 'gql/orders'
import {
  Mutation, MutationUpdateOrderArgs, Order, Query, Route,
} from 'schema'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Modal, ModalProps } from 'antd'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loading } from 'components/Loading'

interface TravelCalloutProps {
  warning?: boolean
}

const TravelCallout = styled.div.attrs<TravelCalloutProps>((props) => ({
  className: compact([props.className, props.warning && 'warning']),
})) <TravelCalloutProps>`
  margin-top: 5px;

  &.warning {
    color: ${colors.error};
  }
`

const Emphasize = styled.span`
  font-weight: bolder;
`

export interface JoinOrderToRouteModalProps extends Omit<ModalProps, 'visible'> {
  orderIds: Order['id'][]
  routeId: Route['id']
}

export const JoinOrderToRouteModal = (props: JoinOrderToRouteModalProps) => {
  const { orderIds, routeId, ...modalInputProps } = props

  const [visible, setVisible] = useState<boolean>(true)

  const { data, error, loading: loadingRaw } = useQuery<Query, any>(
    CHECK_ORDERS_FIT_IN_ROUTE,
    {
      variables: { orderIds, routeId },
    }
  )

  const [moveOrderToNewRoute, { loading: mutationLoading, error: mutationError }] = useMutation<Mutation, MutationUpdateOrderArgs>(UPDATE_ORDER, {
    refetchQueries: ['GetEvents'],
  })

  const loading = loadingRaw && !data

  const route = data?.route

  const routeTitle = useMemo(() => {
    if (!route) return ''

    const startTime = DateTime.fromISO(route.startTimeLocal)
    const endTime = DateTime.fromISO(route.endTimeLocal)
    const midTime = startTime.plus(endTime.diff(startTime))

    return [
      route.active ? (route.equipment?.name || 'unassigned Route') : 'cancelled Route',
      midTime.toFormat('M/d'),
    ].join(' on ')
  }, [route])

  const results = data?.checkOrdersFitInRoute?.results || []
  const moveResults = useMemo(() => {
    const ordersById = Object.fromEntries((data?.orders || []).map((order) => [order.id, order]))

    return compact(results?.map((result) => {
      const order = ordersById[result.orderId || -1]
      if (!order) return
      const {
        incomingTravelDuration, outgoingTravelDuration, invalidDuration, ...rest
      } = result

      return {
        ...rest,
        incomingTravelDuration: incomingTravelDuration ? Duration.fromMillis(incomingTravelDuration * 1000) : null,
        outgoingTravelDuration: outgoingTravelDuration ? Duration.fromMillis(outgoingTravelDuration * 1000) : null,
        invalidDuration: invalidDuration ? Duration.fromMillis(invalidDuration * 1000) : null,
        name: `${order.customer?.abbreviation} ${order.id}`,
      }
    }))
  }, [results])

  const hasOverlap = moveResults.some((item) => item.invalidCode === 'OVERLAPS')

  const modalProps: ModalProps = {
    title: <><QuestionCircleOutlined style={{ color: orange[4], marginRight: 5 }} /> Confirm Order Move</ >,
    cancelText: 'Cancel',
    okText: 'Move Order',
    width: '85%',
    ...modalInputProps,
    confirmLoading: mutationLoading,
    style: {
      ...modalInputProps?.style,
      minWidth: '300px',
      maxWidth: '700px',
    },
    okButtonProps: {
      disabled: hasOverlap,
    },
    visible,
    destroyOnClose: true,
    onCancel: async (evt) => {
      if (props.onCancel) {
        await props.onCancel(evt)
      }
      setVisible(false)
    },
    onOk: async (evt) => {
      // can't do this in parallel via Order mutation
      // would need to use Route mutation, which doesn't
      // support connecting Orders atm
      for (const orderId of orderIds) {
        // eslint-disable-next-line no-await-in-loop
        const resp = await moveOrderToNewRoute({
          variables: {
            data: {
              revision: -42,
              route: {
                connect: {
                  id: routeId,
                  force: true,
                },
              },
            },
            where: {
              id: orderId,
            },
          },
        })

        if (resp.errors) {
          return
        }
      }

      if (props.onOk) {
        await props.onOk(evt)
      }

      setVisible(false)
    },
  }

  return (
    <Modal {...modalProps}>
      {loading ?
        <Loading style={{ fontSize: '1.2rem' }} />
        : error ?
          <>Travel duration could not be calculated at this time. Are you sure you want to move this order?</>
          : moveResults.map((result, index) => {
            const hasBothTravelTimes = result.incomingTravelDuration && result.outgoingTravelDuration

            return (
              <div key={index}>
                Are you sure you want to move <Emphasize>{result.name}</Emphasize> to the <Emphasize>{routeTitle}</Emphasize>?

                {result.invalidCode === 'OVERLAPS' && (
                  <TravelCallout warning style={{ fontWeight: 'bold' }}>
                    It is not possible to move this Order to the selected Route because it is scheduled to overlap another Order on this Route.
                  </TravelCallout>
                )}

                {result.incomingTravelDuration && (
                  <TravelCallout warning={result.invalidCode === 'INCOMING_LEG_TOO_SHORT'}>
                    The new estimated{hasBothTravelTimes ? ' incoming' : ''} travel time is <Emphasize>{formatDuration(result.incomingTravelDuration)}</Emphasize>.

                    {result.invalidCode === 'INCOMING_LEG_TOO_SHORT' && result.invalidDuration && (
                      <>
                        <br /> The scheduled travel time is only <Emphasize>{formatDuration(result.incomingTravelDuration.plus(result.invalidDuration))}</Emphasize>.
                      </>
                    )}
                  </TravelCallout>
                )}
                {result.outgoingTravelDuration && (
                  <TravelCallout warning={result.invalidCode === 'OUTGOING_LEG_TOO_SHORT'}>
                    The new estimated{hasBothTravelTimes ? ' outgoing' : ''} travel time is <Emphasize>{formatDuration(result.outgoingTravelDuration)}</Emphasize>.

                    {result.invalidCode === 'OUTGOING_LEG_TOO_SHORT' && result.invalidDuration && (
                      <>
                        <br /> The scheduled travel time is only <Emphasize>{formatDuration(result.outgoingTravelDuration.plus(result.invalidDuration)) || '0 mins'}</Emphasize>.
                      </>
                    )}
                  </TravelCallout>
                )}
              </div>
            )
          })}
      {mutationError && (
        <ErrorMessage
          title="Error Moving Order"
          size="small"
          style={{ marginTop: 20 }}
        >
          {mutationError.message}
        </ErrorMessage>
      )}
    </Modal>
  )
}

const CHECK_ORDERS_FIT_IN_ROUTE = gql`
  query CheckOrdersFitInRoute($orderIds: [Int!]!, $routeId: Int!) {
    checkOrdersFitInRoute(where: { orderIds: $orderIds, routeId: $routeId }) {
      success
      results {
        orderId
        invalidReason
        invalidCode
        invalidDuration
        outgoingTravelDuration
        incomingTravelDuration
      }
    }

    orders(
      where: {
        id: {
          in: $orderIds
        }
      }
    ) {
      id
      customer {
        id
        abbreviation
      }
    }

    route(
      id: $routeId
    ) {
      id
      active
      startTimeLocal
      endTimeLocal
      equipment {
        id
        name
      }
    }
  }
`
