import {
  Col, Row, Space, Tabs,
} from 'antd'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'
import styled from 'styled-components'
import { InvoiceBalanceContext } from '../../hooks/useBalance'
import { InvoiceSettingsContext } from '../../hooks/useInvoiceSettings'
import { Invoice, InvoiceInput, useUpdateInvoiceMutation } from '../../hooks/useInvoices'
import { InvoiceItemsContext } from '../../hooks/useItems'
import { useNotification } from '../../hooks/useNotification'
import { useTimezone } from '../../hooks/useTimezone'
import { useIsMobile } from '../../hooks/useViewportMode'
import { OrderBillingStateProvider } from '../../pages/Orders/OrderShow/OrderBillingState'
import { OrderCompareTable } from '../../pages/Orders/OrderShow/OrderCompareTable'
import { InventoryTable } from '../../pages/Orders/OrderShow/OrderCompareTable/InventoryTable'
import { ScheduleTable } from '../../pages/Orders/OrderShow/OrderCompareTable/ScheduleTable'
import { Overview } from '../../pages/Orders/OrderShow/Overview'
import { generatePDFDocument, getBlob, printPDF } from '../../pdfs/generatePDFInvoice'
import { formatToCurrency } from '../../utils/format'
import { Button } from '../buttons'
import { Card } from '../card'
import { OrderCommentsForm } from '../form/CommentsForm'
import { PermanentDrawerV2 } from '../layout/PermanentDrawer'
import { InvoiceForm } from './InvoiceForm'
import { InvoiceHeader } from './InvoiceHeader'
import { InvoiceSettings } from './InvoiceSettings'
import { ProductsTable } from './ProductDetails'
import { SubtotalPreview } from './SubtotalPreview'
import { convertToInvoice } from './data/useNewInvoice'

export type TabItems = { title: string, component: JSX.Element }[]

export interface IOrderInvoiceForm {
  tabItems: TabItems
}

interface OrderInvoiceFormProps {
  onCancelBuild?: () => void,
  isNew: boolean,
  invoice: Partial<Invoice>
}

const PDFPage = styled.div`
  background: #fff;
  padding: 30px;
  box-shadow: 0 2px 5px -3px rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.4);
`

export const OrderInvoiceForm = ({ invoice, isNew, onCancelBuild }: OrderInvoiceFormProps) => {
  const previousUrl = useLastLocation()?.pathname || '/invoices'
  const history = useHistory()
  const isMobile = useIsMobile()
  const timezone = useTimezone()
  const notification = useNotification()
  const [isEditing, setEditing] = useState<boolean>(isNew)
  const { settings, jobDetails } = useContext(InvoiceSettingsContext)
  const { balance } = useContext(InvoiceBalanceContext)
  const { items } = useContext(InvoiceItemsContext)
  const [updateInvoice] = useUpdateInvoiceMutation()

  const handlePrintPDF = async () => {
    const doc = generatePDFDocument({
      balance, items, settings, jobDetails,
    })
    const blob = await getBlob(doc)
    printPDF(blob)

    await handleSaveInvoice({ status: 'sent' })
  }

  const handleEmail = async () => {
    await handleSaveInvoice({ status: 'sent' })

    notification.success({
      message: 'Email was sent',
    })
  }

  const orderId = invoice?.orderIds?.[0]

  const cancelEdit = () => {
    if (isNew) {
      history.push(previousUrl)
    } else {
      setEditing(false)
    }
  }

  const handleSaveInvoice = async (data: InvoiceInput = {}) => {
    const newInvoice = convertToInvoice({
      balance, items, settings, timezone,
    })
    const result = await updateInvoice({
      variables: {
        data: {
          ...invoice,
          ...newInvoice,
          ...data,
        },
        where: {
          id: parseInt(newInvoice.id),
        },
      },
    })
    if (result.errors) {
      notification.error({
        message: `ERROR: ${JSON.stringify(result.errors)}`,
      })
      return
    }

    setEditing(false)

    if (isNew) {
      history.replace({
        // eslint-disable-next-line no-restricted-globals
        ...location,
        pathname: `/invoices/${newInvoice.id}`,
      })
    }
  }

  return (
    <>
      <Row wrap={false} style={{ width: '100%', height: '100%' }}>
        <Col flex="auto" style={{ width: '100%', height: '100%' }}>
          <Card block>
            <Card.Header style={{ padding: isMobile ? '25px 20px 8px 60px' : undefined }}>
              <InvoiceHeader
                invoice={invoice}
                isNew={isNew}
                onPrintPDF={handlePrintPDF}
                onSubmitInvoice={handleEmail}
                isEditing={isNew || isEditing}
                onCancelEdit={cancelEdit}
                onEdit={() => { setEditing(true) }}
                onSave={() => { handleSaveInvoice() }}
              />
            </Card.Header>
            <Card.Body style={{ padding: '16px', background: '#5f6368' }}>
              <PDFPage>
                <InvoiceForm invoice={invoice} />
                <div style={{ paddingTop: '2rem' }}>
                  <ProductsTable invoice={invoice} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '2em' }}>
                  <SubtotalPreview />
                </div>
              </PDFPage>
            </Card.Body>
            <SaveFooter>
              <Row gutter={[16, 16]} align="middle">
                {/* <Col style={{ visibility: 'hidden' }}>
                  <Balance>Balance Due: {formatToCurrency(balance.total)}</Balance>
                </Col>
                <Col flex="auto" style={{ textAlign: 'center' }}> */}
                <Col flex="auto">
                  <Space wrap>
                    {
                      isEditing ? (
                        <>
                          <BottomButton type="primary" onClick={() => handleSaveInvoice()}>Save</BottomButton>
                          <BottomButton onClick={cancelEdit}>Cancel</BottomButton>
                        </>
                      ) :
                        <BottomButton type="primary" onClick={() => { setEditing(true) }}>Edit</BottomButton>
                    }
                  </Space>
                </Col>
                <Col>
                  <Balance>Balance Due: {formatToCurrency(balance.total)}</Balance>
                </Col>
              </Row>
            </SaveFooter>
          </Card>
        </Col>
        {!isMobile && (
          <Col flex="450px">
            <PermanentDrawerV2
              width="450px"
              placement="right"
              bodyStyle={{ paddingTop: 0, paddingLeft: '1em', paddingRight: '1em' }}
            >
              <Tabs>
                <Tabs.TabPane tab="Settings" key="item-1">
                  <InvoiceSettings invoice={invoice} editing={isNew || isEditing} />
                </Tabs.TabPane>
                {
                  orderId && (
                    <>
                      <Tabs.TabPane tab="Order Details" key="item-2">
                        <OrderBillingStateProvider orderId={orderId}>
                          <SimplifiedOrderBilling />
                        </OrderBillingStateProvider>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Comments" key="item-4">
                        <OrderCommentsForm id={orderId} />
                      </Tabs.TabPane>
                    </>
                  )
                }
              </Tabs>
            </PermanentDrawerV2>
          </Col>
        )}
      </Row>
    </>
  )
}

const BottomButton = styled(Button)`
  min-width: 100px;
`

const Balance = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: white;
  opacity: 0.95;
`

const SaveFooter = styled(Card.Footer)`
  padding: 0;
  width: 100%;
  min-height: 30px;
  background: #4d5156;
  border-top: none !important;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.3);
  z-index: 10;
  padding: 8px 60px 8px 20px;
`

const SimplifiedOrderBilling = styled.div.attrs({
  children: <>
    <OrderCompareTable />
    <ScheduleTable />
    <InventoryTable />
    <Overview />
  </>,
})`
  div[section="title"] {
    max-width: 100px;
  }
  div[section="actual.operator"] {
    display: none;
  }
  div[section="actual.samsara"] {
    display: none;
  }

  > div {
    min-width: unset !important;
    width: 100%;
    overflow: scroll;
  }

  .anticon-edit {
    display: none;
  }
`
