import { Skeleton as AntSkeleton } from 'antd'
import styled from 'styled-components'

const Skeleton = styled(AntSkeleton)`
  .ant-skeleton-content .ant-skeleton-title {
    height: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
`

Skeleton.Button = AntSkeleton.Button
Skeleton.Avatar = AntSkeleton.Avatar
Skeleton.Input = AntSkeleton.Input
Skeleton.Image = AntSkeleton.Image

export { Skeleton }
