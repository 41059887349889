import {
  Redirect, Route, Switch, useRouteMatch,
} from 'react-router-dom'
import { GeotabEquipmentMatch } from './EquipmentMatch'
import { GeotabSettings } from './Settings'

export const GeotabPage = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/equipment`} component={GeotabEquipmentMatch} />
      <Route path={`${match.path}/`} exact component={GeotabSettings} />
      <Redirect to="/" />
    </Switch>
  )
}

export default GeotabPage
