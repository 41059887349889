import colors from 'constants/colors'
import styled from 'styled-components'

import { OrderStatus } from 'schema'

import { Badge as AntBadge } from 'antd'
import { BadgeProps } from 'antd/lib/badge'
import { Tooltip } from 'components/common/Tooltip'

const BadgeBase = styled(AntBadge)`
  font-size: inherit;

  .ant-badge-status-text {
    font-size: inherit;
    margin-left: 10px;
  }
`

const BadgeMD = styled(BadgeBase)`
  .ant-badge-status-dot {
    width: 7px;
    height: 7px;
  }
`

const BadgeLG = styled(AntBadge)`
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
`

export interface StatusBadgeProps extends Omit<BadgeProps, 'size' | 'status'> {
  status: Partial<Omit<OrderStatus, '__typename'>>
  size?: BadgeProps['size'] | 'large' | 'medium'
  iconOnly?: boolean
}

export const StatusBadge = (props: StatusBadgeProps): JSX.Element => {
  const {
    status,
    size,
    iconOnly,
    ...rest
  } = props
  const args: BadgeProps = rest

  args.color = status.color || colors.emptyStatus

  let Badge: typeof AntBadge

  switch (size) {
    case 'large':
      Badge = BadgeLG
      break
    case 'medium':
      Badge = BadgeMD
      break
    default:
      args.size = size
      Badge = BadgeBase
  }

  return iconOnly ? (
    <Tooltip title={(
      <Badge
        {...args}
        text={
          <span style={{ color: colors.greyscale5 }}>{status.name}</span>
        }
      />
    )}
    >
      <Badge {...args} text={undefined} />
    </Tooltip>
  )
    :
    <Badge {...args} text={status.name} />
}
