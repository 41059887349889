import { gql } from '@apollo/client'
import {
  Button, Col, Divider, Row, Typography,
} from 'antd'
import { FullName } from 'components/common/FullName'
import { ErrorMessage } from 'components/ErrorMessage'
import { PhonesFormList } from 'components/form//common/PhonesFormList'
import { CredentialsFormList } from 'components/form/common/CredentialFormList'
import { Form } from 'components/form/Form'
import { Input } from 'components/form/inline-edit/Input'
import SwitchInput from 'components/form/inline-edit/Switch'
import { Loading } from 'components/Loading'
import { GET_OPERATORS } from 'gql/operators'
import { Footer, Header } from 'hooks/useContentLayout'
import {
  compact, difference, isEmpty, omit, pick,
} from 'lodash'
import React, { useState } from 'react'
import {
  CredentialUpsertInput, OperatorInput,
} from 'schema'
import {
  GetOperatorForFormQuery,
  useCreateOperatorMutation,
  useGetOperatorForFormQuery,
  useUpdateOperatorMutation,
} from './__generated__/OperatorForm'

const { Paragraph } = Typography

type Operator = NonNullable<NonNullable<GetOperatorForFormQuery>['operator']>
type OperatorFormValues = Operator

export interface OperatorFormProps {
  id?: number
  editing?: boolean
  onSubmission?: (operator: Operator, action: 'update' | 'create') => void
  onCancel?: () => void
  customerId?: number
}

gql`
  fragment OperatorForForm on Operator {
    id
    firstName
    lastName
    active
    createdAt
    updatedAt

    credentials {
      id
      number
      images
      expirationDate
      type
      typeDetails {
        slug
        name
      }
      active
    }

    phones {
      id
      number
      type
      sms
      active
    }
  }
`

export const OperatorForm: React.FC<OperatorFormProps> = (props) => {
  const { id, onSubmission } = props
  const isNew = id === undefined
  const [editing, setEditing] = useState<boolean>(props.editing || isNew)

  const { data, error: queryError, loading: queryLoading } = useGetOperatorForFormQuery({
    skip: isNew,
    variables: !id ? undefined : { id },
  })

  const operator = data?.operator

  const [createOperator, createOperatorResponse] = useCreateOperatorMutation({
    refetchQueries: [{
      query: GET_OPERATORS,
    }],
  })

  const [updateOperator, updateOperatorResponse] = useUpdateOperatorMutation()

  const { loading: mutationLoading, error: mutationError } = isNew ? createOperatorResponse : updateOperatorResponse

  const [form] = Form.useForm<OperatorFormValues>()

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setEditing(false)
    form.resetFields()
  }

  const onFinish = async (values: OperatorFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data: OperatorInput = omit(values, ['operator']) as any

    data.phones = compact(
      compact<any>(values.phones || []).map(({ number, sms, type }) => {
        if (!number) return
        return { number, sms, type }
      })
    )

    const credentialsToDelete = difference(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      operator?.credentials.map(({ id }) => id),
      // eslint-disable-next-line @typescript-eslint/no-shadow
      compact(values?.credentials?.map(({ id }) => id))
    )

    data.credentials = {
      upsert: compact(values?.credentials?.map((credentials) => {
        if (!credentials.type) return

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const data: CredentialUpsertInput['data'] = {
          ...pick(credentials, ['active', 'expirationDate', 'images', 'number']),
          type: credentials.type,
        }
        data.typeName = credentials.typeDetails?.name

        const where = pick(credentials, ['id'])

        return {
          data,
          where,
        }
      })),
      // eslint-disable-next-line @typescript-eslint/no-shadow
      delete: credentialsToDelete.length === 0 ? undefined : credentialsToDelete.map((id) => ({ id })),
    }

    if (id) {
      const resp = await updateOperator({
        variables: { data, where: { id } },
      })

      if (resp.errors) return

      setEditing(false)

      const updatedOperator = resp?.data?.updateOperator

      if (updatedOperator) {
        form.setFieldsValue(updatedOperator)
        onSubmission?.(updatedOperator, 'update')
      }
    } else {
      const resp = await createOperator({
        variables: { data },
      })

      if (resp.errors) return

      setEditing(false)

      const newOperator = resp?.data?.createOperator

      if (newOperator && onSubmission) {
        onSubmission(newOperator, 'create')
      }
    }
  }

  const getValues = () => {
    const formVals = form.getFieldsValue()
    if (!isEmpty(formVals)) return formVals
    return operator || {}
  }

  if (queryError) {
    return (
      <ErrorMessage to="/operators">
        <Paragraph>{queryError.message}</Paragraph>
      </ErrorMessage>
    )
  }

  if (queryLoading) return <Loading />

  return (
    <>
      {mutationError && (
        <ErrorMessage>
          <Paragraph>{mutationError.message}</Paragraph>
        </ErrorMessage>
      )}
      <Form
        form={form}
        name="operator-form"
        onFinish={onFinish}
        requiredMark={editing}
        scrollToFirstError
        initialValues={{
          active: true,
          ...operator,
        }}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) => (
            prev.firstName !== current.firstName ||
            prev.lastName !== current.lastName
          )}
        >
          {() => (
            <Header hideIfOrphan>
              <FullName {...getValues()} />
            </Header>
          )}
        </Form.Item>

        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'First name is required',
            },
          ]}
        >
          <Input placeholder="First name" editing={editing} />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Last name is required',
            },
          ]}
        >
          <Input placeholder="Last name" editing={editing} />
        </Form.Item>
        <PhonesFormList
          editing={editing}
          singleMode
          hideType
        />
        <Form.Item
          label="Active"
          name="active"
          valuePropName="checked"
        >
          <SwitchInput
            editing={editing}
          />
        </Form.Item>

        <Divider>Credentials</Divider>
        <CredentialsFormList name="credentials" editing={editing} />
      </Form>

      <Footer>
        {!editing && <Button block type="primary" onClick={() => { setEditing(true) }}>Edit</Button>}
        {editing && (
          <Row gutter={12}>
            <Col flex="auto">
              <Button block onClick={onCancel}>Cancel</Button>
            </Col>
            <Col flex="auto">
              <Button loading={mutationLoading} block type="primary" onClick={form.submit}>Save</Button>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}
