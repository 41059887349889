import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEquipmentForFormQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetEquipmentForFormQuery = { __typename?: 'Query', equipment: Array<{ __typename?: 'Equipment', id: number, name: string, displayName?: string | null, size?: number | null, description?: string | null, prepTime?: number | null, cleanTime?: number | null, type: string, active: boolean, typeDetails: { __typename?: 'EquipmentType', name: string, slug: string, sort: number }, branch: { __typename?: 'Branch', id: number, name?: string | null }, group?: { __typename?: 'EquipmentGroup', id: number, name: string } | null, documents: Array<{ __typename?: 'Document', id: number, active: boolean, attachments?: Types.Scalars['Json'] | null, expirationDate?: any | null, pinned: boolean, fields: Array<{ __typename?: 'KeyValue', key?: string | null, value?: Types.Scalars['Json'] | null }>, typeDetails: { __typename?: 'DocumentType', name: string, slug: string } }> }> };

export type CreateEquipmentMutationVariables = Types.Exact<{
  data: Types.EquipmentInput;
}>;


export type CreateEquipmentMutation = { __typename?: 'Mutation', createOneEquipment?: { __typename?: 'Equipment', id: number, name: string, displayName?: string | null, size?: number | null, description?: string | null, prepTime?: number | null, cleanTime?: number | null, type: string, active: boolean, typeDetails: { __typename?: 'EquipmentType', name: string, slug: string, sort: number }, branch: { __typename?: 'Branch', id: number, name?: string | null }, group?: { __typename?: 'EquipmentGroup', id: number, name: string } | null, documents: Array<{ __typename?: 'Document', id: number, active: boolean, attachments?: Types.Scalars['Json'] | null, expirationDate?: any | null, pinned: boolean, fields: Array<{ __typename?: 'KeyValue', key?: string | null, value?: Types.Scalars['Json'] | null }>, typeDetails: { __typename?: 'DocumentType', name: string, slug: string } }> } | null };

export type UpdateEquipmentMutationVariables = Types.Exact<{
  data: Types.EquipmentUpdateInput;
  where: Types.EquipmentWhereUniqueInput;
}>;


export type UpdateEquipmentMutation = { __typename?: 'Mutation', updateOneEquipment?: { __typename?: 'Equipment', id: number, name: string, displayName?: string | null, size?: number | null, description?: string | null, prepTime?: number | null, cleanTime?: number | null, type: string, active: boolean, typeDetails: { __typename?: 'EquipmentType', name: string, slug: string, sort: number }, branch: { __typename?: 'Branch', id: number, name?: string | null }, group?: { __typename?: 'EquipmentGroup', id: number, name: string } | null, documents: Array<{ __typename?: 'Document', id: number, active: boolean, attachments?: Types.Scalars['Json'] | null, expirationDate?: any | null, pinned: boolean, fields: Array<{ __typename?: 'KeyValue', key?: string | null, value?: Types.Scalars['Json'] | null }>, typeDetails: { __typename?: 'DocumentType', name: string, slug: string } }> } | null };

export type EquipmentForFormFragment = { __typename?: 'Equipment', id: number, name: string, displayName?: string | null, size?: number | null, description?: string | null, prepTime?: number | null, cleanTime?: number | null, type: string, active: boolean, typeDetails: { __typename?: 'EquipmentType', name: string, slug: string, sort: number }, branch: { __typename?: 'Branch', id: number, name?: string | null }, group?: { __typename?: 'EquipmentGroup', id: number, name: string } | null, documents: Array<{ __typename?: 'Document', id: number, active: boolean, attachments?: Types.Scalars['Json'] | null, expirationDate?: any | null, pinned: boolean, fields: Array<{ __typename?: 'KeyValue', key?: string | null, value?: Types.Scalars['Json'] | null }>, typeDetails: { __typename?: 'DocumentType', name: string, slug: string } }> };

export const EquipmentForFormFragmentDoc = gql`
    fragment EquipmentForForm on Equipment {
  id
  name
  displayName
  size
  description
  prepTime
  cleanTime
  type
  active
  typeDetails {
    name
    slug
    sort
  }
  branch {
    id
    name
  }
  group {
    id
    name
  }
  documents {
    id
    active
    attachments
    expirationDate
    fields {
      key
      value
    }
    pinned
    typeDetails {
      name
      slug
    }
  }
}
    `;
export const GetEquipmentForFormDocument = gql`
    query GetEquipmentForForm($id: Int!) {
  equipment(where: {id: {equals: $id}}) {
    ...EquipmentForForm
  }
}
    ${EquipmentForFormFragmentDoc}`;

/**
 * __useGetEquipmentForFormQuery__
 *
 * To run a query within a React component, call `useGetEquipmentForFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentForFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentForFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEquipmentForFormQuery(baseOptions: Apollo.QueryHookOptions<GetEquipmentForFormQuery, GetEquipmentForFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEquipmentForFormQuery, GetEquipmentForFormQueryVariables>(GetEquipmentForFormDocument, options);
      }
export function useGetEquipmentForFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEquipmentForFormQuery, GetEquipmentForFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEquipmentForFormQuery, GetEquipmentForFormQueryVariables>(GetEquipmentForFormDocument, options);
        }
export type GetEquipmentForFormQueryHookResult = ReturnType<typeof useGetEquipmentForFormQuery>;
export type GetEquipmentForFormLazyQueryHookResult = ReturnType<typeof useGetEquipmentForFormLazyQuery>;
export type GetEquipmentForFormQueryResult = Apollo.QueryResult<GetEquipmentForFormQuery, GetEquipmentForFormQueryVariables>;
export function refetchGetEquipmentForFormQuery(variables: GetEquipmentForFormQueryVariables) {
      return { query: GetEquipmentForFormDocument, variables: variables }
    }
export const CreateEquipmentDocument = gql`
    mutation CreateEquipment($data: EquipmentInput!) {
  createOneEquipment(data: $data) {
    ...EquipmentForForm
  }
}
    ${EquipmentForFormFragmentDoc}`;
export type CreateEquipmentMutationFn = Apollo.MutationFunction<CreateEquipmentMutation, CreateEquipmentMutationVariables>;

/**
 * __useCreateEquipmentMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentMutation, { data, loading, error }] = useCreateEquipmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateEquipmentMutation, CreateEquipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEquipmentMutation, CreateEquipmentMutationVariables>(CreateEquipmentDocument, options);
      }
export type CreateEquipmentMutationHookResult = ReturnType<typeof useCreateEquipmentMutation>;
export type CreateEquipmentMutationResult = Apollo.MutationResult<CreateEquipmentMutation>;
export type CreateEquipmentMutationOptions = Apollo.BaseMutationOptions<CreateEquipmentMutation, CreateEquipmentMutationVariables>;
export const UpdateEquipmentDocument = gql`
    mutation UpdateEquipment($data: EquipmentUpdateInput!, $where: EquipmentWhereUniqueInput!) {
  updateOneEquipment(data: $data, where: $where) {
    ...EquipmentForForm
  }
}
    ${EquipmentForFormFragmentDoc}`;
export type UpdateEquipmentMutationFn = Apollo.MutationFunction<UpdateEquipmentMutation, UpdateEquipmentMutationVariables>;

/**
 * __useUpdateEquipmentMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentMutation, { data, loading, error }] = useUpdateEquipmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEquipmentMutation, UpdateEquipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEquipmentMutation, UpdateEquipmentMutationVariables>(UpdateEquipmentDocument, options);
      }
export type UpdateEquipmentMutationHookResult = ReturnType<typeof useUpdateEquipmentMutation>;
export type UpdateEquipmentMutationResult = Apollo.MutationResult<UpdateEquipmentMutation>;
export type UpdateEquipmentMutationOptions = Apollo.BaseMutationOptions<UpdateEquipmentMutation, UpdateEquipmentMutationVariables>;