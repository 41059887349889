import {
  cloneDeep,
  isEqual, isNil,
  get as lodashGet,
  set as lodashSet,
} from 'lodash'
import React, { Suspense, useState } from 'react'

import { Form } from 'antd'
import { AddressInput, AddressInputProps } from 'components/form/common/AddressInput'
import { getNamePath } from 'rc-field-form/es/utils/valueUtil'

import { Loading } from 'components/Loading'
import { AspectRatioBox } from 'components/common/AspectRatioBox'
import { CoordinatesInputButton } from 'components/mapping/CoordinatesInputButton'
import { SingleMarkerProps } from 'components/mapping/SingleMarkerMap'
import { useCurrentForm } from '../Form'

const SingleMarkerMap = React.lazy(
  () => import('components/mapping/SingleMarkerMap').then((module) => ({ default: module.SingleMarkerMap }))
)

export const AddressInputWithMap: React.FC<AddressInputProps> = (props: AddressInputProps) => {
  const form = useCurrentForm()
  const name = getNamePath(props.name || [])
  const path = props.path ? getNamePath(props.path) : name

  const latKey = [...path, 'lat']
  const lngKey = [...path, 'lng']

  const getLatLngFromForm = (): Pick<SingleMarkerProps, 'latitude' | 'longitude'> => (
    {
      latitude: form.getFieldValue(latKey),
      longitude: form.getFieldValue(lngKey),
    }
  )

  const setLatLngOnForm = (coordinates: Pick<SingleMarkerProps, 'latitude' | 'longitude'>) => {
    if (isNil(coordinates.latitude) || isNil(coordinates.longitude)) return

    const current = cloneDeep(form.getFieldValue([]))
    lodashSet(current, path, {
      ...lodashGet(current, path),
      lat: coordinates.latitude,
      lng: coordinates.longitude,
    })

    form.setFieldsValue(current)
  }

  // form is always triggering should update for some reason,
  // so use state to manage rerenders
  const [latLng, setLatLngState] = useState(getLatLngFromForm)

  const setLatLng = () => {
    setLatLngState((prev) => {
      const next = getLatLngFromForm()
      if (isEqual(prev, next)) {
        return prev
      }
      return next
    })
  }

  return (
    <>
      <AddressInput {...props} />
      <Form.Item
        noStyle
        style={{ width: '100%', height: '100%' }}
        shouldUpdate={(prevValues, curValues) => (
          lodashGet(prevValues, latKey) !== lodashGet(curValues, latKey) ||
          lodashGet(prevValues, lngKey) !== lodashGet(curValues, lngKey)
        )}
      >
        {() => {
          setLatLng()
          return null
        }}
      </Form.Item>
      <AspectRatioBox ratio={1}>
        <Suspense fallback={<Loading />}>
          <SingleMarkerMap
            onCoordinatesClick={(coordinates) => {
              setLatLngOnForm(coordinates)
            }}
            marker={{
              ...latLng,
              draggable: props.editing,
              onDragEnd: (event) => {
                if (event.lngLat) {
                  setLatLngOnForm({
                    latitude: event.lngLat[1],
                    longitude: event.lngLat[0],
                  })
                }
              },
            }}
          >
            <CoordinatesInputButton
              visible={props.editing}
              style={{ top: 8, left: 8 }}
              onChange={(coordinates) => {
                setLatLngOnForm({
                  latitude: coordinates.lat,
                  longitude: coordinates.lng,
                })
              }}
            />
          </SingleMarkerMap>
        </Suspense>
      </AspectRatioBox>
    </>
  )
}
