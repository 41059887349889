import { Tooltip as AntTooltip } from 'antd'
import { TooltipProps as AntdTooltipProps } from 'antd/lib/tooltip'
import { compact } from 'lodash'
import styled from 'styled-components'

export type TooltipProps = AntdTooltipProps & {
  enabled?: boolean
  noWrap?: boolean
}

export const TooltipTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
`

export const Tooltip: React.FC<TooltipProps> = (props) => {
  const { enabled, noWrap, ...args } = props

  if (enabled === false) {
    args.overlayClassName = compact([args.overlayClassName, 'ant-tooltip-hidden']).join(' ')
  }

  if (noWrap) {
    args.overlayClassName = compact([args.overlayClassName, 'ant-tooltip-auto-width']).join(' ')
  }

  return <AntTooltip {...args} />
}
